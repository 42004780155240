<section  class="globalContainerOut">
  <section class="loginContainerOut">
    <section class="topBlockOut">
      <div class="container bg-transparent">
        <div class="row">
          <div class="col-2">
            <!-- <img style="height: 60px;"
            src="../../../assets/images/MyFamilyClub-logo_fin.png"
            alt="abbott"
            class="logo"
          /> -->
          </div>
        </div>
      </div>
      <div class="row h-100 align-items-center w-100 m-0">
        <div class="col-12 text-center">
          <img class="img-fluid" src="../../../assets/images/MyFamilyClub-logo_fin.png" />
        </div>
      </div>
    </section>
    <section class="middleBlock">
      <div style="margin-top: 80px;
      padding: 32px;">
          <h1>{{"LINE_LANDING.HEADING" | translate}}</h1>
          <button type="submit" (click)="OnClickOpenLine()" class="btn btn-block goldenBtn mt-3 mb-3">
              {{ "LINE_LANDING.OPEN_LINE_APP" | translate }}
            </button>
      </div>
      
    </section>
  </section>
</section>