import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-traceability-quality-control',
  templateUrl: './traceability-quality-control.component.html',
  styleUrls: ['./traceability-quality-control.component.scss'],
})
export class TraceabilityQualityControlComponent implements OnInit {
  productData: any;
  content: any;
  expiryDate: any;
  constructor() {
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
    this.getTracebilityContent();
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  getTracebilityContent() {
    this.productData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.expiryDate = this.productData.expiry_date;

    // this.content = this.productData.product.txt;
    // this.content.forEach((item: any) => {
    //   if (item.orders == 3) {
    //     let parsedExpiryDate = this.parseFunction(item.value);
    //     this.expiryDate = parsedExpiryDate.value;
    //   }
    // });
  }
}
