<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pediaSureContainer pediasure-plus-tasteless">
  <header class="traceabilityHeader">
    <img class="homeLogo" routerLink="/pediasureplus-traceability" src="../../../assets/images/home_logo_white.png">
    <img class="img-fluid mt-2" src="../../../assets/images/pedisurelogo.png">
</header>

  <div class="heading-icon mt-5">
    <img class="w-100" src="../../../assets/images/pediasure/pediasure-qc-image.png" >

  </div>
  <div class="grd-box mt-3 text-center">
   <div class="grd-inner-div"> <span>วันหมดอายุ : {{expiryDate}}</span></div>
  </div>
  <div class="contentWrapper">
    <div class="text-type-22 text-left">
        สำหรับแอ๊บบอต การควบคุมคุณภาพในกระบวนการผลิตถือเป็น
        สิ่งสำคัญที่สุด เพื่อให้มั่นใจได้ว่าบริษัทฯ จะสามารถผลิตสินค้าที่มี
        คุณภาพและมีความปลอดภัยอยู่เสมอ
        
    
      </div>

      <div>
        <img class="w-100 mt-3" src="../../../assets/images/pediasure/abbott-qc.png">
      </div>
      <div class="text-type-22 mt-3 text-left">
        ตั้งแต่ขั้นตอนการรับวัตถุดิบสำหรับใช้ในการผลิตสินค้า จนกระทั่ง
การผลิตสินค้าออกจากโรงงาน ในทุกขั้นตอนของการผลิตได้ผ่าน
การตรวจสอบอย่างเข้มงวดโดยผู้เชี่ยวชาญด้านการประกันคุณ
ภาพ ที่ผ่านการฝึกอบรม พร้อมด้วยการใช้อุปกรณ์การตรวจสอบ
ที่ทันสมัย

      </div>
      
  </div>
  <div class="text-type-12 mt-3 ml-2">TH.2022.27391.PDS.1(v1.0)©2022Abbott</div>
</section>
<app-pediasure-plus-tasteless-nav></app-pediasure-plus-tasteless-nav>
