import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationService } from "../../navigation.service";


@Component({
  selector: 'app-header-family-club',
  templateUrl: './header-family-club.component.html',
  styleUrls: ['./header-family-club.component.scss']
})
export class HeaderFamilyClubComponent implements OnInit {

  constructor(private _location: Location, private navigation: NavigationService) { }

  ngOnInit(): void {
  }

  goBack() {
    this._location.back();
    // this.navigation.back();
  }

}
