import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-ensure-cereal-traceability',
  templateUrl: './ensure-cereal-traceability.component.html',
  styleUrls: ['./ensure-cereal-traceability.component.scss'],
})
export class EnsureCerealTraceabilityComponent implements OnInit {
  productData: any;
  content: any;
  productCode: any;
  primaryProductTitle: any;
  productName: any;
  manufactCountry: any;
  CRMproductName: any;
  expiryDate: any;
  manufacturedDate: any;
  lotNumber: any;
  sfProductCode: any;
  sfPrice: any;
  productImage: any;
  prodImage: any;
  sku: any;
  isIsprint: any;
  origin: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
    this.getTracebilityContent();
    this.apiService.getGpasProductImage(this.sku).subscribe(
      (res: any) => {
        console.log(res.data, 'getGpasProductImage');
        this.prodImage = res.data[0]?.productimage;
        console.log(this.prodImage);
      },
      (err) => {
        this.bsService.notifyError(err, { sku: this.sku });
      }
    );
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  getTracebilityContent() {
    this.productData = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productData);
    this.productImage = this.productData.product_image;
    // this.productCode = this.productData.product.productCode;
    this.manufacturedDate = this.productData.manufacture_date;
    this.expiryDate = this.productData.expiry_date;
    this.CRMproductName = this.productData.crm_name;

    this.lotNumber = this.productData.lot_number;
    this.sku = this.productData.sku;
    this.isIsprint = this.productData.is_isprint;

    if (this.productData.is_isprint == true) {
      let parsedManufactCountry = this.parseFunction(
        this.productData.country_of_origin
      );
      this.manufactCountry = parsedManufactCountry.th_TH;

      let parsedProductName = this.parseFunction(this.productData.product_name);
      this.productName = this.productData?.crm_name?.th_TH;
    } else {
      this.manufactCountry = this.productData.origin;
      this.productName = this.productData.thai_product_name;
      // this.origin = this.productData.origin;
    }
  }
}
