import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment as env } from '../../environments/environment';
import { BugsnagService } from '../services/bugsnag.service';
import liff from '@line/liff';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  scanScode:any;
  typeCheck: number;
  loader: boolean = true;
  errMsg: any;
  redirect: any;
  productName: any;
  latitude: string;
  longitude: string;
  confidence: string;
  iSprintData: any;
  @ViewChild('failedScanModal', { static: true })
  failedScanModal: ModalDirective;
  url: any;
  urlStrings=[];
  scanArray=[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private bsService: BugsnagService,
    private document: Document
  ) {
    this.url = this.router.url;
    console.log("url: ",this.url)   
    window.scrollTo({ top: 0 });
    if (this.route.snapshot.queryParams.c) {
      this.scanScode = this.route.snapshot.queryParams.c;
      console.log(this.scanScode, 'this.scanScode 12');
      sessionStorage.setItem('scanCode', this.scanScode);
      this.getPosition()
    } else if (this.route.snapshot.queryParams.u) {
      this.scanScode = this.route.snapshot.queryParams.u;
      console.log(this.scanScode, 'this.scanScode 12');
      sessionStorage.setItem('scanCode', this.scanScode);
      this.getPosition()
    } else if(this.route.snapshot.queryParams['liff.state']){
      //on redirecting from liff url to abbott app
      this.scanArray = this.route.snapshot.queryParams['liff.state'].split('=')
      this.scanScode = this.scanArray[1]
      sessionStorage.setItem('scanCode', this.scanScode);
      this.initLine()
    }
    else if (this.route.snapshot?.queryParams?.['code'] || this.route.snapshot?.queryParams?.['liffRedirectUri']) {
      //if the user is just logged in on liff
      this.initLine()
       }
    else if (this.url.includes('scancode=')) {
      //user scanning QR code without line browser
      sessionStorage.setItem('scanCode', this.route.snapshot?.queryParams['scancode']);
       this.initLine()
 }else if (
      (this.scanScode === undefined ||
      this.scanScode === null ||
      this.scanScode === '') 
     &&(window.navigator.userAgent.toLowerCase().includes('line'))
     && (!this.url.includes('scancode='))
    )
     {
      // If the user opens this application from liff browser url
      this.loader = true;
      this.initLine()
    } 
    else if ((this.scanScode == undefined) && (!this.url.includes('scancode='))) {
      //navigate to login if (https://abbott-th-dev.sttarter.com) url opened directly in chrome browser
      this.loader = false;
      sessionStorage.removeItem('iSprintData');
      this.router.navigate(['login'])
   }
    
  }

  ngOnInit(): void { }

  getPosition() {
    this.apiService.getPosition().then((pos) => {
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
      sessionStorage.setItem('latitude', this.latitude)
      sessionStorage.setItem('longitude', this.longitude)
      sessionStorage.setItem('confidence', this.confidence)
      if (this.loader) {
        this.getTraceabilityContent();
      }
    });
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  getTraceabilityContent() {
    sessionStorage.setItem('scanCode',this.scanScode)
    this.apiService
      .productinfo(
        this.scanScode,
        this.latitude,
        this.longitude,
        this.confidence
      )
      .subscribe(
        (res: any) => {
          console.log(res, 'res isprint');
          this.spinner.hide();

          if (res.status === 'success') {
            sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
            let cat_code1 = res.result.category_code;
            sessionStorage.setItem('catCode', cat_code1);

            this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
            console.log(this.iSprintData.sku, 'isprint sku');

            env.sfproductList.filter((data) => {
              if (data.sku == this.iSprintData.sku) {
                let sfCode = data.sfcode;
                console.log(data, 'data===');
              }
            });

            let cat_code = res.result.category_code;

            if (
              res.result.uaid_info_type == 1 ||
              res.result.uaid_info_type == ''
            ) {
              if (res.result?.is_isprint == false) {
                if (
                  (cat_code.startsWith('Ensure') ||
                    cat_code.startsWith('ENSURE') ||
                    cat_code.startsWith('ensure')) &&
                  (res.result.sku == '100S616233385' ||
                    res.result.sku == '100S842233385' ||
                    res.result.sku == '100S617133385' ||
                    res.result.sku == '100S616233440' ||
                    res.result.sku == '100S842233340')
                ) {
                  this.router.navigate(['/ensureh-traceability']);
                } else if (
                  (cat_code.startsWith('Ensure') ||
                    cat_code.startsWith('ENSURE') ||
                    cat_code.startsWith('ensure')) &&
                  (res.result.sku == '100S907233140' ||
                    res.result.sku == '100S907233185' ||
                    res.result.sku == '100S956233140' ||
                    res.result.sku == '100S956233185' ||
                    res.result.sku == '100Y483833185' ||
                    res.result.sku == '100Y483833140' ||
                    res.result.sku == '100Y484833140' ||
                    res.result.sku == '100Y511233140' ||
                    res.result.sku == '100S907233240' ||
                    res.result.sku == '100S956233240')
                ) {
                  this.router.navigate(['/ensuregold-traceability']);

                } else if (
                  (cat_code.startsWith('Ensure') ||
                    cat_code.startsWith('ENSURE') ||
                    cat_code.startsWith('ensure')) &&
                  (res.result.sku == '100Y576233140' ||
                    res.result.sku == '100Y576233185')
                ) {
                  this.router.navigate(['/ensureplantbased-home']);
                }

                else if (

                  cat_code.startsWith('PediaSure') ||
                  cat_code.startsWith('Pediasure') ||
                  cat_code.startsWith('pediasure') ||
                  cat_code.startsWith('PEDIASURE') &&
                  res.result.sku == '100S792233240'

                ) {
                  this.router.navigate(['/traceability-home']);
                }
                else if (
                  (cat_code.startsWith('Glucerna') ||
                    cat_code.startsWith('GLUCERNA') ||
                    cat_code.startsWith('glucerna')) &&
                  (res.result.sku == '100Y524833140' ||
                    res.result.sku == '100Y524833185')
                ) {
                  this.router.navigate(['/glucernaplus-traceability']);
                }
                else if (
                  cat_code.startsWith('Ensure') ||
                  cat_code.startsWith('ENSURE') ||
                  cat_code.startsWith('ensure')
                ) {
                  this.router.navigate(['/ensure-traceability']);
                } else if (
                  cat_code.startsWith('Glucerna') ||
                  cat_code.startsWith('GLUCERNA') ||
                  cat_code.startsWith('glucerna')
                ) {
                  this.router.navigate(['/glucerna-traceability']);
                }
              } else if (res.result?.is_isprint == true) {
                if (

                  res.result?.category_code == 'Ensure Gold'
                ) {
                  this.router.navigate(['/ensuregold-traceability']);
                  return;
                }
                if (

                  res.result?.category_code == 'Ensure POP H'
                ) {
                  this.router.navigate(['/ensureh-traceability']);
                  return;
                }
                if (

                  res.result?.category_code == 'Glucerna Plus'
                ) {
                  this.router.navigate(['/glucernaplus-traceability']);
                  return;
                }

                else if (

                  res.result?.category_code == 'Pediasure Plus'
                ) {
                  this.router.navigate(['/pediasureplus-traceability']);
                  return;
                } else if (
                  cat_code.startsWith('Ensure') ||
                  cat_code.startsWith('ENSURE') ||
                  cat_code.startsWith('ensure')
                ) {
                  this.router.navigate(['/ensure-traceability']);
                } else if (
                  cat_code.startsWith('Glucerna') ||
                  cat_code.startsWith('GLUCERNA') ||
                  cat_code.startsWith('glucerna')
                ) {
                  this.router.navigate(['/glucerna-traceability']);
                } else if (
                  cat_code.startsWith('PediaSure') ||
                  cat_code.startsWith('Pediasure') ||
                  cat_code.startsWith('pediasure') ||
                  cat_code.startsWith('PEDIASURE')
                ) {
                  this.router.navigate(['/traceability-home']);
                }
              }
            } else {
              try {
                let brower = (window.navigator.userAgent.toLowerCase()).toLowerCase()
                if (brower.includes('line')) {
                  let loc = `${env.LIFF_URL}?scancode=${this.scanScode}`
                  window.open(loc,"_self")
                } else this.router.navigate(['line-landing', { scancode: this.scanScode }])
              }
              catch {
                console.log('cant be reached')
              }
            }
          } else {
            alert('Please scan a valid QR code.');
          }
        },
        (err) => {
          if (err) {
            this.spinner.hide();
            this.bsService.notifyError(err, {
              scanScode: this.scanScode,
              latitude: this.latitude,
              longitude: this.longitude,
              confidence: this.confidence,
            });
            this.loader = false;
            this.failedScanModal.show();
          }
        }
      );
  }

  initLine() {
    this.loader = false
    liff.init({
      liffId: env.LIFFID
    }).then((res: any) => {
      if (!liff.isLoggedIn() && !liff.isInClient()) {
        console.log("xyz", res)
        liff.login()
        this.loader = false
      }
      else {
        console.log("else", res)
        const accessToken = liff.getAccessToken();
        this.scanScode = this.route.snapshot?.queryParams['scancode']
        console.log(accessToken);
        this.loader = false
        if(accessToken && this.scanScode)
        this.router.navigate(['/login', { token: accessToken, scan: this.scanScode }]);
        else
        this.router.navigate(['/login', { token: accessToken }]);
      }
    }).catch((err: any) => {
      alert('มีปัญหาบางอย่างกับแอปพลิเคชัน Liff โปรดลองอีกครั้งในภายหลัง '+err.message)
      console.log(err)
      this.bsService.notifyError(err)
    }
      )
    
  }

  onClickFailedScanModal() {
    this.failedScanModal.show();
  }
  closeFailedScanModal() {
    this.failedScanModal.hide();
  }
}
