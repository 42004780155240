import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationService } from "../../navigation.service";

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {

  constructor(private _location: Location, private navigation: NavigationService) { }

  ngOnInit(): void {
  }

  goBack() {
    this._location.back();
    // this.navigation.back();
  }

}
