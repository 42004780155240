<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer glucernaContainer glucerna-cereal">
    <header class="traceabilityHeader bgPrimary-glucerna-cereal">
     <img class="homeLogo" routerLink="/glucernaplus-traceability" src="../../../assets/images/home_logo_white.png">
     <img  routerLink="/glucernaplus-traceability" src="../../../assets/images/glucerna/logo1.png">
      </header>

  <div class="heading-icon mt-5">
    <img class="w-100" src="../../../assets/images/glucerna/gulecerna-manufacturer-image.png" >

  </div>
  <div class="grd-box mt-3 text-center">
   <div class="grd-inner-div"> <span>ประเทศผู้ผลิต : {{manufactCountry}} <br>
    วันที่ผลิต {{manufacturedDate}}</span></div>
  </div>
  <div class="contentWrapper">
    <div class="innerContent">
        <div class="text-type-24 text-left pt-5">
            เราภูมิใจที่ได้เป็นโรงงานแห่งแรกในประเทศสเปนที่ได้
            รับรางวัล <span>Shingo Prize</span> ซึ่งเป็นมาตรฐานระดับโลก
            สำหรับความเป็นเลิศขององค์กรในปี ค.ศ. 2018
            
        
          </div>
    
          <div>
            <img class="w-100 mt-3" src="../../../assets/images/glucerna/manufact2.png">
          </div>
          <div class="text-type-22 mt-4 text-left">
            โรงงานผลิตของแอ๊บบอตในประเทศสเปน มีหน่วยงานสำหรับการวิจัย
และพัฒนาที่ทันสมัย และมีการทำงานร่วมกันตลอดสายการผลิต
เพื่อให้ได้ผลิตภัณฑ์ที่มีคุณภาพ
          </div>
    </div>
    
    <div class="text-type-10 mt-4 text-uppercase">TH.2022.27291.GLU.1(v1.0)©2022Abbott </div>
  </div>
  
</section>
<app-app-glucerna-plus-cereal-nav></app-app-glucerna-plus-cereal-nav>
