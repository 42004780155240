import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-mobile-register',
  templateUrl: './mobile-register.component.html',
  styleUrls: ['./mobile-register.component.scss']
})
export class MobileRegisterComponent implements OnInit {
  registerFormMobile: FormGroup;
  registerOtpForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.registerFormMobile = this.formBuilder.group({
     
      mobileNumber: ['', [Validators.required,  Validators.pattern("^((\\+63-?)|0)?[0-9]{9,10}$")]],
    })
  }

  get regFormVal() {
    return this.registerFormMobile.controls;
  }

  onRegisterFormSubmit() {
    this.submitted = true;  
    if (this.registerFormMobile.invalid) {
      return;
    }
  }
}
