import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pediasureplus-product',
  templateUrl: './pediasureplus-product.component.html',
  styleUrls: ['./pediasureplus-product.component.scss']
})
export class PediasureplusProductComponent implements OnInit {

  constructor() {
    window.scrollTo({ top: 0 });
   }

  ngOnInit(): void {
  }

}
