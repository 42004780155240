import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-traceability-triple-plus',
  templateUrl: './traceability-triple-plus.component.html',
  styleUrls: ['./traceability-triple-plus.component.scss']
})
export class TraceabilityTriplePlusComponent implements OnInit {

  constructor() { 
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
  }

}
