<div class="ensureFamilyClubHeader text-center">
  <img class="" src="../../../assets/images/ensure-aw/my_family_club_nav.png" />
  <div class="scanPoints mt-2">
    <p class="mb-0" (click)="onclickScanToCollect()">คลิกเพื่อสแกนสะสมคะแนน</p>
  </div>
</div>

<!-- pop after click to scan to collect -->
<div
  bsModal
  #popupModalOne="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <section class="text-center">
          <button
            type="button"
            class="close"
            aria-label="Close"
            routerLink="/qr-scanner"
          >
            <img src="../../../assets/images/close.png" />
          </button>
          <h5 class="primaryBlue mt25 primaryTitle">วิธีการสแกน QR Code</h5>
          <h5 class="secondaryBlue secondaryTitle mt30">
            1. ผลิตภัณฑ์แบบกล่องสแกน QR Code <br />
            ที่บนฝากล่องด้านใน
          </h5>
          <!-- <h5 class="secondaryBluee mt30">{{ 'FORGOT_MODAL1.TITLE3' | translate }}</h5> -->
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="mt-2">
                <img
                  class="qrimg"
                  src="../../../assets/images/qr1.png"
                />
              </div>
            </div>
          </div>
          <h5 class="secondaryBlue secondaryTitle mt30">
            2. สแกน QR Code ที่ใต้กระป๋อง<br/> เพื่อสะสมคะแนน
          </h5>
          <!-- <h5 class="secondaryBluee mt30">{{ 'FORGOT_MODAL1.TITLE3' | translate }}</h5> -->
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="mt-2">
                <img
                  class="qrimg"
                  src="../../../assets/images/popupqrcode.png"
                
                />
              </div>
            </div>
          </div>
          <div class="d-flex flex-row bd-highlight mt-3">
            <div class="p-2 bd-highlight">
              <input
                type="checkbox"
                class="p-2 bd-highlight"
                [(ngModel)]="isNotChecked"
                (change)="
                  checkValue(isNotChecked ? 'isChecked' : 'isNotChecked')
                "
              />
            </div>
            <div class="p-2 bd-highlight">
              <span class="p-1 bd-highlight">ไม่ต้องแสดงหน้าต่างนี้อีก</span>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-block goldenBtn mt-3 mb10"
            routerLink="/qr-scanner"
          >
            เริ่มสแกน
          </button>
        </section>
      </div>
    </div>
  </div>
</div>
