import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as ScanditSDK from "scandit-sdk";
import { BarcodePicker, ScanResult, configure } from "scandit-sdk";
import { ApiService } from '../../../../src/app/services/api.service';
import { Location } from '@angular/common';
import { BugsnagService } from 'src/app/services/bugsnag.service';
declare var ZXing: any;
import { environment as env } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-scanner',
  templateUrl: 'scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit {
  user: any;
  userPoints: any;
  showProfile: Boolean = true;
  showScanner: boolean = true;
  loader: boolean = true;

  iSprintData: any;
  image: string;
  customerName: any;
  suspiciousCount = 0;
  prodImage: any;

  categoryCode: any;
  scancodeData: any;
  scanCode: any;
  pointsEarned: any;
  videoStart = false;
  tabId = 'ensure-tab';
  latitude: string;
  longitude: string;
  confidence: string;
  public scannerReady = false;
  public showButton = false;
  public showDescription = true;
  public result = "";
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };
  @ViewChild("barcodePicker") barcodePickerElement: ElementRef<HTMLDivElement & { barcodePicker: BarcodePicker }>;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('successScanModal', { static: true })
  successScanModal: ModalDirective;
  @ViewChild('failedScanModal', { static: true })
  failedScanModal: ModalDirective;
  @ViewChild('camerapermission', { static: true }) camerapermission: ModalDirective;



  constructor(
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private location: Location,
    private bsService: BugsnagService
  ) {
this.onClickScanner()
  }

  ngOnInit(): void {
    this.apiService.getPosition().then((pos: any) => {
      let myuuid = uuidv4();
      let loggerFormData = {
        unique_id: myuuid,
        message: JSON.stringify(pos),
      };
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
    }).catch((err) => {
      this.bsService.notifyError(err);
    });
  }

  ontabChange(ids: any) {
    this.tabId = ids;
  }

  onClickScanner() {
    //this.popupModalOne.hide();
    this.showScanner = true;
    this.showProfile = false;

    let selectedDeviceId;
    const codeReader = new ZXing.BrowserMultiFormatReader();
    console.log('ZXing code reader initialized');
    codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        const sourceSelect = document.getElementById('sourceSelect');
        selectedDeviceId = videoInputDevices[0].deviceId;
        codeReader.decodeFromVideoDevice(undefined, 'video', (result, err) => {
          if (result) {
            let data = result.text;
            console.log(data, 'scanned data===');
            let array = data.split('=');
            let scannedCode = array[1];
            this.scanCode = scannedCode;
            codeReader.reset();
            console.log(scannedCode, ' scannedCode');
            sessionStorage.setItem('scanCode', this.scanCode);
            sessionStorage.setItem('isQRScannedFrmCam', 'true');
            console.log(result, 'scanCode first');
            this.router.navigate(['/home'])
            
          }

          if (err && !(err instanceof ZXing.NotFoundException)) {
            console.error(err);
            document.getElementById('result').textContent = err;
          }
        });
        console.log(
          `Started continous decode from camera with id ${selectedDeviceId}`
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  closeScanner() {
    this.showScanner = false;
    this.location.back();
  }

  onClickSuccessScanModal() {
    this.successScanModal.show();
  }
  closeSuccessScanModal() {
    this.successScanModal.hide();
  }
  onClickFailedScanModal() {
    this.failedScanModal.show();
  }
  closeFailedScanModal() {
    this.failedScanModal.hide();
    this.closeScanner();
  }





  parseFunction(value) {
    return JSON.parse(value);
  }
}


