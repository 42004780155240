<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pb150 ensureProduct">
    <app-ensure-header></app-ensure-header>
    <img class="mt40" src="../../../../assets/images/ensure/ensureproduct-topimage.png">
    <div class="topTextBlock px15">
        <h1>อาหารสูตรครบถ้วน</h1>
        <div class="whiteBorderLine my20"></div>
        <h3 class="mb-0">ให้สารอาหารที่ร่างกายต้องการ พร้อมวิตามิน แร่ธาตุ และใยอาหาร</h3>
        <h1>ช่วยให้ร่างกายแข็งแรง</h1>
    </div>
    <div class="ImgBlock px25">
        <br />
        <img class="mt10 w-65" src="../../../../assets/images/ensure/ensure-product-image.png">
        <h2 class="primaryDesc mt-3 mb-0">มีสารอาหารครบถ้วน</h2>
        <img class="mt30" src="../../../../assets/images/ensure/types-image.png">
        <h2 class="primaryDesc mt-3 mb-0"> ประกอบด้วยโปรตีน 3 ชนิด</h2>
        <h3 class="secondaryDesc">เวย์ เคซีน และโปรตีนสกัดจากถั่วเหลือง โปรตีนมีส่วนช่วยเสริมสร้าง และคงสภาพของ
            <span class="goldenText">มวลกล้ามเนื้อ
            </span>
        </h3><br />
        <img class="" src="../../../../assets/images/ensure/ensure-fos.png">
        <h2 class="primaryDesc mb-0">ใยอาหาร</h2>
        <br/><br/>
        <img class="" src="../../../../assets/images/ensure/ensure-vitamins.png">
        <h2 class="primaryDesc mb-0">มีวิตามินบี6, วิตามินบี12,</h2>
        <h2 class="primaryDesc mb-0"> วิตามินซี, เหล็ก</h2>
        <h3 class="secondaryDesc">มีส่วนช่วยในการทำหน้าที่ <br/>ตามปกติของ                         
            <span class="goldenText">ระบบภูมิคุ้มกัน
            </span>
        </h3>
        <img class="mt40" src="../../../../assets/images/ensure/vitamin28.png">
        <h2 class="primaryDesc mb-0">พร้อมวิตามิน </h2>
        <h2 class="primaryDesc">และแร่ธาตุ 28 ชนิด</h2>
        <img class="mt40" src="../../../../assets/images/ensure/ensure-gold-product-badge.png">
    </div>
    <div class="medicalFoodBlock mt40">
        <h3>อาหารทางการแพทย์<br /> ต้องใช้ภายใต้คำแนะนำของแพทย์</h3>
        <p>*การคำนวณส่วนแบ่งการตลาดของ Abbott Laboratories Limited ส่วนหนึ่งอ้างอิงจาก
            ข้อมูลดัชนีค้าปลีกรายเดือนที่รายงานโดย Nielsen สำหรับตลาดนมผงสูตรสารอาหาร
            ครบถ้วนตามคำนิยามของบริษัท Abbott Laboratories ในช่วงเวลา ตั้งแต่ 1 มกราคม 2560
            สิ้นสุด ณ เดือน กันยายน 2562 ซึ่งเป็นข้อมูลของตลาดในประเทศไทย ซึ่งเป็นลิขสิทธิ์ของ
            Nielsen (Copyright ©2019, Nielsen)</p>
            <p class="text-right mb-0">ใบอนุญาตโฆษณาเลขที่ ฆอ. 3821/2564</p>
    </div>
    <!-- <img class="w-100" src="../../../../assets/images/ensure/ensure-bottom-product.png"> -->
    <iframe
      width="100%"
      height="400px"
      src="https://www.youtube.com/embed/RyZ8SQlcoM4"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

</section>
<app-ensure-nav></app-ensure-nav>