<div class="ensure-qc-container">
  <app-ensure-family-club></app-ensure-family-club>

  <div class="ensure-logo-section">
    <img
      class="img-fluid"
      src="../../../../assets/images/ensure-aw/vector-smart-object.png"
      alt="Ensure brand"
    />
    <a
      ><img
        class="img-fluid ensure-home-icon"
        src="../../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
        alt="Ensure Home" routerLink="/ensureplantbased-home"
    /></a>
  </div>

  <div class="ensure-content-section">
    <div class="ensure-img">
      <img
      class="d-block mw-100"
      src="../../../../assets/images/ensure-aw/qcimg.png"
      alt="manufacture"
    />
    </div>
    <div class="prod-details">
      <span>วันหมดอายุ : {{expiryDate}}</span>
    </div>
    <p class="pb-3 txt-sm">
      สำหรับแอ๊บบอตการควบคุมคุณภาพในกระบวนการผลิตถือเป็นสิ่งสำคัญที่สุด
      เพื่อให้มั่นใจได้ว่าบริษัทฯ จะสามารถผลิตสินค้าที่มีคุณภาพและมีความปลอดภัยอยู่เสมอ
    </p>
    <img
      class="img-fluid"
      src="../../../../assets/images/ensure-gold/ensure-gold-qc-img2.png"
      alt="Abbott"
    />
    <p class="pt-4 txt-sm">
      ตั้งแต่ขั้นตอนการรับวัตถุดิบสำหรับใช้ในการผลิตสินค้าจนกระทั่งการผลิตสินค้าออกจากโรงงาน ในทุกขั้นตอนของการผลิตได้ผ่านการตรวจสอบอย่างเข้มงวดโดยผู้เชี่ยวชาญด้านการประกันคุณภาพที่ผ่านการฝึกอบรม พร้อมด้วยการใช้อุปกรณ์การตรวจสอบที่ทันสมัย
    </p>
  </div>

  <div class="copyright-details text-uppercase">
    <p> TH.2022.34295.ENS.1 (v1.0) ©2023Abbott</p>
  </div>
</div>
<app-ensure-aw-menu></app-ensure-aw-menu>
