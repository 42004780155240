<ul class="traceabilityNav">
    <li [ngClass]="{'activeClassGlu': activeManufacturing}" routerLink="/glucerna-manufacturing">
        <figure>
            <img src="../../../../assets/images/glucerna/homeico.png"alt="home" />
        </figure>
        <p>มาตรฐาน<br/> การผลิตสินค้า</p>
    </li>
    <li [ngClass]="{'activeClassGlu': activeQualityControl}" routerLink="/glucerna-qualitycontrol">
        <figure>
            <img src="../../../../assets/images/glucerna/searchico.png" alt="search" />
        </figure>
        <p>การควบคุม<br/>
            คุณภาพสินค้า</p>
    </li>
    <li [ngClass]="{'activeClassGlu': activeBranded}" routerLink="/glucerna-product">
        <figure>
            <img src="../../../../assets/images/glucerna/glassico.png" alt="glass" />
        </figure>
        <p>รู้จัก<br />
            กลูเซอนา</p>
    </li>
    <li [ngClass]="{'activeClassGlu': activeTriplePlus}" routerLink="/glucerna-mixinstruction">
        <figure>
            <img src="../../../../assets/images/glucerna/scoopico.png" alt="triple plus" />
        </figure>
        <p>วิธีเตรียม<br /> 
            กลูเซอนา</p>
    </li>
    <li [ngClass]="{'activeClassGlu': activeLogIn}" (click)="skipTrans()">
        <a>
        <figure class="familyClub">
            <img src="../../../assets/images/my_family_club_nav.png" alt="my family club" />
        </figure>
        <p>มาย <br />
            แฟมิลี่ คลับ</p>
        </a>
    </li>
</ul>
