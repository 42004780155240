import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment as env } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import liff from '@line/liff';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-ensure-aw-menu',
  templateUrl: './ensure-aw-menu.component.html',
  styleUrls: ['./ensure-aw-menu.component.scss']
})
export class EnsureAwMenuComponent implements OnInit {
  activePath: any;
  activeLogIn: boolean = false;
  activeQualityControl: boolean = false;
  activeBranded: boolean = false;
  activeManufacturing: boolean = false;
  activeTriplePlus: boolean = false;
  scanCode: string;

  constructor(private route: ActivatedRoute,private apiService: ApiService,
    @Inject(DOCUMENT) private document: Document,private router: Router) {
    this.activePath = this.route.snapshot.routeConfig.path;
  }

  ngOnInit(): void {
    if (this.activePath === 'ensureplantbased-manufacturing') {
      this.activeManufacturing = true;
    }
    if (this.activePath === 'ensureplantbased-quality-control') {
      this.activeQualityControl = true;
    }
    if (this.activePath === 'ensureplantbased-branded') {
      this.activeBranded = true;
    }
    if (this.activePath === 'ensureplantbased-instruction') {
      this.activeTriplePlus = true;
    }
    if (this.activePath === 'login') {
      this.activeLogIn = true;
    }
  }

  skipTrans() {
    sessionStorage.setItem('skipTrans', 'skiptrans');
    this.scanCode = 'xyz'
    let iSprintData:any = JSON.parse(sessionStorage.getItem('iSprintData'));
    // changes done for gpass
    if(iSprintData.is_isprint == false) this.scanCode = 'xyz'
      let loc = `${env.LIFF_URL}?scancode=${this.scanCode}`
      liff.openWindow({
        url: loc,
        external: false,
      })
  }
}
