<div class="ensure-manufacture-container">
  <app-ensure-family-club></app-ensure-family-club>

  <!-- Singapore section -->
   <div class="ensuregold-singapore" *ngIf="manufactCountry == 'สิงคโปร์' || manufactCountry == 'Singapore' || manufactCountry == 'SINGAPORE' || manufactCountry == 'singapore'">
    <div class="ensure-logo-section">
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/ensure-logo.webp"
        alt="Ensure brand"
      />
      <a
        ><img
          class="img-fluid ensure-home-icon"
          src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
          alt="Ensure Home"
          routerLink="/ensuregold-traceability"
      /></a>
    </div>

    <div class="ensure-content-section">
      <img
        class="d-block mw-100"
        src="../../../assets/images/ensure-gold/Ensure-gold-manufacture-home.png"
        alt="manufacture"
      />
      <div class="prod-details">
        <span class="mb-0">ประเทศผู้ผลิต : {{ manufactCountry }}</span>
        <span>วันที่ผลิต {{ manufacturedDate }}</span>
      </div>
      <p class="pb-3">
        เราภูมิใจที่เป็นโรงงานแห่งแรกในเอเชียที่ได้รับรางวัล
        <span class="light-yellow">Shingo Prize</span>
        ซึ่งเป็นมาตรฐานระดับโลก สำหรับ ความเป็นเลิศขององค์กรในปี ค.ศ. 2019
      </p>
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/Ensure-gold-abbott-img.jpg"
        alt="Abbott"
      />
      <p class="pt-4 txt-sm">
        โรงงานผลิตของแอ๊บบอต ในประเทศสิงคโปร์ ได้ใช้เทคโนโลยีการผลิต แบบระบบปิด
        ซึ่งเป็นระบบการควบคุมอัตโนมัติ และมีห้องปฏิบัติการ
        สำหรับทำการวิจัยผลิตภัณฑ์ของแอ๊บบอตที่ได้มาตรฐาน เพื่อให้ได้
        ผลิตภัณฑ์ที่มีคุณภาพและสอดคล้องตามเกณฑ์มาตรฐานสากล
      </p>
    </div>

    <div class="copyright-details text-uppercase">
      <p>TH.2022.27390.ENS.1(v1.0)©2022Abbott</p>
    </div>
  </div> 

  <!-- Netherlands section -->

  <div class="ensuregold-netherlands" *ngIf="manufactCountry == 'สเปน' || manufactCountry == 'Spain' || manufactCountry == 'SPAIN' || manufactCountry == 'spain'">
    <div class="ensure-logo-section">
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/ensure-logo.webp"
        alt="Ensure brand"
      />
      <a
        ><img
          class="img-fluid ensure-home-icon"
          src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
          alt="Ensure Home"
          routerLink="/ensuregold-traceability"
      /></a>
    </div>

    <div class="ensure-content-section">
      <img
        class="d-block mw-100"
        src="../../../assets/images/ensure-gold/Ensure-gold-manufacture-home.png"
        alt="manufacture"
      />
      <div class="prod-details">
        <span class="mb-0">ประเทศผู้ผลิต : {{ manufactCountry }}</span>
        <span>วันที่ผลิต {{ manufacturedDate }}</span>
      </div>
      <p class="pb-3">
        เราภูมิใจที่เป็นโรงงานแห่งแรกในประเทศสเปนที่ได้รับ รางวัล
        <span class="light-yellow">Shingo Prize</span>
        ซึ่งเป็นมาตรฐานระดับโลก สำหรับความเป็นเลิศขององค์กรในปี ค.ศ. 2018
      </p>
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/Ensure-gold-netherlands-img-01.png"
        alt="Abbott"
      />
      <p class="pt-4 txt-sm">
        โรงงานผลิตของแอ๊บบอตในประเทศสเปน มีหน่วยงานสำหรับการวิจัย
        และพัฒนาที่ทันสมัย และมีการทำงานร่วมกันตลอดสายการผลิตเพื่อ
        ให้ได้ผลิตภัณฑ์ที่มีคุณภาพ
      </p>
    </div>

    <div class="copyright-details text-uppercase">
      <p>TH.2022.27390.ENS.1(v1.0)©2022Abbott</p>
    </div>
  </div>
</div>
<app-ensure-gold-cereal-nav></app-ensure-gold-cereal-nav>
