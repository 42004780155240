import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';
@Component({
  selector: 'app-pediasureplus-qc',
  templateUrl: './pediasureplus-qc.component.html',
  styleUrls: ['./pediasureplus-qc.component.scss'],
})
export class PediasureplusQcComponent implements OnInit {
  productData: any;
  expiryDate: any;
  constructor(
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
    this.getTracebilityContent();
  }
  getTracebilityContent() {
    this.productData = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productData);
    this.expiryDate = this.productData.expiry_date;
  }
}
