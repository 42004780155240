import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensure-aw-mixinstruction',
  templateUrl: './ensure-aw-mixinstruction.component.html',
  styleUrls: ['./ensure-aw-mixinstruction.component.scss']
})
export class EnsureAwMixinstructionComponent implements OnInit {

  constructor() {
    window.scrollTo({ top: 0 });
   }

  ngOnInit(): void {
  }

}
