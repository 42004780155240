<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer triple">
  <app-traceability-header></app-traceability-header>
  <div class="triplePlusContainer">
    <p class="title-txt">
      วิธีเตรียม
      <span>พีเดียชัวร์</span>
    </p>

    <div class="banner-section">
      <img
        src="../../../assets/images/pediasure/updated-images/mixin-img-1.png"
      />
      <p>เตรียมน้ำสุกที่เย็นแล้ว 1 แก้ว <br />(190 มล.)</p>
    </div>

    <img
      class="arrow-img"
      src="../../../assets/images/pediasure/updated-images/arrow-img.png"
    />

    <div class="banner-section">
      <img
        src="../../../assets/images/pediasure/updated-images/mixin-img-2.png"
      />
      <p>ตักพีเดียชัวร์<br />
        5 ช้อนตวงปาด</p>
    </div>

    <img
      class="arrow-img"
      src="../../../assets/images/pediasure/updated-images/arrow-img.png"
    />

    <div class="banner-section">
      <img
        src="../../../assets/images/pediasure/updated-images/mixin-img-3.png"
      />
      <p>คนให้เข้ากัน<br />
        จะได้ส่วนผสม 225 มล.</p>
    </div>

    <div class="bg-bottom">
        <img src="../../../assets/images/pediasure/updated-images/bg-mixin-bottom.png" />
        <div class="content">

            <a href="#">การเก็บรักษา</a>

            <ul>
                <li>• เมื่อเปิดซองแล้ว ควรเก็บในภาชนะที่สะอาด และปิดฝา
                    ให้แน่นทุกครั้ง แล้วเก็บในที่แห้งและเย็น ไม่ควรเก็บในตู้เย็น
                    และควรบริโภคให้หมดภายใน 3 สัปดาห์</li>
                    <li>• หลังจากผสมน้ำแล้วควรดื่มทันที หากดื่มไม่หมดควรเก็บ
                        ไว้ในภาชนะปิดฝา เก็บในตู้เย็น ใช้ให้หมดภายใน 24 ชั่วโมง</li>
            </ul>
            <p class="small text-uppercase">TH.2022.26826.PDS.1(v1.0)©2022Abbott</p>
        </div>
    </div>
    <!-- <div class="triplePlus">
      

      <figure>
        <img
          src="../../../assets/images/triplepluspedia.png"
          alt="triple plus"
        />
      </figure>
      <div class="container">
        <div class="mb-3">
          <div class="row g-2">
            <div class="col-4">
              <img
                src="../../../assets/images/triple_plus_img1.png"
                alt="triple plus"
              />
            </div>
            <div class="col-8">
              <h5>{{ "TRIPLE_PLUS.FULL_OF_NUTRIENTS" | translate }}</h5>
              <h4>{{ "TRIPLE_PLUS.TO_GROW" | translate }}</h4>
              <p>
                {{ "TRIPLE_PLUS.FROM_28_VITAMINS_AND_MINERALS" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col-4">
              <img src="../../../assets/images/tp_mid.png" alt="triple plus" />
            </div>
            <div class="col-8">
              <h4>
                <span>{{ "TRIPLE_PLUS.HAVE" | translate }}</span>
                {{ "TRIPLE_PLUS.3_QUALITY_PROTEINS" | translate }}
              </h4>
              <p>{{ "TRIPLE_PLUS.IN_ORDER_FOR_THE_BODY" | translate }}</p>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col-4">
              <img
                src="../../../assets/images/triple_plus_img2.png"
                alt="triple plus"
              />
            </div>
            <div class="col-8">
              <h4>
                <span>{{ "TRIPLE_PLUS.HAVE" | translate }}</span>
                {{ "TRIPLE_PLUS.FRUCTO" | translate }}
              </h4>
              <p>{{ "TRIPLE_PLUS.DIETARY" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <h1>
        {{ "TRIPLE_PLUS.A_UNIQUE" | translate }}
        <span>{{ "TRIPLE_PLUS.COMPLETE_RECEIPES" | translate }}</span>
      </h1>
    </div> -->
    <!-- <figure class="triplePlusImg1">
      <img
        src="../../../assets/images/triple_plus_img4.png"
        alt="triple plus"
      />
    </figure> -->
    <!-- <figure class="triplePlusImg2">
      <img
        src="../../../assets/images/triple_plus_img5.png"
        alt="triple plus"
      />
    </figure> -->
    <!-- <p class="adviseMonth">{{ "TRIPLE_PLUS.ADVICE_THE_MOTHER" | translate }}</p> -->
  </div>
</section>
<app-traceability-nav></app-traceability-nav>
