<section class="temp-landing">
<div>
        <figure>
            <img src="../../../assets/images/abbott.svg" class="float-left icon-size">
            <!-- <img src="../../../assets/images/close.png" class="float-right  cancel" (click)="goback()"/> -->
        </figure>
    <div>
        <figure>
            <img src="../../../assets/images/loginlogo.png" alt="my family club" />
        </figure>      
        <p>{{ 'TEMP_LANDING.HEADER' | translate}}</p>
        <p>{{ 'TEMP_LANDING.CONTENT' | translate}}</p>
    </div>
</div>
</section>
