<div class="ensure-prod-container">
  <app-ensure-family-club></app-ensure-family-club>

  <div class="ensure-logo-section">
    <img
      class="img-fluid"
      src="../../../assets/images/ensure-gold/ensure-logo.webp"
      alt="Ensure brand"
    />
    <a
      ><img
        class="img-fluid ensure-home-icon"
        src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
        alt="Ensure Home"
        routerLink="/ensuregold-traceability"
    /></a>
  </div>

  <div class="product-section">
    <img
      src="../../../assets/images/ensure-gold/ensure-gold-hmb-logo.png"
      alt="manufacture"
    />

    <p class="ensure-desc">
      อาหารสูตรครบถ้วนเสริม เอช เอ็ม บี พร้อมวิตามิน แร่ธาตุ และใยอาหาร เอนชัวร์
      โกลด์ <strong>ช่วยให้ร่างกายแข็งแรง</strong>
    </p>

    <img
      class="img-fluid"
      src='../../../assets/images/ensure-gold/ensure_gold_packshot.png'
      alt="Abbott"
    />
    <h3 class="ensure-title">มีสารอาหารหลากหลาย</h3>

    <div class="hmb-section">
      <img
        class="img-85"
        src="../../../assets/images/ensure-gold/ensure-hmb-img1.png"
      />
      <span>+</span>
      <img
        class="img-85"
        src="../../../assets/images/ensure-gold/ensure-hmb-img2.png"
      />
    </div>

    <div class="hmb-content-section">
      <p>
        <span class="txt-light">HMB</span>
        สารสังเคราะห์มาจากกรดอะมิโนลิวซีน<br />
        <span class="fs-38"> + โปรตีนสูงขึ้น</span> <span class="fs-48"> 23%*</span> <br />
        <span class="fs-12"
          >*ปริมาณโปรตีน 10.5 กรัมต่อน้ำ 230 มิลลิลิตร เทียบกับสูตรเดิมมี 8.55
          กรัมต่อน้ำ 230 มิลลิลิตร</span
        ><br />
        <span class="fs-45"> โปรตีน</span> มีส่วนช่วยเสริมสร้างและ
        คงสภาพของ<span class="fs-45">มวลกล้ามเนื้อ</span>
      </p>
    </div>

    <div class="protein-section">
      <img
        src="../../../assets/images/ensure-gold/ensure-v10.png"
        alt="Protein"
      />
      <p class="line-height-30">
        วิตามินและแร่ธาตุ <span class="fn-28">10</span> ชนิด**
        <br />มีส่วนช่วยในการทำหน้าที่ตามปกติของ<br />
        <span class="fn-28">ระบบภูมิคุ้มกัน</span><br />
      </p>
      <p class="mt-0 mb-55 line-height-20">
        <span class="fs-15"
          >**วิตามินเอ บี6 บี12 ซี ดี กรดโฟลิค เหล็ก สังกะสี ทองแดง
          และซีลีเนียม</span
        >
      </p>

      <img
        src="../../../assets/images/ensure-gold/ensure-v34.png"
        alt="Vitamin"
      />
      <p class="text-type-34">
        มีส่วนประกอบของวิตามิน <br />
        และเกลือแร่<span class="fs-42"> 34 </span>ชนิด
      </p>
    </div>
  </div>
  <div class="how-to-use">
    <p>อาหารทางการแพทย์ ต้องใช้ภายใต้คำแนะนำของแพทย์</p>
    <p class="copyright-details">ใบอนุญาตโฆษณาเลขที่ ฆอ. 1762/2565</p>
  </div>
</div>

<div class="promo-video">
  <div class="embed-responsive embed-responsive-16by9">
    <iframe
      class="promo-video-link"
      width="560"
      height="315"
      src="https://www.youtube.com/embed/YASdffMfs2U"
      title="เปิดตัวแล้ว! HMB มิติใหม่ของเอนชัวร์ โกลด์"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>

<app-ensure-gold-cereal-nav></app-ensure-gold-cereal-nav>
