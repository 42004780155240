<div class="loderData" *ngIf="loader">
  <div class="loader"></div>
</div>
<section  *ngIf="!loader" class="globalContainerOut">
  <section class="loginContainerOut">
    <section class="topBlockOut">
      <div class="container bg-transparent">
        <div class="row">
          <div class="col-2">
            <img
              src="../../../assets/images/abbott.svg"
              alt="abbott"
              class="logo"
            />
          </div>
        </div>
      </div>
      <div class="row h-100 align-items-center w-100 m-0">
        <div class="col-12 text-center">
          <img class="img-fluid" src="../../../assets/images/loginlogo.png" />
        </div>
      </div>
    </section>
    <section class="middleBlock">
      <form
        [formGroup]="loginForm"
        class="formContainer container px-5 mt100"
        (ngSubmit)="onLoginFormSubmit()"
      >
        <div class="form-group">
          <img class="fieldImg" src="../../../assets/images/smartphone.png" />
          <input
            type="number"
            class="form-control"
            placeholder="{{ 'LOGIN.PLACEHOLDER1' | translate }}"
            formControlName="mobileNumber"
            (change)="enteredMobnum($event)"
            [ngClass]="{
              'is-invalid': submitted && loginFromVal.mobileNumber.errors
            }"
          />
          <div
            *ngIf="submitted && loginFromVal.mobileNumber.errors"
            class="invalid-feedback"
          >
            <div *ngIf="loginFromVal.mobileNumber.errors.required">
              Enter Number
            </div>
            <div
              *ngIf="
                loginFromVal.mobileNumber.errors.pattern ||
                loginFromVal.mobileNumber.errors.pattern
              "
            >
              หมายเลขโทรศัพท์มือถือไม่ถูกต้อง
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <img class="fieldImg" src="../../../assets/images/padlock.png" />
          <input
            type="password"
            class="form-control"
            placeholder="{{ 'LOGIN.PLACEHOLDER2' | translate }}"
            formControlName="password"
            (input)="passwordCheck($event)"
            [ngClass]="{
              'is-invalid':
                (submitted && loginFromVal.password.errors) || invalidPassword
            }"
          />
          <div
            *ngIf="
              (submitted && loginFromVal.password.errors) || !invalidPassword
            "
            class="invalid-feedback"
          >
            <div *ngIf="loginFromVal.password.errors">Enter Password</div>
          </div>
          <div *ngIf="invalidPassword" class="invalid-feedback">
            <div>รหัสผ่านไม่ถูกต้อง</div>
          </div>
        </div>
        <div class="text-right">
          <a
            class="text-right lightBrown underlinedText"
            (click)="onClickForgotPassword()"
            >{{ "LOGIN.FORGOT_LINK" | translate }}</a
          >
        </div>

        <button type="submit" class="btn btn-block goldenBtn mt-3 mb-3">
          {{ "LOGIN.BTN_TEXT" | translate }}
        </button>
        <div class="row">
          <div class="col-6 text-left">
            <a class="lightBrown underlinedText" routerLink="/register/11">{{
              "LOGIN.BOTTOM_TEXT_LEFT" | translate
            }}</a>
          </div>
          <div class="col-6 text-right">
            <!-- <a class="text-right lightBrown"> {{ 'LOGIN.BOTTOM_TEXT_RIGHT' | translate }}</a> -->
          </div>
        </div>
      </form>
    </section>
  </section>
</section>

<!-- forgot mobile number field modal -->
<div
  bsModal
  #forgotModalOne="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <form [formGroup]="forgotMobileForm" class="formContainer">
          <section class="text-center">
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="closeForgotModalOne()"
            >
              <img src="../../../assets/images/close.png" />
            </button>
            <h5 class="primaryBlue mt25 primaryTitle">
              {{ "FORGOT_MODAL1.TITLE1" | translate }}
            </h5>
            <h5 class="secondaryBlue secondaryTitle mt30">
              {{ "FORGOT_MODAL1.TITLE2" | translate }}
            </h5>
            <!-- <h5 class="secondaryBluee mt30">{{ 'FORGOT_MODAL1.TITLE3' | translate }}</h5> -->
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="form-group mt-4">
                  <img
                    class="fieldImg"
                    src="../../../assets/images/smartphone.png"
                  />
                  <input
                    type="number"
                    class="form-control"
                    placeholder="เบอร์โทรศัพท์มือถือ"
                    formControlName="mobileNumber"
                    (input)="mobileNoCheck($event)"
                    [ngClass]="{
                      'is-invalid':
                        submittedMobileForm &&
                        forgotFormOneVal.mobileNumber.errors
                    }"
                  />
                  <div
                    *ngIf="
                      submittedMobileForm &&
                      forgotFormOneVal.mobileNumber.errors &&
                      !userNotFound
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="forgotFormOneVal.mobileNumber.errors.required"
                      class="text-left"
                    >
                      Enter Number
                    </div>
                    <div
                      class="text-left"
                      *ngIf="forgotFormOneVal.mobileNumber.errors.pattern"
                    >
                      หมายเลขโทรศัพท์มือถือไม่ถูกต้อง
                    </div>
                  </div>
                  <div *ngIf="userNotFound" class="text-danger pl-0 pt-1">
                    ผู้ใช้รายนี้ไม่ได้ลงทะเบียน
                    กรุณาใช้หมายเลขโทรศัพท์มือถือที่ลงทะเบียน
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-block goldenBtn mt50 mb10"
                  (click)="onSubmitForgotFormOne()"
                >
                  {{ "FORGOT_MODAL1.BTN_TEXT" | translate }}
                </button>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- forgot otp field modal -->
<!-- <div bsModal #forgotOtpModal="bs-modal" class="modal fade customModalContainer" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">

                <form [formGroup]="forgotOtpForm" class="formContainer">
                    <section class="text-center">
                        <button type="button" class="close" aria-label="Close" (click)="closeForgotOtpModal()">
                          
                            <img src="../../../assets/images/close.png" />
                        </button>
                        <h5 class="primaryBlue font-weight-bold">{{ 'FORGOT_OTP_MODAL.TITLE1' | translate }}</h5>
                        <h5 class="secondaryBlue mt30">{{ 'FORGOT_OTP_MODAL.TITLE2' | translate }}</h5>
                      
                        <h5>{{ mobileNumWithZero }}</h5>

                       

                        <h5 class="secondaryBlue mt30 mb-4 font-weight-bold">{{ 'FORGOT_OTP_MODAL.TITLE4' | translate }}
                        </h5>
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <ng-otp-input (onInputChange)="onOtpChange($event)"
                                    [config]="{length:6,placeholder:'0',allowNumbersOnly: true}"
                                    [ngClass]="{'is-invalid' : submittedOtpForm  && (otp == undefined || otp.length < 6) || invalidOtp }"
                                    #ngOtpInput>
                                </ng-otp-input>

                                <div *ngIf="submittedOtpForm && forgotOtpFormVal.otp?.errors?.required" class="text-danger pl-0 mt-2">
                                    <div *ngIf="!activeClassButton">ต้องระบุ otp</div>
                                </div>
                                <div *ngIf="invalidOtp" class="text-danger pl-0">
                                    <div>{{ 'FORGOT_OTP_MODAL.OTP_ERROR' | translate }}</div>
                                </div>


                                <p *ngIf="!showResendOtp" class="colorGrey mt-4">{{ 'REG_OTP_MODAL.LINK_TEXT_LEFT' | translate }}
                                    <span class="primaryBlue font-weight-bold" *ngIf="beginTimer">
                                        <countdown #cd [config]="{leftTime: 60,format: 'ss',notify: [ 2, 5 ]}"
                                        (event)="handleEvent($event)"></countdown> {{ 'REG_OTP_MODAL.LINK_TEXT_RIGHT' | translate }}</span></p> 
    
                                    <p *ngIf="showResendOtp" class="mt-4 underlinedText lightBlue" (click)="resendOTP()" (click)="cd.restart()">
                                        {{ 'FORGOT_OTP_MODAL.LINK_TEXT' | translate }}
                                    </p>

                                <button type="submit" class="btn btn-block goldenBtn my-4"
                                    (click)="onSubmitForgotOtpForm()">{{ 'FORGOT_OTP_MODAL.BTN_TEXT' | translate
                                    }}</button>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>
    </div>
</div> -->

<!-- success scan modal -->
<div
  bsModal
  #successScanModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeSuccessScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>{{ "SUCCESS_SCAN_MODAL.SCANNED_SUCCESSFULLY" | translate }}</h4>
        <p>
          {{ "SUCCESS_SCAN_MODAL.CONGRATULATIONS_YOU_RECEIVED" | translate }}
          {{ isprintPoint }} {{ "SUCCESS_SCAN_MODAL.SCORE" | translate }}
        </p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeSuccessScanModal()"
        >
          {{ "SUCCESS_SCAN_MODAL.OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- GPAS Success Modal -->
<div
  bsModal
  #gpasSuccessMdl="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeGpasSuccessScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>สแกนสำเร็จ</h4>
        <p>
          คุณจะได้รับ {{ gpasPoints }} คะแนน <br />
          ระบบจะตรวจสอบและอัปเดตคะแนนภายใน 7 วัน <br />
          กรุณาเก็บใบเสร็จเพื่อเป็นหลักฐาน
        </p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeGpasSuccessScanModal()"
        >
          ตกลง
        </button>
      </div>
    </div>
  </div>
</div>

<!-- failed scan modal -->
<div
  bsModal
  #failedScanModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeFailedScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>{{ "FAILED_SCAN_MODAL.SCAN_FAILED" | translate }}</h4>
        <p>{{ "FAILED_SCAN_MODAL.QR_CODE_ALREADY_IN_USE" | translate }}</p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeFailedScanModal()"
        >
          {{ "FAILED_SCAN_MODAL.TRY_AGAIN" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- user not registered  modal -->
<div
  bsModal
  #userNotRegsitered="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeUserModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>{{ "REGISTER_MODAL.TITLE1" | translate }}</h4>
        <p>
          {{ "REGISTER_MODAL.PARA_DESC1" | translate }}<br />
          {{ "REGISTER_MODAL.PARA_DESC2" | translate }}
        </p>
        <button class="btn btn-block goldenBtn" (click)="validateUserModal()">
          {{ "REGISTER_MODAL.BTN_TEXT" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- exceed limit modal -->
<div
  bsModal
  #exceedLimitModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeExceedLimitModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button> -->
        <h4>ขออภัย</h4>
        <p
          *ngIf="
            catCode == 'Pediasure' ||
            catCode == 'PEDIASURE' ||
            catCode == 'pediasure'
          "
        >
          คุณสแกนผลิตภัณฑ์พีเดียชัวร์ ครบจำนวน 5 ครั้งสำหรับเดือนนี้แล้ว
          กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่ 02-252-2448
        </p>
        <p
          *ngIf="
            catCode == 'Ensure' || catCode == 'ENSURE' || catCode == 'ensure'
          "
        >
          คุณสแกนผลิตภัณฑ์เอนชัวร์ ครบจำนวน 8 ครั้ง สำหรับเดือนนี้แล้ว
          กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่ 02-252-2448
        </p>
        <p
          *ngIf="
            catCode == 'Glucerna' ||
            catCode == 'GLUCERNA' ||
            catCode == 'glucerna'
          "
        >
          คุณสแกนผลิตภัณฑ์กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์ ครบจำนวน 5 ครั้ง
          สำหรับเดือนนี้แล้ว กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่
          02-252-2448
        </p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeExceedLimitModal()"
        >
          ตกลง
        </button>
      </div>
    </div>
  </div>
</div>

<div
          bsModal
          #popupModal="bs-modal"
          class="modal fade px30"
          tabindex="-1"
          role="dialog"
          aria-labelledby="dialog-child-name"
        >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content non-th-popup">
            <div class="modal-body p-4">
              <div class="product-qty row">
                <div class="center p-10">
                  ไม่สามารถสแกน QR Code ได้เนื่องจากไม่ใช่สินค้าไทย
                </div>
                <div class="col-12 mt-3">
                  <button
                    type="submit"
                    class="primaryBtn mt-3 fW500 Btn"
                    (click)="onClickOK()"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
</div>

