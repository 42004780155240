<section class="globalContainer pb-3">
    <app-header-family-club></app-header-family-club>
        <form class="formContainer" [formGroup]="editForm" (ngSubmit)="onEditFormSubmit()">
            <section class="px-4">
                <h5 class="secondaryBlue mt-3 mb-4">{{ 'EDIT_PROFILE.TITLE1' | translate }}</h5>

                <div class="form-group">
                    <!-- name -->
                    <img class="fieldImg" src="../../../assets/images/avatar.png" />
                    <input type="text" class="form-control" id="name" aria-describedby="emailHelp"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER1' | translate }}" formControlName="name"
                        [ngClass]="{'is-invalid': submitted && editFormVal.name.errors}">
                    <div *ngIf="submitted && editFormVal.name.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.name.errors.required">คุณกรอกข้อมูลไม่ครบ
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- surname -->
                    <img class="fieldImg" src="../../../assets/images/avatar.png" />

                    <input type="text" class="form-control" id="surname"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER2' | translate }}" formControlName="surname"
                        [ngClass]="{'is-invalid': submitted && editFormVal.surname.errors}">
                    <div *ngIf="submitted && editFormVal.surname.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.surname.errors.required">คุณกรอกข้อมูลไม่ครบ
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row m-0">
                        <div class="col pl-0">
                            <div class="form-group">
                                <div class="custom-select-box">
                                    <select class="form-control pl-2" 
                                    [ngClass]="{'is-invalid': submitted && editFormVal?.year1?.errors?.required}"
                                    formControlName="year1"
                                    (change)="selectedYear1($event)">
                                        <option value="" disabled selected hidden>{{ 'EDIT_PROFILE.PLACEHOLDER18' | translate }}
                                        </option>
                                        <option *ngFor="let year of yearList1" [value]="year">{{year}}</option>
                                    </select>
                                    <div *ngIf="submitted && editFormVal.year1?.errors?.required" class="invalid-feedback">
                                        <div *ngIf="editFormVal.year1?.errors?.required">คุณกรอกข้อมูลไม่ครบ</div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div class="col px-2">  
                            <div class="form-group">
                                <div class="custom-select-box">
                                    <select class="form-control pl-2" 
                                    [ngClass]="{'is-invalid': submitted && editFormVal?.month1?.errors?.required}"
                                    formControlName="month1"
                                    
                                    (change)="montChanged11($event)"
                                    >
                                        <option value="" disabled selected hidden>{{ 'EDIT_PROFILE.PLACEHOLDER17' | translate }}
                                        </option>
                                        <option value="01">มกราคม	</option>
                                        <option value="02">กุมภาพันธ์</option>
                                        <option value="03">มีนาคม</option>
                                        <option value="04">เมษายน</option>
                                        <option value="05">พฤษภาคม</option>
                                        <option value="06">มิถุนายน</option>
                                        <option value="07">กรกฎาคม</option>
                                        <option value="08">สิงหาคม</option>
                                        <option value="09">กันยายน</option>
                                        <option value="10">ตุลาคม</option>
                                        <option value="11">พฤศจิกายน</option>
                                        <option value="12">ธันวาคม</option>
                                    </select>
                                    <div *ngIf="submitted && editFormVal.month1?.errors?.required" class="invalid-feedback">
                                        <div *ngIf="editFormVal.month1?.errors?.required">คุณกรอกข้อมูลไม่ครบ</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                       
    
                        <div class="col pr-0">
                            <div class="form-group">
                                <div class="custom-select-box">
                                    <select class="form-control pl-2" 
                                    [ngClass]="{'is-invalid': submitted && editFormVal?.birthDay1?.errors?.required}"
                                    formControlName="birthDay1"
                                    (change)="dateChange1($event)">
                                        <option value="" disabled selected hidden>{{ 'EDIT_PROFILE.PLACEHOLDER16' | translate }}
                                        </option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>18</option>
                                        <option>19</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>23</option>
                                        <option>24</option>
                                        <option>25</option>
                                        <option>26</option>
                                        <option>27</option>
                                        <option>28</option>
                                        <option *ngIf="(yearSelected == 2012 && modelMonth1 == 2) || (yearSelected == 2016 && modelMonth1 == 2) || (yearSelected == 2020 && modelMonth1 == 2)">29</option>
                                        <option *ngIf="(yearSelected != 2012 && modelMonth1 == 2) || (yearSelected != 2016 && modelMonth1 == 2) || (yearSelected != 2020 && modelMonth1 == 2)" disabled>29</option>
                                        <option *ngIf="(yearSelected != 2012 && modelMonth1 != 2) || (yearSelected != 2016 && modelMonth1 != 2) || (yearSelected != 2020 && modelMonth1 != 2)">29</option>
                                        <option [disabled]="modelMonth1 == 2">30</option>
                                        <option [disabled]="modelMonth1 == 2 || modelMonth1 == 4 || modelMonth1 == 6 || modelMonth1 == 9 || modelMonth1 == 11">31</option>
                                    </select>
                                    <div *ngIf="submitted && editFormVal.birthDay1?.errors?.required" class="invalid-feedback">
                                        <div *ngIf="editFormVal.birthDay1?.errors?.required">คุณกรอกข้อมูลไม่ครบ</div>
                                    </div>
                                  
                                    <!-- <div *ngIf="submitted" class="text-danger">
                                        <div>คุณกรอกข้อมูลไม่ครบ
                                        </div>
                                    </div> -->
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- mobile number -->
                    <img class="fieldImg" src="../../../assets/images/smartphone.png" />
                    <input type="number" class="form-control" id="mobileNumber"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER3' | translate }}" formControlName="mobileNumber"
                        [ngClass]="{'is-invalid': submitted && editFormVal.mobileNumber.errors}">
                    <div *ngIf="submitted && editFormVal.mobileNumber.errors" class="invalid-feedback">
                        <!-- <div *ngIf="editFormVal.mobileNumber.errors.required">Enter mobilenumber</div> -->
                        <div class="text-left"
                            *ngIf="editFormVal.mobileNumber.errors.pattern ||  editFormVal.mobileNumber.errors.pattern">
                            Invalid Mobile Number
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <img class="fieldImg fieldImgSecondary" src="../../../assets/images/message.png" />
                    <input type="email" class="form-control" id="email"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER4' | translate }}" formControlName="email"
                        [ngClass]="{'is-invalid': submitted && editFormVal.email.errors}">
                    <!-- <div *ngIf="submitted && editFormVal.email.errors && !emailExsistError" class="invalid-feedback">
                        <div *ngIf="editFormVal.email.errors.required">Enter email</div>
                        <div *ngIf="editFormVal.email.errors.pattern">Invalid email format</div>
                    </div> -->
                    <div *ngIf="emailExsistError" class="text-danger pl-0 textsize">
                        <div>คุณกรอกข้อมูลไม่ครบ
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- password -->
                    <img class="fieldImg" src="../../../assets/images/padlock.png" />
                    <span class="primaryBlue editIcon" (click)="goToEditPassword()"><img class=""
                            src="../../../assets/images/edit.png" />แก้ไข</span>
                    <input type="password" class="form-control" id="password"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER5' | translate }}" formControlName="password"
                        [ngClass]="{'is-invalid': submitted && editFormVal.password.errors}" readonly>
                    <!-- <div *ngIf="submitted && editFormVal.password.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.password.errors.required">Enter password</div>
                    </div> -->
                </div>

                <h5 class="secondaryBlue mt-3 mb-4">{{ 'EDIT_PROFILE.TITLE2' | translate }}</h5>

                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/maphome.png" />
                    <input type="text" class="form-control" id="houseNumber"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER6' | translate }}" formControlName="houseNumber"
                        [ngClass]="{'is-invalid': submitted && editFormVal.houseNumber.errors}">
                    <div *ngIf="submitted && editFormVal.houseNumber.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.houseNumber.errors.required">คุณกรอกข้อมูลไม่ครบ
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/maphome.png" />
                    <input type="text" class="form-control" id="swine"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER7' | translate }}" formControlName="swine"
                        [ngClass]="{'is-invalid': submitted && editFormVal.swine.errors}">
                    <!-- <div *ngIf="submitted && editFormVal.swine.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.swine.errors.required">Enter swine</div>
                    </div> -->
                </div>

                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/neighborhood.png" />
                    <input type="text" class="form-control" id="buildingVillage"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER8' | translate }}" formControlName="buildingVillage"
                        [ngClass]="{'is-invalid': submitted && editFormVal.buildingVillage.errors}">
                    <!-- <div *ngIf="submitted && editFormVal.buildingVillage.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.buildingVillage.errors.required">Enter Building/village</div>
                    </div> -->
                </div>
                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/neighborhood.png" />
                    <input type="text" class="form-control" id="alley"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER9' | translate }}" formControlName="alley"
                        [ngClass]="{'is-invalid': submitted && editFormVal.alley.errors}">
                    <!-- <div *ngIf="submitted && editFormVal.alley.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.alley.errors.required">Enter alley</div>
                    </div> -->
                </div>

                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/maphome.png" />
                    <input type="text" class="form-control" id="road"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER10' | translate }}" formControlName="road"
                        [ngClass]="{'is-invalid': submitted && editFormVal.road.errors}">
                    <!-- <div *ngIf="submitted && editFormVal.road.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.road.errors.required">Enter Building/village</div>
                    </div> -->
                </div>

                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/mailbox.png" />
                    <input type="text" class="form-control" id="postalCode"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER12' | translate }}" formControlName="postalCode"
                        [ngClass]="{'is-invalid': submitted && editFormVal.postalCode.errors}">
                    <div *ngIf="submitted && editFormVal.postalCode.errors" class="invalid-feedback">
                        <div *ngIf="editFormVal.postalCode.errors.required">คุณกรอกข้อมูลไม่ครบ
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/map.png" />

                    <div class="custom-select-box">

                     

                        <select class="form-control" [ngClass]="{'is-invalid': submitted && editFormVal.province.errors}" formControlName="province" (change)="provinceChange($event)" >
                            <option value="" disabled selected>จังหวัด</option>
                            <option *ngFor="let p of mainProvienceData" [ngValue]="p.display">{{ p.display }}</option>
                            </select>
                               <div *ngIf="submitted && editFormVal.province.errors" class="invalid-feedback">
                            <div *ngIf="editFormVal.province.errors.required">คุณกรอกข้อมูลไม่ครบ
                            </div>
                        </div>


                    </div>
                </div>


                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/location.png" />
                    <div class="custom-select-box">
                       
                      
                        <select class="form-control" [ngClass]="{'is-invalid': submitted && editFormVal.country.errors}" formControlName="country" (change)="countyChange($event)" >
                            <option value="" disabled selected>เขต</option>
                            <option *ngFor="let c of county" [ngValue]="c.display">{{ c.display }}</option>
                            </select>
                              <div *ngIf="submitted && editFormVal.country.errors" class="invalid-feedback">
                            <div *ngIf="editFormVal.country.errors.required">คุณกรอกข้อมูลไม่ครบ
                            </div>
                        </div>

                    </div>
                </div>

                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/location.png" />
                    <div class="custom-select-box">
                       
                       

                        <select class="form-control" [ngClass]="{'is-invalid': submitted && editFormVal.district.errors}" formControlName="district">
                            <option value="" disabled selected>แขวง</option>
                            <option *ngFor="let d of district" [ngValue]="d.display">{{ d.display }}</option>
                            </select>
                            <div *ngIf="submitted && editFormVal.district.errors" class="invalid-feedback">
                                <div *ngIf="editFormVal.district.errors.required">คุณกรอกข้อมูลไม่ครบ
                                </div>
                        </div>
                    </div>
                 
                </div>

                <div class="checkSection">
                    <strong>คุณซื้อผลิตภัณฑ์เอนชัวร์/กลูเซอนาเพื่ออะไร</strong>
                    <p *ngFor="let item of checkboxesDataList">
                        <input type="checkbox" formc name="test" id="" [(ngModel)]="item.isChecked" (change)="changeSelection()" [ngModelOptions]="{standalone: true}">
                        <span>{{item.label}}</span>
                    </p>
                </div>

                <h5 class="secondaryBlue mt-3 mb-4">{{ 'EDIT_PROFILE.TITLE3' | translate }}</h5>

                <div formArrayName="childForm" *ngIf="addField || showFormArray">
                    <div *ngFor="let label of childFormArray.controls;let i=index" [formGroupName]="i"
                     class="mb30">

                <div class="form-group">
                    <img class="fieldImg" src="../../../assets/images/child.png" />
                    <input type="text" class="form-control" id="childName"
                        placeholder="{{ 'EDIT_PROFILE.PLACEHOLDER15' | translate }}" formControlName="childName"
                        [ngClass]="{'is-invalid': submitted && editFormVal.childForm.controls[i].controls?.childName?.errors?.required}">                  
                        <!-- <div *ngIf="submitted && editFormVal.childForm.controls[i].controls?.childName?.errors?.required" class="invalid-feedback">
                            <div *ngIf="editFormVal.childForm.controls[i].controls?.childName?.errors?.required">Enter Child name</div>
                        </div> -->
                       
                          <div *ngIf="submitted && ChildNameError[i]" class="text-danger">
                            <div>คุณกรอกข้อมูลไม่ครบ
                            </div>
                        </div>
                </div>

                <div class="row m-0">
                    <div class="col pl-0">
                        <div class="form-group">
                            <div class="custom-select-box">
                                <select class="form-control pl-2" 
                                [ngClass]="{'is-invalid': submitted && editFormVal?.childForm?.controls[i].controls?.year?.errors?.required}"
                                formControlName="year"
                                (change)="selectedYear($event)">
                                    <option value="" disabled selected hidden>{{ 'EDIT_PROFILE.PLACEHOLDER18' | translate }}
                                    </option>
                                    <option *ngFor="let year of yearList" [value]="year">{{year}}</option>
                                </select>
                                <!-- <div *ngIf="submitted && editFormVal.childForm.controls[i].controls?.year?.errors?.required" class="invalid-feedback">
                                    <div *ngIf="editFormVal.childForm.controls[i].controls?.year?.errors?.required">Enter birth year</div>
                                </div> -->
                            </div>
                        </div>
                    </div>  
                    <div class="col px-2">  
                        <div class="form-group">
                            <div class="custom-select-box">
                                <select class="form-control pl-2" 
                                [ngClass]="{'is-invalid': submitted && editFormVal?.childForm?.controls[i]?.controls?.month?.errors?.required}"
                                formControlName="month"
                                
                                (change)="montChanged1($event)"
                                >
                                    <option value="" disabled selected hidden>{{ 'EDIT_PROFILE.PLACEHOLDER17' | translate }}
                                    </option>
                                    <option value="1">มกราคม	</option>
                                    <option value="2">กุมภาพันธ์</option>
                                    <option value="3">มีนาคม</option>
                                    <option value="4">เมษายน</option>
                                    <option value="5">พฤษภาคม</option>
                                    <option value="6">มิถุนายน</option>
                                    <option value="7">กรกฎาคม</option>
                                    <option value="8">สิงหาคม</option>
                                    <option value="9">กันยายน</option>
                                    <option value="10">ตุลาคม</option>
                                    <option value="11">พฤศจิกายน</option>
                                    <option value="12">ธันวาคม</option>
                                </select>
                                <!-- <div *ngIf="submitted && editFormVal.childForm.controls[i].controls?.month?.errors?.required" class="invalid-feedback">
                                    <div *ngIf="editFormVal.childForm.controls[i].controls?.month?.errors?.required">Enter month</div>
                                </div> -->
                            </div>
                            
                        </div>
                    </div>
                   

                    <div class="col pr-0">
                        <div class="form-group">
                            <div class="custom-select-box">
                                <select class="form-control pl-2" 
                                [ngClass]="{'is-invalid': submitted && editFormVal?.childForm?.controls[i]?.controls?.birthDay?.errors?.required}"
                                formControlName="birthDay">
                                    <option value="" disabled selected hidden>{{ 'EDIT_PROFILE.PLACEHOLDER16' | translate }}
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                    <option>24</option>
                                    <option>25</option>
                                    <option>26</option>
                                    <option>27</option>
                                    <option>28</option>
                                    <option *ngIf="(yearSelected == 2012 && modelMonth1 == 2) || (yearSelected == 2016 && modelMonth1 == 2) || (yearSelected == 2020 && modelMonth1 == 2)">29</option>
                                    <option *ngIf="(yearSelected != 2012 && modelMonth1 == 2) || (yearSelected != 2016 && modelMonth1 == 2) || (yearSelected != 2020 && modelMonth1 == 2)" disabled>29</option>
                                    <option *ngIf="(yearSelected != 2012 && modelMonth1 != 2) || (yearSelected != 2016 && modelMonth1 != 2) || (yearSelected != 2020 && modelMonth1 != 2)">29</option>
                                    <option [disabled]="modelMonth1 == 2">30</option>
                                    <option [disabled]="modelMonth1 == 2 || modelMonth1 == 4 || modelMonth1 == 6 || modelMonth1 == 9 || modelMonth1 == 11">31</option>
                                </select>
                                <!-- <div *ngIf="submitted && editFormVal.childForm.controls[i].controls?.birthDay?.errors?.required" class="invalid-feedback">
                                    <div *ngIf="editFormVal.childForm.controls[i].controls?.birthDay?.errors?.required">Enter birthDay</div>
                                </div> -->
                              
                                <div *ngIf="submitted && bdayError[i]" class="text-danger">
                                    <div>คุณกรอกข้อมูลไม่ครบ
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                

            </div>
        </div>

                <div class="dashedBorderCard text-center pt-3 mb-5 mt-3" (click)="addMilkFieldValue()">
                    <img class="img-fluid" src="../../../assets/images/girlboybaby.png">
                    <p class="primaryBlue">+ เพิ่มชื่อลูก</p>
                </div>
               
            </section>
            <section class="px-3">
                <button type="submit" class="btn btn-block goldenBtn mt-3 mb-3" >{{ 'EDIT_PROFILE.BTN_TEXT' | translate
                }}</button>
            </section>
           
        </form>
</section>


<div bsModal #editOtpModal="bs-modal" class="modal fade customModalContainer" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body px-2">
                
                <form [formGroup]="editOtpForm">
                    <section class="text-center">
                        <button type="button" class="close" aria-label="Close" (click)="closeEditOtpModal()">
                            <img src="../../../assets/images/close.png" />
                        </button>
                        <h5 class="primaryBlue mt25 primaryTitle"> {{ 'EDIT_OTP_MODAL.TITLE1' | translate }}</h5>
                        <h5 class="secondaryBlue  secondaryTitle mt30"> {{ 'EDIT_OTP_MODAL.TITLE2' | translate }}</h5>
                        <h5 class="secondaryBlue  secondaryTitle">{{ 'EDIT_OTP_MODAL.TITLE3' | translate }}</h5>

                        <h5 class="referalCode mt15 mb20">Ref code : S5D26</h5>
                        <h5 class="secondaryBlue secondaryTitle mt30 mb-4"> {{ 'EDIT_OTP_MODAL.TITLE4' | translate }}</h5>
                        <div class="row justify-content-center">
                            <div class="col-10">
                                <ng-otp-input (onInputChange)="onOtpChange($event)"
                                    [config]="{length:6,placeholder:'0',allowNumbersOnly: true}"
                                    [ngClass]="{'is-invalid' : submitEditOtpForm && (otp == undefined || otp.length < 6) || invalidOtp }"
                                    #ngOtpInput>
                                </ng-otp-input>
                               
                                <div *ngIf="submitEditOtpForm && EditOtpFormVal.otp.errors.required" class="text-danger pl-0 mt-2">
                                    <div *ngIf="!activeClassButton">{{ 'EDIT_OTP_MODAL.OTP_ERROR' | translate }}</div>
                                </div>
                                <div *ngIf="invalidOtp" class="text-danger pl-0">
                                    <div>{{ 'EDIT_OTP_MODAL.OTP_ERROR' | translate }}</div>
                                </div>

                                <p class="colorGrey mt-4">{{ 'EDIT_OTP_MODAL.LINK_TEXT_LEFT' | translate }} <span class="primaryBlue fontKanitMedium">{{ 'EDIT_OTP_MODAL.LINK_TEXT_RIGHT' | translate }}</span></p>

                                <button type="submit" class="btn btn-block goldenBtn my-4" (click)="onSubmitEditOtpForm()">{{ 'REG_OTP_MODAL.BTN_TEXT' | translate }}</button>
                            </div>
                        </div>
                                
                    </section>
                </form>

            </div>
        </div>
    </div>
</div>


<!-- success email modal -->
<div bsModal #successEmailModal="bs-modal" class="modal fade customModalContainer successScanModal" tabindex="-1"
    role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" aria-label="Close" (click)="closeSuccessEmailModal()">
                    <img src="../../../assets/images/close.png" />
                </button>
                <div>
                    <h4>เพิ่มอีเมลเรียบร้อยแล้ว</h4>
                        <p>{{ 'SUCCESS_SCAN_MODAL.CONGRATULATIONS_YOU_RECEIVED' | translate }} 5 {{ 'SUCCESS_SCAN_MODAL.SCORE'
                            | translate }}</p>
                    <button class="btn btn-block goldenBtn" (click)="SuccessEmailModal()">{{ 'SUCCESS_SCAN_MODAL.OK' |
                        translate }}</button>
                </div>               
            </div>
        </div>
    </div>
</div>


<!-- success child modal -->
<div bsModal #successChildModal="bs-modal" class="modal fade customModalContainer successScanModal" tabindex="-1"
    role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" aria-label="Close" (click)="closeSuccessChildModal()">
                    <img src="../../../assets/images/close.png" />
                </button>
                <div >
                    <h4>เพิ่มข้อมูลวันเกิดลูกสำเร็จ</h4>
                    <p>{{ 'SUCCESS_SCAN_MODAL.CONGRATULATIONS_YOU_RECEIVED' | translate }} {{childPoints}} {{ 'SUCCESS_SCAN_MODAL.SCORE'
                        | translate }}</p> 
                    <button class="btn btn-block goldenBtn" (click)="SuccessChildModal()">{{ 'SUCCESS_SCAN_MODAL.OK' |
                        translate }}</button>
                </div>
                
            </div>
        </div>
    </div>
</div>


<!-- success email and child modal -->
<div bsModal #successEmailChildModal="bs-modal" class="modal fade customModalContainer successScanModal" tabindex="-1"
    role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" aria-label="Close" (click)="closeEmailChildModal()">
                    <img src="../../../assets/images/close.png" />
                </button>
                <div >
                    <h4>เพิ่มข้อมูลอีเมลสำเร็จ</h4>
                        <p>ยินดีด้วยคุณได้รับ {{ emailChildPts }} คะแนน</p>
                    <button class="btn btn-block goldenBtn" (click)="SuccessEmailChildModal()">{{ 'SUCCESS_SCAN_MODAL.OK' |
                        translate }}</button>
                </div>
                
            </div>
        </div>
    </div>
</div>


<!-- general update profile success-->
<div bsModal #successProfileUpdateModal="bs-modal" class="modal fade customModalContainer successScanModal" tabindex="-1"
    role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" aria-label="Close" (click)="closeProfileUpdateModal()">
                    <img src="../../../assets/images/close.png" />
                </button>
                <div>
                    <p>อัปเดตโปรไฟล์เรียบร้อยแล้ว</p>
                    <button class="btn btn-block goldenBtn" (click)="SuccessProfileUpdateModal()">{{ 'SUCCESS_SCAN_MODAL.OK' |
                        translate }}</button>
                </div>
                
            </div>
        </div>
    </div>
</div>