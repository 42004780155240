<div class="globalContainer _100vh">
    <app-header></app-header>
    <ul class="moreList">
        <li [routerLink]="['/about-us']"><a >{{ 'MORE.ABBOTT_FAMILY_CLUB' | translate }}</a></li>
        <li [routerLink]="['/howtouse']"><a>{{ 'MORE.HOW_TO_USE_THE_APPLICATION' | translate }}</a></li>
        <li [routerLink]="['/contact-us']"><a>{{ 'MORE.CONTACT_US' | translate }}</a></li>
        <li [routerLink]="['/terms']"><a>{{ 'MORE.TERMS_OF_USE' | translate }}</a></li>
        <li [routerLink]="['/conditions']"><a>{{ 'MORE.PRIVACY_POLICY' | translate }}</a></li>
        <li [routerLink]="['/faq']"><a>{{ 'MORE.FAQ' | translate }}</a></li>
    </ul>
</div>
<app-footer></app-footer>


