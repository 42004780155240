<section class="globalContainer _100vh">
    <app-header-family-club></app-header-family-club>
        <section class="px-4">
            <h5 class="secondaryBlue mt-1 mb-4 fontKanitMedium">{{ 'CONTACT_US.TITLE1' | translate }}</h5>
            <h5 class="secondaryBlue fontKanitRegular">{{ 'CONTACT_US.TITLE2' | translate }}</h5>
            <div class="row mt-4">
                <div class="col-12 px-4">
                    <p class="secondaryBlue mb-2 fontKanitRegular"><img class="mr-1" src="../../../assets/images/phone.png"/><a href="tel:022522448" class="secondaryBlue"> {{ 'CONTACT_US.ADDRESS_LINE1' | translate }}</a></p>
                    <div class="row justify-content-end">
                        <div class="col-11 pl-3">
                            <p class="colorGrey fontKanitRegular">{{ 'CONTACT_US.ADDRESS_LINE2' | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-4">
                    <p class="secondaryBlue fontKanitRegular"><img class="mr-1" src="../../../assets/images/message2.png"/><a href="mailto:myfamilyclub@abbott.com" class="secondaryBlue">{{ 'CONTACT_US.ADDRESS_LINE3' | translate }}</a></p>
                </div>
            </div>
            <section class="mt-4">
                <img class="img-fluid" src="../../../assets/images/cotactusbottombanner.png">
            </section>
        </section>
</section>
<!-- <app-footer></app-footer> -->