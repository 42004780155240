<ul class="traceabilityNav">
    <li [ngClass]="{'activeClassGlu': activeManufacturing}" routerLink="/ensure-manufacturing">
        <figure>
            <img src="../../../../assets/images/ensure/ensurenav-home.png"alt="home" />
        </figure>
        <p>มาตรฐาน<br/> การผลิตสินค้า</p>
    </li>
    <li [ngClass]="{'activeClassGlu': activeQualityControl}" routerLink="/ensure-qualitycontrol">
        <figure>
            <img src="../../../../assets/images/ensure/ensurenav-search.png" alt="search" />
        </figure>
        <p>การควบคุม<br/>
            คุณภาพสินค้า</p>
    </li>
    <li [ngClass]="{'activeClassGlu': activeBranded}" routerLink="/ensure-product">
        <figure>
            <img src="../../../../assets/images/ensure/esnurenav-glass.png" alt="glass" />
        </figure>
        <p> รู้จัก<br />
            เอนชัวร์</p>
    </li>
    <li [ngClass]="{'activeClassGlu': activeTriplePlus}" routerLink="/ensure-mixinstruction">
        <figure>
            <img src="../../../../assets/images/ensure/ensurenav-scoop.png" alt="triple plus" />
        </figure>
        <p>วิธีเตรียม<br /> 
            เอนชัวร์</p>
    </li>
    <li [ngClass]="{'activeClassGlu': activeLogIn}" (click)="skipTrans()">
            <a>
            <figure class="familyClub">
                <img src="../../../assets/images/my_family_club_nav.png" alt="my family club" />
            </figure>
            <p>มาย <br />
                แฟมิลี่ คลับ</p>
            </a>
        </li>
</ul>
