<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer glucernaProduct mix">
    <app-glucerna-header></app-glucerna-header>
    <div class="top">
        <h1><span>วิธีเตรียม</span>
            กลูเซอนา</h1>
    </div>
    <div class="bottom">
        <figure class="first">
            <span>200<br />มล.</span>
            <img src="../../../../assets/images/glucerna/glassmix1.png" alt="img" />
        </figure>
        
        <h3 >เติมน้ำสุกที่เย็นหรืออุ่น 200 มล.
            (ประมาณ 1 แก้ว) ลงในภาชนะที่สะอาด
            </h3>
            <img class="arrow1" src="../../../../assets/images/glucerna/downarrow.png" alt="">

            <figure class="second">
                <span> <small>x</small>5</span>
                <img src="../../../../assets/images/glucerna/glassmix2.png" alt="img" />
            </figure>
            <h3>ค่อยๆ เติมทีละช้อนตวงปาดคนให้เข้ากัน <br />
                จนละลายหมดแล้วจึงเติมทีละช้อน <br />
                จนครบ 5 ช้อนตวง (52.1 กรัม)</h3>
                <img class="arrow1" src="../../../../assets/images/glucerna/downarrow.png" alt="">

                <figure class="first">
                    <span>226
                        <br /><small>กิโลแคลอรี</small></span>
                    <img src="../../../../assets/images/glucerna/glassmix3.png" alt="img" />
                </figure>
                <h3>จะได้ส่วนผสม 237 มล.<br />
                    ซึ่งให้พลังงาน 226 กิโลแคลอรี<br />
                    (0.95 กิโลแคลอรี/มล.)</h3>
    </div>
    <div class="footer">
        <button> การเก็บรักษา </button>
        <ul>
            <li> เมื่อเปิดกระป๋องแล้วควรปิดกระป๋องให้แน่นทุกครั้งหลังใช้
                เก็บไว้ในที่เย็นและแห้ง (ไม่ควรเก็บไว้ในตู้เย็น) และควรใช้ให้
               หมดภายใน 3 สัปดาห์ 
             </li>
            <li> หลังจากผสมแล้วควรใช้ทันที หากใช้ไม่หมดควรเก็บไว้ใน
                ภาชนะปิดฝา และเก็บในตู้เย็นจะเก็บได้นาน 24 ชั่วโมง</li>
        </ul>
    </div>
    


</section>
<app-glucerna-nav></app-glucerna-nav>

