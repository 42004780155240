<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer glucerna-cereal">
  <!-- <app-glucerna-header class="traceabilityHomeheader"></app-glucerna-header> -->
  <header class="traceabilityHeader bgPrimary-glucerna-cereal">
    <!-- <img class="homeLogo" routerLink="/glucerna-traceability" src="../../../assets/images/home_logo_white.png"> -->
    <img
      class="img-fluid mt-2 logo"
      src="../../../../assets/images/glucerna/logo.png"
    />
  </header>

  <div class="homeContainer">
    <h1>ผลิตภัณฑ์กลูเซอนา</h1>
    <div class="imgBlock _gutter1">
      <figure class="_210px">
        <!-- <img
          *ngIf="isIsprint == false"
          class="gpasImg"
          src="../../../assets/images/glucerna/glucerna-cereal-gpass-img.png"
          alt="glucerna"
        /> -->

        <img
          *ngIf="isIsprint == false"
          class="gpasImg"
          [src]="prodImage"
          alt="glucerna"
        />
        <img
          *ngIf="isIsprint == true"
          class=""
          [src]="productImage"
          alt="glucerna"
        />
      </figure>
      <div class="prdDetails pt-30">
        <span>{{ productName }} </span><br /><strong
          >{{ "TRACEABILITY_HOME.LOT_NUMBER" | translate }}
          {{ lotNumber }}</strong
        >
      </div>
      <i class="rightArrow"
        ><img
          src="../../../../assets/images/glucerna/arrowleft.svg"
          alt="right arrow"
      /></i>
    </div>
    <div class="imgBlock _gutter2">
      <figure class="_255px">
        <img src="../../../../assets/images/glucerna/home1.png" alt="home" />
      </figure>
      <div class="prdDetails">
        <p>
          <label
            >{{ "TRACEABILITY_HOME.COUNTRY_OF_ORIGIN" | translate }} :
          </label>
          <span> {{ manufactCountry }}</span>
        </p>
        <p>
          <label>{{ "TRACEABILITY_HOME.PRODUCTION_DATE" | translate }} </label>
          <span> {{ manufacturedDate }}</span>
        </p>
      </div>
      <i class="leftArrow"
        ><img
          src="../../../../assets/images/glucerna/arrowright.svg"
          alt="left arrow"
      /></i>
    </div>
    <div class="imgBlock _mt600px">
      <figure class="_158px">
        <img
          src="../../../../assets/images/glucerna/search1.png"
          alt="search"
        />
      </figure>
      <div class="prdDetails">
        <span>{{ "TRACEABILITY_HOME.EXPIRATION_DATE" | translate }} </span
        ><br /><span>{{ expiryDate }}</span>
      </div>
    </div>
    <div class="triplePlusBlock">
      <h1>รู้จัก <span>กลูเซอนา พลัส</span></h1>
      <a [routerLink]="['/glucernaplus-product']" class="tracePrimaryBtn"
        ><span> คลิกเลย! </span></a
      >
    </div>
  </div>
</section>
<app-app-glucerna-plus-cereal-nav></app-app-glucerna-plus-cereal-nav>
