import { Component, OnInit } from '@angular/core';
import { environment as env } from '../../environments/environment'
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { BugsnagService } from '../services/bugsnag.service';

declare var $:any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  uaaid:any;

  constructor(
    private apiService: ApiService, 
    private spinner: NgxSpinnerService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private bsService: BugsnagService
    ) { 
    this.uaaid = this.route.snapshot.queryParams.uuid;
    console.log(this.uaaid, 'uaaid')
  }

  ngOnInit(): void {
    let getMobile = sessionStorage.getItem('mobileNo')
    let getAuthToken = sessionStorage.getItem('authToken')
    let getAuthKey = sessionStorage.getItem('authKey')
    this.redirectUrlApi();
    
  }


redirectUrlApi(){
  this.apiService.redirectUrlApi(this.uaaid).subscribe((data: any)=>{
    console.log(data, 'redirect api')
      let mobile = data?.result?.mobile;
      let token = data?.result?.auth_token;
      let key = sessionStorage.getItem('authKey');
      console.log(key, ' auth key ========')
      let targetPage = data?.result?.target_page;
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe(res=>{
        if(res['status'].code == 200){
          this.spinner.hide();

          if(targetPage == 'home'){
            this.router.navigate(['/home'])
          }else  if(targetPage == 'login'){
            this.router.navigate(['/login'])
          }else  if(targetPage == 'register'){
            this.router.navigate(['/register'])
          }else  if(targetPage == 'forgot-password'){
            this.router.navigate(['/forgot-password'])
          }else  if(targetPage == 'profile'){
            this.router.navigate(['/profile'])
          }else  if(targetPage == 'edit-profile'){
            this.router.navigate(['/edit-profile'])
          }else  if(targetPage == 'landing-page'){
            this.router.navigate(['/landing-page'])
          }else  if(targetPage == 'edit-password'){
            this.router.navigate(['/edit-password'])
          }else  if(targetPage == 'faq'){
            this.router.navigate(['/faq'])
          }else  if(targetPage == 'contact-us'){
            this.router.navigate(['/contact-us'])
          }else  if(targetPage == 'more'){
            this.router.navigate(['/more'])
          }else  if(targetPage == 'terms'){
            this.router.navigate(['/terms'])
          }else  if(targetPage == 'conditions'){
            this.router.navigate(['/conditions'])
          }else  if(targetPage == 'about-us'){
            this.router.navigate(['/about-us'])
          }else  if(targetPage == 'points'){
            this.router.navigate(['/history'])
          }

        console.log(res, '====== customer Get ==========')
        }else if(res['status'].code == 401){
        // Token Regenrate
        this.spinner.show();
        let tokenReGenerate = {
          "mobile":mobile,
          "deviceId": env.DEVICE_ID,
          "brand" : env.BRAND,
          "key": key
        }
        this.apiService.tokenReGenerate(tokenReGenerate).pipe(first())
          .subscribe(resGen=>{
            this.spinner.hide();
            console.log(tokenReGenerate, 'token regen')
            if(resGen['status'].code == 200){
              sessionStorage.setItem('authToken', resGen['auth'].token)
              //Get cuctomer
              this.spinner.show();
              this.apiService.getCapCustomer(mobile, resGen['auth'].token).subscribe(res=>{
                if(res['status'].code == 200){
                  this.spinner.hide();
                  if(targetPage == 'home'){
                    this.router.navigate(['/home'])
                  }else  if(targetPage == 'login'){
                    this.router.navigate(['/login'])
                  }else  if(targetPage == 'register'){
                    this.router.navigate(['/register'])
                  }else  if(targetPage == 'forgot-password'){
                    this.router.navigate(['/forgot-password'])
                  }else  if(targetPage == 'profile'){
                    this.router.navigate(['/profile'])
                  }else  if(targetPage == 'edit-profile'){
                    this.router.navigate(['/edit-profile'])
                  }else  if(targetPage == 'landing-page'){
                    this.router.navigate(['/landing-page'])
                  }else  if(targetPage == 'edit-password'){
                    this.router.navigate(['/edit-password'])
                  }else  if(targetPage == 'faq'){
                    this.router.navigate(['/faq'])
                  }else  if(targetPage == 'contact-us'){
                    this.router.navigate(['/contact-us'])
                  }else  if(targetPage == 'more'){
                    this.router.navigate(['/more'])
                  }else  if(targetPage == 'terms'){
                    this.router.navigate(['/terms'])
                  }else  if(targetPage == 'conditions'){
                    this.router.navigate(['/conditions'])
                  }else  if(targetPage == 'about-us'){
                    this.router.navigate(['/about-us'])
                  }else  if(targetPage == 'points'){
                    this.router.navigate(['/history'])
                  }
                }
              }, err=>{
                this.spinner.hide();
                this.bsService.notifyError(err, {mobile});
                console.log(err, 'error data')
              })
            }
          },err=>{
            this.spinner.hide();
            this.bsService.notifyError(err, tokenReGenerate);
            console.log(err, 'error data')
          })  
        }
      }, err => {
        this.spinner.hide();
        this.bsService.notifyError(err, {mobile});
      })
    

   
  }, err =>{
    this.bsService.notifyError(err, {uaaid: this.uaaid});
  })
}

}
