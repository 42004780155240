<div class="globalContainer _100vh p-0">
  <app-header-family-club></app-header-family-club>
  <div class="cameraContainer globalContainer">
    <div class="row no-gutters mb-3">
      <div class="col-3"></div>
    </div>
    <div class="text-center initialContent">
      <p class="textBlack mt-2 cameraTitle">
        กรุณาถ่ายรูปใบเสร็จเพื่อทำการตรวจสอบ
      </p>
      <!-- <p class="camerContent">Pastikan foto struk Anda terlihat jelas</p> -->
    </div>
    <div class="text-center captureContent" style="display: none">
      <p class="textBlack mt-2 cameraTitle">Keren!</p>
      <p class="camerContent">
        Cek struk yang telah Anda Unggah, pastikan sudah terlihat jelas agar
        dapat diproses.
      </p>
    </div>
    <div class="videoCam mt-4">
      <div class="snapshot" *ngIf="webcamImage">
        <img [src]="url" class="uploaded-image" />
        <div class="tap-to-edit col-12">
          <img src="../../../assets/images/tap-to-edit.svg" />

          <label for="upload">แตะเพื่อแก้ไขภาพ</label>
          <input
            id="upload"
            type="file"
            name="profile"
            accept="image/*"
            style="display: none"
            (change)="fileChangeEvent($event)"
          />
        </div>
        <div class="confirmButtons mt-4 row">
          <div class="col-12">
            <button
              (click)="uploadImage()"
              class="btn w-100 btnPurple"
              
            >
              ส่ง
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!webcamImage">
        <webcam
          [height]="500"
          [width]="600"
          [trigger]="triggerObservable"
          (imageCapture)="handleImage($event)"
          *ngIf="showWebcam"
          [allowCameraSwitch]="allowCameraSwitch"
          [switchCamera]="nextWebcamObservable"
          [imageQuality]="1"
          [mirrorImage]="never"
          (initError)="handleInitError($event)"
        ></webcam>
      </div>
    </div>
    <div class="camButtons row mt-4 mx-0 mb-4">
      <div class="uploadBtn col-2" *ngIf="!webcamImage">
        <input
          id="upload"
          type="file"
          name="profile"
          accept="image/*"
          (change)="fileChangeEvent($event)"
        />
        <img class="" src="../../../assets/images/uploadimage_file.svg" />
      </div>
      <div class="captureBtn col-8 text-center" *ngIf="showCamera">
        <button>
          <img
            class=""
            src="../../../assets/images/capture_image.svg"
            (click)="triggerSnapshot()"
          />
        </button>
      </div>
      <div class="col-2">
        <!-- <img class="" src="../../../../assets/images/flashnew.png"> -->
      </div>
    </div>
  </div>
</div>

<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
  <li>{{ error | json }}</li>
</ul>
