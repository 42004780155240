<div class="loderData" *ngIf="loader">
    <div class="loader"></div>
</div>
<!-- failed scan modal -->
<div
  bsModal
  #failedScanModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeFailedScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>{{ "FAILED_SCAN_MODAL.SCAN_FAILED" | translate }}</h4>
        <p>{{ "FAILED_SCAN_MODAL.QR_CODE_ALREADY_IN_USE" | translate }}</p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeFailedScanModal()"
        >
          {{ "FAILED_SCAN_MODAL.TRY_AGAIN" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>