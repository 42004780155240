<app-ensure-family-club></app-ensure-family-club>
<section
  class="traceAbilityContainer pb150 ensure manufacturingBg manufacturing"
>
  <app-ensure-header></app-ensure-header>
  <section class="qualityContainer">
    <div class="position-relative">
      <div
        class="productImgBlock quality productImgManufacturerBlock mt30"
      ></div>
      <!-- <div class="productBlock">
                <p>การควบคุมคุณภาพสินค้า</p>
            </div> -->
    </div>
    <div class="cardBlockOut px-2 pb-4 mt30">
      <div class="dateBlock dateBlockManufacture text-center py-2">
        <p class="mb-0">
          {{ "MANUFACTURING.DATE" | translate }} :
          {{ expiryDate }}
        </p>
      </div>

      <p class="cardDescription mb-0">
        สำหรับแอ๊บบอต การควบคุมคุณภาพในกระบวนการผลิตถือเป็น สิ่งสำคัญที่สุด
        เพื่อให้มั่นใจได้ว่าบริษัทฯ จะสามารถผลิตสินค้าที่มี
        คุณภาพและมีความปลอดภัยอยู่เสมอ
      </p>
      <div>
        <figure>
          <img
            class="img-fluid mt40 w-100"
            src="../../../assets/images/manufacturecardmiddle.png"
          />
        </figure>
      </div>
      <p class="cardDescription mt40">
        ตั้งแต่ขั้นตอนการรับวัตถุดิบสำหรับใช้ในการผลิตสินค้า จนกระทั่ง
        การผลิตสินค้าออกจากโรงงาน ในทุกขั้นตอนของการผลิตได้ผ่าน
        การตรวจสอบอย่างเข้มงวดโดยผู้เชี่ยวชาญด้านการประกันคุณ ภาพ
        ที่ผ่านการฝึกอบรม พร้อมด้วยการใช้อุปกรณ์การตรวจสอบ ที่ทันสมัย
      </p>
    </div>
  </section>
</section>
<app-ensure-nav></app-ensure-nav>
