<header>
    <div class="container">
        <div class="row">
            <div class="col-2">
                <img src="../../../assets/images/abbott.svg" alt="abbott" class="logo" />
            </div>
            <div class="col-10 text-center">
                <img class="headerLogo" src="../../../assets/images/loginlogo.png" alt="logo"/>
            </div>
        </div>
    </div>
</header>