<footer>
    <ul class="footerNav">
        <li>
            <a routerLink="/home">
                <i><img src="../../assets/images/acc_points.svg" *ngIf="!showActiveHomeImg" alt="accumulate points" /></i>
                <i><img src="../../assets/images/home_active.svg" *ngIf="showActiveHomeImg" alt="accumulate points" /></i>

                <p [ngClass]="{'activeMenu': showActiveHomeImg}">{{ 'FOOTER.ACCUMULATE_POINTS' | translate }}</p>
            </a>
        </li>
        <li>
            <a routerLink="/reward">
                <i><img src="../../assets/images/redemption.svg" alt="redemption" /></i>
                <p>{{ 'FOOTER.REDEMPTION' | translate }}</p>
            </a>
        </li>
        <li>
            <a routerLink="/profile">
                <i><img src="../../assets/images/personal_info.svg" *ngIf="!showActiveProfileImg" alt="personal information" /></i>
                <i><img src="../../assets/images/personal_infoactive.svg" *ngIf="showActiveProfileImg" alt="personal information" /></i>
                <p [ngClass]="{'activeMenu': showActiveProfileImg}">{{ 'FOOTER.PERSONAL_INFORMATION' | translate }}</p>
            </a>
        </li>
        <li>
            <a routerLink="/more">
                <i><img src="../../assets/images/other.svg" *ngIf="!showActiveMoreImg"   alt="other" /></i>
                <i><img src="../../assets/images/other_active.svg"  *ngIf="showActiveMoreImg"  alt="other" /></i>
                <p [ngClass]="{'activeMenu': showActiveMoreImg}">{{ 'FOOTER.OTHER' | translate }}</p>
            </a>
        </li>
    </ul>
</footer>
