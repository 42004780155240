import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-glucernaplus-product',
  templateUrl: './glucernaplus-product.component.html',
  styleUrls: ['./glucernaplus-product.component.scss']
})
export class GlucernaplusProductComponent implements OnInit {
  productImage: string;

  constructor() {
    window.scrollTo({ top: 0 });
   }

  ngOnInit(): void {
  }

}
