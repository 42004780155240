<ul class="traceabilityNav">
    <li [ngClass]="{ activeClass: activeManufacturing }" routerLink="/ensureplantbased-manufacturing">
      <figure>
        <img
          src="../../../../../assets/images/ensure/ensurenav-home.png"
          alt="home"
        />
      </figure>
      <p>มาตรฐาน<br />
        การผลิตสินค้า</p>
    </li>
    <li [ngClass]="{ activeClass: activeQualityControl }" routerLink="/ensureplantbased-quality-control">
      <figure>
        <img
          src="../../../../../assets/images/ensure/ensurenav-search.png"
          alt="search"
        />
      </figure>
      <p>การควบคุม<br />
        คุณภาพสินค้า</p>
    </li>
    <li [ngClass]="{ activeClass: activeBranded }" routerLink="/ensureplantbased-branded">
      <figure>
        <img
          src="../../../../../assets/images/ensure/esnurenav-glass.png"
          alt="glass"
        />
      </figure>
      <p>รู้จัก
        <br /> เอนชัวร์ โกลด์</p>
    </li>
    <li [ngClass]="{ activeClass: activeTriplePlus }" routerLink="/ensureplantbased-instruction">
      <figure>
        <img
          src="../../../../../assets/images/ensure/ensurenav-scoop.png"
          alt="triple plus"
        />
      </figure>
      <p>วิธีเตรียม
        <br />เอนชัวร์ โกลด์</p>
    </li>
    <li [ngClass]="{ activeClass: activeLogIn }" (click)="skipTrans()" routerLink="/login">
      <figure class="familyClub">
        <img
          src="../../../assets/images/ensure-aw/my_family_club_nav.png"
          alt="my family club"
        />
      </figure>
      <p>มาย<br />
        แฟมิลี่ คลับ</p>
    </li>
  </ul>
  