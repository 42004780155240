<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer glucernaContainer glucerna-cereal">
    <header class="traceabilityHeader bgPrimary-glucerna-cereal">
     <img class="homeLogo" routerLink="/glucernaplus-traceability" src="../../../assets/images/home_logo_white.png">
     <img  routerLink="/glucernaplus-traceability" src="../../../assets/images/glucerna/logo1.png">
      </header>
      <div class="text-type-70">
        <span>วิธีเตรียม</span>
กลูเซอนา พลัส
      </div>
    

     
      <div class="contentWrapper">
        <div class="heading-icon mt-5 text-center">
            <img src="../../../assets/images/glucerna/mixin-icon-160.png" />
          </div>
          <div class="text-type-30 mt-2 text-center">
            เติมน้ำสุกที่เย็นหรืออุ่น 160 มล.
(ประมาณ 1 แก้ว) ลงในภาชนะที่สะอาด
          </div>
          <div class="down-arrow heading-icon mt-3 text-center">
            <img src="../../../assets/images/glucerna/downarrow.png" />
          </div>

          <div class="heading-icon mt-5 text-center">
            <img class="icon-x4" src="../../../assets/images/glucerna/mixin-icon-x4.png" />
          </div>
          <div class="text-type-30 mt-2 text-center">
            ค่อยๆ เติมทีละช้อนตวงปาดคนให้เข้ากัน
            จนละลายหมดแล้วจึงเติมทีละช้อน
            จนครบ 4 ช้อนตวง (41.7 กรัม)
          </div>
          <div class="down-arrow heading-icon mt-3 text-center">
            <img src="../../../assets/images/glucerna/downarrow.png" />
          </div>


          <div class="heading-icon mt-5 text-center">
            <img class="icon-180" src="../../../assets/images/glucerna/mixin-icon-180.png" />
          </div>
          <div class="text-type-30 mt-2 text-center">
            จะได้ส่วนผสม 190 มล.
ซึ่งให้พลังงาน 180 กิโลแคลอรี
(0.97 กิโลแคลอรี/มล.)
          </div>
          



 


      </div>
      <div class="bottom-section">
        <div class="bottom-content">
          <img class="w-100" src="../../../assets/images/glucerna/curve-bg.png">
            <div class="innerDiv">
                    <div id="d">
                        <div class="custom-btn text-center ">การเก็บรักษา</div>
      <ul class="mt-3">
        <li>
            เมื่อเปิดกระป๋องแล้วควรปิดกระป๋องให้แน่นทุกครั้งหลังใช้
            เก็บไว้ในที่เย็นและแห้ง (ไม่ควรเก็บไว้ในตู้เย็น) และควรใช้ให้
           หมดภายใน 3 สัปดาห์ 
        </li>
        <li>
            หลังจากผสมแล้วควรใช้ทันที หากใช้ไม่หมดควรเก็บไว้ใน
            ภาชนะปิดฝา และเก็บในตู้เย็นจะเก็บได้นาน 24 ชั่วโมง
        </li>
  
      </ul>

      <div class="text-type-10 text-right mt-3 pb-2 pr-3 text-uppercase">TH.2022.27291.GLU.1(v1.0)©2022Abbott

    </div>
                    </div>
            </div>
        </div>
        
   
      </div>
</section>
<app-app-glucerna-plus-cereal-nav></app-app-glucerna-plus-cereal-nav>
