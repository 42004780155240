<section class="globalContainer _100vh">
    <app-header-family-club></app-header-family-club>
        <h5 class="secondaryBlue mt-3 mb-2">คำถามที่พบบ่อย</h5>
        <section class="px-4 faqContainer">
            <div class="accordion" id="accordionExample">
                <div class="card customCard" *ngFor="let data of faqCont;let i = index">
                    <div class="card-header" id="headingOne">
                        <button class="btn btn-link collapseBtn" type="button" data-toggle="collapse" [attr.data-target]="'#collapseOne'+i"
                            aria-expanded="true" aria-controls="collapseOne{{i}}" >
                            {{ data.faqtitle }}
                        </button>
                    </div>

                    <div id="collapseOne{{i}}" class="collapse" [ngClass]="{'show': i == 0}" aria-labelledby="headingOne"
                        data-parent="#accordionExample">
                        <div class="card-body" [innerHTML]="data.faqdescription | safe: 'html'">
                        </div>
                    </div>
                </div>
               
            </div>
        </section>
</section>
<!-- <app-footer></app-footer> -->