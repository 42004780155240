import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  about: any;
  constructor(private apiService: ApiService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getAbout();
  }

  getAbout() {
    this.spinner.show();
    this.apiService.getTermsConditionAbout().subscribe((res: any) => {
      this.spinner.hide();
      res.data.forEach(item => {
        if (item.key == "about_us") {
          this.about = item.contentvalue;
        }
      })
    })
  }
}
