import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import liff from '@line/liff';
import { BugsnagService } from '../services/bugsnag.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-line-landing',
  templateUrl: './line-landing.component.html',
  styleUrls: ['./line-landing.component.scss']
})
export class LineLandingComponent implements OnInit {
location: string = ''
scancode: string = ''
  constructor(private router: Router,private apiService: ApiService,
    private route: ActivatedRoute, private bsService: BugsnagService) { }

  ngOnInit(): void {
   this.scancode = this.route.snapshot?.params?.scancode 
  }

  OnClickOpenLine() {
   // const popUp = window.open('', '_blank');
  //   if (!popUp || popUp.closed || typeof popUp.closed == 'undefined') {
  //    console.log('Pop-up blocked');
  //    alert("ป๊อปอัปถูกบล็อก! โปรดเปิดใช้งานการตั้งค่าเบราว์เซอร์เพื่อแสดงป๊อปอัป")
  //  } else {
     //console.log('Pop-up not blocked');
     this.location = `${env.LIFF_URL}?scancode=${this.scancode}`   
      liff.openWindow({
        url: this.location,
        external: false,
      })
 //  }
  }

}
