<div class="globalContainer _100vh p-0">
  <app-header-family-club></app-header-family-club>
  <div class="container"></div>
  <div class="row h-100 align-items-center w-100 m-0">
    <div class="col-12 text-center image-upload-success">
      <img src="../../../assets/images/image-upload-success.svg" />
      <div class="heading">ขอบคุณค่ะ</div>
      <div class="text">
        
        <p class="mt-3">
          ระบบจะทำการตรวจสอบ หากหลักฐานถูกต้อง <br /> ระบบจะอัปเดตคะแนนภายใน 7 วัน​
        </p>
        <p class="mt-5">
          คุณสามารถตรวจสอบสถานะของหลักฐาน <br /> หรือคะแนนสะสมได้ที่เมนู”การสะสมคะแนน”</p>
      </div>
      <div class="col-12">
        <button class="btn w-100 btnPurple" routerLink="/history">ปิด</button>
      </div>
    </div>
  </div>
</div>
