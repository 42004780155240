<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer ensureProduct mix">
    <app-ensure-header></app-ensure-header>
    <div class="top text-center mt30 px20">
        <img class="" src="../../../../assets/images/ensure/ensure-mixmilkbottle.png">
        <h1 class="oneCup">เอนชัวร์ 1 แก้ว</h1>
        <h3 class="spoon">(6 ช้อนตวง) 230 มิลลิลิตร</h3>
        <div class="dashedBorderBlue mt30 mb30"></div>
        <div class="row no-gutters mx-0 detailContent">
            <div class="col-5">
                <p> พลังงาน</p>
                <p>โปรตีน</p>
                <p>คาร์โบไฮเดรต</p>
                <p>ไขมัน</p>
                <p> ใยอาหาร</p>
            </div>
            <div class="col-3">
                <p> 230</p>
                <p>8.55</p>
                <p>30.87</p>
                <p>7.53</p>
                <p> 2.31</p>
            </div>
            <div class="col-4">
                <p> กิโลแคลอรี</p>
                <p>กรัม</p>
                <p>กรัม</p>
                <p>กรัม</p>
                <p>กรัม</p>
            </div>
        </div>
    </div>
    <div class="middle text-center px20">
        <img class="oldageImg" src="../../../../assets/images/ensure/ensure-oldage.png">
        <h1 class="mb-0">เหมาะสำหรับ</h1>
        <p class="mt-0">ผู้สูงอายุ
            <span>
                ผู้ป่วยระยะพักฟื้น<br/>
                ผู้ป่วยที่รักษาตัวอยู่ใน<br/>โรงพยาบาลที่
            </span>
            ขาดสารอาหาร<br/>
            หรือรับประทานอาหาร<br/>
            ไม่ครบถ้วน
        </p>
    </div>

    <div class="text-center px20">
        <h1 class="howToPrepare">วิธีเตรียม</h1>
        <img class="" src="../../../../assets/images/ensure/ensure-prepare.png">
    </div>
    <div class="bottomBlock px20 mt30">
        <div class="bgSandal">
            <div class="row align-items-center no-gutters">
                <div class="col-5 text-center">
                    <img class="" src="../../../../assets/images/ensure/195.png">
                </div>
                <div class="col-7">
                    <p>แช่น้ำอุ่นหรือน้ำเย็น สะอาด 195 มล.</p>
                </div>
            </div>
            <div class="dashedBorderBlue mt30 mb30"></div>

            <div class="row align-items-center no-gutters">
                <div class="col-5 text-center">
                    <img class="" src="../../../../assets/images/ensure/6.png">
                </div>
                <div class="col-7">
                    <p>ตักเอนชัวร์ 6 ช้อน ปาดผสม</p>
                </div>
            </div>
            <div class="dashedBorderBlue mt30 mb30"></div>

            <div class="row align-items-center no-gutters">
                <div class="col-5 text-center">
                    <img class="" src="../../../../assets/images/ensure/ensure-yellow-milk-bottle.png">
                </div>
                <div class="col-7">
                    <p>คนให้เข้ากัน</p>
                </div>
            </div>
        </div>
        <p class="bottomContent mb-0">อาหารทางการแพทย์ต้องใช้ภายใต้คำแนะนำของแพทย์</p>
        <p class="smallContent text-right">ใบอนุญาตโฆษณาเลขที่ ฆอ. 3820/2564</p>
    </div>


</section>
<app-ensure-nav></app-ensure-nav>