<ul class="traceabilityNav">
    <li [ngClass]="{'activeClass': activeManufacturing}" routerLink="/manufacturing">
        <figure>
            <img src="../../../assets/images/home.png" alt="home" />
        </figure>
        <p>{{ 'TRACEABILITY_NAV.PRODUCT_PRODUCTION_STANDARDS' | translate }}</p>
    </li>
    <li [ngClass]="{'activeClass': activeQualityControl}" routerLink="/quality-control">
        <figure>
            <img src="../../../assets/images/search.png" alt="search" />
        </figure>
        <p>{{ 'TRACEABILITY_NAV.PRODUCT_QUALITY_CONTROL' | translate }}</p>
    </li>
    <li [ngClass]="{'activeClass': activeBranded}" routerLink="/brand">
        <figure>
            <img src="../../../assets/images/glass.png" alt="glass" />
        </figure>
        <p>{{ 'TRACEABILITY_NAV.PEDIASURE_BRAND_ONE' | translate }}<br />
            {{ 'TRACEABILITY_NAV.PEDIASURE_BRAND_TWO' | translate }}
        </p>
    </li>
    <li [ngClass]="{'activeClass': activeTriplePlus}" routerLink="/triple-plus">
        <figure>
            <img src="../../../assets/images/pediasure/pediasure-spoon.png" alt="triple plus" />
        </figure>
        <p>{{ 'TRACEABILITY_NAV.PEDIASURE_MIX_ONE' | translate }} <br />
            {{ 'TRACEABILITY_NAV.PEDIASURE_MIX_TWO' | translate }} 
        </p>
    </li>
    <li [ngClass]="{'activeClass': activeLogIn}" (click)="skipTrans()" >
        <figure class="familyClub">
            <img src="../../../assets/images/my_family_club_nav.png" alt="my family club" />
        </figure>
        <p>{{ 'TRACEABILITY_NAV.MY_FAMILY_CLUB' | translate }}</p>
    </li>
</ul>
