<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pediaSureContainer pediasure-plus-tasteless">
  <header class="traceabilityHeader">
    <img class="homeLogo" routerLink="/pediasureplus-traceability" src="../../../assets/images/home_logo_white.png">
    <img class="img-fluid mt-2" src="../../../assets/images/pedisurelogo.png">
</header>

  <div class="heading-icon mt-5">
    <img class="w-100" src="../../../assets/images/pediasure/pediasure-manufacturer-image.png" >

  </div>
  <div class="grd-box mt-3 text-center">
   <div class="grd-inner-div"> <span>ประเทศผู้ผลิต : {{manufactCountry}} <br>
    วันที่ผลิต {{manufacturedDate}}</span></div>
  </div>
  <div class="contentWrapper">
    <div class="text-type-24 text-left">
        เราภูมิใจที่เป็นโรงงานแห่งแรกในเอเชียที่ได้รับรางวัล 
    <span>Shingo Prize</span> ซึ่งเป็นมาตรฐานระดับโลก สำหรับ
    ความเป็นเลิศขององค์กรในปี ค.ศ. 2019
    
      </div>

      <div>
        <img class="w-100 mt-3" src="../../../assets/images/pediasure/abbotcompant-image.png">
      </div>
      <div class="text-type-22 mt-3 text-left">
        โรงงานผลิตของแอ๊บบอต ในประเทศสิงคโปร์ ได้ใช้เทคโนโลยีการผลิต
แบบระบบปิด ซึ่งเป็นระบบการควบคุมอัตโนมัติ และมีห้องปฏิบัติการ
สำหรับทำการวิจัยผลิตภัณฑ์ของแอ๊บบอตที่ได้มาตรฐาน เพื่อให้ได้
ผลิตภัณฑ์ที่มีคุณภาพและสอดคล้องตามเกณฑ์มาตรฐานสากล

      </div>
      
  </div>
  <div class="text-type-12 mt-3 ml-2">TH.2022.27391.PDS.1(v1.0)©2022Abbott</div>

</section>
<app-pediasure-plus-tasteless-nav></app-pediasure-plus-tasteless-nav>
