<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pediasure-plus-tasteless">
  <app-traceability-header
    class="traceabilityHomeheader"
  ></app-traceability-header>

  <div class="homeContainer">
    <h1>{{ "TRACEABILITY_HOME.PEDIA_SURE_PRODUCTS" | translate }}</h1>
    <div class="imgBlock _gutter1">
      <figure class="_210px" style="position: relative; top: -20px">
        <!-- <img
          style="width: 100%"
          *ngIf="isIsprint == false"
          class=""
          src="../../../assets/images/glucerna/pediasure-tastless-dummy.png"
          alt="pediasure"
        /> -->
        <img
          style="width: 100%"
          *ngIf="isIsprint == false"
          class="gpasImg"
          [src]="prodImage"
          alt="glucerna"
        />
        <img
          style="width: 100%"
          *ngIf="isIsprint == true"
          class=""
          [src]="productImage"
          alt="pediasure"
        />
      </figure>
      <div class="prdDetails">
        <span>{{ productName }} </span><br /><span
          >{{ "TRACEABILITY_HOME.LOT_NUMBER" | translate }}
          {{ lotNumber }}</span
        >
      </div>
      <i class="rightArrow"
        ><img
          src="../../../assets/images/home_right_arrow.png"
          alt="right arrow"
      /></i>
    </div>
    <div class="imgBlock _gutter2">
      <figure class="_255px">
        <img src="../../../assets/images/home_home.png" alt="home" />
      </figure>
      <div class="prdDetails">
        <p>
          <label
            >{{ "TRACEABILITY_HOME.COUNTRY_OF_ORIGIN" | translate }} :
          </label>
          <span> {{ manufactCountry }}</span>
        </p>
        <p>
          <label>{{ "TRACEABILITY_HOME.PRODUCTION_DATE" | translate }} </label>
          <span> {{ manufacturedDate }}</span>
        </p>
      </div>
      <i class="leftArrow"
        ><img src="../../../assets/images/home_left_arrow.png" alt="left arrow"
      /></i>
    </div>
    <div class="imgBlock _mt600px">
      <figure class="_158px">
        <img src="../../../assets/images/home_search.png" alt="search" />
      </figure>
      <div class="prdDetails">
        <span>{{ "TRACEABILITY_HOME.EXPIRATION_DATE" | translate }} </span
        ><br /><span>{{ expiryDate }}</span>
      </div>
    </div>
    <div class="triplePlusBlock">
      <p class="title-pediasure">
        {{ "TRACEABILITY_HOME.KNOW" | translate }}
        <!-- <span>{{ "TRACEABILITY_HOME.NUTRIENT" | translate }} </span> -->
      </p>
      <a [routerLink]="['/pediasureplus-product']" class="tracePrimaryBtn"
        ><span>{{ "TRACEABILITY_HOME.CLICK_IT" | translate }}</span></a
      >
    </div>
  </div>
</section>
<app-pediasure-plus-tasteless-nav></app-pediasure-plus-tasteless-nav>
