<div class="ensure-manufacture-container">
    <app-ensure-family-club></app-ensure-family-club>
  
    <!-- Singapore section -->
     <div class="ensuregold-singapore" >
      <div class="ensure-logo-section">
        <img
          class="img-fluid"
          src="../../../../assets/images/ensure-aw/vector-smart-object.png"
          alt="Ensure brand"
        />
        <a
          ><img
            class="img-fluid ensure-home-icon"
            src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
            alt="Ensure Home"
            routerLink="/ensureplantbased-home"
        /></a>
      </div>
  
      <div class="ensure-content-section">
        <img
          class="d-block mw-100"
          src="../../../../assets/images/ensure-aw/manfimg.png"
          alt="manufacture"
        />
        <div class="prod-details">
          <span class="mb-0">ประเทศผู้ผลิต : {{ manufactCountry }}</span>
          <span>วันที่ผลิต {{ manufacturedDate }}</span>
        </div>
        <p class="pb-3">
          เราภูมิใจที่เป็นโรงงานแห่งแรกในเอเชียที่ได้รับรางวัล
          <span class="light-yellow">Shingo Prize</span>
          ซึ่งเป็นมาตรฐานระดับโลก สำหรับ ความเป็นเลิศขององค์กรในปี ค.ศ. 2019
        </p>
        <img
          class="img-fluid"
          src="../../../assets/images/ensure-gold/Ensure-gold-abbott-img.jpg"
          alt="Abbott"
        />
        <p class="pt-4 txt-sm">
          โรงงานผลิตของแอ๊บบอต ในประเทศสิงคโปร์ได้ใช้เทคโนโลยีการผลิต แบบระบบปิด
          ซึ่งเป็นระบบการควบคุมอัตโนมัติและมีห้องปฏิบัติการ
          สำหรับทำการวิจัยผลิตภัณฑ์ของแอ๊บบอตที่ได้มาตรฐาน เพื่อให้ได้ผลิตภัณฑ์ที่มีคุณภาพและสอดคล้องตามเกณฑ์มาตรฐานสากล
        </p>
      </div>
  
      <div class="copyright-details text-uppercase">
        <p> TH.2022.34295.ENS.1 (v1.0) ©2023Abbott</p>
      </div>
    </div> 
  </div>
  <app-ensure-aw-menu></app-ensure-aw-menu>
  