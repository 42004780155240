<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pb150">
  <app-traceability-header></app-traceability-header>
  <section class="brandContainer px15">
    <p class="title-txt">พีเดียชัวร์ <br /><span>อาหารสูตรครบถ้วน</span></p>

    <div class="banner-section-1">
      <img
        src="../../../assets/images/pediasure/updated-images/Clinically-proven-growth.png"
      />
      <p>มีผลงานวิจัยว่า ช่วยการเจริญเติบโตทั้งน้ำหนักและส่วนสูง</p>
    </div>

    <div class="banner-section-2">
      <img
        src="../../../assets/images/pediasure/updated-images/pediasure-one-plus.png"
      />
      <p>
        <img
          src="../../../assets/images/pediasure/updated-images/triple-plus-text.png"
        />
      </p>
    </div>

    <div class="px-3">
      <div class="common-section">
        <img src="../../../assets/images/pediasure/updated-images/img-1.png" />
        <div class="content">
          <h4>วิตามิน และแร่ธาตุ <br />28 ชนิด</h4>
          <p>มีคุณค่าโภชนาการหลากหลาย มีประโยชน์ต่อร่างกาย</p>
        </div>
      </div>
  
      <div class="common-section">
        <div class="content">
          <h4>โปรตีน 3 ชนิด จากเวย์ เคซีน และถั่วเหลือง</h4>
          <p>
            โปรตีนจำเป็นต่อการเจริญเติบโต และช่วย ซ่อมแซมส่วนที่สึกหรอของร่างกาย
          </p>
        </div>
        <img src="../../../assets/images/pediasure/updated-images/img-2.png" />
      </div>
  
      <div class="common-section">
        <img src="../../../assets/images/pediasure/updated-images/img-3.png" />
        <div class="content">
          <h4>ฟรุคโตโอลิโก <br />แซคคาไรด์ (FOS)</h4>
          <p>สูตรผสมจุลินทรีย์ แลคโตบาซิลลัส แอซิโดฟิลัส</p>
        </div>
      </div>
  
      <div class="common-section space">
        <div class="content align pr-10">
          <h4>วิตามินบี 12</h4>
          <p>มีส่วนช่วยในการทำงานตามปกติ ของระบบประสาท และสมอง</p>
        </div>
        <img src="../../../assets/images/pediasure/updated-images/img-4.png" />
      </div>
  
      <div class="common-section">
        <img src="../../../assets/images/pediasure/updated-images/img-5.png" />
        <div class="content">
          <h4>ดีเอชเอ (DHA)</h4>
          <p>10 มก. ต่อหน่วยบริโภค</p>
        </div>
      </div>
  
      <div class="common-section space">
        <div class="content align">
          <h4 class="pr-10">โอเมก้า 3, 6, 9</h4>
        </div>
        <img src="../../../assets/images/pediasure/updated-images/img-6.png" />
      </div>
  
      <div class="common-section">
        <img src="../../../assets/images/pediasure/updated-images/img-7.png" />
        <div class="content">
          <h4>มีเดียมเชนไตร <br />กลีเซอไรด์ (MCT oil)</h4>
          <p>ให้พลังงาน</p>
        </div>
      </div>
    </div>

    <!-- <figure class="mb-0">
      <img
        class="img-fluid mt-2"
        src="../../../assets/images/brandapagetopimage.png"
      />
    </figure> -->

    <!-- <p class="smallText text-justify">
      {{ "TRACEABILITY_BRAND.PARA1" | translate }}
    </p> -->

    <!-- <figure>
      <img class="img-fluid" src="../../../assets/images/girrafe.png" />
    </figure> -->

    <!-- <figure class="text-center">
      <img
        class="img-fluid"
        src="../../../assets/images/pediasureclinical.png"
      />
    </figure> -->
    <!-- <figure>
      <img
        class="img-fluid"
        src="../../../assets/images/roundedcirclevoiletimage.png"
      />
    </figure> -->

    <!-- <figure>
      <img class="img-fluid" src="../../../assets/images/wheyimage.png" />
    </figure>
    <figure class="text-right">
      <img class="img-fluid" src="../../../assets/images/mctimage.png" />
    </figure>
    <figure class="text-left">
      <img class="img-fluid" src="../../../assets/images/dhaimage.png" />
    </figure>
    <figure class="text-right">
      <img class="img-fluid" src="../../../assets/images/fosimage.png" />
    </figure>
    <figure class="text-left">
      <img class="img-fluid" src="../../../assets/images/B12image.png" />
    </figure> -->
  </section>
  <!-- <div class="bottomContainer mt290">
    <div class="topBlock">
      <figure>
        <img
          class=""
          src="../../../assets/images/pediasure/updated-images/img-bottom.png"
        />
        <h1 class="Title text-center mt-2">
          {{ "TRACEABILITY_BRAND.TITLE1" | translate }}
        </h1>
      </figure>
    </div>
    <div class="body">
      <h1 class="Title">{{ "TRACEABILITY_BRAND.TITLE2" | translate }}</h1>
      <ul>
        <li>
          <div class="content">
            <p class="listContentPrimary mb-0">
              {{ "TRACEABILITY_BRAND.LIST_CONTENT_ONE_PRIMARY" | translate }}
            </p>
            <p class="listContentSecondary">
              {{ "TRACEABILITY_BRAND.LIST_CONTENT_ONE_SECONDARY" | translate }}
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <p class="listContentPrimary mb-0">
              {{ "TRACEABILITY_BRAND.LIST_CONTENT_TWO_PRIMARY" | translate }}
            </p>
            <p class="listContentSecondary">
              {{ "TRACEABILITY_BRAND.LIST_CONTENT_TWO_SECONDARY" | translate }}
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <p class="listContentPrimary mb-0">
              {{ "TRACEABILITY_BRAND.LIST_CONTENT_THREE_PRIMARY" | translate }}
            </p>
            <p class="listContentSecondary">
              {{
                "TRACEABILITY_BRAND.LIST_CONTENT_THREE_SECONDARY" | translate
              }}
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <p class="listContentPrimary mb-0">
              {{ "TRACEABILITY_BRAND.LIST_CONTENT_FOUR_PRIMARY" | translate }}
            </p>
            <p class="listContentSecondary">
              {{ "TRACEABILITY_BRAND.LIST_CONTENT_FOUR_SECONDARY" | translate }}
            </p>
          </div>
        </li>
      </ul>
      <figure>
        <img
          class="img-fluid mt60"
          src="../../../assets/images/pedioneplusthereplus.png"
        />
      </figure>

      <div class="row mt40 justify-content-center">
        <div class="col-10">
          <img
            class="img-fluid"
            src="../../../assets/images/flagimagemiddle.png"
          />
        </div>
      </div>
      <div class="mt30">
        <p class="smallContent mb-0 text-justify">
          {{ "TRACEABILITY_BRAND.SMALL_BOTTOM_DESC_ONE" | translate }}
        </p>
        <p class="smallContent mb-0 text-justify mt-1">
          {{ "TRACEABILITY_BRAND.SMALL_BOTTOM_DESC_TWO" | translate }}
        </p>
      </div>
      <div class="text-right mt30">
        <p class="smallContent">
          {{ "TRACEABILITY_BRAND.SMALL_BOTTOM_DESC_THREE" | translate }}
        </p>
      </div>
    </div>
  </div> -->
</section>
<div class="bottom-section">
  <img src="../../../assets/images/pediasure/updated-images/glass-img.png" />
  <div class="content-section">
    <div class="txt">
      <p class="fs-1">
        สามารถใช้ทดแทนนมหรือ เสริมร่วมกับอาหารปกติ เพื่อให้มีภาวะโภชนาการที่ดี
      </p>
      <p class="fs-small">อาหารทางการแพทย์ ต้องใช้ภายใต้คำแนะนำของแพทย์</p>
    </div>

    <div class="terms">
      <span>ใบอนุญาตโฆษณาเลขที่ ฆอ.1692/2565</span>
      <span class="text-uppercase">TH.2022.26826.PDS.1(v1.0)©2022Abbott</span>
    </div>
  </div>

  <div class="youtube-video">
    <div _ngcontent-lpd-c150="" class="embed-responsive embed-responsive-16by9">
      <iframe
        _ngcontent-lpd-c150=""
        width="560"
        height="315"
        src="https://www.youtube.com/embed/-AWB_2Jd2cA"
        title="Pediasure plus"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
        class="promo-video-link"
      ></iframe>
    </div>
  </div>
</div>

<app-traceability-nav></app-traceability-nav>
