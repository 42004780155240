<div class="ensure-container">
  <app-ensure-family-club></app-ensure-family-club>
  <!-- <div class="ensure-family-club">
    <img
      class="img-fluid"
      src="../../../assets/images/ensure-gold/Ensure-gold-family-club.png"
      alt="My Family Club"
    />
  </div> -->
  <!-- Singapore section -->
  <div *ngIf="manufactCountry == 'สิงคโปร์' || manufactCountry == 'Singapore' || manufactCountry == 'SINGAPORE' || manufactCountry == 'singapore'">
    <div class="ensure-logo-section">
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/ensure-logo.webp"
        alt="Ensure brand"
      />
      <a
        ><img
          class="img-fluid ensure-home-icon"
          src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
          alt="Ensure Home" routerLink="/ensureh-traceability"
      /></a>
    </div>
  
    <img
      class="img-fluid mt-30 px-3"
      src="../../../assets/images/ensureh/ensureh-manufacture-img.png"
    />
  
    <div class="content-section">
      <div class="prod-details">
        ประเทศผู้ผลิต : {{manufactCountry}}<br />
        วันที่ผลิต {{manufacturedDate}}
      </div>
      <p class="mt-4">
        เราภูมิใจที่เป็นโรงงานแห่งแรกในเอเชียที่ได้รับรางวัล
        <span class="txt-color">Shingo Prize</span>
        ซึ่งเป็นมาตรฐานระดับโลก สำหรับ ความเป็นเลิศขององค์กรในปี ค.ศ. 2019
      </p>
  
      <img
        class="img-fluid"
        src="../../../assets/images/ensureh/ensureh-abbott-img.png"
      />
      <p class="mt-3 txt-regular">
        โรงงานผลิตของแอ๊บบอต ในประเทศสิงคโปร์ ได้ใช้เทคโนโลยีการผลิต แบบระบบปิด
        ซึ่งเป็นระบบการควบคุมอัตโนมัติ และมีห้องปฏิบัติการ
        สำหรับทำการวิจัยผลิตภัณฑ์ของแอ๊บบอตที่ได้มาตรฐาน เพื่อให้ได้
        ผลิตภัณฑ์ที่มีคุณภาพและสอดคล้องตามเกณฑ์มาตรฐานสากล
      </p>
    </div>
    <p class="terms-of-use text-uppercase">TH.2022.26825.ENS.1(v1.0)©2022Abbott</p>
  </div>


  <!-- Netherland section -->
  <div *ngIf="manufactCountry == 'เนเธอร์แลนด์' || manufactCountry == 'Netherlands' || manufactCountry == 'NETHERLANDS' || manufactCountry == 'netherlands'">
    <div class="ensure-logo-section">
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/ensure-logo.webp"
        alt="Ensure brand"
      />
      <a
        ><img
          class="img-fluid ensure-home-icon"
          src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
          alt="Ensure Home"
          routerLink="/ensureh-traceability"
      /></a>
    </div>

    <img
      class="img-fluid mt-30 px-3"
      src="../../../assets/images/ensureh/ensureh-manufacture-img.png"
    />

    <div class="content-section">
      <div class="prod-details">
        ประเทศผู้ผลิต : {{ manufactCountry }}<br />
        วันที่ผลิต {{ manufacturedDate }}
      </div>
      <p class="mt-4">
        โรงงานผลิตของแอ๊บบอต ในประเทศเนเธอร์แลนด์
        ได้ผลิตและพัฒนาผลิตภัณฑ์ด้านโภชนาการมากกว่า 180 ผลิตภัณฑ์
        เพื่อครอบคลุมความต้องการที่ หลากหลายทั่วโลก
      </p>

      <img
        class="img-fluid"
        src="../../../assets/images/ensureh/Ensure-gold-netherlands-img.png"
      />
      <p class="mt-3 txt-regular">
        แอ๊บบอตใช้เทคโนโลยีที่ทันสมัยในกระบวนการผลิต เพื่อให้ได้
        ผลิตภัณฑ์ที่มีคุณภาพ นอกจากนี้เรายังมุ่งมั่นที่จะลดการใช้พลังงาน
        และผลกระทบต่อสิ่งแวดล้อม
      </p>
    </div>
    <p class="terms-of-use text-uppercase">
      TH.2022.26825.ENS.1(v1.0)©2022Abbott
    </p>
  </div>
</div>
<app-ensure-cereal-nav></app-ensure-cereal-nav>
