<ul class="traceabilityNav">
  <li [ngClass]="{ activeClass: activeManufacturing }" routerLink="/pediasureplus-manufacturing">
    <figure>
      <img src="../../../assets/images/home.png" alt="home" />
    </figure>
    <p>{{ "TRACEABILITY_NAV.PRODUCT_PRODUCTION_STANDARDS" | translate }}</p>
  </li>
  <li [ngClass]="{ activeClass: activeQualityControl }" routerLink="/pediasureplus-qc">
    <figure>
      <img src="../../../assets/images/search.png" alt="search" />
    </figure>
    <p>{{ "TRACEABILITY_NAV.PRODUCT_QUALITY_CONTROL" | translate }}</p>
  </li>
  <li [ngClass]="{ activeClass: activeBranded }" routerLink="/pediasureplus-product">
    <figure>
      <img src="../../../assets/images/glass.png" alt="glass" />
    </figure>
    <p>{{ "TRACEABILITY_NAV.KNOW_PEDIA_SURE_PLUS_ONE" | translate }}<br>{{ "TRACEABILITY_NAV.KNOW_PEDIA_SURE_PLUS_TWO" | translate }}</p>
  </li>
  <li [ngClass]="{ activeClass: activeTriplePlus }" routerLink="/pediasureplus-mixinstruction">
    <figure>
      <img src="../../../assets/images/pediasure/pediasure-spoon.png" alt="Pediasure Mix Instruction" />
    </figure>
    <p>{{ "TRACEABILITY_NAV.HOW_TO_PREPARE_PEDIASURE_ONE" | translate }}<br>{{ "TRACEABILITY_NAV.HOW_TO_PREPARE_PEDIASURE_TWO" | translate }}</p>
  </li>
  <li [ngClass]="{ activeClass: activeLogIn }" (click)="skipTrans()" >
    <a >
    <figure class="familyClub">
      <img
        src="../../../assets/images/my_family_club_nav.png"
        alt="my family club"
      />
    </figure>
    <p>{{ "TRACEABILITY_NAV.MY_FAMILY_CLUB" | translate }}</p>
  </a>
  </li>
</ul>
