<div class="ensure-container">
  <app-ensure-family-club></app-ensure-family-club>
  <!-- <div class="ensure-family-club">
    <img
      class="img-fluid"
      src="../../../assets/images/ensure-gold/Ensure-gold-family-club.png"
      alt="My Family Club"
    />
  </div> -->

  <div class="ensure-logo-section">
    <img
      class="img-fluid"
      src="../../../assets/images/ensure-gold/ensure-logo.webp"
      alt="Ensure brand"
    />
    <a
      ><img
        class="img-fluid ensure-home-icon"
        src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
        alt="Ensure Home" routerLink="/ensuregold-traceability"
    /></a>
  </div>

  <div class="prepare-section">
    <p class="fn-shadow mb-0">
      วิธีเตรียม <br />
      <img
        src="../../../assets/images/ensure-gold/how-to-prapare.png"
        alt="how to prepare"
      />
      <br />
    </p>
    <span class="text-gradient">โกลด์</span>

    <div class="product-card">
      <img
        class="img-fluid mb-3"
        src="../../../assets/images/ensure-gold/ensure-cold-water-img.png"
      />
      <p>เตรียมน้ำอุ่นหรือเย็น ที่สะอาด 185 มล.</p>
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/ensure-mix-powder-img.png"
      />
      <p>
        ผสมผง เอนชัวร์ โกลด์ ทีละช้อนตวงปาด แล้วคน ให้เข้ากันจนละลายหมด ครบ 6
        ช้อน (60.6 กรัม)
      </p>
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/ensure-230ml-img.png"
      />
      <p class="br-none">จะได้ส่วนผสม 230 มล.</p>
    </div>

    <div class="how-to-use">
      <a href="#">การเก็บรักษา</a>
      <ul>
        <li>
          เมื่อเปิดกระป๋องแล้วควรปิดกระป๋องให้แน่นทุกครั้งหลังใช้
          เก็บไว้ในที่เย็นและแห้ง (ไม่ควรเก็บไว้ในตู้เย็น) และควรใช้ให้ หมดภายใน
          3 สัปดาห์
        </li>
        <li>
          เมื่อผสมแล้วควรดื่มทันที หากดื่มไม่หมด ให้ปิดฝา และเก็บในตู้เย็น
          ดื่มให้หมดภายใน 24 ชั่วโมง
        </li>
      </ul>

      <p class="fn-10 mb-0 text-uppercase">TH.2022.27390.ENS.1(v1.0)©2022Abbott</p>
    </div>
  </div>
</div>
<app-ensure-gold-cereal-nav></app-ensure-gold-cereal-nav>
