import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss']
})
export class ConditionsComponent implements OnInit {

  condition: any;
  backData:any
  constructor(private apiService: ApiService, private spinner: NgxSpinnerService) {
    this.backData =  sessionStorage.getItem('cmspage');
    console.log(this.backData, 'backData')
    if(this.backData == 'gotoCms'){
      sessionStorage.setItem('patchTermsData', 'patchData');
    }
   }

  ngOnInit(): void {
    this.getCondition();
  }

  getCondition() {
    this.spinner.show();
    this.apiService.getTermsConditionAbout().subscribe((res:any) => {
      this.spinner.hide();
      res.data.forEach(item => {
        if (item.key == "conditions") {
          this.condition = item.contentvalue;
        }
      })
    })
  }
  ngOnDestroy(){
    sessionStorage.removeItem('cmspage');
  }
}
