<section class="globalContainer pb-3 _100vh">
    <app-header-family-club></app-header-family-club>
    <form class="formContainer" [formGroup]="editPasswordForm" (ngSubmit)="onSubmitEditPasswordForm()">
        <h5 class="secondaryBlue mt-3 mb-4">{{ 'EDIT_PASSWORD.TITLE1' | translate }}</h5>

        <section class="px-4">
            <div class="form-group">
                <img class="fieldImg" src="../../../assets/images/padlock.png" />
                <input type="password" class="form-control" id="password"
                    placeholder="{{ 'EDIT_PASSWORD.PLACEHOLDER1' | translate }}" formControlName="password"
                    [ngClass]="{'is-invalid': submitted && editPassFormVal.password.errors} || passworderror ">
                <div *ngIf="submitted && editPassFormVal.password.errors && !passworderror" class="invalid-feedback">
                    <div *ngIf="editPassFormVal.password.errors.required">Enter password</div>
                </div>
                <div class="text-danger" *ngIf="submitted && passworderror">
                    รหัสผ่านเก่าของคุณไม่ถูกต้อง
                </div>
            </div>

            <div class="form-group">
                <img class="fieldImg" src="../../../assets/images/padlock.png" />
                <input type="password" class="form-control" id="resetPassword"
                    placeholder="{{ 'EDIT_PASSWORD.PLACEHOLDER2' | translate }}" formControlName="resetPassword"
                    [ngClass]="{'is-invalid': submitted && editPassFormVal.resetPassword.errors}">
                <div *ngIf="submitted && editPassFormVal.resetPassword.errors" class="invalid-feedback">
                    <div *ngIf="editPassFormVal.resetPassword.errors.required">Enter Reset password</div>
                </div>
            </div>

            <div class="form-group">
                <img class="fieldImg" src="../../../assets/images/padlock.png" />
                <input type="password" class="form-control" id="confirmNewPassword"
                    placeholder="{{ 'EDIT_PASSWORD.PLACEHOLDER3' | translate }}" formControlName="confirmNewPassword"
                    [ngClass]="{'is-invalid': submitted && editPassFormVal.confirmNewPassword.errors}">
                <div *ngIf="submitted && editPassFormVal.confirmNewPassword.errors" class="invalid-feedback">
                    <div *ngIf="editPassFormVal.confirmNewPassword.errors.required">Enter Reset password</div>
                    <div *ngIf="editPassFormVal.confirmNewPassword.errors.mustMatch">Password confirmation does not
                        match</div>
                </div>
                <div class="text-danger" *ngIf="submitted && oldPassworderror">
                    รหัสผ่านใหม่และรหัสผ่านเก่าต้องไม่เหมือนกัน
                </div>
            </div>

        </section>
        <button type="submit" class="btn btn-block goldenBottomBtn mt-3 mb-3 fontKanitMedium">{{
            'EDIT_PASSWORD.BTN_TEXT' | translate }}</button>

    </form>

</section>


<!-- forgot otp field modal -->
<div bsModal #forgotOtpModal="bs-modal" class="modal fade customModalContainer" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">

                <form [formGroup]="forgotOtpForm" class="formContainer">
                    <section class="text-center">
                        <button type="button" class="close" aria-label="Close" (click)="closeForgotOtpModal()">
                            <!-- <span aria-hidden="true">&times;</span> -->
                            <img src="../../../assets/images/close.png" />
                        </button>
                        <h5 class="primaryBlue font-weight-bold">{{ 'FORGOT_OTP_MODAL.TITLE1' | translate }}</h5>
                        <h5 class="secondaryBlue mt30">{{ 'FORGOT_OTP_MODAL.TITLE2' | translate }}</h5>
                        <!-- <h5>{{ 'FORGOT_OTP_MODAL.TITLE3' | translate }}</h5> -->
                        <h5 *ngIf="phone_no">{{ storedMobNum }}</h5>

                        <!-- <h5 class="darkBrown mt20 text-uppercase">Ref code : {{ 'FORGOT_OTP_MODAL.REF_CODE' | translate
                            }} </h5> -->

                        <h5 class="secondaryBlue mt30 mb-4 font-weight-bold">{{ 'FORGOT_OTP_MODAL.TITLE4' | translate }}
                        </h5>
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <ng-otp-input (onInputChange)="onOtpChange($event)"
                                    [config]="{length:6,placeholder:'0',allowNumbersOnly: true}"
                                    [ngClass]="{'is-invalid' : submittedOtpForm  && (otp == undefined || otp.length < 6) || invalidOtp }"
                                    #ngOtpInput>
                                </ng-otp-input>

                                <div *ngIf="submittedOtpForm && forgotOtpFormVal.otp?.errors?.required" class="text-danger pl-0 mt-2">
                                    <div *ngIf="activeClassButton">ต้องระบุ otp</div>
                                </div>

                                <div *ngIf="invalidOtp" class="text-danger pl-0">
                                    <div>{{ 'FORGOT_OTP_MODAL.OTP_ERROR' | translate }}</div>
                                </div>

                                <!-- <p class="mt-4 underlinedText lightBlue">{{ 'FORGOT_OTP_MODAL.LINK_TEXT' | translate }}
                                </p> -->

                                <p *ngIf="!showResendOtp" class="colorGrey mt-4">{{ 'REG_OTP_MODAL.LINK_TEXT_LEFT' | translate }}
                                    <span class="primaryBlue font-weight-bold" *ngIf="beginTimer">
                                        <countdown #cd [config]="{leftTime: 60,format: 'ss',notify: [ 2, 5 ]}"
                                        (event)="handleEvent($event)"></countdown> {{ 'REG_OTP_MODAL.LINK_TEXT_RIGHT' | translate }}</span></p> 
    
                                    <p *ngIf="showResendOtp" class="mt-4 underlinedText lightBlue" (click)="resendOTP()" (click)="cd.restart()">
                                        {{ 'FORGOT_OTP_MODAL.LINK_TEXT' | translate }}
                                    </p>

                                <button type="submit" class="btn btn-block goldenBtn my-4"
                                    (click)="onSubmitForgotOtpForm()">{{ 'FORGOT_OTP_MODAL.BTN_TEXT' | translate
                                    }}</button>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>
    </div>
</div>