import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensureh-product',
  templateUrl: './ensureh-product.component.html',
  styleUrls: ['./ensureh-product.component.scss']
})
export class EnsurehProductComponent implements OnInit {
  productImage: string;

  constructor() {
    window.scrollTo({ top: 0 });
   }

  ngOnInit(): void {
  }

}
