import { Component, OnInit } from '@angular/core';
import { environment as env } from '../../environments/environment'
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { BugsnagService } from '../services/bugsnag.service';
declare var $:any;
@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit {
  buzzeebeeUrl:any;
  constructor(
    private spinner: NgxSpinnerService, 
    private apiService: ApiService, 
    private bsService: BugsnagService
    ) { 
    this.buzzeebeeUrl = env.BUZZEEBEE_URL;
  }

  ngOnInit(): void {
  let abbott_token;
  let auth_key;
  let abbott_user_id =  sessionStorage.getItem('abbottUserId')
  let getMobile = sessionStorage.getItem('mobileNo')
  let getAuthToken = sessionStorage.getItem('authToken')
  let getAuthKey = sessionStorage.getItem('authKey')
  //sessionStorage.setItem('authKey', res['auth'].key)
  let formData = {
    mobile: getMobile,
    brand_id: env.BRAND,
    device_id: env.DEVICE_ID,
    auth_token: getAuthToken,
    auth_key: getAuthKey
  }
  this.spinner.show();
  this.apiService.buzzbeetokengen(formData).subscribe((res: any)=>{
    console.log(res, 'buzz bee token gen')
    abbott_token = res.result.abbott_token;
    auth_key = res.result.auth_key;
    console.log(abbott_token, 'res.result.abbott_token');
    (<HTMLInputElement>document.getElementById("abbott_token")).value = abbott_token;
    (<HTMLInputElement>document.getElementById("auth_key")).value = auth_key;
    (<HTMLInputElement>document.getElementById("abbott_user_id")).value = abbott_user_id;
    (<HTMLInputElement>document.getElementById("target_page")).value = 'reward';
    $("#formSubmit").submit();
    this.spinner.hide();
    console.log('Sending request to '+$('#formSubmit').attr('action')+' with data: '+$('#formSubmit').serialize());
  }, err => {
    this.bsService.notifyError(err, formData);
  })


  //$(document).ready(function(){
    //this.spinner.show();
     // $('#buttonSubmit').click(function(){
  
        // $.ajax({
        //   url: `${env.SALESFORCE_BASE_URL}/token/generate`,
        //   type: "post",
        //   data: JSON.stringify(formData),
        //   contentType: "application/json",
        //   success: function(res) {
        //     //this.spinner.hide();
        //     console.log(res,'res')
        //     abbott_token = res.result.abbott_token;
        //     auth_key = res.result.auth_key;
        //     (<HTMLInputElement>document.getElementById("abbott_token")).value = abbott_token;
        //     (<HTMLInputElement>document.getElementById("auth_key")).value = auth_key;
        //     (<HTMLInputElement>document.getElementById("abbott_user_id")).value = abbott_user_id;
        //     $("#formSubmit").submit();
        //     console.log('Sending request to '+$('#formSubmit').attr('action')+' with data: '+$('#formSubmit').serialize());
        //   }
        // });
      //})
   // })
  }
}
