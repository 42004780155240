import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BugsnagService } from 'src/app/services/bugsnag.service';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  terms: any;
  backData:any;
  constructor(
    private apiService: ApiService, 
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
    ) {
    this.backData =  sessionStorage.getItem('cmspage');
   console.log(this.backData, 'this.backData')
   if(this.backData == 'gotoCms'){
     sessionStorage.setItem('patchTermsData', 'patchData');
   }

   }

  ngOnInit(): void {
    this.getTerms();
  }

  getTerms() {
    this.spinner.show();
    this.apiService.getTermsConditionAbout().subscribe((res: any) => {
      this.spinner.hide();
      console.log('loopeditem',res);
      
      res.data.forEach(item =>{
        if (item.key == 'terms') {
         this.terms = item.contentvalue;
        }
      })
    }, err => {
      this.bsService.notifyError(err);
    })
  }

  ngOnDestroy(){
    sessionStorage.removeItem('cmspage');
  }


}
