<div class="ensure-qc-container">
  <app-ensure-family-club></app-ensure-family-club>

  <div class="ensure-logo-section">
    <img
      class="img-fluid"
      src="../../../assets/images/ensure-gold/ensure-logo.webp"
      alt="Ensure brand"
    />
    <a
      ><img
        class="img-fluid ensure-home-icon"
        src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
        alt="Ensure Home" routerLink="/ensuregold-traceability"
    /></a>
  </div>

  <div class="ensure-content-section">
    <div class="ensure-img">
      <img
      class="d-block mw-100"
      src="../../../assets/images/ensure-gold/ensure-qc-new-img.png"
      alt="manufacture"
    />

    <span>การควบคุมคุณภาพสินค้า</span>
    </div>
    <div class="prod-details">
      <span>วันหมดอายุ : {{expiryDate}}</span>
    </div>
    <p class="pb-3 txt-sm">
      สำหรับแอ๊บบอต การควบคุมคุณภาพในกระบวนการผลิตถือเป็น สิ่งสำคัญที่สุด
      เพื่อให้มั่นใจได้ว่าบริษัทฯ จะสามารถผลิตสินค้าที่มี
      คุณภาพและมีความปลอดภัยอยู่เสมอ
    </p>
    <img
      class="img-fluid"
      src="../../../assets/images/ensure-gold/ensure-gold-qc-img2.png"
      alt="Abbott"
    />
    <p class="pt-4 txt-sm">
      ตั้งแต่ขั้นตอนการรับวัตถุดิบสำหรับใช้ในการผลิตสินค้า จนกระทั่ง
      การผลิตสินค้าออกจากโรงงาน ในทุกขั้นตอนของการผลิตได้ผ่าน
      การตรวจสอบอย่างเข้มงวดโดยผู้เชี่ยวชาญด้านการประกันคุณ ภาพ
      ที่ผ่านการฝึกอบรม พร้อมด้วยการใช้อุปกรณ์การตรวจสอบ ที่ทันสมัย
    </p>
  </div>

  <div class="copyright-details text-uppercase">
    <p>TH.2022.27390.ENS.1(v1.0)©2022Abbott</p>
  </div>
</div>
<app-ensure-gold-cereal-nav></app-ensure-gold-cereal-nav>
