<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pb150">
  <app-traceability-header></app-traceability-header>
  <section class="qualityContainer">
    <div class="position-relative">
      <div class="productImgBlock mt30"></div>
      <div class="productBlock">
        <p>{{ "QUALITY_CONTROL.PRODUCTION_TEXT" | translate }}</p>
      </div>
    </div>
    <div class="cardBlockOut px-2 pb-4 mt30 mb-2">
      <div class="dateBlock text-center py-2">
        <p class="mb-0">
          {{ "QUALITY_CONTROL.COUNTRY_ORIGIN" | translate }} :
          {{ manufactCountry }}
        </p>
        <p class="mb-0">
          {{ "QUALITY_CONTROL.PRODUCTION_DATE" | translate }}
          {{ manufacturedDate }}
        </p>
      </div>

      <p class="text-justify cardImgTopDescriptionQuality mb-0">
        {{ "QUALITY_CONTROL.CARD_TOP_DESC_BEFORE" | translate }}
        <span class="">{{
          "QUALITY_CONTROL.CARD_TOP_MIDDLE_DESC" | translate
        }}</span>
        {{ "QUALITY_CONTROL.CARD_TOP_DESC_AFTER" | translate }}
      </p>
      <div>
        <figure>
          <img
            class="img-fluid mt40 w-100"
            src="../../../assets/images/abbotcompantImage.png"
          />
        </figure>
      </div>
      <p class="text-justify cardDescription mt40">
        {{ "QUALITY_CONTROL.CARD_BOTTOM_DESC" | translate }}
      </p>
    </div>
  </section>
  <div class="copyright-details text-uppercase">
    <p>TH.2022.26826.PDS.1(v1.0)©2022Abbott</p>
  </div>
</section>

<app-traceability-nav></app-traceability-nav>
