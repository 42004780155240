import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment as env } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import liff from '@line/liff';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-ensure-nav',
  templateUrl: './ensure-nav.component.html',
  styleUrls: ['./ensure-nav.component.scss']
})
export class EnsureNavComponent implements OnInit {
  activePath: any;
  activeLogIn: boolean = false;
  activeQualityControl: boolean = false;
  activeBranded: boolean = false;
  activeManufacturing: boolean = false;
  activeTriplePlus: boolean = false;
  scanCode: string;
  constructor(private route: ActivatedRoute,private apiService: ApiService,
    @Inject(DOCUMENT) private document: Document,private router: Router) {
    this.activePath = this.route.snapshot.routeConfig.path;
   }

  ngOnInit(): void {
    if (this.activePath === 'ensure-manufacturing') {
      this.activeManufacturing = true;
    }
    if (this.activePath === 'ensure-qualitycontrol') {
      this.activeQualityControl = true;
    }
    if (this.activePath === 'ensure-product') {
      this.activeBranded = true;
    }
    if (this.activePath === 'ensure-mixinstruction') {
      this.activeTriplePlus = true;
    }
    if (this.activePath === 'login') {
      this.activeLogIn = true;
     }
  }


  skipTrans() {
    sessionStorage.setItem('skipTrans', 'skiptrans');
    this.scanCode = 'xyz'
    let iSprintData:any = JSON.parse(sessionStorage.getItem('iSprintData'));
    // changes done for gpass
    if(iSprintData.is_isprint == false) this.scanCode = 'xyz'
  // let loc= `${env.LINE_CONSENT_URL}&client_id=${env.ACCOUNTID}&redirect_uri=${env.REDIRECT_URI}?scan=${this.scanCode}&bot_prompt=${env.BOT_PROMPT}&state=${env.STATE}&nonce:09876xyz&scope=profile%20openid`
 // const popUp = window.open('', '_blank');
  // if (!popUp || popUp.closed || typeof popUp.closed == 'undefined') {
  //  console.log('Pop-up blocked');
  //  alert("ป๊อปอัปถูกบล็อก! โปรดเปิดใช้งานการตั้งค่าเบราว์เซอร์เพื่อแสดงป๊อปอัป")
  // } else {
  // console.log('Pop-up not blocked');
   let loc = `${env.LIFF_URL}?scancode=${this.scanCode}`   
   liff.openWindow({
    url: loc,
    external: false,
  })
// }
  }
}
