<app-ensure-family-club></app-ensure-family-club>
<section
  class="traceAbilityContainer pediaSureContainer pediasure-plus-tasteless"
>
<header class="traceabilityHeader">
  <img class="homeLogo" routerLink="/pediasureplus-traceability" src="../../../assets/images/home_logo_white.png">
  <img class="img-fluid mt-2" src="../../../assets/images/pedisurelogo.png">
</header>
  <div class="text-type-70 mt-4 text-center">วิธีเตรียม</div>
  <div class="text-type-90 text-center">พีเดียชัวร์+</div>

  <div class="contentWrapper">
    <div class="heading-icon mt-2 text-center">
      <img src="../../../assets/images/pediasure/pediadsue-190.png" />
    </div>
    <div class="text-type-32 text-center mt-3 pb-3">
      เตรียมน้ำสุกที่เย็นแล้ว 1 แก้ว<br />
      (190 มล.)
    </div>
    <div class="heading-icon mt-5 text-center">
      <img
        class="pediasure-x5"
        src="../../../assets/images/pediasure/pediasure-x5.png"
      />
    </div>
    <div class="text-type-32 text-center mt-2 pb-3">
      แล้วค่อยๆ ผสมผงผลิตภัณฑ์ 5 ช้อนตวงปาด (ที่มีในกล่อง) หรือ 48.6 กรัม
      คนจนละลายเข้ากันดี
    </div>
    <div class="heading-icon mt-5 text-center">
      <img src="../../../assets/images/pediasure/pediasure-230.png" />
    </div>
    <div class="text-type-32 text-center mt-2">
      จะได้ส่วนผสม 225 มล.<br />
      ที่ให้พลังงาน 230 กิโลแคลอรี
    </div>
  </div>
  <div class="bottom-div">
    <img
      class="w-100"
      src="../../../assets/images/pediasure/cross-stroke-line.png"
    />
    <div class="bottom-div-content">
      <div class="custom-btn text-center">การเก็บรักษา</div>
      <ul class="mt-3">
        <li>
          เมื่อเปิดซองอะลูมีเนียมแล้ว ควรเก็บในภาชนะที่มี ฝาปิดมิดชิดที่สะอาด
          และปิดฝาให้แน่นทุกครั้ง เก็บไว้ในที่แห้งและเย็น ไม่ควรเก็บในตู้เย็น
          และควร บริโภคให้หมดภายใน 3 สัปดาห์
        </li>
        <li>
          หลังจากผสมน้ำแล้วควรดื่มทันที หากดื่มไม่หมด ควรเก็บไว้ในภาชนะที่ปิดฝา
          เก็บในตู้เย็น และใช้ให้ หมดภายใน 24 ชั่วโมง
        </li>
        <li>
          ควรปฏิบัติตามคำแนะนำในการเก็บรักษา หาก
          เก็บรักษาไม่ถูกต้องอาจมีผลทำให้กลิ่นเปลี่ยน
        </li>
      </ul>
    </div>
    <div class="text-type-12 mt-3 ml-2">
      TH.2022.27391.PDS.1(v1.0)©2022Abbott
    </div>
  </div>
</section>
<app-pediasure-plus-tasteless-nav></app-pediasure-plus-tasteless-nav>
