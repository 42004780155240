import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CountryListService } from 'src/app/services/country-list.service';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  selectedItemsList = [];
  checkedIDs = [];
  checkboxesDataList = [
    {
      id: 'End User',
      label: 'รับประทานเอง',
      isChecked: false
    },
    {
      id: 'Caregiver',
      label: 'ซื้อให้ผู้อื่น',
      isChecked: false
    },
  ]
  editForm: FormGroup;
  editOtpForm: FormGroup;
  submitted = false;
  mainProvienceData: any;
  formArr: FormGroup;
  addField: boolean = false;
  count: any = 0;
  registerForm: any = {};
  countryList: any = [];
  countryData: any = [];
  provience: any = [];
  district: any = [];
  customerData: any = [];
  emailNotPresent: boolean = false;
  emailPoints: any;
  child1_present: boolean = false;
  child2_present: boolean = false;
  child3_present: boolean = false;
  child4_present: boolean = false;
  emailChildPts: any;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private countryListData: CountryListService, 
    private bsService: BugsnagService
  ) {
    // console.log(this.countryListData.jsondata[0], 'Country List JSON')
    // this.countryList = this.countryListData.jsondata[0]
    // this.mainProvienceData = Object.values(this.countryList);
    // console.log(this.mainProvienceData, 'this.mainProvienceData')

    // console.log(this.countryListData.jsondata01[0], 'Country List JSON');
    this.countryList = this.countryListData.jsondata01[0];
    this.mainProvienceData = Object.values(this.countryList);
    // console.log(this.mainProvienceData, 'this.mainProvienceData');

    let d = new Date();
    //  this.currentMonth = d.getMonth();\
    let cr = moment(d).format('YYYY-MM-DD');
    let currentYear = d.getFullYear();
    let before_tenyears = moment(d).subtract(10, 'years').format('YYYY-MM-DD');

    console.log(d, 'd--');
    console.log(cr, 'cr--');
    console.log(currentYear, 'currentYear');
    console.log(before_tenyears, 'before_tenyears');

    // this.generateDates(cr, before_tenyears);
  }
  submitEditOtpForm = false;
  invalidOtp: boolean = false;
  otp: string;
  activeClassButton: boolean = false;
  showFormArray: boolean = false;
  checkFilled: any = [];
  patch_district: any;
  patch_county: any;
  county: any = [];
  patch_provience: any;
  // bdayError: boolean = false;
  // ChildNameError: boolean = false;
  bdayError: any = [false, false, false, false];
  ChildNameError: any = [false, false, false, false];
  errCheck: any = [];
  yearList: any = [];
  yearList1: any = [];
  emailExsistError: boolean = false;
  childPoints: any;
  @ViewChild('editOtpModal', { static: true }) editOtpModal: ModalDirective;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  @ViewChild('successEmailModal', { static: true })
  successEmailModal: ModalDirective;
  @ViewChild('successChildModal', { static: true })
  successChildModal: ModalDirective;
  @ViewChild('successEmailChildModal', { static: true })
  successEmailChildModal: ModalDirective;
  @ViewChild('successProfileUpdateModal', { static: true })
  successProfileUpdateModal: ModalDirective;

  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: false,
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: true,
  };

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      mobileNumber: ['', [Validators.pattern('^((\\+63-?)|0)?[0-9]{10}$')]],
      email: [''],
      // email: ['', [Validators.pattern('^[a-zA-Z0-9\\W_]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      password: [''],
      houseNumber: ['', Validators.required],
      swine: [''],
      buildingVillage: [''],
      alley: [''],
      road: [''],
      province: ['', Validators.required],
      postalCode: ['', Validators.required],
      country: ['', Validators.required],
      district: ['', Validators.required],
      birthDay1: ['',],
      month1: ['',],
      year1: ['',],

      //  childForm: this.formBuilder.array([this.addChildFields()]),
      childForm: this.formBuilder.array([]),
    });
    this.editOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
    this.getCustomer();
    this.findRange();
    this.findRangePro()
  }

  getDaysBetweenDates(startDate, endDate) {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format('DD-MM-YYYY'));
      now.add(1, 'years');
    }
    return dates;
  }

  findRange() {
    let curr_date = new Date();
    let yearValues: any = [];

    let start = moment(curr_date).format('YYYY-MM-DD');
    let end = moment(curr_date).subtract(10, 'years').format('YYYY-MM-DD');

    let startDate = moment(start);
    let endDate = moment(end);

    // let startDate = moment('2012-01-02');
    // let endDate = moment('2002-05-12');

    console.log(startDate, 'startDate');
    console.log(endDate, 'endDate');

    let dateList = this.getDaysBetweenDates(endDate, startDate);
    console.log(dateList);

    dateList.filter((dates) => {
      let dateSplit = dates.split('-');
      yearValues.push(dateSplit[2]);
    });

    this.yearList = yearValues;
  }

  findRangePro() {
    let curr_date = new Date();
    let yearValues: any = [];

    let start = moment(curr_date).format('YYYY-MM-DD');
    let end = moment(curr_date).subtract(100, 'years').format('YYYY-MM-DD');

    let startDate = moment(start);
    let endDate = moment(end);

    // let startDate = moment('2012-01-02');
    // let endDate = moment('2002-05-12');

    console.log(startDate, 'startDate');
    console.log(endDate, 'endDate');

    let dateList = this.getDaysBetweenDates(endDate, startDate);
    //console.log(dateList);

    dateList.filter((dates) => {
      let dateSplit = dates.split('-');
      yearValues.push(dateSplit[2]);
    });

    this.yearList1 = yearValues;
  }

  get editFormVal() {
    return this.editForm.controls;
  }

  addChildFields() {
    return (this.formArr = this.formBuilder.group({
      childName: [''],
      birthDay: [''],
      month: [''],
      year: [''],
    }));
  }

  get childFormArray() {
    return <FormArray>this.editForm.get('childForm');
  }

  addMilkFieldValue() {
    this.count++;
    this.addField = true;
    if (this.count < 5) {
      if (this.addField == true) {
        this.childFormArray.push(this.addChildFields());
      }
    }
  }

  get EditOtpFormVal() {
    return this.editOtpForm.controls;
  }
  onOtpChange(otp) {
    // this.otp = otp;
    this.invalidOtp = false;
    this.otp = otp;
    console.log(this.otp, 'this.otp--');
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }
  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  showOtpModal() {
    this.submitEditOtpForm = false;
    this.invalidOtp = false;
    this.activeClassButton = false;
    this.ngOtpInputRef.setValue('');
    this.editOtpModal.show();
  }
  closeEditOtpModal() {
    this.submitEditOtpForm = false;
    this.invalidOtp = false;
    this.activeClassButton = false;
    this.ngOtpInputRef.setValue('');
    this.editOtpModal.hide();
  }
  goToEditPassword() {
    // this.showOtpModal();
    this.router.navigate(['/edit-password']);
  }

  onEditFormSubmit() {
    this.submitted = true;
    if (this.editForm.invalid) {
      console.log(this.editForm.value, 'invalid form value---');
      return;
    } else {
      console.log(this.editForm.value, 'form value---');
      let userData = this.editForm.value;
      console.log(userData, 'userData---');
      let childData = userData.childForm;
      childData.filter((val, i) => {
        console.log(val, 'val---');
        console.log(i, 'ii');
        if (val.childName != '') {
          if (val.birthDay == '' || val.month == '' || val.year == '') {
            this.bdayError[i] = true;
            this.ChildNameError[i] = false;
            console.log('bday is mandatory--');
            this.checkFilled.push('empty');
            this.errCheck[i] = true;
            return;
          }
        }
        if (val.birthDay != '' && val.month != '' && val.year != '') {
          if (val.childName == '') {
            this.bdayError[i] = false;
            this.ChildNameError[i] = true;
            console.log('child name is mandatory--');
            this.checkFilled.push('empty');
            this.errCheck[i] = true;
            return;
          }
        }

        if (
          val.childName != '' &&
          val.birthDay != '' &&
          val.month != '' &&
          val.year != ''
        ) {
          this.checkFilled.pop('empty');
          this.bdayError[i] = false;
          this.ChildNameError[i] = false;
          this.errCheck[i] = false;
        }
      });

      let errPresent;
      console.log(this.errCheck, 'testarry');
      this.errCheck.forEach((val) => {
        if (val == true) {
          errPresent = true;
        }
      });

      if (errPresent) {
        return;
      } else {
        // form Submit
        this.spinner.show();
        //if(userData.email != undefined || userData.email != null)
        this.apiService
          .checkExistingEmail(encodeURIComponent(userData.email))
          .pipe(first())
          .subscribe(
            (res: any) => {
              this.spinner.hide();
              if (res.result.statusCode == 200) {
                this.emailExsistError = false;

                // this.apiService.getPosition().then(pos=>{
                //   let lat = pos.lat
                //   let lang = pos.lng
                //   let latitude = JSON.stringify(lat)
                //   let longitude = JSON.stringify(lang)
                let filledFormValues = this.getuserForm(userData);
                console.log(
                  filledFormValues.root.customer[0].email,
                  'filled form values'
                );
                let getMobile = sessionStorage.getItem('mobileNo');
                let token = sessionStorage.getItem('authToken');
                this.spinner.show();
               
                this.apiService
                  .updateCapCustomer(filledFormValues, getMobile, token)
                  .pipe(first())
                  .subscribe(
                    (res) => {
                     
                      this.spinner.hide();
                      if (res['status'].code == 200) {
                        console.log(this.emailNotPresent, 'outside');
                        // if(this.customerData?.email != null){
                        //   let updateEmail = {
                        //   "root": {
                        //     "customer": [
                        //     {
                        //        "identifier": 'email',
                        //        "old_value": this.customerData?.email,
                        //        "new_value": filledFormValues?.root?.customer[0]?.email
                        //     }
                        //     ]
                        //  }
                        // }
                        // this.spinner.show();
                        //   this.apiService.updateCapCustomerIdentity(updateEmail, getMobile, token).pipe(first())
                        //   .subscribe(res=>{
                        //     console.log(res, '=== updated email ===')
                        //     this.spinner.hide();
                        //   },err=>{
                        //     console.log(err, 'err===')
                        //   })
                        // }
                        //------------ new chnages---

                        let childCheck1: boolean = false;
                        let childCheck2: boolean = false;
                        let childCheck3: boolean = false;
                        let childCheck4: boolean = false;
                        let emailCheck: boolean = false;

                        emailCheck = this.emailNotPresent && userData?.email; //check email

                        //---check child 1----
                        childCheck1 =
                          !this.child1_present &&
                          userData.childForm[0]?.childName &&
                          userData.childForm[0]?.birthDay &&
                          userData.childForm[0]?.month &&
                          userData.childForm[0]?.year;

                        //---check child 2----
                        childCheck2 =
                          !this.child2_present &&
                          userData.childForm[1]?.childName &&
                          userData.childForm[1]?.birthDay &&
                          userData.childForm[1]?.month &&
                          userData.childForm[1]?.year;

                        //---check child 3----
                        childCheck3 =
                          !this.child3_present &&
                          userData.childForm[2]?.childName &&
                          userData.childForm[2]?.birthDay &&
                          userData.childForm[2]?.month &&
                          userData.childForm[2]?.year;

                        //---check child 4----
                        childCheck4 =
                          !this.child4_present &&
                          userData.childForm[3]?.childName &&
                          userData.childForm[3]?.birthDay &&
                          userData.childForm[3]?.month &&
                          userData.childForm[3]?.year;

                        if (this.emailNotPresent && userData?.email) {
                          console.log('only email added');
                          // this.emailNotPresent = false;
                          this.successEmailModal.show();

                          if (emailCheck && childCheck1) {
                            //email and child1
                            this.emailChildPts = 10;
                            this.successEmailChildModal.show();
                            this.successEmailModal.hide();
                            console.log('email and child 1 added--');
                          }

                          if (emailCheck && childCheck2) {
                            //email and child2
                            this.emailChildPts = 10;
                            this.successEmailChildModal.show();
                            this.successEmailModal.hide();
                            console.log('email and child 2 added--');
                          }

                          if (emailCheck && childCheck3) {
                            //email and child3
                            this.emailChildPts = 10;
                            this.successEmailChildModal.show();
                            this.successEmailModal.hide();
                            console.log('email and child 3 added--');
                          }

                          if (emailCheck && childCheck4) {
                            //email and child4
                            this.emailChildPts = 10;
                            this.successEmailChildModal.show();
                            this.successEmailModal.hide();
                            console.log('email and child 4 added--');
                          }

                          if (emailCheck && childCheck1 && childCheck2) {
                            //email and child 1,2
                            this.emailChildPts = 15;
                            this.successEmailChildModal.show();
                            this.successEmailModal.hide();
                            console.log('email and child 1, 2 added--');
                          }

                          if (
                            emailCheck &&
                            childCheck1 &&
                            childCheck2 &&
                            childCheck3
                          ) {
                            //email and child 1,2,3
                            this.emailChildPts = 20;
                            this.successEmailChildModal.show();
                            this.successEmailModal.hide();
                            console.log('email and child 1, 2 added--');
                          }

                          if (
                            emailCheck &&
                            childCheck1 &&
                            childCheck2 &&
                            childCheck3 &&
                            childCheck4
                          ) {
                            //email and child 1,2,3,4
                            this.emailChildPts = 25;
                            this.successEmailChildModal.show();
                            this.successEmailModal.hide();
                            console.log('email and child 1, 2 added--');
                          }
                        } else if (
                          !this.child1_present &&
                          userData.childForm[0]?.childName &&
                          userData.childForm[0]?.birthDay &&
                          userData.childForm[0]?.month &&
                          userData.childForm[0]?.year
                        ) {
                          this.childPoints = 5;
                          console.log('child 1 added--'); // only child 1 added
                          this.successChildModal.show();

                          if (childCheck1 && childCheck2) {
                            this.childPoints = 10;
                            this.successChildModal.show();
                            console.log('child 1, 2,added--'); // child 1,2 added
                          }

                          if (childCheck1 && childCheck2 && childCheck3) {
                            this.childPoints = 15;
                            this.successChildModal.show();
                            console.log('child 1, 2, 3 added--'); // child 1,2,3 added
                          }
                          if (
                            childCheck1 &&
                            childCheck2 &&
                            childCheck3 &&
                            childCheck4
                          ) {
                            this.childPoints = 20;
                            this.successChildModal.show();
                            console.log('child 1, 2, 3,4 added--'); // child 1,2,3 added
                          }
                        } else if (
                          !this.child2_present &&
                          userData.childForm[1]?.childName &&
                          userData.childForm[1]?.birthDay &&
                          userData.childForm[1]?.month &&
                          userData.childForm[1]?.year
                        ) {
                          console.log('inside 2');

                          this.childPoints = 5;
                          console.log('child 2 added--'); // only child 2 added
                          this.successChildModal.show();

                          if (childCheck2 && childCheck3) {
                            this.childPoints = 10;
                            this.successChildModal.show();
                            console.log('child 2, 3,added--'); // child 2,3 added
                          }
                          if (childCheck2 && childCheck3 && childCheck4) {
                            this.childPoints = 15;
                            this.successChildModal.show();
                            console.log('email and child 2, 3, 4 added--'); // child 2,3,4 added
                          }
                        } else if (
                          !this.child3_present &&
                          userData.childForm[2]?.childName &&
                          userData.childForm[2]?.birthDay &&
                          userData.childForm[2]?.month &&
                          userData.childForm[2]?.year
                        ) {
                          console.log('inside 3');

                          this.childPoints = 5;
                          console.log('child 3 added--'); // only child 3 added
                          this.successChildModal.show();

                          if (childCheck3 && childCheck4) {
                            this.childPoints = 10;
                            this.successChildModal.show();
                            console.log('child 3, 4 added--'); // child 3,4 added
                          }
                        } else if (
                          !this.child4_present &&
                          userData.childForm[3]?.childName &&
                          userData.childForm[3]?.birthDay &&
                          userData.childForm[3]?.month &&
                          userData.childForm[3]?.year
                        ) {
                          console.log('inside 4');

                          this.childPoints = 5;
                          console.log('child 4 added--'); // only child 4 added
                          this.successChildModal.show();
                        } else {
                          console.log('in global else--'); // general edit done
                          this.successProfileUpdateModal.show();
                        }
                      } else {
                        console.log(res['status'].message);
                        //this.toastr.info(res['status'].message)
                      }
                    },
                    (err) => {
                      this.spinner.hide();
                      this.bsService.notifyError(err, filledFormValues);
                      console.log(err, '=== get customer error =======');
                    }
                  );

                // }) //end poistion api
              }
            },
            (err) => {
              this.bsService.notifyError(err);
              this.emailExsistError = true; //email arleady exsist
              console.log('email already exsist---');
              this.spinner.hide();
              return;
            }
          );
      }
    }
  }

  showChildModal() {
    console.log('inside show--');
    this.successChildModal.show();
  }

  closeProfileUpdateModal() {
    this.successProfileUpdateModal.hide();
  }

  SuccessProfileUpdateModal() {
    this.successProfileUpdateModal.hide();
    this.router.navigate(['/profile']);
  }
  splitDob: any
  dobyear: any
  dobmonth: any
  dobdate: any;
  getCustomer() {
    this.spinner.show();
    const formarray = new FormArray([]);
    let child1_dob: boolean = false;
    let child2_dob: boolean = false;
    let child3_dob: boolean = false;
    let child4_dob: boolean = false;
    let childdate1: any;
    let childdate2: any;
    let childdate3: any;
    let childdate4: any;
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    this.apiService
      .getCapCustomer(getMobile, getAuthKey)
      .pipe(first())
      .subscribe((res) => {
        this.spinner.hide();
        let location: any;
        if (res['status'].code == 200) {
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData?.email, 'email find');
          // let mobile = this.customerData?.mobile;
          // mobile = mobile.substring(2);

          // let dobSplit = this.customerData?.extended_fields?.field.filter(val=>{
          //   return val.name == 'dob_date';
          // })
          let dobSplit = this.customerData?.custom_fields?.field.filter(val => {
            return val.name == 'dob';
          })
          console.log(dobSplit.length)
          if (dobSplit.length > 0) {
            this.splitDob = dobSplit[0].value.split('-');
          }
          let memSplit = this.customerData?.extended_fields?.field.filter(val => {
            return val.name == 'member_type';
          });
          let splitmem = memSplit.length > 0 ? memSplit[0].value : '';
          let result = splitmem.substring(1, splitmem.length - 1);
          let spltmemtype = result.split(', ')
          let oneSplit = spltmemtype[0];
          let twoSplit = spltmemtype[1];
          if (oneSplit) {
            let dataFilter = this.checkboxesDataList.filter(val => {
              return val.id == oneSplit;
            });
            dataFilter[0].isChecked = true;
          }
          if (twoSplit) {
            let dataFilter1 = this.checkboxesDataList.filter(val => {
              return val.id == twoSplit;
            });
            dataFilter1[0].isChecked = true;
          }

          this.dobyear = dobSplit.length > 0 ? this.splitDob[0] : '';
          this.dobmonth = dobSplit.length > 0 ? this.splitDob[1] : '';
          this.dobdate = dobSplit.length > 0 ? this.splitDob[2] : '';
          console.log(this.splitDob, 'splitDob')
          this.editForm.patchValue({
            name: this.customerData?.name
              ? this.customerData?.name
              : this.customerData?.firstname,
            surname: this.customerData?.lastname,
            mobileNumber: this.customerData?.mobile,
            email: this.customerData?.email,
            year1: this.dobyear ? this.dobyear : '',
            month1: this.dobmonth ? this.dobmonth : '',
            birthDay1: this.dobdate ? this.dobdate : ''

          });
          let val = this.editForm.value;
          val.mobileNumber != null
            ? this.editForm.controls['mobileNumber'].disable()
            : '';
          // val.email != null ? '' : this.emailNotPresent = true;
          val.email != null
            ? this.editForm.controls['email'].disable()
            : (this.emailNotPresent = true);
        }
        console.log(this.emailNotPresent, 'while patch');
        location = this.customerData?.custom_fields?.field;
        location.filter((ele) => {
          if (ele.name == 'child_dob_one') {
            ele.value != '' ? (child1_dob = true) : (child1_dob = false);
            childdate1 = ele.value;
          }
          if (ele.name == 'child_dob_two') {
            ele.value != '' ? (child2_dob = true) : (child2_dob = false);
            childdate2 = ele.value;
          }
          if (ele.name == 'child_dob_three') {
            ele.value != '' ? (child3_dob = true) : (child3_dob = false);
            childdate3 = ele.value;
          }
          if (ele.name == 'child_dob_four') {
            ele.value != '' ? (child4_dob = true) : (child4_dob = false);
            childdate4 = ele.value;
          }
        });
        location.forEach((element) => {
          element.name == 'house_number'
            ? this.editForm.patchValue({ houseNumber: element.value })
            : '';
          element.name == 'swine'
            ? this.editForm.patchValue({ swine: element.value })
            : '';
          element.name == 'building_or_village'
            ? this.editForm.patchValue({ buildingVillage: element.value })
            : '';
          element.name == 'alley'
            ? this.editForm.patchValue({ alley: element.value })
            : '';
          element.name == 'road'
            ? this.editForm.patchValue({ road: element.value })
            : '';
          element.name == 'province'
            ? this.editForm.patchValue({ province: element.value })
            : '';
          if (element.name == 'province') {
            let patch_provience = element.value;
            this.findCounty(patch_provience);
          }
          element.name == 'postal_code'
            ? this.editForm.patchValue({ postalCode: element.value })
            : '';
          element.name == 'district'
            ? this.editForm.patchValue({ district: element.value })
            : '';
          if (element.name == 'district') {
            this.patch_district = element.value;
            this.findDistrict();
          }
          element.name == 'county'
            ? this.editForm.patchValue({ country: element.value })
            : '';
          if (element.name == 'county') {
            this.patch_county = element.value;
          }
          if (element.name == 'child_name_1' && element.value != '') {
            if (child1_dob) {
              console.log('in 1--');
              this.child1_present = true;
              let date = childdate1;
              let splitdate = date.split('-');
              this.editForm.setControl(
                'childForm',
                this.setcontrolsChild(
                  element.value,
                  splitdate[0],
                  splitdate[1],
                  splitdate[2],
                  formarray
                )
              );
            } else {
              this.child1_present = false;
            }
          }
          if (element.name == 'child_name_two' && element.value != '') {
            if (child2_dob) {
              console.log('in 2--');
              this.child2_present = true;
              let date = childdate2;
              let splitdate = date.split('-');
              this.editForm.setControl(
                'childForm',
                this.setcontrolsChild(
                  element.value,
                  splitdate[0],
                  splitdate[1],
                  splitdate[2],
                  formarray
                )
              );
            } else {
              this.child2_present = false;
            }
          }
          if (element.name == 'child_name_three' && element.value != '') {
            if (child3_dob) {
              console.log('in 3--');
              this.child3_present = true;
              let date = childdate3;
              let splitdate = date.split('-');
              this.editForm.setControl(
                'childForm',
                this.setcontrolsChild(
                  element.value,
                  splitdate[0],
                  splitdate[1],
                  splitdate[2],
                  formarray
                )
              );
            } else {
              this.child3_present = false;
            }
          }
          if (element.name == 'child_name_4' && element.value != '') {
            if (child3_dob) {
              console.log('in 4--');
              this.child4_present = true;
              let date = childdate4;
              let splitdate = date.split('-');
              this.editForm.setControl(
                'childForm',
                this.setcontrolsChild(
                  element.value,
                  splitdate[0],
                  splitdate[1],
                  splitdate[2],
                  formarray
                )
              );
            } else {
              this.child4_present = false;
            }
          }
        });
      }, err => {
        this.bsService.notifyError(err);
      });
  }

  setcontrolsChild(child_name, yy, mm, dd, formarray) {
    formarray.push(
      this.formBuilder.group({
        childName: child_name,
        birthDay: dd ? dd : '',
        month: mm ? mm : '',
        year: yy ? yy : '',
      })
    );
    this.count = formarray.controls.length;
    if (this.count >= 1) {
      this.showFormArray = true;
    } else {
      this.showFormArray = false;
    }
    return formarray;
  }

  onSubmitEditOtpForm() {
    this.submitEditOtpForm = true;
    this.closeEditOtpModal();
    this.router.navigate(['/edit-password']);
  }

  getuserForm(userData) {
    return (this.registerForm = {
      root: {
        customer: [
          {
            mobile: userData.mobileNumber,
            email: userData.email,
            firstname: userData.name,
            lastname: userData.surname,
            custom_fields: {
              field: [
                {
                  name: 'house_number',
                  value: userData.houseNumber,
                },
                {
                  name: 'swine',
                  value: userData.swine,
                },
                {
                  name: 'building_or_village',
                  value: userData.buildingVillage,
                },
                {
                  name: 'alley',
                  value: userData.alley,
                },
                {
                  name: 'road',
                  value: userData.road,
                },
                {
                  name: 'postal_code',
                  value: userData.postalCode,
                },
                {
                  name: 'province',
                  value: userData.province,
                },
                {
                  name: 'county',
                  value: userData.country,
                },
                {
                  name: 'district',
                  value: userData.district,
                },
                {
                  name: 'dob',
                  value: (this.yearSelected1 == undefined ? this.dobyear : this.yearSelected1) + '-' + (this.modelMonth11 == undefined ? this.dobmonth : this.modelMonth11) + '-' + (this.dateSelected1 == undefined ? this.dobdate : this.dateSelected1),
                },
                {
                  name: 'child_name_1',
                  value:
                    userData.childForm[0]?.childName &&
                      userData.childForm[0]?.birthDay &&
                      userData.childForm[0]?.month &&
                      userData.childForm[0]?.year
                      ? userData.childForm[0]?.childName
                      : '',
                },
                {
                  name: 'child_name_two',
                  value:
                    userData.childForm[1]?.childName &&
                      userData.childForm[1]?.birthDay &&
                      userData.childForm[1]?.month &&
                      userData.childForm[1]?.year
                      ? userData.childForm[1]?.childName
                      : '',
                },
                {
                  name: 'child_name_three',
                  value:
                    userData.childForm[2]?.childName &&
                      userData.childForm[2]?.birthDay &&
                      userData.childForm[2]?.month &&
                      userData.childForm[2]?.year
                      ? userData.childForm[2]?.childName
                      : '',
                },
                {
                  name: 'child_name_4',
                  value:
                    userData.childForm[3]?.childName &&
                      userData.childForm[3]?.birthDay &&
                      userData.childForm[3]?.month &&
                      userData.childForm[3]?.year
                      ? userData.childForm[3]?.childName
                      : '',
                },
                {
                  name: 'child_dob_one',
                  value:
                    userData.childForm[0]?.birthDay &&
                      userData.childForm[0]?.month &&
                      userData.childForm[0]?.year
                      ? userData.childForm[0].year +
                      '-' +
                      userData.childForm[0].month +
                      '-' +
                      userData.childForm[0].birthDay
                      : '',
                },
                {
                  name: 'child_dob_two',
                  value:
                    userData.childForm[1]?.birthDay &&
                      userData.childForm[1]?.month &&
                      userData.childForm[1]?.year
                      ? userData.childForm[1].year +
                      '-' +
                      userData.childForm[1].month +
                      '-' +
                      userData.childForm[1].birthDay
                      : '',
                },
                {
                  name: 'child_dob_three',
                  value:
                    userData.childForm[2]?.birthDay &&
                      userData.childForm[2]?.month &&
                      userData.childForm[2]?.year
                      ? userData.childForm[2].year +
                      '-' +
                      userData.childForm[2].month +
                      '-' +
                      userData.childForm[2].birthDay
                      : '',
                },
                {
                  name: 'child_dob_four',
                  value:
                    userData.childForm[3]?.birthDay &&
                      userData.childForm[3]?.month &&
                      userData.childForm[3]?.year
                      ? userData.childForm[3].year +
                      '-' +
                      userData.childForm[3].month +
                      '-' +
                      userData.childForm[3].birthDay
                      : '',
                },
              ],
            },
            "extended_fields": {
              "field": [
                // {
                //   "name": "dob_date",
                //   "value": this.yearSelected1+'-'+this.modelMonth11+'-'+this.dateSelected1,
                // },
                {
                  "name": "dob",
                  "value": (this.yearSelected1 == undefined ? this.dobyear : this.yearSelected1) + '-' + (this.modelMonth11 == undefined ? this.dobmonth : this.modelMonth11) + '-' + (this.dateSelected1 == undefined ? this.dobdate : this.dateSelected1),
                },
                {
                  "name": "member_type",
                  "value": this.checkedIDs,
                }
              ]
            },
          },
        ],
      },
    });
  }

  countyChange(e) {
    console.log(e.target.value, 'e.target.value--');
    let str = e.target.value.split(' ');
    console.log(str[1], 'county value---');
    this.county.filter((val) => {
      if (val.display == str[1]) {
        this.district = val.districts;
      }
    });
  }

  provinceChange(e) {
    console.log(e.target.value, 'e.target.value--');
    let str = e.target.value.split(' ');
    console.log(str[1], 'str');
    this.mainProvienceData.filter((val) => {
      if (val.display == str[1]) {
        this.county = val.amphoe;
      }
    });
  }

  findCounty(provience) {
    this.mainProvienceData.filter((val) => {
      if (val.display == provience) {
        this.county = val.amphoe;
        this.editForm.patchValue({ country: this.patch_county });
      }
    });
  }

  findDistrict() {
    this.county.filter((val) => {
      if (val.display == this.patch_county) {
        this.district = val.districts;
        this.editForm.patchValue({ district: this.patch_district });
      }
    });
  }

  closeSuccessEmailModal() {
    this.successEmailModal.hide();
  }

  SuccessEmailModal() {
    this.successEmailModal.hide();
    this.router.navigate(['/profile']);
  }

  closeSuccessChildModal() {
    this.successChildModal.hide();
  }

  SuccessChildModal() {
    this.successChildModal.hide();
    this.router.navigate(['/profile']);
  }

  closeEmailChildModal() {
    this.successEmailChildModal.hide();
  }

  SuccessEmailChildModal() {
    this.successEmailChildModal.hide();
    this.router.navigate(['/profile']);
  }
  modelMonth1: any;
  montChanged1(month1) {
    console.log(month1.target.value, 'montChanged1');
    this.modelMonth1 = month1.target.value;
  }
  yearSelected: any;
  selectedYear(event) {
    this.yearSelected = event.target.value;
    console.log(this.yearSelected, 'this.yearSelected');
  }



  modelMonth11: any;
  montChanged11(month1) {

    this.modelMonth11 = month1.target.value;
    console.log(this.modelMonth11, 'montChanged11');
  }
  yearSelected1: any;
  selectedYear1(event) {
    this.yearSelected1 = event.target.value;
    console.log(this.yearSelected1, 'this.yearSelected1');
  }
  dateSelected1: any;
  dateChange1(event) {
    this.dateSelected1 = event.target.value;
    console.log(this.dateSelected1, 'this.dateSelected1');
  }



  changeSelection() {
    this.checkedIDs = []
    this.checkboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
      }
    });
    console.log(this.checkedIDs, 'checkedIDs')
  }
}
