<!-- <div [hidden]="!showScanner">
  <div class="video-row" fxFlex="100">
    <video class="video" #video autoplay playsinline></video>
  </div>
  <div fxFlex="100" style="display: none">
    <canvas class="video" id="canvas" #canvas></canvas>
  </div>
  <button class="cameraBtn" (click)="closeScanner()">back</button>
</div> -->

<div [hidden]="!showScanner">
    <section id="demo-content">
      <div>
        <video id="video" width="100%" height="100%"></video>
      </div>
      <!-- <scandit-barcode-picker
        #barcodePicker
        style="border-radius: 15% !important;"
        id="barcode-picker"
         scanningPaused="true"
        visible="false"
        playSoundOnScan="false"
        vibrateOnScan="true"
        scanSettings.enabledSymbologies='["QR"]'
        scanSettings.codeDuplicateFilter="-1"
        (ready)="onReady()"
        (scan)="onScan($event)"
        (scanError)="onScanError($event)"
         >
      </scandit-barcode-picker> -->
    </section>
      <!-- <div>
        <video id="video" width="100%" height="100%"></video>
      </div> -->
      <button class="cameraBtn" (click)="closeScanner()">back</button>
      <!-- <label>Result:</label>
        <pre><code id="result"></code></pre> -->
    <!-- </section> -->
  </div>
  
  <!-- success scan modal -->
  <div
    bsModal
    #successScanModal="bs-modal"
    class="modal fade customModalContainer successScanModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeSuccessScanModal()"
          >
            <img src="../../../assets/images/close.png" />
          </button>
          <h4>{{ "SUCCESS_SCAN_MODAL.SCANNED_SUCCESSFULLY" | translate }}</h4>
          <p>
            {{ "SUCCESS_SCAN_MODAL.CONGRATULATIONS_YOU_RECEIVED" | translate }}
            {{ pointsEarned }} {{ "SUCCESS_SCAN_MODAL.SCORE" | translate }}
          </p>
          <button
            class="btn btn-block goldenBtn"
            (click)="closeSuccessScanModal()"
          >
            {{ "SUCCESS_SCAN_MODAL.OK" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  
  
  
  <!-- failed scan modal -->
  <div
    bsModal
    #failedScanModal="bs-modal"
    class="modal fade customModalContainer successScanModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeFailedScanModal()"
          >
            <img src="../../../assets/images/close.png" />
          </button>
          <h4>{{ "FAILED_SCAN_MODAL.SCAN_FAILED" | translate }}</h4>
          <p>{{ "FAILED_SCAN_MODAL.QR_CODE_ALREADY_IN_USE" | translate }}</p>
          <button
            class="btn btn-block goldenBtn"
            (click)="closeFailedScanModal()"
          >
            {{ "FAILED_SCAN_MODAL.TRY_AGAIN" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  