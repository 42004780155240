import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { LandingComponent } from './landing/landing.component';
import { EditPasswordComponent } from './pages/edit-password/edit-password.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { MoreComponent } from './pages/more/more.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ConditionsComponent } from './pages/conditions/conditions.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HistoryComponent } from './pages/history/history.component';
import { TraceabilityQualityControlComponent } from './pages/traceability-quality-control/traceability-quality-control.component';
import { TraceabilityNavComponent } from './pages/traceability-nav/traceability-nav.component';
import { TraceabilityHomeComponent } from './pages/traceability-home/traceability-home.component';
import { TraceabilityTriplePlusComponent } from './pages/traceability-triple-plus/traceability-triple-plus.component';
import { TraceabilityManufacturingComponent } from './pages/traceability-manufacturing/traceability-manufacturing.component';
import { TraceabilityBrandComponent } from './pages/traceability-brand/traceability-brand.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RewardsComponent } from './rewards/rewards.component';
import { TemporaryLandingComponent } from './pages/temporary-landing/temporary-landing.component';
import { HowToUseComponent } from './pages/how-to-use/how-to-use.component';
import { MobileRegisterComponent } from './pages/mobile-register/mobile-register.component';

// New Ensure pages
import { EnsureAwTracebilityComponent } from './pages/ensure-aw/ensure-aw-tracebility/ensure-aw-tracebility.component';
import { EnsureAwManufacturingComponent } from './pages/ensure-aw/ensure-aw-manufacturing/ensure-aw-manufacturing.component';
import { EnsureAwQcComponent } from './pages/ensure-aw/ensure-aw-qc/ensure-aw-qc.component';
import { EnsureAwMixinstructionComponent } from './pages/ensure-aw/ensure-aw-mixinstruction/ensure-aw-mixinstruction.component';
import { EnsureAwProductComponent } from './pages/ensure-aw/ensure-aw-product/ensure-aw-product.component';
import { EnsureAwMenuComponent } from './pages/ensure-aw/ensure-aw-menu/ensure-aw-menu.component';

import { GlucernaTracebilityComponent } from './pages/tracebility-glucerna/glucerna-tracebility/glucerna-tracebility.component';
import { GlucernaManufacturarComponent } from './pages/tracebility-glucerna/glucerna-manufacturar/glucerna-manufacturar.component';
import { GlucernaQualitycontrolComponent } from './pages/tracebility-glucerna/glucerna-qualitycontrol/glucerna-qualitycontrol.component';
import { GlucernaProductComponent } from './pages/tracebility-glucerna/glucerna-product/glucerna-product.component';
import { GlucernaMixinstructionComponent } from './pages/tracebility-glucerna/glucerna-mixinstruction/glucerna-mixinstruction.component';
import { EnsureTraceabilityComponent } from './pages/ensure/ensure-traceability/ensure-traceability.component';
import { EnsureManufacturingComponent } from './pages/ensure/ensure-manufacturing/ensure-manufacturing.component';
import { EnsureQualityControlComponent } from './pages/ensure/ensure-quality-control/ensure-quality-control.component';
import { EnsureProductComponent } from './pages/ensure/ensure-product/ensure-product.component';
import { EnsureMixinstructionComponent } from './pages/ensure/ensure-mixinstruction/ensure-mixinstruction.component';
import { QrScannerComponent } from './shared/qr-scanner/qr-scanner.component';
import { CameraComponent } from './shared/camera/camera.component';
import { ImageUploadSuccessComponent } from './pages/image-upload-success/image-upload-success.component';
import { EnsureCerealTraceabilityComponent } from './pages/ensure-cereal-traceability/ensure-cereal-traceability.component';
import { GlucernaPlusCerealTraceabilityComponent } from './pages/glucerna-plus-cereal-traceability/glucerna-plus-cereal-traceability.component';
import { PediasurePlusTastelessTraceabilityComponent } from './pages/pediasure-plus-tasteless-traceability/pediasure-plus-tasteless-traceability.component';
import { EnsureGoldCerealTraceabilityComponent } from './pages/ensure-gold-cereal-traceability/ensure-gold-cereal-traceability.component';
import { EnsurehManufacturingComponent } from './pages/ensureh-manufacturing/ensureh-manufacturing.component';
import { EnsurehQcComponent } from './pages/ensureh-qc/ensureh-qc.component';
import { EnsurehProductComponent } from './pages/ensureh-product/ensureh-product.component';
import { EnsurehMixinstructionComponent } from './pages/ensureh-mixinstruction/ensureh-mixinstruction.component';
import { EnsuregoldManufacturingComponent } from './pages/ensuregold-manufacturing/ensuregold-manufacturing.component';
import { EnsuregoldQcComponent } from './pages/ensuregold-qc/ensuregold-qc.component';
import { EnsuregoldProductComponent } from './pages/ensuregold-product/ensuregold-product.component';
import { EnsuregoldMixinstructionComponent } from './pages/ensuregold-mixinstruction/ensuregold-mixinstruction.component';
import { GlucernaplusManufacturingComponent } from './pages/glucernaplus-manufacturing/glucernaplus-manufacturing.component';
import { GlucernaplusQcComponent } from './pages/glucernaplus-qc/glucernaplus-qc.component';
import { GlucernaplusProductComponent } from './pages/glucernaplus-product/glucernaplus-product.component';
import { GlucernaplusMixinstructionComponent } from './pages/glucernaplus-mixinstruction/glucernaplus-mixinstruction.component';
import { PediasureplusManufacturingComponent } from './pages/pediasureplus-manufacturing/pediasureplus-manufacturing.component';
import { PediasureplusQcComponent } from './pages/pediasureplus-qc/pediasureplus-qc.component';
import { PediasureplusProductComponent } from './pages/pediasureplus-product/pediasureplus-product.component';
import { PediasureplusMixinstructionComponent } from './pages/pediasureplus-mixinstruction/pediasureplus-mixinstruction.component';
import { ScannerComponent } from './shared/scanner/scanner.component';
import { LineLandingComponent } from './line-landing/line-landing.component';

const routes: Routes = [
  {
    path: '',
    //path: 'landing-page',
    component: LandingComponent,
    pathMatch: 'full',
  },
  {
    path:'line-landing',
    component: LineLandingComponent
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'register/:id',
    component: RegisterComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
  },
  {
    path: 'landing-page',
    component: LandingComponent,
  },
  {
    path: 'edit-password',
    component: EditPasswordComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'more',
    component: MoreComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'conditions',
    component: ConditionsComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'history',
    component: HistoryComponent,
  },
  {
    path: 'quality-control',
    component: TraceabilityQualityControlComponent,
  },
  {
    path: 'manufacturing',
    component: TraceabilityManufacturingComponent,
  },
  {
    path: 'traceability-nav',
    component: TraceabilityNavComponent,
  },
  {
    path: 'traceability-home',
    component: TraceabilityHomeComponent,
  },
  {
    path: 'triple-plus',
    component: TraceabilityTriplePlusComponent,
  },
  {
    path: 'brand',
    component: TraceabilityBrandComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'reward',
    component: RewardsComponent,
  },
  {
    path: 'templanding',
    component: TemporaryLandingComponent,
  },
  {
    path: 'howtouse',
    component: HowToUseComponent,
  },
  {
    path: 'mobile-register',
    component: MobileRegisterComponent,
  },
  {
    path: 'glucerna-traceability',
    component: GlucernaTracebilityComponent,
  },
  {
    path: 'glucerna-manufacturing',
    component: GlucernaManufacturarComponent,
  },
  {
    path: 'glucerna-qualitycontrol',
    component: GlucernaQualitycontrolComponent,
  },
  {
    path: 'glucerna-product',
    component: GlucernaProductComponent,
  },
  {
    path: 'glucerna-mixinstruction',
    component: GlucernaMixinstructionComponent,
  },

  {
    path: 'ensureplantbased-home',
    component: EnsureAwTracebilityComponent,
  },
  {
    path: 'ensureplantbased-manufacturing',
    component: EnsureAwManufacturingComponent,
  },
  {
    path: 'ensureplantbased-quality-control',
    component: EnsureAwQcComponent,
  },
  {
    path: 'ensureplantbased-branded',
    component: EnsureAwProductComponent,
  },
  {
    path: 'ensureplantbased-instruction',
    component: EnsureAwMixinstructionComponent,
  },

  {
    path: 'ensure-traceability',
    component: EnsureTraceabilityComponent,
  },
  {
    path: 'ensure-manufacturing',
    component: EnsureManufacturingComponent,
  },
  {
    path: 'ensure-qualitycontrol',
    component: EnsureQualityControlComponent,
  },
  {
    path: 'ensure-product',
    component: EnsureProductComponent,
  },
  {
    path: 'ensure-mixinstruction',
    component: EnsureMixinstructionComponent,
  },
  {
    path: 'qr-scanner',
    component: QrScannerComponent,
  },
  {
    path: 'camera',
    component: CameraComponent,
  },
  {
    path: 'image-upload-success',
    component: ImageUploadSuccessComponent,
  },
  {
    path: 'ensureh-traceability',
    component: EnsureCerealTraceabilityComponent,
  },
  {
    path: 'ensureh-manufacturing',
    component: EnsurehManufacturingComponent,
  },
  {
    path: 'ensureh-qc',
    component: EnsurehQcComponent,
  },
  {
    path: 'ensureh-product',
    component: EnsurehProductComponent,
  },
  {
    path: 'ensureh-mixinstruction',
    component: EnsurehMixinstructionComponent,
  },
  {
    path: 'glucernaplus-traceability',
    component: GlucernaPlusCerealTraceabilityComponent,
  },
  {
    path: 'glucernaplus-manufacturing',
    component: GlucernaplusManufacturingComponent,
  },
  {
    path: 'glucernaplus-qc',
    component: GlucernaplusQcComponent,
  },
  {
    path: 'scanner',
    component: ScannerComponent,
  },
  {
    path: 'glucernaplus-product',
    component: GlucernaplusProductComponent,
  },
  {
    path: 'glucernaplus-mixinstruction',
    component: GlucernaplusMixinstructionComponent,
  },
  {
    path: 'ensuregold-traceability',
    component: EnsureGoldCerealTraceabilityComponent,
  },
  {
    path: 'ensuregold-manufacturing',
    component: EnsuregoldManufacturingComponent,
  },
  {
    path: 'ensuregold-qc',
    component: EnsuregoldQcComponent,
  },
  {
    path: 'ensuregold-product',
    component: EnsuregoldProductComponent,
  },
  {
    path: 'ensuregold-mixinstruction',
    component: EnsuregoldMixinstructionComponent,
  },
  {
    path: 'pediasureplus-traceability',
    component: PediasurePlusTastelessTraceabilityComponent,
  },
  {
    path: 'pediasureplus-manufacturing',
    component: PediasureplusManufacturingComponent,
  },
  {
    path: 'pediasureplus-qc',
    component: PediasureplusQcComponent,
  },
  {
    path: 'pediasureplus-product',
    component: PediasureplusProductComponent,
  },
  {
    path: 'pediasureplus-mixinstruction',
    component: PediasureplusMixinstructionComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
