import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-traceability-home',
  templateUrl: './traceability-home.component.html',
  styleUrls: ['./traceability-home.component.scss'],
})
export class TraceabilityHomeComponent implements OnInit {
  productData: any;
  content: any;
  productCode: any;
  primaryProductTitle: any;
  productName: any;
  manufactCountry: any;
  CRMproductName: any;
  expiryDate: any;
  manufacturedDate: any;
  lotNumber: any;
  sfProductCode: any;
  sfPrice: any;
  productImage: any;
  prodImage: any;
  sku: any;
  isIsprint: any;
  origin: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
    this.getTracebilityContent();
    this.apiService.getGpasProductImage(this.sku).subscribe(
      (res: any) => {
        console.log(res.data, 'getGpasProductImage');
        this.prodImage = res.data[0]?.productimage;
        console.log(this.prodImage);
      },
      (err) => {
        this.bsService.notifyError(err);
      }
    );
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  getTracebilityContent() {
    this.productData = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productData);
    this.productImage = this.productData.product_image;
    // this.productCode = this.productData.product.productCode;
    this.manufacturedDate = this.productData.manufacture_date;
    this.expiryDate = this.productData.expiry_date;
    this.CRMproductName = this.productData.crm_name;

    this.lotNumber = this.productData.lot_number;
    this.sku = this.productData.sku;
    this.isIsprint = this.productData.is_isprint;

    if (this.productData.is_isprint == true) {
      let parsedManufactCountry = this.parseFunction(
        this.productData.country_of_origin
      );
      this.manufactCountry = parsedManufactCountry.th_TH;

      let parsedProductName = this.parseFunction(this.productData.product_name);
      this.productName = this.productData?.crm_name?.th_TH;
    } else {
      this.manufactCountry = this.productData.origin;
      this.productName = this.productData.thai_product_name;
      // this.origin = this.productData.origin;
    }
    // this.content = this.productData.product.txt;
    // this.content.forEach((item: any) => {

    //   if (item.orders == 1) {
    //     let parsedPrimaryValue = this.parseFunction(item.value);
    //     this.primaryProductTitle = parsedPrimaryValue.th_TH;
    //     let parsedProductName = this.parseFunction(item.name);
    //     this.productName = parsedProductName.th_TH;
    //   }

    //   if (item.orders == 2) {
    //     let crmProductName = this.parseFunction(item.value);
    //     this.CRMproductName = crmProductName.th_TH;
    //   }

    //   if (item.orders == 3) {
    //     let parsedExpiryDate = this.parseFunction(item.value);
    //     this.expiryDate = parsedExpiryDate.value;
    //   }

    //   if (item.orders == 4) {
    //     let parsedDate = this.parseFunction(item.value);
    //     this.manufacturedDate = parsedDate.value;
    //   }

    //   if (item.orders == 5) {
    //     let lotNo = this.parseFunction(item.value);
    //     this.lotNumber = lotNo.value;
    //   }

    //   if (item.orders == 6) {
    //     let country_of_origin = this.parseFunction(item.value);
    //     this.manufactCountry = country_of_origin.th_TH;
    //   }

    //   if (item.orders == 7) {
    //     let sf_product_code = this.parseFunction(item.value);
    //     this.sfProductCode = sf_product_code.value;
    //   }

    //   if (item.orders == 8) {
    //     let sf_price = this.parseFunction(item.value);
    //     this.sfPrice = sf_price.value;
    //   }
    // });
  }
}
