import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensure-manufacturing',
  templateUrl: './ensure-manufacturing.component.html',
  styleUrls: ['./ensure-manufacturing.component.scss'],
})
export class EnsureManufacturingComponent implements OnInit {
  productData: any;
  content: any;
  manufactCountry: any;
  expiryDate: any;
  manufacturedDate: any;
  origin: any;
  constructor() {
    window.scrollTo({ top: 0 });
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  ngOnInit(): void {
    this.getTracebilityContent();
  }

  getTracebilityContent() {
    this.productData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.manufacturedDate = this.productData.manufacture_date;
    if (this.productData.is_isprint == true) {
      let parsedManufactCountry = this.parseFunction(
        this.productData.country_of_origin
      );
      this.manufactCountry = parsedManufactCountry.th_TH;
    } else {
      this.manufactCountry = this.productData.origin;
      // this.origin = this.productData.origin;
    }
  }
}
