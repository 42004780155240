<div class="ensure-container">
  <app-ensure-family-club></app-ensure-family-club>
  <!-- <div class="ensure-family-club">
        <img
          class="img-fluid"
          src="../../../assets/images/ensure-gold/Ensure-gold-family-club.png"
          alt="My Family Club"
        />
      </div> -->

  <div class="ensure-logo-section">
    <img
      class="img-fluid"
      src="../../../assets/images/ensure-gold/ensure-logo.webp"
      alt="Ensure brand"
    />
    <a
      ><img
        class="img-fluid ensure-home-icon"
        src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
        alt="Ensure Home" routerLink="/ensureh-traceability"
    /></a>
  </div>

  <div class="prod-section">
    <div class="receipe-top-section">
      <img
        class="img-fluid mw-75"
        src="../../../assets/images/ensureh/ensureh-text-img.png"
        alt="Complete Reciepe"
      />
      <h3>อาหารสูตรครบถ้วน</h3>
      <p class="lead">
        ให้สารอาหารที่ร่างกายต้องการ พร้อมวิตามิน แร่ธาตุ และใยอาหาร
        เพื่อเสริมโภชนาการ <span>ช่วยให้ร่างกายแข็งแรง</span>
      </p>
      <img
        class="mw-90"
        src="../../../assets/images/ensureh/ensureh_packshot.png"
      />
      <h4>มีสารอาหารหลากหลาย</h4>
    </div>

    <div class="prod-features">
      <img
        src="../../../assets/images/ensureh/ensureh-protein-img-new.png"
      />
      <p>
        <span>ประกอบด้วยโปรตีน 3 ชนิด</span><br />
        เวย์ เคซีน และโปรตีนสกัดจากถั่วเหลือง โปรตีนมีส่วนช่วยเสริมสร้างและ<br />
        <span class="txt-brown txt-sm">มวลกล้ามเนื้อ </span>
      </p>

      <img
        class="img-sm mt-4"
        src="../../../assets/images/ensureh/ensureh-dietery-img.png"
      />
      <p>
        <span>มีใยอาหาร</span>
      </p>

      <img
        class="img-sm mt-4"
        src="../../../assets/images/ensureh/ensureh-vitamin-img.png"
      />

      <p>
        <span>มีวิตามินดี, มีแคลเซียม</span><br />
        วิตามินดีมีส่วนช่วยคงสภาพปกติ ของ
        <span class="txt-brown">กระดูก</span> แคลเซียมจำเป็นต่อการ คงสภาพปกติของ
        <span class="txt-brown">กระดูกและฟัน</span>
      </p>

      <img
        class="img-sm mt-4"
        src="../../../assets/images/ensureh/ensureh-vitamin-b12-img.png"
      />

      <p>
        <span>วิตามินบี 12, วิตามินซี, เหล็ก</span><br />
        มีส่วนช่วยในการทำหน้าที่ ตามปกติของ
        <span class="txt-brown">ระบบภูมิคุ้มกัน</span>
      </p>
    </div>
  </div>
  <div class="prod-awards">
    <img class="img-sm" src="../../../assets/images/ensureh/ensureh-1.png" />
  </div>
  <div class="prod-bottom-section">
    <h4 class="text-center">
      อาหารทางการแพทย์ <br />ต้องใช้ภายใต้คำแนะนำของแพทย์
    </h4>
    <p>
      *การคำนวณส่วนแบ่งการตลาดของ Abbott Laboratories Limited
      ส่วนหนึ่งอ้างอิงจาก ข้อมูลดัชนีค้าปลีกรายเดือน ที่รายงานโดย Nielsen
      สำหรับตลาดนมผงสูตรอาหารครบถ้วน สำหรับผู้ใหญ่ ตามคำนิยามของบริษัท Abbott
      Laboratories ในช่วงเวลาตั้งแต่เดือน มกราคม 2560 สิ้นสุดเดือนตุลาคม 2563
      ซึ่งเป็นข้อมูลของตลาดในประเทศไทย ซึ่งเป็น ลิขสิทธิ์ของ Nielsen (Copyright
      © 2020, Nielsen)
    </p>
    <span class="float-left">ใบอนุญาตโฆษณาเลขที่ ฆอ.1696/2565</span>
    <span class="float-right text-uppercase">TH.2022.26825.ENS.1(v1.0)©2022Abbott </span>
  </div>
</div>
<app-ensure-cereal-nav></app-ensure-cereal-nav>
