<ul class="traceabilityNav">
  <li [ngClass]="{ activeClass: activeManufacturing }" routerLink="/ensuregold-manufacturing">
    <figure>
      <img
        src="../../../../assets/images/ensure/ensurenav-home.png"
        alt="home"
      />
    </figure>
    <p>{{ "TRACEABILITY_NAV.PRODUCT_PRODUCTION_STANDARDS" | translate }}</p>
  </li>
  <li [ngClass]="{ activeClass: activeQualityControl }" routerLink="/ensuregold-qc">
    <figure>
      <img
        src="../../../../assets/images/ensure/ensurenav-search.png"
        alt="search"
      />
    </figure>
    <p>{{ "TRACEABILITY_NAV.PRODUCT_QUALITY_CONTROL" | translate }}</p>
  </li>
  <li [ngClass]="{ activeClass: activeBranded }" routerLink="/ensuregold-product">
    <figure>
      <img
        src="../../../../assets/images/ensure/esnurenav-glass.png"
        alt="glass"
      />
    </figure>
    <p>{{ "TRACEABILITY_NAV.ENSURE_GOLD_PRODUCT_ONE" | translate }} <br>{{ "TRACEABILITY_NAV.ENSURE_GOLD_PRODUCT_TWO" | translate }}</p>
  </li>
  <li [ngClass]="{ activeClass: activeTriplePlus }" routerLink="/ensuregold-mixinstruction">
    <figure>
      <img
        src="../../../../assets/images/ensure/ensurenav-scoop.png"
        alt="triple plus"
      />
    </figure>
    <p>{{ "TRACEABILITY_NAV.ENSURE_GOLD_mixing_ONE" | translate }} <br>{{ "TRACEABILITY_NAV.ENSURE_GOLD_mixing_TWO" | translate }}</p>
  </li>
  <li [ngClass]="{ activeClass: activeLogIn }" (click)="skipTrans()" >
    <figure class="familyClub">
      <img
        src="../../../assets/images/my_family_club_nav.png"
        alt="my family club"
      />
    </figure>
    <p>{{ "TRACEABILITY_NAV.MY_FAMILY_CLUB" | translate }}</p>
  </li>
</ul>
