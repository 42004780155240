import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pediasureplus-mixinstruction',
  templateUrl: './pediasureplus-mixinstruction.component.html',
  styleUrls: ['./pediasureplus-mixinstruction.component.scss'],
})
export class PediasureplusMixinstructionComponent implements OnInit {
  constructor() {
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {}
}
