<div class="globalContainer _100vh">
  <header>
    <a class="prevLocation" (click)="goBack()">
      <img src="../../assets/images/back_arrow.svg" alt="back arrow" />
    </a>
    <img
      class="headerLogo"
      src="../../assets/images/loginlogo.png"
      alt="logo"
    />
  </header>
  <div class="myFamilyClub">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          href="#accPoints"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          >{{ "HISTORY.ACCUMULATUING_POINTS" | translate }}</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          (click)="gotoHistory()"
          class="nav-link"
          id="profile-tab"
          data-toggle="tab"
          href="#redemption"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          >{{ "HISTORY.REDEMPTION" | translate }}</a
        >
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="accPoints"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="monthYearOut">
          <div class="row">
            <div class="col-7">
              <div class="customDatepicker" *ngIf="disableCurrentyear == false">
                <!-- <i class="mdi mdi-chevron-down"></i> -->
                <select
                  class="form-control"
                  placement="bottom"
                  [(ngModel)]="modelMonth"
                  (ngModelChange)="monthChanged(modelMonth)"
                >
                  <option
                    [disabled]="currentMonth < 0"
                    [selected]="currentMonth == 0"
                    value="0"
                  >
                    มกราคม
                  </option>
                  <option
                    [disabled]="currentMonth < 1"
                    [selected]="currentMonth == 1"
                    value="1"
                  >
                    กุมภาพันธ์
                  </option>
                  <option
                    [disabled]="currentMonth < 2"
                    [selected]="currentMonth == 2"
                    value="2"
                  >
                    มีนาคม
                  </option>
                  <option
                    [disabled]="currentMonth < 3"
                    [selected]="currentMonth == 3"
                    value="3"
                  >
                    เมษายน
                  </option>
                  <option
                    [disabled]="currentMonth < 4"
                    [selected]="currentMonth == 4"
                    value="4"
                  >
                    พฤษภาคม
                  </option>
                  <option
                    [disabled]="currentMonth < 5"
                    [selected]="currentMonth == 5"
                    value="5"
                  >
                    มิถุนายน
                  </option>
                  <option
                    [disabled]="currentMonth < 6"
                    [selected]="currentMonth == 6"
                    value="6"
                  >
                    กรกฎาคม
                  </option>
                  <option
                    [disabled]="currentMonth < 7"
                    [selected]="currentMonth == 7"
                    value="7"
                  >
                    สิงหาคม
                  </option>
                  <option
                    [disabled]="currentMonth < 8"
                    [selected]="currentMonth == 8"
                    value="8"
                  >
                    กันยายน
                  </option>
                  <option
                    [disabled]="currentMonth < 9"
                    [selected]="currentMonth == 9"
                    value="9"
                  >
                    ตุลาคม
                  </option>
                  <option
                    [disabled]="currentMonth < 10"
                    [selected]="currentMonth == 10"
                    value="10"
                  >
                    พฤศจิกายน
                  </option>
                  <option
                    [disabled]="currentMonth < 11"
                    [selected]="currentMonth == 11"
                    value="11"
                  >
                    ธันวาคม
                  </option>
                </select>
              </div>
              <div class="customDatepicker" *ngIf="disableCurrentyear == true">
                <!-- <i class="mdi mdi-chevron-down"></i> -->
                <select
                  class="form-control"
                  placement="bottom"
                  [(ngModel)]="modelMonth"
                  (ngModelChange)="monthChanged(modelMonth)"
                >
                  <option value="0">มกราคม</option>
                  <option value="1">กุมภาพันธ์</option>
                  <option value="2">มีนาคม</option>
                  <option value="3">เมษายน</option>
                  <option value="4">พฤษภาคม</option>
                  <option value="5">มิถุนายน</option>
                  <option value="6">กรกฎาคม</option>
                  <option value="7">สิงหาคม</option>
                  <option value="8">กันยายน</option>
                  <option value="9">ตุลาคม</option>
                  <option value="10">พฤศจิกายน</option>
                  <option value="11">ธันวาคม</option>
                </select>
              </div>
            </div>
            <div class="col-5">
              <div class="customDatepicker">
                <!-- <i class="mdi mdi-chevron-down"></i> -->
                <select
                  class="form-control"
                  [(ngModel)]="modelYear"
                  (ngModelChange)="yearChanged(modelYear)"
                  placement="bottom"
                  value=""
                >
                  <option
                    [disabled]="currentYear < 2020"
                    [selected]="currentYear == 2020"
                    value="2020"
                  >
                    2020
                  </option>
                  <option
                    [disabled]="currentYear < 2021"
                    [selected]="currentYear == 2021"
                    value="2021"
                  >
                    2021
                  </option>
                  <option
                    [disabled]="currentYear < 2022"
                    [selected]="currentYear == 2022"
                    value="2022"
                  >
                    2022
                  </option>
                  <option
                    [disabled]="currentYear < 2023"
                    [selected]="currentYear == 2023"
                    value="2023"
                  >
                    2023
                  </option>
                  <option
                    [disabled]="currentYear < 2024"
                    [selected]="currentYear == 2024"
                    value="2024"
                  >
                    2024
                  </option>
                  <option
                    [disabled]="currentYear < 2025"
                    [selected]="currentYear == 2025"
                    value="2025"
                  >
                    2025
                  </option>
                  <option
                    [disabled]="currentYear < 2026"
                    [selected]="currentYear == 2026"
                    value="2026"
                  >
                    2026
                  </option>
                  <option
                    [disabled]="currentYear < 2027"
                    [selected]="currentYear == 2027"
                    value="2027"
                  >
                    2027
                  </option>
                  <option
                    [disabled]="currentYear < 2028"
                    [selected]="currentYear == 2028"
                    value="2028"
                  >
                    2028
                  </option>
                  <option
                    [disabled]="currentYear < 2029"
                    [selected]="currentYear == 2029"
                    value="2029"
                  >
                    2029
                  </option>
                  <option
                    [disabled]="currentYear < 2030"
                    [selected]="currentYear == 2030"
                    value="2030"
                  >
                    2030
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <ul class="earnPointsList">
          <li *ngFor="let product of filteredProductList">
            <div class="row">
              <div class="col-7">
                <span class="date">{{ product?.submissionDate }}</span>
                <div class="product-name">
                  {{ product?.name }}
                </div>
                <!-- <div class="product-name" *ngIf="product?.status == 'awaiting'">
                  สะสมคะแนนจากการอัพเดท โปรไฟล์
                </div> -->
                <!-- <span>ID : 1112369852</span> -->
              </div>
              <div class="col-5 text-right">
                <div
                  class="pointsDetails"
                  *ngIf="
                    product?.status == 'approved' ||
                    product?.status == 'success'
                  "
                >
                  <button>
                    <span class="points">{{ product?.points }}</span
                    ><span class="text">คะแนน</span>
                  </button>
                </div>

                <div
                  class="pointsDetails"
                  *ngIf="product?.status == 'rejected'"
                >
                  <button style="background: #d9d9d9">
                    <!-- <span class="points">20</span
                                > -->
                    <span class="text" style="color: #8c9095">ไม่สำเร็จ</span>
                  </button>
                </div>

                <div class="pointsDetails" *ngIf="product?.status == 'pending'">
                  <button>
                    <span class="text">อัปโหลดใบเสร็จ</span>
                  </button>
                  <a
                    class="upload-receipt-click text-center"
                    (click)="onclickUploadReceipt(product?.productCode)"
                  >
                    คลิก
                  </a>
                </div>

                <div
                  class="pointsDetails"
                  *ngIf="product?.status == 'awaiting'"
                >
                  <button>
                    <span class="text">กำลังตรวจสอบ</span>
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li [hidden]="bonousPointList?.length == 0" *ngFor="let point of bonousPointList">
            <div class="row">
                <div class="col-7">
                    <span class="date">{{point?.issued_on | date:'dd/MM/yyyy HH:mm'}}</span>
                    <p>{{ 'HISTORY.EARN_POINTS_PROMOTION' | translate }}</p>
                </div>
                <div class="col-5 text-right">
                    <div class="pointsDetails">
                        <!-- <strong>{{ 'HISTORY.RECEIVE' | translate }}</strong> -->
                        <button><span class="points">{{point?.points }}</span><span class="text">{{ 'HISTORY.SCORE' | translate }}</span></button>
                    </div>
                </div>
            </div>
        </li>
        </ul>
        <!-- <ul class="earnPointsList">
                    <li *ngFor="let point of pointsList">
                        <div class="row">
                            <div class="col-7">
                                <span class="date">{{point?.billing_time }}</span>
                                
                                 <span class="date">{{ point?.billing_time | date:'dd/MM/yyyy'}} </span>
                                <span class="date"> {{ point?.billing_time | date:'HH:MM'}}</span> 
                                <p>{{ 'HISTORY.EARN_POINTS_FROM_PURCHASES' | translate }}</p>
                            </div>
                            <div class="col-5 text-right">
                                <div class="pointsDetails">
                                    <strong>{{ 'HISTORY.RECEIVE' | translate }}</strong>
                                    <button><span class="points">{{point?.points?.issued }}</span><span class="text">{{ 'HISTORY.SCORE' | translate }}</span></button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li *ngFor="let point of bonousPointList">
                        <div class="row">
                            <div class="col-7">
                                <span class="date">{{point?.issued_on }}</span>
                                 <span class="date">{{point?.issued_on | date:'dd/MM/yyyy'}} </span>
                                <span class="date"> {{ point?.issued_on | date:'HH:MM'}}</span>
                                 
                                <p>{{ 'HISTORY.EARN_POINTS_PROMOTION' | translate }}</p>
                            </div>
                            <div class="col-5 text-right">
                                <div class="pointsDetails">
                                    <strong>{{ 'HISTORY.RECEIVE' | translate }}</strong>
                                    <button><span class="points">{{point?.points }}</span><span class="text">{{ 'HISTORY.SCORE' | translate }}</span></button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul> -->
        <!-- <div
          *ngIf="pointsList?.length == 0 && bonousPointList?.length == 0"
          class="noDataFound"
        >
          <img
            src="../../../assets/images/no_data_found.png"
            alt="no data found"
          />
          <p>{{ "HISTORY.NO_DATA_FOUND" | translate }}</p>
        </div> -->
      </div>
      <div
        class="tab-pane fade"
        id="redemption"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="container">
          <form
            method="post"
            action="https://myfamilyclub.buzzebees.com/landing"
            id="formSubmit"
          >
            <input
              id="abbott_token"
              type="hidden"
              name="abbott_token"
              value="abbott_token"
            />
            <input
              id="auth_key"
              type="hidden"
              name="auth_key"
              value="auth_key"
            />
            <input
              id="abbott_user_id"
              type="hidden"
              name="abbott_user_id"
              value="abbott_user_id"
            />
            <input
              id="target_page"
              type="hidden"
              name="target_page"
              value="target_page"
            />

            <!-- <button type="button" va id="buttonSubmit">submit</button> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<!-- pop after click to upload receipt -->
<div
  bsModal
  #popupModalUploadReceipt="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <section class="text-center">
          <!-- <button type="button" class="close" aria-label="Close">
            <img src="../../../assets/images/close.png" />
          </button> -->
          <!-- <h5 class="primaryBlue mt25 primaryTitle">ยืนยันการแลกรางวัล</h5> -->
          <!-- <h5 class="secondaryBlue secondaryTitle mt30">
            ยืนยันการแลกรางวัล
          </h5> -->
          <div class="d-flex flex-row bd-highlight mt-3">
            <div class="p-2 bd-highlight w-100">
              
              <span class="p-1 bd-highlight">
                กรุณาถ่ายรูปใบเสร็จที่ท่านซื้อผลิตภัณฑ์นี้ <br />
                และอัปโหลดส่งเข้าระบบ<br />
                ภายในระยะเวลา 7 วัน เพื่อรับคะแนนสะสม​<br />
                หากท่านทำใบเสร็จสูญหายหรือมีข้อสงสัย <br />
                กรุณาติดต่อ Abbott Care 02-252-2448  <br />
                ระหว่างวันจันทร์-ศุกร์ 8:30-17:30 น. <br />
                (ยกเว้นวันหยุดนักขัตฤกษ์)​
                
              </span>
              <!-- <span>
                We reserve the right to check every receipt sent and have the
                right to cancel if the receipt sent is invalid
              </span> -->
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-block goldenBtn mt25 mb10"
            (click)="openCamera()"
          >
            รับทราบ
          </button>
        </section>
      </div>
    </div>
  </div>
</div>
