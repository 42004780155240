import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensuregold-product',
  templateUrl: './ensuregold-product.component.html',
  styleUrls: ['./ensuregold-product.component.scss']
})
export class EnsuregoldProductComponent implements OnInit {
  productImage: string;

  constructor() { 
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
  }

}
