import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensuregold-mixinstruction',
  templateUrl: './ensuregold-mixinstruction.component.html',
  styleUrls: ['./ensuregold-mixinstruction.component.scss']
})
export class EnsuregoldMixinstructionComponent implements OnInit {

  constructor() {
    window.scrollTo({ top: 0 });
   }

  ngOnInit(): void {
  }

}
