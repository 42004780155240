import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as ScanditSDK from "scandit-sdk";
import { BarcodePicker, ScanResult, configure } from "scandit-sdk";
import { ApiService } from '../../../../src/app/services/api.service';
import { Location } from '@angular/common';
import { BugsnagService } from 'src/app/services/bugsnag.service';
declare var ZXing: any;
import { environment as env } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import liff from '@line/liff';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit {
  user: any;
  userPoints: any;
  showProfile: Boolean = true;
  showScanner: boolean = true;
  loader: boolean = true;

  iSprintData: any;
  image: string;
  customerName: any;
  suspiciousCount = 0;
  prodImage: any;

  categoryCode: any;
  scancodeData: any;
  scanCode: any;
  pointsEarned: any;
  videoStart = false;
  tabId = 'ensure-tab';
  latitude: string;
  longitude: string;
  confidence: string;
  public scannerReady = false;
  public showButton = false;
  public showDescription = true;
  public result = "";
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };
  @ViewChild("barcodePicker") barcodePickerElement: ElementRef<HTMLDivElement & { barcodePicker: BarcodePicker }>;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('successScanModal', { static: true })
  successScanModal: ModalDirective;
  @ViewChild('failedScanModal', { static: true })
  failedScanModal: ModalDirective;
  @ViewChild('camerapermission', { static: true }) camerapermission: ModalDirective;


  constructor(
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private location: Location,
    private bsService: BugsnagService,@Inject(DOCUMENT) private document: Document
  ) {
    this.getPosition();
    this.onClickScanner()
  }

  ngOnInit(): void {
    // this.getCustomer();
    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
    console.log(this.iSprintData);
    this.scanCode = sessionStorage.getItem('scanCode')
    this.showProfile = false;

    if (this.showScanner && this.scanCode) {
      //this.startVideo();
      console.log(this.longitude, this.latitude, this.scanCode);
      this.apiService
        .productinfo(
          this.scanCode,
          this.latitude,
          this.longitude,
          this.confidence
        )
        .subscribe((res) => console.log(res));
    }
  }

  ontabChange(ids: any) {
    this.tabId = ids;
  }


  onClickScanner() {
    //this.popupModalOne.hide();
     //this.popupModalOne.hide();
     this.showScanner = true;
     this.showProfile = false;
 
     let selectedDeviceId;
     const codeReader = new ZXing.BrowserMultiFormatReader();
     console.log('ZXing code reader initialized');
     codeReader
         .listVideoInputDevices()
         .then((videoInputDevices) => {
             const sourceSelect = document.getElementById('sourceSelect');
             selectedDeviceId = videoInputDevices[0].deviceId;
             codeReader.decodeFromVideoDevice(undefined, 'video', (result, err) => {
                 if (result) {
                     let data = result.text;
                     console.log(data, 'scanned data===');
                     let array = data.split('=');
                     let scannedCode = array[1];
                     this.scanCode = scannedCode;
                     codeReader.reset();
                     console.log(scannedCode, ' scannedCode');
                     sessionStorage.setItem('scannedCode', scannedCode);
                     let scanCode = sessionStorage.getItem('scannedCode');
                     console.log(scanCode, 'scanCode first');
                     this.spinner.show();
                     this.getTraceabilityContent()
                 }
 
                 if (err && !(err instanceof ZXing.NotFoundException)) {
                     console.error(err);
                     alert(err)
                     document.getElementById('result').textContent = err;
                 }
             });
             console.log(
                 `Started continous decode from camera with id ${selectedDeviceId}`
             );
         })
         .catch((err) => {
             console.error(err);
             alert("183: "+err)
         });
    }
  

  isAuthToken(token){
    return token == null || token == undefined || token == '' ? false : true
  }


  closeScanner() {
    this.showScanner = false;
    this.location.back();
  }

  onClickSuccessScanModal() {
    this.successScanModal.show();
  }
  closeSuccessScanModal() {
    this.successScanModal.hide();
  }
  onClickFailedScanModal() {
    this.failedScanModal.show();
  }
  closeFailedScanModal() {
    this.failedScanModal.hide();
    this.closeScanner();
  }

  getPosition() {
    this.apiService.getPosition().then((pos) => {
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
      console.log(lang, 'lang');
      if (this.loader ) {
        //  this.getTraceabilityContent();
      }
    });
  }

  getTraceabilityContent() {
    this.loader = true;
    this.apiService
      .productinfo(
        this.scanCode,
        this.latitude,
        this.longitude,
        this.confidence
      )
      .subscribe(
        (res: any) => {
          this.loader = false;
          console.log(res, 'res isprint');

          if (res.status === 'success') {
            sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
            let cat_code = res.result.category_code;
            sessionStorage.setItem('catCode', cat_code);
            //this.catCode = res.result.category_code;
            this.spinner.hide()
            if (res.result.uaid_info_type == 1) {
              if (res.result?.is_isprint == false) {
                if (
                  (cat_code.startsWith('Ensure') ||
                    cat_code.startsWith('ENSURE') ||
                    cat_code.startsWith('ensure')) &&
                  (res.result.sku == '100S616233385' ||
                    res.result.sku == '100S842233385' ||
                    res.result.sku == '100S617133385' ||
                    res.result.sku == '100S616233440' ||
                    res.result.sku == '100S842233340')
                ) {
                  this.router.navigate(['/ensureh-traceability']);
                } else if (
                  (cat_code.startsWith('Ensure') ||
                    cat_code.startsWith('ENSURE') ||
                    cat_code.startsWith('ensure')) &&
                  (res.result.sku == '100S907233140' ||
                    res.result.sku == '100S907233185' ||
                    res.result.sku == '100S956233140' ||
                    res.result.sku == '100S956233185' ||
                    res.result.sku == '100Y483833185' ||
                    res.result.sku == '100Y483833140' ||
                    res.result.sku == '100Y484833140' ||
                    res.result.sku == '100Y511233140' ||
                    res.result.sku == '100S907233240' ||
                    res.result.sku == '100S956233240')
                ) {
                  this.router.navigate(['/ensuregold-traceability']);
                } else if (
                  (cat_code.startsWith('Ensure') ||
                    cat_code.startsWith('ENSURE') ||
                    cat_code.startsWith('ensure')) &&
                  (res.result.sku == '100Y576233140' ||
                    res.result.sku == '100Y576233185')
                  ) {
                  this.router.navigate(['/ensureplantbased-home']);
                } 
                else if (
                  (cat_code.startsWith('Ensure') ||
                    cat_code.startsWith('ENSURE') ||
                    cat_code.startsWith('ensure')) &&
                  (res.result.sku == '100Y576233140' ||
                    res.result.sku == '100Y576233185')
                  ) {
                    this.router.navigate(['/ensureplantbased-home']);
                } 
                else if (
              
                  cat_code.startsWith('PediaSure') ||
                  cat_code.startsWith('Pediasure') ||
                  cat_code.startsWith('pediasure') ||
                  cat_code.startsWith('PEDIASURE') &&
                  res.result.sku == '100S792233240'

                ) {
                  this.router.navigate(['/traceability-home']);
                }


                else if (
                  (cat_code.startsWith('Glucerna') ||
                    cat_code.startsWith('GLUCERNA') ||
                    cat_code.startsWith('glucerna')) &&
                  (res.result.sku == '100Y524833140' ||
                    res.result.sku == '100Y524833185')
                ) {
                  this.router.navigate(['/glucernaplus-traceability']);
                }
                else if (
                  cat_code.startsWith('Ensure') ||
                  cat_code.startsWith('ENSURE') ||
                  cat_code.startsWith('ensure')
                ) {
                  this.router.navigate(['/ensure-traceability']);
                } else if (
                  cat_code.startsWith('Glucerna') ||
                  cat_code.startsWith('GLUCERNA') ||
                  cat_code.startsWith('glucerna')
                ) {
                  this.router.navigate(['/glucerna-traceability']);
                } else if (
                  cat_code.startsWith('PediaSure') ||
                  cat_code.startsWith('Pediasure') ||
                  cat_code.startsWith('pediasure') ||
                  cat_code.startsWith('PEDIASURE')
                ) {
                  this.router.navigate(['/traceability-home']);
                }
              } else if (res.result?.is_isprint == true) {
                if (
                  (res.result?.category_code == 'Ensure Gold')) {
                  this.router.navigate(['/ensuregold-traceability']);
                  return;
                }
                if (

                  res.result?.category_code == 'Ensure POP H'
                ) {
                  this.router.navigate(['/ensureh-traceability']);
                  return;
                }
                if (

                  res.result?.category_code == 'Glucerna Plus'
                ) {
                  this.router.navigate(['/glucernaplus-traceability']);
                  return;
                }

                else if (

                  res.result?.category_code == 'Pediasure Plus'
                ) {
                  this.router.navigate(['/pediasureplus-traceability']);
                  return;
                } else if (
                  cat_code.startsWith('Ensure') ||
                  cat_code.startsWith('ENSURE') ||
                  cat_code.startsWith('ensure')
                ) {
                  this.router.navigate(['/ensure-traceability']);
                } else if (
                  cat_code.startsWith('Glucerna') ||
                  cat_code.startsWith('GLUCERNA') ||
                  cat_code.startsWith('glucerna')
                ) {
                  this.router.navigate(['/glucerna-traceability']);
                } else if (
                  cat_code.startsWith('PediaSure') ||
                  cat_code.startsWith('Pediasure') ||
                  cat_code.startsWith('pediasure') ||
                  cat_code.startsWith('PEDIASURE')
                ) {
                  this.router.navigate(['/traceability-home']);
                }
              }
            } else  {
            // Both Gpass and Isprint(hidden) comes to this page
            sessionStorage.setItem('scanCode', this.scanCode);
            //for not logged in iSprint and Gpass QR code scan navigate to line consent page.
           if(!this.isAuthToken(sessionStorage.getItem('authToken'))) {
            let brower = (window.navigator.userAgent.toLowerCase()).toLowerCase()
                if (brower.includes('line')) {
                  let loc = `${env.LIFF_URL}?scancode=${this.scanCode}`
                  window.open(loc,"_self")
            } else this.router.navigate(['line-landing', {scancode : this.scanCode}])
           } else{
            sessionStorage.setItem('isQRScannedFrmCam', 'true');
            this.router.navigate(['/home']);
           } 
            }
          } else {
            alert('Please scan a valid QR code.');
          }
        },
        (err) => {
          this.spinner.hide()
          console.log(err.error.message);
          alert(err.error.message);
          this.loader = false;
          this.bsService.notifyError(err);
        }
      );
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
}
