import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private profileObs$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  behavsubject = new BehaviorSubject(<boolean>false);
  salesforceCheck = new BehaviorSubject(<boolean>false);
  public pointChanged:Subject<any> = new Subject();
  loggerApi(formData) {
    return this.http.post(env.EMAIL_CHECK_API + '/mobile/log', formData);
  }
  tokenGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/token/generate',
      formData,
      { headers: headers }
    );
  }

  showErrorMsg(msg: any) {
    this.toastr.error(msg, 'Error', {
      timeOut: 5000
    });
  }

  passwordValidate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/password/validate',
      formData,
      { headers: headers }
    );
  }

  forgotPassword(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/password/forget',
      formData,
      { headers: headers }
    );
  }

  otpGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/otp/generate',
      formData,
      { headers: headers }
    );
  }

  otpValidate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/otp/validate',
      formData,
      { headers: headers }
    );
  }

  tokenReGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/token/regenerate',
      formData,
      { headers: headers }
    );
  }
  getCapCustomer(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + '/customer/get?user_id=true', {
      headers: headers,
    });
  }

 

  getCapCustomerPromotion(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(
      env.API_BASE_URL +
      `/customer/get?mobile=${mobileNo}&format=json&promotion_points=True&user_id=true`,
      { headers: headers }
    );
  }

  getSalesForceData(mobile: string) {
    let headers = new HttpHeaders({
      'country-code': 'TH',
      language: 'en_us',
      "Authorization" : env.MOBILE_APP_KEY,
    });
    return this.http.get(
      env.SALESFORCE_BASE_URL + `/salesforce/contact?mobile=${mobile}`,
      { headers: headers }
    );
  }
  getPrivacyPolicyData() {
    return this.http.get(env.CMS_URL + '/privacy');
  }

  getTermsandCondition() {
    return this.http.get(env.CMS_URL + '/tnc');
  }

  addCapCustomer(formData: any, mobileNo: string, getAuthKey: string) {
    console.log(mobileNo, 'mobileNo---');
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.post(env.API_BASE_URL + '/customer/add', formData, {
      headers: headers,
    });
  }

  getLineUserAccountExistingDetails(code:any,scancode:any){
    // var redirect_uri = ''
    // if(scancode == 'xyz')  redirect_uri = `${env.REDIRECT_URI}`
    // else redirect_uri = `${env.REDIRECT_URI}?scan=${scancode}` 
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let body={
      "code":code,
      "redirect_uri":`${env.REDIRECT_URI}?scan=${scancode}`
    }
    console.log('getLineUserAccount body', body)
    console.log('getLineUserAccount headers', headers)
    console.log('getLineUserAccount API_URl', env.API_URl)
    return this.http.post(env.API_URl+`/v1/th/lineUser`,body,{headers} )
  }

  updateLineID(auth:any,mobile:any,userid:any, formData:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      cap_authorization: auth,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobile,
    });
    return this.http.post(env.API_BASE_URL+`/change/lineid?user_id=${userid}`,formData,{headers})
  }

  getLineUserBasedOnToken(token:any){
    var payload = {
      token: token
    }
    return this.http.post(env.API_URl+`/v1/th/lineUserProfile`,payload)
  }

  addLineAccount(auth:any,mobile:any,formdata){
    let headers = new HttpHeaders({
      cap_authorization: auth,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobile,
      'Content-Type': 'application/json',
    });

    return this.http.post(env.API_BASE_URL+`/add/line/account`,formdata,{headers})
  }

  getLineDetails(auth:any,mobile:any){
    let headers = new HttpHeaders({
      cap_authorization: auth,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobile,
      'Content-Type': 'application/json',
    });

    return this.http.get(env.API_BASE_URL+`/get/line/details`,{headers})
  }

  updateCapCustomer(formData: any, mobileNo: string, getAuthKey: string) {
    console.log(mobileNo, 'mobileNo===');
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.post(env.API_BASE_URL + '/customer/update', formData, {
      headers: headers,
    });
  }

  updateCapCustomerIdentity(
    formData: any,
    mobileNo: string,
    getAuthKey: string
  ) {
    console.log(mobileNo, 'mobileNo===');
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.post(
      env.API_BASE_URL + '/customer/update_identity',
      formData,
      { headers: headers }
    );
  }

  getTraceability(scanCode: any) {
    sessionStorage.setItem('scanCode', scanCode);
    console.log(sessionStorage.getItem('scanCode'), 'scan code');
    // nfQzC4mkNF1e
    let headers = new HttpHeaders({
      'country-code': 'TH',
      language: 'en_US',
    });
    return this.http.get(
      `${env.API_URl_TRACEBILITY}/v1/traceability/scancode?scan_code=${scanCode}`,
      { headers: headers }
    );
  }

  getCustomerCoupons(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + '/customer/coupons', {
      headers: headers,
    });
  }

  getCustomerRedemption(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + '/customer/redemptions', {
      headers: headers,
    });
  }

  addTransaction(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      authorization: getAuthKey,
      'country-code': 'TH',
      language: 'en_US',
    });
    console.log(headers, 'headers');
    return this.http.post(env.SALESFORCE_BASE_URL + '/th/scancode', formData, {
      headers: headers,
    });
  }

  // Coupon Reedem
  postCouponRedeem(formData: any, mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.post(env.API_BASE_URL + '/coupon/redeem', formData, {
      headers: headers,
    });
  }

  //  Get Current location
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (resp) => {
            resolve({
              lng: resp.coords.longitude,
              lat: resp.coords.latitude,
              accuracy: Math.floor(resp.coords.accuracy),
            });
          },
          (err) => {
            resolve({ lng: 'undefined', lat: 'undefined' });
          },
          {
            timeout: 3000,
          }
        );
      }
    });
  }

  logout() {
    // remove user from local storage and set current user to null
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  // Toaster Service
  showToaster(msg: any) {
    this.toastr.info(msg);
  }
  showToasterGeo(msg: any) {
    this.toastr.info(msg);
  }

  getSavedImage(
    mobileNo: string,
    getAuthKey: string,
    uniqueId: string,
    format: any
  ) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(
      env.API_BASE_URL + `/image?filename=${uniqueId}${format}`,
      { headers: headers }
    );
  }

  getSavedImageFinal(mobileNo: string, getAuthKey: string, uniqueId: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + `/image?filename=${uniqueId}`, {
      headers: headers,
    });
  }

  customerImageUpload(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      authorization: getAuthKey,
      'country-code': 'TH',
      language: 'en_US',
    });
    return this.http.post(env.SALESFORCE_BASE_URL + '/image/upload', formData, {
      headers: headers,
    });
  }

  checkExistingEmail(email: any) {
    let headers = new HttpHeaders({
      'country-code': 'TH',
      "language": 'en_US',
      "Authorization" : env.MOBILE_APP_KEY,
    });
    return this.http.get(
      env.EMAIL_CHECK_API + `/email/validate?email=${email}`,
      { headers: headers }
    );
  }

  setRememberUser(value) {
    this.behavsubject.next(value);
  }

  getProfileObs(): Observable<any> {
    return this.profileObs$.asObservable();
  }

  setProfileObs(profile: any) {
    this.profileObs$.next(profile);
  }

  setSalesForceUser(value) {
    this.salesforceCheck.next(value);
  }

  changePassword(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      env.AUTH_BASE_URL + '/auth/v1/password/change',
      formData,
      { headers: headers }
    );
  }

  getTermsConditionAbout() {
    return this.http.get(
      env.STTARTER_TERMS_CONDITION_ABOUT_FAQ + '/contentblocks'
    );
  }
  getReferrerName(code) {
    return this.http.get(
      env.STTARTER_TERMS_CONDITION_ABOUT_FAQ +
      `/referraldata?referralcode=${code}`
    );
  }

  getskumap(sku) {
    return this.http.get(
      env.STTARTER_TERMS_CONDITION_ABOUT_FAQ + `/skumap?sku=${sku}`
    );
  }

  getFaq() {
    return this.http.get(env.STTARTER_TERMS_CONDITION_ABOUT_FAQ + '/faq');
  }

  getPoints(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL + '/points/history?mlp=true&type=points',
      { headers: headers }
    );
  }

  getPointsList(mobileNo: string, getAuthKey: string, start_date, end_date) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL +
      `/points/history?mlp=true&type=points&start_date=${start_date}&end_date=${end_date}`,
      { headers: headers }
    );
  }

  getCapCustomerPromotionFilter(
    mobileNo: string,
    getAuthKey: string,
    start_date,
    end_date
  ) {
    let headers = new HttpHeaders({
      cap_authorization: getAuthKey,
      cap_brand: env.BRAND,
      cap_device_id: env.DEVICE_ID,
      cap_mobile: mobileNo,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.API_BASE_URL +
      `/customer/get?mobile=${mobileNo}&format=json&promotion_points=True&user_id=true&start_date=${start_date}&end_date=${end_date}`,
      { headers: headers }
    );
  }

  buzzbeetokengen(formData: any) {
    return this.http.post(
      `${env.SALESFORCE_BASE_URL}/token/generate`,
      formData
    );
  }

  redirectUrlApi(uaaid) {
    let headers = new HttpHeaders({
      'country_code': 'TH',
      'language':'en_us',
      "Authorization" : sessionStorage.getItem('authToken'),
    });
    return this.http.get(
      env.SALESFORCE_BASE_URL + `/redirectiondata?uuid=${uaaid}`, {headers}
    );
  }

  productinfo(
    scanCode: any,
    latitude: any,
    longitude: any,
    confidence: any
  ) {
    let headers = new HttpHeaders({
      'country-code': env.COUNTRY_CODE,
      language: env.LANGUAGE,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.EMAIL_CHECK_API +
      `/th/productinfo?scan_code=${scanCode}&longitude=${longitude}&latitude=${latitude}&confidence=${confidence}`,
      {
        headers: headers,
      }
    );
  }

  postGpassEarnPoints(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      authorization: getAuthKey,
      'country-code': 'TH',
      language: 'en_US',
    });
    return this.http.post(env.SALESFORCE_BASE_URL + '/th/scancode', formData, {
      headers: headers,
    });
  }

  getGpasProductImage(sku) {
    return this.http.get(
      env.PRODUCT_IMAGES + `/productimages?productsku=${sku}`
    );
  }

  getSkuPoints(sku) {
    return this.http.get(
      env.PRODUCT_IMAGES + `/skupoints?sku=${sku}`
    );
  }


  getProductsList(mobile: any) {
    let headers = new HttpHeaders({
      'accept': 'application/json',
      'country-code': 'TH',
      'language': 'en_US',
      "Authorization" : sessionStorage.getItem('authToken')
    });

    return this.http.get(
      `${env.EMAIL_CHECK_API}/th/productlist/status?mobile=${mobile}`,
      { headers: headers }
    )
  }
}
