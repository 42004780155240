<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer glucernaContainer glucerna-cereal">
  <header class="traceabilityHeader bgPrimary-glucerna-cereal">
    <img
      class="homeLogo"
      routerLink="/glucernaplus-traceability"
      src="../../../assets/images/home_logo_white.png"
    />
    <img
      routerLink="/glucernaplus-traceability"
      src="../../../assets/images/glucerna/logo1.png"
    />
  </header>
  <div class="text-type-70">กลูเซอนา พลัส</div>

  <div class="text-type-32 mt-2">
    เครื่องดื่มผสม โปรตีนนม โปรตีนถั่วเหลือง วิตามินรวม แร่ธาตุรวม และใยอาหาร
    จากข้าวโอ๊ต กลิ่นธัญพืช
  </div>
  <img
    class="p-image mt-4"
    src="../../../assets/images/glucerna/glucerna_packshot.png"
  />
  <img
    class="p-t-image w-100 mt-2"
    src="../../../assets/images/glucerna/glucerna-product-tag-image.png"
  />

  <div class="contentWrapper">
    <div class="heading-icon mt-5 text-center">
      <img src="../../../assets/images/glucerna/icon-low-g.png" />
    </div>
    <div class="text-type-36 mt-2 text-center">ค่าดัชนีน้ำตาลต่ำ</div>
    <div class="heading-icon mt-5 text-center">
      <img src="../../../assets/images/glucerna/icon-3-benefit.png" />
    </div>
    <div class="text-type-36 mt-2 text-center">ประกอบด้วย โปรตีน 3 ชนิด</div>
    <div class="heading-icon mt-5 text-center">
      <img src="../../../assets/images/glucerna/icon-cross-line.png" />
    </div>
    <div class="text-type-36 mt-2 text-center">
      ผสมใยอาหาร 2 ชนิด<br />
      จากข้าวโอ๊ต และ FOS
    </div>
    <div class="heading-icon mt-5 text-center">
      <img src="../../../assets/images/glucerna/icon-omega.png" />
    </div>
    <div class="text-type-36 mt-2 text-center">
      โอเมก้า 3, โอเมก้า 6<br />
      และ โอเมก้า 9
    </div>
    <div class="heading-icon mt-5 text-center">
      <img src="../../../assets/images/glucerna/icon-35.png" />
    </div>
    <div class="text-type-36 mt-2 text-center">
      ประกอบด้วย วิตามิน เกลือแร่ และ ส่วนประกอบอื่นๆ รวม 35 ชนิด
    </div>
  </div>
  <div class="bottom-section">
    <div class="bottom-content">
      <div class="innerDiv">
        <img
          class="w-100"
          src="../../../assets/images/glucerna/glucerna-bottom-logo.png"
        />
        <p class="mt-2">
          ควรกินอาหารหลากหลายครบ 5 หมู่ ในสัดส่วนที่เหมาะสมเป็นประจำ
        </p>
        <div class="d-flex flex-wrap flex-row mt-2">
          <div class="text-type-12 text-left flex-column">
            ใบอนุญาตโฆษณาเลขที่ ฆอ. 1782/2565
          </div>
          <div class="text-type-10 text-right flex-column text-uppercase">
            TH.2022.27291.GLU.1(v1.0)©2022Abbott
          </div>
        </div>
      </div>
    </div>

    <div class="yt-section d-flex align-items-center justify-content-center">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="promo-video-link"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/eO-wuxX7yDo"
          title="ใหม่! กลูเซอนา พลัส กลิ่นธัญพืช หอม อร่อย ไม่เติมน้ำตาลทราย"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</section>
<app-app-glucerna-plus-cereal-nav></app-app-glucerna-plus-cereal-nav>
