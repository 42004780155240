import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensure-product',
  templateUrl: './ensure-product.component.html',
  styleUrls: ['./ensure-product.component.scss']
})
export class EnsureProductComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
  }
  

}
