<section class="globalContainer darkBlueBackground pb-3 _100vh">
    <app-header-family-club></app-header-family-club>
        <form [formGroup]="registerFormMobile" class="px-5 formContainer verticallyCentered" (ngSubmit)="onRegisterFormSubmit()">
            <h5 class="colorWhite mt-3 mb-4">{{ 'REGISTER.TITLE' | translate }}</h5>
            <div class="form-group">
                <!-- mobile number -->
                <img class="fieldImg" src="../../../assets/images/smartphone.png" />
                <input type="number" class="form-control" id="mobileNumber"
                    placeholder="{{ 'REGISTER.PLACEHOLDER3' | translate }}" formControlName="mobileNumber"
                    [ngClass]="{'is-invalid': submitted && regFormVal.mobileNumber.errors}">
                <div *ngIf="submitted && regFormVal.mobileNumber.errors" class="invalid-feedback">
                    <div *ngIf="regFormVal.mobileNumber.errors.required">Enter mobilenumber</div>
                    <div class="text-left"
                        *ngIf="regFormVal.mobileNumber.errors.pattern ||  regFormVal.mobileNumber.errors.pattern">
                        Invalid Mobile Number
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-block goldenBtn mt30 mb25">{{ 'REGISTER.MOBILE_REGISTER_BTN' |
                translate }}</button>
        </form>
</section>