import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dimensions, ImageCroppedEvent, ImageTransform,base64ToFile } from 'ngx-image-cropper';
import { first } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import {environment as env } from '../../../environments/environment'
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import 'moment/locale/es'
import { BugsnagService } from 'src/app/services/bugsnag.service';
moment.locale('th')
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  uploadForm: FormGroup; 
  editProfileForm: FormGroup;
  submitted =  false
  visible = false;
  customerData: any;
  getMobile: any;
  latitude:any;
  langitude:any;
  uniqueId:string;
  fileupload:any;
  capturedImage:any
  finalUserImage:any;
  profileImage:any;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  fileInput:any;
  totalAvailablePoints:any;
  expiryDate: any;
  curr_date = new Date();
  year : any;
  @ViewChild('cancelMembershipModal', { static: true }) cancelMembershipModal: ModalDirective;

  constructor(
    private apiService: ApiService, 
    private formBuilder: FormBuilder,
    private router: Router, 
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
    ) { }

    ngOnInit(): void {
      this.editProfileForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        completeAddress: ['']
      })
      this.getCustomer();
      this.getAllPoints();
    }

    imageCropped(event: ImageCroppedEvent) {
      console.log(event, 'cropped')
     this.croppedImage = event.base64;
     this.fileInput = base64ToFile(this.croppedImage);

    }
    fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
      console.log(this.imageChangedEvent, 'this.imageChangedEvent')
      console.log(event.target.files.length, 'event.target.files.length--')
      if (event.target.files.length > 0) {
        this.visible = true;
        console.log(this.visible, 'inside if')
        this.fileupload =event.target.files[0]
      }
    }

    count = 0;
    uploadImage(){
      this.count ++;
      this.visible = false
      let getMobile = sessionStorage.getItem('mobileNo')
      let getAuthKey = sessionStorage.getItem('authToken')
 
      const imageData = new FormData();
      imageData.append('image', this.fileInput)
      imageData.append('mobile',getMobile)
      imageData.append('cap_device_id',env.DEVICE_ID)
 
       this.spinner.show();
       this.apiService.customerImageUpload(imageData, getAuthKey).pipe(first())
       .subscribe((res:any)=>{
         this.spinner.hide();
         console.log(res, 'image upload')
         let uniqueId = res.result.image_id
         let extension = res.result.image_extension
         this.apiService.getSavedImage(getMobile,getAuthKey,uniqueId,extension).pipe(first())
         .subscribe((res:any)=>{
           console.log(res.file_path, 'image get')
           this.finalUserImage = res.file_path;
          this.visible = false;
          console.log(this.visible, 'visible result in final upload fn.' )
         //  this.getCustomer();
         })
       },err => {
         if(err){
           this.spinner.hide();
         }
         this.bsService.notifyError(err);
       })
    }

    imageLoaded() {
      console.log(this.count, 'this.count img load')
      if(this.count != 1){
        this.showCropper = true;
        this.visible = true
        console.log('Image loaded');
      }
    }

    cropperReady(sourceImageDimensions: Dimensions) {
      console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
      console.log('Load failed');
    }
    show() {
      this.visible = true
    }

    getCustomer(){
      this.spinner.show()
      let getMobile = sessionStorage.getItem('mobileNo')
      let getAuthKey = sessionStorage.getItem('authToken')
      this.apiService.getCapCustomer(getMobile, getAuthKey).pipe(first())
            .subscribe(res=>{
              this.spinner.hide()
              if(res['status'].code == 200){
                this.customerData = res['customers']['customer'][0]               
                let profilePic = res['customers']['customer'][0].custom_fields.field;
                let image = profilePic.filter(res=> res.name == 'profileimage');
                this.profileImage = image[0]?.value;
              
                console.log(this.profileImage, 'image');
                this.spinner.show();
                this.apiService.getSavedImageFinal(getMobile,getAuthKey,this.profileImage).pipe(first())
                .subscribe((res:any)=>{
                  this.spinner.hide();
                  this.finalUserImage = res.file_path;
                  console.log(this.finalUserImage, 'image get')
                 this.visible = false;
                }, err => {
                  this.bsService.notifyError(err);
                })

              }
              else if(res['status'].code == 401){
                this.apiService.logout()
              }
              else{
                 this.apiService.showToaster(res['status'].message)
              }
            },err=>{
              this.spinner.hide()
              console.log(err, '=== get customer error =======')
              this.bsService.notifyError(err);
            })
    }

  onClickCancelMembershipModal() {
    this.cancelMembershipModal.show();
  }
  closeCancelMembershipModal() {
    this.cancelMembershipModal.hide();
  }

  getAllPoints(){
    this.year = this.curr_date.getFullYear();
    let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
    let mobile = sessionStorage.getItem('mobileNo')
    let token = sessionStorage.getItem('authToken')
    this.spinner.show();
    this.apiService.getPointsList(mobile,token,startOfMonth,endOfMonth).pipe(first()).subscribe((res:any) => {
      this.spinner.hide();
      this.spinner.hide();
      console.log(res?.customer?.transactions?.transaction, 'points list')
      if( res?.expiry_schedule[0]?.schedule?.length >= 0){
      this.totalAvailablePoints = res?.expiry_schedule[0]?.schedule[0]?.points;
      this.expiryDate = res?.expiry_schedule[0]?.schedule[0]?.expiry_date
      console.log( this.expiryDate, ' this.expiryDate')
      this.expiryDate = moment(this.expiryDate).format('DD MMM yyyy')
      }
    }, err => {
      this.bsService.notifyError(err);
    })
  }

}
