<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer ensure-cereal">
  <!-- <app-ensure-header class="traceabilityHomeheader"></app-ensure-header> -->
  <header class="traceabilityHeader bgEnsureBlue">
    <img
      class="img-fluid logo"
      src="../../../../assets/images/ensure-header-logo.webp"
    />
  </header>
  <div class="homeContainer">
    <h1>ผลิตภัณฑ์เอนชัวร์</h1>
    <div class="imgBlock _gutter1">
      <figure class="_210px">
        <!-- <img
          *ngIf="isIsprint == false"
          class="gpasImg"
          src="../../../assets/images/glucerna/ensure-cereal-gpass-img.png"
          alt="ensure"
        /> -->
        <img
          style="width: 100%"
          *ngIf="isIsprint == false"
          class="gpasImg"
          [src]="prodImage"
          alt="glucerna"
        />
        <img
          *ngIf="isIsprint == true"
          class=""
          [src]="productImage"
          alt="ensure"
        />
      </figure>
      <div class="prdDetails pt-30">
        <span>{{ productName }}</span
        ><br /><strong
          >{{ "TRACEABILITY_HOME.LOT_NUMBER" | translate }} :
          {{ lotNumber }}</strong
        >
      </div>
      <i class="rightArrow"
        ><img
          src="../../../../assets/images/glucerna/arrowleft.svg"
          alt="right arrow"
      /></i>
    </div>
    <div class="imgBlock _gutter2">
      <figure class="_255px">
        <img
          src="../../../../assets/images/ensure-manufactuirng.png"
          alt="home"
        />
      </figure>
      <div class="prdDetails">
        <p>
          <label
            >{{ "TRACEABILITY_HOME.COUNTRY_OF_ORIGIN" | translate }} :
          </label>
          <span> {{ manufactCountry }}</span>
        </p>
        <p>
          <label>{{ "TRACEABILITY_HOME.PRODUCTION_DATE" | translate }} </label>
          <span> {{ manufacturedDate }}</span>
        </p>
      </div>
      <i class="leftArrow"
        ><img
          src="../../../../assets/images/glucerna/arrowright.svg"
          alt="left arrow"
      /></i>
    </div>
    <div class="imgBlock _mt600px">
      <figure class="_158px">
        <img
          src="../../../../assets/images/ensure-qualitycontol.png"
          alt="search"
        />
      </figure>
      <div class="prdDetails">
        <span>{{ "TRACEABILITY_HOME.EXPIRATION_DATE" | translate }} </span
        ><br />
        <span>{{ expiryDate }}</span>
      </div>
    </div>
    <div class="triplePlusBlock">
      <p>รู้จัก</p>
      <h1>เอนชัวร์</h1>
      <a [routerLink]="['/ensureh-product']" class="tracePrimaryBtn"
        ><span> คลิกเลย! </span></a
      >
    </div>
  </div>
</section>
<app-ensure-cereal-nav></app-ensure-cereal-nav>
