import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  activePath: any;
  showActiveProfileImg = false;
  showActiveHomeImg = false;
  showActiveMoreImg = false;
  constructor(private route: ActivatedRoute) { 
    this.activePath = this.route.snapshot.routeConfig.path;
   }

  ngOnInit(): void {
    if(this.activePath === 'profile') {
      this.showActiveProfileImg = true;
    }
    if(this.activePath === 'home') {
      this.showActiveHomeImg = true;
    }
    if (this.activePath === 'more') {
      this.showActiveMoreImg = true;
    }
  }

}
