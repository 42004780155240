<div class="ensure-container">
    <app-ensure-family-club></app-ensure-family-club>  
    <div class="ensure-logo-section">
      <img
        class="img-fluid"
        src="../../../../assets/images/ensure-aw/vector-smart-object.png"
        alt="Ensure brand"
      />
      <a
        ><img
          class="img-fluid ensure-home-icon"
          src="../../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
          alt="Ensure Home" routerLink="/ensureplantbased-home"
      /></a>
    </div>
  
    <div class="prepare-section">
      <p class="fn-shadow mb-5">
        วิธีเตรียม <br />
        <img
          src="../../../../assets/images/ensure-aw/mix1.png"
          alt="how to prepare"
        />
        <br />
      </p>  
      <div class="product-card">
        <img
          class="img-fluid mb-3"
          src="../../../../assets/images/ensure-aw/mix2.png"
        />
        <p>เตรียมน้ำอุ่นหรือเย็น<br /> 
          ที่สะอาด 187 มิลลิลิตร</p>
        <img
          class="img-fluid"
          src="../../../../assets/images/ensure-aw/mix3.png"
        />
        <p>
          ผสมผง เอนชัวร์ โกลด์ <br/>
          ทีละช้อนตวงปาด แล้วคน 
          ให้เข้ากันจนละลายหมด 
          ครบ 6 ช้อน (60.6 กรัม)
        </p>
        <img
          class="img-fluid"
          src="../../../../assets/images/ensure-aw/mix4.png"
        />
        <p class="br-none">จะได้ส่วนผสม <br />
          230 มิลลิลิตร</p>
      </div>
  
      <div class="how-to-use">
        <a href="javascript:;">การเก็บรักษา</a>
        <ul>
          <li>
            เมื่อเปิดกระป๋องแล้วควรปิดกระป๋องให้แน่นทุกครั้งหลังใช้ 
            เก็บไว้ในที่เย็นและแห้ง (ไม่ควรเก็บไว้ในตู้เย็น) และควรใช้ให้ 
           หมดภายใน 3 สัปดาห์ 
         
          </li>
          <li>
            เมื่อผสมแล้วควรดื่มทันที หากดื่มไม่หมด ให้ปิดฝา 
            และเก็บในตู้เย็น ดื่มให้หมดภายใน 24 ชั่วโมง
          </li>
        </ul>
  
        <p class="fn-10 mb-0 text-uppercase">TH.2022.27390.ENS.1(v1.0)©2022Abbott</p>
      </div>
    </div>
  </div>
  <app-ensure-aw-menu></app-ensure-aw-menu>
  