import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';
@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss'],
})
export class EditPasswordComponent implements OnInit {
  editPasswordForm: FormGroup;
  forgotOtpForm: FormGroup;
  submittedOtpForm = false;
  submitted = false;
  sessionId: any;
  otp: string;
  activeClassButton: boolean = false;
  invalidOtp: boolean = false;
  editPasswordValues: any;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('forgotOtpModal', { static: true }) forgotOtpModal: ModalDirective;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: false,
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: true,
  };
  showResendOtp: boolean = false;
  timeLeft: any;
  beginTimer: boolean = false;
  userPassword: any;
  passworderror: boolean = false;
  oldPassworderror: boolean = false;
  storedMobNum: any;
  phone_no: any;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
  ) {
    this.storedMobNum = sessionStorage.getItem('storedMobNum');
  }

  ngOnInit(): void {
    this.userPassword = sessionStorage.getItem('userPassword');
    this.editPasswordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        resetPassword: ['', Validators.required],
        confirmNewPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('resetPassword', 'confirmNewPassword'),
      }
    );

    this.forgotOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
  }

  get editPassFormVal() {
    return this.editPasswordForm.controls;
  }

  get forgotOtpFormVal() {
    return this.forgotOtpForm.controls;
  }

  onOtpChange(otp) {
    this.invalidOtp = false;
    this.otp = otp;
    if (otp.length == 6) {
      this.activeClassButton = false;
    } else {
      this.activeClassButton = true;
    }
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  onSubmitEditPasswordForm() {
    this.submitted = true;
    if (this.editPasswordForm.invalid) {
      return;
    } else {
      this.editPasswordValues = this.editPasswordForm.value;
      if (this.userPassword != this.editPasswordValues.password) {
        this.passworderror = true;
        console.log('inside');
        if (
          this.userPassword == this.editPasswordValues.resetPassword &&
          this.userPassword == this.editPasswordValues.confirmNewPassword
        ) {
          this.oldPassworderror = true;
          return;
        } else {
          this.oldPassworderror = false;
        }
        return;
      } else {
        this.passworderror = false;
      }

      if (
        this.userPassword == this.editPasswordValues.resetPassword &&
        this.userPassword == this.editPasswordValues.confirmNewPassword
      ) {
        this.oldPassworderror = true;
        if (this.userPassword != this.editPasswordValues.password) {
          this.passworderror = true;
          console.log('inside');
          return;
        } else {
          this.passworderror = false;
        }
        return;
      } else {
        this.oldPassworderror = false;
      }

      console.log('outside');
      let getMobile = sessionStorage.getItem('mobileNo');
      let getAuthKey = sessionStorage.getItem('authToken');
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: getMobile,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: 'test123',
        confirmPassword: 'test123',
      };
      
      console.log(getMobile, 'mobile taken from localstorage---');
      this.spinner.show();
      this.apiService
        .tokenGenerate(formData)
        .pipe(first()) //token generate api
        .subscribe((res) => {
          
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.sessionId = res['user'].sessionId;
            sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));

            //----------forgot password---------
            let formData = {
              identifierType: 'MOBILE',
              identifierValue: getMobile,
              deviceId: env.DEVICE_ID,
              brand: env.BRAND,
              sessionId: this.sessionId,
              password: 'test123',
              confirmPassword: 'test123',
            };
            this.apiService
              .forgotPassword(formData)
              .pipe(first()) // forgot password api
              .subscribe(
                (res) => {
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    let otpGenerateData = {
                      identifierType: 'MOBILE',
                      identifierValue: getMobile,
                      deviceId: env.DEVICE_ID,
                      brand: env.BRAND,
                      sessionId: this.sessionId,
                    };
                    
                    this.apiService
                      .otpGenerate(otpGenerateData)
                      .pipe(first()) // otp generate api
                      .subscribe(
                        (res) => {
                          
                          this.spinner.hide();
                          if (res['status'].code == 200) {
                            // this.forgotModalOne.hide();
                            this.phone_no = getMobile;
                            this.showOtpModal();
                          } else {
                            // otp genearte else
                            //this.toastr.error(res['status'].message)
                          }
                        },
                        (err) => {
                          // otp genearte error
                          this.spinner.hide();
                          console.log(
                            err,
                            '=== OTP gererate error list ======='
                          );
                          this.bsService.notifyError(err, formData);
                        }
                      );
                  } else {
                    // forgot passsword else
                    console.log(res['status'].message);
                  }
                },
                (err) => {
                  //forgot passsword error
                  this.spinner.hide();
                  console.log(err);
                  this.bsService.notifyError(err, formData);
                }
              );
          }
        }, err => {
          this.bsService.notifyError(err, formData);
        });
    }
  }

  onSubmitForgotOtpForm() {
    let getMobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.submittedOtpForm = true;
    console.log(this.activeClassButton, 'this.activeClassButton');
    if (this.otp == undefined || this.otp.length < 6) {
      console.log('in if--');
      return;
    } else {
      console.log('inside else---');
      console.log(this.activeClassButton, 'activebtn');
      let otpSubmitData = {
        identifierType: 'MOBILE',
        identifierValue: getMobile,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: this.sessionId,
        otp: this.otp,
      };
     
      this.spinner.show();
      this.apiService
        .otpValidate(otpSubmitData)
        .pipe(first())
        .subscribe(
          (res) => {
            
            this.spinner.hide();
            if (res['status'].code == 200) {
              sessionStorage.setItem('authToken', res['auth'].token);
              sessionStorage.setItem('authKey', res['auth'].key);
              // this.forgotOtpModal.hide();

              // this.ngOtpInputRef.setValue('');
              // this.forgotOtpForm.reset();

              let formValues = this.editPasswordValues;
              this.spinner.show();
              let formData = {
                identifierType: 'MOBILE',
                identifierValue: getMobile,
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                password: 'test123',
                newPassword: formValues.resetPassword,
                confirmPassword: formValues.confirmNewPassword,
                token: token,
              };
              console.log(formValues, 'reset passwd form');
              this.spinner.show();
              this.apiService
                .changePassword(formData)
                .pipe(first())
                .subscribe(
                  (res) => {
                    this.spinner.hide();
                    if (res['status'].code == 200) {
                      sessionStorage.setItem(
                        'userPassword',
                        this.editPasswordValues.confirmNewPassword
                      );
                      this.forgotOtpModal.hide();
                      this.ngOtpInputRef.setValue('');
                      this.forgotOtpForm.reset();
                      this.router.navigateByUrl('/edit-profile');
                    } else {
                      console.log(res['status'].message);
                    }
                  },
                  (err) => {
                    this.spinner.hide();
                    console.log(err);
                    this.bsService.notifyError(err, formData);
                  }
                );
            } else {
              this.invalidOtp = true;
            }
          },
          (err) => {
            console.log(err);
            this.bsService.notifyError(err, otpSubmitData);
          }
        );
    }
  }

  closeForgotOtpModal() {
    this.submittedOtpForm = false;
    this.invalidOtp = false;
    this.activeClassButton = false;
    this.ngOtpInputRef.setValue('');
    this.forgotOtpModal.hide();
    this.beginTimer = false;
  }

  handleEvent(event) {
    console.log(event.left, 'event');
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

  resendOTP() {
    this.spinner.show();
    this.showResendOtp = false;
    let getMobile = sessionStorage.getItem('mobileNo');
    let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    //this.tokenReGenerate();
    let otpGenerateData = {
      mobile: JSON.stringify(getMobile),
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      sessionId: sessionIdValue,
    };
    
    this.apiService
      .otpGenerate(otpGenerateData)
      .pipe(first())
      .subscribe(
        (res) => {
          
          this.spinner.hide();
          if (res['status'].code == 200) {
          } else if (res['status'].code == 401) {
            this.apiService.logout();
          } else {
            this.apiService.showToaster(res['status'].message);
          }
        },
        (err) => {
          this.spinner.hide();
          console.log(err, '=== OTP gererate error list =======');
          this.bsService.notifyError(err, otpGenerateData);
        }
      );
  }

  showOtpModal() {
    this.forgotOtpModal.show();
    this.beginTimer = true;
  }
}
