<div class="ensure-container">
  <app-ensure-family-club></app-ensure-family-club>
  <!-- <div class="ensure-family-club">
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/Ensure-gold-family-club.png"
        alt="My Family Club"
      />
    </div> -->

  <div class="ensure-logo-section">
    <img
      class="img-fluid"
      src="../../../assets/images/ensure-gold/ensure-logo.webp"
      alt="Ensure brand"
    />
    <a
      ><img
        class="img-fluid ensure-home-icon"
        src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
        alt="Ensure Home" routerLink="/ensureh-traceability"
    /></a>
  </div>

  <img
    class="img-fluid mt-30 px-3"
    src="../../../assets/images/ensureh/ensureh-qc-img.png"
  />

  <div class="content-section">
    <div class="prod-details">วันหมดอายุ : {{expiryDate}}</div>
    <p class="mt-3 txt-sm ">
      สำหรับแอ๊บบอต การควบคุมคุณภาพในกระบวนการผลิตถือเป็น สิ่งสำคัญที่สุด
      เพื่อให้มั่นใจได้ว่าบริษัทฯ จะสามารถผลิตสินค้าที่มี
      คุณภาพและมีความปลอดภัยอยู่เสมอ
    </p>

    <img
      class="img-fluid"
      src="../../../assets/images/ensureh/ensureh-qc-img2.png"
    />
    <p class="mt-3 txt-sm">
      ตั้งแต่ขั้นตอนการรับวัตถุดิบสำหรับใช้ในการผลิตสินค้า จนกระทั่ง
      การผลิตสินค้าออกจากโรงงาน ในทุกขั้นตอนของการผลิตได้ผ่าน
      การตรวจสอบอย่างเข้มงวดโดยผู้เชี่ยวชาญด้านการประกันคุณ ภาพ
      ที่ผ่านการฝึกอบรม พร้อมด้วยการใช้อุปกรณ์การตรวจสอบ ที่ทันสมัย
    </p>
  </div>
  <p class="terms-of-use text-uppercase">TH.2022.26826.PDS.1(v1.0)©2022Abbott</p>
</div>
<app-ensure-cereal-nav></app-ensure-cereal-nav>
