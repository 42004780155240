import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensure-quality-control',
  templateUrl: './ensure-quality-control.component.html',
  styleUrls: ['./ensure-quality-control.component.scss'],
})
export class EnsureQualityControlComponent implements OnInit {
  productData: any;
  content: any;
  expiryDate: any;
  constructor() {
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
    this.getTracebilityContent();
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  getTracebilityContent() {
    this.productData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.expiryDate = this.productData.expiry_date;
  }
}
