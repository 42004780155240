import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-glucerna-manufacturar',
  templateUrl: './glucerna-manufacturar.component.html',
  styleUrls: ['./glucerna-manufacturar.component.scss'],
})
export class GlucernaManufacturarComponent implements OnInit {
  productData: any;
  content: any;
  manufactCountry: any;
  expiryDate: any;
  manufacturedDate: any;
  origin: any;
  constructor() {
    window.scrollTo({ top: 0 });
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  ngOnInit(): void {
    this.getTracebilityContent();
  }

  getTracebilityContent() {
    this.productData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.manufacturedDate = this.productData.manufacture_date;
    if (this.productData.is_isprint == true) {
      let parsedManufactCountry = this.parseFunction(
        this.productData.country_of_origin
      );
      this.manufactCountry = parsedManufactCountry.th_TH;
    } else {
      this.manufactCountry = this.productData.origin;
      // this.origin = this.productData.origin;
    }
    // this.content = this.productData.product.txt;
    // this.content.forEach((item: any) => {
    //   if (item.orders == 3) {
    //     let parsedExpiryDate = this.parseFunction(item.value);
    //     this.expiryDate = parsedExpiryDate.value;
    //   }
    //   if (item.orders == 4) {
    //     let parsedDate = this.parseFunction(item.value);
    //     this.manufacturedDate = parsedDate.value;
    //   }
    //   if (item.orders == 6) {
    //     let country_of_origin = this.parseFunction(item.value);
    //     this.manufactCountry = country_of_origin.th_TH;
    //   }
    // });
  }
}
