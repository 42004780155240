import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';
@Component({
  selector: 'app-glucernaplus-manufacturing',
  templateUrl: './glucernaplus-manufacturing.component.html',
  styleUrls: ['./glucernaplus-manufacturing.component.scss'],
})
export class GlucernaplusManufacturingComponent implements OnInit {
  productData: any;
  manufactCountry: any;
  manufacturedDate: any;
  constructor(
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
    this.getTracebilityContent();
  }
  parseFunction(value) {
    return JSON.parse(value);
  }

  getTracebilityContent() {
    this.productData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.manufacturedDate = this.productData.manufacture_date;

    if (this.productData.is_isprint == true) {
      let parsedManufactCountry = this.parseFunction(
        this.productData.country_of_origin
      );
      this.manufactCountry = parsedManufactCountry.th_TH;
    } else {
      this.manufactCountry = this.productData.origin;
    }
  }
}
