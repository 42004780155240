import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryListService {
  jsondata:any;
  jsondataNew: any;
  jsondata01: any;
  constructor() {
  this.jsondata01 = [
    {
      "กระบี่":{
      "sort_order":"1",
      "code":"81",
      "amphoe":[
      {
      "districts":[
      {
      "code":"810402",
      "display":"คลองท่อมเหนือ"
      },
      {
      "code":"810401",
      "display":"คลองท่อมใต้"
      },
      {
      "code":"810403",
      "display":"คลองพน"
      },
      {
      "code":"810404",
      "display":"ทรายขาว"
      },
      {
      "code":"810406",
      "display":"พรุดินนา"
      },
      {
      "code":"810405",
      "display":"ห้วยน้ำขาว"
      },
      {
      "code":"810407",
      "display":"เพหลา"
      }
      ],
      "code":"8104",
      "display":"คลองท่อม"
      },
      {
      "districts":[
      {
      "code":"810604",
      "display":"คีรีวง"
      },
      {
      "code":"810599",
      "display":"ปลายพระยา"
      },
      {
      "code":"810598",
      "display":"เขาต่อ"
      },
      {
      "code":"810597",
      "display":"เขาเขน"
      }
      ],
      "code":"8106",
      "display":"ปลายพระยา"
      },
      {
      "districts":[
      {
      "code":"810702",
      "display":"ดินอุดม"
      },
      {
      "code":"810704",
      "display":"ดินแดง"
      },
      {
      "code":"810703",
      "display":"ทุ่งไทรทอง"
      },
      {
      "code":"810499",
      "display":"ลำทับ"
      }
      ],
      "code":"8107",
      "display":"ลำทับ"
      },
      {
      "districts":[
      {
      "code":"810508",
      "display":"คลองยา"
      },
      {
      "code":"810504",
      "display":"คลองหิน"
      },
      {
      "code":"810503",
      "display":"นาเหนือ"
      },
      {
      "code":"810509",
      "display":"บ้านกลาง"
      },
      {
      "code":"810505",
      "display":"อ่าวลึกน้อย"
      },
      {
      "code":"810506",
      "display":"อ่าวลึกเหนือ"
      },
      {
      "code":"810501",
      "display":"อ่าวลึกใต้"
      },
      {
      "code":"810507",
      "display":"เขาใหญ่"
      },
      {
      "code":"810502",
      "display":"แหลมสัก"
      }
      ],
      "code":"8105",
      "display":"อ่าวลึก"
      },
      {
      "districts":[
      {
      "code":"810304",
      "display":"คลองยาง"
      },
      {
      "code":"810305",
      "display":"ศาลาด่าน"
      },
      {
      "code":"810303",
      "display":"เกาะกลาง"
      },
      {
      "code":"810302",
      "display":"เกาะลันตาน้อย"
      },
      {
      "code":"810301",
      "display":"เกาะลันตาใหญ่"
      }
      ],
      "code":"8103",
      "display":"เกาะลันตา"
      },
      {
      "districts":[
      {
      "code":"810204",
      "display":"พรุเตียว"
      },
      {
      "code":"810203",
      "display":"สินปุน"
      },
      {
      "code":"810205",
      "display":"หน้าเขา"
      },
      {
      "code":"810202",
      "display":"เขาดิน"
      },
      {
      "code":"810201",
      "display":"เขาพนม"
      },
      {
      "code":"810206",
      "display":"โคกหาร"
      }
      ],
      "code":"8102",
      "display":"เขาพนม"
      },
      {
      "districts":[
      {
      "code":"810103",
      "display":"กระบี่น้อย"
      },
      {
      "code":"810102",
      "display":"กระบี่ใหญ่"
      },
      {
      "code":"810118",
      "display":"คลองประสงค์"
      },
      {
      "code":"810111",
      "display":"ทับปริก"
      },
      {
      "code":"810101",
      "display":"ปากน้ำ"
      },
      {
      "code":"810117",
      "display":"หนองทะเล"
      },
      {
      "code":"810116",
      "display":"อ่าวนาง"
      },
      {
      "code":"810105",
      "display":"เขาคราม"
      },
      {
      "code":"810106",
      "display":"เขาทอง"
      },
      {
      "code":"810115",
      "display":"ไสไทย"
      }
      ],
      "code":"8101",
      "display":"เมืองกระบี่"
      },
      {
      "districts":[
      {
      "code":"810107",
      "display":"คลองขนาน"
      },
      {
      "code":"810108",
      "display":"คลองเขม้า"
      },
      {
      "code":"810110",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"810112",
      "display":"ปกาสัย"
      },
      {
      "code":"810113",
      "display":"ห้วยยูง"
      },
      {
      "code":"810104",
      "display":"เกาะศรีบอยา"
      },
      {
      "code":"810114",
      "display":"เหนือคลอง"
      },
      {
      "code":"810109",
      "display":"โคกยาง"
      }
      ],
      "code":"8108",
      "display":"เหนือคลอง"
      }
      ],
      "display":"กระบี่"
      },
      "กรุงเทพมหานคร":{
      "sort_order":"2",
      "code":"10",
      "amphoe":[
      {
      "districts":[
      {
      "code":"101804",
      "display":"คลองต้นไทร"
      },
      {
      "code":"101599",
      "display":"คลองสาน"
      },
      {
      "code":"101803",
      "display":"บางลำภูล่าง"
      },
      {
      "code":"101801",
      "display":"สมเด็จเจ้าพระยา"
      }
      ],
      "code":"1018",
      "display":"คลองสาน"
      },
      {
      "districts":[
      {
      "code":"101004",
      "display":"ทรายกองดิน"
      },
      {
      "code":"101005",
      "display":"ทรายกองดินใต้"
      },
      {
      "code":"101003",
      "display":"บางชัน"
      },
      {
      "code":"101007",
      "display":"สามวาตะวันตก"
      },
      {
      "code":"101006",
      "display":"สามวาตะวันออก"
      }
      ],
      "code":"1046",
      "display":"คลองสามวา"
      },
      {
      "districts":[
      {
      "code":"100902",
      "display":"คลองตัน"
      },
      {
      "code":"100901",
      "display":"คลองเตย"
      },
      {
      "code":"100903",
      "display":"พระโขนง"
      },
      {
      "code":"100903",
      "display":"พระโขนง"
      }
      ],
      "code":"1033",
      "display":"คลองเตย"
      },
      {
      "districts":[
      {
      "code":"100606",
      "display":"คันนายาว"
      },
      {
      "code":"FALSE",
      "display":"รามอินทรา"
      }
      ],
      "code":"1043",
      "display":"คันนายาว"
      },
      {
      "districts":[
      {
      "code":"103005",
      "display":"จตุจักร"
      },
      {
      "code":"103004",
      "display":"จอมพล"
      },
      {
      "code":"103003",
      "display":"จันทรเกษม"
      },
      {
      "code":"100501",
      "display":"ลาดยาว"
      },
      {
      "code":"103002",
      "display":"เสนานิคม"
      }
      ],
      "code":"1030",
      "display":"จตุจักร"
      },
      {
      "districts":[
      {
      "code":"102102",
      "display":"จอมทอง"
      },
      {
      "code":"102103",
      "display":"บางขุนเทียน"
      },
      {
      "code":"102101",
      "display":"บางค้อ"
      },
      {
      "code":"102106",
      "display":"บางมด"
      }
      ],
      "code":"1035",
      "display":"จอมทอง"
      },
      {
      "districts":[
      {
      "code":"FALSE",
      "display":"ดอนเมือง"
      },
      {
      "code":"FALSE",
      "display":"สนามบิน"
      },
      {
      "code":"100505",
      "display":"สีกัน"
      }
      ],
      "code":"1036",
      "display":"ดอนเมือง"
      },
      {
      "districts":[
      {
      "code":"101703",
      "display":"ดินแดง"
      }
      ],
      "code":"1026",
      "display":"ดินแดง"
      },
      {
      "districts":[
      {
      "code":"100201",
      "display":"ดุสิต"
      },
      {
      "code":"100206",
      "display":"ถนนนครไชยศรี"
      },
      {
      "code":"100202",
      "display":"วชิรพยาบาล"
      },
      {
      "code":"100203",
      "display":"สวนจิตรลดา"
      },
      {
      "code":"100203",
      "display":"สวนจิตรลดา"
      },
      {
      "code":"100204",
      "display":"สี่แยกมหานาค"
      }
      ],
      "code":"1002",
      "display":"ดุสิต"
      },
      {
      "districts":[
      {
      "code":"101901",
      "display":"คลองชักพระ"
      },
      {
      "code":"101903",
      "display":"ฉิมพลี"
      },
      {
      "code":"101902",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"101904",
      "display":"บางพรม"
      },
      {
      "code":"101905",
      "display":"บางระมาด"
      },
      {
      "code":"101907",
      "display":"บางเชือกหนัง"
      }
      ],
      "code":"1019",
      "display":"ตลิ่งชัน"
      },
      {
      "districts":[
      {
      "code":"101906",
      "display":"ทวีวัฒนา"
      },
      {
      "code":"101908",
      "display":"ศาลาธรรมสพน์"
      }
      ],
      "code":"1048",
      "display":"ทวีวัฒนา"
      },
      {
      "districts":[
      {
      "code":"102404",
      "display":"ทุ่งครุ"
      },
      {
      "code":"102106",
      "display":"บางมด"
      }
      ],
      "code":"1049",
      "display":"ทุ่งครุ"
      },
      {
      "districts":[
      {
      "code":"101506",
      "display":"ดาวคะนอง"
      },
      {
      "code":"101505",
      "display":"ตลาดพลู"
      },
      {
      "code":"101503",
      "display":"บางยี่เรือ"
      },
      {
      "code":"101504",
      "display":"บุคคโล"
      },
      {
      "code":"101501",
      "display":"วัดกัลยาณ์"
      },
      {
      "code":"101507",
      "display":"สำเหร่"
      },
      {
      "code":"101502",
      "display":"หิรัญรูจี"
      }
      ],
      "code":"1015",
      "display":"ธนบุรี"
      },
      {
      "districts":[
      {
      "code":"102006",
      "display":"บางขุนนนท์"
      },
      {
      "code":"102007",
      "display":"บางขุนศรี"
      },
      {
      "code":"102005",
      "display":"บ้านช่างหล่อ"
      },
      {
      "code":"102004",
      "display":"ศิริราช"
      },
      {
      "code":"FALSE",
      "display":"อรุณอัมรินทร์"
      }
      ],
      "code":"1020",
      "display":"บางกอกน้อย"
      },
      {
      "districts":[
      {
      "code":"101602",
      "display":"วัดท่าพระ"
      },
      {
      "code":"101601",
      "display":"วัดอรุณ"
      }
      ],
      "code":"1016",
      "display":"บางกอกใหญ่"
      },
      {
      "districts":[
      {
      "code":"100601",
      "display":"คลองจั่น"
      },
      {
      "code":"100608",
      "display":"หัวหมาก"
      },
      {
      "code":"100608",
      "display":"หัวหมาก"
      }
      ],
      "code":"1006",
      "display":"บางกะปิ"
      },
      {
      "districts":[
      {
      "code":"102105",
      "display":"ท่าข้าม"
      },
      {
      "code":"102107",
      "display":"แสมดำ"
      }
      ],
      "code":"1021",
      "display":"บางขุนเทียน"
      },
      {
      "districts":[
      {
      "code":"101207",
      "display":"บางคอแหลม"
      },
      {
      "code":"101206",
      "display":"บางโคล่"
      },
      {
      "code":"101205",
      "display":"วัดพระยาไกร"
      }
      ],
      "code":"1031",
      "display":"บางคอแหลม"
      },
      {
      "districts":[
      {
      "code":"100205",
      "display":"บางซื่อ"
      },
      {
      "code":"FALSE",
      "display":"วงศ์สว่าง"
      }
      ],
      "code":"1029",
      "display":"บางซื่อ"
      },
      {
      "districts":[
      {
      "code":"100904",
      "display":"บางนา"
      }
      ],
      "code":"1047",
      "display":"บางนา"
      },
      {
      "districts":[
      {
      "code":"102104",
      "display":"บางบอน"
      }
      ],
      "code":"1050",
      "display":"บางบอน"
      },
      {
      "districts":[
      {
      "code":"102002",
      "display":"บางบำหรุ"
      },
      {
      "code":"102001",
      "display":"บางพลัด"
      },
      {
      "code":"102008",
      "display":"บางยี่ขัน"
      },
      {
      "code":"102003",
      "display":"บางอ้อ"
      }
      ],
      "code":"1025",
      "display":"บางพลัด"
      },
      {
      "districts":[
      {
      "code":"100404",
      "display":"บางรัก"
      },
      {
      "code":"100401",
      "display":"มหาพฤฒาราม"
      },
      {
      "code":"100402",
      "display":"สีลม"
      },
      {
      "code":"100405",
      "display":"สี่พระยา"
      },
      {
      "code":"100403",
      "display":"สุริยวงศ์"
      }
      ],
      "code":"1004",
      "display":"บางรัก"
      },
      {
      "districts":[
      {
      "code":"100508",
      "display":"ท่าแร้ง"
      },
      {
      "code":"100508",
      "display":"ท่าแร้ง"
      },
      {
      "code":"100502",
      "display":"อนุสาวรีย์"
      }
      ],
      "code":"1005",
      "display":"บางเขน"
      },
      {
      "districts":[
      {
      "code":"102203",
      "display":"บางแค"
      },
      {
      "code":"102204",
      "display":"บางแคเหนือ"
      },
      {
      "code":"102205",
      "display":"บางไผ่"
      },
      {
      "code":"102301",
      "display":"หลักสอง"
      }
      ],
      "code":"1040",
      "display":"บางแค"
      },
      {
      "districts":[
      {
      "code":"100604",
      "display":"คลองกุ่ม"
      },
      {
      "code":"100604",
      "display":"คลองกุ่ม"
      },
      {
      "code":"FALSE",
      "display":"นวมินทร์"
      },
      {
      "code":"FALSE",
      "display":"นวลจันทร์"
      }
      ],
      "code":"1027",
      "display":"บึงกุ่ม"
      },
      {
      "districts":[
      {
      "code":"100703",
      "display":"ปทุมวัน"
      },
      {
      "code":"100701",
      "display":"รองเมือง"
      },
      {
      "code":"100704",
      "display":"ลุมพินี"
      },
      {
      "code":"100702",
      "display":"วังใหม่"
      },
      {
      "code":"100702",
      "display":"วังใหม่"
      },
      {
      "code":"100702",
      "display":"วังใหม่"
      },
      {
      "code":"100702",
      "display":"วังใหม่"
      },
      {
      "code":"100702",
      "display":"วังใหม่"
      }
      ],
      "code":"1007",
      "display":"ปทุมวัน"
      },
      {
      "districts":[
      {
      "code":"100909",
      "display":"ดอกไม้"
      },
      {
      "code":"100908",
      "display":"ประเวศ"
      },
      {
      "code":"100907",
      "display":"หนองบอน"
      }
      ],
      "code":"1032",
      "display":"ประเวศ"
      },
      {
      "districts":[
      {
      "code":"100803",
      "display":"คลองมหานาค"
      },
      {
      "code":"100804",
      "display":"บ้านบาตร"
      },
      {
      "code":"100801",
      "display":"ป้อมปราบ"
      },
      {
      "code":"100802",
      "display":"วัดเทพศิรินทร์"
      },
      {
      "code":"100805",
      "display":"วัดโสมนัส"
      }
      ],
      "code":"1008",
      "display":"ป้อมปราบศัตรูพ่าย"
      },
      {
      "districts":[
      {
      "code":"100299",
      "display":"สามเสนใน"
      }
      ],
      "code":"1014",
      "display":"พญาไท"
      },
      {
      "districts":[
      {
      "code":"100109",
      "display":"ชนะสงคราม"
      },
      {
      "code":"100108",
      "display":"ตลาดยอด"
      },
      {
      "code":"100107",
      "display":"บวรนิเวศ"
      },
      {
      "code":"100111",
      "display":"บางขุนพรหม"
      },
      {
      "code":"100110",
      "display":"บ้านพานถม"
      },
      {
      "code":"100101",
      "display":"พระบรมมหาราชวัง"
      },
      {
      "code":"100102",
      "display":"วังบูรพาภิรมย์"
      },
      {
      "code":"100103",
      "display":"วัดราชบพิธ"
      },
      {
      "code":"100112",
      "display":"วัดสามพระยา"
      },
      {
      "code":"100105",
      "display":"ศาลเจ้าพ่อเสือ"
      },
      {
      "code":"100104",
      "display":"สำราญราษฎร์"
      },
      {
      "code":"100106",
      "display":"เสาชิงช้า"
      }
      ],
      "code":"1001",
      "display":"พระนคร"
      },
      {
      "districts":[
      {
      "code":"100905",
      "display":"บางจาก"
      }
      ],
      "code":"1009",
      "display":"พระโขนง"
      },
      {
      "districts":[
      {
      "code":"102208",
      "display":"คลองขวาง"
      },
      {
      "code":"102210",
      "display":"คูหาสวรรค์"
      },
      {
      "code":"100905",
      "display":"บางจาก"
      },
      {
      "code":"102202",
      "display":"บางด้วน"
      },
      {
      "code":"102201",
      "display":"บางหว้า"
      },
      {
      "code":"102207",
      "display":"บางแวก"
      },
      {
      "code":"102209",
      "display":"ปากคลองภาษีเจริญ"
      }
      ],
      "code":"1022",
      "display":"ภาษีเจริญ"
      },
      {
      "districts":[
      {
      "code":"101001",
      "display":"มีนบุรี"
      },
      {
      "code":"101002",
      "display":"แสนแสบ"
      }
      ],
      "code":"1010",
      "display":"มีนบุรี"
      },
      {
      "districts":[
      {
      "code":"101203",
      "display":"ช่องนนทรี"
      },
      {
      "code":"101204",
      "display":"บางโพงพาง"
      }
      ],
      "code":"1012",
      "display":"ยานนาวา"
      },
      {
      "districts":[
      {
      "code":"101405",
      "display":"ถนนพญาไท"
      },
      {
      "code":"101402",
      "display":"ถนนเพชรบุรี"
      },
      {
      "code":"101403",
      "display":"ทุ่งพญาไท"
      },
      {
      "code":"101404",
      "display":"มักกะสัน"
      }
      ],
      "code":"1037",
      "display":"ราชเทวี"
      },
      {
      "districts":[
      {
      "code":"102402",
      "display":"บางปะกอก"
      },
      {
      "code":"102401",
      "display":"ราษฎร์บูรณะ"
      }
      ],
      "code":"1024",
      "display":"ราษฎร์บูรณะ"
      },
      {
      "districts":[
      {
      "code":"101106",
      "display":"ขุมทอง"
      },
      {
      "code":"101102",
      "display":"คลองสองต้นนุ่น"
      },
      {
      "code":"101103",
      "display":"คลองสามประเวศ"
      },
      {
      "code":"101105",
      "display":"ทับยาว"
      },
      {
      "code":"101101",
      "display":"ลาดกระบัง"
      },
      {
      "code":"101104",
      "display":"ลำปลาทิว"
      }
      ],
      "code":"1011",
      "display":"ลาดกระบัง"
      },
      {
      "districts":[
      {
      "code":"100607",
      "display":"จรเข้บัว"
      },
      {
      "code":"100603",
      "display":"ลาดพร้าว"
      }
      ],
      "code":"1038",
      "display":"ลาดพร้าว"
      },
      {
      "districts":[
      {
      "code":"FALSE",
      "display":"คลองเจ้าคุณสิงห์"
      },
      {
      "code":"FALSE",
      "display":"พลับพลา"
      },
      {
      "code":"100602",
      "display":"วังทองหลาง"
      },
      {
      "code":"FALSE",
      "display":"สะพานสอง"
      }
      ],
      "code":"1045",
      "display":"วังทองหลาง"
      },
      {
      "districts":[
      {
      "code":"103305",
      "display":"คลองตันเหนือ"
      },
      {
      "code":"103304",
      "display":"คลองเตยเหนือ"
      },
      {
      "code":"103306",
      "display":"พระโขนงเหนือ"
      },
      {
      "code":"103306",
      "display":"พระโขนงเหนือ"
      }
      ],
      "code":"1039",
      "display":"วัฒนา"
      },
      {
      "districts":[
      {
      "code":"100906",
      "display":"สวนหลวง"
      },
      {
      "code":"103400",
      "display":"อ่อนนุช"
      }
      ],
      "code":"1034",
      "display":"สวนหลวง"
      },
      {
      "districts":[
      {
      "code":"100605",
      "display":"สะพานสูง"
      },
      {
      "code":"100605",
      "display":"สะพานสูง"
      },
      {
      "code":"104402",
      "display":"ราษฎร์พัฒนา"
      },
      {
      "code":"104403",
      "display":"ทับช้าง"
      }
      ],
      "code":"1044",
      "display":"สะพานสูง"
      },
      {
      "districts":[
      {
      "code":"FALSE",
      "display":"จักรวรรดิ์"
      },
      {
      "code":"101303",
      "display":"ตลาดน้อย"
      },
      {
      "code":"101302",
      "display":"สัมพันธวงศ์"
      }
      ],
      "code":"1013",
      "display":"สัมพันธวงศ์"
      },
      {
      "districts":[
      {
      "code":"101208",
      "display":"ทุ่งมหาเมฆ"
      },
      {
      "code":"101201",
      "display":"ทุ่งวัดดอน"
      },
      {
      "code":"101202",
      "display":"ยานนาวา"
      }
      ],
      "code":"1028",
      "display":"สาทร"
      },
      {
      "districts":[
      {
      "code":"100503",
      "display":"คลองถนน"
      },
      {
      "code":"100506",
      "display":"สายไหม"
      },
      {
      "code":"100509",
      "display":"ออเงิน"
      }
      ],
      "code":"1042",
      "display":"สายไหม"
      },
      {
      "districts":[
      {
      "code":"100301",
      "display":"กระทุ่มราย"
      },
      {
      "code":"100303",
      "display":"คลองสิบ"
      },
      {
      "code":"100304",
      "display":"คลองสิบสอง"
      },
      {
      "code":"100306",
      "display":"คู้ฝั่งเหนือ"
      },
      {
      "code":"100308",
      "display":"ลำต้อยติ่ง"
      },
      {
      "code":"100307",
      "display":"ลำผักชี"
      },
      {
      "code":"100302",
      "display":"หนองจอก"
      },
      {
      "code":"100305",
      "display":"โคกแฝด"
      }
      ],
      "code":"1003",
      "display":"หนองจอก"
      },
      {
      "districts":[
      {
      "code":"102303",
      "display":"หนองค้างพลู"
      },
      {
      "code":"102302",
      "display":"หนองแขม"
      }
      ],
      "code":"1023",
      "display":"หนองแขม"
      },
      {
      "districts":[
      {
      "code":"100504",
      "display":"ตลาดบางเขน"
      },
      {
      "code":"100507",
      "display":"ทุ่งสองห้อง"
      }
      ],
      "code":"1041",
      "display":"หลักสี่"
      },
      {
      "districts":[
      {
      "code":"101702",
      "display":"บางกะปิ"
      },
      {
      "code":"101704",
      "display":"สามเสนนอก"
      },
      {
      "code":"101701",
      "display":"ห้วยขวาง"
      }
      ],
      "code":"1017",
      "display":"ห้วยขวาง"
      }
      ],
      "display":"กรุงเทพมหานคร"
      },
      "กาญจนบุรี":{
      "sort_order":"3",
      "code":"71",
      "amphoe":[
      {
      "districts":[
      {
      "code":"710115",
      "display":"กลอนโด"
      },
      {
      "code":"710114",
      "display":"จรเข้เผือก"
      },
      {
      "code":"710112",
      "display":"ด่านมะขามเตี้ย"
      },
      {
      "code":"711104",
      "display":"หนองไผ่"
      }
      ],
      "code":"7111",
      "display":"ด่านมะขามเตี้ย"
      },
      {
      "districts":[
      {
      "code":"710705",
      "display":"ชะแล"
      },
      {
      "code":"710701",
      "display":"ท่าขนุน"
      },
      {
      "code":"710702",
      "display":"ปิล๊อก"
      },
      {
      "code":"710704",
      "display":"ลิ่นถิ่น"
      },
      {
      "code":"710707",
      "display":"สหกรณ์นิคม"
      },
      {
      "code":"710703",
      "display":"หินดาด"
      },
      {
      "code":"710706",
      "display":"ห้วยเขย่ง"
      }
      ],
      "code":"7107",
      "display":"ทองผาภูมิ"
      },
      {
      "districts":[
      {
      "code":"710509",
      "display":"ดอนขมิ้น"
      },
      {
      "code":"710503",
      "display":"ดอนชะเอม"
      },
      {
      "code":"710505",
      "display":"ตะคร้ำเอน"
      },
      {
      "code":"710506",
      "display":"ท่ามะกา"
      },
      {
      "code":"710507",
      "display":"ท่าเรือ"
      },
      {
      "code":"710202",
      "display":"ท่าเสา"
      },
      {
      "code":"710504",
      "display":"ท่าไม้"
      },
      {
      "code":"710501",
      "display":"พงตึก"
      },
      {
      "code":"710512",
      "display":"พระแท่น"
      },
      {
      "code":"710502",
      "display":"ยางม่วง"
      },
      {
      "code":"710515",
      "display":"สนามแย้"
      },
      {
      "code":"710517",
      "display":"หนองลาน"
      },
      {
      "code":"710513",
      "display":"หวายเหนียว"
      },
      {
      "code":"710510",
      "display":"อุโลกสี่หมื่น"
      },
      {
      "code":"710511",
      "display":"เขาสามสิบหาบ"
      },
      {
      "code":"710514",
      "display":"แสนตอ"
      },
      {
      "code":"710508",
      "display":"โคกตะบอง"
      }
      ],
      "code":"7105",
      "display":"ท่ามะกา"
      },
      {
      "districts":[
      {
      "code":"710606",
      "display":"ทุ่งทอง"
      },
      {
      "code":"710611",
      "display":"ท่าตะคร้อ"
      },
      {
      "code":"710601",
      "display":"ท่าม่วง"
      },
      {
      "code":"710604",
      "display":"ท่าล้อ"
      },
      {
      "code":"710609",
      "display":"บ้านใหม่"
      },
      {
      "code":"710610",
      "display":"พังตรุ"
      },
      {
      "code":"710608",
      "display":"ม่วงชุม"
      },
      {
      "code":"710612",
      "display":"รางสาลี่"
      },
      {
      "code":"710602",
      "display":"วังขนาย"
      },
      {
      "code":"710603",
      "display":"วังศาลา"
      },
      {
      "code":"710605",
      "display":"หนองขาว"
      },
      {
      "code":"710613",
      "display":"หนองตากยา"
      },
      {
      "code":"710607",
      "display":"เขาน้อย"
      }
      ],
      "code":"7106",
      "display":"ท่าม่วง"
      },
      {
      "districts":[
      {
      "code":"710308",
      "display":"ช่องด่าน"
      },
      {
      "code":"710301",
      "display":"บ่อพลอย"
      },
      {
      "code":"710309",
      "display":"หนองกร่าง"
      },
      {
      "code":"710302",
      "display":"หนองกุ่ม"
      },
      {
      "code":"710303",
      "display":"หนองรี"
      },
      {
      "code":"710305",
      "display":"หลุมรัง"
      }
      ],
      "code":"7103",
      "display":"บ่อพลอย"
      },
      {
      "districts":[
      {
      "code":"710912",
      "display":"ดอนตาเพชร"
      },
      {
      "code":"710904",
      "display":"ดอนเจดีย์"
      },
      {
      "code":"710903",
      "display":"ทุ่งสมอ"
      },
      {
      "code":"710901",
      "display":"พนมทวน"
      },
      {
      "code":"710610",
      "display":"พังตรุ"
      },
      {
      "code":"710906",
      "display":"รางหวาย"
      },
      {
      "code":"710911",
      "display":"หนองสาหร่าย"
      },
      {
      "code":"710902",
      "display":"หนองโรง"
      }
      ],
      "code":"7109",
      "display":"พนมทวน"
      },
      {
      "districts":[
      {
      "code":"710402",
      "display":"ด่านแม่แฉลบ"
      },
      {
      "code":"710404",
      "display":"ท่ากระดาน"
      },
      {
      "code":"710401",
      "display":"นาสวน"
      },
      {
      "code":"710403",
      "display":"หนองเป็ด"
      },
      {
      "code":"710405",
      "display":"เขาโจด"
      },
      {
      "code":"710406",
      "display":"แม่กระบุง"
      }
      ],
      "code":"7104",
      "display":"ศรีสวัสดิ์"
      },
      {
      "districts":[
      {
      "code":"710802",
      "display":"ปรังเผล"
      },
      {
      "code":"710801",
      "display":"หนองลู"
      },
      {
      "code":"710803",
      "display":"ไล่โว่"
      }
      ],
      "code":"7108",
      "display":"สังขละบุรี"
      },
      {
      "districts":[
      {
      "code":"710307",
      "display":"สมเด็จเจริญ"
      },
      {
      "code":"710304",
      "display":"หนองปรือ"
      },
      {
      "code":"710306",
      "display":"หนองปลาไหล"
      }
      ],
      "code":"7112",
      "display":"หนองปรือ"
      },
      {
      "districts":[
      {
      "code":"710907",
      "display":"ดอนแสลบ"
      },
      {
      "code":"710910",
      "display":"วังไผ่"
      },
      {
      "code":"710909",
      "display":"สระลงเรือ"
      },
      {
      "code":"710908",
      "display":"ห้วยกระเจา"
      }
      ],
      "code":"7113",
      "display":"ห้วยกระเจา"
      },
      {
      "districts":[
      {
      "code":"710109",
      "display":"ช่องสะเดา"
      },
      {
      "code":"710104",
      "display":"ท่ามะขาม"
      },
      {
      "code":"710113",
      "display":"บ้านเก่า"
      },
      {
      "code":"710101",
      "display":"บ้านเหนือ"
      },
      {
      "code":"710102",
      "display":"บ้านใต้"
      },
      {
      "code":"710103",
      "display":"ปากแพรก"
      },
      {
      "code":"710107",
      "display":"ลาดหญ้า"
      },
      {
      "code":"710108",
      "display":"วังด้ง"
      },
      {
      "code":"710116",
      "display":"วังเย็น"
      },
      {
      "code":"710106",
      "display":"หนองบัว"
      },
      {
      "code":"710110",
      "display":"หนองหญ้า"
      },
      {
      "code":"710111",
      "display":"เกาะสำโรง"
      },
      {
      "code":"710105",
      "display":"แก่งเสี้ยน"
      }
      ],
      "code":"7101",
      "display":"เมืองกาญจนบุรี"
      },
      {
      "districts":[
      {
      "code":"711006",
      "display":"ทุ่งกระบ่ำ"
      },
      {
      "code":"711005",
      "display":"หนองนกแก้ว"
      },
      {
      "code":"711003",
      "display":"หนองประดู่"
      },
      {
      "code":"711004",
      "display":"หนองปลิง"
      },
      {
      "code":"711007",
      "display":"หนองฝ้าย"
      },
      {
      "code":"711002",
      "display":"หนองโสน"
      },
      {
      "code":"711001",
      "display":"เลาขวัญ"
      }
      ],
      "code":"7110",
      "display":"เลาขวัญ"
      },
      {
      "districts":[
      {
      "code":"710202",
      "display":"ท่าเสา"
      },
      {
      "code":"710207",
      "display":"บ้องตี้"
      },
      {
      "code":"710201",
      "display":"ลุ่มสุ่ม"
      },
      {
      "code":"710205",
      "display":"วังกระแจะ"
      },
      {
      "code":"710206",
      "display":"ศรีมงคล"
      },
      {
      "code":"710203",
      "display":"สิงห์"
      },
      {
      "code":"710204",
      "display":"ไทรโยค"
      }
      ],
      "code":"7102",
      "display":"ไทรโยค"
      }
      ],
      "display":"กาญจนบุรี"
      },
      "กาฬสินธุ์":{
      "sort_order":"4",
      "code":"46",
      "amphoe":[
      {
      "districts":[
      {
      "code":"460301",
      "display":"กมลาไสย"
      },
      {
      "code":"460304",
      "display":"ดงลิง"
      },
      {
      "code":"460305",
      "display":"ธัญญา"
      },
      {
      "code":"460308",
      "display":"หนองแปน"
      },
      {
      "code":"460302",
      "display":"หลักเมือง"
      },
      {
      "code":"460310",
      "display":"เจ้าท่า"
      },
      {
      "code":"460311",
      "display":"โคกสมบูรณ์"
      },
      {
      "code":"460303",
      "display":"โพนงาม"
      }
      ],
      "code":"4603",
      "display":"กมลาไสย"
      },
      {
      "districts":[
      {
      "code":"460512",
      "display":"กุดค้าว"
      },
      {
      "code":"460506",
      "display":"กุดหว้า"
      },
      {
      "code":"460504",
      "display":"จุมจัง"
      },
      {
      "code":"460508",
      "display":"นาขาม"
      },
      {
      "code":"460510",
      "display":"นาโก"
      },
      {
      "code":"460501",
      "display":"บัวขาว"
      },
      {
      "code":"460511",
      "display":"สมสะอาด"
      },
      {
      "code":"460507",
      "display":"สามขา"
      },
      {
      "code":"460509",
      "display":"หนองห้าง"
      },
      {
      "code":"460503",
      "display":"เหล่าใหญ่"
      },
      {
      "code":"460505",
      "display":"เหล่าไฮงาม"
      },
      {
      "code":"460502",
      "display":"แจนแลน"
      }
      ],
      "code":"4605",
      "display":"กุฉินารายณ์"
      },
      {
      "districts":[
      {
      "code":"461005",
      "display":"ดินจี่"
      },
      {
      "code":"460997",
      "display":"ทุ่งคลอง"
      },
      {
      "code":"461007",
      "display":"นาทัน"
      },
      {
      "code":"461006",
      "display":"นาบอน"
      },
      {
      "code":"461009",
      "display":"เนินยาง"
      },
      {
      "code":"460999",
      "display":"โพน"
      }
      ],
      "code":"4610",
      "display":"คำม่วง"
      },
      {
      "districts":[
      {
      "code":"460313",
      "display":"ฆ้องชัยพัฒนา"
      },
      {
      "code":"460307",
      "display":"ลำชี"
      },
      {
      "code":"461802",
      "display":"เหล่ากลาง"
      },
      {
      "code":"460309",
      "display":"โคกสะอาด"
      },
      {
      "code":"461804",
      "display":"โนนศิลาเลิง"
      }
      ],
      "code":"4618",
      "display":"ฆ้องชัย"
      },
      {
      "districts":[
      {
      "code":"460112",
      "display":"ดงพยุง"
      },
      {
      "code":"460114",
      "display":"ดอนจาน"
      },
      {
      "code":"460118",
      "display":"นาจำปา"
      },
      {
      "code":"460110",
      "display":"ม่วงนา"
      },
      {
      "code":"460122",
      "display":"สะอาดไชยศรี"
      }
      ],
      "code":"4617",
      "display":"ดอนจาน"
      },
      {
      "districts":[
      {
      "code":"461102",
      "display":"กุงเก่า"
      },
      {
      "code":"461104",
      "display":"กุดจิก"
      },
      {
      "code":"461106",
      "display":"ดงสมบูรณ์"
      },
      {
      "code":"461101",
      "display":"ท่าคันโท"
      },
      {
      "code":"461105",
      "display":"นาตาล"
      },
      {
      "code":"461103",
      "display":"ยางอู้ม"
      }
      ],
      "code":"4611",
      "display":"ท่าคันโท"
      },
      {
      "districts":[
      {
      "code":"460605",
      "display":"นาคู"
      },
      {
      "code":"460607",
      "display":"บ่อแก้ว"
      },
      {
      "code":"460604",
      "display":"ภูแล่นช้าง"
      },
      {
      "code":"460609",
      "display":"สายนาวัง"
      },
      {
      "code":"460610",
      "display":"โนนนาจาน"
      }
      ],
      "code":"4616",
      "display":"นาคู"
      },
      {
      "districts":[
      {
      "code":"460198",
      "display":"นามน"
      },
      {
      "code":"460199",
      "display":"ยอดแกง"
      },
      {
      "code":"460203",
      "display":"สงเปลือย"
      },
      {
      "code":"460205",
      "display":"หนองบัว"
      },
      {
      "code":"460204",
      "display":"หลักเหลี่ยม"
      }
      ],
      "code":"4602",
      "display":"นามน"
      },
      {
      "districts":[
      {
      "code":"460711",
      "display":"คลองขาม"
      },
      {
      "code":"460709",
      "display":"ดอนสมบูรณ์"
      },
      {
      "code":"460713",
      "display":"นาดี"
      },
      {
      "code":"460710",
      "display":"นาเชือก"
      },
      {
      "code":"460704",
      "display":"บัวบาน"
      },
      {
      "code":"460701",
      "display":"ยางตลาด"
      },
      {
      "code":"460715",
      "display":"หนองตอกแป้น"
      },
      {
      "code":"460708",
      "display":"หนองอิเฒ่า"
      },
      {
      "code":"460702",
      "display":"หัวงัว"
      },
      {
      "code":"460707",
      "display":"หัวนาคำ"
      },
      {
      "code":"460706",
      "display":"อิตื้อ"
      },
      {
      "code":"460703",
      "display":"อุ่มเม่า"
      },
      {
      "code":"460712",
      "display":"เขาพระนอน"
      },
      {
      "code":"460705",
      "display":"เว่อ"
      },
      {
      "code":"460714",
      "display":"โนนสูง"
      }
      ],
      "code":"4607",
      "display":"ยางตลาด"
      },
      {
      "districts":[
      {
      "code":"460401",
      "display":"ร่องคำ"
      },
      {
      "code":"460402",
      "display":"สามัคคี"
      },
      {
      "code":"460403",
      "display":"เหล่าอ้อย"
      }
      ],
      "code":"4604",
      "display":"ร่องคำ"
      },
      {
      "districts":[
      {
      "code":"461306",
      "display":"ผาเสวย"
      },
      {
      "code":"461304",
      "display":"มหาไชย"
      },
      {
      "code":"461308",
      "display":"ลำห้วยหลัว"
      },
      {
      "code":"461307",
      "display":"ศรีสมเด็จ"
      },
      {
      "code":"461301",
      "display":"สมเด็จ"
      },
      {
      "code":"461302",
      "display":"หนองแวง"
      },
      {
      "code":"461305",
      "display":"หมูม่น"
      },
      {
      "code":"461303",
      "display":"แซงบาดาล"
      }
      ],
      "code":"4613",
      "display":"สมเด็จ"
      },
      {
      "districts":[
      {
      "code":"460903",
      "display":"นามะเขือ"
      },
      {
      "code":"460905",
      "display":"นิคม"
      },
      {
      "code":"460901",
      "display":"ภูสิงห์"
      },
      {
      "code":"460902",
      "display":"สหัสขันธ์"
      },
      {
      "code":"460908",
      "display":"โนนน้ำเกลี้ยง"
      },
      {
      "code":"460907",
      "display":"โนนบุรี"
      },
      {
      "code":"460312",
      "display":"โนนศิลา"
      },
      {
      "code":"460906",
      "display":"โนนแหลมทอง"
      }
      ],
      "code":"4609",
      "display":"สหัสขันธ์"
      },
      {
      "districts":[
      {
      "code":"461008",
      "display":"คำสร้างเที่ยง"
      },
      {
      "code":"460998",
      "display":"สำราญ"
      },
      {
      "code":"461004",
      "display":"สำราญใต้"
      },
      {
      "code":"461010",
      "display":"หนองช้าง"
      }
      ],
      "code":"4615",
      "display":"สามชัย"
      },
      {
      "districts":[
      {
      "code":"461207",
      "display":"ดงมูล"
      },
      {
      "code":"461208",
      "display":"ลำหนองแสน"
      },
      {
      "code":"461201",
      "display":"หนองกุงศรี"
      },
      {
      "code":"460205",
      "display":"หนองบัว"
      },
      {
      "code":"461204",
      "display":"หนองสรวง"
      },
      {
      "code":"461209",
      "display":"หนองหิน"
      },
      {
      "code":"461206",
      "display":"หนองใหญ่"
      },
      {
      "code":"461205",
      "display":"เสาเล้า"
      },
      {
      "code":"461198",
      "display":"โคกเครือ"
      }
      ],
      "code":"4612",
      "display":"หนองกุงศรี"
      },
      {
      "districts":[
      {
      "code":"461401",
      "display":"คำบง"
      },
      {
      "code":"461403",
      "display":"นิคมห้วยผึ้ง"
      },
      {
      "code":"461404",
      "display":"หนองอีบุตร"
      },
      {
      "code":"461402",
      "display":"ไค้นุ่น"
      }
      ],
      "code":"4614",
      "display":"ห้วยผึ้ง"
      },
      {
      "districts":[
      {
      "code":"460803",
      "display":"กุดโดน"
      },
      {
      "code":"460807",
      "display":"คำเหมือดแก้ว"
      },
      {
      "code":"460802",
      "display":"คำใหญ่"
      },
      {
      "code":"460809",
      "display":"ทรายทอง"
      },
      {
      "code":"460804",
      "display":"บึงนาเรียง"
      },
      {
      "code":"460806",
      "display":"พิมูล"
      },
      {
      "code":"460805",
      "display":"หัวหิน"
      },
      {
      "code":"460801",
      "display":"ห้วยเม็ก"
      },
      {
      "code":"460808",
      "display":"โนนสะอาด"
      }
      ],
      "code":"4608",
      "display":"ห้วยเม็ก"
      },
      {
      "districts":[
      {
      "code":"460611",
      "display":"กุดปลาค้าว"
      },
      {
      "code":"460606",
      "display":"กุดสิมคุ้มใหม่"
      },
      {
      "code":"460601",
      "display":"คุ้มเก่า"
      },
      {
      "code":"460203",
      "display":"สงเปลือย"
      },
      {
      "code":"460608",
      "display":"สระพังทอง"
      },
      {
      "code":"460603",
      "display":"หนองผือ"
      }
      ],
      "code":"4606",
      "display":"เขาวง"
      },
      {
      "districts":[
      {
      "code":"460116",
      "display":"กลางหมื่น"
      },
      {
      "code":"460101",
      "display":"กาฬสินธุ์"
      },
      {
      "code":"460117",
      "display":"ขมิ้น"
      },
      {
      "code":"460120",
      "display":"นาจารย์"
      },
      {
      "code":"460108",
      "display":"บึงวิชัย"
      },
      {
      "code":"460113",
      "display":"ภูดิน"
      },
      {
      "code":"460111",
      "display":"ภูปอ"
      },
      {
      "code":"460121",
      "display":"ลำคลอง"
      },
      {
      "code":"460105",
      "display":"ลำปาว"
      },
      {
      "code":"460106",
      "display":"ลำพาน"
      },
      {
      "code":"460115",
      "display":"หนองกุง"
      },
      {
      "code":"460103",
      "display":"หลุบ"
      },
      {
      "code":"460109",
      "display":"ห้วยโพธิ์"
      },
      {
      "code":"460107",
      "display":"เชียงเครือ"
      },
      {
      "code":"460102",
      "display":"เหนือ"
      },
      {
      "code":"460119",
      "display":"โพนทอง"
      },
      {
      "code":"460104",
      "display":"ไผ่"
      }
      ],
      "code":"4601",
      "display":"เมืองกาฬสินธุ์"
      }
      ],
      "display":"กาฬสินธุ์"
      },
      "กำแพงเพชร":{
      "sort_order":"5",
      "code":"62",
      "amphoe":[
      {
      "districts":[
      {
      "code":"620408",
      "display":"ดอนแตง"
      },
      {
      "code":"620407",
      "display":"บ่อถ้ำ"
      },
      {
      "code":"620411",
      "display":"ปางมะค่า"
      },
      {
      "code":"620404",
      "display":"ป่าพุทรา"
      },
      {
      "code":"620403",
      "display":"ยางสูง"
      },
      {
      "code":"620409",
      "display":"วังชะพลู"
      },
      {
      "code":"620412",
      "display":"วังหามแห"
      },
      {
      "code":"620406",
      "display":"สลกบาตร"
      },
      {
      "code":"620413",
      "display":"เกาะตาล"
      },
      {
      "code":"620405",
      "display":"แสนตอ"
      },
      {
      "code":"620410",
      "display":"โค้งไผ่"
      }
      ],
      "code":"6204",
      "display":"ขาณุวรลักษบุรี"
      },
      {
      "districts":[
      {
      "code":"620501",
      "display":"คลองขลุง"
      },
      {
      "code":"620516",
      "display":"คลองสมบูรณ์"
      },
      {
      "code":"620504",
      "display":"ท่าพุทรา"
      },
      {
      "code":"620502",
      "display":"ท่ามะเขือ"
      },
      {
      "code":"620513",
      "display":"วังบัว"
      },
      {
      "code":"620506",
      "display":"วังยาง"
      },
      {
      "code":"620507",
      "display":"วังแขม"
      },
      {
      "code":"620509",
      "display":"วังไทร"
      },
      {
      "code":"620508",
      "display":"หัวถนน"
      },
      {
      "code":"620505",
      "display":"แม่ลาด"
      }
      ],
      "code":"6205",
      "display":"คลองขลุง"
      },
      {
      "districts":[
      {
      "code":"620301",
      "display":"คลองน้ำไหล"
      },
      {
      "code":"620303",
      "display":"คลองลานพัฒนา"
      },
      {
      "code":"620304",
      "display":"สักงาม"
      },
      {
      "code":"620198",
      "display":"โป่งน้ำร้อน"
      }
      ],
      "code":"6203",
      "display":"คลองลาน"
      },
      {
      "districts":[
      {
      "code":"620803",
      "display":"ถาวรวัฒนา"
      },
      {
      "code":"620599",
      "display":"ทุ่งทราย"
      },
      {
      "code":"620514",
      "display":"ทุ่งทอง"
      }
      ],
      "code":"6208",
      "display":"ทรายทองวัฒนา"
      },
      {
      "districts":[
      {
      "code":"620414",
      "display":"บึงสามัคคี"
      },
      {
      "code":"620402",
      "display":"ระหาน"
      },
      {
      "code":"620401",
      "display":"วังชะโอน"
      },
      {
      "code":"621004",
      "display":"เทพนิมิต"
      }
      ],
      "code":"6210",
      "display":"บึงสามัคคี"
      },
      {
      "districts":[
      {
      "code":"620511",
      "display":"ปางตาไว"
      },
      {
      "code":"620515",
      "display":"หินดาต"
      },
      {
      "code":"620510",
      "display":"โพธิ์ทอง"
      }
      ],
      "code":"6209",
      "display":"ปางศิลาทอง"
      },
      {
      "districts":[
      {
      "code":"620608",
      "display":"คลองพิไกร"
      },
      {
      "code":"620607",
      "display":"คุยบ้านโอง"
      },
      {
      "code":"620609",
      "display":"ถ้ำกระต่ายทอง"
      },
      {
      "code":"620603",
      "display":"ท่าไม้"
      },
      {
      "code":"620601",
      "display":"พรานกระต่าย"
      },
      {
      "code":"620604",
      "display":"วังควง"
      },
      {
      "code":"620605",
      "display":"วังตะแบก"
      },
      {
      "code":"620602",
      "display":"หนองหัววัว"
      },
      {
      "code":"620610",
      "display":"ห้วยยั้ง"
      },
      {
      "code":"620606",
      "display":"เขาคีริส"
      }
      ],
      "code":"6206",
      "display":"พรานกระต่าย"
      },
      {
      "districts":[
      {
      "code":"620707",
      "display":"จันทิมา"
      },
      {
      "code":"620702",
      "display":"ช่องลม"
      },
      {
      "code":"620706",
      "display":"บึงทับแรต"
      },
      {
      "code":"620705",
      "display":"ประชาสุขสันต์"
      },
      {
      "code":"620701",
      "display":"ลานกระบือ"
      },
      {
      "code":"620703",
      "display":"หนองหลวง"
      },
      {
      "code":"620704",
      "display":"โนนพลวง"
      }
      ],
      "code":"6207",
      "display":"ลานกระบือ"
      },
      {
      "districts":[
      {
      "code":"620111",
      "display":"คณฑี"
      },
      {
      "code":"620117",
      "display":"คลองแม่ลาย"
      },
      {
      "code":"620106",
      "display":"ทรงธรรม"
      },
      {
      "code":"620115",
      "display":"ท่าขุนราม"
      },
      {
      "code":"620118",
      "display":"ธำมรงค์"
      },
      {
      "code":"620105",
      "display":"นครชุม"
      },
      {
      "code":"620104",
      "display":"นาบ่อคำ"
      },
      {
      "code":"620112",
      "display":"นิคมทุ่งโพธิ์ทะเล"
      },
      {
      "code":"620107",
      "display":"ลานดอกไม้"
      },
      {
      "code":"620114",
      "display":"วังทอง"
      },
      {
      "code":"620119",
      "display":"สระแก้ว"
      },
      {
      "code":"620110",
      "display":"หนองปลิง"
      },
      {
      "code":"620103",
      "display":"อ่างทอง"
      },
      {
      "code":"620113",
      "display":"เทพนคร"
      },
      {
      "code":"620101",
      "display":"ในเมือง"
      },
      {
      "code":"620102",
      "display":"ไตรตรึงษ์"
      }
      ],
      "code":"6201",
      "display":"เมืองกำแพงเพชร"
      },
      {
      "districts":[
      {
      "code":"620108",
      "display":"ลานดอกไม้ตก"
      },
      {
      "code":"620116",
      "display":"เพชรชมภู"
      },
      {
      "code":"620109",
      "display":"โกสัมพี"
      }
      ],
      "code":"6211",
      "display":"โกสัมพีนคร"
      },
      {
      "districts":[
      {
      "code":"620206",
      "display":"พานทอง"
      },
      {
      "code":"620205",
      "display":"มหาชัย"
      },
      {
      "code":"620197",
      "display":"หนองคล้า"
      },
      {
      "code":"620203",
      "display":"หนองทอง"
      },
      {
      "code":"620207",
      "display":"หนองแม่แตง"
      },
      {
      "code":"620204",
      "display":"หนองไม้กอง"
      },
      {
      "code":"620199",
      "display":"ไทรงาม"
      }
      ],
      "code":"6202",
      "display":"ไทรงาม"
      }
      ],
      "display":"กำแพงเพชร"
      },
      "ขอนแก่น":{
      "sort_order":"6",
      "code":"40",
      "amphoe":[
      {
      "districts":[
      {
      "code":"400909",
      "display":"ดูนสาด"
      },
      {
      "code":"400911",
      "display":"น้ำอ้อม"
      },
      {
      "code":"400206",
      "display":"บ้านฝาง"
      },
      {
      "code":"400902",
      "display":"หนองกุงใหญ่"
      },
      {
      "code":"400901",
      "display":"หนองโก"
      },
      {
      "code":"400910",
      "display":"หนองโน"
      },
      {
      "code":"400912",
      "display":"หัวนาคำ"
      },
      {
      "code":"400906",
      "display":"ห้วยยาง"
      },
      {
      "code":"400905",
      "display":"ห้วยโจด"
      }
      ],
      "code":"4009",
      "display":"กระนวน"
      },
      {
      "districts":[
      {
      "code":"401802",
      "display":"กุดเพียขอม"
      },
      {
      "code":"401801",
      "display":"ชนบท"
      },
      {
      "code":"401805",
      "display":"บ้านแท่น"
      },
      {
      "code":"401808",
      "display":"ปอแดง"
      },
      {
      "code":"401803",
      "display":"วังแสง"
      },
      {
      "code":"401806",
      "display":"ศรีบุญเรือง"
      },
      {
      "code":"401804",
      "display":"ห้วยแก"
      },
      {
      "code":"401807",
      "display":"โนนพะยอม"
      }
      ],
      "code":"4018",
      "display":"ชนบท"
      },
      {
      "districts":[
      {
      "code":"400505",
      "display":"ขัวเรียง"
      },
      {
      "code":"400501",
      "display":"ชุมแพ"
      },
      {
      "code":"400503",
      "display":"นาหนองทุ่ม"
      },
      {
      "code":"400509",
      "display":"นาเพียง"
      },
      {
      "code":"400508",
      "display":"วังหินลาด"
      },
      {
      "code":"400510",
      "display":"หนองเขียด"
      },
      {
      "code":"400511",
      "display":"หนองเสาเล้า"
      },
      {
      "code":"400506",
      "display":"หนองไผ่"
      },
      {
      "code":"400409",
      "display":"โนนสะอาด"
      },
      {
      "code":"400502",
      "display":"โนนหัน"
      },
      {
      "code":"400504",
      "display":"โนนอุดม"
      },
      {
      "code":"400507",
      "display":"ไชยสอ"
      }
      ],
      "code":"4005",
      "display":"ชุมแพ"
      },
      {
      "districts":[
      {
      "code":"400903",
      "display":"กระนวน"
      },
      {
      "code":"400908",
      "display":"คำแมด"
      },
      {
      "code":"400913",
      "display":"คูคำ"
      },
      {
      "code":"400904",
      "display":"บ้านโนน"
      },
      {
      "code":"400914",
      "display":"ห้วยเตย"
      }
      ],
      "code":"4021",
      "display":"ซำสูง"
      },
      {
      "districts":[
      {
      "code":"400712",
      "display":"กุดน้ำใส"
      },
      {
      "code":"400709",
      "display":"ทรายมูล"
      },
      {
      "code":"400710",
      "display":"ท่ากระเสริม"
      },
      {
      "code":"400701",
      "display":"น้ำพอง"
      },
      {
      "code":"400708",
      "display":"บัวเงิน"
      },
      {
      "code":"400704",
      "display":"บัวใหญ่"
      },
      {
      "code":"400707",
      "display":"บ้านขาม"
      },
      {
      "code":"400711",
      "display":"พังทุย"
      },
      {
      "code":"400706",
      "display":"ม่วงหวาน"
      },
      {
      "code":"400702",
      "display":"วังชัย"
      },
      {
      "code":"400705",
      "display":"สะอาด"
      },
      {
      "code":"400703",
      "display":"หนองกุง"
      }
      ],
      "code":"4007",
      "display":"น้ำพอง"
      },
      {
      "districts":[
      {
      "code":"400206",
      "display":"บ้านฝาง"
      },
      {
      "code":"400204",
      "display":"บ้านเหล่า"
      },
      {
      "code":"400205",
      "display":"ป่ามะนาว"
      },
      {
      "code":"400202",
      "display":"ป่าหวายนั่ง"
      },
      {
      "code":"400199",
      "display":"หนองบัว"
      },
      {
      "code":"400207",
      "display":"โคกงาม"
      },
      {
      "code":"400203",
      "display":"โนนฆ้อง"
      }
      ],
      "code":"4002",
      "display":"บ้านฝาง"
      },
      {
      "districts":[
      {
      "code":"401003",
      "display":"บ้านแฮด"
      },
      {
      "code":"401012",
      "display":"หนองแซง"
      },
      {
      "code":"401004",
      "display":"โคกสำราญ"
      },
      {
      "code":"401015",
      "display":"โนนสมบูรณ์"
      }
      ],
      "code":"4024",
      "display":"บ้านแฮด"
      },
      {
      "districts":[
      {
      "code":"401009",
      "display":"บ้านลาน"
      },
      {
      "code":"401001",
      "display":"บ้านไผ่"
      },
      {
      "code":"401013",
      "display":"ป่าปอ"
      },
      {
      "code":"401011",
      "display":"ภูเหล็ก"
      },
      {
      "code":"401016",
      "display":"หนองน้ำใส"
      },
      {
      "code":"401017",
      "display":"หัวหนอง"
      },
      {
      "code":"401014",
      "display":"หินตั้ง"
      },
      {
      "code":"401005",
      "display":"เมืองเพีย"
      },
      {
      "code":"401010",
      "display":"แคนเหนือ"
      },
      {
      "code":"400101",
      "display":"ในเมือง"
      }
      ],
      "code":"4010",
      "display":"บ้านไผ่"
      },
      {
      "districts":[
      {
      "code":"400305",
      "display":"ขามป้อม"
      },
      {
      "code":"400198",
      "display":"บ้านโต้น"
      },
      {
      "code":"400302",
      "display":"พระบุ"
      },
      {
      "code":"400301",
      "display":"พระยืน"
      },
      {
      "code":"400304",
      "display":"หนองแวง"
      }
      ],
      "code":"4003",
      "display":"พระยืน"
      },
      {
      "districts":[
      {
      "code":"401210",
      "display":"ลอมคอม"
      },
      {
      "code":"401205",
      "display":"หนองมะเขือ"
      },
      {
      "code":"401209",
      "display":"หนองแวงนางเบ้า"
      },
      {
      "code":"401206",
      "display":"หนองแวงโสกพระ"
      },
      {
      "code":"401213",
      "display":"หัวทุ่ง"
      },
      {
      "code":"401204",
      "display":"เก่างิ้ว"
      },
      {
      "code":"401207",
      "display":"เพ็กใหญ่"
      },
      {
      "code":"401201",
      "display":"เมืองพล"
      },
      {
      "code":"401208",
      "display":"โคกสง่า"
      },
      {
      "code":"401203",
      "display":"โจดหนองแก"
      },
      {
      "code":"401211",
      "display":"โนนข่า"
      },
      {
      "code":"401212",
      "display":"โสกนกเต็น"
      }
      ],
      "code":"4012",
      "display":"พล"
      },
      {
      "districts":[
      {
      "code":"402002",
      "display":"นาฝาย"
      },
      {
      "code":"402003",
      "display":"ภูผาม่าน"
      },
      {
      "code":"402004",
      "display":"วังสวาบ"
      },
      {
      "code":"402005",
      "display":"ห้วยม่วง"
      },
      {
      "code":"402001",
      "display":"โนนคอม"
      }
      ],
      "code":"4020",
      "display":"ภูผาม่าน"
      },
      {
      "districts":[
      {
      "code":"401605",
      "display":"กุดขอนแก่น"
      },
      {
      "code":"401616",
      "display":"ดินดำ"
      },
      {
      "code":"401614",
      "display":"ทุ่งชมพู"
      },
      {
      "code":"401606",
      "display":"นาชุมแสง"
      },
      {
      "code":"401607",
      "display":"นาหว้า"
      },
      {
      "code":"401601",
      "display":"บ้านเรือ"
      },
      {
      "code":"401617",
      "display":"ภูเวียง"
      },
      {
      "code":"401613",
      "display":"สงเปือย"
      },
      {
      "code":"401610",
      "display":"หนองกุงธนสาร"
      },
      {
      "code":"401612",
      "display":"หนองกุงเซิน"
      },
      {
      "code":"401604",
      "display":"หว้าทอง"
      }
      ],
      "code":"4016",
      "display":"ภูเวียง"
      },
      {
      "districts":[
      {
      "code":"401608",
      "display":"เขาน้อย"
      },
      {
      "code":"401615",
      "display":"เมืองเก่าพัฒนา"
      },
      {
      "code":"400101",
      "display":"ในเมือง"
      }
      ],
      "code":"4029",
      "display":"เวียงเก่า"
      },
      {
      "districts":[
      {
      "code":"401701",
      "display":"กุดเค้า"
      },
      {
      "code":"401705",
      "display":"คำแคน"
      },
      {
      "code":"401710",
      "display":"ท่าศาลา"
      },
      {
      "code":"401706",
      "display":"นาข่า"
      },
      {
      "code":"401707",
      "display":"นางาม"
      },
      {
      "code":"401702",
      "display":"สวนหม่อน"
      },
      {
      "code":"401703",
      "display":"หนองแปน"
      },
      {
      "code":"401704",
      "display":"โพนเพ็ก"
      }
      ],
      "code":"4017",
      "display":"มัญจาคีรี"
      },
      {
      "districts":[
      {
      "code":"400605",
      "display":"ซำยาง"
      },
      {
      "code":"400607",
      "display":"ดงลาน"
      },
      {
      "code":"400603",
      "display":"นาจาน"
      },
      {
      "code":"400608",
      "display":"บริบูรณ์"
      },
      {
      "code":"400609",
      "display":"บ้านใหม่"
      },
      {
      "code":"400610",
      "display":"ภูห่าน"
      },
      {
      "code":"400604",
      "display":"วังเพิ่ม"
      },
      {
      "code":"400602",
      "display":"ศรีสุข"
      },
      {
      "code":"400601",
      "display":"สีชมพู"
      },
      {
      "code":"400606",
      "display":"หนองแดง"
      }
      ],
      "code":"4006",
      "display":"สีชมพู"
      },
      {
      "districts":[
      {
      "code":"401609",
      "display":"กุดธาตุ"
      },
      {
      "code":"401611",
      "display":"ขนวน"
      },
      {
      "code":"401603",
      "display":"บ้านโคก"
      }
      ],
      "code":"4023",
      "display":"หนองนาคำ"
      },
      {
      "districts":[
      {
      "code":"401502",
      "display":"คึมชาด"
      },
      {
      "code":"401508",
      "display":"ดงเค็ง"
      },
      {
      "code":"401510",
      "display":"ดอนดั่ง"
      },
      {
      "code":"401507",
      "display":"ดอนดู่"
      },
      {
      "code":"401504",
      "display":"ตะกั่วป่า"
      },
      {
      "code":"401511",
      "display":"วังหิน"
      },
      {
      "code":"401505",
      "display":"สำโรง"
      },
      {
      "code":"401501",
      "display":"หนองสองห้อง"
      },
      {
      "code":"401506",
      "display":"หนองเม็ก"
      },
      {
      "code":"401512",
      "display":"หนองไผ่ล้อม"
      },
      {
      "code":"401509",
      "display":"หันโจด"
      },
      {
      "code":"401503",
      "display":"โนนธาตุ"
      }
      ],
      "code":"4015",
      "display":"หนองสองห้อง"
      },
      {
      "districts":[
      {
      "code":"400407",
      "display":"กุดกว้าง"
      },
      {
      "code":"400405",
      "display":"จระเข้"
      },
      {
      "code":"400403",
      "display":"บ้านกง"
      },
      {
      "code":"400410",
      "display":"บ้านผือ"
      },
      {
      "code":"400402",
      "display":"บ้านเม็ง"
      },
      {
      "code":"400404",
      "display":"ยางคำ"
      },
      {
      "code":"400401",
      "display":"หนองเรือ"
      },
      {
      "code":"400406",
      "display":"โนนทอง"
      },
      {
      "code":"400408",
      "display":"โนนทัน"
      },
      {
      "code":"400409",
      "display":"โนนสะอาด"
      }
      ],
      "code":"4004",
      "display":"หนองเรือ"
      },
      {
      "districts":[
      {
      "code":"400806",
      "display":"ทุ่งโป่ง"
      },
      {
      "code":"400804",
      "display":"นาคำ"
      },
      {
      "code":"400802",
      "display":"บ้านดง"
      },
      {
      "code":"400805",
      "display":"ศรีสุขสำราญ"
      },
      {
      "code":"400803",
      "display":"เขื่อนอุบลรัตน์"
      },
      {
      "code":"400801",
      "display":"โคกสูง"
      }
      ],
      "code":"4008",
      "display":"อุบลรัตน์"
      },
      {
      "districts":[
      {
      "code":"401905",
      "display":"คำม่วง"
      },
      {
      "code":"401902",
      "display":"ดงเมืองแอม"
      },
      {
      "code":"401903",
      "display":"นางิ้ว"
      },
      {
      "code":"401901",
      "display":"เขาสวนกวาง"
      },
      {
      "code":"401015",
      "display":"โนนสมบูรณ์"
      }
      ],
      "code":"4019",
      "display":"เขาสวนกวาง"
      },
      {
      "districts":[
      {
      "code":"400305",
      "display":"ขามป้อม"
      },
      {
      "code":"401098",
      "display":"วังม่วง"
      },
      {
      "code":"401096",
      "display":"สระแก้ว"
      },
      {
      "code":"401099",
      "display":"เปือยน้อย"
      }
      ],
      "code":"4011",
      "display":"เปือยน้อย"
      },
      {
      "districts":[
      {
      "code":"400112",
      "display":"ดอนช้าง"
      },
      {
      "code":"400113",
      "display":"ดอนหัน"
      },
      {
      "code":"400104",
      "display":"ท่าพระ"
      },
      {
      "code":"400117",
      "display":"บึงเนียม"
      },
      {
      "code":"400110",
      "display":"บ้านค้อ"
      },
      {
      "code":"400105",
      "display":"บ้านทุ่ม"
      },
      {
      "code":"400109",
      "display":"บ้านหว้า"
      },
      {
      "code":"400115",
      "display":"บ้านเป็ด"
      },
      {
      "code":"400107",
      "display":"พระลับ"
      },
      {
      "code":"400114",
      "display":"ศิลา"
      },
      {
      "code":"400108",
      "display":"สาวะถี"
      },
      {
      "code":"400102",
      "display":"สำราญ"
      },
      {
      "code":"400116",
      "display":"หนองตูม"
      },
      {
      "code":"400106",
      "display":"เมืองเก่า"
      },
      {
      "code":"400111",
      "display":"แดงใหญ่"
      },
      {
      "code":"400103",
      "display":"โคกสี"
      },
      {
      "code":"400118",
      "display":"โนนท่อน"
      },
      {
      "code":"400101",
      "display":"ในเมือง"
      }
      ],
      "code":"4001",
      "display":"เมืองขอนแก่น"
      },
      {
      "districts":[
      {
      "code":"401294",
      "display":"ก้านเหลือง"
      },
      {
      "code":"401290",
      "display":"ทางขวาง"
      },
      {
      "code":"401403",
      "display":"ท่านางแนว"
      },
      {
      "code":"401291",
      "display":"ท่าวัด"
      },
      {
      "code":"401404",
      "display":"ละหานนา"
      },
      {
      "code":"401293",
      "display":"แวงน้อย"
      }
      ],
      "code":"4014",
      "display":"แวงน้อย"
      },
      {
      "districts":[
      {
      "code":"401299",
      "display":"คอนฉิม"
      },
      {
      "code":"401296",
      "display":"แวงใหญ่"
      },
      {
      "code":"400406",
      "display":"โนนทอง"
      },
      {
      "code":"400409",
      "display":"โนนสะอาด"
      },
      {
      "code":"401298",
      "display":"ใหม่นาเพียง"
      }
      ],
      "code":"4013",
      "display":"แวงใหญ่"
      },
      {
      "districts":[
      {
      "code":"401711",
      "display":"ซับสมบูรณ์"
      },
      {
      "code":"401712",
      "display":"นาแพง"
      },
      {
      "code":"401603",
      "display":"บ้านโคก"
      },
      {
      "code":"401709",
      "display":"โพธิ์ไชย"
      }
      ],
      "code":"4022",
      "display":"โคกโพธิ์ไชย"
      },
      {
      "districts":[
      {
      "code":"401008",
      "display":"บ้านหัน"
      },
      {
      "code":"401020",
      "display":"หนองปลาหมอ"
      },
      {
      "code":"401006",
      "display":"เปือยใหญ่"
      },
      {
      "code":"401007",
      "display":"โนนศิลา"
      },
      {
      "code":"401019",
      "display":"โนนแดง"
      }
      ],
      "code":"4025",
      "display":"โนนศิลา"
      }
      ],
      "display":"ขอนแก่น"
      },
      "จันทบุรี":{
      "sort_order":"7",
      "code":"22",
      "amphoe":[
      {
      "districts":[
      {
      "code":"220201",
      "display":"ขลุง"
      },
      {
      "code":"220207",
      "display":"ซึ้ง"
      },
      {
      "code":"220211",
      "display":"ตกพรม"
      },
      {
      "code":"220210",
      "display":"ตรอกนอง"
      },
      {
      "code":"220204",
      "display":"ตะปอน"
      },
      {
      "code":"220205",
      "display":"บางชัน"
      },
      {
      "code":"220202",
      "display":"บ่อ"
      },
      {
      "code":"220212",
      "display":"บ่อเวฬุ"
      },
      {
      "code":"220208",
      "display":"มาบไพ"
      },
      {
      "code":"220209",
      "display":"วังสรรพรส"
      },
      {
      "code":"220206",
      "display":"วันยาว"
      },
      {
      "code":"220203",
      "display":"เกวียนหัก"
      }
      ],
      "code":"2202",
      "display":"ขลุง"
      },
      {
      "districts":[
      {
      "code":"220314",
      "display":"คลองขุด"
      },
      {
      "code":"220313",
      "display":"ตะกาดเง้า"
      },
      {
      "code":"220309",
      "display":"ทุ่งเบญจา"
      },
      {
      "code":"220301",
      "display":"ท่าใหม่"
      },
      {
      "code":"220304",
      "display":"บ่อพุ"
      },
      {
      "code":"220305",
      "display":"พลอยแหวน"
      },
      {
      "code":"220302",
      "display":"ยายร้า"
      },
      {
      "code":"220311",
      "display":"รำพัน"
      },
      {
      "code":"220308",
      "display":"สองพี่น้อง"
      },
      {
      "code":"220303",
      "display":"สีพยา"
      },
      {
      "code":"220307",
      "display":"เขาบายศรี"
      },
      {
      "code":"220306",
      "display":"เขาวัว"
      },
      {
      "code":"220324",
      "display":"เขาแก้ว"
      },
      {
      "code":"220312",
      "display":"โขมง"
      }
      ],
      "code":"2203",
      "display":"ท่าใหม่"
      },
      {
      "districts":[
      {
      "code":"220315",
      "display":"กระแจะ"
      },
      {
      "code":"220317",
      "display":"ช้างข้าม"
      },
      {
      "code":"220318",
      "display":"นายายอาม"
      },
      {
      "code":"220310",
      "display":"วังโตนด"
      },
      {
      "code":"220906",
      "display":"วังใหม่"
      },
      {
      "code":"220316",
      "display":"สนามไชย"
      }
      ],
      "code":"2209",
      "display":"นายายอาม"
      },
      {
      "districts":[
      {
      "code":"220506",
      "display":"ฉมัน"
      },
      {
      "code":"220502",
      "display":"ท่าหลวง"
      },
      {
      "code":"220503",
      "display":"ปัถวี"
      },
      {
      "code":"220501",
      "display":"มะขาม"
      },
      {
      "code":"220504",
      "display":"วังแซ้ม"
      },
      {
      "code":"220508",
      "display":"อ่างคีรี"
      }
      ],
      "code":"2205",
      "display":"มะขาม"
      },
      {
      "districts":[
      {
      "code":"220403",
      "display":"ทรายขาว"
      },
      {
      "code":"220408",
      "display":"ทับช้าง"
      },
      {
      "code":"220406",
      "display":"ทุ่งขนาน"
      },
      {
      "code":"220405",
      "display":"ปะตง"
      },
      {
      "code":"220407",
      "display":"สะตอน"
      }
      ],
      "code":"2207",
      "display":"สอยดาว"
      },
      {
      "districts":[
      {
      "code":"220509",
      "display":"คลองพลู"
      },
      {
      "code":"221005",
      "display":"จันทเขลม"
      },
      {
      "code":"221001",
      "display":"ชากไทย"
      },
      {
      "code":"220507",
      "display":"ตะเคียนทอง"
      },
      {
      "code":"220505",
      "display":"พลวง"
      }
      ],
      "code":"2210",
      "display":"เขาคิชฌกูฏ"
      },
      {
      "districts":[
      {
      "code":"220105",
      "display":"คมบาง"
      },
      {
      "code":"220103",
      "display":"คลองนารายณ์"
      },
      {
      "code":"220107",
      "display":"จันทนิมิต"
      },
      {
      "code":"220101",
      "display":"ตลาด"
      },
      {
      "code":"220106",
      "display":"ท่าช้าง"
      },
      {
      "code":"220108",
      "display":"บางกะจะ"
      },
      {
      "code":"220111",
      "display":"พลับพลา"
      },
      {
      "code":"220102",
      "display":"วัดใหม่"
      },
      {
      "code":"220110",
      "display":"หนองบัว"
      },
      {
      "code":"220104",
      "display":"เกาะขวาง"
      },
      {
      "code":"220109",
      "display":"แสลง"
      }
      ],
      "code":"2201",
      "display":"เมืองจันทบุรี"
      },
      {
      "districts":[
      {
      "code":"220323",
      "display":"ขุนซ่อง"
      },
      {
      "code":"220322",
      "display":"พวา"
      },
      {
      "code":"220320",
      "display":"สามพี่น้อง"
      },
      {
      "code":"220321",
      "display":"เขาวงกต"
      },
      {
      "code":"220319",
      "display":"แก่งหางแมว"
      }
      ],
      "code":"2208",
      "display":"แก่งหางแมว"
      },
      {
      "districts":[
      {
      "code":"220605",
      "display":"คลองน้ำเค็ม"
      },
      {
      "code":"220607",
      "display":"บางกะไชย"
      },
      {
      "code":"220606",
      "display":"บางสระเก้า"
      },
      {
      "code":"220601",
      "display":"ปากน้ำแหลมสิงห์"
      },
      {
      "code":"220604",
      "display":"พลิ้ว"
      },
      {
      "code":"220603",
      "display":"หนองชิ่ม"
      },
      {
      "code":"220602",
      "display":"เกาะเปริด"
      }
      ],
      "code":"2206",
      "display":"แหลมสิงห์"
      },
      {
      "districts":[
      {
      "code":"220410",
      "display":"คลองใหญ่"
      },
      {
      "code":"220401",
      "display":"ทับไทร"
      },
      {
      "code":"220404",
      "display":"หนองตาคง"
      },
      {
      "code":"220409",
      "display":"เทพนิมิต"
      },
      {
      "code":"220402",
      "display":"โป่งน้ำร้อน"
      }
      ],
      "code":"2204",
      "display":"โป่งน้ำร้อน"
      }
      ],
      "display":"จันทบุรี"
      },
      "ฉะเชิงเทรา":{
      "sort_order":"8",
      "code":"24",
      "amphoe":[
      {
      "districts":[
      {
      "code":"240202",
      "display":"ก้อนแก้ว"
      },
      {
      "code":"240203",
      "display":"คลองเขื่อน"
      },
      {
      "code":"240207",
      "display":"บางตลาด"
      },
      {
      "code":"240205",
      "display":"บางเล่า"
      },
      {
      "code":"240206",
      "display":"บางโรง"
      }
      ],
      "code":"2411",
      "display":"คลองเขื่อน"
      },
      {
      "districts":[
      {
      "code":"240806",
      "display":"คลองตะเกรา"
      },
      {
      "code":"240804",
      "display":"ท่าตะเกียบ"
      }
      ],
      "code":"2410",
      "display":"ท่าตะเกียบ"
      },
      {
      "districts":[
      {
      "code":"240210",
      "display":"ท่าทองหลาง"
      },
      {
      "code":"240208",
      "display":"บางกระเจ็ด"
      },
      {
      "code":"240201",
      "display":"บางคล้า"
      },
      {
      "code":"240204",
      "display":"บางสวน"
      },
      {
      "code":"240209",
      "display":"ปากน้ำ"
      },
      {
      "code":"240211",
      "display":"สาวชะโงก"
      },
      {
      "code":"240214",
      "display":"หัวไทร"
      },
      {
      "code":"240212",
      "display":"เสม็ดเหนือ"
      },
      {
      "code":"240213",
      "display":"เสม็ดใต้"
      }
      ],
      "code":"2402",
      "display":"บางคล้า"
      },
      {
      "districts":[
      {
      "code":"240308",
      "display":"ดอนฉิมพลี"
      },
      {
      "code":"240306",
      "display":"ดอนเกาะกา"
      },
      {
      "code":"240302",
      "display":"บางขนาก"
      },
      {
      "code":"240301",
      "display":"บางน้ำเปรี้ยว"
      },
      {
      "code":"240305",
      "display":"บึงน้ำรักษ์"
      },
      {
      "code":"240309",
      "display":"ศาลาแดง"
      },
      {
      "code":"240303",
      "display":"สิงโตทอง"
      },
      {
      "code":"240304",
      "display":"หมอนทอง"
      },
      {
      "code":"240310",
      "display":"โพรงอากาศ"
      },
      {
      "code":"240307",
      "display":"โยธะกา"
      }
      ],
      "code":"2403",
      "display":"บางน้ำเปรี้ยว"
      },
      {
      "districts":[
      {
      "code":"240410",
      "display":"ท่าข้าม"
      },
      {
      "code":"240402",
      "display":"ท่าสะอ้าน"
      },
      {
      "code":"240401",
      "display":"บางปะกง"
      },
      {
      "code":"240405",
      "display":"บางผึ้ง"
      },
      {
      "code":"240403",
      "display":"บางวัว"
      },
      {
      "code":"240404",
      "display":"บางสมัคร"
      },
      {
      "code":"240406",
      "display":"บางเกลือ"
      },
      {
      "code":"240409",
      "display":"พิมพา"
      },
      {
      "code":"240407",
      "display":"สองคลอง"
      },
      {
      "code":"240408",
      "display":"หนองจอก"
      },
      {
      "code":"240411",
      "display":"หอมศีล"
      },
      {
      "code":"240412",
      "display":"เขาดิน"
      }
      ],
      "code":"2404",
      "display":"บางปะกง"
      },
      {
      "districts":[
      {
      "code":"240503",
      "display":"คลองขุด"
      },
      {
      "code":"240504",
      "display":"คลองบ้านโพธิ์"
      },
      {
      "code":"240505",
      "display":"คลองประเวศ"
      },
      {
      "code":"240506",
      "display":"ดอนทราย"
      },
      {
      "code":"240508",
      "display":"ท่าพลับ"
      },
      {
      "code":"240512",
      "display":"บางกรูด"
      },
      {
      "code":"240511",
      "display":"บางซ่อน"
      },
      {
      "code":"240501",
      "display":"บ้านโพธิ์"
      },
      {
      "code":"240514",
      "display":"ลาดขวาง"
      },
      {
      "code":"240515",
      "display":"สนามจันทร์"
      },
      {
      "code":"240517",
      "display":"สิบเอ็ดศอก"
      },
      {
      "code":"240509",
      "display":"หนองตีนนก"
      },
      {
      "code":"240510",
      "display":"หนองบัว"
      },
      {
      "code":"240502",
      "display":"เกาะไร่"
      },
      {
      "code":"240507",
      "display":"เทพราช"
      },
      {
      "code":"240516",
      "display":"แสนภูดาษ"
      },
      {
      "code":"240513",
      "display":"แหลมประดู่"
      }
      ],
      "code":"2405",
      "display":"บ้านโพธิ์"
      },
      {
      "districts":[
      {
      "code":"240606",
      "display":"ท่าถ่าน"
      },
      {
      "code":"240602",
      "display":"บ้านซ่อง"
      },
      {
      "code":"240603",
      "display":"พนมสารคาม"
      },
      {
      "code":"240605",
      "display":"หนองยาว"
      },
      {
      "code":"240607",
      "display":"หนองแหน"
      },
      {
      "code":"240601",
      "display":"เกาะขนุน"
      },
      {
      "code":"240608",
      "display":"เขาหินซ้อน"
      },
      {
      "code":"240604",
      "display":"เมืองเก่า"
      }
      ],
      "code":"2406",
      "display":"พนมสารคาม"
      },
      {
      "districts":[
      {
      "code":"240703",
      "display":"ดงน้อย"
      },
      {
      "code":"240701",
      "display":"บางคา"
      },
      {
      "code":"240702",
      "display":"เมืองใหม่"
      }
      ],
      "code":"2407",
      "display":"ราชสาส์น"
      },
      {
      "districts":[
      {
      "code":"240801",
      "display":"คู้ยายหมี"
      },
      {
      "code":"240803",
      "display":"ทุ่งพระยา"
      },
      {
      "code":"240802",
      "display":"ท่ากระดาน"
      },
      {
      "code":"240805",
      "display":"ลาดกระทิง"
      }
      ],
      "code":"2408",
      "display":"สนามชัยเขต"
      },
      {
      "districts":[
      {
      "code":"240107",
      "display":"คลองจุกกระเฌอ"
      },
      {
      "code":"240110",
      "display":"คลองนครเนื่องเขต"
      },
      {
      "code":"240104",
      "display":"คลองนา"
      },
      {
      "code":"240118",
      "display":"คลองหลวงแพ่ง"
      },
      {
      "code":"240117",
      "display":"คลองอุดมชลจร"
      },
      {
      "code":"240116",
      "display":"คลองเปรง"
      },
      {
      "code":"240102",
      "display":"ท่าไข่"
      },
      {
      "code":"240114",
      "display":"บางกะไห"
      },
      {
      "code":"240109",
      "display":"บางขวัญ"
      },
      {
      "code":"240105",
      "display":"บางตีนเป็ด"
      },
      {
      "code":"240113",
      "display":"บางพระ"
      },
      {
      "code":"240119",
      "display":"บางเตย"
      },
      {
      "code":"240108",
      "display":"บางแก้ว"
      },
      {
      "code":"240106",
      "display":"บางไผ่"
      },
      {
      "code":"240103",
      "display":"บ้านใหม่"
      },
      {
      "code":"240111",
      "display":"วังตะเคียน"
      },
      {
      "code":"240115",
      "display":"หนามแดง"
      },
      {
      "code":"240101",
      "display":"หน้าเมือง"
      },
      {
      "code":"240112",
      "display":"โสธร"
      }
      ],
      "code":"2401",
      "display":"เมืองฉะเชิงเทรา"
      },
      {
      "districts":[
      {
      "code":"240902",
      "display":"วังเย็น"
      },
      {
      "code":"240904",
      "display":"หนองไม้แก่น"
      },
      {
      "code":"240903",
      "display":"หัวสำโรง"
      },
      {
      "code":"240901",
      "display":"แปลงยาว"
      }
      ],
      "code":"2409",
      "display":"แปลงยาว"
      }
      ],
      "display":"ฉะเชิงเทรา"
      },
      "ชลบุรี":{
      "sort_order":"9",
      "code":"20",
      "amphoe":[
      {
      "districts":[
      {
      "code":"200407",
      "display":"ตะเคียนเตี้ย"
      },
      {
      "code":"200408",
      "display":"นาเกลือ"
      },
      {
      "code":"200401",
      "display":"บางละมุง"
      },
      {
      "code":"200402",
      "display":"หนองปรือ"
      },
      {
      "code":"200403",
      "display":"หนองปลาไหล"
      },
      {
      "code":"200406",
      "display":"ห้วยใหญ่"
      },
      {
      "code":"200405",
      "display":"เขาไม้แก้ว"
      },
      {
      "code":"200404",
      "display":"โป่ง"
      }
      ],
      "code":"2004",
      "display":"บางละมุง"
      },
      {
      "districts":[
      {
      "code":"201004",
      "display":"ธาตุทอง"
      },
      {
      "code":"200697",
      "display":"บ่อกวางทอง"
      },
      {
      "code":"200699",
      "display":"บ่อทอง"
      },
      {
      "code":"201006",
      "display":"พลวงทอง"
      },
      {
      "code":"200698",
      "display":"วัดสุวรรณ"
      },
      {
      "code":"201005",
      "display":"เกษตรสุวรรณ"
      }
      ],
      "code":"2010",
      "display":"บ่อทอง"
      },
      {
      "districts":[
      {
      "code":"200202",
      "display":"คลองกิ่ว"
      },
      {
      "code":"200201",
      "display":"บ้านบึง"
      },
      {
      "code":"200203",
      "display":"มาบไผ่"
      },
      {
      "code":"200206",
      "display":"หนองชาก"
      },
      {
      "code":"200204",
      "display":"หนองซ้ำซาก"
      },
      {
      "code":"200205",
      "display":"หนองบอนแดง"
      },
      {
      "code":"200207",
      "display":"หนองอิรุณ"
      },
      {
      "code":"200208",
      "display":"หนองไผ่แก้ว"
      }
      ],
      "code":"2002",
      "display":"บ้านบึง"
      },
      {
      "districts":[
      {
      "code":"200609",
      "display":"กุฎโง้ง"
      },
      {
      "code":"200615",
      "display":"ทุ่งขวาง"
      },
      {
      "code":"200611",
      "display":"ท่าข้าม"
      },
      {
      "code":"200622",
      "display":"นามะตูม"
      },
      {
      "code":"200617",
      "display":"นาวังหิน"
      },
      {
      "code":"200605",
      "display":"นาเริก"
      },
      {
      "code":"200618",
      "display":"บ้านช้าง"
      },
      {
      "code":"200604",
      "display":"บ้านเซิด"
      },
      {
      "code":"200601",
      "display":"พนัสนิคม"
      },
      {
      "code":"200603",
      "display":"วัดหลวง"
      },
      {
      "code":"200608",
      "display":"วัดโบสถ์"
      },
      {
      "code":"200607",
      "display":"สระสี่เหลี่ยม"
      },
      {
      "code":"200614",
      "display":"หนองขยาด"
      },
      {
      "code":"200402",
      "display":"หนองปรือ"
      },
      {
      "code":"200616",
      "display":"หนองเหียง"
      },
      {
      "code":"200602",
      "display":"หน้าพระธาตุ"
      },
      {
      "code":"200606",
      "display":"หมอนนาง"
      },
      {
      "code":"200610",
      "display":"หัวถนน"
      },
      {
      "code":"200620",
      "display":"โคกเพลาะ"
      },
      {
      "code":"200621",
      "display":"ไร่หลักทอง"
      }
      ],
      "code":"2006",
      "display":"พนัสนิคม"
      },
      {
      "districts":[
      {
      "code":"200509",
      "display":"บางนาง"
      },
      {
      "code":"200511",
      "display":"บางหัก"
      },
      {
      "code":"200507",
      "display":"บ้านเก่า"
      },
      {
      "code":"200501",
      "display":"พานทอง"
      },
      {
      "code":"200503",
      "display":"มาบโป่ง"
      },
      {
      "code":"200504",
      "display":"หนองกะขะ"
      },
      {
      "code":"200502",
      "display":"หนองตำลึง"
      },
      {
      "code":"200505",
      "display":"หนองหงษ์"
      },
      {
      "code":"200508",
      "display":"หน้าประดู่"
      },
      {
      "code":"200510",
      "display":"เกาะลอย"
      },
      {
      "code":"200506",
      "display":"โคกขี้หนอน"
      }
      ],
      "code":"2005",
      "display":"พานทอง"
      },
      {
      "districts":[
      {
      "code":"200703",
      "display":"ทุ่งสุขลา"
      },
      {
      "code":"200707",
      "display":"บางพระ"
      },
      {
      "code":"200704",
      "display":"บึง"
      },
      {
      "code":"200708",
      "display":"บ่อวิน"
      },
      {
      "code":"200701",
      "display":"ศรีราชา"
      },
      {
      "code":"200702",
      "display":"สุรศักดิ์"
      },
      {
      "code":"200705",
      "display":"หนองขาม"
      },
      {
      "code":"200706",
      "display":"เขาคันทรง"
      }
      ],
      "code":"2007",
      "display":"ศรีราชา"
      },
      {
      "districts":[
      {
      "code":"200902",
      "display":"นาจอมเทียน"
      },
      {
      "code":"200904",
      "display":"บางเสร่"
      },
      {
      "code":"200903",
      "display":"พลูตาหลวง"
      },
      {
      "code":"200901",
      "display":"สัตหีบ"
      },
      {
      "code":"200905",
      "display":"แสมสาร"
      }
      ],
      "code":"2009",
      "display":"สัตหีบ"
      },
      {
      "districts":[
      {
      "code":"200298",
      "display":"คลองพลู"
      },
      {
      "code":"200297",
      "display":"หนองเสือช้าง"
      },
      {
      "code":"200299",
      "display":"หนองใหญ่"
      },
      {
      "code":"200304",
      "display":"ห้างสูง"
      },
      {
      "code":"200305",
      "display":"เขาซก"
      }
      ],
      "code":"2003",
      "display":"หนองใหญ่"
      },
      {
      "districts":[
      {
      "code":"200612",
      "display":"ท่าบุญมี"
      },
      {
      "code":"200619",
      "display":"เกาะจันทร์"
      }
      ],
      "code":"2011",
      "display":"เกาะจันทร์"
      },
      {
      "districts":[
      {
      "code":"200799",
      "display":"ท่าเทววงษ์"
      }
      ],
      "code":"2008",
      "display":"เกาะสีชัง"
      },
      {
      "districts":[
      {
      "code":"200112",
      "display":"คลองตำหรุ"
      },
      {
      "code":"200109",
      "display":"ดอนหัวฬ่อ"
      },
      {
      "code":"200107",
      "display":"นาป่า"
      },
      {
      "code":"200111",
      "display":"บางทราย"
      },
      {
      "code":"200101",
      "display":"บางปลาสร้อย"
      },
      {
      "code":"200114",
      "display":"บ้านปึก"
      },
      {
      "code":"200105",
      "display":"บ้านสวน"
      },
      {
      "code":"200103",
      "display":"บ้านโขด"
      },
      {
      "code":"200102",
      "display":"มะขามหย่ง"
      },
      {
      "code":"200118",
      "display":"สำนักบก"
      },
      {
      "code":"200108",
      "display":"หนองข้างคอก"
      },
      {
      "code":"200106",
      "display":"หนองรี"
      },
      {
      "code":"200110",
      "display":"หนองไม้แดง"
      },
      {
      "code":"200115",
      "display":"ห้วยกะปิ"
      },
      {
      "code":"200117",
      "display":"อ่างศิลา"
      },
      {
      "code":"200116",
      "display":"เสม็ด"
      },
      {
      "code":"200113",
      "display":"เหมือง"
      },
      {
      "code":"200104",
      "display":"แสนสุข"
      }
      ],
      "code":"2001",
      "display":"เมืองชลบุรี"
      }
      ],
      "display":"ชลบุรี"
      },
      "ชัยนาท":{
      "sort_order":"10",
      "code":"18",
      "amphoe":[
      {
      "districts":[
      {
      "code":"180201",
      "display":"คุ้งสำเภา"
      },
      {
      "code":"180204",
      "display":"ท่าฉนวน"
      },
      {
      "code":"180202",
      "display":"วัดโคก"
      },
      {
      "code":"180203",
      "display":"ศิลาดาน"
      },
      {
      "code":"180205",
      "display":"หางน้ำสาคร"
      },
      {
      "code":"180207",
      "display":"อู่ตะเภา"
      },
      {
      "code":"180206",
      "display":"ไร่พัฒนา"
      }
      ],
      "code":"1802",
      "display":"มโนรมย์"
      },
      {
      "districts":[
      {
      "code":"180307",
      "display":"บ่อแร่"
      },
      {
      "code":"180302",
      "display":"มะขามเฒ่า"
      },
      {
      "code":"180311",
      "display":"วังหมัน"
      },
      {
      "code":"180301",
      "display":"วัดสิงห์"
      },
      {
      "code":"180306",
      "display":"หนองขุ่น"
      },
      {
      "code":"180303",
      "display":"หนองน้อย"
      },
      {
      "code":"180304",
      "display":"หนองบัว"
      }
      ],
      "code":"1803",
      "display":"วัดสิงห์"
      },
      {
      "districts":[
      {
      "code":"180506",
      "display":"ดงคอน"
      },
      {
      "code":"180507",
      "display":"ดอนกำ"
      },
      {
      "code":"180505",
      "display":"บางขุด"
      },
      {
      "code":"180503",
      "display":"ห้วยกรด"
      },
      {
      "code":"180508",
      "display":"ห้วยกรดพัฒนา"
      },
      {
      "code":"180502",
      "display":"เที่ยงแท้"
      },
      {
      "code":"180501",
      "display":"แพรกศรีราชา"
      },
      {
      "code":"180504",
      "display":"โพงาม"
      }
      ],
      "code":"1805",
      "display":"สรรคบุรี"
      },
      {
      "districts":[
      {
      "code":"180402",
      "display":"ตลุก"
      },
      {
      "code":"180406",
      "display":"บางหลวง"
      },
      {
      "code":"180401",
      "display":"สรรพยา"
      },
      {
      "code":"180407",
      "display":"หาดอาษา"
      },
      {
      "code":"180403",
      "display":"เขาแก้ว"
      },
      {
      "code":"180404",
      "display":"โพนางดำตก"
      },
      {
      "code":"180405",
      "display":"โพนางดำออก"
      }
      ],
      "code":"1804",
      "display":"สรรพยา"
      },
      {
      "districts":[
      {
      "code":"180308",
      "display":"กุดจอก"
      },
      {
      "code":"180309",
      "display":"วังตะเคียน"
      },
      {
      "code":"180310",
      "display":"สะพานหิน"
      },
      {
      "code":"180305",
      "display":"หนองมะโมง"
      }
      ],
      "code":"1807",
      "display":"หนองมะโมง"
      },
      {
      "districts":[
      {
      "code":"180602",
      "display":"บ้านเชี่ยน"
      },
      {
      "code":"180608",
      "display":"วังไก่เถื่อน"
      },
      {
      "code":"180611",
      "display":"สามง่ามท่าโบสถ์"
      },
      {
      "code":"180606",
      "display":"หนองแซง"
      },
      {
      "code":"180601",
      "display":"หันคา"
      },
      {
      "code":"180607",
      "display":"ห้วยงู"
      },
      {
      "code":"180609",
      "display":"เด่นใหญ่"
      },
      {
      "code":"180605",
      "display":"ไพรนกยูง"
      }
      ],
      "code":"1806",
      "display":"หันคา"
      },
      {
      "districts":[
      {
      "code":"180610",
      "display":"กะบกเตี้ย"
      },
      {
      "code":"180604",
      "display":"สุขเดือนห้า"
      },
      {
      "code":"180603",
      "display":"เนินขาม"
      }
      ],
      "code":"1808",
      "display":"เนินขาม"
      },
      {
      "districts":[
      {
      "code":"180104",
      "display":"ชัยนาท"
      },
      {
      "code":"180103",
      "display":"ท่าชัย"
      },
      {
      "code":"180107",
      "display":"ธรรมามูล"
      },
      {
      "code":"180109",
      "display":"นางลือ"
      },
      {
      "code":"180102",
      "display":"บ้านกล้วย"
      },
      {
      "code":"180106",
      "display":"หาดท่าเสา"
      },
      {
      "code":"180105",
      "display":"เขาท่าพระ"
      },
      {
      "code":"180108",
      "display":"เสือโฮก"
      },
      {
      "code":"180101",
      "display":"ในเมือง"
      }
      ],
      "code":"1801",
      "display":"เมืองชัยนาท"
      }
      ],
      "display":"ชัยนาท"
      },
      "ชัยภูมิ":{
      "sort_order":"11",
      "code":"36",
      "amphoe":[
      {
      "districts":[
      {
      "code":"360301",
      "display":"คอนสวรรค์"
      },
      {
      "code":"360303",
      "display":"ช่องสามหมอ"
      },
      {
      "code":"360306",
      "display":"บ้านโสก"
      },
      {
      "code":"360302",
      "display":"ยางหวาย"
      },
      {
      "code":"360309",
      "display":"ศรีสำราญ"
      },
      {
      "code":"360308",
      "display":"หนองขาม"
      },
      {
      "code":"360305",
      "display":"ห้วยไร่"
      },
      {
      "code":"360307",
      "display":"โคกมั่งงอย"
      },
      {
      "code":"360304",
      "display":"โนนสะอาด"
      }
      ],
      "code":"3603",
      "display":"คอนสวรรค์"
      },
      {
      "districts":[
      {
      "code":"361301",
      "display":"คอนสาร"
      },
      {
      "code":"361308",
      "display":"ดงกลาง"
      },
      {
      "code":"361306",
      "display":"ดงบัง"
      },
      {
      "code":"361307",
      "display":"ทุ่งนาเลา"
      },
      {
      "code":"361302",
      "display":"ทุ่งพระ"
      },
      {
      "code":"361305",
      "display":"ทุ่งลุยลาย"
      },
      {
      "code":"361304",
      "display":"ห้วยยาง"
      },
      {
      "code":"361303",
      "display":"โนนคูณ"
      }
      ],
      "code":"3613",
      "display":"คอนสาร"
      },
      {
      "districts":[
      {
      "code":"360605",
      "display":"กุดน้ำใส"
      },
      {
      "code":"360601",
      "display":"บ้านกอก"
      },
      {
      "code":"360603",
      "display":"บ้านขาม"
      },
      {
      "code":"360607",
      "display":"ละหาน"
      },
      {
      "code":"360613",
      "display":"ส้มป่อย"
      },
      {
      "code":"360602",
      "display":"หนองบัวบาน"
      },
      {
      "code":"360611",
      "display":"หนองบัวโคก"
      },
      {
      "code":"360610",
      "display":"หนองบัวใหญ่"
      },
      {
      "code":"360606",
      "display":"หนองโดน"
      }
      ],
      "code":"3606",
      "display":"จัตุรัส"
      },
      {
      "districts":[
      {
      "code":"360614",
      "display":"ซับใหญ่"
      },
      {
      "code":"360616",
      "display":"ตะโกทอง"
      },
      {
      "code":"360612",
      "display":"ท่ากูบ"
      }
      ],
      "code":"3616",
      "display":"ซับใหญ่"
      },
      {
      "districts":[
      {
      "code":"360701",
      "display":"บ้านชวน"
      },
      {
      "code":"360703",
      "display":"บ้านตาล"
      },
      {
      "code":"360702",
      "display":"บ้านเพชร"
      },
      {
      "code":"360704",
      "display":"หัวทะเล"
      },
      {
      "code":"360706",
      "display":"เกาะมะนาว"
      },
      {
      "code":"360707",
      "display":"โคกเพชรพัฒนา"
      },
      {
      "code":"360705",
      "display":"โคกเริงรมย์"
      }
      ],
      "code":"3607",
      "display":"บำเหน็จณรงค์"
      },
      {
      "districts":[
      {
      "code":"360204",
      "display":"ชีบน"
      },
      {
      "code":"360202",
      "display":"ตลาดแร้ง"
      },
      {
      "code":"360201",
      "display":"บ้านเขว้า"
      },
      {
      "code":"360205",
      "display":"ภูแลนคา"
      },
      {
      "code":"360203",
      "display":"ลุ่มลำชี"
      },
      {
      "code":"360206",
      "display":"โนนแดง"
      }
      ],
      "code":"3602",
      "display":"บ้านเขว้า"
      },
      {
      "districts":[
      {
      "code":"361104",
      "display":"บ้านเต่า"
      },
      {
      "code":"361101",
      "display":"บ้านแท่น"
      },
      {
      "code":"361103",
      "display":"สระพัง"
      },
      {
      "code":"361102",
      "display":"สามสวน"
      },
      {
      "code":"361105",
      "display":"หนองคู"
      }
      ],
      "code":"3611",
      "display":"บ้านแท่น"
      },
      {
      "districts":[
      {
      "code":"360199",
      "display":"บ้านเจียง"
      },
      {
      "code":"360510",
      "display":"วังทอง"
      },
      {
      "code":"360198",
      "display":"เจาทอง"
      },
      {
      "code":"361404",
      "display":"แหลมทอง"
      }
      ],
      "code":"3614",
      "display":"ภักดีชุมพล"
      },
      {
      "districts":[
      {
      "code":"361002",
      "display":"กวางโจน"
      },
      {
      "code":"361005",
      "display":"กุดยม"
      },
      {
      "code":"361010",
      "display":"ธาตุทอง"
      },
      {
      "code":"361011",
      "display":"บ้านดอน"
      },
      {
      "code":"360702",
      "display":"บ้านเพชร"
      },
      {
      "code":"361004",
      "display":"บ้านแก้ง"
      },
      {
      "code":"361001",
      "display":"ผักปัง"
      },
      {
      "code":"361003",
      "display":"หนองคอนไทย"
      },
      {
      "code":"361008",
      "display":"หนองตูม"
      },
      {
      "code":"360804",
      "display":"โคกสะอาด"
      },
      {
      "code":"361009",
      "display":"โอโล"
      }
      ],
      "code":"3610",
      "display":"ภูเขียว"
      },
      {
      "districts":[
      {
      "code":"360802",
      "display":"วังตะเฆ่"
      },
      {
      "code":"360801",
      "display":"หนองบัวระเหว"
      },
      {
      "code":"360803",
      "display":"ห้วยแย้"
      },
      {
      "code":"360804",
      "display":"โคกสะอาด"
      },
      {
      "code":"360805",
      "display":"โสกปลาดุก"
      }
      ],
      "code":"3608",
      "display":"หนองบัวระเหว"
      },
      {
      "districts":[
      {
      "code":"360502",
      "display":"กุดชุมแสง"
      },
      {
      "code":"360508",
      "display":"คูเมือง"
      },
      {
      "code":"360503",
      "display":"ถ้ำวัวแดง"
      },
      {
      "code":"360509",
      "display":"ท่าใหญ่"
      },
      {
      "code":"360504",
      "display":"นางแดด"
      },
      {
      "code":"360511",
      "display":"วังชมภู"
      },
      {
      "code":"360501",
      "display":"หนองบัวแดง"
      },
      {
      "code":"360507",
      "display":"หนองแวง"
      }
      ],
      "code":"3605",
      "display":"หนองบัวแดง"
      },
      {
      "districts":[
      {
      "code":"360405",
      "display":"กุดเลาะ"
      },
      {
      "code":"360410",
      "display":"บ้านบัว"
      },
      {
      "code":"360401",
      "display":"บ้านยาง"
      },
      {
      "code":"360402",
      "display":"บ้านหัน"
      },
      {
      "code":"360403",
      "display":"บ้านเดื่อ"
      },
      {
      "code":"360404",
      "display":"บ้านเป้า"
      },
      {
      "code":"360407",
      "display":"สระโพนทอง"
      },
      {
      "code":"360408",
      "display":"หนองข่า"
      },
      {
      "code":"360409",
      "display":"หนองโพนงาม"
      },
      {
      "code":"360406",
      "display":"โนนกอก"
      },
      {
      "code":"360412",
      "display":"โนนทอง"
      }
      ],
      "code":"3604",
      "display":"เกษตรสมบูรณ์"
      },
      {
      "districts":[
      {
      "code":"360797",
      "display":"นายางกลัก"
      },
      {
      "code":"360796",
      "display":"บ้านไร่"
      },
      {
      "code":"360799",
      "display":"วะตะแบก"
      },
      {
      "code":"360798",
      "display":"ห้วยยายจิ๋ว"
      },
      {
      "code":"360905",
      "display":"โป่งนก"
      }
      ],
      "code":"3609",
      "display":"เทพสถิต"
      },
      {
      "districts":[
      {
      "code":"360609",
      "display":"กะฮาด"
      },
      {
      "code":"360608",
      "display":"ตาเนิน"
      },
      {
      "code":"360615",
      "display":"รังงาม"
      },
      {
      "code":"360604",
      "display":"หนองฉิม"
      }
      ],
      "code":"3615",
      "display":"เนินสง่า"
      },
      {
      "districts":[
      {
      "code":"360106",
      "display":"กุดตุ้ม"
      },
      {
      "code":"360107",
      "display":"ชีลอง"
      },
      {
      "code":"360119",
      "display":"ซับสีทอง"
      },
      {
      "code":"360113",
      "display":"ท่าหินโงม"
      },
      {
      "code":"360104",
      "display":"นาฝาย"
      },
      {
      "code":"360109",
      "display":"นาเสียว"
      },
      {
      "code":"360118",
      "display":"บุ่งคล้า"
      },
      {
      "code":"360105",
      "display":"บ้านค่าย"
      },
      {
      "code":"360108",
      "display":"บ้านเล่า"
      },
      {
      "code":"360102",
      "display":"รอบเมือง"
      },
      {
      "code":"360111",
      "display":"ลาดใหญ่"
      },
      {
      "code":"360110",
      "display":"หนองนาแซง"
      },
      {
      "code":"360112",
      "display":"หนองไผ่"
      },
      {
      "code":"360114",
      "display":"ห้วยต้อน"
      },
      {
      "code":"360115",
      "display":"ห้วยบง"
      },
      {
      "code":"360117",
      "display":"โคกสูง"
      },
      {
      "code":"360116",
      "display":"โนนสำราญ"
      },
      {
      "code":"360103",
      "display":"โพนทอง"
      },
      {
      "code":"360101",
      "display":"ในเมือง"
      }
      ],
      "code":"3601",
      "display":"เมืองชัยภูมิ"
      },
      {
      "districts":[
      {
      "code":"360303",
      "display":"ช่องสามหมอ"
      },
      {
      "code":"361209",
      "display":"ท่ามะไฟหวาน"
      },
      {
      "code":"361203",
      "display":"นาหนองทุ่ม"
      },
      {
      "code":"361004",
      "display":"บ้านแก้ง"
      },
      {
      "code":"360308",
      "display":"หนองขาม"
      },
      {
      "code":"361205",
      "display":"หนองสังข์"
      },
      {
      "code":"360112",
      "display":"หนองไผ่"
      },
      {
      "code":"361206",
      "display":"หลุบคา"
      },
      {
      "code":"361208",
      "display":"เก่าย่าดี"
      },
      {
      "code":"361207",
      "display":"โคกกุง"
      }
      ],
      "code":"3612",
      "display":"แก้งคร้อ"
      }
      ],
      "display":"ชัยภูมิ"
      },
      "ชุมพร":{
      "sort_order":"12",
      "code":"86",
      "amphoe":[
      {
      "districts":[
      {
      "code":"860804",
      "display":"ช่องไม้แก้ว"
      },
      {
      "code":"860803",
      "display":"ตะโก"
      },
      {
      "code":"860802",
      "display":"ทุ่งตะไคร"
      },
      {
      "code":"860801",
      "display":"ปากตะโก"
      },
      {
      "code":"FALSE",
      "display":"หมู่เกาะศรีอบยา"
      },
      {
      "code":"FALSE",
      "display":"เกาะขี้นก"
      },
      {
      "code":"FALSE",
      "display":"เกาะคราม"
      },
      {
      "code":"FALSE",
      "display":"เกาะคางสือ"
      },
      {
      "code":"FALSE",
      "display":"เกาะมะพร้าว"
      },
      {
      "code":"FALSE",
      "display":"เกาะมุก"
      },
      {
      "code":"FALSE",
      "display":"เกาะยอ"
      },
      {
      "code":"FALSE",
      "display":"เกาะรางบรรทัด"
      },
      {
      "code":"FALSE",
      "display":"เกาะสูบ"
      }
      ],
      "code":"8608",
      "display":"ทุ่งตะโก"
      },
      {
      "districts":[
      {
      "code":"860202",
      "display":"คุริง"
      },
      {
      "code":"860209",
      "display":"ทรัพย์อนันต์"
      },
      {
      "code":"860206",
      "display":"ท่าข้าม"
      },
      {
      "code":"860201",
      "display":"ท่าแซะ"
      },
      {
      "code":"860204",
      "display":"นากระตาม"
      },
      {
      "code":"860205",
      "display":"รับร่อ"
      },
      {
      "code":"860203",
      "display":"สลุย"
      },
      {
      "code":"860210",
      "display":"สองพี่น้อง"
      },
      {
      "code":"860207",
      "display":"หงษ์เจริญ"
      },
      {
      "code":"860208",
      "display":"หินแก้ว"
      }
      ],
      "code":"8602",
      "display":"ท่าแซะ"
      },
      {
      "districts":[
      {
      "code":"860304",
      "display":"ชุมโค"
      },
      {
      "code":"860305",
      "display":"ดอนยาง"
      },
      {
      "code":"860302",
      "display":"ทะเลทรัพย์"
      },
      {
      "code":"860301",
      "display":"บางสน"
      },
      {
      "code":"860306",
      "display":"ปากคลอง"
      },
      {
      "code":"860303",
      "display":"สะพลี"
      },
      {
      "code":"FALSE",
      "display":"เกาะซีกง"
      },
      {
      "code":"FALSE",
      "display":"เกาะพระ"
      },
      {
      "code":"FALSE",
      "display":"เกาะยอ"
      },
      {
      "code":"FALSE",
      "display":"เกาะรัง"
      },
      {
      "code":"FALSE",
      "display":"เกาะร้านเป็ด"
      },
      {
      "code":"FALSE",
      "display":"เกาะร้านไก่"
      },
      {
      "code":"FALSE",
      "display":"เกาะเอียง"
      },
      {
      "code":"FALSE",
      "display":"เกาะไข่"
      },
      {
      "code":"860307",
      "display":"เขาไชยราช"
      }
      ],
      "code":"8603",
      "display":"ปะทิว"
      },
      {
      "districts":[
      {
      "code":"860603",
      "display":"ปังหวาน"
      },
      {
      "code":"860602",
      "display":"ปากทรง"
      },
      {
      "code":"860604",
      "display":"พระรักษ์"
      },
      {
      "code":"860601",
      "display":"พะโต๊ะ"
      }
      ],
      "code":"8606",
      "display":"พะโต๊ะ"
      },
      {
      "districts":[
      {
      "code":"860504",
      "display":"ทุ่งคาวัด"
      },
      {
      "code":"860502",
      "display":"ทุ่งหลวง"
      },
      {
      "code":"860501",
      "display":"ละแม"
      },
      {
      "code":"860503",
      "display":"สวนแตง"
      }
      ],
      "code":"8605",
      "display":"ละแม"
      },
      {
      "districts":[
      {
      "code":"860707",
      "display":"ครน"
      },
      {
      "code":"860706",
      "display":"ด่านสวี"
      },
      {
      "code":"860703",
      "display":"ทุ่งระยะ"
      },
      {
      "code":"860704",
      "display":"ท่าหิน"
      },
      {
      "code":"860709",
      "display":"นาสัก"
      },
      {
      "code":"860701",
      "display":"นาโพธิ์"
      },
      {
      "code":"860705",
      "display":"ปากแพรก"
      },
      {
      "code":"860708",
      "display":"วิสัยใต้"
      },
      {
      "code":"860702",
      "display":"สวี"
      },
      {
      "code":"FALSE",
      "display":"เกาะกระ"
      },
      {
      "code":"FALSE",
      "display":"เกาะกุลา"
      },
      {
      "code":"FALSE",
      "display":"เกาะทองแก้ว"
      },
      {
      "code":"FALSE",
      "display":"เกาะบาตร์"
      },
      {
      "code":"FALSE",
      "display":"เกาะพัดหวายเล็ก"
      },
      {
      "code":"FALSE",
      "display":"เกาะพัดหวายใหญ่"
      },
      {
      "code":"FALSE",
      "display":"เกาะยูง"
      },
      {
      "code":"FALSE",
      "display":"เกาะหนู"
      },
      {
      "code":"FALSE",
      "display":"เกาะแมว"
      },
      {
      "code":"FALSE",
      "display":"เกาะแรด"
      },
      {
      "code":"860711",
      "display":"เขาค่าย"
      },
      {
      "code":"860710",
      "display":"เขาทะลุ"
      }
      ],
      "code":"8607",
      "display":"สวี"
      },
      {
      "districts":[
      {
      "code":"860402",
      "display":"ขันเงิน"
      },
      {
      "code":"860403",
      "display":"ท่ามะพลา"
      },
      {
      "code":"860404",
      "display":"นาขา"
      },
      {
      "code":"860405",
      "display":"นาพญา"
      },
      {
      "code":"860408",
      "display":"บางน้ำจืด"
      },
      {
      "code":"860407",
      "display":"บางมะพร้าว"
      },
      {
      "code":"860406",
      "display":"บ้านควน"
      },
      {
      "code":"860102",
      "display":"ปากน้ำ"
      },
      {
      "code":"860410",
      "display":"พ้อแดง"
      },
      {
      "code":"860412",
      "display":"วังตะกอ"
      },
      {
      "code":"860401",
      "display":"หลังสวน"
      },
      {
      "code":"860413",
      "display":"หาดยาย"
      },
      {
      "code":"FALSE",
      "display":"เกาะพิทักษ์"
      },
      {
      "code":"860411",
      "display":"แหลมทราย"
      }
      ],
      "code":"8604",
      "display":"หลังสวน"
      },
      {
      "districts":[
      {
      "code":"860113",
      "display":"ขุนกระทิง"
      },
      {
      "code":"860107",
      "display":"ตากแดด"
      },
      {
      "code":"860117",
      "display":"ถ้ำสิงห์"
      },
      {
      "code":"860114",
      "display":"ทุ่งคา"
      },
      {
      "code":"860101",
      "display":"ท่าตะเภา"
      },
      {
      "code":"860103",
      "display":"ท่ายาง"
      },
      {
      "code":"860106",
      "display":"นาชะอัง"
      },
      {
      "code":"860105",
      "display":"นาทุ่ง"
      },
      {
      "code":"860108",
      "display":"บางลึก"
      },
      {
      "code":"860104",
      "display":"บางหมาก"
      },
      {
      "code":"860112",
      "display":"บ้านนา"
      },
      {
      "code":"860102",
      "display":"ปากน้ำ"
      },
      {
      "code":"860111",
      "display":"วังใหม่"
      },
      {
      "code":"860110",
      "display":"วังไผ่"
      },
      {
      "code":"860115",
      "display":"วิสัยเหนือ"
      },
      {
      "code":"860116",
      "display":"หาดทรายรี"
      },
      {
      "code":"860109",
      "display":"หาดพันไกร"
      },
      {
      "code":"FALSE",
      "display":"เกาะกา"
      },
      {
      "code":"FALSE",
      "display":"เกาะกินหลักง้ำ"
      },
      {
      "code":"FALSE",
      "display":"เกาะงามเล็ก"
      },
      {
      "code":"FALSE",
      "display":"เกาะงามใหญ่"
      },
      {
      "code":"FALSE",
      "display":"เกาะทองหลาง"
      },
      {
      "code":"FALSE",
      "display":"เกาะทะลุ"
      },
      {
      "code":"FALSE",
      "display":"เกาะมะพร้าว"
      },
      {
      "code":"FALSE",
      "display":"เกาะมัตโพน"
      },
      {
      "code":"FALSE",
      "display":"เกาะมาตรา"
      },
      {
      "code":"FALSE",
      "display":"เกาะรังกาจิว"
      },
      {
      "code":"FALSE",
      "display":"เกาะละวะ"
      },
      {
      "code":"FALSE",
      "display":"เกาะสาก"
      },
      {
      "code":"FALSE",
      "display":"เกาะหลักแรด"
      },
      {
      "code":"FALSE",
      "display":"เกาะอีแรด"
      },
      {
      "code":"FALSE",
      "display":"เกาะเสม็ด"
      }
      ],
      "code":"8601",
      "display":"เมืองชุมพร"
      }
      ],
      "display":"ชุมพร"
      },
      "ตรัง":{
      "sort_order":"13",
      "code":"92",
      "amphoe":[
      {
      "districts":[
      {
      "code":"920201",
      "display":"กันตัง"
      },
      {
      "code":"920206",
      "display":"กันตังใต้"
      },
      {
      "code":"920214",
      "display":"คลองชีล้อม"
      },
      {
      "code":"920208",
      "display":"คลองลุ"
      },
      {
      "code":"920202",
      "display":"ควนธานี"
      },
      {
      "code":"920212",
      "display":"นาเกลือ"
      },
      {
      "code":"920211",
      "display":"บางสัก"
      },
      {
      "code":"920203",
      "display":"บางหมาก"
      },
      {
      "code":"920204",
      "display":"บางเป้า"
      },
      {
      "code":"920210",
      "display":"บ่อน้ำร้อน"
      },
      {
      "code":"920209",
      "display":"ย่านซื่อ"
      },
      {
      "code":"920205",
      "display":"วังวน"
      },
      {
      "code":"920213",
      "display":"เกาะลิบง"
      },
      {
      "code":"920207",
      "display":"โคกยาง"
      }
      ],
      "code":"9202",
      "display":"กันตัง"
      },
      {
      "districts":[
      {
      "code":"920121",
      "display":"ช่อง"
      },
      {
      "code":"920111",
      "display":"นาข้าวเสีย"
      },
      {
      "code":"920112",
      "display":"นาหมื่นศรี"
      },
      {
      "code":"920116",
      "display":"นาโยงเหนือ"
      },
      {
      "code":"920103",
      "display":"ละมอ"
      },
      {
      "code":"920102",
      "display":"โคกสะบ้า"
      }
      ],
      "code":"9208",
      "display":"นาโยง"
      },
      {
      "districts":[
      {
      "code":"920402",
      "display":"ทุ่งยาว"
      },
      {
      "code":"920401",
      "display":"ท่าข้าม"
      },
      {
      "code":"920412",
      "display":"ท่าพญา"
      },
      {
      "code":"920404",
      "display":"บางด้วน"
      },
      {
      "code":"920407",
      "display":"บ้านนา"
      },
      {
      "code":"920403",
      "display":"ปะเหลียน"
      },
      {
      "code":"920410",
      "display":"ลิพัง"
      },
      {
      "code":"920409",
      "display":"สุโสะ"
      },
      {
      "code":"920411",
      "display":"เกาะสุกร"
      },
      {
      "code":"920413",
      "display":"แหลมสอม"
      }
      ],
      "code":"9204",
      "display":"ปะเหลียน"
      },
      {
      "districts":[
      {
      "code":"920306",
      "display":"ทุ่งกระบือ"
      },
      {
      "code":"920307",
      "display":"ทุ่งค่าย"
      },
      {
      "code":"920303",
      "display":"นาชุมเห็ด"
      },
      {
      "code":"920301",
      "display":"ย่านตาขาว"
      },
      {
      "code":"920302",
      "display":"หนองบ่อ"
      },
      {
      "code":"920308",
      "display":"เกาะเปียะ"
      },
      {
      "code":"920305",
      "display":"โพรงจระเข้"
      },
      {
      "code":"920304",
      "display":"ในควน"
      }
      ],
      "code":"9203",
      "display":"ย่านตาขาว"
      },
      {
      "districts":[
      {
      "code":"920612",
      "display":"คลองปาง"
      },
      {
      "code":"920613",
      "display":"ควนเมา"
      },
      {
      "code":"920604",
      "display":"หนองบัว"
      },
      {
      "code":"920603",
      "display":"หนองปรือ"
      },
      {
      "code":"920618",
      "display":"เขาไพร"
      }
      ],
      "code":"9209",
      "display":"รัษฎา"
      },
      {
      "districts":[
      {
      "code":"920595",
      "display":"ท่าสะบ้า"
      },
      {
      "code":"920598",
      "display":"วังมะปราง"
      },
      {
      "code":"920705",
      "display":"วังมะปรางเหนือ"
      },
      {
      "code":"920597",
      "display":"อ่าวตง"
      },
      {
      "code":"920599",
      "display":"เขาวิเศษ"
      }
      ],
      "code":"9207",
      "display":"วังวิเศษ"
      },
      {
      "districts":[
      {
      "code":"920503",
      "display":"กะลาเส"
      },
      {
      "code":"920505",
      "display":"นาเมืองเพชร"
      },
      {
      "code":"920501",
      "display":"บ่อหิน"
      },
      {
      "code":"920502",
      "display":"เขาไม้แก้ว"
      },
      {
      "code":"920504",
      "display":"ไม้ฝาด"
      }
      ],
      "code":"9205",
      "display":"สิเกา"
      },
      {
      "districts":[
      {
      "code":"920406",
      "display":"ตะเสะ"
      },
      {
      "code":"920408",
      "display":"บ้าหวี"
      },
      {
      "code":"920405",
      "display":"หาดสำราญ"
      }
      ],
      "code":"9210",
      "display":"หาดสำราญ"
      },
      {
      "districts":[
      {
      "code":"920620",
      "display":"ทุ่งต่อ"
      },
      {
      "code":"920614",
      "display":"ท่างิ้ว"
      },
      {
      "code":"920616",
      "display":"นาวง"
      },
      {
      "code":"920606",
      "display":"บางกุ้ง"
      },
      {
      "code":"920605",
      "display":"บางดี"
      },
      {
      "code":"920611",
      "display":"ปากคม"
      },
      {
      "code":"920610",
      "display":"ปากแจ่ม"
      },
      {
      "code":"920615",
      "display":"ลำภูรา"
      },
      {
      "code":"920621",
      "display":"วังคีรี"
      },
      {
      "code":"920602",
      "display":"หนองช้างแล่น"
      },
      {
      "code":"920617",
      "display":"ห้วยนาง"
      },
      {
      "code":"920601",
      "display":"ห้วยยอด"
      },
      {
      "code":"920607",
      "display":"เขากอบ"
      },
      {
      "code":"920608",
      "display":"เขาขาว"
      },
      {
      "code":"920609",
      "display":"เขาปูน"
      },
      {
      "code":"920619",
      "display":"ในเตา"
      }
      ],
      "code":"9206",
      "display":"ห้วยยอด"
      },
      {
      "districts":[
      {
      "code":"920107",
      "display":"ควนปริง"
      },
      {
      "code":"920101",
      "display":"ทับเที่ยง"
      },
      {
      "code":"920117",
      "display":"นาตาล่วง"
      },
      {
      "code":"920119",
      "display":"นาท่ามเหนือ"
      },
      {
      "code":"920120",
      "display":"นาท่ามใต้"
      },
      {
      "code":"920106",
      "display":"นาบินหลา"
      },
      {
      "code":"920104",
      "display":"นาพละ"
      },
      {
      "code":"920113",
      "display":"นาโต๊ะหมิง"
      },
      {
      "code":"920108",
      "display":"นาโยงใต้"
      },
      {
      "code":"920115",
      "display":"น้ำผุด"
      },
      {
      "code":"920109",
      "display":"บางรัก"
      },
      {
      "code":"920105",
      "display":"บ้านควน"
      },
      {
      "code":"920118",
      "display":"บ้านโพธิ์"
      },
      {
      "code":"920114",
      "display":"หนองตรุด"
      },
      {
      "code":"920110",
      "display":"โคกหล่อ"
      }
      ],
      "code":"9201",
      "display":"เมืองตรัง"
      }
      ],
      "display":"ตรัง"
      },
      "ตราด":{
      "sort_order":"14",
      "code":"23",
      "amphoe":[
      {
      "districts":[
      {
      "code":"230201",
      "display":"คลองใหญ่"
      },
      {
      "code":"230203",
      "display":"หาดเล็ก"
      },
      {
      "code":"230202",
      "display":"ไม้รูด"
      }
      ],
      "code":"2302",
      "display":"คลองใหญ่"
      },
      {
      "districts":[
      {
      "code":"230398",
      "display":"ช้างทูน"
      },
      {
      "code":"230396",
      "display":"ด่านชุมพล"
      },
      {
      "code":"230405",
      "display":"นนทรีย์"
      },
      {
      "code":"230399",
      "display":"บ่อพลอย"
      },
      {
      "code":"230397",
      "display":"หนองบอน"
      }
      ],
      "code":"2304",
      "display":"บ่อไร่"
      },
      {
      "districts":[
      {
      "code":"230506",
      "display":"เกาะกูด"
      },
      {
      "code":"230505",
      "display":"เกาะหมาก"
      }
      ],
      "code":"2306",
      "display":"เกาะกูด"
      },
      {
      "districts":[
      {
      "code":"230504",
      "display":"เกาะช้าง"
      },
      {
      "code":"230508",
      "display":"เกาะช้างใต้"
      }
      ],
      "code":"2307",
      "display":"เกาะช้าง"
      },
      {
      "districts":[
      {
      "code":"230308",
      "display":"ทุ่งนนทรี"
      },
      {
      "code":"230304",
      "display":"ท่าโสม"
      },
      {
      "code":"230306",
      "display":"ประณีต"
      },
      {
      "code":"230303",
      "display":"วังตะเคียน"
      },
      {
      "code":"230305",
      "display":"สะตอ"
      },
      {
      "code":"230301",
      "display":"เขาสมิง"
      },
      {
      "code":"230307",
      "display":"เทพนิมิต"
      },
      {
      "code":"230302",
      "display":"แสนตุ้ง"
      }
      ],
      "code":"2303",
      "display":"เขาสมิง"
      },
      {
      "districts":[
      {
      "code":"230113",
      "display":"ชำราก"
      },
      {
      "code":"230112",
      "display":"ตะกาง"
      },
      {
      "code":"230111",
      "display":"ท่ากุ่ม"
      },
      {
      "code":"230110",
      "display":"ท่าพริก"
      },
      {
      "code":"230101",
      "display":"บางพระ"
      },
      {
      "code":"230107",
      "display":"วังกระแจะ"
      },
      {
      "code":"230104",
      "display":"หนองคันทรง"
      },
      {
      "code":"230102",
      "display":"หนองเสม็ด"
      },
      {
      "code":"230103",
      "display":"หนองโสน"
      },
      {
      "code":"230105",
      "display":"ห้วงน้ำขาว"
      },
      {
      "code":"230108",
      "display":"ห้วยแร้ง"
      },
      {
      "code":"230106",
      "display":"อ่าวใหญ่"
      },
      {
      "code":"230109",
      "display":"เนินทราย"
      },
      {
      "code":"230114",
      "display":"แหลมกลัด"
      }
      ],
      "code":"2301",
      "display":"เมืองตราด"
      },
      {
      "districts":[
      {
      "code":"230201",
      "display":"คลองใหญ่"
      },
      {
      "code":"230502",
      "display":"น้ำเชี่ยว"
      },
      {
      "code":"230503",
      "display":"บางปิด"
      },
      {
      "code":"230501",
      "display":"แหลมงอบ"
      }
      ],
      "code":"2305",
      "display":"แหลมงอบ"
      }
      ],
      "display":"ตราด"
      },
      "ตาก":{
      "sort_order":"15",
      "code":"63",
      "amphoe":[
      {
      "districts":[
      {
      "code":"630501",
      "display":"ท่าสองยาง"
      },
      {
      "code":"630502",
      "display":"แม่ต้าน"
      },
      {
      "code":"630505",
      "display":"แม่วะหลวง"
      },
      {
      "code":"630503",
      "display":"แม่สอง"
      },
      {
      "code":"630504",
      "display":"แม่หละ"
      },
      {
      "code":"630506",
      "display":"แม่อุสุ"
      }
      ],
      "code":"6305",
      "display":"ท่าสองยาง"
      },
      {
      "districts":[
      {
      "code":"630204",
      "display":"ตากตก"
      },
      {
      "code":"630201",
      "display":"ตากออก"
      },
      {
      "code":"630206",
      "display":"ทุ่งกระเชาะ"
      },
      {
      "code":"630207",
      "display":"ท้องฟ้า"
      },
      {
      "code":"630202",
      "display":"สมอโคน"
      },
      {
      "code":"630205",
      "display":"เกาะตะเภา"
      },
      {
      "code":"630203",
      "display":"แม่สลิด"
      }
      ],
      "code":"6302",
      "display":"บ้านตาก"
      },
      {
      "districts":[
      {
      "code":"630703",
      "display":"คีรีราษฎร์"
      },
      {
      "code":"630702",
      "display":"ช่องแคบ"
      },
      {
      "code":"630701",
      "display":"พบพระ"
      },
      {
      "code":"630705",
      "display":"รวมไทยพัฒนา"
      },
      {
      "code":"630704",
      "display":"วาเล่ย์"
      }
      ],
      "code":"6307",
      "display":"พบพระ"
      },
      {
      "districts":[
      {
      "code":"630116",
      "display":"นาโบสถ์"
      },
      {
      "code":"630117",
      "display":"ประดาง"
      },
      {
      "code":"630110",
      "display":"เชียงทอง"
      }
      ],
      "code":"6309",
      "display":"วังเจ้า"
      },
      {
      "districts":[
      {
      "code":"630305",
      "display":"บ้านนา"
      },
      {
      "code":"630303",
      "display":"ยกกระบัตร"
      },
      {
      "code":"630304",
      "display":"ย่านรี"
      },
      {
      "code":"630306",
      "display":"วังจันทร์"
      },
      {
      "code":"630302",
      "display":"วังหมัน"
      },
      {
      "code":"630301",
      "display":"สามเงา"
      }
      ],
      "code":"6303",
      "display":"สามเงา"
      },
      {
      "districts":[
      {
      "code":"630102",
      "display":"หนองหลวง"
      },
      {
      "code":"630801",
      "display":"อุ้มผาง"
      },
      {
      "code":"630806",
      "display":"แม่กลอง"
      },
      {
      "code":"630804",
      "display":"แม่จัน"
      },
      {
      "code":"630805",
      "display":"แม่ละมุ้ง"
      },
      {
      "code":"630803",
      "display":"โมโกร"
      }
      ],
      "code":"6308",
      "display":"อุ้มผาง"
      },
      {
      "districts":[
      {
      "code":"630115",
      "display":"ตลุกกลางทุ่ง"
      },
      {
      "code":"630108",
      "display":"น้ำรึม"
      },
      {
      "code":"630112",
      "display":"ป่ามะม่วง"
      },
      {
      "code":"630101",
      "display":"ระแหง"
      },
      {
      "code":"630114",
      "display":"วังประจบ"
      },
      {
      "code":"630109",
      "display":"วังหิน"
      },
      {
      "code":"630105",
      "display":"หนองบัวเหนือ"
      },
      {
      "code":"630113",
      "display":"หนองบัวใต้"
      },
      {
      "code":"630102",
      "display":"หนองหลวง"
      },
      {
      "code":"630104",
      "display":"หัวเดียด"
      },
      {
      "code":"630103",
      "display":"เชียงเงิน"
      },
      {
      "code":"630111",
      "display":"แม่ท้อ"
      },
      {
      "code":"630107",
      "display":"โป่งแดง"
      },
      {
      "code":"630106",
      "display":"ไม้งาม"
      }
      ],
      "code":"6301",
      "display":"เมืองตาก"
      },
      {
      "districts":[
      {
      "code":"630403",
      "display":"ขะเนจื้อ"
      },
      {
      "code":"630406",
      "display":"พระธาตุ"
      },
      {
      "code":"630405",
      "display":"สามหมื่น"
      },
      {
      "code":"630402",
      "display":"แม่จะเรา"
      },
      {
      "code":"630404",
      "display":"แม่ตื่น"
      },
      {
      "code":"630401",
      "display":"แม่ระมาด"
      }
      ],
      "code":"6304",
      "display":"แม่ระมาด"
      },
      {
      "districts":[
      {
      "code":"630609",
      "display":"ด่านแม่ละเมา"
      },
      {
      "code":"630606",
      "display":"ท่าสายลวด"
      },
      {
      "code":"630610",
      "display":"พระธาตุผาแดง"
      },
      {
      "code":"630603",
      "display":"พะวอ"
      },
      {
      "code":"630608",
      "display":"มหาวัน"
      },
      {
      "code":"630605",
      "display":"แม่กาษา"
      },
      {
      "code":"630602",
      "display":"แม่กุ"
      },
      {
      "code":"630604",
      "display":"แม่ตาว"
      },
      {
      "code":"630607",
      "display":"แม่ปะ"
      },
      {
      "code":"630601",
      "display":"แม่สอด"
      }
      ],
      "code":"6306",
      "display":"แม่สอด"
      }
      ],
      "display":"ตาก"
      },
      "นครนายก":{
      "sort_order":"16",
      "code":"26",
      "amphoe":[
      {
      "districts":[
      {
      "code":"260305",
      "display":"ทองหลาง"
      },
      {
      "code":"260306",
      "display":"บางอ้อ"
      },
      {
      "code":"260301",
      "display":"บ้านนา"
      },
      {
      "code":"260303",
      "display":"บ้านพริก"
      },
      {
      "code":"260302",
      "display":"บ้านพร้าว"
      },
      {
      "code":"260308",
      "display":"ป่าขะ"
      },
      {
      "code":"260307",
      "display":"พิกุลออก"
      },
      {
      "code":"260310",
      "display":"ศรีกะอาง"
      },
      {
      "code":"260304",
      "display":"อาษา"
      },
      {
      "code":"260309",
      "display":"เขาเพิ่ม"
      }
      ],
      "code":"2603",
      "display":"บ้านนา"
      },
      {
      "districts":[
      {
      "code":"260205",
      "display":"ท่าเรือ"
      },
      {
      "code":"260207",
      "display":"นาหินลาด"
      },
      {
      "code":"260203",
      "display":"ปากพลี"
      },
      {
      "code":"260206",
      "display":"หนองแสง"
      },
      {
      "code":"260201",
      "display":"เกาะหวาย"
      },
      {
      "code":"260202",
      "display":"เกาะโพธิ์"
      },
      {
      "code":"260204",
      "display":"โคกกรวด"
      }
      ],
      "code":"2602",
      "display":"ปากพลี"
      },
      {
      "districts":[
      {
      "code":"260411",
      "display":"คลองใหญ่"
      },
      {
      "code":"260410",
      "display":"ชุมพล"
      },
      {
      "code":"260406",
      "display":"ทรายมูล"
      },
      {
      "code":"260407",
      "display":"บางปลากด"
      },
      {
      "code":"260408",
      "display":"บางลูกเสือ"
      },
      {
      "code":"260405",
      "display":"บางสมบูรณ์"
      },
      {
      "code":"260402",
      "display":"บึงศาล"
      },
      {
      "code":"260401",
      "display":"พระอาจารย์"
      },
      {
      "code":"260403",
      "display":"ศีรษะกระบือ"
      },
      {
      "code":"260409",
      "display":"องครักษ์"
      },
      {
      "code":"260404",
      "display":"โพธิ์แทน"
      }
      ],
      "code":"2604",
      "display":"องครักษ์"
      },
      {
      "districts":[
      {
      "code":"260108",
      "display":"ดงละคร"
      },
      {
      "code":"260106",
      "display":"ดอนยอ"
      },
      {
      "code":"260102",
      "display":"ท่าช้าง"
      },
      {
      "code":"260105",
      "display":"ท่าทราย"
      },
      {
      "code":"260101",
      "display":"นครนายก"
      },
      {
      "code":"260103",
      "display":"บ้านใหญ่"
      },
      {
      "code":"260113",
      "display":"พรหมณี"
      },
      {
      "code":"260104",
      "display":"วังกระโจม"
      },
      {
      "code":"260107",
      "display":"ศรีจุฬา"
      },
      {
      "code":"260109",
      "display":"ศรีนาวา"
      },
      {
      "code":"260110",
      "display":"สาริกา"
      },
      {
      "code":"260111",
      "display":"หินตั้ง"
      },
      {
      "code":"260112",
      "display":"เขาพระ"
      }
      ],
      "code":"2601",
      "display":"เมืองนครนายก"
      }
      ],
      "display":"นครนายก"
      },
      "นครปฐม":{
      "sort_order":"17",
      "code":"73",
      "amphoe":[
      {
      "districts":[
      {
      "code":"730202",
      "display":"กระตีบ"
      },
      {
      "code":"730212",
      "display":"กำแพงแสน"
      },
      {
      "code":"730208",
      "display":"ดอนข่อย"
      },
      {
      "code":"730201",
      "display":"ทุ่งกระพังโหม"
      },
      {
      "code":"730205",
      "display":"ทุ่งขวาง"
      },
      {
      "code":"730207",
      "display":"ทุ่งบัว"
      },
      {
      "code":"730203",
      "display":"ทุ่งลูกนก"
      },
      {
      "code":"730213",
      "display":"รางพิกุล"
      },
      {
      "code":"730215",
      "display":"วังน้ำเขียว"
      },
      {
      "code":"730209",
      "display":"สระพัฒนา"
      },
      {
      "code":"730206",
      "display":"สระสี่มุม"
      },
      {
      "code":"730214",
      "display":"หนองกระทุ่ม"
      },
      {
      "code":"730204",
      "display":"ห้วยขวาง"
      },
      {
      "code":"730211",
      "display":"ห้วยม่วง"
      },
      {
      "code":"730210",
      "display":"ห้วยหมอนทอง"
      }
      ],
      "code":"7302",
      "display":"กำแพงแสน"
      },
      {
      "districts":[
      {
      "code":"730404",
      "display":"ดอนพุทรา"
      },
      {
      "code":"730406",
      "display":"ดอนรวก"
      },
      {
      "code":"730405",
      "display":"บ้านหลวง"
      },
      {
      "code":"730408",
      "display":"ลำลูกบัว"
      },
      {
      "code":"730403",
      "display":"ลำเหย"
      },
      {
      "code":"730401",
      "display":"สามง่าม"
      },
      {
      "code":"730407",
      "display":"ห้วยด้วน"
      },
      {
      "code":"730402",
      "display":"ห้วยพระ"
      }
      ],
      "code":"7304",
      "display":"ดอนตูม"
      },
      {
      "districts":[
      {
      "code":"730307",
      "display":"ขุนแก้ว"
      },
      {
      "code":"730323",
      "display":"งิ้วราย"
      },
      {
      "code":"730317",
      "display":"ดอนแฝก"
      },
      {
      "code":"730306",
      "display":"ท่ากระชับ"
      },
      {
      "code":"730304",
      "display":"ท่าตำหนัก"
      },
      {
      "code":"730308",
      "display":"ท่าพระยา"
      },
      {
      "code":"730301",
      "display":"นครชัยศรี"
      },
      {
      "code":"730302",
      "display":"บางกระเบา"
      },
      {
      "code":"730320",
      "display":"บางพระ"
      },
      {
      "code":"730310",
      "display":"บางระกำ"
      },
      {
      "code":"730305",
      "display":"บางแก้ว"
      },
      {
      "code":"730321",
      "display":"บางแก้วฟ้า"
      },
      {
      "code":"730309",
      "display":"พะเนียด"
      },
      {
      "code":"730322",
      "display":"ลานตากฟ้า"
      },
      {
      "code":"730319",
      "display":"วัดละมุด"
      },
      {
      "code":"730316",
      "display":"วัดสำโรง"
      },
      {
      "code":"730303",
      "display":"วัดแค"
      },
      {
      "code":"730314",
      "display":"ศรีมหาโพธิ์"
      },
      {
      "code":"730312",
      "display":"ศรีษะทอง"
      },
      {
      "code":"730315",
      "display":"สัมปทวน"
      },
      {
      "code":"730318",
      "display":"ห้วยพลู"
      },
      {
      "code":"730313",
      "display":"แหลมบัว"
      },
      {
      "code":"730311",
      "display":"โคกพระเจดีย์"
      },
      {
      "code":"730324",
      "display":"ไทยาวาส"
      }
      ],
      "code":"7303",
      "display":"นครชัยศรี"
      },
      {
      "districts":[
      {
      "code":"730512",
      "display":"คลองนกกระทุง"
      },
      {
      "code":"730509",
      "display":"ดอนตูม"
      },
      {
      "code":"730513",
      "display":"นราภิรมย์"
      },
      {
      "code":"730510",
      "display":"นิลเพชร"
      },
      {
      "code":"730511",
      "display":"บัวปากท่า"
      },
      {
      "code":"730502",
      "display":"บางปลา"
      },
      {
      "code":"730504",
      "display":"บางภาษี"
      },
      {
      "code":"730310",
      "display":"บางระกำ"
      },
      {
      "code":"730503",
      "display":"บางหลวง"
      },
      {
      "code":"730501",
      "display":"บางเลน"
      },
      {
      "code":"730506",
      "display":"บางไทรป่า"
      },
      {
      "code":"730514",
      "display":"ลำพญา"
      },
      {
      "code":"730507",
      "display":"หินมูล"
      },
      {
      "code":"730508",
      "display":"ไทรงาม"
      },
      {
      "code":"730515",
      "display":"ไผ่หูช้าง"
      }
      ],
      "code":"7305",
      "display":"บางเลน"
      },
      {
      "districts":[
      {
      "code":"730327",
      "display":"คลองโยง"
      },
      {
      "code":"730326",
      "display":"มหาสวัสดิ์"
      },
      {
      "code":"730325",
      "display":"ศาลายา"
      }
      ],
      "code":"7307",
      "display":"พุทธมณฑล"
      },
      {
      "districts":[
      {
      "code":"730610",
      "display":"กระทุ่มล้ม"
      },
      {
      "code":"730613",
      "display":"คลองจินดา"
      },
      {
      "code":"730611",
      "display":"คลองใหม่"
      },
      {
      "code":"730612",
      "display":"ตลาดจินดา"
      },
      {
      "code":"730602",
      "display":"ทรงคนอง"
      },
      {
      "code":"730601",
      "display":"ท่าข้าม"
      },
      {
      "code":"730609",
      "display":"ท่าตลาด"
      },
      {
      "code":"730604",
      "display":"บางกระทึก"
      },
      {
      "code":"730607",
      "display":"บางช้าง"
      },
      {
      "code":"730605",
      "display":"บางเตย"
      },
      {
      "code":"730615",
      "display":"บ้านใหม่"
      },
      {
      "code":"730614",
      "display":"ยายชา"
      },
      {
      "code":"730606",
      "display":"สามพราน"
      },
      {
      "code":"730603",
      "display":"หอมเกร็ด"
      },
      {
      "code":"730616",
      "display":"อ้อมใหญ่"
      },
      {
      "code":"730608",
      "display":"ไร่ขิง"
      }
      ],
      "code":"7306",
      "display":"สามพราน"
      },
      {
      "districts":[
      {
      "code":"730108",
      "display":"ดอนยายหอม"
      },
      {
      "code":"730105",
      "display":"ตาก้อง"
      },
      {
      "code":"730109",
      "display":"ถนนขาด"
      },
      {
      "code":"730123",
      "display":"ทัพหลวง"
      },
      {
      "code":"730115",
      "display":"ทุ่งน้อย"
      },
      {
      "code":"730104",
      "display":"ธรรมศาลา"
      },
      {
      "code":"730111",
      "display":"นครปฐม"
      },
      {
      "code":"730102",
      "display":"บางแขม"
      },
      {
      "code":"730110",
      "display":"บ่อพลับ"
      },
      {
      "code":"730125",
      "display":"บ้านยาง"
      },
      {
      "code":"730101",
      "display":"พระปฐมเจดีย์"
      },
      {
      "code":"730103",
      "display":"พระประโทน"
      },
      {
      "code":"730106",
      "display":"มาบแค"
      },
      {
      "code":"730119",
      "display":"ลำพยา"
      },
      {
      "code":"730112",
      "display":"วังตะกู"
      },
      {
      "code":"730117",
      "display":"วังเย็น"
      },
      {
      "code":"730107",
      "display":"สนามจันทร์"
      },
      {
      "code":"730120",
      "display":"สระกะเทียม"
      },
      {
      "code":"730121",
      "display":"สวนป่าน"
      },
      {
      "code":"730114",
      "display":"สามควายเผือก"
      },
      {
      "code":"730124",
      "display":"หนองงูเหลือม"
      },
      {
      "code":"730116",
      "display":"หนองดินแดง"
      },
      {
      "code":"730113",
      "display":"หนองปากโลง"
      },
      {
      "code":"730122",
      "display":"ห้วยจรเข้"
      },
      {
      "code":"730118",
      "display":"โพรงมะเดื่อ"
      }
      ],
      "code":"7301",
      "display":"เมืองนครปฐม"
      }
      ],
      "display":"นครปฐม"
      },
      "นครพนม":{
      "sort_order":"18",
      "code":"48",
      "amphoe":[
      {
      "districts":[
      {
      "code":"480303",
      "display":"ท่าจำปา"
      },
      {
      "code":"480301",
      "display":"ท่าอุเทน"
      },
      {
      "code":"480305",
      "display":"พนอม"
      },
      {
      "code":"480306",
      "display":"พะทาย"
      },
      {
      "code":"480312",
      "display":"รามราช"
      },
      {
      "code":"480314",
      "display":"หนองเทา"
      },
      {
      "code":"480311",
      "display":"เวินพระบาท"
      },
      {
      "code":"480302",
      "display":"โนนตาล"
      },
      {
      "code":"480304",
      "display":"ไชยบุรี"
      }
      ],
      "code":"4803",
      "display":"ท่าอุเทน"
      },
      {
      "districts":[
      {
      "code":"480511",
      "display":"กุดฉิม"
      },
      {
      "code":"480507",
      "display":"ดอนนางหงส์"
      },
      {
      "code":"480501",
      "display":"ธาตุพนม"
      },
      {
      "code":"480512",
      "display":"ธาตุพนมเหนือ"
      },
      {
      "code":"480505",
      "display":"นาถ่อน"
      },
      {
      "code":"480510",
      "display":"นาหนาด"
      },
      {
      "code":"480508",
      "display":"น้ำก่ำ"
      },
      {
      "code":"480502",
      "display":"ฝั่งแดง"
      },
      {
      "code":"480504",
      "display":"พระกลางทุ่ง"
      },
      {
      "code":"480509",
      "display":"อุ่มเหม้า"
      },
      {
      "code":"480506",
      "display":"แสนพัน"
      },
      {
      "code":"480503",
      "display":"โพนแพง"
      }
      ],
      "code":"4805",
      "display":"ธาตุพนม"
      },
      {
      "districts":[
      {
      "code":"480407",
      "display":"ดอนเตย"
      },
      {
      "code":"480405",
      "display":"นาทม"
      },
      {
      "code":"480406",
      "display":"หนองซน"
      }
      ],
      "code":"4811",
      "display":"นาทม"
      },
      {
      "districts":[
      {
      "code":"480906",
      "display":"ท่าเรือ"
      },
      {
      "code":"480904",
      "display":"นาคูณใหญ่"
      },
      {
      "code":"480408",
      "display":"นางัว"
      },
      {
      "code":"480901",
      "display":"นาหว้า"
      },
      {
      "code":"480903",
      "display":"บ้านเสียว"
      },
      {
      "code":"480905",
      "display":"เหล่าพัฒนา"
      }
      ],
      "code":"4809",
      "display":"นาหว้า"
      },
      {
      "districts":[
      {
      "code":"480707",
      "display":"ก้านเหลือง"
      },
      {
      "code":"480713",
      "display":"คำพี้"
      },
      {
      "code":"480704",
      "display":"นาคู่"
      },
      {
      "code":"480709",
      "display":"นาเลียง"
      },
      {
      "code":"480701",
      "display":"นาแก"
      },
      {
      "code":"480712",
      "display":"บ้านแก้ง"
      },
      {
      "code":"480702",
      "display":"พระซอง"
      },
      {
      "code":"480705",
      "display":"พิมาน"
      },
      {
      "code":"480706",
      "display":"พุ่มแก"
      },
      {
      "code":"480715",
      "display":"สีชมพู"
      },
      {
      "code":"480708",
      "display":"หนองบ่อ"
      },
      {
      "code":"480703",
      "display":"หนองสังข์"
      }
      ],
      "code":"4807",
      "display":"นาแก"
      },
      {
      "districts":[
      {
      "code":"480408",
      "display":"นางัว"
      },
      {
      "code":"480409",
      "display":"นาเข"
      },
      {
      "code":"480401",
      "display":"บ้านแพง"
      },
      {
      "code":"480404",
      "display":"หนองแวง"
      },
      {
      "code":"480403",
      "display":"โพนทอง"
      },
      {
      "code":"480402",
      "display":"ไผ่ล้อม"
      }
      ],
      "code":"4804",
      "display":"บ้านแพง"
      },
      {
      "districts":[
      {
      "code":"480203",
      "display":"กุตาไก้"
      },
      {
      "code":"480207",
      "display":"นามะเขือ"
      },
      {
      "code":"480201",
      "display":"ปลาปาก"
      },
      {
      "code":"480206",
      "display":"มหาชัย"
      },
      {
      "code":"480202",
      "display":"หนองฮี"
      },
      {
      "code":"480208",
      "display":"หนองเทาใหญ่"
      },
      {
      "code":"480204",
      "display":"โคกสว่าง"
      },
      {
      "code":"480205",
      "display":"โคกสูง"
      }
      ],
      "code":"4802",
      "display":"ปลาปาก"
      },
      {
      "districts":[
      {
      "code":"480714",
      "display":"ยอดชาด"
      },
      {
      "code":"480711",
      "display":"วังยาง"
      },
      {
      "code":"480716",
      "display":"หนองโพธิ์"
      },
      {
      "code":"480710",
      "display":"โคกสี"
      }
      ],
      "code":"4812",
      "display":"วังยาง"
      },
      {
      "districts":[
      {
      "code":"480805",
      "display":"ท่าบ่อสงคราม"
      },
      {
      "code":"480807",
      "display":"นาคำ"
      },
      {
      "code":"480802",
      "display":"นาเดื่อ"
      },
      {
      "code":"480806",
      "display":"บ้านข่า"
      },
      {
      "code":"480803",
      "display":"บ้านเอื้อง"
      },
      {
      "code":"480801",
      "display":"ศรีสงคราม"
      },
      {
      "code":"480804",
      "display":"สามผง"
      },
      {
      "code":"480809",
      "display":"หาดแพง"
      },
      {
      "code":"480808",
      "display":"โพนสว่าง"
      }
      ],
      "code":"4808",
      "display":"ศรีสงคราม"
      },
      {
      "districts":[
      {
      "code":"480105",
      "display":"กุรุคุ"
      },
      {
      "code":"480108",
      "display":"ขามเฒ่า"
      },
      {
      "code":"480111",
      "display":"คำเตย"
      },
      {
      "code":"480113",
      "display":"ดงขวาง"
      },
      {
      "code":"480110",
      "display":"ท่าค้อ"
      },
      {
      "code":"480103",
      "display":"นาทราย"
      },
      {
      "code":"480104",
      "display":"นาราชควาย"
      },
      {
      "code":"480109",
      "display":"บ้านกลาง"
      },
      {
      "code":"480106",
      "display":"บ้านผึ้ง"
      },
      {
      "code":"480114",
      "display":"วังตามัว"
      },
      {
      "code":"480112",
      "display":"หนองญาติ"
      },
      {
      "code":"480102",
      "display":"หนองแสง"
      },
      {
      "code":"480107",
      "display":"อาจสามารถ"
      },
      {
      "code":"480115",
      "display":"โพธิ์ตาก"
      },
      {
      "code":"480101",
      "display":"ในเมือง"
      }
      ],
      "code":"4801",
      "display":"เมืองนครพนม"
      },
      {
      "districts":[
      {
      "code":"480603",
      "display":"ท่าลาด"
      },
      {
      "code":"480609",
      "display":"นาขาม"
      },
      {
      "code":"480604",
      "display":"นางาม"
      },
      {
      "code":"480607",
      "display":"หนองย่างชิ้น"
      },
      {
      "code":"480601",
      "display":"เรณู"
      },
      {
      "code":"480608",
      "display":"เรณูใต้"
      },
      {
      "code":"480605",
      "display":"โคกหินแฮ่"
      },
      {
      "code":"480403",
      "display":"โพนทอง"
      }
      ],
      "code":"4806",
      "display":"เรณูนคร"
      },
      {
      "districts":[
      {
      "code":"480307",
      "display":"นาขมิ้น"
      },
      {
      "code":"480313",
      "display":"นาหัวบ่อ"
      },
      {
      "code":"481007",
      "display":"นาใน"
      },
      {
      "code":"480310",
      "display":"บ้านค้อ"
      },
      {
      "code":"481006",
      "display":"โพนจาน"
      },
      {
      "code":"480308",
      "display":"โพนบก"
      },
      {
      "code":"480309",
      "display":"โพนสวรรค์"
      }
      ],
      "code":"4810",
      "display":"โพนสวรรค์"
      }
      ],
      "display":"นครพนม"
      },
      "นครราชสีมา":{
      "sort_order":"19",
      "code":"30",
      "amphoe":[
      {
      "districts":[
      {
      "code":"301901",
      "display":"ขามทะเลสอ"
      },
      {
      "code":"301905",
      "display":"บึงอ้อ"
      },
      {
      "code":"301903",
      "display":"พันดุง"
      },
      {
      "code":"301904",
      "display":"หนองสรวง"
      },
      {
      "code":"301902",
      "display":"โป่งแดง"
      }
      ],
      "code":"3019",
      "display":"ขามทะเลสอ"
      },
      {
      "districts":[
      {
      "code":"301101",
      "display":"ขามสะแกแสง"
      },
      {
      "code":"301104",
      "display":"ชีวึก"
      },
      {
      "code":"301105",
      "display":"พะงาด"
      },
      {
      "code":"301106",
      "display":"หนองหัวฟาน"
      },
      {
      "code":"301103",
      "display":"เมืองนาท"
      },
      {
      "code":"301107",
      "display":"เมืองเกษตร"
      },
      {
      "code":"301102",
      "display":"โนนเมือง"
      }
      ],
      "code":"3011",
      "display":"ขามสะแกแสง"
      },
      {
      "districts":[
      {
      "code":"300410",
      "display":"ขามสมบูรณ์"
      },
      {
      "code":"300402",
      "display":"คูขาด"
      },
      {
      "code":"300409",
      "display":"ดอนใหญ่"
      },
      {
      "code":"300404",
      "display":"ตาจั่น"
      },
      {
      "code":"300405",
      "display":"บ้านปรางค์"
      },
      {
      "code":"300407",
      "display":"หนองบัว"
      },
      {
      "code":"300406",
      "display":"หนองมะนาว"
      },
      {
      "code":"300403",
      "display":"เทพาลัย"
      },
      {
      "code":"300401",
      "display":"เมืองคง"
      },
      {
      "code":"300408",
      "display":"โนนเต็ง"
      }
      ],
      "code":"3004",
      "display":"คง"
      },
      {
      "districts":[
      {
      "code":"300203",
      "display":"ครบุรี"
      },
      {
      "code":"300210",
      "display":"ครบุรีใต้"
      },
      {
      "code":"300205",
      "display":"จระเข้หิน"
      },
      {
      "code":"300211",
      "display":"ตะแบกบาน"
      },
      {
      "code":"300113",
      "display":"บ้านใหม่"
      },
      {
      "code":"300206",
      "display":"มาบตะโกเอน"
      },
      {
      "code":"300209",
      "display":"ลำเพียก"
      },
      {
      "code":"300212",
      "display":"สระว่านพระยา"
      },
      {
      "code":"300207",
      "display":"อรพิมพ์"
      },
      {
      "code":"300202",
      "display":"เฉลียง"
      },
      {
      "code":"300201",
      "display":"แชะ"
      },
      {
      "code":"300204",
      "display":"โคกกระชาย"
      }
      ],
      "code":"3002",
      "display":"ครบุรี"
      },
      {
      "districts":[
      {
      "code":"300611",
      "display":"คลองเมือง"
      },
      {
      "code":"300601",
      "display":"จักราช"
      },
      {
      "code":"300603",
      "display":"ทองหลาง"
      },
      {
      "code":"300610",
      "display":"ศรีละกอ"
      },
      {
      "code":"300604",
      "display":"สีสุก"
      },
      {
      "code":"300605",
      "display":"หนองขาม"
      },
      {
      "code":"300607",
      "display":"หนองพลวง"
      },
      {
      "code":"300613",
      "display":"หินโคน"
      }
      ],
      "code":"3006",
      "display":"จักราช"
      },
      {
      "districts":[
      {
      "code":"301701",
      "display":"ชุมพวง"
      },
      {
      "code":"301306",
      "display":"ตลาดไทร"
      },
      {
      "code":"301703",
      "display":"ท่าลาด"
      },
      {
      "code":"301702",
      "display":"ประสุข"
      },
      {
      "code":"301704",
      "display":"สาหร่าย"
      },
      {
      "code":"301714",
      "display":"หนองหลัก"
      },
      {
      "code":"301716",
      "display":"โนนตูม"
      },
      {
      "code":"301717",
      "display":"โนนยอ"
      },
      {
      "code":"301710",
      "display":"โนนรัง"
      }
      ],
      "code":"3017",
      "display":"ชุมพวง"
      },
      {
      "districts":[
      {
      "code":"300801",
      "display":"กุดพิมาน"
      },
      {
      "code":"300802",
      "display":"ด่านขุนทด"
      },
      {
      "code":"300803",
      "display":"ด่านนอก"
      },
      {
      "code":"300804",
      "display":"ด่านใน"
      },
      {
      "code":"300805",
      "display":"ตะเคียน"
      },
      {
      "code":"300806",
      "display":"บ้านเก่า"
      },
      {
      "code":"300807",
      "display":"บ้านแปรง"
      },
      {
      "code":"300808",
      "display":"พันชนะ"
      },
      {
      "code":"300809",
      "display":"สระจรเข้"
      },
      {
      "code":"300810",
      "display":"หนองกราด"
      },
      {
      "code":"300811",
      "display":"หนองบัวตะเกียด"
      },
      {
      "code":"300812",
      "display":"หนองบัวละคร"
      },
      {
      "code":"300818",
      "display":"หนองไทร"
      },
      {
      "code":"300813",
      "display":"หินดาด"
      },
      {
      "code":"300815",
      "display":"ห้วยบง"
      },
      {
      "code":"300817",
      "display":"โนนเมืองพัฒนา"
      }
      ],
      "code":"3008",
      "display":"ด่านขุนทด"
      },
      {
      "districts":[
      {
      "code":"301210",
      "display":"บัวลาย"
      },
      {
      "code":"301209",
      "display":"หนองหว้า"
      },
      {
      "code":"301222",
      "display":"เมืองพะไล"
      },
      {
      "code":"301216",
      "display":"โนนจาน"
      }
      ],
      "code":"3030",
      "display":"บัวลาย"
      },
      {
      "districts":[
      {
      "code":"301214",
      "display":"กุดจอก"
      },
      {
      "code":"301220",
      "display":"ขุนทอง"
      },
      {
      "code":"301206",
      "display":"ดอนตะหนิน"
      },
      {
      "code":"301215",
      "display":"ด่านช้าง"
      },
      {
      "code":"301201",
      "display":"บัวใหญ่"
      },
      {
      "code":"301207",
      "display":"หนองบัวสะอาด"
      },
      {
      "code":"301224",
      "display":"หนองแจ้งใหญ่"
      },
      {
      "code":"301203",
      "display":"ห้วยยาง"
      },
      {
      "code":"301204",
      "display":"เสมาใหญ่"
      },
      {
      "code":"301208",
      "display":"โนนทองหลาง"
      }
      ],
      "code":"3012",
      "display":"บัวใหญ่"
      },
      {
      "districts":[
      {
      "code":"300504",
      "display":"ช่อระกา"
      },
      {
      "code":"300501",
      "display":"บ้านเหลื่อม"
      },
      {
      "code":"300502",
      "display":"วังโพธิ์"
      },
      {
      "code":"300503",
      "display":"โคกกระเบื้อง"
      }
      ],
      "code":"3005",
      "display":"บ้านเหลื่อม"
      },
      {
      "districts":[
      {
      "code":"301303",
      "display":"กระทุ่มราย"
      },
      {
      "code":"301310",
      "display":"ดอนมัน"
      },
      {
      "code":"301306",
      "display":"ตลาดไทร"
      },
      {
      "code":"301315",
      "display":"ทุ่งสว่าง"
      },
      {
      "code":"301313",
      "display":"นางรำ"
      },
      {
      "code":"301301",
      "display":"ประทาย"
      },
      {
      "code":"301304",
      "display":"วังไม้แดง"
      },
      {
      "code":"301308",
      "display":"หนองค่าย"
      },
      {
      "code":"300607",
      "display":"หนองพลวง"
      },
      {
      "code":"301309",
      "display":"หันห้วยทราย"
      },
      {
      "code":"301318",
      "display":"เมืองโดน"
      },
      {
      "code":"301317",
      "display":"โคกกลาง"
      },
      {
      "code":"301314",
      "display":"โนนเพ็ด"
      }
      ],
      "code":"3013",
      "display":"ประทาย"
      },
      {
      "districts":[
      {
      "code":"301410",
      "display":"งิ้ว"
      },
      {
      "code":"301407",
      "display":"ดอน"
      },
      {
      "code":"301405",
      "display":"ตะขบ"
      },
      {
      "code":"301402",
      "display":"ตะคุ"
      },
      {
      "code":"301409",
      "display":"ตูม"
      },
      {
      "code":"301417",
      "display":"ธงชัยเหนือ"
      },
      {
      "code":"301406",
      "display":"นกออก"
      },
      {
      "code":"301420",
      "display":"บ่อปลาทอง"
      },
      {
      "code":"301416",
      "display":"ภูหลวง"
      },
      {
      "code":"301412",
      "display":"ลำนางแก้ว"
      },
      {
      "code":"301411",
      "display":"สะแกราช"
      },
      {
      "code":"300904",
      "display":"สำโรง"
      },
      {
      "code":"301418",
      "display":"สุขเกษม"
      },
      {
      "code":"301419",
      "display":"เกษมทรัพย์"
      },
      {
      "code":"301401",
      "display":"เมืองปัก"
      },
      {
      "code":"301403",
      "display":"โคกไทย"
      }
      ],
      "code":"3014",
      "display":"ปักธงชัย"
      },
      {
      "districts":[
      {
      "code":"302102",
      "display":"กลางดง"
      },
      {
      "code":"302107",
      "display":"ขนงพระ"
      },
      {
      "code":"302109",
      "display":"คลองม่วง"
      },
      {
      "code":"302103",
      "display":"จันทึก"
      },
      {
      "code":"302101",
      "display":"ปากช่อง"
      },
      {
      "code":"302112",
      "display":"พญาเย็น"
      },
      {
      "code":"302104",
      "display":"วังกะทะ"
      },
      {
      "code":"302111",
      "display":"วังไทร"
      },
      {
      "code":"302110",
      "display":"หนองน้ำแดง"
      },
      {
      "code":"302106",
      "display":"หนองสาหร่าย"
      },
      {
      "code":"302105",
      "display":"หมูสี"
      },
      {
      "code":"302108",
      "display":"โป่งตาลอง"
      }
      ],
      "code":"3021",
      "display":"ปากช่อง"
      },
      {
      "districts":[
      {
      "code":"300912",
      "display":"ทัพรั้ง"
      },
      {
      "code":"300910",
      "display":"พังเทียม"
      },
      {
      "code":"300915",
      "display":"มาบกราด"
      },
      {
      "code":"300911",
      "display":"สระพระ"
      },
      {
      "code":"300913",
      "display":"หนองหอย"
      }
      ],
      "code":"3028",
      "display":"พระทองคำ"
      },
      {
      "districts":[
      {
      "code":"301509",
      "display":"กระชอน"
      },
      {
      "code":"301504",
      "display":"กระเบื้องใหญ่"
      },
      {
      "code":"301507",
      "display":"ชีวาน"
      },
      {
      "code":"301510",
      "display":"ดงใหญ่"
      },
      {
      "code":"301505",
      "display":"ท่าหลวง"
      },
      {
      "code":"301511",
      "display":"ธารละหลอด"
      },
      {
      "code":"301508",
      "display":"นิคมสร้างตนเอง"
      },
      {
      "code":"301506",
      "display":"รังกาใหญ่"
      },
      {
      "code":"301502",
      "display":"สัมฤทธิ์"
      },
      {
      "code":"300106",
      "display":"หนองระเวียง"
      },
      {
      "code":"301503",
      "display":"โบสถ์"
      },
      {
      "code":"300101",
      "display":"ในเมือง"
      }
      ],
      "code":"3015",
      "display":"พิมาย"
      },
      {
      "districts":[
      {
      "code":"301707",
      "display":"ขุย"
      },
      {
      "code":"301706",
      "display":"ช่องแมว"
      },
      {
      "code":"301711",
      "display":"บ้านยาง"
      },
      {
      "code":"301715",
      "display":"ไพล"
      }
      ],
      "code":"3029",
      "display":"ลำทะเมนชัย"
      },
      {
      "districts":[
      {
      "code":"301414",
      "display":"ระเริง"
      },
      {
      "code":"301408",
      "display":"วังน้ำเขียว"
      },
      {
      "code":"301413",
      "display":"วังหมี"
      },
      {
      "code":"301415",
      "display":"อุดมทรัพย์"
      },
      {
      "code":"302505",
      "display":"ไทยสามัคคี"
      }
      ],
      "code":"3025",
      "display":"วังน้ำเขียว"
      },
      {
      "districts":[
      {
      "code":"302003",
      "display":"กฤษณา"
      },
      {
      "code":"302006",
      "display":"กุดน้อย"
      },
      {
      "code":"302010",
      "display":"คลองไผ่"
      },
      {
      "code":"302011",
      "display":"ดอนเมือง"
      },
      {
      "code":"302002",
      "display":"บ้านหัน"
      },
      {
      "code":"302009",
      "display":"มิตรภาพ"
      },
      {
      "code":"302004",
      "display":"ลาดบัวขาว"
      },
      {
      "code":"302008",
      "display":"วังโรงใหญ่"
      },
      {
      "code":"302001",
      "display":"สีคิ้ว"
      },
      {
      "code":"302007",
      "display":"หนองน้ำใส"
      },
      {
      "code":"302012",
      "display":"หนองบัวน้อย"
      },
      {
      "code":"302005",
      "display":"หนองหญ้าขาว"
      }
      ],
      "code":"3020",
      "display":"สีคิ้ว"
      },
      {
      "districts":[
      {
      "code":"301218",
      "display":"สามเมือง"
      },
      {
      "code":"301211",
      "display":"สีดา"
      },
      {
      "code":"301221",
      "display":"หนองตาดใหญ่"
      },
      {
      "code":"301223",
      "display":"โนนประดู่"
      },
      {
      "code":"301212",
      "display":"โพนทอง"
      }
      ],
      "code":"3031",
      "display":"สีดา"
      },
      {
      "districts":[
      {
      "code":"301811",
      "display":"กุดจิก"
      },
      {
      "code":"301809",
      "display":"นากลาง"
      },
      {
      "code":"301804",
      "display":"บุ่งขี้เหล็ก"
      },
      {
      "code":"301807",
      "display":"มะเกลือเก่า"
      },
      {
      "code":"301808",
      "display":"มะเกลือใหม่"
      },
      {
      "code":"301801",
      "display":"สูงเนิน"
      },
      {
      "code":"301810",
      "display":"หนองตะไก้"
      },
      {
      "code":"301802",
      "display":"เสมา"
      },
      {
      "code":"301803",
      "display":"โคราช"
      },
      {
      "code":"301806",
      "display":"โค้งยาง"
      },
      {
      "code":"301805",
      "display":"โนนค่า"
      }
      ],
      "code":"3018",
      "display":"สูงเนิน"
      },
      {
      "districts":[
      {
      "code":"300113",
      "display":"บ้านใหม่"
      },
      {
      "code":"302207",
      "display":"ลุงเขว้า"
      },
      {
      "code":"302202",
      "display":"สารภี"
      },
      {
      "code":"301810",
      "display":"หนองตะไก้"
      },
      {
      "code":"302201",
      "display":"หนองบุนนาก"
      },
      {
      "code":"302204",
      "display":"หนองหัวแรต"
      },
      {
      "code":"302208",
      "display":"หนองไม้ไผ่"
      },
      {
      "code":"302205",
      "display":"แหลมทอง"
      },
      {
      "code":"302203",
      "display":"ไทยเจริญ"
      }
      ],
      "code":"3022",
      "display":"หนองบุญมาก"
      },
      {
      "districts":[
      {
      "code":"301607",
      "display":"กงรถ"
      },
      {
      "code":"301410",
      "display":"งิ้ว"
      },
      {
      "code":"301609",
      "display":"ตะโก"
      },
      {
      "code":"301602",
      "display":"ทับสวาย"
      },
      {
      "code":"301604",
      "display":"หลุ่งตะเคียน"
      },
      {
      "code":"301608",
      "display":"หลุ่งประดู่"
      },
      {
      "code":"300813",
      "display":"หินดาด"
      },
      {
      "code":"301610",
      "display":"ห้วยแคน"
      },
      {
      "code":"301601",
      "display":"ห้วยแถลง"
      },
      {
      "code":"301603",
      "display":"เมืองพลับพลา"
      }
      ],
      "code":"3016",
      "display":"ห้วยแถลง"
      },
      {
      "districts":[
      {
      "code":"300612",
      "display":"ช้างทอง"
      },
      {
      "code":"300602",
      "display":"ท่าช้าง"
      },
      {
      "code":"300609",
      "display":"พระพุทธ"
      },
      {
      "code":"300606",
      "display":"หนองงูเหลือม"
      },
      {
      "code":"300608",
      "display":"หนองยาง"
      }
      ],
      "code":"3032",
      "display":"เฉลิมพระเกียรติ"
      },
      {
      "districts":[
      {
      "code":"300819",
      "display":"บึงปรือ"
      },
      {
      "code":"302604",
      "display":"วังยายทอง"
      },
      {
      "code":"300814",
      "display":"สำนักตะคร้อ"
      },
      {
      "code":"300816",
      "display":"หนองแวง"
      }
      ],
      "code":"3026",
      "display":"เทพารักษ์"
      },
      {
      "districts":[
      {
      "code":"300116",
      "display":"จอหอ"
      },
      {
      "code":"300122",
      "display":"ตลาด"
      },
      {
      "code":"300112",
      "display":"บ้านเกาะ"
      },
      {
      "code":"300115",
      "display":"บ้านโพธิ์"
      },
      {
      "code":"300113",
      "display":"บ้านใหม่"
      },
      {
      "code":"300107",
      "display":"ปรุใหญ่"
      },
      {
      "code":"300109",
      "display":"พลกรัง"
      },
      {
      "code":"300123",
      "display":"พะเนา"
      },
      {
      "code":"300114",
      "display":"พุดซา"
      },
      {
      "code":"300105",
      "display":"มะเริง"
      },
      {
      "code":"300121",
      "display":"สีมุม"
      },
      {
      "code":"300120",
      "display":"สุรนารี"
      },
      {
      "code":"300124",
      "display":"หนองกระทุ่ม"
      },
      {
      "code":"300103",
      "display":"หนองจะบก"
      },
      {
      "code":"300119",
      "display":"หนองบัวศาลา"
      },
      {
      "code":"300106",
      "display":"หนองระเวียง"
      },
      {
      "code":"300125",
      "display":"หนองไข่น้ำ"
      },
      {
      "code":"300110",
      "display":"หนองไผ่ล้อม"
      },
      {
      "code":"300108",
      "display":"หมื่นไวย"
      },
      {
      "code":"300111",
      "display":"หัวทะเล"
      },
      {
      "code":"300117",
      "display":"โคกกรวด"
      },
      {
      "code":"300104",
      "display":"โคกสูง"
      },
      {
      "code":"300102",
      "display":"โพธิ์กลาง"
      },
      {
      "code":"300101",
      "display":"ในเมือง"
      },
      {
      "code":"300118",
      "display":"ไชยมงคล"
      }
      ],
      "code":"3001",
      "display":"เมืองนครราชสีมา"
      },
      {
      "districts":[
      {
      "code":"301708",
      "display":"กระเบื้องนอก"
      },
      {
      "code":"301712",
      "display":"ละหานปลาค้าว"
      },
      {
      "code":"301709",
      "display":"เมืองยาง"
      },
      {
      "code":"301713",
      "display":"โนนอุดม"
      }
      ],
      "code":"3027",
      "display":"เมืองยาง"
      },
      {
      "districts":[
      {
      "code":"300304",
      "display":"กุดโบสถ์"
      },
      {
      "code":"300306",
      "display":"บ้านราษฎร์"
      },
      {
      "code":"300302",
      "display":"สระตะเคียน"
      },
      {
      "code":"300305",
      "display":"สุขไพบูลย์"
      },
      {
      "code":"300301",
      "display":"เสิงสาง"
      },
      {
      "code":"300303",
      "display":"โนนสมบูรณ์"
      }
      ],
      "code":"3003",
      "display":"เสิงสาง"
      },
      {
      "districts":[
      {
      "code":"301205",
      "display":"บึงพะไล"
      },
      {
      "code":"302305",
      "display":"บึงสำโรง"
      },
      {
      "code":"300604",
      "display":"สีสุก"
      },
      {
      "code":"301213",
      "display":"แก้งสนามนาง"
      },
      {
      "code":"301219",
      "display":"โนนสำราญ"
      }
      ],
      "code":"3023",
      "display":"แก้งสนามนาง"
      },
      {
      "districts":[
      {
      "code":"300701",
      "display":"กระโทก"
      },
      {
      "code":"300710",
      "display":"ด่านเกวียน"
      },
      {
      "code":"300704",
      "display":"ทุ่งอรุณ"
      },
      {
      "code":"300706",
      "display":"ท่าจะหลุง"
      },
      {
      "code":"300705",
      "display":"ท่าลาดขาว"
      },
      {
      "code":"300703",
      "display":"ท่าอ่าง"
      },
      {
      "code":"300707",
      "display":"ท่าเยี่ยม"
      },
      {
      "code":"300702",
      "display":"พลับพลา"
      },
      {
      "code":"300709",
      "display":"ละลมใหม่พัฒนา"
      },
      {
      "code":"300708",
      "display":"โชคชัย"
      }
      ],
      "code":"3007",
      "display":"โชคชัย"
      },
      {
      "districts":[
      {
      "code":"301011",
      "display":"ขามเฒ่า"
      },
      {
      "code":"301010",
      "display":"จันอัด"
      },
      {
      "code":"301005",
      "display":"ดอนชมพู"
      },
      {
      "code":"301015",
      "display":"ดอนหวาย"
      },
      {
      "code":"301012",
      "display":"ด่านคล้า"
      },
      {
      "code":"301006",
      "display":"ธารปราสาท"
      },
      {
      "code":"301004",
      "display":"บิง"
      },
      {
      "code":"301009",
      "display":"พลสงคราม"
      },
      {
      "code":"300914",
      "display":"มะค่า"
      },
      {
      "code":"301013",
      "display":"ลำคอหงษ์"
      },
      {
      "code":"301016",
      "display":"ลำมูล"
      },
      {
      "code":"301007",
      "display":"หลุมข้าว"
      },
      {
      "code":"301014",
      "display":"เมืองปราสาท"
      },
      {
      "code":"301003",
      "display":"โตนด"
      },
      {
      "code":"301001",
      "display":"โนนสูง"
      },
      {
      "code":"301002",
      "display":"ใหม่"
      }
      ],
      "code":"3010",
      "display":"โนนสูง"
      },
      {
      "districts":[
      {
      "code":"301316",
      "display":"ดอนยาวใหญ่"
      },
      {
      "code":"301305",
      "display":"วังหิน"
      },
      {
      "code":"301312",
      "display":"สำพะเนียง"
      },
      {
      "code":"301311",
      "display":"โนนตาเถร"
      },
      {
      "code":"301302",
      "display":"โนนแดง"
      }
      ],
      "code":"3024",
      "display":"โนนแดง"
      },
      {
      "districts":[
      {
      "code":"300903",
      "display":"กำปัง"
      },
      {
      "code":"300905",
      "display":"ค้างพลู"
      },
      {
      "code":"300902",
      "display":"ด่านจาก"
      },
      {
      "code":"300909",
      "display":"ถนนโพธิ์"
      },
      {
      "code":"300907",
      "display":"บัลลังก์"
      },
      {
      "code":"300906",
      "display":"บ้านวัง"
      },
      {
      "code":"300914",
      "display":"มะค่า"
      },
      {
      "code":"300908",
      "display":"สายออ"
      },
      {
      "code":"300904",
      "display":"สำโรง"
      },
      {
      "code":"300901",
      "display":"โนนไทย"
      }
      ],
      "code":"3009",
      "display":"โนนไทย"
      }
      ],
      "display":"นครราชสีมา"
      },
      "นครศรีธรรมราช":{
      "sort_order":"20",
      "code":"80",
      "amphoe":[
      {
      "districts":[
      {
      "code":"801501",
      "display":"ขนอม"
      },
      {
      "code":"801502",
      "display":"ควนทอง"
      },
      {
      "code":"801503",
      "display":"ท้องเนียน"
      }
      ],
      "code":"8015",
      "display":"ขนอม"
      },
      {
      "districts":[
      {
      "code":"801311",
      "display":"ควนหนองคว้า"
      },
      {
      "code":"801310",
      "display":"ทุ่งโพธิ์"
      },
      {
      "code":"801309",
      "display":"นาหมอบุญ"
      },
      {
      "code":"800712",
      "display":"บ้านควนมุด"
      },
      {
      "code":"800713",
      "display":"บ้านชะอวด"
      },
      {
      "code":"801307",
      "display":"สามตำบล"
      }
      ],
      "code":"8019",
      "display":"จุฬาภรณ์"
      },
      {
      "districts":[
      {
      "code":"800406",
      "display":"กะเปียด"
      },
      {
      "code":"800416",
      "display":"จันดี"
      },
      {
      "code":"800401",
      "display":"ฉวาง"
      },
      {
      "code":"800407",
      "display":"นากะชะ"
      },
      {
      "code":"800415",
      "display":"นาเขลียง"
      },
      {
      "code":"800404",
      "display":"นาแว"
      },
      {
      "code":"800403",
      "display":"ละอาย"
      },
      {
      "code":"800409",
      "display":"ห้วยปริก"
      },
      {
      "code":"800405",
      "display":"ไม้เรียง"
      },
      {
      "code":"800410",
      "display":"ไสหร้า"
      }
      ],
      "code":"8004",
      "display":"ฉวาง"
      },
      {
      "districts":[
      {
      "code":"800707",
      "display":"ขอนหาด"
      },
      {
      "code":"800709",
      "display":"ควนหนองหงษ์"
      },
      {
      "code":"800701",
      "display":"ชะอวด"
      },
      {
      "code":"800703",
      "display":"ท่าประจะ"
      },
      {
      "code":"800702",
      "display":"ท่าเสม็ด"
      },
      {
      "code":"800711",
      "display":"นางหลง"
      },
      {
      "code":"800706",
      "display":"บ้านตูล"
      },
      {
      "code":"800705",
      "display":"วังอ่าง"
      },
      {
      "code":"800708",
      "display":"เกาะขันธ์"
      },
      {
      "code":"800710",
      "display":"เขาพระทอง"
      },
      {
      "code":"800704",
      "display":"เคร็ง"
      }
      ],
      "code":"8007",
      "display":"ชะอวด"
      },
      {
      "districts":[
      {
      "code":"800402",
      "display":"ช้างกลาง"
      },
      {
      "code":"800412",
      "display":"สวนขัน"
      },
      {
      "code":"800411",
      "display":"หลักช้าง"
      }
      ],
      "code":"8022",
      "display":"ช้างกลาง"
      },
      {
      "districts":[
      {
      "code":"800413",
      "display":"คลองเส"
      },
      {
      "code":"800414",
      "display":"ดุสิต"
      },
      {
      "code":"800408",
      "display":"ถ้ำพรรณรา"
      }
      ],
      "code":"8018",
      "display":"ถ้ำพรรณรา"
      },
      {
      "districts":[
      {
      "code":"800908",
      "display":"กะปาง"
      },
      {
      "code":"800904",
      "display":"ควนกรด"
      },
      {
      "code":"800902",
      "display":"ชะมาย"
      },
      {
      "code":"800911",
      "display":"ถ้ำใหญ่"
      },
      {
      "code":"800909",
      "display":"ที่วัง"
      },
      {
      "code":"800906",
      "display":"นาหลวงเสน"
      },
      {
      "code":"800912",
      "display":"นาโพธิ์"
      },
      {
      "code":"800905",
      "display":"นาไม้ไผ่"
      },
      {
      "code":"800910",
      "display":"น้ำตก"
      },
      {
      "code":"800901",
      "display":"ปากแพรก"
      },
      {
      "code":"800903",
      "display":"หนองหงส์"
      },
      {
      "code":"800913",
      "display":"เขาขาว"
      },
      {
      "code":"800907",
      "display":"เขาโร"
      }
      ],
      "code":"8009",
      "display":"ทุ่งสง"
      },
      {
      "districts":[
      {
      "code":"801107",
      "display":"กรุงหยัน"
      },
      {
      "code":"801104",
      "display":"กุแหระ"
      },
      {
      "code":"801102",
      "display":"ทุ่งสัง"
      },
      {
      "code":"801103",
      "display":"ทุ่งใหญ่"
      },
      {
      "code":"801101",
      "display":"ท่ายาง"
      },
      {
      "code":"801106",
      "display":"บางรูป"
      },
      {
      "code":"801105",
      "display":"ปริก"
      }
      ],
      "code":"8011",
      "display":"ทุ่งใหญ่"
      },
      {
      "districts":[
      {
      "code":"800802",
      "display":"กลาย"
      },
      {
      "code":"800810",
      "display":"ดอนตะโก"
      },
      {
      "code":"800811",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"800803",
      "display":"ท่าขึ้น"
      },
      {
      "code":"800801",
      "display":"ท่าศาลา"
      },
      {
      "code":"800806",
      "display":"สระแก้ว"
      },
      {
      "code":"800804",
      "display":"หัวตะพาน"
      },
      {
      "code":"800813",
      "display":"โพธิ์ทอง"
      },
      {
      "code":"800807",
      "display":"โมคลาน"
      },
      {
      "code":"800809",
      "display":"ไทยบุรี"
      }
      ],
      "code":"8008",
      "display":"ท่าศาลา"
      },
      {
      "districts":[
      {
      "code":"800812",
      "display":"กรุงชิง"
      },
      {
      "code":"800805",
      "display":"กะหรอ"
      },
      {
      "code":"800808",
      "display":"นบพิตำ"
      },
      {
      "code":"800814",
      "display":"นาเหรง"
      }
      ],
      "code":"8021",
      "display":"นบพิตำ"
      },
      {
      "districts":[
      {
      "code":"800998",
      "display":"ทุ่งสง"
      },
      {
      "code":"800999",
      "display":"นาบอน"
      },
      {
      "code":"800997",
      "display":"แก้วแสน"
      }
      ],
      "code":"8010",
      "display":"นาบอน"
      },
      {
      "districts":[
      {
      "code":"800996",
      "display":"บางขัน"
      },
      {
      "code":"801704",
      "display":"บ้านนิคม"
      },
      {
      "code":"800995",
      "display":"บ้านลำนาว"
      },
      {
      "code":"800994",
      "display":"วังหิน"
      }
      ],
      "code":"8017",
      "display":"บางขัน"
      },
      {
      "districts":[
      {
      "code":"801218",
      "display":"ขนาบนาก"
      },
      {
      "code":"801205",
      "display":"คลองกระบือ"
      },
      {
      "code":"801202",
      "display":"คลองน้อย"
      },
      {
      "code":"801204",
      "display":"ชะเมา"
      },
      {
      "code":"801216",
      "display":"ท่าพยา"
      },
      {
      "code":"801213",
      "display":"บางตะพง"
      },
      {
      "code":"801212",
      "display":"บางพระ"
      },
      {
      "code":"801211",
      "display":"บางศาลา"
      },
      {
      "code":"801215",
      "display":"บ้านเพิง"
      },
      {
      "code":"801207",
      "display":"บ้านใหม่"
      },
      {
      "code":"801201",
      "display":"ปากพนัง"
      },
      {
      "code":"801210",
      "display":"ปากพนังฝั่งตะวันตก"
      },
      {
      "code":"801214",
      "display":"ปากพนังฝั่งตะวันออก"
      },
      {
      "code":"800901",
      "display":"ปากแพรก"
      },
      {
      "code":"801203",
      "display":"ป่าระกำ"
      },
      {
      "code":"801208",
      "display":"หูล่อง"
      },
      {
      "code":"801206",
      "display":"เกาะทวด"
      },
      {
      "code":"801209",
      "display":"แหลมตะลุมพุก"
      }
      ],
      "code":"8012",
      "display":"ปากพนัง"
      },
      {
      "districts":[
      {
      "code":"800204",
      "display":"ทอนหงส์"
      },
      {
      "code":"800205",
      "display":"นาเรียง"
      },
      {
      "code":"800199",
      "display":"บ้านเกาะ"
      },
      {
      "code":"800196",
      "display":"พรหมโลก"
      },
      {
      "code":"800195",
      "display":"อินคีรี"
      }
      ],
      "code":"8002",
      "display":"พรหมคีรี"
      },
      {
      "districts":[
      {
      "code":"800110",
      "display":"ช้างซ้าย"
      },
      {
      "code":"800117",
      "display":"ท้ายสำเภา"
      },
      {
      "code":"800109",
      "display":"นาพรุ"
      },
      {
      "code":"800111",
      "display":"นาสาร"
      }
      ],
      "code":"8020",
      "display":"พระพรหม"
      },
      {
      "districts":[
      {
      "code":"800502",
      "display":"กะทูน"
      },
      {
      "code":"800505",
      "display":"ควนกลาง"
      },
      {
      "code":"800501",
      "display":"พิปูน"
      },
      {
      "code":"800504",
      "display":"ยางค้อม"
      },
      {
      "code":"800503",
      "display":"เขาพระ"
      }
      ],
      "code":"8005",
      "display":"พิปูน"
      },
      {
      "districts":[
      {
      "code":"801306",
      "display":"ควนชุม"
      },
      {
      "code":"801305",
      "display":"ควนพัง"
      },
      {
      "code":"801304",
      "display":"ควนเกย"
      },
      {
      "code":"801301",
      "display":"ร่อนพิบูลย์"
      },
      {
      "code":"801302",
      "display":"หินตก"
      },
      {
      "code":"801303",
      "display":"เสาธง"
      }
      ],
      "code":"8013",
      "display":"ร่อนพิบูลย์"
      },
      {
      "districts":[
      {
      "code":"800304",
      "display":"กำโลน"
      },
      {
      "code":"800305",
      "display":"ขุนทะเล"
      },
      {
      "code":"800303",
      "display":"ท่าดี"
      },
      {
      "code":"800302",
      "display":"ลานสกา"
      },
      {
      "code":"800301",
      "display":"เขาแก้ว"
      }
      ],
      "code":"8003",
      "display":"ลานสกา"
      },
      {
      "districts":[
      {
      "code":"801403",
      "display":"ฉลอง"
      },
      {
      "code":"801402",
      "display":"ทุ่งปรัง"
      },
      {
      "code":"801409",
      "display":"ทุ่งใส"
      },
      {
      "code":"801401",
      "display":"สิชล"
      },
      {
      "code":"801406",
      "display":"สี่ขีด"
      },
      {
      "code":"801408",
      "display":"เขาน้อย"
      },
      {
      "code":"801407",
      "display":"เทพราช"
      },
      {
      "code":"801405",
      "display":"เปลี่ยน"
      },
      {
      "code":"801404",
      "display":"เสาเภา"
      }
      ],
      "code":"8014",
      "display":"สิชล"
      },
      {
      "districts":[
      {
      "code":"801609",
      "display":"ควนชะลิก"
      },
      {
      "code":"801603",
      "display":"ทรายขาว"
      },
      {
      "code":"801608",
      "display":"ท่าซอม"
      },
      {
      "code":"801607",
      "display":"บางนบ"
      },
      {
      "code":"801606",
      "display":"บ้านราม"
      },
      {
      "code":"801610",
      "display":"รามแก้ว"
      },
      {
      "code":"801602",
      "display":"หน้าสตน"
      },
      {
      "code":"801601",
      "display":"หัวไทร"
      },
      {
      "code":"801611",
      "display":"เกาะเพชร"
      },
      {
      "code":"801605",
      "display":"เขาพังไกร"
      },
      {
      "code":"801604",
      "display":"แหลม"
      }
      ],
      "code":"8016",
      "display":"หัวไทร"
      },
      {
      "districts":[
      {
      "code":"800608",
      "display":"ดอนตรอ"
      },
      {
      "code":"801308",
      "display":"ทางพูน"
      },
      {
      "code":"800609",
      "display":"สวนหลวง"
      },
      {
      "code":"800602",
      "display":"เชียรเขา"
      }
      ],
      "code":"8023",
      "display":"เฉลิมพระเกียรติ"
      },
      {
      "districts":[
      {
      "code":"800611",
      "display":"การะเกด"
      },
      {
      "code":"800603",
      "display":"ท่าขนาน"
      },
      {
      "code":"800607",
      "display":"ท้องลำเจียก"
      },
      {
      "code":"800604",
      "display":"บ้านกลาง"
      },
      {
      "code":"800605",
      "display":"บ้านเนิน"
      },
      {
      "code":"800612",
      "display":"เขาพระบาท"
      },
      {
      "code":"800601",
      "display":"เชียรใหญ่"
      },
      {
      "code":"800610",
      "display":"เสือหึง"
      },
      {
      "code":"800613",
      "display":"แม่เจ้าอยู่หัว"
      },
      {
      "code":"800606",
      "display":"ไสหมาก"
      }
      ],
      "code":"8006",
      "display":"เชียรใหญ่"
      },
      {
      "districts":[
      {
      "code":"800112",
      "display":"กำแพงเซา"
      },
      {
      "code":"800103",
      "display":"คลัง"
      },
      {
      "code":"800116",
      "display":"ท่างิ้ว"
      },
      {
      "code":"800121",
      "display":"ท่าซัก"
      },
      {
      "code":"800102",
      "display":"ท่าวัง"
      },
      {
      "code":"800122",
      "display":"ท่าเรือ"
      },
      {
      "code":"800106",
      "display":"ท่าไร่"
      },
      {
      "code":"800108",
      "display":"นาทราย"
      },
      {
      "code":"800115",
      "display":"นาเคียน"
      },
      {
      "code":"800119",
      "display":"บางจาก"
      },
      {
      "code":"800107",
      "display":"ปากนคร"
      },
      {
      "code":"800120",
      "display":"ปากพูน"
      },
      {
      "code":"800114",
      "display":"มะม่วงสองต้น"
      },
      {
      "code":"800118",
      "display":"โพธิ์เสด็จ"
      },
      {
      "code":"800101",
      "display":"ในเมือง"
      },
      {
      "code":"800113",
      "display":"ไชยมนตรี"
      }
      ],
      "code":"8001",
      "display":"เมืองนครศรีธรรมราช"
      }
      ],
      "display":"นครศรีธรรมราช"
      },
      "นครสวรรค์":{
      "sort_order":"21",
      "code":"60",
      "amphoe":[
      {
      "districts":[
      {
      "code":"601308",
      "display":"ชุมตาบง"
      },
      {
      "code":"601306",
      "display":"ปางสวรรค์"
      }
      ],
      "code":"6015",
      "display":"ชุมตาบง"
      },
      {
      "districts":[
      {
      "code":"600311",
      "display":"ฆะมัง"
      },
      {
      "code":"600301",
      "display":"ชุมแสง"
      },
      {
      "code":"600302",
      "display":"ทับกฤช"
      },
      {
      "code":"600312",
      "display":"ทับกฤชใต้"
      },
      {
      "code":"600305",
      "display":"ท่าไม้"
      },
      {
      "code":"600306",
      "display":"บางเคียน"
      },
      {
      "code":"600308",
      "display":"พันลาน"
      },
      {
      "code":"600303",
      "display":"พิกุล"
      },
      {
      "code":"600307",
      "display":"หนองกระเจา"
      },
      {
      "code":"600304",
      "display":"เกยไชย"
      },
      {
      "code":"600309",
      "display":"โคกหม้อ"
      },
      {
      "code":"600310",
      "display":"ไผ่สิงห์"
      }
      ],
      "code":"6003",
      "display":"ชุมแสง"
      },
      {
      "districts":[
      {
      "code":"601201",
      "display":"ตากฟ้า"
      },
      {
      "code":"601205",
      "display":"พุนกยูง"
      },
      {
      "code":"601202",
      "display":"ลำพยนต์"
      },
      {
      "code":"601203",
      "display":"สุขสำราญ"
      },
      {
      "code":"601204",
      "display":"หนองพิกุล"
      },
      {
      "code":"601206",
      "display":"อุดมธัญญา"
      },
      {
      "code":"601207",
      "display":"เขาชายธง"
      }
      ],
      "code":"6012",
      "display":"ตากฟ้า"
      },
      {
      "districts":[
      {
      "code":"600703",
      "display":"จันเสน"
      },
      {
      "code":"600702",
      "display":"ช่องแค"
      },
      {
      "code":"600701",
      "display":"ตาคลี"
      },
      {
      "code":"600710",
      "display":"พรหมนิมิต"
      },
      {
      "code":"600709",
      "display":"ลาดทิพรส"
      },
      {
      "code":"600708",
      "display":"สร้อยทอง"
      },
      {
      "code":"600707",
      "display":"หนองหม้อ"
      },
      {
      "code":"600706",
      "display":"หนองโพ"
      },
      {
      "code":"600705",
      "display":"หัวหวาย"
      },
      {
      "code":"600704",
      "display":"ห้วยหอม"
      }
      ],
      "code":"6007",
      "display":"ตาคลี"
      },
      {
      "districts":[
      {
      "code":"600806",
      "display":"ดอนคา"
      },
      {
      "code":"600807",
      "display":"ทำนบ"
      },
      {
      "code":"600801",
      "display":"ท่าตะโก"
      },
      {
      "code":"600802",
      "display":"พนมรอก"
      },
      {
      "code":"600809",
      "display":"พนมเศษ"
      },
      {
      "code":"600805",
      "display":"วังมหากร"
      },
      {
      "code":"600808",
      "display":"วังใหญ่"
      },
      {
      "code":"600804",
      "display":"สายลำโพง"
      },
      {
      "code":"600810",
      "display":"หนองหลวง"
      },
      {
      "code":"600803",
      "display":"หัวถนน"
      }
      ],
      "code":"6008",
      "display":"ท่าตะโก"
      },
      {
      "districts":[
      {
      "code":"600509",
      "display":"ด่านช้าง"
      },
      {
      "code":"600507",
      "display":"ตาขีด"
      },
      {
      "code":"600508",
      "display":"ตาสัง"
      },
      {
      "code":"600501",
      "display":"ท่างิ้ว"
      },
      {
      "code":"600502",
      "display":"บางตาหงาย"
      },
      {
      "code":"600506",
      "display":"บางแก้ว"
      },
      {
      "code":"600512",
      "display":"บึงปลาทู"
      },
      {
      "code":"600505",
      "display":"บ้านแดน"
      },
      {
      "code":"600114",
      "display":"หนองกรด"
      },
      {
      "code":"600511",
      "display":"หนองตางู"
      },
      {
      "code":"600503",
      "display":"หูกวาง"
      },
      {
      "code":"600504",
      "display":"อ่างทอง"
      },
      {
      "code":"600513",
      "display":"เจริญผล"
      }
      ],
      "code":"6005",
      "display":"บรรพตพิสัย"
      },
      {
      "districts":[
      {
      "code":"601008",
      "display":"ท่าน้ำอ้อย"
      },
      {
      "code":"601003",
      "display":"นิคมเขาบ่อแก้ว"
      },
      {
      "code":"601009",
      "display":"น้ำทรง"
      },
      {
      "code":"601001",
      "display":"พยุหะ"
      },
      {
      "code":"601004",
      "display":"ม่วงหัก"
      },
      {
      "code":"601005",
      "display":"ยางขาว"
      },
      {
      "code":"601006",
      "display":"ย่านมัทรี"
      },
      {
      "code":"601011",
      "display":"สระทะเล"
      },
      {
      "code":"601010",
      "display":"เขากะลา"
      },
      {
      "code":"601007",
      "display":"เขาทอง"
      },
      {
      "code":"601002",
      "display":"เนินมะกอก"
      }
      ],
      "code":"6010",
      "display":"พยุหะคีรี"
      },
      {
      "districts":[
      {
      "code":"601109",
      "display":"บ้านไร่"
      },
      {
      "code":"601106",
      "display":"มาบแก"
      },
      {
      "code":"601101",
      "display":"ลาดยาว"
      },
      {
      "code":"601103",
      "display":"วังม้า"
      },
      {
      "code":"601104",
      "display":"วังเมือง"
      },
      {
      "code":"601116",
      "display":"ศาลเจ้าไก่ต่อ"
      },
      {
      "code":"601117",
      "display":"สระแก้ว"
      },
      {
      "code":"601105",
      "display":"สร้อยละคร"
      },
      {
      "code":"601108",
      "display":"หนองนมวัว"
      },
      {
      "code":"601107",
      "display":"หนองยาว"
      },
      {
      "code":"601102",
      "display":"ห้วยน้ำหอม"
      },
      {
      "code":"601110",
      "display":"เนินขี้เหล็ก"
      }
      ],
      "code":"6011",
      "display":"ลาดยาว"
      },
      {
      "districts":[
      {
      "code":"600408",
      "display":"ทุ่งทอง"
      },
      {
      "code":"600403",
      "display":"ธารทหาร"
      },
      {
      "code":"600409",
      "display":"วังบ่อ"
      },
      {
      "code":"600402",
      "display":"หนองกลับ"
      },
      {
      "code":"600401",
      "display":"หนองบัว"
      },
      {
      "code":"600406",
      "display":"ห้วยถั่วเหนือ"
      },
      {
      "code":"600405",
      "display":"ห้วยถั่วใต้"
      },
      {
      "code":"600404",
      "display":"ห้วยร่วม"
      },
      {
      "code":"600407",
      "display":"ห้วยใหญ่"
      }
      ],
      "code":"6004",
      "display":"หนองบัว"
      },
      {
      "districts":[
      {
      "code":"600601",
      "display":"มหาโพธิ"
      },
      {
      "code":"600603",
      "display":"หนองเต่า"
      },
      {
      "code":"600605",
      "display":"หัวดง"
      },
      {
      "code":"600602",
      "display":"เก้าเลี้ยว"
      },
      {
      "code":"600604",
      "display":"เขาดิน"
      }
      ],
      "code":"6006",
      "display":"เก้าเลี้ยว"
      },
      {
      "districts":[
      {
      "code":"600102",
      "display":"กลางแดด"
      },
      {
      "code":"600105",
      "display":"ตะเคียนเลื่อน"
      },
      {
      "code":"600106",
      "display":"นครสวรรค์ตก"
      },
      {
      "code":"600107",
      "display":"นครสวรรค์ออก"
      },
      {
      "code":"600108",
      "display":"บางพระหลวง"
      },
      {
      "code":"600109",
      "display":"บางม่วง"
      },
      {
      "code":"600117",
      "display":"บึงเสนาท"
      },
      {
      "code":"600110",
      "display":"บ้านมะเกลือ"
      },
      {
      "code":"600111",
      "display":"บ้านแก่ง"
      },
      {
      "code":"600101",
      "display":"ปากน้ำโพ"
      },
      {
      "code":"600112",
      "display":"พระนอน"
      },
      {
      "code":"FALSE",
      "display":"วัดไทรย์"
      },
      {
      "code":"600114",
      "display":"หนองกรด"
      },
      {
      "code":"600115",
      "display":"หนองกระโดน"
      },
      {
      "code":"600116",
      "display":"หนองปลิง"
      },
      {
      "code":"600103",
      "display":"เกรียงไกร"
      },
      {
      "code":"600104",
      "display":"แควใหญ่"
      }
      ],
      "code":"6001",
      "display":"เมืองนครสวรรค์"
      },
      {
      "districts":[
      {
      "code":"601304",
      "display":"วังซ่าน"
      },
      {
      "code":"601305",
      "display":"เขาชนกัน"
      },
      {
      "code":"601301",
      "display":"แม่วงก์"
      },
      {
      "code":"601303",
      "display":"แม่เล่ย์"
      }
      ],
      "code":"6013",
      "display":"แม่วงก์"
      },
      {
      "districts":[
      {
      "code":"601307",
      "display":"แม่เปิน"
      }
      ],
      "code":"6014",
      "display":"แม่เปิน"
      },
      {
      "districts":[
      {
      "code":"600205",
      "display":"นากลาง"
      },
      {
      "code":"600204",
      "display":"บางประมุง"
      },
      {
      "code":"600203",
      "display":"บางมะฝ่อ"
      },
      {
      "code":"600202",
      "display":"ยางตาล"
      },
      {
      "code":"600206",
      "display":"ศาลาแดง"
      },
      {
      "code":"600209",
      "display":"หาดสูง"
      },
      {
      "code":"600207",
      "display":"เนินกว้าว"
      },
      {
      "code":"600208",
      "display":"เนินศาลา"
      },
      {
      "code":"600201",
      "display":"โกรกพระ"
      }
      ],
      "code":"6002",
      "display":"โกรกพระ"
      },
      {
      "districts":[
      {
      "code":"600904",
      "display":"ตะคร้อ"
      },
      {
      "code":"600907",
      "display":"นาขอม"
      },
      {
      "code":"600906",
      "display":"วังข่อย"
      },
      {
      "code":"600903",
      "display":"วังน้ำลัด"
      },
      {
      "code":"600902",
      "display":"สำโรงชัย"
      },
      {
      "code":"600901",
      "display":"โคกเดื่อ"
      },
      {
      "code":"600905",
      "display":"โพธิ์ประสาท"
      },
      {
      "code":"600908",
      "display":"ไพศาลี"
      }
      ],
      "code":"6009",
      "display":"ไพศาลี"
      }
      ],
      "display":"นครสวรรค์"
      },
      "นนทบุรี":{
      "sort_order":"22",
      "code":"12",
      "amphoe":[
      {
      "districts":[
      {
      "code":"120202",
      "display":"บางกรวย"
      },
      {
      "code":"120204",
      "display":"บางขนุน"
      },
      {
      "code":"120205",
      "display":"บางขุนกอง"
      },
      {
      "code":"120206",
      "display":"บางคูเวียง"
      },
      {
      "code":"120203",
      "display":"บางสีทอง"
      },
      {
      "code":"120208",
      "display":"ปลายบาง"
      },
      {
      "code":"120207",
      "display":"มหาสวัสดิ์"
      },
      {
      "code":"120201",
      "display":"วัดชลอ"
      },
      {
      "code":"120209",
      "display":"ศาลากลาง"
      }
      ],
      "code":"1202",
      "display":"บางกรวย"
      },
      {
      "districts":[
      {
      "code":"120404",
      "display":"บางคูรัด"
      },
      {
      "code":"120402",
      "display":"บางบัวทอง"
      },
      {
      "code":"120408",
      "display":"บางรักพัฒนา"
      },
      {
      "code":"120403",
      "display":"บางรักใหญ่"
      },
      {
      "code":"120407",
      "display":"พิมลราช"
      },
      {
      "code":"120405",
      "display":"ละหาร"
      },
      {
      "code":"120406",
      "display":"ลำโพ"
      },
      {
      "code":"120401",
      "display":"โสนลอย"
      }
      ],
      "code":"1204",
      "display":"บางบัวทอง"
      },
      {
      "districts":[
      {
      "code":"120301",
      "display":"บางม่วง"
      },
      {
      "code":"120303",
      "display":"บางเลน"
      },
      {
      "code":"120302",
      "display":"บางแม่นาง"
      },
      {
      "code":"120305",
      "display":"บางใหญ่"
      },
      {
      "code":"120306",
      "display":"บ้านใหม่"
      },
      {
      "code":"120304",
      "display":"เสาธงหิน"
      }
      ],
      "code":"1203",
      "display":"บางใหญ่"
      },
      {
      "districts":[
      {
      "code":"120610",
      "display":"คลองข่อย"
      },
      {
      "code":"120606",
      "display":"คลองพระอุดม"
      },
      {
      "code":"120612",
      "display":"คลองเกลือ"
      },
      {
      "code":"120607",
      "display":"ท่าอิฐ"
      },
      {
      "code":"120602",
      "display":"บางตลาด"
      },
      {
      "code":"120605",
      "display":"บางตะไนย์"
      },
      {
      "code":"120611",
      "display":"บางพลับ"
      },
      {
      "code":"120604",
      "display":"บางพูด"
      },
      {
      "code":"120306",
      "display":"บ้านใหม่"
      },
      {
      "code":"120601",
      "display":"ปากเกร็ด"
      },
      {
      "code":"120609",
      "display":"อ้อมเกร็ด"
      },
      {
      "code":"120608",
      "display":"เกาะเกร็ด"
      }
      ],
      "code":"1206",
      "display":"ปากเกร็ด"
      },
      {
      "districts":[
      {
      "code":"120102",
      "display":"ตลาดขวัญ"
      },
      {
      "code":"120105",
      "display":"ท่าทราย"
      },
      {
      "code":"120104",
      "display":"บางกระสอ"
      },
      {
      "code":"120108",
      "display":"บางกร่าง"
      },
      {
      "code":"120110",
      "display":"บางรักน้อย"
      },
      {
      "code":"120107",
      "display":"บางศรีเมือง"
      },
      {
      "code":"120103",
      "display":"บางเขน"
      },
      {
      "code":"120106",
      "display":"บางไผ่"
      },
      {
      "code":"120101",
      "display":"สวนใหญ่"
      },
      {
      "code":"120109",
      "display":"ไทรม้า"
      }
      ],
      "code":"1201",
      "display":"เมืองนนทบุรี"
      },
      {
      "districts":[
      {
      "code":"120505",
      "display":"ขุนศรี"
      },
      {
      "code":"120506",
      "display":"คลองขวาง"
      },
      {
      "code":"120507",
      "display":"ทวีวัฒนา"
      },
      {
      "code":"120502",
      "display":"ราษฎร์นิยม"
      },
      {
      "code":"120503",
      "display":"หนองเพรางาย"
      },
      {
      "code":"120501",
      "display":"ไทรน้อย"
      },
      {
      "code":"120504",
      "display":"ไทรใหญ่"
      }
      ],
      "code":"1205",
      "display":"ไทรน้อย"
      }
      ],
      "display":"นนทบุรี"
      },
      "นราธิวาส":{
      "sort_order":"23",
      "code":"96",
      "amphoe":[
      {
      "districts":[
      {
      "code":"960599",
      "display":"จะแนะ"
      },
      {
      "code":"961204",
      "display":"ช้างเผือก"
      },
      {
      "code":"960598",
      "display":"ดุซงญอ"
      },
      {
      "code":"961203",
      "display":"ผดุงมาตร"
      }
      ],
      "code":"9612",
      "display":"จะแนะ"
      },
      {
      "districts":[
      {
      "code":"960207",
      "display":"นานาค"
      },
      {
      "code":"960205",
      "display":"บางขุนทอง"
      },
      {
      "code":"960203",
      "display":"พร่อน"
      },
      {
      "code":"960204",
      "display":"ศาลาใหม่"
      },
      {
      "code":"960206",
      "display":"เกาะสะท้อน"
      },
      {
      "code":"960201",
      "display":"เจ๊ะเห"
      },
      {
      "code":"960208",
      "display":"โฆษิต"
      },
      {
      "code":"960202",
      "display":"ไพรวัน"
      }
      ],
      "code":"9602",
      "display":"ตากใบ"
      },
      {
      "districts":[
      {
      "code":"960303",
      "display":"กาเยาะมาตี"
      },
      {
      "code":"960301",
      "display":"บาเจาะ"
      },
      {
      "code":"960305",
      "display":"บาเระเหนือ"
      },
      {
      "code":"960306",
      "display":"บาเระใต้"
      },
      {
      "code":"960304",
      "display":"ปะลุกาสาเมาะ"
      },
      {
      "code":"960302",
      "display":"ลุโบะสาวอ"
      }
      ],
      "code":"9603",
      "display":"บาเจาะ"
      },
      {
      "districts":[
      {
      "code":"960403",
      "display":"จอเบาะ"
      },
      {
      "code":"960406",
      "display":"ตะปอเยาะ"
      },
      {
      "code":"960401",
      "display":"ยี่งอ"
      },
      {
      "code":"960402",
      "display":"ละหาร"
      },
      {
      "code":"960404",
      "display":"ลุโบะบายะ"
      },
      {
      "code":"960405",
      "display":"ลุโบะบือซา"
      }
      ],
      "code":"9604",
      "display":"ยี่งอ"
      },
      {
      "districts":[
      {
      "code":"960507",
      "display":"กาลิซา"
      },
      {
      "code":"960501",
      "display":"ตันหยงมัส"
      },
      {
      "code":"960502",
      "display":"ตันหยงลิมอ"
      },
      {
      "code":"960506",
      "display":"บองอ"
      },
      {
      "code":"960508",
      "display":"บาโงสะโต"
      },
      {
      "code":"960510",
      "display":"มะรือโบตก"
      },
      {
      "code":"960509",
      "display":"เฉลิม"
      }
      ],
      "code":"9605",
      "display":"ระแงะ"
      },
      {
      "districts":[
      {
      "code":"960605",
      "display":"บาตง"
      },
      {
      "code":"960601",
      "display":"รือเสาะ"
      },
      {
      "code":"960607",
      "display":"รือเสาะออก"
      },
      {
      "code":"960606",
      "display":"ลาโละ"
      },
      {
      "code":"960604",
      "display":"สามัคคี"
      },
      {
      "code":"960602",
      "display":"สาวอ"
      },
      {
      "code":"960609",
      "display":"สุวารี"
      },
      {
      "code":"960603",
      "display":"เรียง"
      },
      {
      "code":"960608",
      "display":"โคกสะตอ"
      }
      ],
      "code":"9606",
      "display":"รือเสาะ"
      },
      {
      "districts":[
      {
      "code":"960705",
      "display":"กาหลง"
      },
      {
      "code":"960701",
      "display":"ซากอ"
      },
      {
      "code":"960698",
      "display":"ตะมะยูง"
      },
      {
      "code":"960706",
      "display":"ศรีบรรพต"
      },
      {
      "code":"960703",
      "display":"ศรีสาคร"
      },
      {
      "code":"960704",
      "display":"เชิงคีรี"
      }
      ],
      "code":"9607",
      "display":"ศรีสาคร"
      },
      {
      "districts":[
      {
      "code":"960904",
      "display":"ภูเขาทอง"
      },
      {
      "code":"960901",
      "display":"มาโมง"
      },
      {
      "code":"960905",
      "display":"ร่มไทร"
      },
      {
      "code":"960902",
      "display":"สุคิริน"
      },
      {
      "code":"960903",
      "display":"เกียร์"
      }
      ],
      "code":"9609",
      "display":"สุคิริน"
      },
      {
      "districts":[
      {
      "code":"961106",
      "display":"กาวะ"
      },
      {
      "code":"961101",
      "display":"ปะลุรู"
      },
      {
      "code":"961105",
      "display":"ริโก๋"
      },
      {
      "code":"961104",
      "display":"สากอ"
      },
      {
      "code":"961102",
      "display":"สุไหงปาดี"
      },
      {
      "code":"961103",
      "display":"โต๊ะเด็ง"
      }
      ],
      "code":"9611",
      "display":"สุไหงปาดี"
      },
      {
      "districts":[
      {
      "code":"961002",
      "display":"ปาเสมัส"
      },
      {
      "code":"961004",
      "display":"ปูโยะ"
      },
      {
      "code":"961003",
      "display":"มูโนะ"
      },
      {
      "code":"961001",
      "display":"สุไหงโก-ลก"
      }
      ],
      "code":"9610",
      "display":"สุไหงโก-ลก"
      },
      {
      "districts":[
      {
      "code":"960503",
      "display":"จวบ"
      },
      {
      "code":"960505",
      "display":"บูกิต"
      },
      {
      "code":"961303",
      "display":"มะรือโบออก"
      }
      ],
      "code":"9613",
      "display":"เจาะไอร้อง"
      },
      {
      "districts":[
      {
      "code":"960105",
      "display":"กะลุวอ"
      },
      {
      "code":"960106",
      "display":"กะลุวอเหนือ"
      },
      {
      "code":"960101",
      "display":"บางนาค"
      },
      {
      "code":"960104",
      "display":"บางปอ"
      },
      {
      "code":"960103",
      "display":"มะนังตายอ"
      },
      {
      "code":"960102",
      "display":"ลำภู"
      },
      {
      "code":"960107",
      "display":"โคกเคียน"
      }
      ],
      "code":"9601",
      "display":"เมืองนราธิวาส"
      },
      {
      "districts":[
      {
      "code":"960802",
      "display":"กายูคละ"
      },
      {
      "code":"960803",
      "display":"ฆอเลาะ"
      },
      {
      "code":"960806",
      "display":"เอราวัณ"
      },
      {
      "code":"960805",
      "display":"แม่ดง"
      },
      {
      "code":"960801",
      "display":"แว้ง"
      },
      {
      "code":"960804",
      "display":"โละจูด"
      }
      ],
      "code":"9608",
      "display":"แว้ง"
      }
      ],
      "display":"นราธิวาส"
      },
      "น่าน":{
      "sort_order":"24",
      "code":"55",
      "amphoe":[
      {
      "districts":[
      {
      "code":"550802",
      "display":"งอบ"
      },
      {
      "code":"550804",
      "display":"ทุ่งช้าง"
      },
      {
      "code":"550801",
      "display":"ปอน"
      },
      {
      "code":"550803",
      "display":"และ"
      }
      ],
      "code":"5508",
      "display":"ทุ่งช้าง"
      },
      {
      "districts":[
      {
      "code":"550607",
      "display":"จอมพระ"
      },
      {
      "code":"550605",
      "display":"ตาลชุม"
      },
      {
      "code":"550609",
      "display":"ท่าวังผา"
      },
      {
      "code":"550602",
      "display":"ป่าคา"
      },
      {
      "code":"550603",
      "display":"ผาตอ"
      },
      {
      "code":"550610",
      "display":"ผาทอง"
      },
      {
      "code":"550604",
      "display":"ยม"
      },
      {
      "code":"550601",
      "display":"ริม"
      },
      {
      "code":"550606",
      "display":"ศรีภูมิ"
      },
      {
      "code":"550608",
      "display":"แสนทอง"
      }
      ],
      "code":"5506",
      "display":"ท่าวังผา"
      },
      {
      "districts":[
      {
      "code":"550401",
      "display":"นาน้อย"
      },
      {
      "code":"550407",
      "display":"น้ำตก"
      },
      {
      "code":"550406",
      "display":"บัวใหญ่"
      },
      {
      "code":"550403",
      "display":"ศรีษะเกษ"
      },
      {
      "code":"550404",
      "display":"สถาน"
      },
      {
      "code":"550405",
      "display":"สันทะ"
      },
      {
      "code":"550402",
      "display":"เชียงของ"
      }
      ],
      "code":"5504",
      "display":"นาน้อย"
      },
      {
      "districts":[
      {
      "code":"551001",
      "display":"นาทะนุง"
      },
      {
      "code":"551002",
      "display":"บ่อแก้ว"
      },
      {
      "code":"551004",
      "display":"ปิงหลวง"
      },
      {
      "code":"551003",
      "display":"เมืองลี"
      }
      ],
      "code":"5510",
      "display":"นาหมื่น"
      },
      {
      "districts":[
      {
      "code":"551205",
      "display":"ดงพญา"
      },
      {
      "code":"550507",
      "display":"บ่อเกลือเหนือ"
      },
      {
      "code":"550508",
      "display":"บ่อเกลือใต้"
      },
      {
      "code":"551204",
      "display":"ภูฟ้า"
      }
      ],
      "code":"5512",
      "display":"บ่อเกลือ"
      },
      {
      "districts":[
      {
      "code":"550304",
      "display":"บ้านพี้"
      },
      {
      "code":"550198",
      "display":"บ้านฟ้า"
      },
      {
      "code":"550196",
      "display":"ป่าคาหลวง"
      },
      {
      "code":"550303",
      "display":"สวด"
      }
      ],
      "code":"5503",
      "display":"บ้านหลวง"
      },
      {
      "districts":[
      {
      "code":"550501",
      "display":"ปัว"
      },
      {
      "code":"550513",
      "display":"ป่ากลาง"
      },
      {
      "code":"550511",
      "display":"ภูคา"
      },
      {
      "code":"550514",
      "display":"วรนคร"
      },
      {
      "code":"550505",
      "display":"ศิลาเพชร"
      },
      {
      "code":"550504",
      "display":"ศิลาแลง"
      },
      {
      "code":"550512",
      "display":"สกาด"
      },
      {
      "code":"550404",
      "display":"สถาน"
      },
      {
      "code":"550506",
      "display":"อวน"
      },
      {
      "code":"550510",
      "display":"เจดีย์ชัย"
      },
      {
      "code":"550502",
      "display":"แงง"
      },
      {
      "code":"550509",
      "display":"ไชยวัฒนา"
      }
      ],
      "code":"5505",
      "display":"ปัว"
      },
      {
      "districts":[
      {
      "code":"550112",
      "display":"ท่าน้าว"
      },
      {
      "code":"550113",
      "display":"นาปัง"
      },
      {
      "code":"550118",
      "display":"น้ำเกี๋ยน"
      },
      {
      "code":"550115",
      "display":"น้ำแก่น"
      },
      {
      "code":"550110",
      "display":"ฝายแก้ว"
      },
      {
      "code":"550111",
      "display":"ม่วงตึ๊ด"
      },
      {
      "code":"550114",
      "display":"เมืองจัง"
      }
      ],
      "code":"5514",
      "display":"ภูเพียง"
      },
      {
      "districts":[
      {
      "code":"551302",
      "display":"ชนแดน"
      },
      {
      "code":"550998",
      "display":"นาไร่หลวง"
      },
      {
      "code":"550999",
      "display":"ยอด"
      }
      ],
      "code":"5513",
      "display":"สองแคว"
      },
      {
      "districts":[
      {
      "code":"550199",
      "display":"ดู่พงษ์"
      },
      {
      "code":"551102",
      "display":"ป่าแลวหลวง"
      },
      {
      "code":"550201",
      "display":"พงษ์"
      }
      ],
      "code":"5511",
      "display":"สันติสุข"
      },
      {
      "districts":[
      {
      "code":"551203",
      "display":"ขุนน่าน"
      },
      {
      "code":"550805",
      "display":"ห้วยโก๋น"
      }
      ],
      "code":"5515",
      "display":"เฉลิมพระเกียรติ"
      },
      {
      "districts":[
      {
      "code":"550908",
      "display":"พญาแก้ว"
      },
      {
      "code":"550904",
      "display":"พระธาตุ"
      },
      {
      "code":"550909",
      "display":"พระพุทธบาท"
      },
      {
      "code":"550899",
      "display":"เชียงกลาง"
      },
      {
      "code":"550903",
      "display":"เชียงคาน"
      },
      {
      "code":"550898",
      "display":"เปือ"
      }
      ],
      "code":"5509",
      "display":"เชียงกลาง"
      },
      {
      "districts":[
      {
      "code":"550109",
      "display":"กองควาย"
      },
      {
      "code":"550108",
      "display":"ดู่ใต้"
      },
      {
      "code":"550105",
      "display":"ถืมตอง"
      },
      {
      "code":"550107",
      "display":"นาซาว"
      },
      {
      "code":"550102",
      "display":"บ่อ"
      },
      {
      "code":"550103",
      "display":"ผาสิงห์"
      },
      {
      "code":"550116",
      "display":"สวก"
      },
      {
      "code":"550117",
      "display":"สะเนียน"
      },
      {
      "code":"550106",
      "display":"เรือง"
      },
      {
      "code":"550101",
      "display":"ในเวียง"
      },
      {
      "code":"550104",
      "display":"ไชยสถาน"
      }
      ],
      "code":"5501",
      "display":"เมืองน่าน"
      },
      {
      "districts":[
      {
      "code":"550701",
      "display":"กลางเวียง"
      },
      {
      "code":"550702",
      "display":"ขึ่ง"
      },
      {
      "code":"550715",
      "display":"จอมจันทร์"
      },
      {
      "code":"550605",
      "display":"ตาลชุม"
      },
      {
      "code":"550717",
      "display":"ทุ่งศรีทอง"
      },
      {
      "code":"550705",
      "display":"นาเหลือง"
      },
      {
      "code":"550708",
      "display":"น้ำปั้ว"
      },
      {
      "code":"550707",
      "display":"น้ำมวบ"
      },
      {
      "code":"550710",
      "display":"ปงสนุก"
      },
      {
      "code":"550709",
      "display":"ยาบหัวนา"
      },
      {
      "code":"550706",
      "display":"ส้าน"
      },
      {
      "code":"550712",
      "display":"ส้านนาหนองใหม่"
      },
      {
      "code":"550711",
      "display":"อ่ายนาไลย"
      },
      {
      "code":"550713",
      "display":"แม่ขะนิง"
      },
      {
      "code":"550716",
      "display":"แม่สา"
      },
      {
      "code":"550714",
      "display":"แม่สาคร"
      },
      {
      "code":"550703",
      "display":"ไหล่น่าน"
      }
      ],
      "code":"5507",
      "display":"เวียงสา"
      },
      {
      "districts":[
      {
      "code":"550205",
      "display":"น้ำปาย"
      },
      {
      "code":"550204",
      "display":"น้ำพาง"
      },
      {
      "code":"550202",
      "display":"หนองแดง"
      },
      {
      "code":"550197",
      "display":"หมอเมือง"
      },
      {
      "code":"550206",
      "display":"แม่จริม"
      }
      ],
      "code":"5502",
      "display":"แม่จริม"
      }
      ],
      "display":"น่าน"
      },
      "บึงกาฬ":{
      "sort_order":"25",
      "code":"97",
      "amphoe":[
      {
      "districts":[
      {
      "code":"FALSE",
      "display":"ดงบัง"
      },
      {
      "code":"FALSE",
      "display":"ท่าดอกคำ"
      },
      {
      "code":"FALSE",
      "display":"บึงโขงหลง"
      },
      {
      "code":"FALSE",
      "display":"โพธิ์หมากแข้ง"
      },
      {
      "code":"FALSE",
      "display":"บุ่งคล้า"
      },
      {
      "code":"FALSE",
      "display":"หนองเดิ่น"
      },
      {
      "code":"FALSE",
      "display":"โคกกว้าง"
      },
      {
      "code":"FALSE",
      "display":"นากั้ง"
      },
      {
      "code":"FALSE",
      "display":"นาดง"
      },
      {
      "code":"FALSE",
      "display":"ปากคาด"
      },
      {
      "code":"FALSE",
      "display":"สมสนุก"
      },
      {
      "code":"FALSE",
      "display":"หนองยอง"
      },
      {
      "code":"FALSE",
      "display":"โนนศิลา"
      },
      {
      "code":"FALSE",
      "display":"ดอนหญ้านาง"
      },
      {
      "code":"FALSE",
      "display":"ป่าแฝก"
      },
      {
      "code":"FALSE",
      "display":"พรเจริญ"
      },
      {
      "code":"FALSE",
      "display":"วังชมภู"
      },
      {
      "code":"FALSE",
      "display":"ศรีชมภู"
      },
      {
      "code":"FALSE",
      "display":"ศรีสำราญ"
      },
      {
      "code":"FALSE",
      "display":"หนองหัวช้าง"
      },
      {
      "code":"FALSE",
      "display":"ชุมภูพร"
      },
      {
      "code":"FALSE",
      "display":"นาสะแบง"
      },
      {
      "code":"FALSE",
      "display":"นาสิงห์"
      },
      {
      "code":"FALSE",
      "display":"นาแสง"
      },
      {
      "code":"FALSE",
      "display":"ศรีวิไล"
      },
      {
      "code":"FALSE",
      "display":"ซาง"
      },
      {
      "code":"FALSE",
      "display":"ท่ากกแดง"
      },
      {
      "code":"FALSE",
      "display":"ท่าสะอาด"
      },
      {
      "code":"FALSE",
      "display":"น้ำจั้น"
      },
      {
      "code":"FALSE",
      "display":"บ้านต้อง"
      },
      {
      "code":"FALSE",
      "display":"ป่งไฮ"
      },
      {
      "code":"FALSE",
      "display":"หนองทุ่ม"
      },
      {
      "code":"FALSE",
      "display":"เซกา"
      },
      {
      "code":"FALSE",
      "display":"โสกก่าม"
      },
      {
      "code":"FALSE",
      "display":"คำนาดี"
      },
      {
      "code":"FALSE",
      "display":"ชัยพร"
      },
      {
      "code":"FALSE",
      "display":"นาสวรรค์"
      },
      {
      "code":"FALSE",
      "display":"บึงกาฬ"
      },
      {
      "code":"FALSE",
      "display":"วิศิษฐ์"
      },
      {
      "code":"FALSE",
      "display":"โนนสว่าง"
      },
      {
      "code":"FALSE",
      "display":"หนองเลิง"
      },
      {
      "code":"FALSE",
      "display":"หอคำ"
      },
      {
      "code":"FALSE",
      "display":"โคกก่อง"
      },
      {
      "code":"FALSE",
      "display":"โนนสมบูรณ์"
      },
      {
      "code":"FALSE",
      "display":"โป่งเปือย"
      },
      {
      "code":"FALSE",
      "display":"ไคสี"
      },
      {
      "code":"FALSE",
      "display":"คำแก้ว"
      },
      {
      "code":"FALSE",
      "display":"ถ้ำเจริญ"
      },
      {
      "code":"FALSE",
      "display":"บัวตูม"
      },
      {
      "code":"FALSE",
      "display":"ศรีชมภู"
      },
      {
      "code":"FALSE",
      "display":"หนองพันทา"
      },
      {
      "code":"FALSE",
      "display":"เหล่าทอง"
      },
      {
      "code":"FALSE",
      "display":"โซ่"
      }
      ],
      "code":"FALSE",
      "display":"บึงโขงหลง"
      }
      ],
      "display":"บึงกาฬ"
      },
      "บุรีรัมย์":{
      "sort_order":"26",
      "code":"31",
      "amphoe":[
      {
      "districts":[
      {
      "code":"310127",
      "display":"กระสัง"
      },
      {
      "code":"310310",
      "display":"กันทรารมย์"
      },
      {
      "code":"310307",
      "display":"ชุมแสง"
      },
      {
      "code":"310308",
      "display":"บ้านปรือ"
      },
      {
      "code":"310302",
      "display":"ลำดวน"
      },
      {
      "code":"310311",
      "display":"ศรีภูมิ"
      },
      {
      "code":"310303",
      "display":"สองชั้น"
      },
      {
      "code":"310304",
      "display":"สูงเนิน"
      },
      {
      "code":"310305",
      "display":"หนองเต็ง"
      },
      {
      "code":"310309",
      "display":"ห้วยสำราญ"
      },
      {
      "code":"310306",
      "display":"เมืองไผ่"
      }
      ],
      "code":"3103",
      "display":"กระสัง"
      },
      {
      "districts":[
      {
      "code":"310201",
      "display":"คูเมือง"
      },
      {
      "code":"310206",
      "display":"ตูมใหญ่"
      },
      {
      "code":"310203",
      "display":"บ้านแพ"
      },
      {
      "code":"310198",
      "display":"ปะเคียบ"
      },
      {
      "code":"310204",
      "display":"พรสำราญ"
      },
      {
      "code":"310207",
      "display":"หนองขมาร"
      },
      {
      "code":"310205",
      "display":"หินเหล็กไฟ"
      }
      ],
      "code":"3102",
      "display":"คูเมือง"
      },
      {
      "districts":[
      {
      "code":"310404",
      "display":"ชำนิ"
      },
      {
      "code":"310422",
      "display":"ช่อผกา"
      },
      {
      "code":"310423",
      "display":"ละลวด"
      },
      {
      "code":"310407",
      "display":"หนองปล่อง"
      },
      {
      "code":"310410",
      "display":"เมืองยาง"
      },
      {
      "code":"311806",
      "display":"โคกสนวน"
      }
      ],
      "code":"3118",
      "display":"ชำนิ"
      },
      {
      "districts":[
      {
      "code":"310415",
      "display":"ก้านเหลือง"
      },
      {
      "code":"310307",
      "display":"ชุมแสง"
      },
      {
      "code":"310413",
      "display":"ถนนหัก"
      },
      {
      "code":"310418",
      "display":"ทรัพย์พระยา"
      },
      {
      "code":"310426",
      "display":"ทุ่งแสงทอง"
      },
      {
      "code":"310401",
      "display":"นางรอง"
      },
      {
      "code":"310416",
      "display":"บ้านสิงห์"
      },
      {
      "code":"310417",
      "display":"ลำไทรโยง"
      },
      {
      "code":"310403",
      "display":"สะเดา"
      },
      {
      "code":"310408",
      "display":"หนองกง"
      },
      {
      "code":"310424",
      "display":"หนองยายพิมพ์"
      },
      {
      "code":"310406",
      "display":"หนองโบสถ์"
      },
      {
      "code":"310427",
      "display":"หนองโสน"
      },
      {
      "code":"310414",
      "display":"หนองไทร"
      },
      {
      "code":"310425",
      "display":"หัวถนน"
      }
      ],
      "code":"3104",
      "display":"นางรอง"
      },
      {
      "districts":[
      {
      "code":"311304",
      "display":"ดอนกอก"
      },
      {
      "code":"311301",
      "display":"นาโพธิ์"
      },
      {
      "code":"311302",
      "display":"บ้านคู"
      },
      {
      "code":"311303",
      "display":"บ้านดู่"
      },
      {
      "code":"311305",
      "display":"ศรีสว่าง"
      }
      ],
      "code":"3113",
      "display":"นาโพธิ์"
      },
      {
      "districts":[
      {
      "code":"310808",
      "display":"จันทบเพชร"
      },
      {
      "code":"310807",
      "display":"บึงเจริญ"
      },
      {
      "code":"310801",
      "display":"บ้านกรวด"
      },
      {
      "code":"310111",
      "display":"ปราสาท"
      },
      {
      "code":"310805",
      "display":"สายตะกู"
      },
      {
      "code":"310803",
      "display":"หนองไม้งาม"
      },
      {
      "code":"310806",
      "display":"หินลาด"
      },
      {
      "code":"310809",
      "display":"เขาดินเหนือ"
      },
      {
      "code":"310802",
      "display":"โนนเจริญ"
      }
      ],
      "code":"3108",
      "display":"บ้านกรวด"
      },
      {
      "districts":[
      {
      "code":"310109",
      "display":"บ้านด่าน"
      },
      {
      "code":"310111",
      "display":"ปราสาท"
      },
      {
      "code":"310123",
      "display":"วังเหนือ"
      },
      {
      "code":"310115",
      "display":"โนนขวาง"
      }
      ],
      "code":"3121",
      "display":"บ้านด่าน"
      },
      {
      "districts":[
      {
      "code":"310911",
      "display":"กู่สวนแตง"
      },
      {
      "code":"310905",
      "display":"ทองหลาง"
      },
      {
      "code":"310912",
      "display":"หนองเยือง"
      },
      {
      "code":"310607",
      "display":"หนองแวง"
      },
      {
      "code":"311903",
      "display":"แดงใหญ่"
      }
      ],
      "code":"3119",
      "display":"บ้านใหม่ไชยพจน์"
      },
      {
      "districts":[
      {
      "code":"310706",
      "display":"จรเข้มาก"
      },
      {
      "code":"310714",
      "display":"ตะโกตาพิ"
      },
      {
      "code":"310703",
      "display":"บ้านไทร"
      },
      {
      "code":"310720",
      "display":"ประทัดบุ"
      },
      {
      "code":"310701",
      "display":"ประโคนชัย"
      },
      {
      "code":"310707",
      "display":"ปังกู"
      },
      {
      "code":"310705",
      "display":"ละเวี้ย"
      },
      {
      "code":"310721",
      "display":"สี่เหลี่ยม"
      },
      {
      "code":"310716",
      "display":"หนองบอน"
      },
      {
      "code":"310715",
      "display":"เขาคอก"
      },
      {
      "code":"310702",
      "display":"แสลงโทน"
      },
      {
      "code":"310719",
      "display":"โคกตูม"
      },
      {
      "code":"310718",
      "display":"โคกมะขาม"
      },
      {
      "code":"310710",
      "display":"โคกม้า"
      },
      {
      "code":"310708",
      "display":"โคกย่าง"
      },
      {
      "code":"310713",
      "display":"ไพศาล"
      }
      ],
      "code":"3107",
      "display":"ประโคนชัย"
      },
      {
      "districts":[
      {
      "code":"311201",
      "display":"ปะคำ"
      },
      {
      "code":"311203",
      "display":"หนองบัว"
      },
      {
      "code":"311205",
      "display":"หูทำนบ"
      },
      {
      "code":"311204",
      "display":"โคกมะม่วง"
      },
      {
      "code":"310699",
      "display":"ไทยเจริญ"
      }
      ],
      "code":"3112",
      "display":"ปะคำ"
      },
      {
      "districts":[
      {
      "code":"310704",
      "display":"จันดุม"
      },
      {
      "code":"310711",
      "display":"ป่าชัน"
      },
      {
      "code":"310403",
      "display":"สะเดา"
      },
      {
      "code":"310717",
      "display":"สำโรง"
      },
      {
      "code":"310709",
      "display":"โคกขมิ้น"
      }
      ],
      "code":"3115",
      "display":"พลับพลาชัย"
      },
      {
      "districts":[
      {
      "code":"310903",
      "display":"บ้านจาน"
      },
      {
      "code":"310108",
      "display":"บ้านยาง"
      },
      {
      "code":"310906",
      "display":"บ้านเป้า"
      },
      {
      "code":"310907",
      "display":"บ้านแวง"
      },
      {
      "code":"310901",
      "display":"พุทไธสง"
      },
      {
      "code":"310902",
      "display":"มะเฟือง"
      },
      {
      "code":"310910",
      "display":"หายโศก"
      }
      ],
      "code":"3109",
      "display":"พุทไธสง"
      },
      {
      "districts":[
      {
      "code":"310603",
      "display":"ตาจง"
      },
      {
      "code":"310601",
      "display":"ละหานทราย"
      },
      {
      "code":"310604",
      "display":"สำโรงใหม่"
      },
      {
      "code":"310610",
      "display":"หนองตะครอง"
      },
      {
      "code":"310607",
      "display":"หนองแวง"
      },
      {
      "code":"310611",
      "display":"โคกว่าน"
      }
      ],
      "code":"3106",
      "display":"ละหานทราย"
      },
      {
      "districts":[
      {
      "code":"311005",
      "display":"ตลาดโพธิ์"
      },
      {
      "code":"311004",
      "display":"ทะเมนชัย"
      },
      {
      "code":"311015",
      "display":"บุโพธิ์"
      },
      {
      "code":"310108",
      "display":"บ้านยาง"
      },
      {
      "code":"311011",
      "display":"ผไทรินทร์"
      },
      {
      "code":"311001",
      "display":"ลำปลายมาศ"
      },
      {
      "code":"311006",
      "display":"หนองกะทิง"
      },
      {
      "code":"311002",
      "display":"หนองคู"
      },
      {
      "code":"311014",
      "display":"หนองบัวโคก"
      },
      {
      "code":"311016",
      "display":"หนองโดน"
      },
      {
      "code":"311013",
      "display":"หินโคน"
      },
      {
      "code":"311009",
      "display":"เมืองแฝก"
      },
      {
      "code":"311003",
      "display":"แสลงพัน"
      },
      {
      "code":"311007",
      "display":"โคกกลาง"
      },
      {
      "code":"311012",
      "display":"โคกล่าม"
      },
      {
      "code":"311008",
      "display":"โคกสะอาด"
      }
      ],
      "code":"3110",
      "display":"ลำปลายมาศ"
      },
      {
      "districts":[
      {
      "code":"310127",
      "display":"กระสัง"
      },
      {
      "code":"310307",
      "display":"ชุมแสง"
      },
      {
      "code":"311109",
      "display":"ดอนมนต์"
      },
      {
      "code":"311103",
      "display":"ทุ่งวัง"
      },
      {
      "code":"311111",
      "display":"ท่าม่วง"
      },
      {
      "code":"311102",
      "display":"นิคม"
      },
      {
      "code":"311106",
      "display":"ร่อนทอง"
      },
      {
      "code":"311101",
      "display":"สตึก"
      },
      {
      "code":"311114",
      "display":"สนามชัย"
      },
      {
      "code":"311112",
      "display":"สะแก"
      },
      {
      "code":"311105",
      "display":"หนองใหญ่"
      },
      {
      "code":"311104",
      "display":"เมืองแก"
      }
      ],
      "code":"3111",
      "display":"สตึก"
      },
      {
      "districts":[
      {
      "code":"310496",
      "display":"ดอนอะราง"
      },
      {
      "code":"310506",
      "display":"ทุ่งกระตาดพัฒนา"
      },
      {
      "code":"310507",
      "display":"ทุ่งกระเต็น"
      },
      {
      "code":"310508",
      "display":"ท่าโพธิ์ชัย"
      },
      {
      "code":"310510",
      "display":"บุกระสัง"
      },
      {
      "code":"310499",
      "display":"หนองกี่"
      },
      {
      "code":"310306",
      "display":"เมืองไผ่"
      },
      {
      "code":"310498",
      "display":"เย้ยปราสาท"
      },
      {
      "code":"310505",
      "display":"โคกสว่าง"
      },
      {
      "code":"310509",
      "display":"โคกสูง"
      }
      ],
      "code":"3105",
      "display":"หนองกี่"
      },
      {
      "districts":[
      {
      "code":"311407",
      "display":"สระทอง"
      },
      {
      "code":"311099",
      "display":"สระแก้ว"
      },
      {
      "code":"311404",
      "display":"หนองชัยศรี"
      },
      {
      "code":"311098",
      "display":"ห้วยหิน"
      },
      {
      "code":"311406",
      "display":"เมืองฝ้าย"
      },
      {
      "code":"311405",
      "display":"เสาเดียว"
      },
      {
      "code":"311097",
      "display":"ไทยสามัคคี"
      }
      ],
      "code":"3114",
      "display":"หนองหงส์"
      },
      {
      "districts":[
      {
      "code":"310116",
      "display":"ตาเสา"
      },
      {
      "code":"310124",
      "display":"บ้านตะโก"
      },
      {
      "code":"310121",
      "display":"สนวน"
      },
      {
      "code":"310110",
      "display":"สามแวง"
      },
      {
      "code":"310107",
      "display":"ห้วยราช"
      },
      {
      "code":"311608",
      "display":"ห้วยราชา"
      },
      {
      "code":"311607",
      "display":"เมืองโพธิ์"
      },
      {
      "code":"311606",
      "display":"โคกเหล็ก"
      }
      ],
      "code":"3116",
      "display":"ห้วยราช"
      },
      {
      "districts":[
      {
      "code":"310402",
      "display":"ตาเป๊ก"
      },
      {
      "code":"310602",
      "display":"ถาวร"
      },
      {
      "code":"310606",
      "display":"ยายแย้มวัฒนา"
      },
      {
      "code":"310419",
      "display":"อีสานเขต"
      },
      {
      "code":"310411",
      "display":"เจริญสุข"
      }
      ],
      "code":"3123",
      "display":"เฉลิมพระเกียรติ"
      },
      {
      "districts":[
      {
      "code":"310127",
      "display":"กระสัง"
      },
      {
      "code":"310126",
      "display":"กลันทา"
      },
      {
      "code":"310120",
      "display":"ชุมเห็ด"
      },
      {
      "code":"310113",
      "display":"ถลุงเหล็ก"
      },
      {
      "code":"310119",
      "display":"บัวทอง"
      },
      {
      "code":"310104",
      "display":"บ้านบัว"
      },
      {
      "code":"310108",
      "display":"บ้านยาง"
      },
      {
      "code":"310112",
      "display":"พระครู"
      },
      {
      "code":"310117",
      "display":"ลุมปุ๊ก"
      },
      {
      "code":"310106",
      "display":"สวายจีก"
      },
      {
      "code":"310118",
      "display":"สองห้อง"
      },
      {
      "code":"310125",
      "display":"สะแกซำ"
      },
      {
      "code":"310105",
      "display":"สะแกโพรง"
      },
      {
      "code":"310114",
      "display":"หนองตาด"
      },
      {
      "code":"310122",
      "display":"หลักเขต"
      },
      {
      "code":"310102",
      "display":"อิสาณ"
      },
      {
      "code":"310128",
      "display":"เมืองฝาง"
      },
      {
      "code":"310103",
      "display":"เสม็ด"
      },
      {
      "code":"310101",
      "display":"ในเมือง"
      }
      ],
      "code":"3101",
      "display":"เมืองบุรีรัมย์"
      },
      {
      "districts":[
      {
      "code":"311108",
      "display":"ดงพลอง"
      },
      {
      "code":"311113",
      "display":"สระบัว"
      },
      {
      "code":"311116",
      "display":"หัวฝาย"
      },
      {
      "code":"311107",
      "display":"แคนดง"
      }
      ],
      "code":"3122",
      "display":"แคนดง"
      },
      {
      "districts":[
      {
      "code":"310608",
      "display":"ลำนางรอง"
      },
      {
      "code":"310609",
      "display":"ส้มป่อย"
      },
      {
      "code":"310605",
      "display":"โนนดินแดง"
      }
      ],
      "code":"3120",
      "display":"โนนดินแดง"
      },
      {
      "districts":[
      {
      "code":"310420",
      "display":"ดงอีจาน"
      },
      {
      "code":"310409",
      "display":"ทุ่งจังหัน"
      },
      {
      "code":"310421",
      "display":"โกรกแก้ว"
      },
      {
      "code":"310412",
      "display":"โนนสุวรรณ"
      }
      ],
      "code":"3117",
      "display":"โนนสุวรรณ"
      }
      ],
      "display":"บุรีรัมย์"
      },
      "ปทุมธานี":{
      "sort_order":"27",
      "code":"13",
      "amphoe":[
      {
      "districts":[
      {
      "code":"130202",
      "display":"คลองสอง"
      },
      {
      "code":"130203",
      "display":"คลองสาม"
      },
      {
      "code":"130204",
      "display":"คลองสี่"
      },
      {
      "code":"130206",
      "display":"คลองหก"
      },
      {
      "code":"130201",
      "display":"คลองหนึ่ง"
      },
      {
      "code":"130205",
      "display":"คลองห้า"
      },
      {
      "code":"130207",
      "display":"คลองเจ็ด"
      }
      ],
      "code":"1302",
      "display":"คลองหลวง"
      },
      {
      "districts":[
      {
      "code":"130306",
      "display":"บึงน้ำรักษ์"
      },
      {
      "code":"130302",
      "display":"บึงยี่โถ"
      },
      {
      "code":"130305",
      "display":"บึงสนั่น"
      },
      {
      "code":"130301",
      "display":"ประชาธิปัตย์"
      },
      {
      "code":"130303",
      "display":"รังสิต"
      },
      {
      "code":"130304",
      "display":"ลำผักกูด"
      }
      ],
      "code":"1303",
      "display":"ธัญบุรี"
      },
      {
      "districts":[
      {
      "code":"130505",
      "display":"คลองพระอุดม"
      },
      {
      "code":"130504",
      "display":"คูขวาง"
      },
      {
      "code":"130503",
      "display":"คูบางหลวง"
      },
      {
      "code":"130506",
      "display":"บ่อเงิน"
      },
      {
      "code":"130501",
      "display":"ระแหง"
      },
      {
      "code":"130502",
      "display":"ลาดหลุมแก้ว"
      },
      {
      "code":"130507",
      "display":"หน้าไม้"
      }
      ],
      "code":"1305",
      "display":"ลาดหลุมแก้ว"
      },
      {
      "districts":[
      {
      "code":"130601",
      "display":"คูคต"
      },
      {
      "code":"130607",
      "display":"บึงคอไห"
      },
      {
      "code":"130603",
      "display":"บึงคำพร้อย"
      },
      {
      "code":"130605",
      "display":"บึงทองหลาง"
      },
      {
      "code":"130608",
      "display":"พืชอุดม"
      },
      {
      "code":"130602",
      "display":"ลาดสวาย"
      },
      {
      "code":"130604",
      "display":"ลำลูกกา"
      },
      {
      "code":"130606",
      "display":"ลำไทร"
      }
      ],
      "code":"1306",
      "display":"ลำลูกกา"
      },
      {
      "districts":[
      {
      "code":"130704",
      "display":"กระแชง"
      },
      {
      "code":"130702",
      "display":"คลองควาย"
      },
      {
      "code":"130711",
      "display":"ท้ายเกาะ"
      },
      {
      "code":"130710",
      "display":"บางกระบือ"
      },
      {
      "code":"130701",
      "display":"บางเตย"
      },
      {
      "code":"130705",
      "display":"บางโพธิ์เหนือ"
      },
      {
      "code":"130708",
      "display":"บ้านงิ้ว"
      },
      {
      "code":"130707",
      "display":"บ้านปทุม"
      },
      {
      "code":"130703",
      "display":"สามโคก"
      },
      {
      "code":"130709",
      "display":"เชียงรากน้อย"
      },
      {
      "code":"130706",
      "display":"เชียงรากใหญ่"
      }
      ],
      "code":"1307",
      "display":"สามโคก"
      },
      {
      "districts":[
      {
      "code":"130407",
      "display":"นพรัตน์"
      },
      {
      "code":"130403",
      "display":"บึงกาสาม"
      },
      {
      "code":"130404",
      "display":"บึงชำอ้อ"
      },
      {
      "code":"130402",
      "display":"บึงบอน"
      },
      {
      "code":"130401",
      "display":"บึงบา"
      },
      {
      "code":"130406",
      "display":"ศาลาครุ"
      },
      {
      "code":"130405",
      "display":"หนองสามวัง"
      }
      ],
      "code":"1304",
      "display":"หนองเสือ"
      },
      {
      "districts":[
      {
      "code":"130112",
      "display":"บางกะดี"
      },
      {
      "code":"130106",
      "display":"บางขะแยง"
      },
      {
      "code":"130107",
      "display":"บางคูวัด"
      },
      {
      "code":"130101",
      "display":"บางปรอก"
      },
      {
      "code":"130110",
      "display":"บางพูด"
      },
      {
      "code":"130111",
      "display":"บางพูน"
      },
      {
      "code":"130108",
      "display":"บางหลวง"
      },
      {
      "code":"130109",
      "display":"บางเดื่อ"
      },
      {
      "code":"130105",
      "display":"บ้านกระแชง"
      },
      {
      "code":"130103",
      "display":"บ้านกลาง"
      },
      {
      "code":"130104",
      "display":"บ้านฉาง"
      },
      {
      "code":"130102",
      "display":"บ้านใหม่"
      },
      {
      "code":"130113",
      "display":"สวนพริกไทย"
      },
      {
      "code":"130114",
      "display":"หลักหก"
      }
      ],
      "code":"1301",
      "display":"เมืองปทุมธานี"
      }
      ],
      "display":"ปทุมธานี"
      },
      "ประจวบคีรีขันธ์":{
      "sort_order":"28",
      "code":"77",
      "amphoe":[
      {
      "districts":[
      {
      "code":"770201",
      "display":"กุยบุรี"
      },
      {
      "code":"770202",
      "display":"กุยเหนือ"
      },
      {
      "code":"770204",
      "display":"ดอนยายหนู"
      },
      {
      "code":"770206",
      "display":"สามกระทาย"
      },
      {
      "code":"770207",
      "display":"หาดขาม"
      },
      {
      "code":"770203",
      "display":"เขาแดง"
      }
      ],
      "code":"7702",
      "display":"กุยบุรี"
      },
      {
      "districts":[
      {
      "code":"770301",
      "display":"ทับสะแก"
      },
      {
      "code":"770303",
      "display":"นาหูกวาง"
      },
      {
      "code":"770305",
      "display":"ห้วยยาง"
      },
      {
      "code":"770302",
      "display":"อ่างทอง"
      },
      {
      "code":"770304",
      "display":"เขาล้าน"
      },
      {
      "code":"770306",
      "display":"แสงอรุณ"
      }
      ],
      "code":"7703",
      "display":"ทับสะแก"
      },
      {
      "districts":[
      {
      "code":"770401",
      "display":"กำเนิดนพคุณ"
      },
      {
      "code":"770405",
      "display":"ชัยเกษม"
      },
      {
      "code":"770406",
      "display":"ทองมงคล"
      },
      {
      "code":"770404",
      "display":"ธงชัย"
      },
      {
      "code":"770402",
      "display":"พงศ์ประศาสน์"
      },
      {
      "code":"770403",
      "display":"ร่อนทอง"
      },
      {
      "code":"770407",
      "display":"แม่รำพึง"
      }
      ],
      "code":"7704",
      "display":"บางสะพาน"
      },
      {
      "districts":[
      {
      "code":"770504",
      "display":"ช้างแรก"
      },
      {
      "code":"770503",
      "display":"ทรายทอง"
      },
      {
      "code":"770502",
      "display":"บางสะพาน"
      },
      {
      "code":"770501",
      "display":"ปากแพรก"
      },
      {
      "code":"770505",
      "display":"ไชยราช"
      }
      ],
      "code":"7705",
      "display":"บางสะพานน้อย"
      },
      {
      "districts":[
      {
      "code":"770601",
      "display":"ปราณบุรี"
      },
      {
      "code":"770601",
      "display":"ปราณบุรี"
      },
      {
      "code":"770604",
      "display":"ปากน้ำปราณ"
      },
      {
      "code":"770608",
      "display":"วังก์พง"
      },
      {
      "code":"770608",
      "display":"วังก์พง"
      },
      {
      "code":"770607",
      "display":"หนองตาแต้ม"
      },
      {
      "code":"770607",
      "display":"หนองตาแต้ม"
      },
      {
      "code":"770609",
      "display":"เขาจ้าว"
      },
      {
      "code":"770609",
      "display":"เขาจ้าว"
      },
      {
      "code":"770602",
      "display":"เขาน้อย"
      },
      {
      "code":"770602",
      "display":"เขาน้อย"
      }
      ],
      "code":"7706",
      "display":"ปราณบุรี"
      },
      {
      "districts":[
      {
      "code":"770804",
      "display":"ศาลาลัย"
      },
      {
      "code":"770603",
      "display":"ศิลาลอย"
      },
      {
      "code":"770605",
      "display":"สามร้อยยอด"
      },
      {
      "code":"770605",
      "display":"สามร้อยยอด"
      },
      {
      "code":"770606",
      "display":"ไร่เก่า"
      },
      {
      "code":"770205",
      "display":"ไร่ใหม่"
      }
      ],
      "code":"7708",
      "display":"สามร้อยยอด"
      },
      {
      "districts":[
      {
      "code":"770705",
      "display":"ทับใต้"
      },
      {
      "code":"770707",
      "display":"บึงนคร"
      },
      {
      "code":"770704",
      "display":"หนองพลับ"
      },
      {
      "code":"770702",
      "display":"หนองแก"
      },
      {
      "code":"770701",
      "display":"หัวหิน"
      },
      {
      "code":"770703",
      "display":"หินเหล็กไฟ"
      },
      {
      "code":"770706",
      "display":"ห้วยสัตว์ใหญ่"
      }
      ],
      "code":"7707",
      "display":"หัวหิน"
      },
      {
      "districts":[
      {
      "code":"770103",
      "display":"คลองวาฬ"
      },
      {
      "code":"770106",
      "display":"บ่อนอก"
      },
      {
      "code":"770101",
      "display":"ประจวบคีรีขันธ์"
      },
      {
      "code":"770104",
      "display":"ห้วยทราย"
      },
      {
      "code":"770105",
      "display":"อ่าวน้อย"
      },
      {
      "code":"770102",
      "display":"เกาะหลัก"
      }
      ],
      "code":"7701",
      "display":"เมืองประจวบคีรีขันธ์"
      }
      ],
      "display":"ประจวบคีรีขันธ์"
      },
      "ปราจีนบุรี":{
      "sort_order":"29",
      "code":"25",
      "amphoe":[
      {
      "districts":[
      {
      "code":"250201",
      "display":"กบินทร์"
      },
      {
      "code":"250204",
      "display":"นนทรี"
      },
      {
      "code":"250212",
      "display":"นาแขม"
      },
      {
      "code":"250210",
      "display":"บ่อทอง"
      },
      {
      "code":"250209",
      "display":"บ้านนา"
      },
      {
      "code":"250205",
      "display":"ย่านรี"
      },
      {
      "code":"250208",
      "display":"ลาดตะเคียน"
      },
      {
      "code":"250203",
      "display":"วังดาล"
      },
      {
      "code":"250206",
      "display":"วังตะเคียน"
      },
      {
      "code":"250214",
      "display":"วังท่าช้าง"
      },
      {
      "code":"250211",
      "display":"หนองกี่"
      },
      {
      "code":"250207",
      "display":"หาดนางแก้ว"
      },
      {
      "code":"250213",
      "display":"เขาไม้แก้ว"
      },
      {
      "code":"250202",
      "display":"เมืองเก่า"
      }
      ],
      "code":"2502",
      "display":"กบินทร์บุรี"
      },
      {
      "districts":[
      {
      "code":"250299",
      "display":"ทุ่งโพธิ์"
      },
      {
      "code":"250297",
      "display":"นาดี"
      },
      {
      "code":"250306",
      "display":"บุพราหมณ์"
      },
      {
      "code":"250296",
      "display":"สะพานหิน"
      },
      {
      "code":"250302",
      "display":"สำพันตา"
      },
      {
      "code":"250305",
      "display":"แก่งดินสอ"
      }
      ],
      "code":"2503",
      "display":"นาดี"
      },
      {
      "districts":[
      {
      "code":"250609",
      "display":"กระทุ่มแพ้ว"
      },
      {
      "code":"250602",
      "display":"บางกระเบา"
      },
      {
      "code":"250608",
      "display":"บางขาม"
      },
      {
      "code":"250607",
      "display":"บางปลาร้า"
      },
      {
      "code":"250606",
      "display":"บางพลวง"
      },
      {
      "code":"250604",
      "display":"บางยาง"
      },
      {
      "code":"250603",
      "display":"บางเตย"
      },
      {
      "code":"250605",
      "display":"บางแตน"
      },
      {
      "code":"250601",
      "display":"บ้านสร้าง"
      }
      ],
      "code":"2506",
      "display":"บ้านสร้าง"
      },
      {
      "districts":[
      {
      "code":"250706",
      "display":"คำโตนด"
      },
      {
      "code":"250705",
      "display":"ดงบัง"
      },
      {
      "code":"250707",
      "display":"บุฝ้าย"
      },
      {
      "code":"250703",
      "display":"บ้านหอย"
      },
      {
      "code":"250701",
      "display":"ประจันตคาม"
      },
      {
      "code":"250708",
      "display":"หนองแก้ว"
      },
      {
      "code":"250704",
      "display":"หนองแสง"
      },
      {
      "code":"250702",
      "display":"เกาะลอย"
      },
      {
      "code":"250709",
      "display":"โพธิ์งาม"
      }
      ],
      "code":"2507",
      "display":"ประจันตคาม"
      },
      {
      "districts":[
      {
      "code":"250810",
      "display":"กรอกสมบูรณ์"
      },
      {
      "code":"250806",
      "display":"ดงกระทงยาม"
      },
      {
      "code":"250804",
      "display":"ท่าตูม"
      },
      {
      "code":"250805",
      "display":"บางกุ้ง"
      },
      {
      "code":"250803",
      "display":"บ้านทาม"
      },
      {
      "code":"250801",
      "display":"ศรีมหาโพธิ"
      },
      {
      "code":"250802",
      "display":"สัมพันธ์"
      },
      {
      "code":"250807",
      "display":"หนองโพรง"
      },
      {
      "code":"250808",
      "display":"หัวหว้า"
      },
      {
      "code":"250809",
      "display":"หาดยาง"
      }
      ],
      "code":"2508",
      "display":"ศรีมหาโพธิ"
      },
      {
      "districts":[
      {
      "code":"250896",
      "display":"คู้ลำพัน"
      },
      {
      "code":"250897",
      "display":"โคกปีบ"
      },
      {
      "code":"250898",
      "display":"โคกไทย"
      },
      {
      "code":"250899",
      "display":"ไผ่ชะเลือด"
      }
      ],
      "code":"2509",
      "display":"ศรีมโหสถ"
      },
      {
      "districts":[
      {
      "code":"250111",
      "display":"ดงขี้เหล็ก"
      },
      {
      "code":"250107",
      "display":"ดงพระราม"
      },
      {
      "code":"250105",
      "display":"ท่างาม"
      },
      {
      "code":"250106",
      "display":"บางบริบูรณ์"
      },
      {
      "code":"250104",
      "display":"บางเดชะ"
      },
      {
      "code":"250108",
      "display":"บ้านพระ"
      },
      {
      "code":"250102",
      "display":"รอบเมือง"
      },
      {
      "code":"250103",
      "display":"วัดโบสถ์"
      },
      {
      "code":"250101",
      "display":"หน้าเมือง"
      },
      {
      "code":"250112",
      "display":"เนินหอม"
      },
      {
      "code":"250109",
      "display":"โคกไม้ลาย"
      },
      {
      "code":"250113",
      "display":"โนนห้อม"
      },
      {
      "code":"250110",
      "display":"ไม้เค็ด"
      }
      ],
      "code":"2501",
      "display":"เมืองปราจีนบุรี"
      }
      ],
      "display":"ปราจีนบุรี"
      },
      "ปัตตานี":{
      "sort_order":"30",
      "code":"94",
      "amphoe":[
      {
      "districts":[
      {
      "code":"941101",
      "display":"กะรุบี"
      },
      {
      "code":"941102",
      "display":"ตะโละดือรามัน"
      },
      {
      "code":"941103",
      "display":"ปล่องหอย"
      }
      ],
      "code":"9411",
      "display":"กะพ้อ"
      },
      {
      "districts":[
      {
      "code":"940601",
      "display":"ตะโละแมะนา"
      },
      {
      "code":"940603",
      "display":"น้ำดำ"
      },
      {
      "code":"940604",
      "display":"ปากู"
      },
      {
      "code":"940602",
      "display":"พิเทน"
      }
      ],
      "code":"9406",
      "display":"ทุ่งยางแดง"
      },
      {
      "districts":[
      {
      "code":"940405",
      "display":"ควน"
      },
      {
      "code":"940407",
      "display":"คอกกระบือ"
      },
      {
      "code":"940404",
      "display":"ดอน"
      },
      {
      "code":"940402",
      "display":"ท่าข้าม"
      },
      {
      "code":"940406",
      "display":"ท่าน้ำ"
      },
      {
      "code":"940409",
      "display":"บ้านกลาง"
      },
      {
      "code":"940403",
      "display":"บ้านนอก"
      },
      {
      "code":"940410",
      "display":"บ้านน้ำบ่อ"
      },
      {
      "code":"940401",
      "display":"ปะนาเระ"
      },
      {
      "code":"940408",
      "display":"พ่อมิ่ง"
      }
      ],
      "code":"9404",
      "display":"ปะนาเระ"
      },
      {
      "districts":[
      {
      "code":"940504",
      "display":"กระหวะ"
      },
      {
      "code":"940507",
      "display":"กระเสาะ"
      },
      {
      "code":"940503",
      "display":"ตรัง"
      },
      {
      "code":"940502",
      "display":"ถนน"
      },
      {
      "code":"940509",
      "display":"ปะโด"
      },
      {
      "code":"940513",
      "display":"ปานัน"
      },
      {
      "code":"940501",
      "display":"มายอ"
      },
      {
      "code":"940506",
      "display":"ลางา"
      },
      {
      "code":"940505",
      "display":"ลุโบะยิไร"
      },
      {
      "code":"940512",
      "display":"สะกำ"
      },
      {
      "code":"940510",
      "display":"สาคอบน"
      },
      {
      "code":"940511",
      "display":"สาคอใต้"
      },
      {
      "code":"940508",
      "display":"เกาะจัน"
      }
      ],
      "code":"9405",
      "display":"มายอ"
      },
      {
      "districts":[
      {
      "code":"941008",
      "display":"กระโด"
      },
      {
      "code":"941011",
      "display":"กอลำ"
      },
      {
      "code":"941009",
      "display":"คลองใหม่"
      },
      {
      "code":"941003",
      "display":"ประจัน"
      },
      {
      "code":"941006",
      "display":"ปิตูมุดี"
      },
      {
      "code":"941001",
      "display":"ยะรัง"
      },
      {
      "code":"941005",
      "display":"ระแว้ง"
      },
      {
      "code":"941007",
      "display":"วัด"
      },
      {
      "code":"941002",
      "display":"สะดาวา"
      },
      {
      "code":"941004",
      "display":"สะนอ"
      },
      {
      "code":"941012",
      "display":"เขาตูม"
      },
      {
      "code":"941010",
      "display":"เมาะมาวี"
      }
      ],
      "code":"9410",
      "display":"ยะรัง"
      },
      {
      "districts":[
      {
      "code":"940917",
      "display":"จะรัง"
      },
      {
      "code":"940905",
      "display":"ตอหลัง"
      },
      {
      "code":"940901",
      "display":"ตะโละ"
      },
      {
      "code":"940902",
      "display":"ตะโละกาโปร์"
      },
      {
      "code":"940904",
      "display":"ตันหยงจึงงา"
      },
      {
      "code":"940903",
      "display":"ตันหยงดาลอ"
      },
      {
      "code":"940907",
      "display":"ตาลีอายร์"
      },
      {
      "code":"940906",
      "display":"ตาแกะ"
      },
      {
      "code":"940909",
      "display":"บางปู"
      },
      {
      "code":"940913",
      "display":"บาโลย"
      },
      {
      "code":"940911",
      "display":"ปิยามุมัง"
      },
      {
      "code":"940912",
      "display":"ปุลากง"
      },
      {
      "code":"940915",
      "display":"มะนังยง"
      },
      {
      "code":"940908",
      "display":"ยามู"
      },
      {
      "code":"940916",
      "display":"ราตาปันยัง"
      },
      {
      "code":"940914",
      "display":"สาบัน"
      },
      {
      "code":"940910",
      "display":"หนองแรต"
      },
      {
      "code":"940918",
      "display":"แหลมโพธิ์"
      }
      ],
      "code":"9409",
      "display":"ยะหริ่ง"
      },
      {
      "districts":[
      {
      "code":"940707",
      "display":"กะดุนง"
      },
      {
      "code":"940702",
      "display":"ตะบิ้ง"
      },
      {
      "code":"940701",
      "display":"ตะลุบัน"
      },
      {
      "code":"940711",
      "display":"ทุ่งคล้า"
      },
      {
      "code":"940704",
      "display":"บางเก่า"
      },
      {
      "code":"940705",
      "display":"บือเระ"
      },
      {
      "code":"940703",
      "display":"ปะเสยะวอ"
      },
      {
      "code":"940709",
      "display":"มะนังดาลำ"
      },
      {
      "code":"940708",
      "display":"ละหาร"
      },
      {
      "code":"940706",
      "display":"เตราะบอน"
      },
      {
      "code":"940710",
      "display":"แป้น"
      }
      ],
      "code":"9407",
      "display":"สายบุรี"
      },
      {
      "districts":[
      {
      "code":"940302",
      "display":"คอลอตันหยง"
      },
      {
      "code":"940303",
      "display":"ดอนรัก"
      },
      {
      "code":"940304",
      "display":"ดาโต๊ะ"
      },
      {
      "code":"940305",
      "display":"ตุยง"
      },
      {
      "code":"940306",
      "display":"ท่ากำชำ"
      },
      {
      "code":"940309",
      "display":"บางตาวา"
      },
      {
      "code":"940308",
      "display":"บางเขา"
      },
      {
      "code":"940307",
      "display":"บ่อทอง"
      },
      {
      "code":"940310",
      "display":"ปุโละปุโย"
      },
      {
      "code":"940311",
      "display":"ยาบี"
      },
      {
      "code":"940312",
      "display":"ลิปะสะโง"
      },
      {
      "code":"940301",
      "display":"เกาะเปาะ"
      }
      ],
      "code":"9403",
      "display":"หนองจิก"
      },
      {
      "districts":[
      {
      "code":"940107",
      "display":"กะมิยอ"
      },
      {
      "code":"940106",
      "display":"คลองมานิง"
      },
      {
      "code":"940103",
      "display":"จะบังติกอ"
      },
      {
      "code":"940111",
      "display":"ตะลุโบะ"
      },
      {
      "code":"940105",
      "display":"ตันหยงลุโละ"
      },
      {
      "code":"940104",
      "display":"บานา"
      },
      {
      "code":"940112",
      "display":"บาราเฮาะ"
      },
      {
      "code":"940108",
      "display":"บาราโหม"
      },
      {
      "code":"940109",
      "display":"ปะกาฮะรัง"
      },
      {
      "code":"940113",
      "display":"ปุยุด"
      },
      {
      "code":"940110",
      "display":"รูสะมิแล"
      },
      {
      "code":"940101",
      "display":"สะบารัง"
      },
      {
      "code":"940102",
      "display":"อาเนาะรู"
      }
      ],
      "code":"9401",
      "display":"เมืองปัตตานี"
      },
      {
      "districts":[
      {
      "code":"940210",
      "display":"ป่าไร่"
      },
      {
      "code":"940212",
      "display":"ม่วงเตี้ย"
      },
      {
      "code":"940209",
      "display":"แม่ลาน"
      }
      ],
      "code":"9412",
      "display":"แม่ลาน"
      },
      {
      "districts":[
      {
      "code":"940214",
      "display":"ควนโนรี"
      },
      {
      "code":"940215",
      "display":"ช้างให้ตก"
      },
      {
      "code":"940205",
      "display":"ทรายขาว"
      },
      {
      "code":"940208",
      "display":"ทุ่งพลา"
      },
      {
      "code":"940211",
      "display":"ท่าเรือ"
      },
      {
      "code":"940206",
      "display":"นาประดู่"
      },
      {
      "code":"940213",
      "display":"นาเกตุ"
      },
      {
      "code":"940203",
      "display":"บางโกระ"
      },
      {
      "code":"940207",
      "display":"ปากล่อ"
      },
      {
      "code":"940204",
      "display":"ป่าบอน"
      },
      {
      "code":"940202",
      "display":"มะกรูด"
      },
      {
      "code":"940201",
      "display":"โคกโพธิ์"
      }
      ],
      "code":"9402",
      "display":"โคกโพธิ์"
      },
      {
      "districts":[
      {
      "code":"940804",
      "display":"ดอนทราย"
      },
      {
      "code":"940803",
      "display":"ตะโละไกรทอง"
      },
      {
      "code":"940801",
      "display":"ไทรทอง"
      },
      {
      "code":"940802",
      "display":"ไม้แก่น"
      }
      ],
      "code":"9408",
      "display":"ไม้แก่น"
      }
      ],
      "display":"ปัตตานี"
      },
      "พระนครศรีอยุธยา":{
      "sort_order":"31",
      "code":"14",
      "amphoe":[
      {
      "districts":[
      {
      "code":"140199",
      "display":"จำปา"
      },
      {
      "code":"140203",
      "display":"ท่าหลวง"
      },
      {
      "code":"140210",
      "display":"ท่าเจ้าสนุก"
      },
      {
      "code":"140201",
      "display":"ท่าเรือ"
      },
      {
      "code":"140204",
      "display":"บ้านร่อม"
      },
      {
      "code":"140208",
      "display":"ปากท่า"
      },
      {
      "code":"140206",
      "display":"วังแดง"
      },
      {
      "code":"140205",
      "display":"ศาลาลอย"
      },
      {
      "code":"140209",
      "display":"หนองขนาก"
      },
      {
      "code":"140207",
      "display":"โพธิ์เอน"
      }
      ],
      "code":"1402",
      "display":"ท่าเรือ"
      },
      {
      "districts":[
      {
      "code":"140310",
      "display":"คลองสะแก"
      },
      {
      "code":"140302",
      "display":"ท่าช้าง"
      },
      {
      "code":"140301",
      "display":"นครหลวง"
      },
      {
      "code":"140307",
      "display":"บางพระครู"
      },
      {
      "code":"140306",
      "display":"บางระกำ"
      },
      {
      "code":"140303",
      "display":"บ่อโพง"
      },
      {
      "code":"140304",
      "display":"บ้านชุ้ง"
      },
      {
      "code":"140305",
      "display":"ปากจั่น"
      },
      {
      "code":"140312",
      "display":"พระนอน"
      },
      {
      "code":"140311",
      "display":"สามไถ"
      },
      {
      "code":"140309",
      "display":"หนองปลิง"
      },
      {
      "code":"140308",
      "display":"แม่ลา"
      }
      ],
      "code":"1403",
      "display":"นครหลวง"
      },
      {
      "districts":[
      {
      "code":"141301",
      "display":"บางซ้าย"
      },
      {
      "code":"141304",
      "display":"ปลายกลัด"
      },
      {
      "code":"141306",
      "display":"วังพัฒนา"
      },
      {
      "code":"141303",
      "display":"เต่าเล่า"
      },
      {
      "code":"141305",
      "display":"เทพมงคล"
      },
      {
      "code":"141302",
      "display":"แก้วฟ้า"
      }
      ],
      "code":"1413",
      "display":"บางซ้าย"
      },
      {
      "districts":[
      {
      "code":"140506",
      "display":"กบเจา"
      },
      {
      "code":"140510",
      "display":"ทางช้าง"
      },
      {
      "code":"140509",
      "display":"น้ำเต้า"
      },
      {
      "code":"140515",
      "display":"บางชะนี"
      },
      {
      "code":"140501",
      "display":"บางบาล"
      },
      {
      "code":"140512",
      "display":"บางหลวง"
      },
      {
      "code":"140513",
      "display":"บางหลวงโดด"
      },
      {
      "code":"140514",
      "display":"บางหัก"
      },
      {
      "code":"140516",
      "display":"บ้านกุ่ม"
      },
      {
      "code":"140507",
      "display":"บ้านคลัง"
      },
      {
      "code":"140508",
      "display":"พระขาว"
      },
      {
      "code":"140505",
      "display":"มหาพราหมณ์"
      },
      {
      "code":"140511",
      "display":"วัดตะกู"
      },
      {
      "code":"140502",
      "display":"วัดยม"
      },
      {
      "code":"140504",
      "display":"สะพานไทย"
      },
      {
      "code":"140503",
      "display":"ไทรน้อย"
      }
      ],
      "code":"1405",
      "display":"บางบาล"
      },
      {
      "districts":[
      {
      "code":"140702",
      "display":"ขยาย"
      },
      {
      "code":"140712",
      "display":"ขวัญเมือง"
      },
      {
      "code":"140709",
      "display":"ตานิม"
      },
      {
      "code":"140716",
      "display":"ตาลเอน"
      },
      {
      "code":"140710",
      "display":"ทับน้ำ"
      },
      {
      "code":"140705",
      "display":"ทางกลาง"
      },
      {
      "code":"140708",
      "display":"บางนางร้า"
      },
      {
      "code":"140701",
      "display":"บางปะหัน"
      },
      {
      "code":"140703",
      "display":"บางเดื่อ"
      },
      {
      "code":"140706",
      "display":"บางเพลิง"
      },
      {
      "code":"140717",
      "display":"บ้านขล้อ"
      },
      {
      "code":"140417",
      "display":"บ้านม้า"
      },
      {
      "code":"140713",
      "display":"บ้านลี่"
      },
      {
      "code":"140715",
      "display":"พุทเลา"
      },
      {
      "code":"140707",
      "display":"หันสัง"
      },
      {
      "code":"140704",
      "display":"เสาธง"
      },
      {
      "code":"140714",
      "display":"โพธิ์สามต้น"
      }
      ],
      "code":"1407",
      "display":"บางปะหัน"
      },
      {
      "districts":[
      {
      "code":"140618",
      "display":"ขนอนหลวง"
      },
      {
      "code":"140606",
      "display":"คลองจิก"
      },
      {
      "code":"140614",
      "display":"คุ้งลาน"
      },
      {
      "code":"140617",
      "display":"ตลาดเกรียบ"
      },
      {
      "code":"140615",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"140605",
      "display":"บางกระสั้น"
      },
      {
      "code":"140609",
      "display":"บางประแดง"
      },
      {
      "code":"140604",
      "display":"บ้านกรด"
      },
      {
      "code":"140612",
      "display":"บ้านพลับ"
      },
      {
      "code":"140616",
      "display":"บ้านสร้าง"
      },
      {
      "code":"140607",
      "display":"บ้านหว้า"
      },
      {
      "code":"140601",
      "display":"บ้านเลน"
      },
      {
      "code":"140404",
      "display":"บ้านแป้ง"
      },
      {
      "code":"140603",
      "display":"บ้านโพ"
      },
      {
      "code":"140502",
      "display":"วัดยม"
      },
      {
      "code":"140610",
      "display":"สามเรือน"
      },
      {
      "code":"140611",
      "display":"เกาะเกิด"
      },
      {
      "code":"140422",
      "display":"เชียงรากน้อย"
      }
      ],
      "code":"1406",
      "display":"บางปะอิน"
      },
      {
      "districts":[
      {
      "code":"140415",
      "display":"กกแก้วบูรพา"
      },
      {
      "code":"140410",
      "display":"กระแชง"
      },
      {
      "code":"140409",
      "display":"ช่างเหล็ก"
      },
      {
      "code":"140412",
      "display":"ช้างน้อย"
      },
      {
      "code":"140420",
      "display":"ช้างใหญ่"
      },
      {
      "code":"140402",
      "display":"บางพลี"
      },
      {
      "code":"140406",
      "display":"บางยี่โท"
      },
      {
      "code":"140401",
      "display":"บางไทร"
      },
      {
      "code":"140411",
      "display":"บ้านกลึง"
      },
      {
      "code":"140417",
      "display":"บ้านม้า"
      },
      {
      "code":"140116",
      "display":"บ้านเกาะ"
      },
      {
      "code":"140404",
      "display":"บ้านแป้ง"
      },
      {
      "code":"140419",
      "display":"ราชคราม"
      },
      {
      "code":"140403",
      "display":"สนามชัย"
      },
      {
      "code":"140405",
      "display":"หน้าไม้"
      },
      {
      "code":"140413",
      "display":"ห่อหมก"
      },
      {
      "code":"140422",
      "display":"เชียงรากน้อย"
      },
      {
      "code":"140408",
      "display":"แคตก"
      },
      {
      "code":"140407",
      "display":"แคออก"
      },
      {
      "code":"140423",
      "display":"โคกช้าง"
      },
      {
      "code":"140421",
      "display":"โพแตง"
      },
      {
      "code":"140414",
      "display":"ไผ่พระ"
      },
      {
      "code":"140416",
      "display":"ไม้ตรา"
      }
      ],
      "code":"1404",
      "display":"บางไทร"
      },
      {
      "districts":[
      {
      "code":"141604",
      "display":"คลองน้อย"
      },
      {
      "code":"141601",
      "display":"บ้านแพรก"
      },
      {
      "code":"140115",
      "display":"บ้านใหม่"
      },
      {
      "code":"141605",
      "display":"สองห้อง"
      },
      {
      "code":"141603",
      "display":"สำพะเนียง"
      }
      ],
      "code":"1416",
      "display":"บ้านแพรก"
      },
      {
      "districts":[
      {
      "code":"140809",
      "display":"กุฎี"
      },
      {
      "code":"140812",
      "display":"จักราช"
      },
      {
      "code":"140807",
      "display":"ดอนลาน"
      },
      {
      "code":"140805",
      "display":"ตาลาน"
      },
      {
      "code":"140806",
      "display":"ท่าดินแดง"
      },
      {
      "code":"140808",
      "display":"นาคู"
      },
      {
      "code":"140803",
      "display":"บ้านแค"
      },
      {
      "code":"140816",
      "display":"บ้านใหญ่"
      },
      {
      "code":"140801",
      "display":"ผักไห่"
      },
      {
      "code":"140814",
      "display":"ลาดชิด"
      },
      {
      "code":"140804",
      "display":"ลาดน้ำเค็ม"
      },
      {
      "code":"140810",
      "display":"ลำตะเคียน"
      },
      {
      "code":"140813",
      "display":"หนองน้ำใหญ่"
      },
      {
      "code":"140815",
      "display":"หน้าโคก"
      },
      {
      "code":"140802",
      "display":"อมฤต"
      },
      {
      "code":"140423",
      "display":"โคกช้าง"
      }
      ],
      "code":"1408",
      "display":"ผักไห่"
      },
      {
      "districts":[
      {
      "code":"140102",
      "display":"กะมัง"
      },
      {
      "code":"140111",
      "display":"คลองตะเคียน"
      },
      {
      "code":"140118",
      "display":"คลองสระบัว"
      },
      {
      "code":"140117",
      "display":"คลองสวนพลู"
      },
      {
      "code":"140105",
      "display":"ท่าวาสุกรี"
      },
      {
      "code":"140120",
      "display":"บ้านป้อม"
      },
      {
      "code":"140121",
      "display":"บ้านรุน"
      },
      {
      "code":"140116",
      "display":"บ้านเกาะ"
      },
      {
      "code":"140115",
      "display":"บ้านใหม่"
      },
      {
      "code":"140101",
      "display":"ประตูชัย"
      },
      {
      "code":"140107",
      "display":"ปากกราน"
      },
      {
      "code":"140108",
      "display":"ภูเขาทอง"
      },
      {
      "code":"140114",
      "display":"ลุมพลี"
      },
      {
      "code":"140112",
      "display":"วัดตูม"
      },
      {
      "code":"140110",
      "display":"สวนพริก"
      },
      {
      "code":"140109",
      "display":"สำเภาล่ม"
      },
      {
      "code":"140103",
      "display":"หอรัตนไชย"
      },
      {
      "code":"140113",
      "display":"หันตรา"
      },
      {
      "code":"140104",
      "display":"หัวรอ"
      },
      {
      "code":"140119",
      "display":"เกาะเรียน"
      },
      {
      "code":"140106",
      "display":"ไผ่ลิง"
      }
      ],
      "code":"1401",
      "display":"พระนครศรีอยุธยา"
      },
      {
      "districts":[
      {
      "code":"140907",
      "display":"กระจิว"
      },
      {
      "code":"140905",
      "display":"ดอนหญ้านาง"
      },
      {
      "code":"140908",
      "display":"พระแก้ว"
      },
      {
      "code":"140901",
      "display":"ภาชี"
      },
      {
      "code":"140903",
      "display":"ระโสม"
      },
      {
      "code":"140904",
      "display":"หนองน้ำใส"
      },
      {
      "code":"140902",
      "display":"โคกม่วง"
      },
      {
      "code":"140906",
      "display":"ไผ่ล้อม"
      }
      ],
      "code":"1409",
      "display":"ภาชี"
      },
      {
      "districts":[
      {
      "code":"141502",
      "display":"กะทุ่ม"
      },
      {
      "code":"141511",
      "display":"ท่าตอ"
      },
      {
      "code":"140509",
      "display":"น้ำเต้า"
      },
      {
      "code":"141505",
      "display":"บางนา"
      },
      {
      "code":"141510",
      "display":"บ้านขวาง"
      },
      {
      "code":"141509",
      "display":"บ้านนา"
      },
      {
      "code":"140115",
      "display":"บ้านใหม่"
      },
      {
      "code":"141508",
      "display":"พิตเพียน"
      },
      {
      "code":"141503",
      "display":"มหาราช"
      },
      {
      "code":"141501",
      "display":"หัวไผ่"
      },
      {
      "code":"141507",
      "display":"เจ้าปลุก"
      },
      {
      "code":"141506",
      "display":"โรงช้าง"
      }
      ],
      "code":"1415",
      "display":"มหาราช"
      },
      {
      "districts":[
      {
      "code":"141007",
      "display":"คลองพระยาบันลือ"
      },
      {
      "code":"141006",
      "display":"คู้สลอด"
      },
      {
      "code":"141004",
      "display":"พระยาบันลือ"
      },
      {
      "code":"141001",
      "display":"ลาดบัวหลวง"
      },
      {
      "code":"141003",
      "display":"สามเมือง"
      },
      {
      "code":"141005",
      "display":"สิงหนาท"
      },
      {
      "code":"141002",
      "display":"หลักชัย"
      }
      ],
      "code":"1410",
      "display":"ลาดบัวหลวง"
      },
      {
      "districts":[
      {
      "code":"141109",
      "display":"ข้าวงาม"
      },
      {
      "code":"141110",
      "display":"ชะแมบ"
      },
      {
      "code":"141102",
      "display":"บ่อตาโล่"
      },
      {
      "code":"141106",
      "display":"พยอม"
      },
      {
      "code":"141101",
      "display":"ลำตาเสา"
      },
      {
      "code":"141104",
      "display":"ลำไทร"
      },
      {
      "code":"141108",
      "display":"วังจุฬา"
      },
      {
      "code":"141103",
      "display":"วังน้อย"
      },
      {
      "code":"141105",
      "display":"สนับทึบ"
      },
      {
      "code":"141107",
      "display":"หันตะเภา"
      }
      ],
      "code":"1411",
      "display":"วังน้อย"
      },
      {
      "districts":[
      {
      "code":"141411",
      "display":"ข้าวเม่า"
      },
      {
      "code":"141401",
      "display":"คานหาม"
      },
      {
      "code":"141410",
      "display":"ธนู"
      },
      {
      "code":"141402",
      "display":"บ้านช้าง"
      },
      {
      "code":"141404",
      "display":"บ้านหีบ"
      },
      {
      "code":"141403",
      "display":"สามบัณฑิต"
      },
      {
      "code":"141408",
      "display":"หนองน้ำส้ม"
      },
      {
      "code":"141405",
      "display":"หนองไม้ซุง"
      },
      {
      "code":"141406",
      "display":"อุทัย"
      },
      {
      "code":"141201",
      "display":"เสนา"
      },
      {
      "code":"141409",
      "display":"โพสาวหาญ"
      }
      ],
      "code":"1414",
      "display":"อุทัย"
      },
      {
      "districts":[
      {
      "code":"141212",
      "display":"ชายนา"
      },
      {
      "code":"141215",
      "display":"ดอนทอง"
      },
      {
      "code":"141205",
      "display":"บางนมโค"
      },
      {
      "code":"141210",
      "display":"บ้านกระทุ่ม"
      },
      {
      "code":"141216",
      "display":"บ้านหลวง"
      },
      {
      "code":"141211",
      "display":"บ้านแถว"
      },
      {
      "code":"141202",
      "display":"บ้านแพน"
      },
      {
      "code":"141208",
      "display":"บ้านโพธิ์"
      },
      {
      "code":"141207",
      "display":"มารวิชัย"
      },
      {
      "code":"141209",
      "display":"รางจรเข้"
      },
      {
      "code":"141214",
      "display":"ลาดงา"
      },
      {
      "code":"141204",
      "display":"สามกอ"
      },
      {
      "code":"141213",
      "display":"สามตุ่ม"
      },
      {
      "code":"141206",
      "display":"หัวเวียง"
      },
      {
      "code":"141203",
      "display":"เจ้าเจ็ด"
      },
      {
      "code":"141217",
      "display":"เจ้าเสด็จ"
      },
      {
      "code":"141201",
      "display":"เสนา"
      }
      ],
      "code":"1412",
      "display":"เสนา"
      }
      ],
      "display":"พระนครศรีอยุธยา"
      },
      "พะเยา":{
      "sort_order":"32",
      "code":"56",
      "amphoe":[
      {
      "districts":[
      {
      "code":"560202",
      "display":"จุน"
      },
      {
      "code":"560205",
      "display":"ทุ่งรวงทอง"
      },
      {
      "code":"560207",
      "display":"พระธาตุขิงแกง"
      },
      {
      "code":"560203",
      "display":"ลอ"
      },
      {
      "code":"560204",
      "display":"หงส์หิน"
      },
      {
      "code":"560201",
      "display":"ห้วยข้าวก่ำ"
      },
      {
      "code":"560206",
      "display":"ห้วยยางขาม"
      }
      ],
      "code":"5602",
      "display":"จุน"
      },
      {
      "districts":[
      {
      "code":"560512",
      "display":"คือเวียง"
      },
      {
      "code":"560509",
      "display":"ดงสุวรรณ"
      },
      {
      "code":"560501",
      "display":"ดอกคำใต้"
      },
      {
      "code":"560502",
      "display":"ดอนศรีชุม"
      },
      {
      "code":"560510",
      "display":"บุญเกิด"
      },
      {
      "code":"560503",
      "display":"บ้านถ้ำ"
      },
      {
      "code":"560504",
      "display":"บ้านปิน"
      },
      {
      "code":"560507",
      "display":"ป่าซาง"
      },
      {
      "code":"560511",
      "display":"สว่างอารมณ์"
      },
      {
      "code":"560506",
      "display":"สันโค้ง"
      },
      {
      "code":"560508",
      "display":"หนองหล่ม"
      },
      {
      "code":"560505",
      "display":"ห้วยลาน"
      }
      ],
      "code":"5605",
      "display":"ดอกคำใต้"
      },
      {
      "districts":[
      {
      "code":"560607",
      "display":"ขุนควร"
      },
      {
      "code":"560602",
      "display":"ควร"
      },
      {
      "code":"560604",
      "display":"งิม"
      },
      {
      "code":"560606",
      "display":"นาปรัง"
      },
      {
      "code":"560601",
      "display":"ปง"
      },
      {
      "code":"560605",
      "display":"ผาช้างน้อย"
      },
      {
      "code":"560603",
      "display":"ออย"
      }
      ],
      "code":"5606",
      "display":"ปง"
      },
      {
      "districts":[
      {
      "code":"560103",
      "display":"ดงเจน"
      },
      {
      "code":"560109",
      "display":"ห้วยแก้ว"
      },
      {
      "code":"560117",
      "display":"แม่อิง"
      }
      ],
      "code":"5609",
      "display":"ภูกามยาว"
      },
      {
      "districts":[
      {
      "code":"560302",
      "display":"ทุ่งกล้วย"
      },
      {
      "code":"560315",
      "display":"ป่าสัก"
      },
      {
      "code":"560305",
      "display":"ภูซาง"
      },
      {
      "code":"560303",
      "display":"สบบง"
      },
      {
      "code":"560304",
      "display":"เชียงแรง"
      }
      ],
      "code":"5608",
      "display":"ภูซาง"
      },
      {
      "districts":[
      {
      "code":"560314",
      "display":"ทุ่งผาสุข"
      },
      {
      "code":"560306",
      "display":"น้ำแวน"
      },
      {
      "code":"560308",
      "display":"ฝายกวาง"
      },
      {
      "code":"560310",
      "display":"ร่มเย็น"
      },
      {
      "code":"560301",
      "display":"หย่วน"
      },
      {
      "code":"560313",
      "display":"อ่างทอง"
      },
      {
      "code":"560309",
      "display":"เจดีย์คำ"
      },
      {
      "code":"560311",
      "display":"เชียงบาน"
      },
      {
      "code":"560101",
      "display":"เวียง"
      },
      {
      "code":"560312",
      "display":"แม่ลาว"
      }
      ],
      "code":"5603",
      "display":"เชียงคำ"
      },
      {
      "districts":[
      {
      "code":"560402",
      "display":"บ้านมาง"
      },
      {
      "code":"560403",
      "display":"สระ"
      },
      {
      "code":"560401",
      "display":"เชียงม่วน"
      }
      ],
      "code":"5604",
      "display":"เชียงม่วน"
      },
      {
      "districts":[
      {
      "code":"560112",
      "display":"จำป่าหวาย"
      },
      {
      "code":"560116",
      "display":"ท่าจำปี"
      },
      {
      "code":"560113",
      "display":"ท่าวังทอง"
      },
      {
      "code":"560105",
      "display":"บ้านตุ่น"
      },
      {
      "code":"560106",
      "display":"บ้านต๊ำ"
      },
      {
      "code":"560107",
      "display":"บ้านต๋อม"
      },
      {
      "code":"560115",
      "display":"บ้านสาง"
      },
      {
      "code":"560111",
      "display":"บ้านใหม่"
      },
      {
      "code":"560118",
      "display":"สันป่าม่วง"
      },
      {
      "code":"560101",
      "display":"เวียง"
      },
      {
      "code":"560110",
      "display":"แม่กา"
      },
      {
      "code":"560102",
      "display":"แม่ต๋ำ"
      },
      {
      "code":"560104",
      "display":"แม่นาเรือ"
      },
      {
      "code":"560108",
      "display":"แม่ปืม"
      },
      {
      "code":"560114",
      "display":"แม่ใส"
      }
      ],
      "code":"5601",
      "display":"เมืองพะเยา"
      },
      {
      "districts":[
      {
      "code":"560705",
      "display":"บ้านเหล่า"
      },
      {
      "code":"560704",
      "display":"ป่าแฝก"
      },
      {
      "code":"560702",
      "display":"ศรีถ้อย"
      },
      {
      "code":"560706",
      "display":"เจริญราษฎร์"
      },
      {
      "code":"560703",
      "display":"แม่สุก"
      },
      {
      "code":"560701",
      "display":"แม่ใจ"
      }
      ],
      "code":"5607",
      "display":"แม่ใจ"
      }
      ],
      "display":"พะเยา"
      },
      "พังงา":{
      "sort_order":"33",
      "code":"82",
      "amphoe":[
      {
      "districts":[
      {
      "code":"820301",
      "display":"กะปง"
      },
      {
      "code":"820302",
      "display":"ท่านา"
      },
      {
      "code":"820305",
      "display":"รมณีย์"
      },
      {
      "code":"820303",
      "display":"เหมาะ"
      },
      {
      "code":"820304",
      "display":"เหล"
      }
      ],
      "code":"8203",
      "display":"กะปง"
      },
      {
      "districts":[
      {
      "code":"820601",
      "display":"คุระ"
      },
      {
      "code":"820602",
      "display":"บางวัน"
      },
      {
      "code":"820603",
      "display":"เกาะพระทอง"
      },
      {
      "code":"820605",
      "display":"แม่นางขาว"
      }
      ],
      "code":"8206",
      "display":"คุระบุรี"
      },
      {
      "districts":[
      {
      "code":"820402",
      "display":"กระโสม"
      },
      {
      "code":"820403",
      "display":"กะไหล"
      },
      {
      "code":"820407",
      "display":"คลองเคียน"
      },
      {
      "code":"820401",
      "display":"ถ้ำ"
      },
      {
      "code":"820404",
      "display":"ท่าอยู่"
      },
      {
      "code":"820405",
      "display":"หล่อยูง"
      },
      {
      "code":"820406",
      "display":"โคกกลอย"
      }
      ],
      "code":"8204",
      "display":"ตะกั่วทุ่ง"
      },
      {
      "districts":[
      {
      "code":"820507",
      "display":"คึกคัก"
      },
      {
      "code":"820501",
      "display":"ตะกั่วป่า"
      },
      {
      "code":"820505",
      "display":"ตำตัว"
      },
      {
      "code":"820502",
      "display":"บางนายสี"
      },
      {
      "code":"820504",
      "display":"บางม่วง"
      },
      {
      "code":"820503",
      "display":"บางไทร"
      },
      {
      "code":"820508",
      "display":"เกาะคอเขา"
      },
      {
      "code":"820506",
      "display":"โคกเคียน"
      }
      ],
      "code":"8205",
      "display":"ตะกั่วป่า"
      },
      {
      "districts":[
      {
      "code":"820704",
      "display":"ถ้ำทองหลาง"
      },
      {
      "code":"820701",
      "display":"ทับปุด"
      },
      {
      "code":"820706",
      "display":"บางเหรียง"
      },
      {
      "code":"820703",
      "display":"บ่อแสน"
      },
      {
      "code":"820702",
      "display":"มะรุ่ย"
      },
      {
      "code":"820705",
      "display":"โคกเจริญ"
      }
      ],
      "code":"8207",
      "display":"ทับปุด"
      },
      {
      "districts":[
      {
      "code":"820804",
      "display":"ทุ่งมะพร้าว"
      },
      {
      "code":"820801",
      "display":"ท้ายเหมือง"
      },
      {
      "code":"820802",
      "display":"นาเตย"
      },
      {
      "code":"820803",
      "display":"บางทอง"
      },
      {
      "code":"820805",
      "display":"ลำภี"
      },
      {
      "code":"820806",
      "display":"ลำแก่น"
      }
      ],
      "code":"8208",
      "display":"ท้ายเหมือง"
      },
      {
      "districts":[
      {
      "code":"820203",
      "display":"พรุใน"
      },
      {
      "code":"820199",
      "display":"เกาะยาวน้อย"
      },
      {
      "code":"820198",
      "display":"เกาะยาวใหญ่"
      }
      ],
      "code":"8202",
      "display":"เกาะยาว"
      },
      {
      "districts":[
      {
      "code":"820105",
      "display":"ตากแดด"
      },
      {
      "code":"820103",
      "display":"ถ้ำน้ำผุด"
      },
      {
      "code":"820107",
      "display":"ทุ่งคาโงก"
      },
      {
      "code":"820101",
      "display":"ท้ายช้าง"
      },
      {
      "code":"820102",
      "display":"นบปริง"
      },
      {
      "code":"820104",
      "display":"บางเตย"
      },
      {
      "code":"820109",
      "display":"ป่ากอ"
      },
      {
      "code":"820106",
      "display":"สองแพรก"
      },
      {
      "code":"820108",
      "display":"เกาะปันหยี"
      }
      ],
      "code":"8201",
      "display":"เมืองพังงา"
      }
      ],
      "display":"พังงา"
      },
      "พัทลุง":{
      "sort_order":"34",
      "code":"93",
      "amphoe":[
      {
      "districts":[
      {
      "code":"930201",
      "display":"กงหรา"
      },
      {
      "code":"930204",
      "display":"คลองทรายขาว"
      },
      {
      "code":"930203",
      "display":"คลองเฉลิม"
      },
      {
      "code":"930202",
      "display":"ชะรัด"
      },
      {
      "code":"930205",
      "display":"สมหวัง"
      }
      ],
      "code":"9302",
      "display":"กงหรา"
      },
      {
      "districts":[
      {
      "code":"930302",
      "display":"ควนขนุน"
      },
      {
      "code":"930513",
      "display":"ชะมวง"
      },
      {
      "code":"930510",
      "display":"ดอนทราย"
      },
      {
      "code":"930502",
      "display":"ทะเลน้อย"
      },
      {
      "code":"930504",
      "display":"นาขยาด"
      },
      {
      "code":"930508",
      "display":"ปันแต"
      },
      {
      "code":"930505",
      "display":"พนมวังก์"
      },
      {
      "code":"930512",
      "display":"พนางตุง"
      },
      {
      "code":"930511",
      "display":"มะกอกเหนือ"
      },
      {
      "code":"930516",
      "display":"แพรกหา"
      },
      {
      "code":"930506",
      "display":"แหลมโตนด"
      },
      {
      "code":"930509",
      "display":"โตนดด้วน"
      }
      ],
      "code":"9305",
      "display":"ควนขนุน"
      },
      {
      "districts":[
      {
      "code":"930396",
      "display":"คลองใหญ่"
      },
      {
      "code":"930397",
      "display":"ตะโหมด"
      },
      {
      "code":"930399",
      "display":"แม่ขรี"
      }
      ],
      "code":"9304",
      "display":"ตะโหมด"
      },
      {
      "districts":[
      {
      "code":"930303",
      "display":"ท่ามะเดื่อ"
      },
      {
      "code":"930304",
      "display":"นาปะขอ"
      },
      {
      "code":"930308",
      "display":"โคกสัก"
      }
      ],
      "code":"9309",
      "display":"บางแก้ว"
      },
      {
      "districts":[
      {
      "code":"930510",
      "display":"ดอนทราย"
      },
      {
      "code":"930602",
      "display":"ดอนประดู่"
      },
      {
      "code":"930601",
      "display":"ปากพะยูน"
      },
      {
      "code":"930605",
      "display":"ฝาละมี"
      },
      {
      "code":"930606",
      "display":"หารเทา"
      },
      {
      "code":"930603",
      "display":"เกาะนางคำ"
      },
      {
      "code":"930604",
      "display":"เกาะหมาก"
      }
      ],
      "code":"9306",
      "display":"ปากพะยูน"
      },
      {
      "districts":[
      {
      "code":"930804",
      "display":"ทุ่งนารี"
      },
      {
      "code":"930699",
      "display":"ป่าบอน"
      },
      {
      "code":"930806",
      "display":"วังใหม่"
      },
      {
      "code":"930803",
      "display":"หนองธง"
      },
      {
      "code":"930698",
      "display":"โคกทราย"
      }
      ],
      "code":"9308",
      "display":"ป่าบอน"
      },
      {
      "districts":[
      {
      "code":"930514",
      "display":"บ้านพร้าว"
      },
      {
      "code":"930507",
      "display":"ป่าพะยอม"
      },
      {
      "code":"930515",
      "display":"ลานข่อย"
      },
      {
      "code":"930503",
      "display":"เกาะเต่า"
      }
      ],
      "code":"9310",
      "display":"ป่าพะยอม"
      },
      {
      "districts":[
      {
      "code":"930118",
      "display":"ชุมพล"
      },
      {
      "code":"930102",
      "display":"บ้านนา"
      },
      {
      "code":"930116",
      "display":"ลำสินธุ์"
      },
      {
      "code":"930117",
      "display":"อ่างทอง"
      }
      ],
      "code":"9311",
      "display":"ศรีนครินทร์"
      },
      {
      "districts":[
      {
      "code":"930703",
      "display":"ตะแพน"
      },
      {
      "code":"930702",
      "display":"เขาปู่"
      },
      {
      "code":"930701",
      "display":"เขาย่า"
      }
      ],
      "code":"9307",
      "display":"ศรีบรรพต"
      },
      {
      "districts":[
      {
      "code":"930302",
      "display":"ควนขนุน"
      },
      {
      "code":"930305",
      "display":"จองถนน"
      },
      {
      "code":"930306",
      "display":"หานโพธิ์"
      },
      {
      "code":"930301",
      "display":"เขาชัยสน"
      },
      {
      "code":"930307",
      "display":"โคกม่วง"
      }
      ],
      "code":"9303",
      "display":"เขาชัยสน"
      },
      {
      "districts":[
      {
      "code":"930111",
      "display":"ควนมะพร้าว"
      },
      {
      "code":"930101",
      "display":"คูหาสวรรค์"
      },
      {
      "code":"930113",
      "display":"ชัยบุรี"
      },
      {
      "code":"930110",
      "display":"ตำนาน"
      },
      {
      "code":"930104",
      "display":"ท่ามิหรำ"
      },
      {
      "code":"930108",
      "display":"ท่าแค"
      },
      {
      "code":"930106",
      "display":"นาท่อม"
      },
      {
      "code":"930114",
      "display":"นาโหนด"
      },
      {
      "code":"930107",
      "display":"ปรางหมู่"
      },
      {
      "code":"930115",
      "display":"พญาขัน"
      },
      {
      "code":"930112",
      "display":"ร่มเมือง"
      },
      {
      "code":"930109",
      "display":"ลำปำ"
      },
      {
      "code":"930103",
      "display":"เขาเจียก"
      },
      {
      "code":"930105",
      "display":"โคกชะงาย"
      }
      ],
      "code":"9301",
      "display":"เมืองพัทลุง"
      }
      ],
      "display":"พัทลุง"
      },
      "พิจิตร":{
      "sort_order":"35",
      "code":"66",
      "amphoe":[
      {
      "districts":[
      {
      "code":"660513",
      "display":"วังงิ้ว"
      },
      {
      "code":"660515",
      "display":"วังงิ้วใต้"
      },
      {
      "code":"660510",
      "display":"สำนักขุนเณร"
      },
      {
      "code":"660511",
      "display":"ห้วยพุก"
      },
      {
      "code":"660512",
      "display":"ห้วยร่วม"
      }
      ],
      "code":"6611",
      "display":"ดงเจริญ"
      },
      {
      "districts":[
      {
      "code":"660408",
      "display":"คลองคูณ"
      },
      {
      "code":"660402",
      "display":"งิ้วราย"
      },
      {
      "code":"660407",
      "display":"ดงตะขบ"
      },
      {
      "code":"660401",
      "display":"ตะพานหิน"
      },
      {
      "code":"660412",
      "display":"ทับหมัน"
      },
      {
      "code":"660406",
      "display":"ทุ่งโพธิ์"
      },
      {
      "code":"660409",
      "display":"วังสำโรง"
      },
      {
      "code":"660411",
      "display":"วังหลุม"
      },
      {
      "code":"660410",
      "display":"วังหว้า"
      },
      {
      "code":"660405",
      "display":"หนองพยอม"
      },
      {
      "code":"660403",
      "display":"ห้วยเกตุ"
      },
      {
      "code":"660404",
      "display":"ไทรโรงโขน"
      },
      {
      "code":"660413",
      "display":"ไผ่หลวง"
      }
      ],
      "code":"6604",
      "display":"ตะพานหิน"
      },
      {
      "districts":[
      {
      "code":"660499",
      "display":"ทับคล้อ"
      },
      {
      "code":"660496",
      "display":"ท้ายทุ่ง"
      },
      {
      "code":"660498",
      "display":"เขาทราย"
      },
      {
      "code":"660497",
      "display":"เขาเจ็ดลูก"
      }
      ],
      "code":"6608",
      "display":"ทับคล้อ"
      },
      {
      "districts":[
      {
      "code":"660501",
      "display":"บางมูลนาก"
      },
      {
      "code":"660502",
      "display":"บางไผ่"
      },
      {
      "code":"660506",
      "display":"ภูมิ"
      },
      {
      "code":"660514",
      "display":"ลำประดา"
      },
      {
      "code":"660507",
      "display":"วังกรด"
      },
      {
      "code":"660509",
      "display":"วังตะกู"
      },
      {
      "code":"660409",
      "display":"วังสำโรง"
      },
      {
      "code":"660503",
      "display":"หอไกร"
      },
      {
      "code":"660508",
      "display":"ห้วยเขน"
      },
      {
      "code":"660504",
      "display":"เนินมะกอก"
      }
      ],
      "code":"6605",
      "display":"บางมูลนาก"
      },
      {
      "districts":[
      {
      "code":"660609",
      "display":"บางลาย"
      },
      {
      "code":"660610",
      "display":"บึงนาราง"
      },
      {
      "code":"660616",
      "display":"ห้วยแก้ว"
      },
      {
      "code":"660615",
      "display":"แหลมรัง"
      },
      {
      "code":"660614",
      "display":"โพธิ์ไทรงาม"
      }
      ],
      "code":"6610",
      "display":"บึงนาราง"
      },
      {
      "districts":[
      {
      "code":"660709",
      "display":"บึงบัว"
      },
      {
      "code":"660705",
      "display":"บ้านนา"
      },
      {
      "code":"660708",
      "display":"วังโมกข์"
      },
      {
      "code":"660704",
      "display":"หนองหลุม"
      }
      ],
      "code":"6612",
      "display":"วชิรบารมี"
      },
      {
      "districts":[
      {
      "code":"660199",
      "display":"วังทรายพูน"
      },
      {
      "code":"660198",
      "display":"หนองปลาไหล"
      },
      {
      "code":"660114",
      "display":"หนองปล้อง"
      },
      {
      "code":"660197",
      "display":"หนองพระ"
      }
      ],
      "code":"6602",
      "display":"วังทรายพูน"
      },
      {
      "districts":[
      {
      "code":"660118",
      "display":"คลองทราย"
      },
      {
      "code":"660117",
      "display":"ท่าเยี่ยม"
      },
      {
      "code":"660905",
      "display":"วังทับไทร"
      },
      {
      "code":"660116",
      "display":"สากเหล็ก"
      },
      {
      "code":"660904",
      "display":"หนองหญ้าไทร"
      }
      ],
      "code":"6609",
      "display":"สากเหล็ก"
      },
      {
      "districts":[
      {
      "code":"660702",
      "display":"กำแพงดิน"
      },
      {
      "code":"660703",
      "display":"รังนก"
      },
      {
      "code":"660701",
      "display":"สามง่าม"
      },
      {
      "code":"660707",
      "display":"หนองโสน"
      },
      {
      "code":"660706",
      "display":"เนินปอ"
      }
      ],
      "code":"6607",
      "display":"สามง่าม"
      },
      {
      "districts":[
      {
      "code":"660106",
      "display":"คลองคะเชนทร์"
      },
      {
      "code":"660111",
      "display":"ฆะมัง"
      },
      {
      "code":"660120",
      "display":"ดงกลาง"
      },
      {
      "code":"660112",
      "display":"ดงป่าคำ"
      },
      {
      "code":"660109",
      "display":"ท่าหลวง"
      },
      {
      "code":"660104",
      "display":"ท่าฬ่อ"
      },
      {
      "code":"660110",
      "display":"บ้านบุ่ง"
      },
      {
      "code":"660105",
      "display":"ปากทาง"
      },
      {
      "code":"660115",
      "display":"ป่ามะคาบ"
      },
      {
      "code":"660103",
      "display":"ย่านยาว"
      },
      {
      "code":"660119",
      "display":"สายคำโห้"
      },
      {
      "code":"660113",
      "display":"หัวดง"
      },
      {
      "code":"660108",
      "display":"เมืองเก่า"
      },
      {
      "code":"660107",
      "display":"โรงช้าง"
      },
      {
      "code":"660101",
      "display":"ในเมือง"
      },
      {
      "code":"660102",
      "display":"ไผ่ขวาง"
      }
      ],
      "code":"6601",
      "display":"เมืองพิจิตร"
      },
      {
      "districts":[
      {
      "code":"660603",
      "display":"ทะนง"
      },
      {
      "code":"660605",
      "display":"ทุ่งน้อย"
      },
      {
      "code":"660606",
      "display":"ท่าขมิ้น"
      },
      {
      "code":"660611",
      "display":"ท่านั่ง"
      },
      {
      "code":"660604",
      "display":"ท่าบัว"
      },
      {
      "code":"660607",
      "display":"ท่าเสา"
      },
      {
      "code":"660602",
      "display":"ท้ายน้ำ"
      },
      {
      "code":"660608",
      "display":"บางคลาน"
      },
      {
      "code":"660612",
      "display":"บ้านน้อย"
      },
      {
      "code":"660613",
      "display":"วัดขวาง"
      },
      {
      "code":"660601",
      "display":"โพทะเล"
      }
      ],
      "code":"6606",
      "display":"โพทะเล"
      },
      {
      "districts":[
      {
      "code":"660305",
      "display":"ดงเสือเหลือง"
      },
      {
      "code":"660307",
      "display":"ทุ่งใหญ่"
      },
      {
      "code":"660193",
      "display":"วังจิก"
      },
      {
      "code":"660306",
      "display":"เนินสว่าง"
      },
      {
      "code":"660194",
      "display":"โพธิ์ประทับช้าง"
      },
      {
      "code":"660195",
      "display":"ไผ่ท่าโพ"
      },
      {
      "code":"660192",
      "display":"ไผ่รอบ"
      }
      ],
      "code":"6603",
      "display":"โพธิ์ประทับช้าง"
      }
      ],
      "display":"พิจิตร"
      },
      "พิษณุโลก":{
      "sort_order":"36",
      "code":"65",
      "amphoe":[
      {
      "districts":[
      {
      "code":"650302",
      "display":"ชาติตระการ"
      },
      {
      "code":"650306",
      "display":"ท่าสะแก"
      },
      {
      "code":"650305",
      "display":"บ่อภาค"
      },
      {
      "code":"650304",
      "display":"บ้านดง"
      },
      {
      "code":"650301",
      "display":"ป่าแดง"
      },
      {
      "code":"650303",
      "display":"สวนเมี่ยง"
      }
      ],
      "code":"6503",
      "display":"ชาติตระการ"
      },
      {
      "districts":[
      {
      "code":"650206",
      "display":"นครชุม"
      },
      {
      "code":"650201",
      "display":"นครไทย"
      },
      {
      "code":"650205",
      "display":"นาบัว"
      },
      {
      "code":"650207",
      "display":"น้ำกุ่ม"
      },
      {
      "code":"650209",
      "display":"บ่อโพธิ์"
      },
      {
      "code":"650210",
      "display":"บ้านพร้าว"
      },
      {
      "code":"650203",
      "display":"บ้านแยง"
      },
      {
      "code":"650208",
      "display":"ยางโกลน"
      },
      {
      "code":"650202",
      "display":"หนองกะท้าว"
      },
      {
      "code":"650211",
      "display":"ห้วยเฮี้ย"
      },
      {
      "code":"650204",
      "display":"เนินเพิ่ม"
      }
      ],
      "code":"6502",
      "display":"นครไทย"
      },
      {
      "districts":[
      {
      "code":"650505",
      "display":"ท่าตาล"
      },
      {
      "code":"650507",
      "display":"นครป่าหมาก"
      },
      {
      "code":"650501",
      "display":"บางกระทุ่ม"
      },
      {
      "code":"650502",
      "display":"บ้านไร่"
      },
      {
      "code":"650509",
      "display":"วัดตายม"
      },
      {
      "code":"650504",
      "display":"สนามคลี"
      },
      {
      "code":"650508",
      "display":"เนินกุ่ม"
      },
      {
      "code":"650503",
      "display":"โคกสลุด"
      },
      {
      "code":"650506",
      "display":"ไผ่ล้อม"
      }
      ],
      "code":"6505",
      "display":"บางกระทุ่ม"
      },
      {
      "districts":[
      {
      "code":"650411",
      "display":"คุยม่วง"
      },
      {
      "code":"650407",
      "display":"ชุมแสงสงคราม"
      },
      {
      "code":"650410",
      "display":"ท่านางงาม"
      },
      {
      "code":"650408",
      "display":"นิคมพัฒนา"
      },
      {
      "code":"650401",
      "display":"บางระกำ"
      },
      {
      "code":"650405",
      "display":"บึงกอก"
      },
      {
      "code":"650409",
      "display":"บ่อทอง"
      },
      {
      "code":"650402",
      "display":"ปลักแรด"
      },
      {
      "code":"650403",
      "display":"พันเสา"
      },
      {
      "code":"650404",
      "display":"วังอิทก"
      },
      {
      "code":"650406",
      "display":"หนองกุลา"
      }
      ],
      "code":"6504",
      "display":"บางระกำ"
      },
      {
      "districts":[
      {
      "code":"650612",
      "display":"ดงประคำ"
      },
      {
      "code":"650607",
      "display":"ตลุกเทียม"
      },
      {
      "code":"650611",
      "display":"ทับยายเชียง"
      },
      {
      "code":"650602",
      "display":"ท่าช้าง"
      },
      {
      "code":"650601",
      "display":"พรหมพิราม"
      },
      {
      "code":"650604",
      "display":"มะตูม"
      },
      {
      "code":"650610",
      "display":"มะต้อง"
      },
      {
      "code":"650603",
      "display":"วงฆ้อง"
      },
      {
      "code":"650608",
      "display":"วังวน"
      },
      {
      "code":"650606",
      "display":"ศรีภิรมย์"
      },
      {
      "code":"650609",
      "display":"หนองแขม"
      },
      {
      "code":"650605",
      "display":"หอกลอง"
      }
      ],
      "code":"6506",
      "display":"พรหมพิราม"
      },
      {
      "districts":[
      {
      "code":"650810",
      "display":"ชัยนาม"
      },
      {
      "code":"650811",
      "display":"ดินทอง"
      },
      {
      "code":"650807",
      "display":"ท่าหมื่นราม"
      },
      {
      "code":"650804",
      "display":"บ้านกลาง"
      },
      {
      "code":"650802",
      "display":"พันชาลี"
      },
      {
      "code":"650801",
      "display":"วังทอง"
      },
      {
      "code":"650808",
      "display":"วังนกแอ่น"
      },
      {
      "code":"650805",
      "display":"วังพิกุล"
      },
      {
      "code":"650809",
      "display":"หนองพระ"
      },
      {
      "code":"650806",
      "display":"แก่งโสภา"
      },
      {
      "code":"650803",
      "display":"แม่ระกา"
      }
      ],
      "code":"6508",
      "display":"วังทอง"
      },
      {
      "districts":[
      {
      "code":"650706",
      "display":"คันโช้ง"
      },
      {
      "code":"650702",
      "display":"ท่างาม"
      },
      {
      "code":"650703",
      "display":"ท้อแท้"
      },
      {
      "code":"650704",
      "display":"บ้านยาง"
      },
      {
      "code":"650701",
      "display":"วัดโบสถ์"
      },
      {
      "code":"650705",
      "display":"หินลาด"
      }
      ],
      "code":"6507",
      "display":"วัดโบสถ์"
      },
      {
      "districts":[
      {
      "code":"650899",
      "display":"ชมพู"
      },
      {
      "code":"650895",
      "display":"บ้านน้อยซุ้มขี้เหล็ก"
      },
      {
      "code":"650898",
      "display":"บ้านมุง"
      },
      {
      "code":"650907",
      "display":"วังยาง"
      },
      {
      "code":"650896",
      "display":"วังโพรง"
      },
      {
      "code":"650906",
      "display":"เนินมะปราง"
      },
      {
      "code":"650897",
      "display":"ไทรย้อย"
      }
      ],
      "code":"6509",
      "display":"เนินมะปราง"
      },
      {
      "districts":[
      {
      "code":"650120",
      "display":"งิ้วงาม"
      },
      {
      "code":"650112",
      "display":"จอมทอง"
      },
      {
      "code":"650108",
      "display":"ดอนทอง"
      },
      {
      "code":"650105",
      "display":"ท่าทอง"
      },
      {
      "code":"650106",
      "display":"ท่าโพธิ์"
      },
      {
      "code":"650118",
      "display":"บึงพระ"
      },
      {
      "code":"650113",
      "display":"บ้านกร่าง"
      },
      {
      "code":"650114",
      "display":"บ้านคลอง"
      },
      {
      "code":"650109",
      "display":"บ้านป่า"
      },
      {
      "code":"650110",
      "display":"ปากโทก"
      },
      {
      "code":"650115",
      "display":"พลายชุมพล"
      },
      {
      "code":"650116",
      "display":"มะขามสูง"
      },
      {
      "code":"650102",
      "display":"วังน้ำคู้"
      },
      {
      "code":"650103",
      "display":"วัดจันทร์"
      },
      {
      "code":"650104",
      "display":"วัดพริก"
      },
      {
      "code":"650107",
      "display":"สมอแข"
      },
      {
      "code":"650111",
      "display":"หัวรอ"
      },
      {
      "code":"650117",
      "display":"อรัญญิก"
      },
      {
      "code":"650101",
      "display":"ในเมือง"
      },
      {
      "code":"650119",
      "display":"ไผ่ขอดอน"
      }
      ],
      "code":"6501",
      "display":"เมืองพิษณุโลก"
      }
      ],
      "display":"พิษณุโลก"
      },
      "ภูเก็ต":{
      "sort_order":"37",
      "code":"83",
      "amphoe":[
      {
      "districts":[
      {
      "code":"830203",
      "display":"กมลา"
      },
      {
      "code":"830201",
      "display":"กะทู้"
      },
      {
      "code":"830202",
      "display":"ป่าตอง"
      }
      ],
      "code":"8302",
      "display":"กะทู้"
      },
      {
      "districts":[
      {
      "code":"830304",
      "display":"ป่าคลอก"
      },
      {
      "code":"830302",
      "display":"ศรีสุนทร"
      },
      {
      "code":"830306",
      "display":"สาคู"
      },
      {
      "code":"830303",
      "display":"เชิงทะเล"
      },
      {
      "code":"830301",
      "display":"เทพกระษัตรี"
      },
      {
      "code":"830305",
      "display":"ไม้ขาว"
      }
      ],
      "code":"8303",
      "display":"ถลาง"
      },
      {
      "districts":[
      {
      "code":"830108",
      "display":"กะรน"
      },
      {
      "code":"830106",
      "display":"ฉลอง"
      },
      {
      "code":"830106",
      "display":"ฉลอง"
      },
      {
      "code":"830102",
      "display":"ตลาดเหนือ"
      },
      {
      "code":"830101",
      "display":"ตลาดใหญ่"
      },
      {
      "code":"830104",
      "display":"รัษฎา"
      },
      {
      "code":"830107",
      "display":"ราไวย์"
      },
      {
      "code":"830107",
      "display":"ราไวย์"
      },
      {
      "code":"830105",
      "display":"วิชิต"
      },
      {
      "code":"830103",
      "display":"เกาะแก้ว"
      }
      ],
      "code":"8301",
      "display":"เมืองภูเก็ต"
      }
      ],
      "display":"ภูเก็ต"
      },
      "มหาสารคาม":{
      "sort_order":"38",
      "code":"44",
      "amphoe":[
      {
      "districts":[
      {
      "code":"440409",
      "display":"กุดใส้จ่อ"
      },
      {
      "code":"440410",
      "display":"ขามเฒ่าพัฒนา"
      },
      {
      "code":"440406",
      "display":"ขามเรียง"
      },
      {
      "code":"440402",
      "display":"คันธารราษฎร์"
      },
      {
      "code":"440404",
      "display":"ท่าขอนยาง"
      },
      {
      "code":"440405",
      "display":"นาสีนวน"
      },
      {
      "code":"440403",
      "display":"มะค่า"
      },
      {
      "code":"440408",
      "display":"ศรีสุข"
      },
      {
      "code":"440407",
      "display":"เขวาใหญ่"
      },
      {
      "code":"440401",
      "display":"โคกพระ"
      }
      ],
      "code":"4404",
      "display":"กันทรวิชัย"
      },
      {
      "districts":[
      {
      "code":"440603",
      "display":"กุดรัง"
      },
      {
      "code":"440612",
      "display":"นาโพธิ์"
      },
      {
      "code":"440614",
      "display":"หนองแวง"
      },
      {
      "code":"440617",
      "display":"ห้วยเตย"
      },
      {
      "code":"440609",
      "display":"เลิงแฝก"
      }
      ],
      "code":"4412",
      "display":"กุดรัง"
      },
      {
      "districts":[
      {
      "code":"440509",
      "display":"กุดปลาดุก"
      },
      {
      "code":"440502",
      "display":"ชื่นชม"
      },
      {
      "code":"440204",
      "display":"หนองกุง"
      },
      {
      "code":"440504",
      "display":"เหล่าดอกไม้"
      }
      ],
      "code":"4413",
      "display":"ชื่นชม"
      },
      {
      "districts":[
      {
      "code":"441008",
      "display":"กู่สันตรัตน์"
      },
      {
      "code":"441005",
      "display":"ดงดวน"
      },
      {
      "code":"441004",
      "display":"ดงบัง"
      },
      {
      "code":"441007",
      "display":"ดงยาง"
      },
      {
      "code":"440998",
      "display":"นาดูน"
      },
      {
      "code":"441009",
      "display":"พระธาตุ"
      },
      {
      "code":"440999",
      "display":"หนองคู"
      },
      {
      "code":"440997",
      "display":"หนองไผ่"
      },
      {
      "code":"441006",
      "display":"หัวดง"
      }
      ],
      "code":"4410",
      "display":"นาดูน"
      },
      {
      "districts":[
      {
      "code":"440701",
      "display":"นาเชือก"
      },
      {
      "code":"440706",
      "display":"ปอพาน"
      },
      {
      "code":"440710",
      "display":"สันป่าตอง"
      },
      {
      "code":"440702",
      "display":"สำโรง"
      },
      {
      "code":"440204",
      "display":"หนองกุง"
      },
      {
      "code":"440707",
      "display":"หนองเม็ก"
      },
      {
      "code":"440708",
      "display":"หนองเรือ"
      },
      {
      "code":"440703",
      "display":"หนองแดง"
      },
      {
      "code":"440705",
      "display":"หนองโพธิ์"
      },
      {
      "code":"440304",
      "display":"เขวาไร่"
      }
      ],
      "code":"4407",
      "display":"นาเชือก"
      },
      {
      "districts":[
      {
      "code":"440606",
      "display":"กำพี้"
      },
      {
      "code":"440620",
      "display":"ดอนงัว"
      },
      {
      "code":"440601",
      "display":"บรบือ"
      },
      {
      "code":"440611",
      "display":"บัวมาศ"
      },
      {
      "code":"440602",
      "display":"บ่อใหญ่"
      },
      {
      "code":"440616",
      "display":"ยาง"
      },
      {
      "code":"440615",
      "display":"วังใหม่"
      },
      {
      "code":"440604",
      "display":"วังไชย"
      },
      {
      "code":"440613",
      "display":"หนองคูขาด"
      },
      {
      "code":"440610",
      "display":"หนองจิก"
      },
      {
      "code":"440605",
      "display":"หนองม่วง"
      },
      {
      "code":"440618",
      "display":"หนองสิม"
      },
      {
      "code":"440619",
      "display":"หนองโก"
      },
      {
      "code":"440607",
      "display":"โนนราษี"
      },
      {
      "code":"440608",
      "display":"โนนแดง"
      }
      ],
      "code":"4406",
      "display":"บรบือ"
      },
      {
      "districts":[
      {
      "code":"440802",
      "display":"ก้ามปู"
      },
      {
      "code":"440805",
      "display":"นาสีนวล"
      },
      {
      "code":"440801",
      "display":"ปะหลาน"
      },
      {
      "code":"440820",
      "display":"ภารแอ่น"
      },
      {
      "code":"440818",
      "display":"ราษฎร์พัฒนา"
      },
      {
      "code":"440809",
      "display":"ราษฎร์เจริญ"
      },
      {
      "code":"440815",
      "display":"ลานสะแก"
      },
      {
      "code":"440308",
      "display":"หนองบัว"
      },
      {
      "code":"440810",
      "display":"หนองบัวแก้ว"
      },
      {
      "code":"440812",
      "display":"เมืองเตา"
      },
      {
      "code":"440819",
      "display":"เมืองเสือ"
      },
      {
      "code":"440804",
      "display":"เม็กดำ"
      },
      {
      "code":"440816",
      "display":"เวียงชัย"
      },
      {
      "code":"440803",
      "display":"เวียงสะอาด"
      }
      ],
      "code":"4408",
      "display":"พยัคฆภูมิพิสัย"
      },
      {
      "districts":[
      {
      "code":"440808",
      "display":"ขามเรียน"
      },
      {
      "code":"440806",
      "display":"ดงเมือง"
      },
      {
      "code":"440811",
      "display":"นาภู"
      },
      {
      "code":"440813",
      "display":"บ้านกู่"
      },
      {
      "code":"440814",
      "display":"ยางสีสุราช"
      },
      {
      "code":"441107",
      "display":"หนองบัวสันตุ"
      },
      {
      "code":"440807",
      "display":"แวงดง"
      }
      ],
      "code":"4411",
      "display":"ยางสีสุราช"
      },
      {
      "districts":[
      {
      "code":"440902",
      "display":"ขามป้อม"
      },
      {
      "code":"440908",
      "display":"งัวบา"
      },
      {
      "code":"440904",
      "display":"ดงใหญ่"
      },
      {
      "code":"440909",
      "display":"นาข่า"
      },
      {
      "code":"440910",
      "display":"บ้านหวาย"
      },
      {
      "code":"440912",
      "display":"ประชาพัฒนา"
      },
      {
      "code":"440913",
      "display":"หนองทุ่ม"
      },
      {
      "code":"440901",
      "display":"หนองแสง"
      },
      {
      "code":"440914",
      "display":"หนองแสน"
      },
      {
      "code":"440911",
      "display":"หนองไฮ"
      },
      {
      "code":"440906",
      "display":"หัวเรือ"
      },
      {
      "code":"440903",
      "display":"เสือโก้ก"
      },
      {
      "code":"440907",
      "display":"แคน"
      },
      {
      "code":"440915",
      "display":"โคกสีทองหลาง"
      },
      {
      "code":"440905",
      "display":"โพธิ์ชัย"
      }
      ],
      "code":"4409",
      "display":"วาปีปทุม"
      },
      {
      "districts":[
      {
      "code":"440506",
      "display":"กู่ทอง"
      },
      {
      "code":"440505",
      "display":"ดอนเงิน"
      },
      {
      "code":"440507",
      "display":"นาทอง"
      },
      {
      "code":"440503",
      "display":"หนองซอน"
      },
      {
      "code":"440501",
      "display":"เชียงยืน"
      },
      {
      "code":"440508",
      "display":"เสือเฒ่า"
      },
      {
      "code":"440512",
      "display":"เหล่าบัวบาน"
      },
      {
      "code":"440511",
      "display":"โพนทอง"
      }
      ],
      "code":"4405",
      "display":"เชียงยืน"
      },
      {
      "districts":[
      {
      "code":"440106",
      "display":"ดอนหว่าน"
      },
      {
      "code":"440101",
      "display":"ตลาด"
      },
      {
      "code":"440103",
      "display":"ท่าตูม"
      },
      {
      "code":"440109",
      "display":"ท่าสองคอน"
      },
      {
      "code":"440114",
      "display":"บัวค้อ"
      },
      {
      "code":"440110",
      "display":"ลาดพัฒนา"
      },
      {
      "code":"440111",
      "display":"หนองปลิง"
      },
      {
      "code":"440113",
      "display":"หนองโน"
      },
      {
      "code":"440112",
      "display":"ห้วยแอ่ง"
      },
      {
      "code":"440107",
      "display":"เกิ้ง"
      },
      {
      "code":"440102",
      "display":"เขวา"
      },
      {
      "code":"440108",
      "display":"แก่งเลิงจาน"
      },
      {
      "code":"440104",
      "display":"แวงน่าง"
      },
      {
      "code":"440105",
      "display":"โคกก่อ"
      }
      ],
      "code":"4401",
      "display":"เมืองมหาสารคาม"
      },
      {
      "districts":[
      {
      "code":"440203",
      "display":"มิตรภาพ"
      },
      {
      "code":"440202",
      "display":"วังแสง"
      },
      {
      "code":"440204",
      "display":"หนองกุง"
      },
      {
      "code":"440201",
      "display":"แกดำ"
      },
      {
      "code":"440205",
      "display":"โนนภิบาล"
      }
      ],
      "code":"4402",
      "display":"แกดำ"
      },
      {
      "districts":[
      {
      "code":"440317",
      "display":"ดอนกลาง"
      },
      {
      "code":"440313",
      "display":"ยางท่าแจ้ง"
      },
      {
      "code":"440302",
      "display":"ยางน้อย"
      },
      {
      "code":"440303",
      "display":"วังยาว"
      },
      {
      "code":"440315",
      "display":"หนองกุงสวรรค์"
      },
      {
      "code":"440311",
      "display":"หนองบอน"
      },
      {
      "code":"440308",
      "display":"หนองบัว"
      },
      {
      "code":"440307",
      "display":"หนองเหล็ก"
      },
      {
      "code":"440301",
      "display":"หัวขวาง"
      },
      {
      "code":"440304",
      "display":"เขวาไร่"
      },
      {
      "code":"440310",
      "display":"เขื่อน"
      },
      {
      "code":"440316",
      "display":"เลิงใต้"
      },
      {
      "code":"440309",
      "display":"เหล่า"
      },
      {
      "code":"440306",
      "display":"แก้งแก"
      },
      {
      "code":"440305",
      "display":"แพง"
      },
      {
      "code":"440314",
      "display":"แห่ใต้"
      },
      {
      "code":"440312",
      "display":"โพนงาม"
      }
      ],
      "code":"4403",
      "display":"โกสุมพิสัย"
      }
      ],
      "display":"มหาสารคาม"
      },
      "มุกดาหาร":{
      "sort_order":"39",
      "code":"49",
      "amphoe":[
      {
      "districts":[
      {
      "code":"490504",
      "display":"คำชะอี"
      },
      {
      "code":"490512",
      "display":"คำบก"
      },
      {
      "code":"490514",
      "display":"น้ำเที่ยง"
      },
      {
      "code":"490506",
      "display":"บ้านค้อ"
      },
      {
      "code":"490503",
      "display":"บ้านซ่ง"
      },
      {
      "code":"490507",
      "display":"บ้านเหล่า"
      },
      {
      "code":"490505",
      "display":"หนองเอี่ยน"
      },
      {
      "code":"490511",
      "display":"เหล่าสร้างถ่อ"
      },
      {
      "code":"490508",
      "display":"โพนงาม"
      }
      ],
      "code":"4905",
      "display":"คำชะอี"
      },
      {
      "districts":[
      {
      "code":"490403",
      "display":"กกตูม"
      },
      {
      "code":"490405",
      "display":"ชะโนดน้อย"
      },
      {
      "code":"490401",
      "display":"ดงหลวง"
      },
      {
      "code":"490406",
      "display":"พังแดง"
      },
      {
      "code":"490402",
      "display":"หนองบัว"
      },
      {
      "code":"490404",
      "display":"หนองแคน"
      }
      ],
      "code":"4904",
      "display":"ดงหลวง"
      },
      {
      "districts":[
      {
      "code":"490301",
      "display":"ดอนตาล"
      },
      {
      "code":"490306",
      "display":"นาสะเม็ง"
      },
      {
      "code":"490305",
      "display":"บ้านบาก"
      },
      {
      "code":"490307",
      "display":"บ้านแก้ง"
      },
      {
      "code":"490303",
      "display":"ป่าไร่"
      },
      {
      "code":"490304",
      "display":"เหล่าหมี"
      },
      {
      "code":"490302",
      "display":"โพธิ์ไทร"
      }
      ],
      "code":"4903",
      "display":"ดอนตาล"
      },
      {
      "districts":[
      {
      "code":"490195",
      "display":"กกแดง"
      },
      {
      "code":"490196",
      "display":"นากอก"
      },
      {
      "code":"490205",
      "display":"นาอุดม"
      },
      {
      "code":"490201",
      "display":"นิคมคำสร้อย"
      },
      {
      "code":"490207",
      "display":"ร่มเกล้า"
      },
      {
      "code":"490194",
      "display":"หนองแวง"
      },
      {
      "code":"490206",
      "display":"โชคชัย"
      }
      ],
      "code":"4902",
      "display":"นิคมคำสร้อย"
      },
      {
      "districts":[
      {
      "code":"490510",
      "display":"บ้านเป้า"
      },
      {
      "code":"490513",
      "display":"ภูวง"
      },
      {
      "code":"490502",
      "display":"หนองสูง"
      },
      {
      "code":"490706",
      "display":"หนองสูงเหนือ"
      },
      {
      "code":"490501",
      "display":"หนองสูงใต้"
      },
      {
      "code":"490509",
      "display":"โนนยาง"
      }
      ],
      "code":"4907",
      "display":"หนองสูง"
      },
      {
      "districts":[
      {
      "code":"490604",
      "display":"ชะโนด"
      },
      {
      "code":"490605",
      "display":"ดงหมู"
      },
      {
      "code":"490198",
      "display":"บางทรายน้อย"
      },
      {
      "code":"490602",
      "display":"ป่งขาม"
      },
      {
      "code":"490199",
      "display":"หว้านใหญ่"
      }
      ],
      "code":"4906",
      "display":"หว้านใหญ่"
      },
      {
      "districts":[
      {
      "code":"490113",
      "display":"กุดแข้"
      },
      {
      "code":"490109",
      "display":"คำป่าหลาย"
      },
      {
      "code":"490110",
      "display":"คำอาฮวน"
      },
      {
      "code":"490112",
      "display":"ดงมอน"
      },
      {
      "code":"490111",
      "display":"ดงเย็น"
      },
      {
      "code":"490108",
      "display":"นาสีนวน"
      },
      {
      "code":"490107",
      "display":"นาโสก"
      },
      {
      "code":"490104",
      "display":"บางทรายใหญ่"
      },
      {
      "code":"490103",
      "display":"บ้านโคก"
      },
      {
      "code":"490106",
      "display":"ผึ่งแดด"
      },
      {
      "code":"490101",
      "display":"มุกดาหาร"
      },
      {
      "code":"490102",
      "display":"ศรีบุญเรือง"
      },
      {
      "code":"490105",
      "display":"โพนทราย"
      }
      ],
      "code":"4901",
      "display":"เมืองมุกดาหาร"
      }
      ],
      "display":"มุกดาหาร"
      },
      "ยะลา":{
      "sort_order":"40",
      "code":"95",
      "amphoe":[
      {
      "districts":[
      {
      "code":"950113",
      "display":"กรงปินัง"
      },
      {
      "code":"950105",
      "display":"ปุโรง"
      },
      {
      "code":"950107",
      "display":"สะเอะ"
      },
      {
      "code":"950117",
      "display":"ห้วยกระทิง"
      }
      ],
      "code":"9508",
      "display":"กรงปินัง"
      },
      {
      "districts":[
      {
      "code":"950505",
      "display":"กาบัง"
      },
      {
      "code":"950509",
      "display":"บาละ"
      }
      ],
      "code":"9507",
      "display":"กาบัง"
      },
      {
      "districts":[
      {
      "code":"950404",
      "display":"คีรีเขต"
      },
      {
      "code":"950399",
      "display":"ธารโต"
      },
      {
      "code":"950398",
      "display":"บ้านแหร"
      },
      {
      "code":"950397",
      "display":"แม่หวาด"
      }
      ],
      "code":"9504",
      "display":"ธารโต"
      },
      {
      "districts":[
      {
      "code":"950305",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"950303",
      "display":"ตาเนาะปูเต๊ะ"
      },
      {
      "code":"950304",
      "display":"ถ้ำทะลุ"
      },
      {
      "code":"950301",
      "display":"บันนังสตา"
      },
      {
      "code":"950302",
      "display":"บาเจาะ"
      },
      {
      "code":"950306",
      "display":"เขื่อนบางลาง"
      }
      ],
      "code":"9503",
      "display":"บันนังสตา"
      },
      {
      "districts":[
      {
      "code":"950508",
      "display":"กาตอง"
      },
      {
      "code":"950506",
      "display":"ตาชี"
      },
      {
      "code":"950507",
      "display":"บาโงยซิแน"
      },
      {
      "code":"950504",
      "display":"บาโร๊ะ"
      },
      {
      "code":"950503",
      "display":"ปะแต"
      },
      {
      "code":"950501",
      "display":"ยะหา"
      },
      {
      "code":"950502",
      "display":"ละแอ"
      }
      ],
      "code":"9505",
      "display":"ยะหา"
      },
      {
      "districts":[
      {
      "code":"950604",
      "display":"กอตอตือร๊ะ"
      },
      {
      "code":"950601",
      "display":"กายูบอเกาะ"
      },
      {
      "code":"950603",
      "display":"กาลอ"
      },
      {
      "code":"950602",
      "display":"กาลูปัง"
      },
      {
      "code":"950607",
      "display":"จะกว๊ะ"
      },
      {
      "code":"950616",
      "display":"ตะโล๊ะหะลอ"
      },
      {
      "code":"950608",
      "display":"ท่าธง"
      },
      {
      "code":"950610",
      "display":"บาลอ"
      },
      {
      "code":"950611",
      "display":"บาโงย"
      },
      {
      "code":"950612",
      "display":"บือมัง"
      },
      {
      "code":"950613",
      "display":"ยะต๊ะ"
      },
      {
      "code":"950614",
      "display":"วังพญา"
      },
      {
      "code":"950615",
      "display":"อาซ่อง"
      },
      {
      "code":"950606",
      "display":"เกะรอ"
      },
      {
      "code":"950609",
      "display":"เนินงาม"
      },
      {
      "code":"950605",
      "display":"โกตาบารู"
      }
      ],
      "code":"9506",
      "display":"รามัน"
      },
      {
      "districts":[
      {
      "code":"950203",
      "display":"ตาเนาะแมเราะ"
      },
      {
      "code":"950205",
      "display":"ธารน้ำทิพย์"
      },
      {
      "code":"950202",
      "display":"ยะรม"
      },
      {
      "code":"950204",
      "display":"อัยเยอร์เวง"
      },
      {
      "code":"950201",
      "display":"เบตง"
      }
      ],
      "code":"9502",
      "display":"เบตง"
      },
      {
      "districts":[
      {
      "code":"950118",
      "display":"ตาเซะ"
      },
      {
      "code":"950108",
      "display":"ท่าสาป"
      },
      {
      "code":"950115",
      "display":"บันนังสาเรง"
      },
      {
      "code":"950102",
      "display":"บุดี"
      },
      {
      "code":"950114",
      "display":"พร่อน"
      },
      {
      "code":"950106",
      "display":"ยะลา"
      },
      {
      "code":"950103",
      "display":"ยุโป"
      },
      {
      "code":"950111",
      "display":"ลำพะยา"
      },
      {
      "code":"950109",
      "display":"ลำใหม่"
      },
      {
      "code":"950104",
      "display":"ลิดล"
      },
      {
      "code":"950101",
      "display":"สะเตง"
      },
      {
      "code":"950116",
      "display":"สะเตงนอก"
      },
      {
      "code":"950110",
      "display":"หน้าถ้ำ"
      },
      {
      "code":"950112",
      "display":"เปาะเส้ง"
      }
      ],
      "code":"9501",
      "display":"เมืองยะลา"
      }
      ],
      "display":"ยะลา"
      },
      "ยโสธร":{
      "sort_order":"41",
      "code":"35",
      "amphoe":[
      {
      "districts":[
      {
      "code":"350303",
      "display":"กำแมด"
      },
      {
      "code":"350301",
      "display":"กุดชุม"
      },
      {
      "code":"350308",
      "display":"คำน้ำสร้าง"
      },
      {
      "code":"350304",
      "display":"นาโส่"
      },
      {
      "code":"350306",
      "display":"หนองหมี"
      },
      {
      "code":"350309",
      "display":"หนองแหน"
      },
      {
      "code":"350305",
      "display":"ห้วยแก้ง"
      },
      {
      "code":"350302",
      "display":"โนนเปือย"
      },
      {
      "code":"350307",
      "display":"โพนงาม"
      }
      ],
      "code":"3503",
      "display":"กุดชุม"
      },
      {
      "districts":[
      {
      "code":"350410",
      "display":"กุดกุง"
      },
      {
      "code":"350408",
      "display":"กู่จาน"
      },
      {
      "code":"350413",
      "display":"ดงเจริญ"
      },
      {
      "code":"350407",
      "display":"ดงแคนใหญ่"
      },
      {
      "code":"350405",
      "display":"ทุ่งมน"
      },
      {
      "code":"350406",
      "display":"นาคำ"
      },
      {
      "code":"350409",
      "display":"นาแก"
      },
      {
      "code":"350402",
      "display":"ย่อ"
      },
      {
      "code":"350401",
      "display":"ลุมพุก"
      },
      {
      "code":"350403",
      "display":"สงเปือย"
      },
      {
      "code":"350411",
      "display":"เหล่าไฮ"
      },
      {
      "code":"350412",
      "display":"แคนน้อย"
      },
      {
      "code":"350404",
      "display":"โพนทัน"
      }
      ],
      "code":"3504",
      "display":"คำเขื่อนแก้ว"
      },
      {
      "districts":[
      {
      "code":"350698",
      "display":"กุดน้ำใส"
      },
      {
      "code":"350696",
      "display":"ค้อวัง"
      },
      {
      "code":"350697",
      "display":"น้ำอ้อม"
      },
      {
      "code":"350699",
      "display":"ฟ้าห่วน"
      }
      ],
      "code":"3507",
      "display":"ค้อวัง"
      },
      {
      "districts":[
      {
      "code":"350197",
      "display":"ดงมะไฟ"
      },
      {
      "code":"350202",
      "display":"ดู่ลาด"
      },
      {
      "code":"350199",
      "display":"ทรายมูล"
      },
      {
      "code":"350196",
      "display":"นาเวียง"
      },
      {
      "code":"350205",
      "display":"ไผ่"
      }
      ],
      "code":"3502",
      "display":"ทรายมูล"
      },
      {
      "districts":[
      {
      "code":"350502",
      "display":"กระจาย"
      },
      {
      "code":"350505",
      "display":"ศรีฐาน"
      },
      {
      "code":"350504",
      "display":"เชียงเพ็ง"
      },
      {
      "code":"350503",
      "display":"โคกนาโก"
      },
      {
      "code":"350501",
      "display":"โพธิ์ไทร"
      }
      ],
      "code":"3505",
      "display":"ป่าติ้ว"
      },
      {
      "districts":[
      {
      "code":"350603",
      "display":"คูเมือง"
      },
      {
      "code":"350605",
      "display":"บากเรือ"
      },
      {
      "code":"350608",
      "display":"บึงแก"
      },
      {
      "code":"350604",
      "display":"ผือฮี"
      },
      {
      "code":"350609",
      "display":"พระเสาร์"
      },
      {
      "code":"350601",
      "display":"ฟ้าหยาด"
      },
      {
      "code":"350606",
      "display":"ม่วง"
      },
      {
      "code":"350610",
      "display":"สงยาง"
      },
      {
      "code":"350602",
      "display":"หัวเมือง"
      },
      {
      "code":"350607",
      "display":"โนนทราย"
      }
      ],
      "code":"3506",
      "display":"มหาชนะชัย"
      },
      {
      "districts":[
      {
      "code":"350108",
      "display":"ขั้นไดใหญ่"
      },
      {
      "code":"350115",
      "display":"ขุมเงิน"
      },
      {
      "code":"350105",
      "display":"ค้อเหนือ"
      },
      {
      "code":"350106",
      "display":"ดู่ทุ่ง"
      },
      {
      "code":"350103",
      "display":"ตาดทอง"
      },
      {
      "code":"350116",
      "display":"ทุ่งนางโอก"
      },
      {
      "code":"350109",
      "display":"ทุ่งแต้"
      },
      {
      "code":"350111",
      "display":"นาสะไมย์"
      },
      {
      "code":"350102",
      "display":"น้ำคำใหญ่"
      },
      {
      "code":"350104",
      "display":"สำราญ"
      },
      {
      "code":"350110",
      "display":"สิงห์"
      },
      {
      "code":"350114",
      "display":"หนองคู"
      },
      {
      "code":"350113",
      "display":"หนองหิน"
      },
      {
      "code":"350118",
      "display":"หนองเป็ด"
      },
      {
      "code":"350117",
      "display":"หนองเรือ"
      },
      {
      "code":"350112",
      "display":"เขื่องคำ"
      },
      {
      "code":"350107",
      "display":"เดิด"
      },
      {
      "code":"350101",
      "display":"ในเมือง"
      }
      ],
      "code":"3501",
      "display":"เมืองยโสธร"
      },
      {
      "districts":[
      {
      "code":"350807",
      "display":"กุดเชียงหมี"
      },
      {
      "code":"350811",
      "display":"กุดแห่"
      },
      {
      "code":"350802",
      "display":"บุ่งค้า"
      },
      {
      "code":"350814",
      "display":"ศรีแก้ว"
      },
      {
      "code":"350813",
      "display":"สร้างมิ่ง"
      },
      {
      "code":"350803",
      "display":"สวาท"
      },
      {
      "code":"350806",
      "display":"สามัคคี"
      },
      {
      "code":"350810",
      "display":"สามแยก"
      },
      {
      "code":"350805",
      "display":"ห้องแซง"
      },
      {
      "code":"350812",
      "display":"โคกสำราญ"
      }
      ],
      "code":"3508",
      "display":"เลิงนกทา"
      },
      {
      "districts":[
      {
      "code":"350808",
      "display":"คำเตย"
      },
      {
      "code":"350809",
      "display":"คำไผ่"
      },
      {
      "code":"350801",
      "display":"น้ำคำ"
      },
      {
      "code":"350804",
      "display":"ส้มผ่อ"
      },
      {
      "code":"350815",
      "display":"ไทยเจริญ"
      }
      ],
      "code":"3509",
      "display":"ไทยเจริญ"
      }
      ],
      "display":"ยโสธร"
      },
      "ระนอง":{
      "sort_order":"42",
      "code":"85",
      "amphoe":[
      {
      "districts":[
      {
      "code":"850406",
      "display":"จ.ป.ร."
      },
      {
      "code":"850401",
      "display":"น้ำจืด"
      },
      {
      "code":"850402",
      "display":"น้ำจืดน้อย"
      },
      {
      "code":"850407",
      "display":"บางใหญ่"
      },
      {
      "code":"850404",
      "display":"ปากจั่น"
      },
      {
      "code":"850403",
      "display":"มะมุ"
      },
      {
      "code":"850405",
      "display":"ลำเลียง"
      }
      ],
      "code":"8504",
      "display":"กระบุรี"
      },
      {
      "districts":[
      {
      "code":"850302",
      "display":"กะเปอร์"
      },
      {
      "code":"850305",
      "display":"บางหิน"
      },
      {
      "code":"850304",
      "display":"บ้านนา"
      },
      {
      "code":"850301",
      "display":"ม่วงกลวง"
      },
      {
      "code":"850303",
      "display":"เชี่ยวเหลียง"
      }
      ],
      "code":"8503",
      "display":"กะเปอร์"
      },
      {
      "districts":[
      {
      "code":"850204",
      "display":"บางพระเหนือ"
      },
      {
      "code":"850203",
      "display":"บางพระใต้"
      },
      {
      "code":"850205",
      "display":"บางแก้ว"
      },
      {
      "code":"850202",
      "display":"ละอุ่นเหนือ"
      },
      {
      "code":"850201",
      "display":"ละอุ่นใต้"
      },
      {
      "code":"850206",
      "display":"ในวงเหนือ"
      },
      {
      "code":"850207",
      "display":"ในวงใต้"
      }
      ],
      "code":"8502",
      "display":"ละอุ่น"
      },
      {
      "districts":[
      {
      "code":"850307",
      "display":"กำพวน"
      },
      {
      "code":"850306",
      "display":"นาคา"
      }
      ],
      "code":"8505",
      "display":"สุขสำราญ"
      },
      {
      "districts":[
      {
      "code":"850108",
      "display":"ทรายแดง"
      },
      {
      "code":"850106",
      "display":"บางนอน"
      },
      {
      "code":"850104",
      "display":"บางริ้น"
      },
      {
      "code":"850105",
      "display":"ปากน้ำ"
      },
      {
      "code":"850102",
      "display":"ราชกรูด"
      },
      {
      "code":"850103",
      "display":"หงาว"
      },
      {
      "code":"850107",
      "display":"หาดส้มแป้น"
      },
      {
      "code":"850109",
      "display":"เกาะพยาม"
      },
      {
      "code":"850101",
      "display":"เขานิเวศน์"
      }
      ],
      "code":"8501",
      "display":"เมืองระนอง"
      }
      ],
      "display":"ระนอง"
      },
      "ระยอง":{
      "sort_order":"43",
      "code":"21",
      "amphoe":[
      {
      "districts":[
      {
      "code":"210510",
      "display":"นิคมพัฒนา"
      },
      {
      "code":"210509",
      "display":"พนานิคม"
      },
      {
      "code":"210511",
      "display":"มะขามคู่"
      },
      {
      "code":"210508",
      "display":"มาบข่า"
      }
      ],
      "code":"2108",
      "display":"นิคมพัฒนา"
      },
      {
      "districts":[
      {
      "code":"210507",
      "display":"ชากบก"
      },
      {
      "code":"210504",
      "display":"ตาขัน"
      },
      {
      "code":"210505",
      "display":"บางบุตร"
      },
      {
      "code":"210501",
      "display":"บ้านค่าย"
      },
      {
      "code":"210503",
      "display":"หนองตะพาน"
      },
      {
      "code":"210506",
      "display":"หนองบัว"
      },
      {
      "code":"210502",
      "display":"หนองละลอก"
      }
      ],
      "code":"2105",
      "display":"บ้านค่าย"
      },
      {
      "districts":[
      {
      "code":"210203",
      "display":"บ้านฉาง"
      },
      {
      "code":"210199",
      "display":"พลา"
      },
      {
      "code":"210198",
      "display":"สำนักท้อน"
      }
      ],
      "code":"2102",
      "display":"บ้านฉาง"
      },
      {
      "districts":[
      {
      "code":"210598",
      "display":"ตาสิทธิ์"
      },
      {
      "code":"210599",
      "display":"ปลวกแดง"
      },
      {
      "code":"210595",
      "display":"มาบยางพร"
      },
      {
      "code":"210597",
      "display":"ละหาร"
      },
      {
      "code":"210594",
      "display":"หนองไร่"
      },
      {
      "code":"210596",
      "display":"แม่น้ำคู้"
      }
      ],
      "code":"2106",
      "display":"ปลวกแดง"
      },
      {
      "districts":[
      {
      "code":"210398",
      "display":"ชุมแสง"
      },
      {
      "code":"210403",
      "display":"ป่ายุบใน"
      },
      {
      "code":"210404",
      "display":"พลงตาเอี่ยม"
      },
      {
      "code":"210399",
      "display":"วังจันทร์"
      }
      ],
      "code":"2104",
      "display":"วังจันทร์"
      },
      {
      "districts":[
      {
      "code":"210315",
      "display":"ชำฆ้อ"
      },
      {
      "code":"210314",
      "display":"น้ำเป็น"
      },
      {
      "code":"210316",
      "display":"ห้วยทับมอญ"
      },
      {
      "code":"210319",
      "display":"เขาน้อย"
      }
      ],
      "code":"2107",
      "display":"เขาชะเมา"
      },
      {
      "districts":[
      {
      "code":"210110",
      "display":"กะเฉด"
      },
      {
      "code":"210103",
      "display":"ตะพง"
      },
      {
      "code":"210111",
      "display":"ทับมา"
      },
      {
      "code":"210101",
      "display":"ท่าประดู่"
      },
      {
      "code":"210108",
      "display":"นาตาขวัญ"
      },
      {
      "code":"210112",
      "display":"น้ำคอก"
      },
      {
      "code":"210107",
      "display":"บ้านแลง"
      },
      {
      "code":"210104",
      "display":"ปากน้ำ"
      },
      {
      "code":"210114",
      "display":"มาบตาพุด"
      },
      {
      "code":"210115",
      "display":"สำนักทอง"
      },
      {
      "code":"210113",
      "display":"ห้วยโป่ง"
      },
      {
      "code":"210102",
      "display":"เชิงเนิน"
      },
      {
      "code":"210109",
      "display":"เนินพระ"
      },
      {
      "code":"210109",
      "display":"เนินพระ"
      },
      {
      "code":"210105",
      "display":"เพ"
      },
      {
      "code":"210106",
      "display":"แกลง"
      }
      ],
      "code":"2101",
      "display":"เมืองระยอง"
      },
      {
      "districts":[
      {
      "code":"210307",
      "display":"กระแสบน"
      },
      {
      "code":"210305",
      "display":"กร่ำ"
      },
      {
      "code":"210310",
      "display":"กองดิน"
      },
      {
      "code":"210311",
      "display":"คลองปูน"
      },
      {
      "code":"210306",
      "display":"ชากพง"
      },
      {
      "code":"210303",
      "display":"ชากโดน"
      },
      {
      "code":"210301",
      "display":"ทางเกวียน"
      },
      {
      "code":"210309",
      "display":"ทุ่งควายกิน"
      },
      {
      "code":"210308",
      "display":"บ้านนา"
      },
      {
      "code":"210313",
      "display":"ปากน้ำกระแส"
      },
      {
      "code":"210312",
      "display":"พังราด"
      },
      {
      "code":"210302",
      "display":"วังหว้า"
      },
      {
      "code":"210318",
      "display":"สองสลึง"
      },
      {
      "code":"210317",
      "display":"ห้วยยาง"
      },
      {
      "code":"210304",
      "display":"เนินฆ้อ"
      }
      ],
      "code":"2103",
      "display":"แกลง"
      }
      ],
      "display":"ระยอง"
      },
      "ราชบุรี":{
      "sort_order":"44",
      "code":"70",
      "amphoe":[
      {
      "districts":[
      {
      "code":"700201",
      "display":"จอมบึง"
      },
      {
      "code":"700204",
      "display":"ด่านทับตะโก"
      },
      {
      "code":"700202",
      "display":"ปากช่อง"
      },
      {
      "code":"700206",
      "display":"รางบัว"
      },
      {
      "code":"700203",
      "display":"เบิกไพร"
      },
      {
      "code":"700205",
      "display":"แก้มอ้น"
      }
      ],
      "code":"7002",
      "display":"จอมบึง"
      },
      {
      "districts":[
      {
      "code":"700412",
      "display":"ขุนพิทักษ์"
      },
      {
      "code":"700405",
      "display":"ดอนกรวย"
      },
      {
      "code":"700406",
      "display":"ดอนคลัง"
      },
      {
      "code":"700413",
      "display":"ดอนไผ่"
      },
      {
      "code":"700401",
      "display":"ดำเนินสะดวก"
      },
      {
      "code":"700404",
      "display":"ตาหลวง"
      },
      {
      "code":"700411",
      "display":"ท่านัด"
      },
      {
      "code":"700407",
      "display":"บัวงาม"
      },
      {
      "code":"700122",
      "display":"บ้านไร่"
      },
      {
      "code":"700402",
      "display":"ประสาทสิทธิ์"
      },
      {
      "code":"700403",
      "display":"ศรีสุราษฎร์"
      },
      {
      "code":"700410",
      "display":"สี่หมื่น"
      },
      {
      "code":"700409",
      "display":"แพงพวย"
      }
      ],
      "code":"7004",
      "display":"ดำเนินสะดวก"
      },
      {
      "districts":[
      {
      "code":"700606",
      "display":"ดอนคา"
      },
      {
      "code":"700605",
      "display":"ดอนใหญ่"
      },
      {
      "code":"700601",
      "display":"บางแพ"
      },
      {
      "code":"700602",
      "display":"วังเย็น"
      },
      {
      "code":"700604",
      "display":"วัดแก้ว"
      },
      {
      "code":"700603",
      "display":"หัวโพ"
      },
      {
      "code":"700607",
      "display":"โพหัก"
      }
      ],
      "code":"7006",
      "display":"บางแพ"
      },
      {
      "districts":[
      {
      "code":"700305",
      "display":"บ้านคา"
      },
      {
      "code":"700303",
      "display":"บ้านบึง"
      },
      {
      "code":"700306",
      "display":"หนองพันจันทร์"
      }
      ],
      "code":"7010",
      "display":"บ้านคา"
      },
      {
      "districts":[
      {
      "code":"700503",
      "display":"กรับใหญ่"
      },
      {
      "code":"700511",
      "display":"คุ้งพยอม"
      },
      {
      "code":"700507",
      "display":"ดอนกระเบื้อง"
      },
      {
      "code":"700502",
      "display":"ท่าผา"
      },
      {
      "code":"700509",
      "display":"นครชุมน์"
      },
      {
      "code":"700510",
      "display":"บ้านม่วง"
      },
      {
      "code":"700501",
      "display":"บ้านโป่ง"
      },
      {
      "code":"700504",
      "display":"ปากแรต"
      },
      {
      "code":"700515",
      "display":"ลาดบัวขาว"
      },
      {
      "code":"700508",
      "display":"สวนกล้วย"
      },
      {
      "code":"700505",
      "display":"หนองกบ"
      },
      {
      "code":"700512",
      "display":"หนองปลาหมอ"
      },
      {
      "code":"700506",
      "display":"หนองอ้อ"
      },
      {
      "code":"700513",
      "display":"เขาขลุง"
      },
      {
      "code":"700203",
      "display":"เบิกไพร"
      }
      ],
      "code":"7005",
      "display":"บ้านโป่ง"
      },
      {
      "districts":[
      {
      "code":"700708",
      "display":"ดอนทราย"
      },
      {
      "code":"700801",
      "display":"ทุ่งหลวง"
      },
      {
      "code":"700809",
      "display":"บ่อกระดาน"
      },
      {
      "code":"700805",
      "display":"ปากท่อ"
      },
      {
      "code":"700806",
      "display":"ป่าไก่"
      },
      {
      "code":"700810",
      "display":"ยางหัก"
      },
      {
      "code":"700802",
      "display":"วังมะนาว"
      },
      {
      "code":"700807",
      "display":"วัดยางงาม"
      },
      {
      "code":"700811",
      "display":"วันดาว"
      },
      {
      "code":"700804",
      "display":"หนองกระทุ่ม"
      },
      {
      "code":"700812",
      "display":"ห้วยยางโทน"
      },
      {
      "code":"700808",
      "display":"อ่างหิน"
      }
      ],
      "code":"7008",
      "display":"ปากท่อ"
      },
      {
      "districts":[
      {
      "code":"700902",
      "display":"จอมประทัด"
      },
      {
      "code":"700903",
      "display":"วัดเพลง"
      },
      {
      "code":"700901",
      "display":"เกาะศาลพระ"
      }
      ],
      "code":"7009",
      "display":"วัดเพลง"
      },
      {
      "districts":[
      {
      "code":"700307",
      "display":"ตะนาวศรี"
      },
      {
      "code":"700304",
      "display":"ท่าเคย"
      },
      {
      "code":"700297",
      "display":"ป่าหวาย"
      },
      {
      "code":"700299",
      "display":"สวนผึ้ง"
      }
      ],
      "code":"7003",
      "display":"สวนผึ้ง"
      },
      {
      "districts":[
      {
      "code":"700107",
      "display":"คุ้งกระถิน"
      },
      {
      "code":"700106",
      "display":"คุ้งน้ำวน"
      },
      {
      "code":"700120",
      "display":"คูบัว"
      },
      {
      "code":"700103",
      "display":"ดอนตะโก"
      },
      {
      "code":"700113",
      "display":"ดอนแร่"
      },
      {
      "code":"700121",
      "display":"ท่าราบ"
      },
      {
      "code":"700112",
      "display":"น้ำพุ"
      },
      {
      "code":"700118",
      "display":"บางป่า"
      },
      {
      "code":"700122",
      "display":"บ้านไร่"
      },
      {
      "code":"700119",
      "display":"พงสวาย"
      },
      {
      "code":"700111",
      "display":"พิกุลทอง"
      },
      {
      "code":"700110",
      "display":"สามเรือน"
      },
      {
      "code":"700104",
      "display":"หนองกลางนา"
      },
      {
      "code":"700101",
      "display":"หน้าเมือง"
      },
      {
      "code":"700117",
      "display":"หลุมดิน"
      },
      {
      "code":"700114",
      "display":"หินกอง"
      },
      {
      "code":"700105",
      "display":"ห้วยไผ่"
      },
      {
      "code":"700108",
      "display":"อ่างทอง"
      },
      {
      "code":"700116",
      "display":"เกาะพลับพลา"
      },
      {
      "code":"700115",
      "display":"เขาแร้ง"
      },
      {
      "code":"700102",
      "display":"เจดีย์หัก"
      },
      {
      "code":"700109",
      "display":"โคกหม้อ"
      }
      ],
      "code":"7001",
      "display":"เมืองราชบุรี"
      },
      {
      "districts":[
      {
      "code":"700710",
      "display":"คลองข่อย"
      },
      {
      "code":"700705",
      "display":"คลองตาคต"
      },
      {
      "code":"700711",
      "display":"ชำแระ"
      },
      {
      "code":"700507",
      "display":"ดอนกระเบื้อง"
      },
      {
      "code":"700708",
      "display":"ดอนทราย"
      },
      {
      "code":"700713",
      "display":"ท่าชุมพล"
      },
      {
      "code":"700717",
      "display":"ธรรมเสน"
      },
      {
      "code":"700716",
      "display":"นางแก้ว"
      },
      {
      "code":"700714",
      "display":"บางโตนด"
      },
      {
      "code":"700706",
      "display":"บ้านฆ้อง"
      },
      {
      "code":"700707",
      "display":"บ้านสิงห์"
      },
      {
      "code":"700704",
      "display":"บ้านเลือก"
      },
      {
      "code":"700712",
      "display":"สร้อยฟ้า"
      },
      {
      "code":"700719",
      "display":"หนองกวาง"
      },
      {
      "code":"700703",
      "display":"หนองโพ"
      },
      {
      "code":"700718",
      "display":"เขาชะงุ้ม"
      },
      {
      "code":"700709",
      "display":"เจ็ดเสมียน"
      },
      {
      "code":"700715",
      "display":"เตาปูน"
      },
      {
      "code":"700701",
      "display":"โพธาราม"
      }
      ],
      "code":"7007",
      "display":"โพธาราม"
      }
      ],
      "display":"ราชบุรี"
      },
      "ร้อยเอ็ด":{
      "sort_order":"45",
      "code":"45",
      "amphoe":[
      {
      "districts":[
      {
      "code":"450407",
      "display":"ดงกลาง"
      },
      {
      "code":"450406",
      "display":"ดงแดง"
      },
      {
      "code":"450411",
      "display":"ดู่น้อย"
      },
      {
      "code":"450405",
      "display":"น้ำใส"
      },
      {
      "code":"450408",
      "display":"ป่าสังข์"
      },
      {
      "code":"450410",
      "display":"ลิ้นฟ้า"
      },
      {
      "code":"450412",
      "display":"ศรีโคตร"
      },
      {
      "code":"450402",
      "display":"หนองผือ"
      },
      {
      "code":"450401",
      "display":"หัวช้าง"
      },
      {
      "code":"450409",
      "display":"อีง่อง"
      },
      {
      "code":"450403",
      "display":"เมืองหงส์"
      },
      {
      "code":"450404",
      "display":"โคกล่าม"
      }
      ],
      "code":"FALSE",
      "display":"จตุรพักตรพิมาน"
      },
      {
      "districts":[
      {
      "code":"450115",
      "display":"จังหาร"
      },
      {
      "code":"450111",
      "display":"ดงสิงห์"
      },
      {
      "code":"450116",
      "display":"ดินดำ"
      },
      {
      "code":"450107",
      "display":"ปาฝา"
      },
      {
      "code":"451707",
      "display":"ผักแว่น"
      },
      {
      "code":"450113",
      "display":"ม่วงลาด"
      },
      {
      "code":"451706",
      "display":"ยางใหญ่"
      },
      {
      "code":"451708",
      "display":"แสนชาติ"
      }
      ],
      "code":"4517",
      "display":"จังหาร"
      },
      {
      "districts":[
      {
      "code":"450523",
      "display":"ทุ่งเขาหลวง"
      },
      {
      "code":"450512",
      "display":"บึงงาม"
      },
      {
      "code":"450509",
      "display":"มะบ้า"
      },
      {
      "code":"450516",
      "display":"เทอดไทย"
      },
      {
      "code":"450508",
      "display":"เหล่า"
      }
      ],
      "code":"4520",
      "display":"ทุ่งเขาหลวง"
      },
      {
      "districts":[
      {
      "code":"450502",
      "display":"ธงธานี"
      },
      {
      "code":"450504",
      "display":"ธวัชบุรี"
      },
      {
      "code":"450501",
      "display":"นิเวศน์"
      },
      {
      "code":"450520",
      "display":"บึงนคร"
      },
      {
      "code":"450507",
      "display":"มะอึ"
      },
      {
      "code":"450522",
      "display":"ราชธานี"
      },
      {
      "code":"450524",
      "display":"หนองพอก"
      },
      {
      "code":"450503",
      "display":"หนองไผ่"
      },
      {
      "code":"450506",
      "display":"อุ่มเม้า"
      },
      {
      "code":"450510",
      "display":"เขวาทุ่ง"
      },
      {
      "code":"450517",
      "display":"เมืองน้อย"
      },
      {
      "code":"450515",
      "display":"ไพศาล"
      }
      ],
      "code":"4505",
      "display":"ธวัชบุรี"
      },
      {
      "districts":[
      {
      "code":"450308",
      "display":"ขี้เหล็ก"
      },
      {
      "code":"450302",
      "display":"ดอกล้ำ"
      },
      {
      "code":"450301",
      "display":"บัวแดง"
      },
      {
      "code":"450306",
      "display":"สระบัว"
      },
      {
      "code":"450303",
      "display":"หนองแคน"
      },
      {
      "code":"450307",
      "display":"โนนสง่า"
      },
      {
      "code":"450305",
      "display":"โนนสวรรค์"
      },
      {
      "code":"450304",
      "display":"โพนสูง"
      }
      ],
      "code":"4503",
      "display":"ปทุมรัตต์"
      },
      {
      "districts":[
      {
      "code":"450603",
      "display":"กุดน้ำใส"
      },
      {
      "code":"450613",
      "display":"คำไฮ"
      },
      {
      "code":"450615",
      "display":"ค้อใหญ่"
      },
      {
      "code":"450617",
      "display":"ชานุวรรณ"
      },
      {
      "code":"450612",
      "display":"นานวล"
      },
      {
      "code":"450601",
      "display":"พนมไพร"
      },
      {
      "code":"450606",
      "display":"วารีสวัสดิ์"
      },
      {
      "code":"450614",
      "display":"สระแก้ว"
      },
      {
      "code":"450604",
      "display":"หนองทัพไทย"
      },
      {
      "code":"450602",
      "display":"แสนสุข"
      },
      {
      "code":"450607",
      "display":"โคกสว่าง"
      },
      {
      "code":"450611",
      "display":"โพธิ์ชัย"
      },
      {
      "code":"450605",
      "display":"โพธิ์ใหญ่"
      }
      ],
      "code":"4506",
      "display":"พนมไพร"
      },
      {
      "districts":[
      {
      "code":"451608",
      "display":"บ้านบาก"
      },
      {
      "code":"450119",
      "display":"ศรีสมเด็จ"
      },
      {
      "code":"450112",
      "display":"สวนจิก"
      },
      {
      "code":"451607",
      "display":"หนองแวงควง"
      },
      {
      "code":"450121",
      "display":"หนองใหญ่"
      },
      {
      "code":"450122",
      "display":"เมืองเปลือย"
      },
      {
      "code":"450114",
      "display":"โพธิ์ทอง"
      },
      {
      "code":"451606",
      "display":"โพธิ์สัย"
      }
      ],
      "code":"4516",
      "display":"ศรีสมเด็จ"
      },
      {
      "districts":[
      {
      "code":"451115",
      "display":"จำปาขัน"
      },
      {
      "code":"451112",
      "display":"ช้างเผือก"
      },
      {
      "code":"451102",
      "display":"ดอกไม้"
      },
      {
      "code":"451113",
      "display":"ทุ่งกุลา"
      },
      {
      "code":"451114",
      "display":"ทุ่งศรีเมือง"
      },
      {
      "code":"451108",
      "display":"ทุ่งหลวง"
      },
      {
      "code":"451103",
      "display":"นาใหญ่"
      },
      {
      "code":"451110",
      "display":"น้ำคำ"
      },
      {
      "code":"451107",
      "display":"บ่อพันขัน"
      },
      {
      "code":"451101",
      "display":"สระคู"
      },
      {
      "code":"450401",
      "display":"หัวช้าง"
      },
      {
      "code":"451106",
      "display":"หัวโทน"
      },
      {
      "code":"451104",
      "display":"หินกอง"
      },
      {
      "code":"451111",
      "display":"ห้วยหินลาด"
      },
      {
      "code":"451105",
      "display":"เมืองทุ่ง"
      }
      ],
      "code":"4511",
      "display":"สุวรรณภูมิ"
      },
      {
      "districts":[
      {
      "code":"450904",
      "display":"กกโพธิ์"
      },
      {
      "code":"450909",
      "display":"ท่าสีดา"
      },
      {
      "code":"450512",
      "display":"บึงงาม"
      },
      {
      "code":"450908",
      "display":"ผาน้ำย้อย"
      },
      {
      "code":"450903",
      "display":"ภูเขาทอง"
      },
      {
      "code":"450102",
      "display":"รอบเมือง"
      },
      {
      "code":"450906",
      "display":"หนองขุ่นใหญ่"
      },
      {
      "code":"450524",
      "display":"หนองพอก"
      },
      {
      "code":"450607",
      "display":"โคกสว่าง"
      }
      ],
      "code":"4509",
      "display":"หนองพอก"
      },
      {
      "districts":[
      {
      "code":"450610",
      "display":"ดูกอึ่ง"
      },
      {
      "code":"450616",
      "display":"สาวแห"
      },
      {
      "code":"450608",
      "display":"หนองฮี"
      },
      {
      "code":"450609",
      "display":"เด่นราษฎร์"
      }
      ],
      "code":"4519",
      "display":"หนองฮี"
      },
      {
      "districts":[
      {
      "code":"450308",
      "display":"ขี้เหล็ก"
      },
      {
      "code":"451410",
      "display":"บ้านดู่"
      },
      {
      "code":"451403",
      "display":"บ้านแจ้ง"
      },
      {
      "code":"451406",
      "display":"หนองขาม"
      },
      {
      "code":"451408",
      "display":"หนองบัว"
      },
      {
      "code":"451405",
      "display":"หนองหมื่นถ่าน"
      },
      {
      "code":"451404",
      "display":"หน่อม"
      },
      {
      "code":"451401",
      "display":"อาจสามารถ"
      },
      {
      "code":"451402",
      "display":"โพนเมือง"
      },
      {
      "code":"451407",
      "display":"โหรา"
      }
      ],
      "code":"4514",
      "display":"อาจสามารถ"
      },
      {
      "districts":[
      {
      "code":"450208",
      "display":"กำแพง"
      },
      {
      "code":"450209",
      "display":"กู่กาสิงห์"
      },
      {
      "code":"450213",
      "display":"ดงครั่งน้อย"
      },
      {
      "code":"450205",
      "display":"ดงครั่งใหญ่"
      },
      {
      "code":"450212",
      "display":"ทุ่งทอง"
      },
      {
      "code":"450210",
      "display":"น้ำอ้อม"
      },
      {
      "code":"450206",
      "display":"บ้านฝาง"
      },
      {
      "code":"450204",
      "display":"สิงห์โคก"
      },
      {
      "code":"450118",
      "display":"หนองแวง"
      },
      {
      "code":"450201",
      "display":"เกษตรวิสัย"
      },
      {
      "code":"450202",
      "display":"เมืองบัว"
      },
      {
      "code":"450203",
      "display":"เหล่าหลวง"
      },
      {
      "code":"450211",
      "display":"โนนสว่าง"
      }
      ],
      "code":"4502",
      "display":"เกษตรวิสัย"
      },
      {
      "districts":[
      {
      "code":"450513",
      "display":"บ้านเขือง"
      },
      {
      "code":"450511",
      "display":"พระธาตุ"
      },
      {
      "code":"450514",
      "display":"พระเจ้า"
      },
      {
      "code":"450521",
      "display":"พลับพลา"
      },
      {
      "code":"450505",
      "display":"หมูม้น"
      },
      {
      "code":"450519",
      "display":"เชียงขวัญ"
      }
      ],
      "code":"4518",
      "display":"เชียงขวัญ"
      },
      {
      "districts":[
      {
      "code":"451504",
      "display":"ชมสะอาด"
      },
      {
      "code":"450794",
      "display":"ชุมพร"
      },
      {
      "code":"451503",
      "display":"บุ่งเลิศ"
      },
      {
      "code":"450795",
      "display":"เมยวดี"
      }
      ],
      "code":"4515",
      "display":"เมยวดี"
      },
      {
      "districts":[
      {
      "code":"450104",
      "display":"ขอนแก่น"
      },
      {
      "code":"450120",
      "display":"ดงลาน"
      },
      {
      "code":"450105",
      "display":"นาโพธิ์"
      },
      {
      "code":"FALSE",
      "display":"ปอภาร"
      },
      {
      "code":"450102",
      "display":"รอบเมือง"
      },
      {
      "code":"450106",
      "display":"สะอาดสมบูรณ์"
      },
      {
      "code":"450108",
      "display":"สีแก้ว"
      },
      {
      "code":"450117",
      "display":"หนองแก้ว"
      },
      {
      "code":"450118",
      "display":"หนองแวง"
      },
      {
      "code":"450125",
      "display":"เมืองทอง"
      },
      {
      "code":"450103",
      "display":"เหนือเมือง"
      },
      {
      "code":"450123",
      "display":"แคนใหญ่"
      },
      {
      "code":"450124",
      "display":"โนนตาล"
      },
      {
      "code":"450110",
      "display":"โนนรัง"
      },
      {
      "code":"450101",
      "display":"ในเมือง"
      }
      ],
      "code":"4501",
      "display":"เมืองร้อยเอ็ด"
      },
      {
      "districts":[
      {
      "code":"451204",
      "display":"กกกุง"
      },
      {
      "code":"451203",
      "display":"คูเมือง"
      },
      {
      "code":"450402",
      "display":"หนองผือ"
      },
      {
      "code":"451202",
      "display":"หนองหิน"
      },
      {
      "code":"451205",
      "display":"เมืองสรวง"
      }
      ],
      "code":"4512",
      "display":"เมืองสรวง"
      },
      {
      "districts":[
      {
      "code":"451001",
      "display":"กลาง"
      },
      {
      "code":"451017",
      "display":"ขวัญเมือง"
      },
      {
      "code":"451008",
      "display":"ขวาว"
      },
      {
      "code":"451007",
      "display":"ท่าม่วง"
      },
      {
      "code":"451002",
      "display":"นางาม"
      },
      {
      "code":"451005",
      "display":"นาเมือง"
      },
      {
      "code":"451012",
      "display":"นาเลิง"
      },
      {
      "code":"451004",
      "display":"นาแซง"
      },
      {
      "code":"451018",
      "display":"บึงเกลือ"
      },
      {
      "code":"451016",
      "display":"พรสวรรค์"
      },
      {
      "code":"451010",
      "display":"ภูเงิน"
      },
      {
      "code":"451006",
      "display":"วังหลวง"
      },
      {
      "code":"451014",
      "display":"ศรีวิลัย"
      },
      {
      "code":"451015",
      "display":"หนองหลวง"
      },
      {
      "code":"451011",
      "display":"เกาะแก้ว"
      },
      {
      "code":"451003",
      "display":"เมืองไพร"
      },
      {
      "code":"451013",
      "display":"เหล่าน้อย"
      },
      {
      "code":"450114",
      "display":"โพธิ์ทอง"
      }
      ],
      "code":"4510",
      "display":"เสลภูมิ"
      },
      {
      "districts":[
      {
      "code":"450801",
      "display":"ขามเปี้ย"
      },
      {
      "code":"450806",
      "display":"คำพอุง"
      },
      {
      "code":"450808",
      "display":"ดอนโอง"
      },
      {
      "code":"450803",
      "display":"บัวคำ"
      },
      {
      "code":"450805",
      "display":"สะอาด"
      },
      {
      "code":"450807",
      "display":"หนองตาไก้"
      },
      {
      "code":"450797",
      "display":"อัคคะคำ"
      },
      {
      "code":"450798",
      "display":"เชียงใหม่"
      },
      {
      "code":"450809",
      "display":"โพธิ์ศรี"
      }
      ],
      "code":"4508",
      "display":"โพธิ์ชัย"
      },
      {
      "districts":[
      {
      "code":"451305",
      "display":"ท่าหาดยาว"
      },
      {
      "code":"451304",
      "display":"ยางคำ"
      },
      {
      "code":"451303",
      "display":"ศรีสว่าง"
      },
      {
      "code":"451302",
      "display":"สามขา"
      },
      {
      "code":"451301",
      "display":"โพนทราย"
      }
      ],
      "code":"4513",
      "display":"โพนทราย"
      },
      {
      "districts":[
      {
      "code":"450710",
      "display":"คำนาดี"
      },
      {
      "code":"450703",
      "display":"นาอุดม"
      },
      {
      "code":"450711",
      "display":"พรมสวรรค์"
      },
      {
      "code":"450713",
      "display":"วังสามัคคี"
      },
      {
      "code":"450712",
      "display":"สระนกแก้ว"
      },
      {
      "code":"450704",
      "display":"สว่าง"
      },
      {
      "code":"450121",
      "display":"หนองใหญ่"
      },
      {
      "code":"450709",
      "display":"อุ่มเม่า"
      },
      {
      "code":"450701",
      "display":"แวง"
      },
      {
      "code":"450702",
      "display":"โคกกกม่วง"
      },
      {
      "code":"450714",
      "display":"โคกสูง"
      },
      {
      "code":"450707",
      "display":"โนนชัยศรี"
      },
      {
      "code":"450114",
      "display":"โพธิ์ทอง"
      },
      {
      "code":"450708",
      "display":"โพธิ์ศรีสว่าง"
      }
      ],
      "code":"4507",
      "display":"โพนทอง"
      }
      ],
      "display":"ร้อยเอ็ด"
      },
      "ลพบุรี":{
      "sort_order":"46",
      "code":"16",
      "amphoe":[
      {
      "districts":[
      {
      "code":"160402",
      "display":"ชัยนารายณ์"
      },
      {
      "code":"160418",
      "display":"ชัยบาดาล"
      },
      {
      "code":"160409",
      "display":"ซับตะเคียน"
      },
      {
      "code":"160407",
      "display":"ท่าดินดำ"
      },
      {
      "code":"160414",
      "display":"ท่ามะนาว"
      },
      {
      "code":"160410",
      "display":"นาโสม"
      },
      {
      "code":"160417",
      "display":"นิคมลำนารายณ์"
      },
      {
      "code":"160406",
      "display":"บัวชุม"
      },
      {
      "code":"160419",
      "display":"บ้านใหม่สามัคคี"
      },
      {
      "code":"160408",
      "display":"มะกอกหวาน"
      },
      {
      "code":"160405",
      "display":"ม่วงค่อม"
      },
      {
      "code":"160401",
      "display":"ลำนารายณ์"
      },
      {
      "code":"160403",
      "display":"ศิลาทิพย์"
      },
      {
      "code":"160411",
      "display":"หนองยายโต๊ะ"
      },
      {
      "code":"160404",
      "display":"ห้วยหิน"
      },
      {
      "code":"160412",
      "display":"เกาะรัง"
      },
      {
      "code":"160422",
      "display":"เขาแหลม"
      }
      ],
      "code":"1604",
      "display":"ชัยบาดาล"
      },
      {
      "districts":[
      {
      "code":"160501",
      "display":"ท่าวุ้ง"
      },
      {
      "code":"160502",
      "display":"บางคู้"
      },
      {
      "code":"160505",
      "display":"บางงา"
      },
      {
      "code":"160504",
      "display":"บางลี่"
      },
      {
      "code":"160510",
      "display":"บ้านเบิก"
      },
      {
      "code":"160511",
      "display":"มุจลินท์"
      },
      {
      "code":"160509",
      "display":"ลาดสาลี่"
      },
      {
      "code":"160508",
      "display":"หัวสำโรง"
      },
      {
      "code":"160507",
      "display":"เขาสมอคอน"
      },
      {
      "code":"160506",
      "display":"โคกสลุด"
      },
      {
      "code":"160503",
      "display":"โพตลาดแก้ว"
      }
      ],
      "code":"1605",
      "display":"ท่าวุ้ง"
      },
      {
      "districts":[
      {
      "code":"160497",
      "display":"ซับจำปา"
      },
      {
      "code":"160705",
      "display":"ทะเลวังวัด"
      },
      {
      "code":"160499",
      "display":"ท่าหลวง"
      },
      {
      "code":"160496",
      "display":"หนองผักแว่น"
      },
      {
      "code":"160706",
      "display":"หัวลำ"
      },
      {
      "code":"160498",
      "display":"แก่งผักกูด"
      }
      ],
      "code":"1607",
      "display":"ท่าหลวง"
      },
      {
      "districts":[
      {
      "code":"160615",
      "display":"ชอนม่วง"
      },
      {
      "code":"160604",
      "display":"ดงพลับ"
      },
      {
      "code":"160614",
      "display":"ดอนดึง"
      },
      {
      "code":"160610",
      "display":"บางกะพี้"
      },
      {
      "code":"160613",
      "display":"บางขาม"
      },
      {
      "code":"160608",
      "display":"บางพึ่ง"
      },
      {
      "code":"160603",
      "display":"บ้านกล้วย"
      },
      {
      "code":"160605",
      "display":"บ้านชี"
      },
      {
      "code":"160602",
      "display":"บ้านทราย"
      },
      {
      "code":"160619",
      "display":"บ้านหมี่"
      },
      {
      "code":"160606",
      "display":"พุคา"
      },
      {
      "code":"160618",
      "display":"มหาสอน"
      },
      {
      "code":"160622",
      "display":"สนามแจง"
      },
      {
      "code":"160617",
      "display":"สายห้วยแก้ว"
      },
      {
      "code":"160616",
      "display":"หนองกระเบียน"
      },
      {
      "code":"160609",
      "display":"หนองทรายขาว"
      },
      {
      "code":"160611",
      "display":"หนองเต่า"
      },
      {
      "code":"160621",
      "display":"หนองเมือง"
      },
      {
      "code":"160607",
      "display":"หินปัก"
      },
      {
      "code":"160620",
      "display":"เชียงงา"
      },
      {
      "code":"160612",
      "display":"โพนทอง"
      },
      {
      "code":"160601",
      "display":"ไผ่ใหญ่"
      }
      ],
      "code":"1606",
      "display":"บ้านหมี่"
      },
      {
      "districts":[
      {
      "code":"160206",
      "display":"ชอนน้อย"
      },
      {
      "code":"160202",
      "display":"ช่องสาริกา"
      },
      {
      "code":"160204",
      "display":"ดีลัง"
      },
      {
      "code":"160209",
      "display":"น้ำสุด"
      },
      {
      "code":"160201",
      "display":"พัฒนานิคม"
      },
      {
      "code":"160203",
      "display":"มะนาวหวาน"
      },
      {
      "code":"160207",
      "display":"หนองบัว"
      },
      {
      "code":"160208",
      "display":"ห้วยขุนราม"
      },
      {
      "code":"160205",
      "display":"โคกสลุง"
      }
      ],
      "code":"1602",
      "display":"พัฒนานิคม"
      },
      {
      "districts":[
      {
      "code":"160415",
      "display":"กุดตาเพชร"
      },
      {
      "code":"160420",
      "display":"ซับสมบูรณ์"
      },
      {
      "code":"160416",
      "display":"ลำสนธิ"
      },
      {
      "code":"160413",
      "display":"หนองรี"
      },
      {
      "code":"161006",
      "display":"เขาน้อย"
      },
      {
      "code":"160421",
      "display":"เขารวก"
      }
      ],
      "code":"1610",
      "display":"ลำสนธิ"
      },
      {
      "districts":[
      {
      "code":"160397",
      "display":"ทุ่งท่าช้าง"
      },
      {
      "code":"160805",
      "display":"นิยมชัย"
      },
      {
      "code":"160802",
      "display":"มหาโพธิ"
      },
      {
      "code":"160399",
      "display":"สระโบสถ์"
      },
      {
      "code":"160804",
      "display":"ห้วยใหญ่"
      }
      ],
      "code":"1608",
      "display":"สระโบสถ์"
      },
      {
      "districts":[
      {
      "code":"160315",
      "display":"ชอนสมบูรณ์"
      },
      {
      "code":"160311",
      "display":"ชอนสารเดช"
      },
      {
      "code":"160319",
      "display":"ดงดินแดง"
      },
      {
      "code":"160313",
      "display":"บ่อทอง"
      },
      {
      "code":"160314",
      "display":"ยางโทน"
      },
      {
      "code":"160312",
      "display":"หนองม่วง"
      }
      ],
      "code":"1611",
      "display":"หนองม่วง"
      },
      {
      "districts":[
      {
      "code":"160103",
      "display":"กกโก"
      },
      {
      "code":"160110",
      "display":"งิ้วราย"
      },
      {
      "code":"160111",
      "display":"ดอนโพธิ์"
      },
      {
      "code":"160112",
      "display":"ตะลุง"
      },
      {
      "code":"160125",
      "display":"ถนนใหญ่"
      },
      {
      "code":"160101",
      "display":"ทะเลชุบศร"
      },
      {
      "code":"160115",
      "display":"ท่าศาลา"
      },
      {
      "code":"160102",
      "display":"ท่าหิน"
      },
      {
      "code":"160114",
      "display":"ท่าแค"
      },
      {
      "code":"160119",
      "display":"ท้ายตลาด"
      },
      {
      "code":"160116",
      "display":"นิคมสร้างตนเอง"
      },
      {
      "code":"160117",
      "display":"บางขันหมาก"
      },
      {
      "code":"160118",
      "display":"บ้านข่อย"
      },
      {
      "code":"160120",
      "display":"ป่าตาล"
      },
      {
      "code":"160121",
      "display":"พรหมมาสตร์"
      },
      {
      "code":"160124",
      "display":"สี่คลอง"
      },
      {
      "code":"160105",
      "display":"เขาพระงาม"
      },
      {
      "code":"160106",
      "display":"เขาสามยอด"
      },
      {
      "code":"160104",
      "display":"โก่งธนู"
      },
      {
      "code":"160107",
      "display":"โคกกะเทียม"
      },
      {
      "code":"160109",
      "display":"โคกตูม"
      },
      {
      "code":"160108",
      "display":"โคกลำพาน"
      },
      {
      "code":"160123",
      "display":"โพธิ์ตรุ"
      },
      {
      "code":"160122",
      "display":"โพธิ์เก้าต้น"
      }
      ],
      "code":"1601",
      "display":"เมืองลพบุรี"
      },
      {
      "districts":[
      {
      "code":"160306",
      "display":"คลองเกตุ"
      },
      {
      "code":"160310",
      "display":"ดงมะรุม"
      },
      {
      "code":"160303",
      "display":"ถลุงเหล็ก"
      },
      {
      "code":"160318",
      "display":"วังขอนขว้าง"
      },
      {
      "code":"160320",
      "display":"วังจั่น"
      },
      {
      "code":"160309",
      "display":"วังเพลิง"
      },
      {
      "code":"160307",
      "display":"สะแกราบ"
      },
      {
      "code":"160322",
      "display":"หนองแขม"
      },
      {
      "code":"160304",
      "display":"หลุมข้าว"
      },
      {
      "code":"160305",
      "display":"ห้วยโป่ง"
      },
      {
      "code":"160302",
      "display":"เกาะแก้ว"
      },
      {
      "code":"160308",
      "display":"เพนียด"
      },
      {
      "code":"160301",
      "display":"โคกสำโรง"
      }
      ],
      "code":"1603",
      "display":"โคกสำโรง"
      },
      {
      "districts":[
      {
      "code":"160317",
      "display":"ยางราก"
      },
      {
      "code":"160323",
      "display":"วังทอง"
      },
      {
      "code":"160321",
      "display":"หนองมะค่า"
      },
      {
      "code":"160316",
      "display":"โคกเจริญ"
      },
      {
      "code":"160905",
      "display":"โคกแสมสาร"
      }
      ],
      "code":"1609",
      "display":"โคกเจริญ"
      }
      ],
      "display":"ลพบุรี"
      },
      "ลำปาง":{
      "sort_order":"47",
      "code":"52",
      "amphoe":[
      {
      "districts":[
      {
      "code":"520506",
      "display":"นาแก"
      },
      {
      "code":"520504",
      "display":"บ้านร้อง"
      },
      {
      "code":"520509",
      "display":"บ้านหวด"
      },
      {
      "code":"520507",
      "display":"บ้านอ้อน"
      },
      {
      "code":"520508",
      "display":"บ้านแหง"
      },
      {
      "code":"520503",
      "display":"บ้านโป่ง"
      },
      {
      "code":"520505",
      "display":"ปงเตา"
      },
      {
      "code":"520501",
      "display":"หลวงเหนือ"
      },
      {
      "code":"520502",
      "display":"หลวงใต้"
      },
      {
      "code":"520510",
      "display":"แม่ตีบ"
      }
      ],
      "code":"5205",
      "display":"งาว"
      },
      {
      "districts":[
      {
      "code":"520701",
      "display":"ทุ่งฮั้ว"
      },
      {
      "code":"520704",
      "display":"ร่องเคาะ"
      },
      {
      "code":"520706",
      "display":"วังซ้าย"
      },
      {
      "code":"520708",
      "display":"วังทรายคำ"
      },
      {
      "code":"520705",
      "display":"วังทอง"
      },
      {
      "code":"520702",
      "display":"วังเหนือ"
      },
      {
      "code":"520707",
      "display":"วังแก้ว"
      },
      {
      "code":"520703",
      "display":"วังใต้"
      }
      ],
      "code":"5207",
      "display":"วังเหนือ"
      },
      {
      "districts":[
      {
      "code":"521104",
      "display":"นายาง"
      },
      {
      "code":"521101",
      "display":"สบปราบ"
      },
      {
      "code":"521102",
      "display":"สมัย"
      },
      {
      "code":"521103",
      "display":"แม่กัวะ"
      }
      ],
      "code":"5211",
      "display":"สบปราบ"
      },
      {
      "districts":[
      {
      "code":"521204",
      "display":"ปงยางคก"
      },
      {
      "code":"521207",
      "display":"วอแก้ว"
      },
      {
      "code":"521202",
      "display":"หนองหล่ม"
      },
      {
      "code":"521201",
      "display":"ห้างฉัตร"
      },
      {
      "code":"521203",
      "display":"เมืองยาว"
      },
      {
      "code":"521205",
      "display":"เวียงตาล"
      },
      {
      "code":"521206",
      "display":"แม่สัน"
      }
      ],
      "code":"5212",
      "display":"ห้างฉัตร"
      },
      {
      "districts":[
      {
      "code":"520308",
      "display":"ท่าผา"
      },
      {
      "code":"520302",
      "display":"นาแก้ว"
      },
      {
      "code":"520307",
      "display":"นาแส่ง"
      },
      {
      "code":"520301",
      "display":"ลำปางหลวง"
      },
      {
      "code":"520304",
      "display":"วังพร้าว"
      },
      {
      "code":"520305",
      "display":"ศาลา"
      },
      {
      "code":"520306",
      "display":"เกาะคา"
      },
      {
      "code":"520309",
      "display":"ใหม่พัฒนา"
      },
      {
      "code":"520303",
      "display":"ไหล่หิน"
      }
      ],
      "code":"5203",
      "display":"เกาะคา"
      },
      {
      "districts":[
      {
      "code":"520806",
      "display":"นาโป่ง"
      },
      {
      "code":"520801",
      "display":"ล้อมแรด"
      },
      {
      "code":"520808",
      "display":"เถินบุรี"
      },
      {
      "code":"520805",
      "display":"เวียงมอก"
      },
      {
      "code":"520807",
      "display":"แม่ถอด"
      },
      {
      "code":"520803",
      "display":"แม่ปะ"
      },
      {
      "code":"520804",
      "display":"แม่มอก"
      },
      {
      "code":"520802",
      "display":"แม่วะ"
      }
      ],
      "code":"5208",
      "display":"เถิน"
      },
      {
      "districts":[
      {
      "code":"520697",
      "display":"ทุ่งกว๋าว"
      },
      {
      "code":"520698",
      "display":"บ้านขอ"
      },
      {
      "code":"521305",
      "display":"หัวเมือง"
      },
      {
      "code":"520699",
      "display":"เมืองปาน"
      },
      {
      "code":"520696",
      "display":"แจ้ซ้อน"
      }
      ],
      "code":"5213",
      "display":"เมืองปาน"
      },
      {
      "districts":[
      {
      "code":"520107",
      "display":"กล้วยแพะ"
      },
      {
      "code":"520106",
      "display":"ชมพู"
      },
      {
      "code":"520117",
      "display":"ต้นธงชัย"
      },
      {
      "code":"520112",
      "display":"ทุ่งฝาย"
      },
      {
      "code":"520118",
      "display":"นิคมพัฒนา"
      },
      {
      "code":"520119",
      "display":"บุญนาคพัฒนา"
      },
      {
      "code":"520116",
      "display":"บ่อแฮ้ว"
      },
      {
      "code":"520115",
      "display":"บ้านค่า"
      },
      {
      "code":"520114",
      "display":"บ้านเป้า"
      },
      {
      "code":"520110",
      "display":"บ้านเสด็จ"
      },
      {
      "code":"520113",
      "display":"บ้านเอื้อม"
      },
      {
      "code":"520109",
      "display":"บ้านแลง"
      },
      {
      "code":"520108",
      "display":"ปงแสนทอง"
      },
      {
      "code":"520105",
      "display":"พระบาท"
      },
      {
      "code":"520111",
      "display":"พิชัย"
      },
      {
      "code":"520104",
      "display":"สบตุ๋ย"
      },
      {
      "code":"520103",
      "display":"สวนดอก"
      },
      {
      "code":"520102",
      "display":"หัวเวียง"
      },
      {
      "code":"520101",
      "display":"เวียงเหนือ"
      }
      ],
      "code":"5201",
      "display":"เมืองลำปาง"
      },
      {
      "districts":[
      {
      "code":"520401",
      "display":"ทุ่งงาม"
      },
      {
      "code":"520404",
      "display":"เสริมกลาง"
      },
      {
      "code":"520402",
      "display":"เสริมขวา"
      },
      {
      "code":"520403",
      "display":"เสริมซ้าย"
      }
      ],
      "code":"5204",
      "display":"เสริมงาม"
      },
      {
      "districts":[
      {
      "code":"520606",
      "display":"ทุ่งผึ้ง"
      },
      {
      "code":"520602",
      "display":"บ้านสา"
      },
      {
      "code":"520603",
      "display":"ปงดอน"
      },
      {
      "code":"520607",
      "display":"วิเชตนคร"
      },
      {
      "code":"520605",
      "display":"เมืองมาย"
      },
      {
      "code":"520601",
      "display":"แจ้ห่ม"
      },
      {
      "code":"520604",
      "display":"แม่สุก"
      }
      ],
      "code":"5206",
      "display":"แจ้ห่ม"
      },
      {
      "districts":[
      {
      "code":"521007",
      "display":"ดอนไฟ"
      },
      {
      "code":"521002",
      "display":"นาครัว"
      },
      {
      "code":"521006",
      "display":"น้ำโจ้"
      },
      {
      "code":"521004",
      "display":"บ้านกิ่ว"
      },
      {
      "code":"521005",
      "display":"บ้านบอม"
      },
      {
      "code":"521003",
      "display":"ป่าตัน"
      },
      {
      "code":"521010",
      "display":"วังเงิน"
      },
      {
      "code":"521011",
      "display":"สันดอนแก้ว"
      },
      {
      "code":"521008",
      "display":"หัวเสือ"
      },
      {
      "code":"521001",
      "display":"แม่ทะ"
      }
      ],
      "code":"5210",
      "display":"แม่ทะ"
      },
      {
      "districts":[
      {
      "code":"520902",
      "display":"ผาปัง"
      },
      {
      "code":"520904",
      "display":"พระบาทวังตวง"
      },
      {
      "code":"520903",
      "display":"แม่ปุ"
      },
      {
      "code":"520901",
      "display":"แม่พริก"
      }
      ],
      "code":"5209",
      "display":"แม่พริก"
      },
      {
      "districts":[
      {
      "code":"520203",
      "display":"จางเหนือ"
      },
      {
      "code":"520198",
      "display":"นาสัก"
      },
      {
      "code":"520199",
      "display":"บ้านดง"
      },
      {
      "code":"520205",
      "display":"สบป้าด"
      },
      {
      "code":"520204",
      "display":"แม่เมาะ"
      }
      ],
      "code":"5202",
      "display":"แม่เมาะ"
      }
      ],
      "display":"ลำปาง"
      },
      "ลำพูน":{
      "sort_order":"48",
      "code":"51",
      "amphoe":[
      {
      "districts":[
      {
      "code":"510503",
      "display":"ตะเคียนปม"
      },
      {
      "code":"510499",
      "display":"ทุ่งหัวช้าง"
      },
      {
      "code":"510498",
      "display":"บ้านปวง"
      }
      ],
      "code":"5105",
      "display":"ทุ่งหัวช้าง"
      },
      {
      "districts":[
      {
      "code":"510114",
      "display":"บ้านธิ"
      },
      {
      "code":"510115",
      "display":"ห้วยยาบ"
      }
      ],
      "code":"5107",
      "display":"บ้านธิ"
      },
      {
      "districts":[
      {
      "code":"510301",
      "display":"บ้านโฮ่ง"
      },
      {
      "code":"510302",
      "display":"ป่าพลู"
      },
      {
      "code":"510304",
      "display":"ศรีเตี้ย"
      },
      {
      "code":"510305",
      "display":"หนองปลาสะวาย"
      },
      {
      "code":"510303",
      "display":"เหล่ายาว"
      }
      ],
      "code":"5103",
      "display":"บ้านโฮ่ง"
      },
      {
      "districts":[
      {
      "code":"510607",
      "display":"ท่าตุ้ม"
      },
      {
      "code":"510611",
      "display":"นครเจดีย์"
      },
      {
      "code":"510608",
      "display":"น้ำดิบ"
      },
      {
      "code":"510605",
      "display":"บ้านเรือน"
      },
      {
      "code":"510601",
      "display":"ปากบ่อง"
      },
      {
      "code":"510602",
      "display":"ป่าซาง"
      },
      {
      "code":"510606",
      "display":"มะกอก"
      },
      {
      "code":"510604",
      "display":"ม่วงน้อย"
      },
      {
      "code":"510603",
      "display":"แม่แรง"
      }
      ],
      "code":"5106",
      "display":"ป่าซาง"
      },
      {
      "districts":[
      {
      "code":"510405",
      "display":"ก้อ"
      },
      {
      "code":"510404",
      "display":"ดงดำ"
      },
      {
      "code":"510403",
      "display":"นาทราย"
      },
      {
      "code":"510408",
      "display":"ป่าไผ่"
      },
      {
      "code":"510401",
      "display":"ลี้"
      },
      {
      "code":"510409",
      "display":"ศรีวิชัย"
      },
      {
      "code":"510402",
      "display":"แม่ตืน"
      },
      {
      "code":"510406",
      "display":"แม่ลาน"
      }
      ],
      "code":"5104",
      "display":"ลี้"
      },
      {
      "districts":[
      {
      "code":"510107",
      "display":"ต้นธง"
      },
      {
      "code":"510112",
      "display":"บ้านกลาง"
      },
      {
      "code":"510108",
      "display":"บ้านแป้น"
      },
      {
      "code":"510105",
      "display":"ประตูป่า"
      },
      {
      "code":"510110",
      "display":"ป่าสัก"
      },
      {
      "code":"510113",
      "display":"มะเขือแจ้"
      },
      {
      "code":"510106",
      "display":"ริมปิง"
      },
      {
      "code":"510116",
      "display":"ศรีบัวบาน"
      },
      {
      "code":"510104",
      "display":"หนองช้างคืน"
      },
      {
      "code":"510117",
      "display":"หนองหนาม"
      },
      {
      "code":"510103",
      "display":"อุโมงค์"
      },
      {
      "code":"510111",
      "display":"เวียงยอง"
      },
      {
      "code":"510102",
      "display":"เหมืองง่า"
      },
      {
      "code":"510109",
      "display":"เหมืองจี้"
      },
      {
      "code":"510101",
      "display":"ในเมือง"
      }
      ],
      "code":"5101",
      "display":"เมืองลำพูน"
      },
      {
      "districts":[
      {
      "code":"510609",
      "display":"วังผาง"
      },
      {
      "code":"510612",
      "display":"หนองยวง"
      },
      {
      "code":"510610",
      "display":"หนองล่อง"
      }
      ],
      "code":"5108",
      "display":"เวียงหนองล่อง"
      },
      {
      "districts":[
      {
      "code":"510203",
      "display":"ทากาศ"
      },
      {
      "code":"510204",
      "display":"ทาขุมเงิน"
      },
      {
      "code":"510205",
      "display":"ทาทุ่งหลวง"
      },
      {
      "code":"510201",
      "display":"ทาปลาดุก"
      },
      {
      "code":"510202",
      "display":"ทาสบเส้า"
      },
      {
      "code":"510206",
      "display":"ทาแม่ลอบ"
      }
      ],
      "code":"5102",
      "display":"แม่ทา"
      }
      ],
      "display":"ลำพูน"
      },
      "ศรีสะเกษ":{
      "sort_order":"49",
      "code":"33",
      "amphoe":[
      {
      "districts":[
      {
      "code":"330414",
      "display":"กระแชง"
      },
      {
      "code":"330402",
      "display":"กุดเสลา"
      },
      {
      "code":"330420",
      "display":"ขนุน"
      },
      {
      "code":"330411",
      "display":"จานใหญ่"
      },
      {
      "code":"330413",
      "display":"ชำ"
      },
      {
      "code":"330409",
      "display":"ตระกาจ"
      },
      {
      "code":"330424",
      "display":"ทุ่งใหญ่"
      },
      {
      "code":"330406",
      "display":"น้ำอ้อม"
      },
      {
      "code":"330401",
      "display":"บึงมะลู"
      },
      {
      "code":"330425",
      "display":"ภูผาหมอก"
      },
      {
      "code":"330412",
      "display":"ภูเงิน"
      },
      {
      "code":"330408",
      "display":"รุง"
      },
      {
      "code":"330407",
      "display":"ละลาย"
      },
      {
      "code":"330421",
      "display":"สวนกล้วย"
      },
      {
      "code":"330405",
      "display":"สังเม็ก"
      },
      {
      "code":"330416",
      "display":"หนองหญ้าลาด"
      },
      {
      "code":"330403",
      "display":"เมือง"
      },
      {
      "code":"330423",
      "display":"เวียงเหนือ"
      },
      {
      "code":"330419",
      "display":"เสาธงชัย"
      },
      {
      "code":"330415",
      "display":"โนนสำราญ"
      }
      ],
      "code":"3304",
      "display":"กันทรลักษ์"
      },
      {
      "districts":[
      {
      "code":"330320",
      "display":"คำเนียม"
      },
      {
      "code":"330105",
      "display":"จาน"
      },
      {
      "code":"330301",
      "display":"ดูน"
      },
      {
      "code":"330313",
      "display":"ดู่"
      },
      {
      "code":"330307",
      "display":"ทาม"
      },
      {
      "code":"330311",
      "display":"บัวน้อย"
      },
      {
      "code":"330314",
      "display":"ผักแพว"
      },
      {
      "code":"330304",
      "display":"ยาง"
      },
      {
      "code":"330308",
      "display":"ละทาย"
      },
      {
      "code":"330312",
      "display":"หนองบัว"
      },
      {
      "code":"330303",
      "display":"หนองหัวช้าง"
      },
      {
      "code":"330121",
      "display":"หนองแก้ว"
      },
      {
      "code":"330305",
      "display":"หนองแวง"
      },
      {
      "code":"330310",
      "display":"อีปาด"
      },
      {
      "code":"330309",
      "display":"เมืองน้อย"
      },
      {
      "code":"330302",
      "display":"โนนสัง"
      }
      ],
      "code":"3303",
      "display":"กันทรารมย์"
      },
      {
      "districts":[
      {
      "code":"330524",
      "display":"กฤษณา"
      },
      {
      "code":"330501",
      "display":"กันทรารมย์"
      },
      {
      "code":"330502",
      "display":"จะกง"
      },
      {
      "code":"330504",
      "display":"ดองกำเม็ด"
      },
      {
      "code":"330513",
      "display":"ตะเคียน"
      },
      {
      "code":"330508",
      "display":"ตาอุด"
      },
      {
      "code":"330515",
      "display":"นิคมพัฒนา"
      },
      {
      "code":"330518",
      "display":"ปราสาท"
      },
      {
      "code":"330506",
      "display":"ปรือใหญ่"
      },
      {
      "code":"330525",
      "display":"ลมศักดิ์"
      },
      {
      "code":"330527",
      "display":"ศรีตระกูล"
      },
      {
      "code":"330528",
      "display":"ศรีสะอาด"
      },
      {
      "code":"330507",
      "display":"สะเดาใหญ่"
      },
      {
      "code":"330521",
      "display":"สำโรงตาเจ็น"
      },
      {
      "code":"330526",
      "display":"หนองฉลอง"
      },
      {
      "code":"330511",
      "display":"หัวเสือ"
      },
      {
      "code":"330522",
      "display":"ห้วยสำราญ"
      },
      {
      "code":"330509",
      "display":"ห้วยเหนือ"
      },
      {
      "code":"330510",
      "display":"ห้วยใต้"
      },
      {
      "code":"330517",
      "display":"โคกเพชร"
      },
      {
      "code":"330505",
      "display":"โสน"
      },
      {
      "code":"330503",
      "display":"ใจดี"
      }
      ],
      "code":"3305",
      "display":"ขุขันธ์"
      },
      {
      "districts":[
      {
      "code":"330806",
      "display":"กระหวัน"
      },
      {
      "code":"330809",
      "display":"กันทรอม"
      },
      {
      "code":"330807",
      "display":"ขุนหาญ"
      },
      {
      "code":"330802",
      "display":"บักดอง"
      },
      {
      "code":"330803",
      "display":"พราน"
      },
      {
      "code":"330810",
      "display":"ภูฝ้าย"
      },
      {
      "code":"330801",
      "display":"สิ"
      },
      {
      "code":"330812",
      "display":"ห้วยจันทร์"
      },
      {
      "code":"330808",
      "display":"โนนสูง"
      },
      {
      "code":"330811",
      "display":"โพธิ์กระสังข์"
      },
      {
      "code":"330804",
      "display":"โพธิ์วงศ์"
      },
      {
      "code":"330805",
      "display":"ไพร"
      }
      ],
      "code":"3308",
      "display":"ขุนหาญ"
      },
      {
      "districts":[
      {
      "code":"331506",
      "display":"คูบ"
      },
      {
      "code":"330394",
      "display":"ตองปิด"
      },
      {
      "code":"330318",
      "display":"น้ำเกลี้ยง"
      },
      {
      "code":"331505",
      "display":"รุ่งระวี"
      },
      {
      "code":"330317",
      "display":"ละเอาะ"
      },
      {
      "code":"330319",
      "display":"เขิน"
      }
      ],
      "code":"3315",
      "display":"น้ำเกลี้ยง"
      },
      {
      "districts":[
      {
      "code":"331102",
      "display":"บึงบูรพ์"
      },
      {
      "code":"331099",
      "display":"เป๊าะ"
      }
      ],
      "code":"3311",
      "display":"บึงบูรพ์"
      },
      {
      "districts":[
      {
      "code":"330702",
      "display":"กู่"
      },
      {
      "code":"330313",
      "display":"ดู่"
      },
      {
      "code":"330499",
      "display":"ตูม"
      },
      {
      "code":"330701",
      "display":"พิมาย"
      },
      {
      "code":"330710",
      "display":"พิมายเหนือ"
      },
      {
      "code":"330705",
      "display":"สมอ"
      },
      {
      "code":"330709",
      "display":"สวาย"
      },
      {
      "code":"330707",
      "display":"สำโรงปราสาท"
      },
      {
      "code":"330703",
      "display":"หนองเชียงทูน"
      },
      {
      "code":"330706",
      "display":"โพธิ์ศรี"
      }
      ],
      "code":"3307",
      "display":"ปรางค์กู่"
      },
      {
      "districts":[
      {
      "code":"330114",
      "display":"ตำแย"
      },
      {
      "code":"330110",
      "display":"พยุห์"
      },
      {
      "code":"330109",
      "display":"พรหมสวัสดิ์"
      },
      {
      "code":"330128",
      "display":"หนองค้า"
      },
      {
      "code":"330108",
      "display":"โนนเพ็ก"
      }
      ],
      "code":"3320",
      "display":"พยุห์"
      },
      {
      "districts":[
      {
      "code":"330523",
      "display":"ดงรัก"
      },
      {
      "code":"330519",
      "display":"ตะเคียนราม"
      },
      {
      "code":"330512",
      "display":"ละลม"
      },
      {
      "code":"330516",
      "display":"ห้วยตามอญ"
      },
      {
      "code":"331703",
      "display":"ห้วยตึ๊กชู"
      },
      {
      "code":"330514",
      "display":"โคกตาล"
      },
      {
      "code":"331707",
      "display":"ไพรพัฒนา"
      }
      ],
      "code":"3317",
      "display":"ภูสิงห์"
      },
      {
      "districts":[
      {
      "code":"330205",
      "display":"กุดเมืองฮาม"
      },
      {
      "code":"330199",
      "display":"คอนกาม"
      },
      {
      "code":"330206",
      "display":"บึงบอน"
      },
      {
      "code":"330201",
      "display":"ยางชุมน้อย"
      },
      {
      "code":"330207",
      "display":"ยางชุมใหญ่"
      },
      {
      "code":"330202",
      "display":"ลิ้นฟ้า"
      },
      {
      "code":"330204",
      "display":"โนนคูณ"
      }
      ],
      "code":"3302",
      "display":"ยางชุมน้อย"
      },
      {
      "districts":[
      {
      "code":"330906",
      "display":"จิกสังข์ทอง"
      },
      {
      "code":"330313",
      "display":"ดู่"
      },
      {
      "code":"330907",
      "display":"ด่าน"
      },
      {
      "code":"330910",
      "display":"บัวหุ่ง"
      },
      {
      "code":"330915",
      "display":"สร้างปี่"
      },
      {
      "code":"330912",
      "display":"ส้มป่อย"
      },
      {
      "code":"330913",
      "display":"หนองหมี"
      },
      {
      "code":"330909",
      "display":"หนองอึ่ง"
      },
      {
      "code":"330903",
      "display":"หนองแค"
      },
      {
      "code":"330914",
      "display":"หว้านคำ"
      },
      {
      "code":"330901",
      "display":"เมืองคง"
      },
      {
      "code":"330902",
      "display":"เมืองแคน"
      },
      {
      "code":"330911",
      "display":"ไผ่"
      }
      ],
      "code":"3309",
      "display":"ราษีไศล"
      },
      {
      "districts":[
      {
      "code":"330120",
      "display":"ดวนใหญ่"
      },
      {
      "code":"331606",
      "display":"ทุ่งสว่าง"
      },
      {
      "code":"330113",
      "display":"ธาตุ"
      },
      {
      "code":"330117",
      "display":"บุสูง"
      },
      {
      "code":"330125",
      "display":"บ่อแก้ว"
      },
      {
      "code":"331607",
      "display":"วังหิน"
      },
      {
      "code":"330126",
      "display":"ศรีสำราญ"
      },
      {
      "code":"331608",
      "display":"โพนยาง"
      }
      ],
      "code":"3316",
      "display":"วังหิน"
      },
      {
      "districts":[
      {
      "code":"330499",
      "display":"ตูม"
      },
      {
      "code":"330497",
      "display":"พิงพวย"
      },
      {
      "code":"330498",
      "display":"ศรีแก้ว"
      },
      {
      "code":"331406",
      "display":"ศรีโนนงาม"
      },
      {
      "code":"330496",
      "display":"สระเยาว์"
      },
      {
      "code":"331407",
      "display":"สะพุง"
      },
      {
      "code":"331405",
      "display":"เสื่องข้าว"
      }
      ],
      "code":"3314",
      "display":"ศรีรัตนะ"
      },
      {
      "districts":[
      {
      "code":"330904",
      "display":"กุง"
      },
      {
      "code":"330905",
      "display":"คลีกลิ้ง"
      },
      {
      "code":"330917",
      "display":"หนองบัวดง"
      },
      {
      "code":"330916",
      "display":"โจดม่วง"
      }
      ],
      "code":"3322",
      "display":"ศิลาลาด"
      },
      {
      "districts":[
      {
      "code":"331097",
      "display":"กล้วยกว้าง"
      },
      {
      "code":"331205",
      "display":"จานแสนไชย"
      },
      {
      "code":"330518",
      "display":"ปราสาท"
      },
      {
      "code":"331096",
      "display":"ผักไหม"
      },
      {
      "code":"331098",
      "display":"ห้วยทับทัน"
      },
      {
      "code":"331202",
      "display":"เมืองหลวง"
      }
      ],
      "code":"3312",
      "display":"ห้วยทับทัน"
      },
      {
      "districts":[
      {
      "code":"331001",
      "display":"กำแพง"
      },
      {
      "code":"331003",
      "display":"ก้านเหลือง"
      },
      {
      "code":"331008",
      "display":"ขะยูง"
      },
      {
      "code":"331010",
      "display":"ตาเกษ"
      },
      {
      "code":"331004",
      "display":"ทุ่งไชย"
      },
      {
      "code":"331017",
      "display":"ปะอาว"
      },
      {
      "code":"331012",
      "display":"รังแร้ง"
      },
      {
      "code":"331022",
      "display":"สระกำแพงใหญ่"
      },
      {
      "code":"331005",
      "display":"สำโรง"
      },
      {
      "code":"331018",
      "display":"หนองห้าง"
      },
      {
      "code":"330119",
      "display":"หนองไฮ"
      },
      {
      "code":"331011",
      "display":"หัวช้าง"
      },
      {
      "code":"331002",
      "display":"อี่หล่ำ"
      },
      {
      "code":"331006",
      "display":"แขม"
      },
      {
      "code":"331015",
      "display":"แข้"
      },
      {
      "code":"331014",
      "display":"แต้"
      },
      {
      "code":"331025",
      "display":"โคกจาน"
      },
      {
      "code":"331024",
      "display":"โคกหล่าม"
      },
      {
      "code":"331016",
      "display":"โพธิ์ชัย"
      }
      ],
      "code":"3310",
      "display":"อุทุมพรพิสัย"
      },
      {
      "districts":[
      {
      "code":"330418",
      "display":"ท่าคล้อ"
      },
      {
      "code":"330417",
      "display":"หนองงูเหลือม"
      },
      {
      "code":"330404",
      "display":"หนองหว้า"
      },
      {
      "code":"330422",
      "display":"หนองฮาง"
      },
      {
      "code":"330410",
      "display":"เสียว"
      }
      ],
      "code":"3319",
      "display":"เบญจลักษ์"
      },
      {
      "districts":[
      {
      "code":"331009",
      "display":"ตาโกน"
      },
      {
      "code":"331023",
      "display":"หนองใหญ่"
      },
      {
      "code":"331013",
      "display":"เมืองจันทร์"
      }
      ],
      "code":"3318",
      "display":"เมืองจันทร์"
      },
      {
      "districts":[
      {
      "code":"330103",
      "display":"คูซอด"
      },
      {
      "code":"330105",
      "display":"จาน"
      },
      {
      "code":"330104",
      "display":"ซำ"
      },
      {
      "code":"330106",
      "display":"ตะดอบ"
      },
      {
      "code":"330118",
      "display":"ทุ่ม"
      },
      {
      "code":"330122",
      "display":"น้ำคำ"
      },
      {
      "code":"330116",
      "display":"หญ้าปล้อง"
      },
      {
      "code":"330107",
      "display":"หนองครก"
      },
      {
      "code":"330121",
      "display":"หนองแก้ว"
      },
      {
      "code":"330127",
      "display":"หนองไผ่"
      },
      {
      "code":"330119",
      "display":"หนองไฮ"
      },
      {
      "code":"330124",
      "display":"หมากเขียบ"
      },
      {
      "code":"330101",
      "display":"เมืองเหนือ"
      },
      {
      "code":"330102",
      "display":"เมืองใต้"
      },
      {
      "code":"330123",
      "display":"โพธิ์"
      },
      {
      "code":"330111",
      "display":"โพนข่า"
      },
      {
      "code":"330112",
      "display":"โพนค้อ"
      },
      {
      "code":"330115",
      "display":"โพนเขวา"
      }
      ],
      "code":"3301",
      "display":"เมืองศรีสะเกษ"
      },
      {
      "districts":[
      {
      "code":"330398",
      "display":"บก"
      },
      {
      "code":"330396",
      "display":"หนองกุง"
      },
      {
      "code":"331305",
      "display":"เหล่ากวาง"
      },
      {
      "code":"330399",
      "display":"โนนค้อ"
      },
      {
      "code":"330123",
      "display":"โพธิ์"
      }
      ],
      "code":"3313",
      "display":"โนนคูณ"
      },
      {
      "districts":[
      {
      "code":"331026",
      "display":"ผือใหญ่"
      },
      {
      "code":"331021",
      "display":"หนองม้า"
      },
      {
      "code":"331027",
      "display":"อีเซ"
      },
      {
      "code":"330410",
      "display":"เสียว"
      },
      {
      "code":"331019",
      "display":"โดด"
      }
      ],
      "code":"3321",
      "display":"โพธิ์ศรีสุวรรณ"
      },
      {
      "districts":[
      {
      "code":"330602",
      "display":"ดินแดง"
      },
      {
      "code":"330603",
      "display":"ปราสาทเยอ"
      },
      {
      "code":"330604",
      "display":"สำโรงพลัน"
      },
      {
      "code":"330605",
      "display":"สุขสวัสดิ์"
      },
      {
      "code":"330606",
      "display":"โนนปูน"
      },
      {
      "code":"330601",
      "display":"ไพรบึง"
      }
      ],
      "code":"3306",
      "display":"ไพรบึง"
      }
      ],
      "display":"ศรีสะเกษ"
      },
      "สกลนคร":{
      "sort_order":"50",
      "code":"47",
      "amphoe":[
      {
      "districts":[
      {
      "code":"470301",
      "display":"กุดบาก"
      },
      {
      "code":"470305",
      "display":"กุดไห"
      },
      {
      "code":"470303",
      "display":"นาม่อง"
      }
      ],
      "code":"4703",
      "display":"กุดบาก"
      },
      {
      "districts":[
      {
      "code":"470201",
      "display":"กุสุมาลย์"
      },
      {
      "code":"470203",
      "display":"นาเพียง"
      },
      {
      "code":"470202",
      "display":"นาโพธิ์"
      },
      {
      "code":"470205",
      "display":"อุ่มจาน"
      },
      {
      "code":"FALSE",
      "display":"โพธิ์ไพศาล"
      }
      ],
      "code":"4702",
      "display":"กุสุมาลย์"
      },
      {
      "districts":[
      {
      "code":"470901",
      "display":"คำตากล้า"
      },
      {
      "code":"470903",
      "display":"นาแต้"
      },
      {
      "code":"470902",
      "display":"หนองบัวสิม"
      },
      {
      "code":"470904",
      "display":"แพด"
      }
      ],
      "code":"4709",
      "display":"คำตากล้า"
      },
      {
      "districts":[
      {
      "code":"470701",
      "display":"นิคมน้ำอูน"
      },
      {
      "code":"470704",
      "display":"สุวรรณคาม"
      },
      {
      "code":"470703",
      "display":"หนองบัว"
      },
      {
      "code":"470702",
      "display":"หนองปลิง"
      }
      ],
      "code":"4707",
      "display":"นิคมน้ำอูน"
      },
      {
      "districts":[
      {
      "code":"471003",
      "display":"ดงหม้อทอง"
      },
      {
      "code":"471005",
      "display":"ดงหม้อทองใต้"
      },
      {
      "code":"471004",
      "display":"ดงเหนือ"
      },
      {
      "code":"471009",
      "display":"บ่อแก้ว"
      },
      {
      "code":"471002",
      "display":"มาย"
      },
      {
      "code":"471001",
      "display":"ม่วง"
      },
      {
      "code":"471008",
      "display":"หนองกวั่ง"
      },
      {
      "code":"471006",
      "display":"ห้วยหลัว"
      },
      {
      "code":"471007",
      "display":"โนนสะอาด"
      }
      ],
      "code":"4710",
      "display":"บ้านม่วง"
      },
      {
      "districts":[
      {
      "code":"470406",
      "display":"ช้างมิ่ง"
      },
      {
      "code":"470404",
      "display":"นาหัวบ่อ"
      },
      {
      "code":"470407",
      "display":"นาใน"
      },
      {
      "code":"470409",
      "display":"บะฮี"
      },
      {
      "code":"470401",
      "display":"พรรณา"
      },
      {
      "code":"470403",
      "display":"พอกน้อย"
      },
      {
      "code":"470402",
      "display":"วังยาง"
      },
      {
      "code":"470408",
      "display":"สว่าง"
      },
      {
      "code":"470410",
      "display":"เชิงชุม"
      },
      {
      "code":"470405",
      "display":"ไร่"
      }
      ],
      "code":"4704",
      "display":"พรรณานิคม"
      },
      {
      "districts":[
      {
      "code":"470505",
      "display":"ต้นผึ้ง"
      },
      {
      "code":"470501",
      "display":"พังโคน"
      },
      {
      "code":"470502",
      "display":"ม่วงไข่"
      },
      {
      "code":"470503",
      "display":"แร่"
      },
      {
      "code":"470504",
      "display":"ไฮหย่อง"
      }
      ],
      "code":"4705",
      "display":"พังโคน"
      },
      {
      "districts":[
      {
      "code":"470110",
      "display":"กกปลาซิว"
      },
      {
      "code":"470304",
      "display":"สร้างค้อ"
      },
      {
      "code":"470306",
      "display":"หลุบเลา"
      },
      {
      "code":"470302",
      "display":"โคกภู"
      }
      ],
      "code":"4718",
      "display":"ภูพาน"
      },
      {
      "districts":[
      {
      "code":"470813",
      "display":"กุดเรือคำ"
      },
      {
      "code":"470803",
      "display":"ขัวก่าย"
      },
      {
      "code":"470812",
      "display":"คอนสวรรค์"
      },
      {
      "code":"470805",
      "display":"คูสะคาม"
      },
      {
      "code":"470806",
      "display":"ธาตุ"
      },
      {
      "code":"470811",
      "display":"นาคำ"
      },
      {
      "code":"470809",
      "display":"นาซอ"
      },
      {
      "code":"470801",
      "display":"วานรนิวาส"
      },
      {
      "code":"470808",
      "display":"ศรีวิชัย"
      },
      {
      "code":"470804",
      "display":"หนองสนม"
      },
      {
      "code":"470807",
      "display":"หนองแวง"
      },
      {
      "code":"470814",
      "display":"หนองแวงใต้"
      },
      {
      "code":"470810",
      "display":"อินทร์แปลง"
      },
      {
      "code":"470802",
      "display":"เดื่อศรีคันไชย"
      }
      ],
      "code":"4708",
      "display":"วานรนิวาส"
      },
      {
      "districts":[
      {
      "code":"470604",
      "display":"คำบ่อ"
      },
      {
      "code":"470605",
      "display":"ค้อเขียว"
      },
      {
      "code":"470602",
      "display":"ปลาโหล"
      },
      {
      "code":"470601",
      "display":"วาริชภูมิ"
      },
      {
      "code":"470118",
      "display":"หนองลาด"
      }
      ],
      "code":"4706",
      "display":"วาริชภูมิ"
      },
      {
      "districts":[
      {
      "code":"471203",
      "display":"คำสะอาด"
      },
      {
      "code":"471212",
      "display":"ค้อใต้"
      },
      {
      "code":"471217",
      "display":"ตาลเนิ้ง"
      },
      {
      "code":"471216",
      "display":"ตาลโกน"
      },
      {
      "code":"471215",
      "display":"ทรายมูล"
      },
      {
      "code":"471220",
      "display":"ธาตุทอง"
      },
      {
      "code":"471206",
      "display":"บงเหนือ"
      },
      {
      "code":"471211",
      "display":"บงใต้"
      },
      {
      "code":"471204",
      "display":"บ้านต้าย"
      },
      {
      "code":"471221",
      "display":"บ้านถ่อน"
      },
      {
      "code":"471213",
      "display":"พันนา"
      },
      {
      "code":"471201",
      "display":"สว่างแดนดิน"
      },
      {
      "code":"471210",
      "display":"หนองหลวง"
      },
      {
      "code":"471214",
      "display":"แวง"
      },
      {
      "code":"471208",
      "display":"โคกสี"
      },
      {
      "code":"471207",
      "display":"โพนสูง"
      }
      ],
      "code":"4712",
      "display":"สว่างแดนดิน"
      },
      {
      "districts":[
      {
      "code":"471302",
      "display":"ท่าศิลา"
      },
      {
      "code":"471304",
      "display":"ปทุมวาปี"
      },
      {
      "code":"471303",
      "display":"วัฒนา"
      },
      {
      "code":"471301",
      "display":"ส่องดาว"
      }
      ],
      "code":"4713",
      "display":"ส่องดาว"
      },
      {
      "districts":[
      {
      "code":"471105",
      "display":"ท่าก้อน"
      },
      {
      "code":"471106",
      "display":"นาฮี"
      },
      {
      "code":"471107",
      "display":"บะหว้า"
      },
      {
      "code":"471103",
      "display":"วาใหญ่"
      },
      {
      "code":"471108",
      "display":"สามัคคีพัฒนา"
      },
      {
      "code":"471101",
      "display":"อากาศ"
      },
      {
      "code":"471104",
      "display":"โพนงาม"
      },
      {
      "code":"471102",
      "display":"โพนแพง"
      }
      ],
      "code":"4711",
      "display":"อากาศอำนวย"
      },
      {
      "districts":[
      {
      "code":"471209",
      "display":"ทุ่งแก"
      },
      {
      "code":"471202",
      "display":"บ้านเหล่า"
      },
      {
      "code":"471219",
      "display":"หนองแปน"
      },
      {
      "code":"471205",
      "display":"เจริญศิลป์"
      },
      {
      "code":"471218",
      "display":"โคกศิลา"
      }
      ],
      "code":"4716",
      "display":"เจริญศิลป์"
      },
      {
      "districts":[
      {
      "code":"471404",
      "display":"จันทร์เพ็ญ"
      },
      {
      "code":"471403",
      "display":"นาตาล"
      },
      {
      "code":"471402",
      "display":"บึงทวาย"
      },
      {
      "code":"470199",
      "display":"เต่างอย"
      }
      ],
      "code":"4714",
      "display":"เต่างอย"
      },
      {
      "districts":[
      {
      "code":"470102",
      "display":"ขมิ้น"
      },
      {
      "code":"470103",
      "display":"งิ้วด่อน"
      },
      {
      "code":"470111",
      "display":"ดงชน"
      },
      {
      "code":"470115",
      "display":"ดงมะไฟ"
      },
      {
      "code":"470107",
      "display":"ท่าแร่"
      },
      {
      "code":"470116",
      "display":"ธาตุนาเวง"
      },
      {
      "code":"470101",
      "display":"ธาตุเชิงชุม"
      },
      {
      "code":"470113",
      "display":"พังขว้าง"
      },
      {
      "code":"470109",
      "display":"ม่วงลาย"
      },
      {
      "code":"470118",
      "display":"หนองลาด"
      },
      {
      "code":"470112",
      "display":"ห้วยยาง"
      },
      {
      "code":"470120",
      "display":"ฮางโฮง"
      },
      {
      "code":"470106",
      "display":"เชียงเครือ"
      },
      {
      "code":"470117",
      "display":"เหล่าปอแดง"
      },
      {
      "code":"470121",
      "display":"โคกก่อง"
      },
      {
      "code":"470104",
      "display":"โนนหอม"
      }
      ],
      "code":"4701",
      "display":"เมืองสกลนคร"
      },
      {
      "districts":[
      {
      "code":"471503",
      "display":"ด่านม่วงคำ"
      },
      {
      "code":"470198",
      "display":"ตองโขบ"
      },
      {
      "code":"470197",
      "display":"เหล่าโพนค้อ"
      },
      {
      "code":"471504",
      "display":"แมดนาท่ม"
      }
      ],
      "code":"4715",
      "display":"โคกศรีสุพรรณ"
      },
      {
      "districts":[
      {
      "code":"470105",
      "display":"นาตงวัฒนา"
      },
      {
      "code":"470114",
      "display":"นาแก้ว"
      },
      {
      "code":"470119",
      "display":"บ้านแป้น"
      },
      {
      "code":"470108",
      "display":"บ้านโพน"
      },
      {
      "code":"471705",
      "display":"เชียงสือ"
      }
      ],
      "code":"4717",
      "display":"โพนนาแก้ว"
      }
      ],
      "display":"สกลนคร"
      },
      "สงขลา":{
      "sort_order":"51",
      "code":"90",
      "amphoe":[
      {
      "districts":[
      {
      "code":"900804",
      "display":"กระแสสินธุ์"
      },
      {
      "code":"900799",
      "display":"เกาะใหญ่"
      },
      {
      "code":"900797",
      "display":"เชิงแส"
      },
      {
      "code":"900798",
      "display":"โรง"
      }
      ],
      "code":"9008",
      "display":"กระแสสินธุ์"
      },
      {
      "districts":[
      {
      "code":"901604",
      "display":"คลองหลา"
      },
      {
      "code":"901106",
      "display":"คลองหอยโข่ง"
      },
      {
      "code":"901109",
      "display":"ทุ่งลาน"
      },
      {
      "code":"901121",
      "display":"โคกม่วง"
      },
      {
      "code":"901121",
      "display":"โคกม่วง"
      }
      ],
      "code":"9016",
      "display":"คลองหอยโข่ง"
      },
      {
      "districts":[
      {
      "code":"900905",
      "display":"ควนโส"
      },
      {
      "code":"900907",
      "display":"บางเหรียง"
      },
      {
      "code":"900906",
      "display":"รัตภูมิ"
      },
      {
      "code":"900908",
      "display":"ห้วยลึก"
      }
      ],
      "code":"9013",
      "display":"ควนเนียง"
      },
      {
      "districts":[
      {
      "code":"900308",
      "display":"ขุนตัดหวาย"
      },
      {
      "code":"900313",
      "display":"คลองเปียะ"
      },
      {
      "code":"900311",
      "display":"คู"
      },
      {
      "code":"900310",
      "display":"จะโหนง"
      },
      {
      "code":"900314",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"900309",
      "display":"ท่าหมอไทร"
      },
      {
      "code":"900306",
      "display":"นาทับ"
      },
      {
      "code":"900305",
      "display":"นาหว้า"
      },
      {
      "code":"900307",
      "display":"น้ำขาว"
      },
      {
      "code":"900301",
      "display":"บ้านนา"
      },
      {
      "code":"900302",
      "display":"ป่าชิง"
      },
      {
      "code":"900304",
      "display":"สะกอม"
      },
      {
      "code":"900303",
      "display":"สะพานไม้แก่น"
      },
      {
      "code":"900312",
      "display":"แค"
      }
      ],
      "code":"9003",
      "display":"จะนะ"
      },
      {
      "districts":[
      {
      "code":"900410",
      "display":"คลองกวาง"
      },
      {
      "code":"900404",
      "display":"คลองทราย"
      },
      {
      "code":"900402",
      "display":"ฉาง"
      },
      {
      "code":"900408",
      "display":"ทับช้าง"
      },
      {
      "code":"900406",
      "display":"ท่าประดู่"
      },
      {
      "code":"900401",
      "display":"นาทวี"
      },
      {
      "code":"900403",
      "display":"นาหมอศรี"
      },
      {
      "code":"900409",
      "display":"ประกอบ"
      },
      {
      "code":"900405",
      "display":"ปลักหนู"
      },
      {
      "code":"900407",
      "display":"สะท้อน"
      }
      ],
      "code":"9004",
      "display":"นาทวี"
      },
      {
      "districts":[
      {
      "code":"901196",
      "display":"คลองหรัง"
      },
      {
      "code":"901197",
      "display":"ทุ่งขมิ้น"
      },
      {
      "code":"901199",
      "display":"นาหม่อม"
      },
      {
      "code":"901198",
      "display":"พิจิตร"
      }
      ],
      "code":"9012",
      "display":"นาหม่อม"
      },
      {
      "districts":[
      {
      "code":"901110",
      "display":"ท่าช้าง"
      },
      {
      "code":"901115",
      "display":"บางกล่ำ"
      },
      {
      "code":"901117",
      "display":"บ้านหาร"
      },
      {
      "code":"901119",
      "display":"แม่ทอม"
      }
      ],
      "code":"9014",
      "display":"บางกล่ำ"
      },
      {
      "districts":[
      {
      "code":"900702",
      "display":"คลองแดน"
      },
      {
      "code":"900703",
      "display":"ตะเครียะ"
      },
      {
      "code":"900704",
      "display":"ท่าบอน"
      },
      {
      "code":"900706",
      "display":"บ่อตรุ"
      },
      {
      "code":"900711",
      "display":"บ้านขาว"
      },
      {
      "code":"900705",
      "display":"บ้านใหม่"
      },
      {
      "code":"900707",
      "display":"ปากแตระ"
      },
      {
      "code":"900708",
      "display":"พังยาง"
      },
      {
      "code":"900709",
      "display":"ระวะ"
      },
      {
      "code":"900701",
      "display":"ระโนด"
      },
      {
      "code":"900710",
      "display":"วัดสน"
      },
      {
      "code":"900712",
      "display":"แดนสงวน"
      }
      ],
      "code":"9007",
      "display":"ระโนด"
      },
      {
      "districts":[
      {
      "code":"900901",
      "display":"กำแพงเพชร"
      },
      {
      "code":"900904",
      "display":"ควนรู"
      },
      {
      "code":"900903",
      "display":"คูหาใต้"
      },
      {
      "code":"900902",
      "display":"ท่าชะมวง"
      },
      {
      "code":"900909",
      "display":"เขาพระ"
      }
      ],
      "code":"9009",
      "display":"รัตภูมิ"
      },
      {
      "districts":[
      {
      "code":"900202",
      "display":"กระดังงา"
      },
      {
      "code":"900206",
      "display":"คลองรี"
      },
      {
      "code":"900207",
      "display":"คูขุด"
      },
      {
      "code":"900201",
      "display":"จะทิ้งพระ"
      },
      {
      "code":"900205",
      "display":"ชุมพล"
      },
      {
      "code":"900204",
      "display":"ดีหลวง"
      },
      {
      "code":"900208",
      "display":"ท่าหิน"
      },
      {
      "code":"900211",
      "display":"บ่อดาน"
      },
      {
      "code":"900210",
      "display":"บ่อแดง"
      },
      {
      "code":"900209",
      "display":"วัดจันทร์"
      },
      {
      "code":"900203",
      "display":"สนามชัย"
      }
      ],
      "code":"9002",
      "display":"สทิงพระ"
      },
      {
      "districts":[
      {
      "code":"900606",
      "display":"คูหา"
      },
      {
      "code":"900605",
      "display":"จะแหน"
      },
      {
      "code":"900602",
      "display":"ทุ่งพอ"
      },
      {
      "code":"900609",
      "display":"ธารคีรี"
      },
      {
      "code":"900608",
      "display":"บาโหย"
      },
      {
      "code":"900604",
      "display":"บ้านโหนด"
      },
      {
      "code":"900601",
      "display":"สะบ้าย้อย"
      },
      {
      "code":"900607",
      "display":"เขาแดง"
      },
      {
      "code":"900603",
      "display":"เปียน"
      }
      ],
      "code":"9006",
      "display":"สะบ้าย้อย"
      },
      {
      "districts":[
      {
      "code":"901005",
      "display":"ทุ่งหมอ"
      },
      {
      "code":"901006",
      "display":"ท่าโพธิ์"
      },
      {
      "code":"901002",
      "display":"ปริก"
      },
      {
      "code":"901007",
      "display":"ปาดังเบซาร์"
      },
      {
      "code":"901003",
      "display":"พังลา"
      },
      {
      "code":"901001",
      "display":"สะเดา"
      },
      {
      "code":"901008",
      "display":"สำนักขาม"
      },
      {
      "code":"901004",
      "display":"สำนักแต้ว"
      },
      {
      "code":"901009",
      "display":"เขามีเกียรติ"
      }
      ],
      "code":"9010",
      "display":"สะเดา"
      },
      {
      "districts":[
      {
      "code":"900112",
      "display":"ชะแล้"
      },
      {
      "code":"900107",
      "display":"ชิงโค"
      },
      {
      "code":"900109",
      "display":"ทำนบ"
      },
      {
      "code":"900116",
      "display":"บางเขียด"
      },
      {
      "code":"900113",
      "display":"ปากรอ"
      },
      {
      "code":"900114",
      "display":"ป่าขาด"
      },
      {
      "code":"900117",
      "display":"ม่วงงาม"
      },
      {
      "code":"900110",
      "display":"รำแดง"
      },
      {
      "code":"900111",
      "display":"วัดขนุน"
      },
      {
      "code":"900108",
      "display":"สทิงหม้อ"
      },
      {
      "code":"900115",
      "display":"หัวเขา"
      }
      ],
      "code":"9015",
      "display":"สิงหนคร"
      },
      {
      "districts":[
      {
      "code":"901107",
      "display":"คลองอู่ตะเภา"
      },
      {
      "code":"901105",
      "display":"คลองแห"
      },
      {
      "code":"901102",
      "display":"ควนลัง"
      },
      {
      "code":"901104",
      "display":"คอหงส์"
      },
      {
      "code":"901103",
      "display":"คูเต่า"
      },
      {
      "code":"901108",
      "display":"ฉลุง"
      },
      {
      "code":"901112",
      "display":"ทุ่งตำเสา"
      },
      {
      "code":"901111",
      "display":"ทุ่งใหญ่"
      },
      {
      "code":"901113",
      "display":"ท่าข้าม"
      },
      {
      "code":"901114",
      "display":"น้ำน้อย"
      },
      {
      "code":"901116",
      "display":"บ้านพรุ"
      },
      {
      "code":"901118",
      "display":"พะตง"
      },
      {
      "code":"901101",
      "display":"หาดใหญ่"
      }
      ],
      "code":"9011",
      "display":"หาดใหญ่"
      },
      {
      "districts":[
      {
      "code":"900505",
      "display":"ท่าม่วง"
      },
      {
      "code":"900502",
      "display":"ปากบาง"
      },
      {
      "code":"900504",
      "display":"ลำไพล"
      },
      {
      "code":"900506",
      "display":"วังใหญ่"
      },
      {
      "code":"900304",
      "display":"สะกอม"
      },
      {
      "code":"900503",
      "display":"เกาะสะบ้า"
      },
      {
      "code":"900501",
      "display":"เทพา"
      }
      ],
      "code":"9005",
      "display":"เทพา"
      },
      {
      "districts":[
      {
      "code":"900105",
      "display":"ทุ่งหวัง"
      },
      {
      "code":"900101",
      "display":"บ่อยาง"
      },
      {
      "code":"900104",
      "display":"พะวง"
      },
      {
      "code":"900106",
      "display":"เกาะยอ"
      },
      {
      "code":"900103",
      "display":"เกาะแต้ว"
      },
      {
      "code":"900102",
      "display":"เขารูปช้าง"
      }
      ],
      "code":"9001",
      "display":"เมืองสงขลา"
      }
      ],
      "display":"สงขลา"
      },
      "สตูล":{
      "sort_order":"52",
      "code":"91",
      "amphoe":[
      {
      "districts":[
      {
      "code":"910302",
      "display":"ควนกาหลง"
      },
      {
      "code":"910301",
      "display":"ทุ่งนุ้ย"
      },
      {
      "code":"FALSE",
      "display":"อุไดเจริญ"
      }
      ],
      "code":"9103",
      "display":"ควนกาหลง"
      },
      {
      "districts":[
      {
      "code":"910202",
      "display":"ควนสตอ"
      },
      {
      "code":"910201",
      "display":"ควนโดน"
      },
      {
      "code":"910203",
      "display":"ย่านซื่อ"
      },
      {
      "code":"910204",
      "display":"วังประจัน"
      }
      ],
      "code":"9102",
      "display":"ควนโดน"
      },
      {
      "districts":[
      {
      "code":"910603",
      "display":"ขอนคลาน"
      },
      {
      "code":"910604",
      "display":"ทุ่งบุหลัง"
      },
      {
      "code":"910601",
      "display":"ทุ่งหว้า"
      },
      {
      "code":"910602",
      "display":"นาทอน"
      },
      {
      "code":"910605",
      "display":"ป่าแก่บ่อหิน"
      }
      ],
      "code":"9106",
      "display":"ทุ่งหว้า"
      },
      {
      "districts":[
      {
      "code":"910404",
      "display":"ท่าเรือ"
      },
      {
      "code":"910199",
      "display":"ท่าแพ"
      },
      {
      "code":"910403",
      "display":"สาคร"
      },
      {
      "code":"910402",
      "display":"แป-ระ"
      }
      ],
      "code":"9104",
      "display":"ท่าแพ"
      },
      {
      "districts":[
      {
      "code":"910304",
      "display":"นิคมพัฒนา"
      },
      {
      "code":"910305",
      "display":"ปาล์มพัฒนา"
      }
      ],
      "code":"9107",
      "display":"มะนัง"
      },
      {
      "districts":[
      {
      "code":"910501",
      "display":"กำแพง"
      },
      {
      "code":"910505",
      "display":"น้ำผุด"
      },
      {
      "code":"910504",
      "display":"ปากน้ำ"
      },
      {
      "code":"910502",
      "display":"ละงู"
      },
      {
      "code":"910503",
      "display":"เขาขาว"
      },
      {
      "code":"910506",
      "display":"แหลมสน"
      }
      ],
      "code":"9105",
      "display":"ละงู"
      },
      {
      "districts":[
      {
      "code":"910102",
      "display":"คลองขุด"
      },
      {
      "code":"910103",
      "display":"ควนขัน"
      },
      {
      "code":"910111",
      "display":"ควนโพธิ์"
      },
      {
      "code":"910105",
      "display":"ฉลุง"
      },
      {
      "code":"910107",
      "display":"ตันหยงโป"
      },
      {
      "code":"910109",
      "display":"ตำมะลัง"
      },
      {
      "code":"910104",
      "display":"บ้านควน"
      },
      {
      "code":"910110",
      "display":"ปูยู"
      },
      {
      "code":"910101",
      "display":"พิมาน"
      },
      {
      "code":"910112",
      "display":"เกตรี"
      },
      {
      "code":"910106",
      "display":"เกาะสาหร่าย"
      },
      {
      "code":"910108",
      "display":"เจ๊ะบิลัง"
      }
      ],
      "code":"9101",
      "display":"เมืองสตูล"
      }
      ],
      "display":"สตูล"
      },
      "สมุทรปราการ":{
      "sort_order":"53",
      "code":"11",
      "amphoe":[
      {
      "districts":[
      {
      "code":"110205",
      "display":"คลองด่าน"
      },
      {
      "code":"110208",
      "display":"คลองนิยมยาตรา"
      },
      {
      "code":"110206",
      "display":"คลองสวน"
      },
      {
      "code":"110201",
      "display":"บางบ่อ"
      },
      {
      "code":"110203",
      "display":"บางพลีน้อย"
      },
      {
      "code":"110204",
      "display":"บางเพรียง"
      },
      {
      "code":"110202",
      "display":"บ้านระกาศ"
      },
      {
      "code":"110207",
      "display":"เปร็ง"
      }
      ],
      "code":"1102",
      "display":"บางบ่อ"
      },
      {
      "districts":[
      {
      "code":"110303",
      "display":"บางปลา"
      },
      {
      "code":"110301",
      "display":"บางพลีใหญ่"
      },
      {
      "code":"110302",
      "display":"บางแก้ว"
      },
      {
      "code":"110304",
      "display":"บางโฉลง"
      },
      {
      "code":"110308",
      "display":"ราชาเทวะ"
      },
      {
      "code":"110309",
      "display":"หนองปรือ"
      }
      ],
      "code":"1103",
      "display":"บางพลี"
      },
      {
      "districts":[
      {
      "code":"110305",
      "display":"บางเสาธง"
      },
      {
      "code":"110602",
      "display":"ศีรษะจรเข้น้อย"
      },
      {
      "code":"110603",
      "display":"ศีรษะจรเข้ใหญ่"
      }
      ],
      "code":"1106",
      "display":"บางเสาธง"
      },
      {
      "districts":[
      {
      "code":"110401",
      "display":"ตลาด"
      },
      {
      "code":"110413",
      "display":"ทรงคนอง"
      },
      {
      "code":"110411",
      "display":"บางกระสอบ"
      },
      {
      "code":"110412",
      "display":"บางกอบัว"
      },
      {
      "code":"110409",
      "display":"บางกะเจ้า"
      },
      {
      "code":"110404",
      "display":"บางครุ"
      },
      {
      "code":"110403",
      "display":"บางจาก"
      },
      {
      "code":"110410",
      "display":"บางน้ำผึ้ง"
      },
      {
      "code":"110402",
      "display":"บางพึ่ง"
      },
      {
      "code":"110408",
      "display":"บางยอ"
      },
      {
      "code":"110405",
      "display":"บางหญ้าแพรก"
      },
      {
      "code":"110406",
      "display":"บางหัวเสือ"
      },
      {
      "code":"110414",
      "display":"สำโรง"
      },
      {
      "code":"110415",
      "display":"สำโรงกลาง"
      },
      {
      "code":"110407",
      "display":"สำโรงใต้"
      }
      ],
      "code":"1104",
      "display":"พระประแดง"
      },
      {
      "districts":[
      {
      "code":"110105",
      "display":"นาเกลือ"
      },
      {
      "code":"110198",
      "display":"บ้านคลองสวน"
      },
      {
      "code":"110109",
      "display":"ปากคลองบางปลากด"
      },
      {
      "code":"110106",
      "display":"แหลมฟ้าผ่า"
      },
      {
      "code":"110107",
      "display":"ในคลองบางปลากด"
      }
      ],
      "code":"1105",
      "display":"พระสมุทรเจดีย์"
      },
      {
      "districts":[
      {
      "code":"110104",
      "display":"ท้ายบ้าน"
      },
      {
      "code":"110116",
      "display":"ท้ายบ้านใหม่"
      },
      {
      "code":"110113",
      "display":"บางด้วน"
      },
      {
      "code":"110112",
      "display":"บางปู"
      },
      {
      "code":"110108",
      "display":"บางปูใหม่"
      },
      {
      "code":"110103",
      "display":"บางเมือง"
      },
      {
      "code":"110114",
      "display":"บางเมืองใหม่"
      },
      {
      "code":"110111",
      "display":"บางโปรง"
      },
      {
      "code":"110101",
      "display":"ปากน้ำ"
      },
      {
      "code":"110102",
      "display":"สำโรงเหนือ"
      },
      {
      "code":"110115",
      "display":"เทพารักษ์"
      },
      {
      "code":"110110",
      "display":"แพรกษา"
      },
      {
      "code":"110117",
      "display":"แพรกษาใหม่"
      }
      ],
      "code":"1101",
      "display":"เมืองสมุทรปราการ"
      }
      ],
      "display":"สมุทรปราการ"
      },
      "สมุทรสงคราม":{
      "sort_order":"54",
      "code":"75",
      "amphoe":[
      {
      "districts":[
      {
      "code":"750201",
      "display":"กระดังงา"
      },
      {
      "code":"750209",
      "display":"จอมปลวก"
      },
      {
      "code":"750206",
      "display":"ดอนมะโนรา"
      },
      {
      "code":"750212",
      "display":"บางกระบือ"
      },
      {
      "code":"750208",
      "display":"บางกุ้ง"
      },
      {
      "code":"750205",
      "display":"บางคนที"
      },
      {
      "code":"750210",
      "display":"บางนกแขวก"
      },
      {
      "code":"750207",
      "display":"บางพรม"
      },
      {
      "code":"750203",
      "display":"บางยี่รงค์"
      },
      {
      "code":"750202",
      "display":"บางสะแก"
      },
      {
      "code":"750213",
      "display":"บ้านปราโมทย์"
      },
      {
      "code":"750211",
      "display":"ยายแพง"
      },
      {
      "code":"750204",
      "display":"โรงหีบ"
      }
      ],
      "code":"7502",
      "display":"บางคนที"
      },
      {
      "districts":[
      {
      "code":"750303",
      "display":"ท่าคา"
      },
      {
      "code":"750306",
      "display":"บางช้าง"
      },
      {
      "code":"750312",
      "display":"บางนางลี่"
      },
      {
      "code":"750309",
      "display":"บางแค"
      },
      {
      "code":"750308",
      "display":"ปลายโพงพาง"
      },
      {
      "code":"750311",
      "display":"ยี่สาร"
      },
      {
      "code":"750304",
      "display":"วัดประดู่"
      },
      {
      "code":"750302",
      "display":"สวนหลวง"
      },
      {
      "code":"750301",
      "display":"อัมพวา"
      },
      {
      "code":"750305",
      "display":"เหมืองใหม่"
      },
      {
      "code":"750307",
      "display":"แควอ้อม"
      },
      {
      "code":"750310",
      "display":"แพรกหนามแดง"
      }
      ],
      "code":"7503",
      "display":"อัมพวา"
      },
      {
      "districts":[
      {
      "code":"750108",
      "display":"คลองเขิน"
      },
      {
      "code":"750109",
      "display":"คลองโคน"
      },
      {
      "code":"750106",
      "display":"ท้ายหาด"
      },
      {
      "code":"750110",
      "display":"นางตะเคียน"
      },
      {
      "code":"750102",
      "display":"บางขันแตก"
      },
      {
      "code":"750111",
      "display":"บางจะเกร็ง"
      },
      {
      "code":"750105",
      "display":"บางแก้ว"
      },
      {
      "code":"750104",
      "display":"บ้านปรก"
      },
      {
      "code":"750103",
      "display":"ลาดใหญ่"
      },
      {
      "code":"750101",
      "display":"แม่กลอง"
      },
      {
      "code":"750107",
      "display":"แหลมใหญ่"
      }
      ],
      "code":"7501",
      "display":"เมืองสมุทรสงคราม"
      }
      ],
      "display":"สมุทรสงคราม"
      },
      "สมุทรสาคร":{
      "sort_order":"55",
      "code":"74",
      "amphoe":[
      {
      "districts":[
      {
      "code":"740206",
      "display":"คลองมะเดื่อ"
      },
      {
      "code":"740208",
      "display":"ดอนไก่ดี"
      },
      {
      "code":"740201",
      "display":"ตลาดกระทุ่มแบน"
      },
      {
      "code":"740210",
      "display":"ท่าเสา"
      },
      {
      "code":"740203",
      "display":"ท่าไม้"
      },
      {
      "code":"740205",
      "display":"บางยาง"
      },
      {
      "code":"740204",
      "display":"สวนหลวง"
      },
      {
      "code":"740207",
      "display":"หนองนกไข่"
      },
      {
      "code":"740202",
      "display":"อ้อมน้อย"
      },
      {
      "code":"740209",
      "display":"แคราย"
      }
      ],
      "code":"7402",
      "display":"กระทุ่มแบน"
      },
      {
      "districts":[
      {
      "code":"740309",
      "display":"คลองตัน"
      },
      {
      "code":"740301",
      "display":"บ้านแพ้ว"
      },
      {
      "code":"740303",
      "display":"ยกกระบัตร"
      },
      {
      "code":"740311",
      "display":"สวนส้ม"
      },
      {
      "code":"740306",
      "display":"หนองบัว"
      },
      {
      "code":"740305",
      "display":"หนองสองห้อง"
      },
      {
      "code":"740307",
      "display":"หลักสอง"
      },
      {
      "code":"740302",
      "display":"หลักสาม"
      },
      {
      "code":"740310",
      "display":"อำแพง"
      },
      {
      "code":"740312",
      "display":"เกษตรพัฒนา"
      },
      {
      "code":"740308",
      "display":"เจ็ดริ้ว"
      },
      {
      "code":"740304",
      "display":"โรงเข้"
      }
      ],
      "code":"7403",
      "display":"บ้านแพ้ว"
      },
      {
      "districts":[
      {
      "code":"740106",
      "display":"กาหลง"
      },
      {
      "code":"740111",
      "display":"คอกกระบือ"
      },
      {
      "code":"740118",
      "display":"ชัยมงคล"
      },
      {
      "code":"740108",
      "display":"ท่าจีน"
      },
      {
      "code":"740102",
      "display":"ท่าฉลอม"
      },
      {
      "code":"740110",
      "display":"ท่าทราย"
      },
      {
      "code":"740109",
      "display":"นาดี"
      },
      {
      "code":"740107",
      "display":"นาโคก"
      },
      {
      "code":"740116",
      "display":"บางกระเจ้า"
      },
      {
      "code":"740112",
      "display":"บางน้ำจืด"
      },
      {
      "code":"740117",
      "display":"บางหญ้าแพรก"
      },
      {
      "code":"740105",
      "display":"บางโทรัด"
      },
      {
      "code":"740104",
      "display":"บ้านบ่อ"
      },
      {
      "code":"740115",
      "display":"บ้านเกาะ"
      },
      {
      "code":"740113",
      "display":"พันท้ายนรสิงห์"
      },
      {
      "code":"740101",
      "display":"มหาชัย"
      },
      {
      "code":"740103",
      "display":"โกรกกราก"
      },
      {
      "code":"740114",
      "display":"โคกขาม"
      }
      ],
      "code":"7401",
      "display":"เมืองสมุทรสาคร"
      }
      ],
      "display":"สมุทรสาคร"
      },
      "สระบุรี":{
      "sort_order":"56",
      "code":"19",
      "amphoe":[
      {
      "districts":[
      {
      "code":"190696",
      "display":"ดงตะงาว"
      },
      {
      "code":"190699",
      "display":"ดอนพุด"
      },
      {
      "code":"190697",
      "display":"บ้านหลวง"
      },
      {
      "code":"190698",
      "display":"ไผ่หลิ่ว"
      }
      ],
      "code":"1907",
      "display":"ดอนพุด"
      },
      {
      "districts":[
      {
      "code":"190604",
      "display":"ตลาดน้อย"
      },
      {
      "code":"190602",
      "display":"บางโขมด"
      },
      {
      "code":"190608",
      "display":"บ้านครัว"
      },
      {
      "code":"190601",
      "display":"บ้านหมอ"
      },
      {
      "code":"190603",
      "display":"สร่างโศก"
      },
      {
      "code":"190609",
      "display":"หนองบัว"
      },
      {
      "code":"190605",
      "display":"หรเทพ"
      },
      {
      "code":"190606",
      "display":"โคกใหญ่"
      },
      {
      "code":"190607",
      "display":"ไผ่ขวาง"
      }
      ],
      "code":"1906",
      "display":"บ้านหมอ"
      },
      {
      "districts":[
      {
      "code":"190902",
      "display":"ขุนโขลน"
      },
      {
      "code":"190903",
      "display":"ธารเกษม"
      },
      {
      "code":"190904",
      "display":"นายาว"
      },
      {
      "code":"190901",
      "display":"พระพุทธบาท"
      },
      {
      "code":"190908",
      "display":"พุกร่าง"
      },
      {
      "code":"190905",
      "display":"พุคำจาน"
      },
      {
      "code":"190909",
      "display":"หนองแก"
      },
      {
      "code":"190907",
      "display":"ห้วยป่าหวาย"
      },
      {
      "code":"190906",
      "display":"เขาวง"
      }
      ],
      "code":"1909",
      "display":"พระพุทธบาท"
      },
      {
      "districts":[
      {
      "code":"191109",
      "display":"ซับสนุ่น"
      },
      {
      "code":"191101",
      "display":"มวกเหล็ก"
      },
      {
      "code":"191102",
      "display":"มิตรภาพ"
      },
      {
      "code":"191107",
      "display":"ลำพญากลาง"
      },
      {
      "code":"191105",
      "display":"ลำสมพุง"
      },
      {
      "code":"191104",
      "display":"หนองย่างเสือ"
      }
      ],
      "code":"1911",
      "display":"มวกเหล็ก"
      },
      {
      "districts":[
      {
      "code":"191106",
      "display":"คำพราน"
      },
      {
      "code":"191108",
      "display":"วังม่วง"
      },
      {
      "code":"191103",
      "display":"แสลงพัน"
      }
      ],
      "code":"1912",
      "display":"วังม่วง"
      },
      {
      "districts":[
      {
      "code":"190403",
      "display":"คลองเรือ"
      },
      {
      "code":"190402",
      "display":"บ้านลำ"
      },
      {
      "code":"190404",
      "display":"วิหารแดง"
      },
      {
      "code":"190405",
      "display":"หนองสรวง"
      },
      {
      "code":"190401",
      "display":"หนองหมู"
      },
      {
      "code":"190406",
      "display":"เจริญธรรม"
      }
      ],
      "code":"1904",
      "display":"วิหารแดง"
      },
      {
      "districts":[
      {
      "code":"190302",
      "display":"กุ่มหัก"
      },
      {
      "code":"190303",
      "display":"คชสิทธิ์"
      },
      {
      "code":"190306",
      "display":"บัวลอย"
      },
      {
      "code":"190314",
      "display":"หนองจรเข้"
      },
      {
      "code":"190313",
      "display":"หนองจิก"
      },
      {
      "code":"190315",
      "display":"หนองนาก"
      },
      {
      "code":"190316",
      "display":"หนองปลาหมอ"
      },
      {
      "code":"190317",
      "display":"หนองปลิง"
      },
      {
      "code":"190312",
      "display":"หนองแขม"
      },
      {
      "code":"190301",
      "display":"หนองแค"
      },
      {
      "code":"190318",
      "display":"หนองโรง"
      },
      {
      "code":"190311",
      "display":"หนองไข่น้ำ"
      },
      {
      "code":"190309",
      "display":"ห้วยขมิ้น"
      },
      {
      "code":"190310",
      "display":"ห้วยทราย"
      },
      {
      "code":"190304",
      "display":"โคกตูม"
      },
      {
      "code":"190305",
      "display":"โคกแย้"
      },
      {
      "code":"190308",
      "display":"โพนทอง"
      },
      {
      "code":"190307",
      "display":"ไผ่ต่ำ"
      }
      ],
      "code":"1903",
      "display":"หนองแค"
      },
      {
      "districts":[
      {
      "code":"190508",
      "display":"ม่วงหวาน"
      },
      {
      "code":"190505",
      "display":"หนองกบ"
      },
      {
      "code":"190502",
      "display":"หนองควายโซ"
      },
      {
      "code":"190504",
      "display":"หนองสีดา"
      },
      {
      "code":"190503",
      "display":"หนองหัวโพ"
      },
      {
      "code":"190501",
      "display":"หนองแซง"
      },
      {
      "code":"190509",
      "display":"เขาดิน"
      },
      {
      "code":"190507",
      "display":"โคกสะอาด"
      },
      {
      "code":"190506",
      "display":"ไก่เส่า"
      }
      ],
      "code":"1905",
      "display":"หนองแซง"
      },
      {
      "districts":[
      {
      "code":"190803",
      "display":"ดอนทอง"
      },
      {
      "code":"190802",
      "display":"บ้านกลับ"
      },
      {
      "code":"190804",
      "display":"บ้านโปร่ง"
      },
      {
      "code":"190801",
      "display":"หนองโดน"
      }
      ],
      "code":"1908",
      "display":"หนองโดน"
      },
      {
      "districts":[
      {
      "code":"190115",
      "display":"บ้านแก้ง"
      },
      {
      "code":"190116",
      "display":"ผึ้งรวง"
      },
      {
      "code":"190103",
      "display":"พุแค"
      },
      {
      "code":"190102",
      "display":"หน้าพระลาน"
      },
      {
      "code":"190104",
      "display":"ห้วยบง"
      },
      {
      "code":"190117",
      "display":"เขาดินพัฒนา"
      }
      ],
      "code":"1913",
      "display":"เฉลิมพระเกียรติ"
      },
      {
      "districts":[
      {
      "code":"190112",
      "display":"กุดนกเปล้า"
      },
      {
      "code":"190105",
      "display":"ดาวเรือง"
      },
      {
      "code":"190113",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"190114",
      "display":"ตะกุด"
      },
      {
      "code":"190106",
      "display":"นาโฉง"
      },
      {
      "code":"190110",
      "display":"ปากข้าวสาร"
      },
      {
      "code":"190101",
      "display":"ปากเพรียว"
      },
      {
      "code":"190111",
      "display":"หนองปลาไหล"
      },
      {
      "code":"190109",
      "display":"หนองยาว"
      },
      {
      "code":"190108",
      "display":"หนองโน"
      },
      {
      "code":"190107",
      "display":"โคกสว่าง"
      }
      ],
      "code":"1901",
      "display":"เมืองสระบุรี"
      },
      {
      "districts":[
      {
      "code":"191004",
      "display":"งิ้วงาม"
      },
      {
      "code":"191006",
      "display":"ต้นตาล"
      },
      {
      "code":"191007",
      "display":"ท่าช้าง"
      },
      {
      "code":"191002",
      "display":"บ้านยาง"
      },
      {
      "code":"191008",
      "display":"พระยาทด"
      },
      {
      "code":"191009",
      "display":"ม่วงงาม"
      },
      {
      "code":"191005",
      "display":"ศาลารีไทย"
      },
      {
      "code":"191012",
      "display":"สวนดอกไม้"
      },
      {
      "code":"191003",
      "display":"หัวปลวก"
      },
      {
      "code":"191011",
      "display":"เมืองเก่า"
      },
      {
      "code":"191010",
      "display":"เริงราง"
      },
      {
      "code":"191001",
      "display":"เสาไห้"
      }
      ],
      "code":"1910",
      "display":"เสาไห้"
      },
      {
      "districts":[
      {
      "code":"190210",
      "display":"ชะอม"
      },
      {
      "code":"190213",
      "display":"ชำผักแพว"
      },
      {
      "code":"190203",
      "display":"ตาลเดี่ยว"
      },
      {
      "code":"190202",
      "display":"ทับกวาง"
      },
      {
      "code":"190205",
      "display":"ท่าคล้อ"
      },
      {
      "code":"190209",
      "display":"ท่าตูม"
      },
      {
      "code":"190215",
      "display":"ท่ามะปราง"
      },
      {
      "code":"190207",
      "display":"บ้านธาตุ"
      },
      {
      "code":"190208",
      "display":"บ้านป่า"
      },
      {
      "code":"190211",
      "display":"สองคอน"
      },
      {
      "code":"190206",
      "display":"หินซ้อน"
      },
      {
      "code":"190204",
      "display":"ห้วยแห้ง"
      },
      {
      "code":"190212",
      "display":"เตาปูน"
      },
      {
      "code":"190201",
      "display":"แก่งคอย"
      }
      ],
      "code":"1902",
      "display":"แก่งคอย"
      }
      ],
      "display":"สระบุรี"
      },
      "สระแก้ว":{
      "sort_order":"57",
      "code":"27",
      "amphoe":[
      {
      "districts":[
      {
      "code":"270201",
      "display":"คลองหาด"
      },
      {
      "code":"270205",
      "display":"คลองไก่เถื่อน"
      },
      {
      "code":"270203",
      "display":"ซับมะกรูด"
      },
      {
      "code":"270206",
      "display":"เบญจขร"
      },
      {
      "code":"270202",
      "display":"ไทยอุดม"
      },
      {
      "code":"270207",
      "display":"ไทรทอง"
      },
      {
      "code":"270204",
      "display":"ไทรเดี่ยว"
      }
      ],
      "code":"2702",
      "display":"คลองหาด"
      },
      {
      "districts":[
      {
      "code":"270301",
      "display":"ตาพระยา"
      },
      {
      "code":"270306",
      "display":"ทัพราช"
      },
      {
      "code":"270302",
      "display":"ทัพเสด็จ"
      },
      {
      "code":"270307",
      "display":"ทัพไทย"
      },
      {
      "code":"270309",
      "display":"โคคลาน"
      }
      ],
      "code":"2703",
      "display":"ตาพระยา"
      },
      {
      "districts":[
      {
      "code":"270405",
      "display":"คลองหินปูน"
      },
      {
      "code":"270403",
      "display":"ตาหลังใน"
      },
      {
      "code":"270406",
      "display":"ทุ่งมหาเจริญ"
      },
      {
      "code":"270401",
      "display":"วังน้ำเย็น"
      }
      ],
      "code":"2704",
      "display":"วังน้ำเย็น"
      },
      {
      "districts":[
      {
      "code":"270407",
      "display":"วังทอง"
      },
      {
      "code":"270402",
      "display":"วังสมบูรณ์"
      },
      {
      "code":"270404",
      "display":"วังใหม่"
      }
      ],
      "code":"2709",
      "display":"วังสมบูรณ์"
      },
      {
      "districts":[
      {
      "code":"270506",
      "display":"ช่องกุ่ม"
      },
      {
      "code":"270502",
      "display":"ท่าเกวียน"
      },
      {
      "code":"270503",
      "display":"ผักขะ"
      },
      {
      "code":"270501",
      "display":"วัฒนานคร"
      },
      {
      "code":"270510",
      "display":"หนองตะเคียนบอน"
      },
      {
      "code":"270505",
      "display":"หนองน้ำใส"
      },
      {
      "code":"270509",
      "display":"หนองหมากฝ้าย"
      },
      {
      "code":"270304",
      "display":"หนองแวง"
      },
      {
      "code":"270511",
      "display":"ห้วยโจด"
      },
      {
      "code":"270508",
      "display":"แซร์ออ"
      },
      {
      "code":"270504",
      "display":"โนนหมากเค็ง"
      }
      ],
      "code":"2705",
      "display":"วัฒนานคร"
      },
      {
      "districts":[
      {
      "code":"270611",
      "display":"คลองทับจันทร์"
      },
      {
      "code":"270604",
      "display":"คลองน้ำใส"
      },
      {
      "code":"270607",
      "display":"ทับพริก"
      },
      {
      "code":"270605",
      "display":"ท่าข้าม"
      },
      {
      "code":"270613",
      "display":"บ้านด่าน"
      },
      {
      "code":"270608",
      "display":"บ้านใหม่หนองไทร"
      },
      {
      "code":"270606",
      "display":"ป่าไร่"
      },
      {
      "code":"270609",
      "display":"ผ่านศึก"
      },
      {
      "code":"270612",
      "display":"ฟากห้วย"
      },
      {
      "code":"270610",
      "display":"หนองสังข์"
      },
      {
      "code":"270603",
      "display":"หันทราย"
      },
      {
      "code":"270601",
      "display":"อรัญประเทศ"
      },
      {
      "code":"270602",
      "display":"เมืองไผ่"
      }
      ],
      "code":"2706",
      "display":"อรัญประเทศ"
      },
      {
      "districts":[
      {
      "code":"270110",
      "display":"พระเพลิง"
      },
      {
      "code":"270109",
      "display":"หนองหว้า"
      },
      {
      "code":"270107",
      "display":"เขาฉกรรจ์"
      },
      {
      "code":"270112",
      "display":"เขาสามสิบ"
      }
      ],
      "code":"2707",
      "display":"เขาฉกรรจ์"
      },
      {
      "districts":[
      {
      "code":"270106",
      "display":"ท่าเกษม"
      },
      {
      "code":"270105",
      "display":"ท่าแยก"
      },
      {
      "code":"270102",
      "display":"บ้านแก้ง"
      },
      {
      "code":"270103",
      "display":"ศาลาลำดวน"
      },
      {
      "code":"270108",
      "display":"สระขวัญ"
      },
      {
      "code":"270101",
      "display":"สระแก้ว"
      },
      {
      "code":"270111",
      "display":"หนองบอน"
      },
      {
      "code":"270104",
      "display":"โคกปี่ฆ้อง"
      }
      ],
      "code":"2701",
      "display":"เมืองสระแก้ว"
      },
      {
      "districts":[
      {
      "code":"270305",
      "display":"หนองม่วง"
      },
      {
      "code":"270304",
      "display":"หนองแวง"
      },
      {
      "code":"270303",
      "display":"โคกสูง"
      },
      {
      "code":"270308",
      "display":"โนนหมากมุ่น"
      }
      ],
      "code":"2708",
      "display":"โคกสูง"
      }
      ],
      "display":"สระแก้ว"
      },
      "สิงห์บุรี":{
      "sort_order":"58",
      "code":"17",
      "amphoe":[
      {
      "districts":[
      {
      "code":"170305",
      "display":"คอทราย"
      },
      {
      "code":"170304",
      "display":"ท่าข้าม"
      },
      {
      "code":"170302",
      "display":"บางระจัน"
      },
      {
      "code":"170306",
      "display":"หนองกระทุ่ม"
      },
      {
      "code":"170301",
      "display":"โพทะเล"
      },
      {
      "code":"170303",
      "display":"โพสังโฆ"
      }
      ],
      "code":"1703",
      "display":"ค่ายบางระจัน"
      },
      {
      "districts":[
      {
      "code":"170501",
      "display":"ถอนสมอ"
      },
      {
      "code":"170504",
      "display":"พิกุลทอง"
      },
      {
      "code":"170503",
      "display":"วิหารขาว"
      },
      {
      "code":"170502",
      "display":"โพประจักษ์"
      }
      ],
      "code":"1705",
      "display":"ท่าช้าง"
      },
      {
      "districts":[
      {
      "code":"170206",
      "display":"บ้านจ่า"
      },
      {
      "code":"170207",
      "display":"พักทัน"
      },
      {
      "code":"170208",
      "display":"สระแจง"
      },
      {
      "code":"170201",
      "display":"สิงห์"
      },
      {
      "code":"170203",
      "display":"เชิงกลัด"
      },
      {
      "code":"170205",
      "display":"แม่ลา"
      },
      {
      "code":"170204",
      "display":"โพชนไก่"
      },
      {
      "code":"170202",
      "display":"ไม้ดัด"
      }
      ],
      "code":"1702",
      "display":"บางระจัน"
      },
      {
      "districts":[
      {
      "code":"170403",
      "display":"บางน้ำเชี่ยว"
      },
      {
      "code":"170404",
      "display":"บ้านหม้อ"
      },
      {
      "code":"170405",
      "display":"บ้านแป้ง"
      },
      {
      "code":"170402",
      "display":"พรหมบุรี"
      },
      {
      "code":"170401",
      "display":"พระงาม"
      },
      {
      "code":"170406",
      "display":"หัวป่า"
      },
      {
      "code":"170407",
      "display":"โรงช้าง"
      }
      ],
      "code":"1704",
      "display":"พรหมบุรี"
      },
      {
      "districts":[
      {
      "code":"170604",
      "display":"งิ้วราย"
      },
      {
      "code":"170605",
      "display":"ชีน้ำร้าย"
      },
      {
      "code":"170608",
      "display":"ทองเอน"
      },
      {
      "code":"170603",
      "display":"ทับยา"
      },
      {
      "code":"170606",
      "display":"ท่างาม"
      },
      {
      "code":"170607",
      "display":"น้ำตาล"
      },
      {
      "code":"170602",
      "display":"ประศุก"
      },
      {
      "code":"170609",
      "display":"ห้วยชัน"
      },
      {
      "code":"170601",
      "display":"อินทร์บุรี"
      },
      {
      "code":"170610",
      "display":"โพธิ์ชัย"
      }
      ],
      "code":"1706",
      "display":"อินทร์บุรี"
      },
      {
      "districts":[
      {
      "code":"170107",
      "display":"จักรสีห์"
      },
      {
      "code":"170106",
      "display":"ต้นโพธิ์"
      },
      {
      "code":"170108",
      "display":"บางกระบือ"
      },
      {
      "code":"170101",
      "display":"บางพุทรา"
      },
      {
      "code":"170102",
      "display":"บางมัญ"
      },
      {
      "code":"170104",
      "display":"ม่วงหมู่"
      },
      {
      "code":"170105",
      "display":"หัวไผ่"
      },
      {
      "code":"170103",
      "display":"โพกรวม"
      }
      ],
      "code":"1701",
      "display":"เมืองสิงห์บุรี"
      }
      ],
      "display":"สิงห์บุรี"
      },
      "สุพรรณบุรี":{
      "sort_order":"59",
      "code":"72",
      "amphoe":[
      {
      "districts":[
      {
      "code":"720601",
      "display":"ดอนเจดีย์"
      },
      {
      "code":"720605",
      "display":"ทะเลบก"
      },
      {
      "code":"720604",
      "display":"สระกระโจม"
      },
      {
      "code":"720602",
      "display":"หนองสาหร่าย"
      },
      {
      "code":"720603",
      "display":"ไร่รถ"
      }
      ],
      "code":"7206",
      "display":"ดอนเจดีย์"
      },
      {
      "districts":[
      {
      "code":"720298",
      "display":"ด่านช้าง"
      },
      {
      "code":"720306",
      "display":"นิคมกระเสียว"
      },
      {
      "code":"720305",
      "display":"วังคัน"
      },
      {
      "code":"720307",
      "display":"วังยาว"
      },
      {
      "code":"720299",
      "display":"หนองมะค่าโมง"
      },
      {
      "code":"720297",
      "display":"ห้วยขมิ้น"
      },
      {
      "code":"720296",
      "display":"องค์พระ"
      }
      ],
      "code":"7203",
      "display":"ด่านช้าง"
      },
      {
      "districts":[
      {
      "code":"720405",
      "display":"กฤษณา"
      },
      {
      "code":"720409",
      "display":"จรเข้ใหญ่"
      },
      {
      "code":"720403",
      "display":"ตะค่า"
      },
      {
      "code":"720402",
      "display":"บางปลาม้า"
      },
      {
      "code":"720404",
      "display":"บางใหญ่"
      },
      {
      "code":"720410",
      "display":"บ้านแหลม"
      },
      {
      "code":"720411",
      "display":"มะขามล้ม"
      },
      {
      "code":"720412",
      "display":"วังน้ำเย็น"
      },
      {
      "code":"720414",
      "display":"วัดดาว"
      },
      {
      "code":"720413",
      "display":"วัดโบสถ์"
      },
      {
      "code":"720406",
      "display":"สาลี"
      },
      {
      "code":"720408",
      "display":"องครักษ์"
      },
      {
      "code":"720401",
      "display":"โคกคราม"
      },
      {
      "code":"720407",
      "display":"ไผ่กองดิน"
      }
      ],
      "code":"7204",
      "display":"บางปลาม้า"
      },
      {
      "districts":[
      {
      "code":"720505",
      "display":"ดอนปรู"
      },
      {
      "code":"720504",
      "display":"บางงาม"
      },
      {
      "code":"720502",
      "display":"บ้านกร่าง"
      },
      {
      "code":"720506",
      "display":"ปลายนา"
      },
      {
      "code":"720503",
      "display":"มดแดง"
      },
      {
      "code":"720508",
      "display":"วังน้ำซับ"
      },
      {
      "code":"720509",
      "display":"วังยาง"
      },
      {
      "code":"720507",
      "display":"วังหว้า"
      },
      {
      "code":"720501",
      "display":"ศรีประจันต์"
      }
      ],
      "code":"7205",
      "display":"ศรีประจันต์"
      },
      {
      "districts":[
      {
      "code":"720715",
      "display":"ดอนมะนาว"
      },
      {
      "code":"720710",
      "display":"ต้นตาล"
      },
      {
      "code":"720712",
      "display":"ทุ่งคอก"
      },
      {
      "code":"720704",
      "display":"บางตะเคียน"
      },
      {
      "code":"720703",
      "display":"บางตาเถร"
      },
      {
      "code":"720707",
      "display":"บางพลับ"
      },
      {
      "code":"720702",
      "display":"บางเลน"
      },
      {
      "code":"720714",
      "display":"บ่อสุพรรณ"
      },
      {
      "code":"720705",
      "display":"บ้านกุ่ม"
      },
      {
      "code":"720709",
      "display":"บ้านช้าง"
      },
      {
      "code":"720711",
      "display":"ศรีสำราญ"
      },
      {
      "code":"720701",
      "display":"สองพี่น้อง"
      },
      {
      "code":"720713",
      "display":"หนองบ่อ"
      },
      {
      "code":"720706",
      "display":"หัวโพธิ์"
      },
      {
      "code":"720708",
      "display":"เนินพระปรางค์"
      }
      ],
      "code":"7207",
      "display":"สองพี่น้อง"
      },
      {
      "districts":[
      {
      "code":"720807",
      "display":"กระเสียว"
      },
      {
      "code":"720805",
      "display":"บ้านสระ"
      },
      {
      "code":"720801",
      "display":"ย่านยาว"
      },
      {
      "code":"720802",
      "display":"วังลึก"
      },
      {
      "code":"720803",
      "display":"สามชุก"
      },
      {
      "code":"720804",
      "display":"หนองผักนาก"
      },
      {
      "code":"720806",
      "display":"หนองสะเดา"
      }
      ],
      "code":"7208",
      "display":"สามชุก"
      },
      {
      "districts":[
      {
      "code":"721006",
      "display":"ทัพหลวง"
      },
      {
      "code":"721005",
      "display":"หนองขาม"
      },
      {
      "code":"720898",
      "display":"หนองราชวัตร"
      },
      {
      "code":"720899",
      "display":"หนองหญ้าไซ"
      },
      {
      "code":"720897",
      "display":"หนองโพธิ์"
      },
      {
      "code":"720896",
      "display":"แจงงาม"
      }
      ],
      "code":"7210",
      "display":"หนองหญ้าไซ"
      },
      {
      "districts":[
      {
      "code":"720913",
      "display":"กระจัน"
      },
      {
      "code":"720903",
      "display":"จรเข้สามพัน"
      },
      {
      "code":"720908",
      "display":"ดอนคา"
      },
      {
      "code":"720906",
      "display":"ดอนมะเกลือ"
      },
      {
      "code":"720904",
      "display":"บ้านดอน"
      },
      {
      "code":"720910",
      "display":"บ้านโข้ง"
      },
      {
      "code":"720909",
      "display":"พลับพลาไชย"
      },
      {
      "code":"720905",
      "display":"ยุ้งทะลาย"
      },
      {
      "code":"720912",
      "display":"สระพังลาน"
      },
      {
      "code":"720902",
      "display":"สระยายโสม"
      },
      {
      "code":"720907",
      "display":"หนองโอ่ง"
      },
      {
      "code":"720901",
      "display":"อู่ทอง"
      },
      {
      "code":"720911",
      "display":"เจดีย์"
      }
      ],
      "code":"7209",
      "display":"อู่ทอง"
      },
      {
      "districts":[
      {
      "code":"720206",
      "display":"ทุ่งคลี"
      },
      {
      "code":"720203",
      "display":"นางบวช"
      },
      {
      "code":"720210",
      "display":"บ่อกรุ"
      },
      {
      "code":"720205",
      "display":"ปากน้ำ"
      },
      {
      "code":"720212",
      "display":"ป่าสะแก"
      },
      {
      "code":"720213",
      "display":"ยางนอน"
      },
      {
      "code":"720211",
      "display":"วังศรีราช"
      },
      {
      "code":"720214",
      "display":"หนองกระทุ่ม"
      },
      {
      "code":"720209",
      "display":"หัวนา"
      },
      {
      "code":"720208",
      "display":"หัวเขา"
      },
      {
      "code":"720204",
      "display":"เขาดิน"
      },
      {
      "code":"720201",
      "display":"เขาพระ"
      },
      {
      "code":"720202",
      "display":"เดิมบาง"
      },
      {
      "code":"720207",
      "display":"โคกช้าง"
      }
      ],
      "code":"7202",
      "display":"เดิมบางนางบวช"
      },
      {
      "districts":[
      {
      "code":"720110",
      "display":"ดอนกำยาน"
      },
      {
      "code":"720107",
      "display":"ดอนตาล"
      },
      {
      "code":"720108",
      "display":"ดอนมะสังข์"
      },
      {
      "code":"720111",
      "display":"ดอนโพธิ์ทอง"
      },
      {
      "code":"720114",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"720103",
      "display":"ทับตีเหล็ก"
      },
      {
      "code":"720101",
      "display":"ท่าพี่เลี้ยง"
      },
      {
      "code":"720104",
      "display":"ท่าระหัด"
      },
      {
      "code":"720115",
      "display":"บางกุ้ง"
      },
      {
      "code":"720112",
      "display":"บ้านโพธิ์"
      },
      {
      "code":"720109",
      "display":"พิหารแดง"
      },
      {
      "code":"720102",
      "display":"รั้วใหญ่"
      },
      {
      "code":"720116",
      "display":"ศาลาขาว"
      },
      {
      "code":"720120",
      "display":"สนามคลี"
      },
      {
      "code":"720118",
      "display":"สนามชัย"
      },
      {
      "code":"720113",
      "display":"สระแก้ว"
      },
      {
      "code":"720117",
      "display":"สวนแตง"
      },
      {
      "code":"720106",
      "display":"โคกโคเฒ่า"
      },
      {
      "code":"720119",
      "display":"โพธิ์พระยา"
      },
      {
      "code":"720105",
      "display":"ไผ่ขวาง"
      }
      ],
      "code":"7201",
      "display":"เมืองสุพรรณบุรี"
      }
      ],
      "display":"สุพรรณบุรี"
      },
      "สุราษฎร์ธานี":{
      "sort_order":"60",
      "code":"84",
      "amphoe":[
      {
      "districts":[
      {
      "code":"840205",
      "display":"กรูด"
      },
      {
      "code":"840203",
      "display":"กะแดะ"
      },
      {
      "code":"840213",
      "display":"คลองสระ"
      },
      {
      "code":"840210",
      "display":"ช้างขวา"
      },
      {
      "code":"840206",
      "display":"ช้างซ้าย"
      },
      {
      "code":"840209",
      "display":"ตะเคียนทอง"
      },
      {
      "code":"840204",
      "display":"ทุ่งกง"
      },
      {
      "code":"840212",
      "display":"ทุ่งรัง"
      },
      {
      "code":"840202",
      "display":"ท่าทอง"
      },
      {
      "code":"840201",
      "display":"ท่าทองใหม่"
      },
      {
      "code":"840211",
      "display":"ท่าอุแท"
      },
      {
      "code":"840208",
      "display":"ป่าร่อน"
      },
      {
      "code":"840207",
      "display":"พลายวาส"
      }
      ],
      "code":"8402",
      "display":"กาญจนดิษฐ์"
      },
      {
      "districts":[
      {
      "code":"840806",
      "display":"กะเปา"
      },
      {
      "code":"840809",
      "display":"ถ้ำสิงขร"
      },
      {
      "code":"840807",
      "display":"ท่ากระดาน"
      },
      {
      "code":"840801",
      "display":"ท่าขนอน"
      },
      {
      "code":"840803",
      "display":"น้ำหัก"
      },
      {
      "code":"840810",
      "display":"บ้านทำเนียบ"
      },
      {
      "code":"840802",
      "display":"บ้านยาง"
      },
      {
      "code":"840808",
      "display":"ย่านยาว"
      }
      ],
      "code":"8408",
      "display":"คีรีรัฐนิคม"
      },
      {
      "districts":[
      {
      "code":"840107",
      "display":"คลองน้อย"
      },
      {
      "code":"841698",
      "display":"ชัยบุรี"
      },
      {
      "code":"841699",
      "display":"สองแพรก"
      },
      {
      "code":"841804",
      "display":"ไทรทอง"
      }
      ],
      "code":"8418",
      "display":"ชัยบุรี"
      },
      {
      "districts":[
      {
      "code":"840302",
      "display":"ชลคราม"
      },
      {
      "code":"840301",
      "display":"ดอนสัก"
      },
      {
      "code":"840304",
      "display":"ปากแพรก"
      },
      {
      "code":"840303",
      "display":"ไชยคราม"
      }
      ],
      "code":"8403",
      "display":"ดอนสัก"
      },
      {
      "districts":[
      {
      "code":"841103",
      "display":"คลองไทร"
      },
      {
      "code":"841101",
      "display":"ท่าฉาง"
      },
      {
      "code":"841102",
      "display":"ท่าเคย"
      },
      {
      "code":"841106",
      "display":"ปากฉลุย"
      },
      {
      "code":"841104",
      "display":"เขาถ่าน"
      },
      {
      "code":"841105",
      "display":"เสวียด"
      }
      ],
      "code":"8411",
      "display":"ท่าฉาง"
      },
      {
      "districts":[
      {
      "code":"840706",
      "display":"คลองพา"
      },
      {
      "code":"840704",
      "display":"คันธุลี"
      },
      {
      "code":"840701",
      "display":"ท่าชนะ"
      },
      {
      "code":"840703",
      "display":"ประสงค์"
      },
      {
      "code":"840705",
      "display":"วัง"
      },
      {
      "code":"840702",
      "display":"สมอทอง"
      }
      ],
      "code":"8407",
      "display":"ท่าชนะ"
      },
      {
      "districts":[
      {
      "code":"840903",
      "display":"พรุไทย"
      },
      {
      "code":"840902",
      "display":"พะแสง"
      },
      {
      "code":"840904",
      "display":"เขาพัง"
      },
      {
      "code":"840901",
      "display":"เขาวง"
      }
      ],
      "code":"8409",
      "display":"บ้านตาขุน"
      },
      {
      "districts":[
      {
      "code":"841208",
      "display":"คลองปราบ"
      },
      {
      "code":"841206",
      "display":"ควนศรี"
      },
      {
      "code":"841207",
      "display":"ควนสุบรรณ"
      },
      {
      "code":"841203",
      "display":"ทุ่งเตา"
      },
      {
      "code":"841210",
      "display":"ทุ่งเตาใหม่"
      },
      {
      "code":"841205",
      "display":"ท่าชี"
      },
      {
      "code":"841201",
      "display":"นาสาร"
      },
      {
      "code":"841209",
      "display":"น้ำพุ"
      },
      {
      "code":"841202",
      "display":"พรุพี"
      },
      {
      "code":"841204",
      "display":"ลำพูน"
      },
      {
      "code":"841211",
      "display":"เพิ่มพูนทรัพย์"
      }
      ],
      "code":"8412",
      "display":"บ้านนาสาร"
      },
      {
      "districts":[
      {
      "code":"841303",
      "display":"ทรัพย์ทวี"
      },
      {
      "code":"841298",
      "display":"ท่าเรือ"
      },
      {
      "code":"841304",
      "display":"นาใต้"
      },
      {
      "code":"841299",
      "display":"บ้านนา"
      }
      ],
      "code":"8413",
      "display":"บ้านนาเดิม"
      },
      {
      "districts":[
      {
      "code":"841006",
      "display":"คลองชะอุ่น"
      },
      {
      "code":"841003",
      "display":"คลองศก"
      },
      {
      "code":"841002",
      "display":"ต้นยวน"
      },
      {
      "code":"841001",
      "display":"พนม"
      },
      {
      "code":"841004",
      "display":"พลูเถื่อน"
      },
      {
      "code":"841005",
      "display":"พังกาญจน์"
      }
      ],
      "code":"8410",
      "display":"พนม"
      },
      {
      "districts":[
      {
      "code":"841603",
      "display":"บางสวรรค์"
      },
      {
      "code":"841607",
      "display":"สาคู"
      },
      {
      "code":"841602",
      "display":"สินปุน"
      },
      {
      "code":"841605",
      "display":"สินเจริญ"
      },
      {
      "code":"841601",
      "display":"อิปัน"
      },
      {
      "code":"841604",
      "display":"ไทรขึง"
      },
      {
      "code":"841606",
      "display":"ไทรโสภา"
      }
      ],
      "code":"8416",
      "display":"พระแสง"
      },
      {
      "districts":[
      {
      "code":"840205",
      "display":"กรูด"
      },
      {
      "code":"841716",
      "display":"ตะปาน"
      },
      {
      "code":"841701",
      "display":"ท่าข้าม"
      },
      {
      "code":"841702",
      "display":"ท่าสะท้อน"
      },
      {
      "code":"841706",
      "display":"ท่าโรงช้าง"
      },
      {
      "code":"841711",
      "display":"น้ำรอบ"
      },
      {
      "code":"841709",
      "display":"บางงอน"
      },
      {
      "code":"841704",
      "display":"บางมะเดื่อ"
      },
      {
      "code":"841705",
      "display":"บางเดือน"
      },
      {
      "code":"841708",
      "display":"พุนพิน"
      },
      {
      "code":"841712",
      "display":"มะลวน"
      },
      {
      "code":"841703",
      "display":"ลีเล็ด"
      },
      {
      "code":"841710",
      "display":"ศรีวิชัย"
      },
      {
      "code":"841714",
      "display":"หนองไทร"
      },
      {
      "code":"841713",
      "display":"หัวเตย"
      },
      {
      "code":"841715",
      "display":"เขาหัวควาย"
      }
      ],
      "code":"8417",
      "display":"พุนพิน"
      },
      {
      "districts":[
      {
      "code":"840805",
      "display":"ตะกุกเหนือ"
      },
      {
      "code":"840804",
      "display":"ตะกุกใต้"
      }
      ],
      "code":"8419",
      "display":"วิภาวดี"
      },
      {
      "districts":[
      {
      "code":"840502",
      "display":"บ้านใต้"
      },
      {
      "code":"840501",
      "display":"เกาะพะงัน"
      },
      {
      "code":"840503",
      "display":"เกาะเต่า"
      },
      {
      "code":"840503",
      "display":"เกาะเต่า"
      }
      ],
      "code":"8405",
      "display":"เกาะพะงัน"
      },
      {
      "districts":[
      {
      "code":"840403",
      "display":"ตลิ่งงาม"
      },
      {
      "code":"840406",
      "display":"บ่อผุด"
      },
      {
      "code":"840405",
      "display":"มะเร็ต"
      },
      {
      "code":"840402",
      "display":"ลิปะน้อย"
      },
      {
      "code":"840404",
      "display":"หน้าเมือง"
      },
      {
      "code":"840401",
      "display":"อ่างทอง"
      },
      {
      "code":"840407",
      "display":"แม่น้ำ"
      }
      ],
      "code":"8404",
      "display":"เกาะสมุย"
      },
      {
      "districts":[
      {
      "code":"841405",
      "display":"บ้านเสด็จ"
      },
      {
      "code":"841402",
      "display":"พ่วงพรมคร"
      },
      {
      "code":"841404",
      "display":"อรัญคามวารี"
      },
      {
      "code":"841403",
      "display":"เขาตอก"
      },
      {
      "code":"841401",
      "display":"เคียนซา"
      }
      ],
      "code":"8414",
      "display":"เคียนซา"
      },
      {
      "districts":[
      {
      "code":"840104",
      "display":"ขุนทะเล"
      },
      {
      "code":"840111",
      "display":"คลองฉนาก"
      },
      {
      "code":"840107",
      "display":"คลองน้อย"
      },
      {
      "code":"840101",
      "display":"ตลาด"
      },
      {
      "code":"840110",
      "display":"บางกุ้ง"
      },
      {
      "code":"840106",
      "display":"บางชนะ"
      },
      {
      "code":"840109",
      "display":"บางโพธิ์"
      },
      {
      "code":"840105",
      "display":"บางใบไม้"
      },
      {
      "code":"840108",
      "display":"บางไทร"
      },
      {
      "code":"840102",
      "display":"มะขามเตี้ย"
      },
      {
      "code":"840103",
      "display":"วัดประดู่"
      }
      ],
      "code":"8401",
      "display":"เมืองสุราษฎร์ธานี"
      },
      {
      "districts":[
      {
      "code":"841503",
      "display":"คลองฉนวน"
      },
      {
      "code":"841504",
      "display":"ทุ่งหลวง"
      },
      {
      "code":"841502",
      "display":"บ้านส้อง"
      },
      {
      "code":"841505",
      "display":"เขานิพันธ์"
      },
      {
      "code":"841501",
      "display":"เวียงสระ"
      }
      ],
      "code":"8415",
      "display":"เวียงสระ"
      },
      {
      "districts":[
      {
      "code":"840601",
      "display":"ตลาดไชยา"
      },
      {
      "code":"840607",
      "display":"ตะกรบ"
      },
      {
      "code":"840605",
      "display":"ทุ่ง"
      },
      {
      "code":"840609",
      "display":"ปากหมาก"
      },
      {
      "code":"840606",
      "display":"ป่าเว"
      },
      {
      "code":"840602",
      "display":"พุมเรียง"
      },
      {
      "code":"840603",
      "display":"เลม็ด"
      },
      {
      "code":"840604",
      "display":"เวียง"
      },
      {
      "code":"840608",
      "display":"โมถ่าย"
      }
      ],
      "code":"8406",
      "display":"ไชยา"
      }
      ],
      "display":"สุราษฎร์ธานี"
      },
      "สุรินทร์":{
      "sort_order":"61",
      "code":"32",
      "amphoe":[
      {
      "districts":[
      {
      "code":"320599",
      "display":"กาบเชิง"
      },
      {
      "code":"320596",
      "display":"คูตัน"
      },
      {
      "code":"320595",
      "display":"ด่าน"
      },
      {
      "code":"320610",
      "display":"ตะเคียน"
      },
      {
      "code":"320606",
      "display":"แนงมุด"
      },
      {
      "code":"320607",
      "display":"โคกตะเคียน"
      }
      ],
      "code":"3206",
      "display":"กาบเชิง"
      },
      {
      "districts":[
      {
      "code":"320403",
      "display":"กระหาด"
      },
      {
      "code":"320401",
      "display":"จอมพระ"
      },
      {
      "code":"320408",
      "display":"ชุมแสง"
      },
      {
      "code":"320404",
      "display":"บุแกรง"
      },
      {
      "code":"320406",
      "display":"บ้านผือ"
      },
      {
      "code":"320407",
      "display":"ลุ่มระวี"
      },
      {
      "code":"320405",
      "display":"หนองสนิท"
      },
      {
      "code":"320409",
      "display":"เป็นสุข"
      },
      {
      "code":"320402",
      "display":"เมืองลีง"
      }
      ],
      "code":"3204",
      "display":"จอมพระ"
      },
      {
      "districts":[
      {
      "code":"320208",
      "display":"กระเบื้อง"
      },
      {
      "code":"320201",
      "display":"ชุมพลบุรี"
      },
      {
      "code":"320202",
      "display":"นาหนองไผ่"
      },
      {
      "code":"320205",
      "display":"ยะวึก"
      },
      {
      "code":"320204",
      "display":"ศรีณรงค์"
      },
      {
      "code":"320207",
      "display":"สระขุด"
      },
      {
      "code":"320209",
      "display":"หนองเรือ"
      },
      {
      "code":"320206",
      "display":"เมืองบัว"
      },
      {
      "code":"320203",
      "display":"ไพรขลา"
      }
      ],
      "code":"3202",
      "display":"ชุมพลบุรี"
      },
      {
      "districts":[
      {
      "code":"320302",
      "display":"กระโพ"
      },
      {
      "code":"320310",
      "display":"ทุ่งกุลา"
      },
      {
      "code":"320301",
      "display":"ท่าตูม"
      },
      {
      "code":"320306",
      "display":"บะ"
      },
      {
      "code":"320308",
      "display":"บัวโคก"
      },
      {
      "code":"320303",
      "display":"พรมเทพ"
      },
      {
      "code":"320307",
      "display":"หนองบัว"
      },
      {
      "code":"320309",
      "display":"หนองเมธี"
      },
      {
      "code":"320305",
      "display":"เมืองแก"
      },
      {
      "code":"320304",
      "display":"โพนครก"
      }
      ],
      "code":"3203",
      "display":"ท่าตูม"
      },
      {
      "districts":[
      {
      "code":"321303",
      "display":"จรัส"
      },
      {
      "code":"321304",
      "display":"ตาวัง"
      },
      {
      "code":"321301",
      "display":"บัวเชด"
      },
      {
      "code":"321302",
      "display":"สะเดา"
      },
      {
      "code":"321306",
      "display":"สำเภาลูน"
      },
      {
      "code":"321305",
      "display":"อาโพน"
      }
      ],
      "code":"3213",
      "display":"บัวเชด"
      },
      {
      "districts":[
      {
      "code":"320501",
      "display":"กังแอน"
      },
      {
      "code":"320516",
      "display":"กันตวจระมวล"
      },
      {
      "code":"320514",
      "display":"ตานี"
      },
      {
      "code":"320506",
      "display":"ตาเบา"
      },
      {
      "code":"320502",
      "display":"ทมอ"
      },
      {
      "code":"320505",
      "display":"ทุ่งมน"
      },
      {
      "code":"320515",
      "display":"บ้านพลวง"
      },
      {
      "code":"320510",
      "display":"บ้านไทร"
      },
      {
      "code":"320518",
      "display":"ประทัดบุ"
      },
      {
      "code":"320513",
      "display":"ปราสาททนง"
      },
      {
      "code":"320504",
      "display":"ปรือ"
      },
      {
      "code":"320517",
      "display":"สมุด"
      },
      {
      "code":"320507",
      "display":"หนองใหญ่"
      },
      {
      "code":"320512",
      "display":"เชื้อเพลิง"
      },
      {
      "code":"320508",
      "display":"โคกยาง"
      },
      {
      "code":"320509",
      "display":"โคกสะอาด"
      },
      {
      "code":"320511",
      "display":"โชคนาสาม"
      },
      {
      "code":"320503",
      "display":"ไพล"
      }
      ],
      "code":"3205",
      "display":"ปราสาท"
      },
      {
      "districts":[
      {
      "code":"320609",
      "display":"จีกแดก"
      },
      {
      "code":"320608",
      "display":"ตาเมียง"
      },
      {
      "code":"320598",
      "display":"บักได"
      },
      {
      "code":"320597",
      "display":"โคกกลาง"
      }
      ],
      "code":"3214",
      "display":"พนมดงรัก"
      },
      {
      "districts":[
      {
      "code":"320714",
      "display":"กุดขาคีม"
      },
      {
      "code":"320704",
      "display":"ดอนแรด"
      },
      {
      "code":"320716",
      "display":"ทับใหญ่"
      },
      {
      "code":"320702",
      "display":"ธาตุ"
      },
      {
      "code":"320713",
      "display":"น้ำเขียว"
      },
      {
      "code":"320715",
      "display":"ยางสว่าง"
      },
      {
      "code":"320701",
      "display":"รัตนบุรี"
      },
      {
      "code":"320705",
      "display":"หนองบัวทอง"
      },
      {
      "code":"320706",
      "display":"หนองบัวบาน"
      },
      {
      "code":"320711",
      "display":"เบิด"
      },
      {
      "code":"320703",
      "display":"แก"
      },
      {
      "code":"320709",
      "display":"ไผ่"
      }
      ],
      "code":"3207",
      "display":"รัตนบุรี"
      },
      {
      "districts":[
      {
      "code":"321105",
      "display":"ตระเปียงเตีย"
      },
      {
      "code":"321104",
      "display":"ตรำดม"
      },
      {
      "code":"321101",
      "display":"ลำดวน"
      },
      {
      "code":"321103",
      "display":"อู่โลก"
      },
      {
      "code":"321102",
      "display":"โชคเหนือ"
      }
      ],
      "code":"3211",
      "display":"ลำดวน"
      },
      {
      "districts":[
      {
      "code":"321003",
      "display":"ณรงค์"
      },
      {
      "code":"321005",
      "display":"ตรวจ"
      },
      {
      "code":"321014",
      "display":"ศรีสุข"
      },
      {
      "code":"321016",
      "display":"หนองแวง"
      },
      {
      "code":"321004",
      "display":"แจนแวน"
      }
      ],
      "code":"3215",
      "display":"ศรีณรงค์"
      },
      {
      "districts":[
      {
      "code":"320911",
      "display":"กุดหวาย"
      },
      {
      "code":"320912",
      "display":"ขวาวใหญ่"
      },
      {
      "code":"320907",
      "display":"คาละแมะ"
      },
      {
      "code":"320903",
      "display":"จารพัต"
      },
      {
      "code":"320910",
      "display":"ช่างปี่"
      },
      {
      "code":"320914",
      "display":"ตรมไพร"
      },
      {
      "code":"320902",
      "display":"ตรึม"
      },
      {
      "code":"320913",
      "display":"นารุ่ง"
      },
      {
      "code":"320915",
      "display":"ผักไหม"
      },
      {
      "code":"320904",
      "display":"ยาง"
      },
      {
      "code":"320901",
      "display":"ระแงง"
      },
      {
      "code":"320909",
      "display":"หนองขวาว"
      },
      {
      "code":"320307",
      "display":"หนองบัว"
      },
      {
      "code":"320908",
      "display":"หนองเหล็ก"
      },
      {
      "code":"320905",
      "display":"แตล"
      }
      ],
      "code":"3209",
      "display":"ศีขรภูมิ"
      },
      {
      "districts":[
      {
      "code":"320797",
      "display":"นานวน"
      },
      {
      "code":"320795",
      "display":"สนม"
      },
      {
      "code":"320796",
      "display":"หนองระฆัง"
      },
      {
      "code":"320807",
      "display":"หนองอียอ"
      },
      {
      "code":"320806",
      "display":"หัวงัว"
      },
      {
      "code":"320799",
      "display":"แคน"
      },
      {
      "code":"320798",
      "display":"โพนโก"
      }
      ],
      "code":"3208",
      "display":"สนม"
      },
      {
      "districts":[
      {
      "code":"321009",
      "display":"กระเทียม"
      },
      {
      "code":"321002",
      "display":"ขอนแตก"
      },
      {
      "code":"321006",
      "display":"ดม"
      },
      {
      "code":"321013",
      "display":"ตาคง"
      },
      {
      "code":"321011",
      "display":"ตาตุม"
      },
      {
      "code":"321012",
      "display":"ทับทัน"
      },
      {
      "code":"321008",
      "display":"บ้านจารย์"
      },
      {
      "code":"321015",
      "display":"บ้านชบ"
      },
      {
      "code":"321007",
      "display":"พระแก้ว"
      },
      {
      "code":"321010",
      "display":"สะกาด"
      },
      {
      "code":"321001",
      "display":"สังขะ"
      },
      {
      "code":"321017",
      "display":"เทพรักษา"
      }
      ],
      "code":"3210",
      "display":"สังขะ"
      },
      {
      "districts":[
      {
      "code":"321203",
      "display":"กระออม"
      },
      {
      "code":"321210",
      "display":"ประดู่"
      },
      {
      "code":"321014",
      "display":"ศรีสุข"
      },
      {
      "code":"321209",
      "display":"สะโน"
      },
      {
      "code":"321201",
      "display":"สำโรงทาบ"
      },
      {
      "code":"321204",
      "display":"หนองฮะ"
      },
      {
      "code":"321202",
      "display":"หนองไผ่ล้อม"
      },
      {
      "code":"321207",
      "display":"หมื่นศรี"
      },
      {
      "code":"321206",
      "display":"เกาะแก้ว"
      },
      {
      "code":"321208",
      "display":"เสม็จ"
      }
      ],
      "code":"3212",
      "display":"สำโรงทาบ"
      },
      {
      "districts":[
      {
      "code":"320108",
      "display":"ตากูก"
      },
      {
      "code":"320115",
      "display":"บึง"
      },
      {
      "code":"320123",
      "display":"บ้านแร่"
      },
      {
      "code":"320124",
      "display":"ปราสาททอง"
      },
      {
      "code":"320117",
      "display":"เขวาสินรินทร์"
      }
      ],
      "code":"3216",
      "display":"เขวาสินรินทร์"
      },
      {
      "districts":[
      {
      "code":"320126",
      "display":"กาเกาะ"
      },
      {
      "code":"320112",
      "display":"คอโค"
      },
      {
      "code":"320122",
      "display":"ตระแสง"
      },
      {
      "code":"320102",
      "display":"ตั้งใจ"
      },
      {
      "code":"320107",
      "display":"ตาอ็อง"
      },
      {
      "code":"320105",
      "display":"ท่าสว่าง"
      },
      {
      "code":"320111",
      "display":"นอกเมือง"
      },
      {
      "code":"320104",
      "display":"นาดี"
      },
      {
      "code":"320118",
      "display":"นาบัว"
      },
      {
      "code":"320121",
      "display":"บุฤาษี"
      },
      {
      "code":"320120",
      "display":"ราม"
      },
      {
      "code":"320106",
      "display":"สลักได"
      },
      {
      "code":"320113",
      "display":"สวาย"
      },
      {
      "code":"320109",
      "display":"สำโรง"
      },
      {
      "code":"320114",
      "display":"เฉนียง"
      },
      {
      "code":"320116",
      "display":"เทนมีย์"
      },
      {
      "code":"320103",
      "display":"เพี้ยราม"
      },
      {
      "code":"320119",
      "display":"เมืองที"
      },
      {
      "code":"320110",
      "display":"แกใหญ่"
      },
      {
      "code":"320125",
      "display":"แสลงพันธ์"
      },
      {
      "code":"320101",
      "display":"ในเมือง"
      }
      ],
      "code":"3201",
      "display":"เมืองสุรินทร์"
      },
      {
      "districts":[
      {
      "code":"320717",
      "display":"คำผง"
      },
      {
      "code":"320712",
      "display":"ระเวียง"
      },
      {
      "code":"320707",
      "display":"หนองหลวง"
      },
      {
      "code":"320708",
      "display":"หนองเทพ"
      },
      {
      "code":"320710",
      "display":"โนน"
      }
      ],
      "code":"3217",
      "display":"โนนนารายณ์"
      }
      ],
      "display":"สุรินทร์"
      },
      "สุโขทัย":{
      "sort_order":"62",
      "code":"64",
      "amphoe":[
      {
      "districts":[
      {
      "code":"640408",
      "display":"กกแรต"
      },
      {
      "code":"640401",
      "display":"กง"
      },
      {
      "code":"640406",
      "display":"ดงเดือย"
      },
      {
      "code":"640409",
      "display":"ท่าฉนวน"
      },
      {
      "code":"640402",
      "display":"บ้านกร่าง"
      },
      {
      "code":"640411",
      "display":"บ้านใหม่สุขเกษม"
      },
      {
      "code":"640407",
      "display":"ป่าแฝก"
      },
      {
      "code":"640410",
      "display":"หนองตูม"
      },
      {
      "code":"640404",
      "display":"ไกรกลาง"
      },
      {
      "code":"640403",
      "display":"ไกรนอก"
      },
      {
      "code":"640405",
      "display":"ไกรใน"
      }
      ],
      "code":"6404",
      "display":"กงไกรลาศ"
      },
      {
      "districts":[
      {
      "code":"640310",
      "display":"ทุ่งยางเมือง"
      },
      {
      "code":"640302",
      "display":"ทุ่งหลวง"
      },
      {
      "code":"640307",
      "display":"นาเชิงคีรี"
      },
      {
      "code":"640309",
      "display":"บ้านน้ำพุ"
      },
      {
      "code":"640303",
      "display":"บ้านป้อม"
      },
      {
      "code":"640305",
      "display":"ศรีคีรีมาศ"
      },
      {
      "code":"640304",
      "display":"สามพวง"
      },
      {
      "code":"640308",
      "display":"หนองกระดิ่ง"
      },
      {
      "code":"640306",
      "display":"หนองจิก"
      },
      {
      "code":"640301",
      "display":"โตนด"
      }
      ],
      "code":"6403",
      "display":"คีรีมาศ"
      },
      {
      "districts":[
      {
      "code":"640904",
      "display":"กลางดง"
      },
      {
      "code":"640903",
      "display":"ทุ่งเสลี่ยม"
      },
      {
      "code":"640901",
      "display":"บ้านใหม่ไชยมงคล"
      },
      {
      "code":"640905",
      "display":"เขาแก้วศรีสมบูรณ์"
      },
      {
      "code":"640902",
      "display":"ไทยชนะศึก"
      }
      ],
      "code":"6409",
      "display":"ทุ่งเสลี่ยม"
      },
      {
      "districts":[
      {
      "code":"640205",
      "display":"ตลิ่งชัน"
      },
      {
      "code":"640202",
      "display":"บ้านด่าน"
      },
      {
      "code":"640201",
      "display":"ลานหอย"
      },
      {
      "code":"640203",
      "display":"วังตะคร้อ"
      },
      {
      "code":"640204",
      "display":"วังน้ำขาว"
      },
      {
      "code":"640207",
      "display":"วังลึก"
      },
      {
      "code":"640206",
      "display":"หนองหญ้าปล้อง"
      }
      ],
      "code":"6402",
      "display":"บ้านด่านลานหอย"
      },
      {
      "districts":[
      {
      "code":"640796",
      "display":"คลองมะพลับ"
      },
      {
      "code":"640798",
      "display":"นครเดิฐ"
      },
      {
      "code":"640797",
      "display":"น้ำขุม"
      },
      {
      "code":"640799",
      "display":"ศรีนคร"
      },
      {
      "code":"640805",
      "display":"หนองบัว"
      }
      ],
      "code":"6408",
      "display":"ศรีนคร"
      },
      {
      "districts":[
      {
      "code":"640509",
      "display":"ดงคู่"
      },
      {
      "code":"640507",
      "display":"ท่าชัย"
      },
      {
      "code":"640505",
      "display":"บ้านตึก"
      },
      {
      "code":"640510",
      "display":"บ้านแก่ง"
      },
      {
      "code":"640502",
      "display":"ป่างิ้ว"
      },
      {
      "code":"640508",
      "display":"ศรีสัชนาลัย"
      },
      {
      "code":"640511",
      "display":"สารจิตร"
      },
      {
      "code":"640506",
      "display":"หนองอ้อ"
      },
      {
      "code":"640501",
      "display":"หาดเสี้ยว"
      },
      {
      "code":"640503",
      "display":"แม่สำ"
      },
      {
      "code":"640504",
      "display":"แม่สิน"
      }
      ],
      "code":"6405",
      "display":"ศรีสัชนาลัย"
      },
      {
      "districts":[
      {
      "code":"640601",
      "display":"คลองตาล"
      },
      {
      "code":"640610",
      "display":"ทับผึ้ง"
      },
      {
      "code":"640606",
      "display":"นาขุนไกร"
      },
      {
      "code":"640611",
      "display":"บ้านซ่าน"
      },
      {
      "code":"640604",
      "display":"บ้านนา"
      },
      {
      "code":"640609",
      "display":"บ้านไร่"
      },
      {
      "code":"640613",
      "display":"ราวต้นจันทร์"
      },
      {
      "code":"640605",
      "display":"วังทอง"
      },
      {
      "code":"640207",
      "display":"วังลึก"
      },
      {
      "code":"640612",
      "display":"วังใหญ่"
      },
      {
      "code":"640608",
      "display":"วัดเกาะ"
      },
      {
      "code":"640603",
      "display":"สามเรือน"
      },
      {
      "code":"640607",
      "display":"เกาะตาเลี้ยง"
      }
      ],
      "code":"6406",
      "display":"ศรีสำโรง"
      },
      {
      "districts":[
      {
      "code":"640703",
      "display":"คลองกระจง"
      },
      {
      "code":"640708",
      "display":"คลองยาง"
      },
      {
      "code":"640710",
      "display":"ท่าทอง"
      },
      {
      "code":"640707",
      "display":"นาทุ่ง"
      },
      {
      "code":"640711",
      "display":"ปากน้ำ"
      },
      {
      "code":"640712",
      "display":"ป่ากุมเกาะ"
      },
      {
      "code":"640706",
      "display":"ย่านยาว"
      },
      {
      "code":"640704",
      "display":"วังพิณพาทย์"
      },
      {
      "code":"640705",
      "display":"วังไม้ขอน"
      },
      {
      "code":"640714",
      "display":"หนองกลับ"
      },
      {
      "code":"640713",
      "display":"เมืองบางขลัง"
      },
      {
      "code":"640709",
      "display":"เมืองบางยม"
      },
      {
      "code":"640701",
      "display":"เมืองสวรรคโลก"
      },
      {
      "code":"640702",
      "display":"ในเมือง"
      }
      ],
      "code":"6407",
      "display":"สวรรคโลก"
      },
      {
      "districts":[
      {
      "code":"640108",
      "display":"ตาลเตี้ย"
      },
      {
      "code":"640101",
      "display":"ธานี"
      },
      {
      "code":"640106",
      "display":"บ้านกล้วย"
      },
      {
      "code":"640102",
      "display":"บ้านสวน"
      },
      {
      "code":"640107",
      "display":"บ้านหลุม"
      },
      {
      "code":"640109",
      "display":"ปากพระ"
      },
      {
      "code":"640104",
      "display":"ปากแคว"
      },
      {
      "code":"640105",
      "display":"ยางซ้าย"
      },
      {
      "code":"640110",
      "display":"วังทองแดง"
      },
      {
      "code":"640103",
      "display":"เมืองเก่า"
      }
      ],
      "code":"6401",
      "display":"เมืองสุโขทัย"
      }
      ],
      "display":"สุโขทัย"
      },
      "หนองคาย":{
      "sort_order":"63",
      "code":"43",
      "amphoe":[
      {
      "districts":[
      {
      "code":"430203",
      "display":"กองนาง"
      },
      {
      "code":"430201",
      "display":"ท่าบ่อ"
      },
      {
      "code":"430208",
      "display":"นาข่า"
      },
      {
      "code":"430202",
      "display":"น้ำโมง"
      },
      {
      "code":"430206",
      "display":"บ้านถ่อน"
      },
      {
      "code":"430207",
      "display":"บ้านว่าน"
      },
      {
      "code":"430109",
      "display":"บ้านเดื่อ"
      },
      {
      "code":"430210",
      "display":"หนองนาง"
      },
      {
      "code":"430204",
      "display":"โคกคอน"
      },
      {
      "code":"430209",
      "display":"โพนสา"
      }
      ],
      "code":"4302",
      "display":"ท่าบ่อ"
      },
      {
      "districts":[
      {
      "code":"430514",
      "display":"นาทับไฮ"
      },
      {
      "code":"430520",
      "display":"บ้านต้อน"
      },
      {
      "code":"430516",
      "display":"พระบาทนาสิงห์"
      },
      {
      "code":"430505",
      "display":"รัตนวาปี"
      },
      {
      "code":"430511",
      "display":"โพนแพง"
      }
      ],
      "code":"4316",
      "display":"รัตนวาปี"
      },
      {
      "districts":[
      {
      "code":"430703",
      "display":"บ้านหม้อ"
      },
      {
      "code":"430704",
      "display":"พระพุทธบาท"
      },
      {
      "code":"430701",
      "display":"พานพร้าว"
      },
      {
      "code":"430705",
      "display":"หนองปลาปาก"
      }
      ],
      "code":"4307",
      "display":"ศรีเชียงใหม่"
      },
      {
      "districts":[
      {
      "code":"430115",
      "display":"คอกช้าง"
      },
      {
      "code":"430114",
      "display":"บ้านฝาง"
      },
      {
      "code":"430112",
      "display":"สระใคร"
      }
      ],
      "code":"4314",
      "display":"สระใคร"
      },
      {
      "districts":[
      {
      "code":"430804",
      "display":"นางิ้ว"
      },
      {
      "code":"430803",
      "display":"บ้านม่วง"
      },
      {
      "code":"430802",
      "display":"ผาตั้ง"
      },
      {
      "code":"430805",
      "display":"สังคม"
      },
      {
      "code":"430801",
      "display":"แก้งไก่"
      }
      ],
      "code":"4308",
      "display":"สังคม"
      },
      {
      "districts":[
      {
      "code":"430518",
      "display":"นาดี"
      },
      {
      "code":"430515",
      "display":"วังหลวง"
      },
      {
      "code":"430510",
      "display":"หนองหลวง"
      },
      {
      "code":"430517",
      "display":"อุดมพร"
      },
      {
      "code":"430512",
      "display":"เฝ้าไร่"
      }
      ],
      "code":"4315",
      "display":"เฝ้าไร่"
      },
      {
      "districts":[
      {
      "code":"430104",
      "display":"กวนวัน"
      },
      {
      "code":"430110",
      "display":"ค่ายบกหวาน"
      },
      {
      "code":"430109",
      "display":"บ้านเดื่อ"
      },
      {
      "code":"430117",
      "display":"ปะโค"
      },
      {
      "code":"430113",
      "display":"พระธาตุบังพวน"
      },
      {
      "code":"430102",
      "display":"มีชัย"
      },
      {
      "code":"430106",
      "display":"วัดธาตุ"
      },
      {
      "code":"430111",
      "display":"สองห้อง"
      },
      {
      "code":"430119",
      "display":"สีกาย"
      },
      {
      "code":"430116",
      "display":"หนองกอมเกาะ"
      },
      {
      "code":"430107",
      "display":"หาดคำ"
      },
      {
      "code":"430108",
      "display":"หินโงม"
      },
      {
      "code":"430118",
      "display":"เมืองหมี"
      },
      {
      "code":"430105",
      "display":"เวียงคุก"
      },
      {
      "code":"430103",
      "display":"โพธิ์ชัย"
      },
      {
      "code":"430101",
      "display":"ในเมือง"
      }
      ],
      "code":"4301",
      "display":"เมืองหนองคาย"
      },
      {
      "districts":[
      {
      "code":"430707",
      "display":"ด่านศรีสุข"
      },
      {
      "code":"430702",
      "display":"โพธิ์ตาก"
      },
      {
      "code":"430706",
      "display":"โพนทอง"
      }
      ],
      "code":"4317",
      "display":"โพธิ์ตาก"
      },
      {
      "districts":[
      {
      "code":"430503",
      "display":"กุดบง"
      },
      {
      "code":"430501",
      "display":"จุมพล"
      },
      {
      "code":"430504",
      "display":"ชุมช้าง"
      },
      {
      "code":"430506",
      "display":"ทุ่งหลวง"
      },
      {
      "code":"430508",
      "display":"นาหนัง"
      },
      {
      "code":"430521",
      "display":"บ้านผือ"
      },
      {
      "code":"430513",
      "display":"บ้านโพธิ์"
      },
      {
      "code":"430502",
      "display":"วัดหลวง"
      },
      {
      "code":"430522",
      "display":"สร้างนางขาว"
      },
      {
      "code":"430509",
      "display":"เซิม"
      },
      {
      "code":"430507",
      "display":"เหล่าต่างคำ"
      }
      ],
      "code":"4305",
      "display":"โพนพิสัย"
      }
      ],
      "display":"หนองคาย"
      },
      "หนองบัวลำภู":{
      "sort_order":"64",
      "code":"39",
      "amphoe":[
      {
      "districts":[
      {
      "code":"390205",
      "display":"กุดดินจี่"
      },
      {
      "code":"390213",
      "display":"กุดแห่"
      },
      {
      "code":"390211",
      "display":"ดงสวรรค์"
      },
      {
      "code":"390202",
      "display":"ด่านช้าง"
      },
      {
      "code":"390201",
      "display":"นากลาง"
      },
      {
      "code":"390206",
      "display":"ฝั่งแดง"
      },
      {
      "code":"390210",
      "display":"อุทัยสวรรค์"
      },
      {
      "code":"390207",
      "display":"เก่ากลอย"
      },
      {
      "code":"390209",
      "display":"โนนเมือง"
      }
      ],
      "code":"3902",
      "display":"นากลาง"
      },
      {
      "districts":[
      {
      "code":"390203",
      "display":"นาเหล่า"
      },
      {
      "code":"390204",
      "display":"นาแก"
      },
      {
      "code":"390208",
      "display":"วังทอง"
      },
      {
      "code":"390212",
      "display":"วังปลาป้อม"
      },
      {
      "code":"390214",
      "display":"เทพคีรี"
      }
      ],
      "code":"3906",
      "display":"นาวัง"
      },
      {
      "districts":[
      {
      "code":"390404",
      "display":"กุดสะเทียน"
      },
      {
      "code":"390411",
      "display":"ทรายทอง"
      },
      {
      "code":"390405",
      "display":"นากอก"
      },
      {
      "code":"390407",
      "display":"ยางหล่อ"
      },
      {
      "code":"390402",
      "display":"ศรีบุญเรือง"
      },
      {
      "code":"390409",
      "display":"หนองกุงแก้ว"
      },
      {
      "code":"390403",
      "display":"หนองบัวใต้"
      },
      {
      "code":"390410",
      "display":"หนองแก"
      },
      {
      "code":"390412",
      "display":"หันนางาม"
      },
      {
      "code":"390401",
      "display":"เมืองใหม่"
      },
      {
      "code":"390408",
      "display":"โนนม่วง"
      },
      {
      "code":"390406",
      "display":"โนนสะอาด"
      }
      ],
      "code":"3904",
      "display":"ศรีบุญเรือง"
      },
      {
      "districts":[
      {
      "code":"390508",
      "display":"กุดผึ้ง"
      },
      {
      "code":"390505",
      "display":"ดงมะไฟ"
      },
      {
      "code":"390296",
      "display":"นาดี"
      },
      {
      "code":"390504",
      "display":"นาด่าน"
      },
      {
      "code":"390297",
      "display":"นาสี"
      },
      {
      "code":"390507",
      "display":"บุญทัน"
      },
      {
      "code":"390298",
      "display":"บ้านโคก"
      },
      {
      "code":"390506",
      "display":"สุวรรณคูหา"
      }
      ],
      "code":"3905",
      "display":"สุวรรณคูหา"
      },
      {
      "districts":[
      {
      "code":"390111",
      "display":"กุดจิก"
      },
      {
      "code":"390113",
      "display":"นาคำไฮ"
      },
      {
      "code":"390107",
      "display":"นามะเฟือง"
      },
      {
      "code":"390106",
      "display":"บ้านขาม"
      },
      {
      "code":"390108",
      "display":"บ้านพร้าว"
      },
      {
      "code":"390114",
      "display":"ป่าไม้งาม"
      },
      {
      "code":"390110",
      "display":"ลำภู"
      },
      {
      "code":"390101",
      "display":"หนองบัว"
      },
      {
      "code":"390102",
      "display":"หนองภัยศูนย์"
      },
      {
      "code":"390104",
      "display":"หนองสวรรค์"
      },
      {
      "code":"390115",
      "display":"หนองหว้า"
      },
      {
      "code":"390105",
      "display":"หัวนา"
      },
      {
      "code":"390109",
      "display":"โนนขมิ้น"
      },
      {
      "code":"390112",
      "display":"โนนทัน"
      },
      {
      "code":"390103",
      "display":"โพธิ์ชัย"
      }
      ],
      "code":"3901",
      "display":"เมืองหนองบัวลำภู"
      },
      {
      "districts":[
      {
      "code":"390304",
      "display":"กุดดู่"
      },
      {
      "code":"390309",
      "display":"นิคมพัฒนา"
      },
      {
      "code":"390305",
      "display":"บ้านค้อ"
      },
      {
      "code":"390302",
      "display":"บ้านถิ่น"
      },
      {
      "code":"390310",
      "display":"ปางกู่"
      },
      {
      "code":"390303",
      "display":"หนองเรือ"
      },
      {
      "code":"390308",
      "display":"โคกม่วง"
      },
      {
      "code":"390307",
      "display":"โคกใหญ่"
      },
      {
      "code":"390301",
      "display":"โนนสัง"
      },
      {
      "code":"390209",
      "display":"โนนเมือง"
      }
      ],
      "code":"3903",
      "display":"โนนสัง"
      }
      ],
      "display":"หนองบัวลำภู"
      },
      "อำนาจเจริญ":{
      "sort_order":"65",
      "code":"37",
      "amphoe":[
      {
      "districts":[
      {
      "code":"370203",
      "display":"คำเขื่อนแก้ว"
      },
      {
      "code":"370201",
      "display":"ชานุมาน"
      },
      {
      "code":"370205",
      "display":"ป่าก่อ"
      },
      {
      "code":"370204",
      "display":"โคกก่ง"
      },
      {
      "code":"370202",
      "display":"โคกสาร"
      }
      ],
      "code":"3702",
      "display":"ชานุมาน"
      },
      {
      "districts":[
      {
      "code":"370302",
      "display":"คำโพน"
      },
      {
      "code":"370307",
      "display":"นาป่าแซง"
      },
      {
      "code":"370303",
      "display":"นาหว้า"
      },
      {
      "code":"370304",
      "display":"ลือ"
      },
      {
      "code":"370299",
      "display":"หนองข่า"
      },
      {
      "code":"370305",
      "display":"ห้วย"
      },
      {
      "code":"370306",
      "display":"โนนงาม"
      }
      ],
      "code":"3703",
      "display":"ปทุมราชวงศา"
      },
      {
      "districts":[
      {
      "code":"370402",
      "display":"จานลาน"
      },
      {
      "code":"370401",
      "display":"พนา"
      },
      {
      "code":"370404",
      "display":"พระเหลา"
      },
      {
      "code":"370403",
      "display":"ไม้กลอน"
      }
      ],
      "code":"3704",
      "display":"พนา"
      },
      {
      "districts":[
      {
      "code":"370199",
      "display":"ดงบัง"
      },
      {
      "code":"370191",
      "display":"ดงมะยาง"
      },
      {
      "code":"370198",
      "display":"อำนาจ"
      },
      {
      "code":"370192",
      "display":"เปือย"
      },
      {
      "code":"370706",
      "display":"แมด"
      },
      {
      "code":"370707",
      "display":"โคกกลาง"
      },
      {
      "code":"370195",
      "display":"ไร่ขี"
      }
      ],
      "code":"3707",
      "display":"ลืออำนาจ"
      },
      {
      "districts":[
      {
      "code":"370602",
      "display":"คำพระ"
      },
      {
      "code":"370607",
      "display":"จิกดู่"
      },
      {
      "code":"370608",
      "display":"รัตนวารี"
      },
      {
      "code":"370606",
      "display":"สร้างถ่อน้อย"
      },
      {
      "code":"370604",
      "display":"หนองแก้ว"
      },
      {
      "code":"370601",
      "display":"หัวตะพาน"
      },
      {
      "code":"370603",
      "display":"เค็งใหญ่"
      },
      {
      "code":"370605",
      "display":"โพนเมืองน้อย"
      }
      ],
      "code":"3706",
      "display":"หัวตะพาน"
      },
      {
      "districts":[
      {
      "code":"370116",
      "display":"กุดปลาดุก"
      },
      {
      "code":"370107",
      "display":"คึมใหญ่"
      },
      {
      "code":"370117",
      "display":"ดอนเมย"
      },
      {
      "code":"370103",
      "display":"นาจิก"
      },
      {
      "code":"370108",
      "display":"นาผือ"
      },
      {
      "code":"370118",
      "display":"นายม"
      },
      {
      "code":"370110",
      "display":"นาวัง"
      },
      {
      "code":"370111",
      "display":"นาหมอม้า"
      },
      {
      "code":"370119",
      "display":"นาแต้"
      },
      {
      "code":"370109",
      "display":"น้ำปลีก"
      },
      {
      "code":"370101",
      "display":"บุ่ง"
      },
      {
      "code":"370104",
      "display":"ปลาค้าว"
      },
      {
      "code":"370106",
      "display":"สร้างนกทา"
      },
      {
      "code":"370115",
      "display":"หนองมะแซว"
      },
      {
      "code":"370114",
      "display":"ห้วยไร่"
      },
      {
      "code":"370105",
      "display":"เหล่าพรวน"
      },
      {
      "code":"370113",
      "display":"โนนหนามแท่ง"
      },
      {
      "code":"370112",
      "display":"โนนโพธิ์"
      },
      {
      "code":"370102",
      "display":"ไก่คำ"
      }
      ],
      "code":"3701",
      "display":"เมืองอำนาจเจริญ"
      },
      {
      "districts":[
      {
      "code":"370194",
      "display":"นาเวียง"
      },
      {
      "code":"370506",
      "display":"หนองสามสี"
      },
      {
      "code":"370193",
      "display":"หนองไฮ"
      },
      {
      "code":"370197",
      "display":"เสนางคนิคม"
      },
      {
      "code":"370190",
      "display":"โพนทอง"
      },
      {
      "code":"370196",
      "display":"ไร่สีสุก"
      }
      ],
      "code":"3705",
      "display":"เสนางคนิคม"
      }
      ],
      "display":"อำนาจเจริญ"
      },
      "อุดรธานี":{
      "sort_order":"66",
      "code":"41",
      "amphoe":[
      {
      "districts":[
      {
      "code":"410198",
      "display":"กุดจับ"
      },
      {
      "code":"410190",
      "display":"ขอนยูง"
      },
      {
      "code":"410207",
      "display":"ตาลเลียน"
      },
      {
      "code":"410191",
      "display":"ปะโค"
      },
      {
      "code":"410205",
      "display":"สร้างก่อ"
      },
      {
      "code":"410197",
      "display":"เชียงเพ็ง"
      },
      {
      "code":"410206",
      "display":"เมืองเพีย"
      }
      ],
      "code":"4102",
      "display":"กุดจับ"
      },
      {
      "districts":[
      {
      "code":"410415",
      "display":"กุมภวาปี"
      },
      {
      "code":"410401",
      "display":"ตูมใต้"
      },
      {
      "code":"410414",
      "display":"ท่าลี่"
      },
      {
      "code":"410191",
      "display":"ปะโค"
      },
      {
      "code":"410413",
      "display":"ผาสุก"
      },
      {
      "code":"410402",
      "display":"พันดอน"
      },
      {
      "code":"410410",
      "display":"สีออ"
      },
      {
      "code":"410194",
      "display":"หนองหว้า"
      },
      {
      "code":"410407",
      "display":"ห้วยเกิ้ง"
      },
      {
      "code":"410406",
      "display":"เชียงแหว"
      },
      {
      "code":"410403",
      "display":"เวียงคำ"
      },
      {
      "code":"410409",
      "display":"เสอเพลอ"
      },
      {
      "code":"410404",
      "display":"แชแล"
      }
      ],
      "code":"4104",
      "display":"กุมภวาปี"
      },
      {
      "districts":[
      {
      "code":"410603",
      "display":"คอนสาย"
      },
      {
      "code":"410616",
      "display":"ค้อใหญ่"
      },
      {
      "code":"410604",
      "display":"บ้านจีต"
      },
      {
      "code":"410619",
      "display":"โนนทองอินทร์"
      }
      ],
      "code":"4124",
      "display":"กู่แก้ว"
      },
      {
      "districts":[
      {
      "code":"410697",
      "display":"ทุ่งฝน"
      },
      {
      "code":"410696",
      "display":"ทุ่งใหญ่"
      },
      {
      "code":"410703",
      "display":"นาชุมแสง"
      },
      {
      "code":"410704",
      "display":"นาทม"
      }
      ],
      "code":"4107",
      "display":"ทุ่งฝน"
      },
      {
      "districts":[
      {
      "code":"410906",
      "display":"นายูง"
      },
      {
      "code":"411804",
      "display":"นาแค"
      },
      {
      "code":"411809",
      "display":"บ้านก้อง"
      },
      {
      "code":"411705",
      "display":"โนนทอง"
      }
      ],
      "code":"4122",
      "display":"นายูง"
      },
      {
      "districts":[
      {
      "code":"411801",
      "display":"นางัว"
      },
      {
      "code":"411802",
      "display":"น้ำโสม"
      },
      {
      "code":"411806",
      "display":"บ้านหยวก"
      },
      {
      "code":"411810",
      "display":"ศรีสำราญ"
      },
      {
      "code":"411812",
      "display":"สามัคคี"
      },
      {
      "code":"411713",
      "display":"หนองแวง"
      },
      {
      "code":"411807",
      "display":"โสมเยี่ยม"
      }
      ],
      "code":"4118",
      "display":"น้ำโสม"
      },
      {
      "districts":[
      {
      "code":"411103",
      "display":"ดงเย็น"
      },
      {
      "code":"411109",
      "display":"ถ่อนนาลับ"
      },
      {
      "code":"411113",
      "display":"นาคำ"
      },
      {
      "code":"411108",
      "display":"นาไหม"
      },
      {
      "code":"FALSE",
      "display":"บ้านจันทร์"
      },
      {
      "code":"411107",
      "display":"บ้านชัย"
      },
      {
      "code":"411102",
      "display":"บ้านดุง"
      },
      {
      "code":"410105",
      "display":"บ้านตาด"
      },
      {
      "code":"411111",
      "display":"บ้านม่วง"
      },
      {
      "code":"411110",
      "display":"วังทอง"
      },
      {
      "code":"411101",
      "display":"ศรีสุทโธ"
      },
      {
      "code":"411105",
      "display":"อ้อมกอ"
      },
      {
      "code":"410698",
      "display":"โพนสูง"
      }
      ],
      "code":"4111",
      "display":"บ้านดุง"
      },
      {
      "districts":[
      {
      "code":"411708",
      "display":"กลางใหญ่"
      },
      {
      "code":"411706",
      "display":"ข้าวสาร"
      },
      {
      "code":"411710",
      "display":"คำด้วง"
      },
      {
      "code":"411704",
      "display":"คำบง"
      },
      {
      "code":"411707",
      "display":"จำปาโมง"
      },
      {
      "code":"411605",
      "display":"บ้านค้อ"
      },
      {
      "code":"411701",
      "display":"บ้านผือ"
      },
      {
      "code":"411711",
      "display":"หนองหัวคู"
      },
      {
      "code":"411713",
      "display":"หนองแวง"
      },
      {
      "code":"411702",
      "display":"หายโศก"
      },
      {
      "code":"411703",
      "display":"เขือน้ำ"
      },
      {
      "code":"411709",
      "display":"เมืองพาน"
      },
      {
      "code":"411705",
      "display":"โนนทอง"
      }
      ],
      "code":"4117",
      "display":"บ้านผือ"
      },
      {
      "districts":[
      {
      "code":"410412",
      "display":"นาม่วง"
      },
      {
      "code":"410408",
      "display":"ห้วยสามพาด"
      },
      {
      "code":"410405",
      "display":"อุ่มจาน"
      }
      ],
      "code":"4125",
      "display":"ประจักษ์ศิลปาคม"
      },
      {
      "districts":[
      {
      "code":"410613",
      "display":"ดอนกลอย"
      },
      {
      "code":"410615",
      "display":"นาทราย"
      },
      {
      "code":"410608",
      "display":"บ้านแดง"
      }
      ],
      "code":"4123",
      "display":"พิบูลย์รักษ์"
      },
      {
      "districts":[
      {
      "code":"411005",
      "display":"คำโคกสูง"
      },
      {
      "code":"411003",
      "display":"บะยาว"
      },
      {
      "code":"410413",
      "display":"ผาสุก"
      },
      {
      "code":"411006",
      "display":"วังสามหมอ"
      },
      {
      "code":"411001",
      "display":"หนองกุงทับม้า"
      },
      {
      "code":"411002",
      "display":"หนองหญ้าไซ"
      }
      ],
      "code":"4110",
      "display":"วังสามหมอ"
      },
      {
      "districts":[
      {
      "code":"410902",
      "display":"จำปี"
      },
      {
      "code":"410907",
      "display":"ตาดทอง"
      },
      {
      "code":"410906",
      "display":"นายูง"
      },
      {
      "code":"410903",
      "display":"บ้านโปร่ง"
      },
      {
      "code":"410901",
      "display":"ศรีธาตุ"
      },
      {
      "code":"410905",
      "display":"หนองนกเขียน"
      },
      {
      "code":"410904",
      "display":"หัวนาคำ"
      }
      ],
      "code":"4109",
      "display":"ศรีธาตุ"
      },
      {
      "districts":[
      {
      "code":"412005",
      "display":"นาสะอาด"
      },
      {
      "code":"411998",
      "display":"บ้านยวด"
      },
      {
      "code":"412006",
      "display":"บ้านหินโงม"
      },
      {
      "code":"411498",
      "display":"บ้านโคก"
      },
      {
      "code":"411999",
      "display":"สร้างคอม"
      },
      {
      "code":"411997",
      "display":"เชียงดา"
      }
      ],
      "code":"4120",
      "display":"สร้างคอม"
      },
      {
      "districts":[
      {
      "code":"410304",
      "display":"กุดหมากไฟ"
      },
      {
      "code":"410305",
      "display":"น้ำพ่น"
      },
      {
      "code":"410306",
      "display":"หนองบัวบาน"
      },
      {
      "code":"410308",
      "display":"หนองวัวซอ"
      },
      {
      "code":"410302",
      "display":"หนองอ้อ"
      },
      {
      "code":"410301",
      "display":"หมากหญ้า"
      },
      {
      "code":"410303",
      "display":"อูบมุง"
      },
      {
      "code":"410307",
      "display":"โนนหวาย"
      }
      ],
      "code":"4103",
      "display":"หนองวัวซอ"
      },
      {
      "districts":[
      {
      "code":"410617",
      "display":"ดอนหายโศก"
      },
      {
      "code":"410610",
      "display":"บ้านยา"
      },
      {
      "code":"410609",
      "display":"บ้านเชียง"
      },
      {
      "code":"410612",
      "display":"ผักตบ"
      },
      {
      "code":"410605",
      "display":"พังงู"
      },
      {
      "code":"410607",
      "display":"สร้อยพร้าว"
      },
      {
      "code":"410606",
      "display":"สะแบง"
      },
      {
      "code":"410618",
      "display":"หนองสระปลา"
      },
      {
      "code":"410601",
      "display":"หนองหาน"
      },
      {
      "code":"410602",
      "display":"หนองเม็ก"
      },
      {
      "code":"410121",
      "display":"หนองไผ่"
      },
      {
      "code":"410611",
      "display":"โพนงาม"
      }
      ],
      "code":"4106",
      "display":"หนองหาน"
      },
      {
      "districts":[
      {
      "code":"412104",
      "display":"ทับกุง"
      },
      {
      "code":"410111",
      "display":"นาดี"
      },
      {
      "code":"410498",
      "display":"หนองแสง"
      },
      {
      "code":"410499",
      "display":"แสงสว่าง"
      }
      ],
      "code":"4121",
      "display":"หนองแสง"
      },
      {
      "districts":[
      {
      "code":"411908",
      "display":"จอมศรี"
      },
      {
      "code":"411906",
      "display":"นาบัว"
      },
      {
      "code":"411903",
      "display":"นาพู่"
      },
      {
      "code":"411902",
      "display":"บ้านธาตุ"
      },
      {
      "code":"411907",
      "display":"บ้านเหล่า"
      },
      {
      "code":"411911",
      "display":"สร้างแป้น"
      },
      {
      "code":"411905",
      "display":"สุมเส้า"
      },
      {
      "code":"411904",
      "display":"เชียงหวาง"
      },
      {
      "code":"411909",
      "display":"เตาไห"
      },
      {
      "code":"411901",
      "display":"เพ็ญ"
      },
      {
      "code":"410506",
      "display":"โคกกลาง"
      }
      ],
      "code":"4119",
      "display":"เพ็ญ"
      },
      {
      "districts":[
      {
      "code":"410110",
      "display":"กุดสระ"
      },
      {
      "code":"410120",
      "display":"นากว้าง"
      },
      {
      "code":"410116",
      "display":"นาข่า"
      },
      {
      "code":"410111",
      "display":"นาดี"
      },
      {
      "code":"410102",
      "display":"นิคมสงเคราะห์"
      },
      {
      "code":"410103",
      "display":"บ้านขาว"
      },
      {
      "code":"410117",
      "display":"บ้านจั่น"
      },
      {
      "code":"410105",
      "display":"บ้านตาด"
      },
      {
      "code":"410112",
      "display":"บ้านเลื่อม"
      },
      {
      "code":"410114",
      "display":"สามพร้าว"
      },
      {
      "code":"410118",
      "display":"หนองขอนกว้าง"
      },
      {
      "code":"410109",
      "display":"หนองนาคำ"
      },
      {
      "code":"410104",
      "display":"หนองบัว"
      },
      {
      "code":"410121",
      "display":"หนองไผ่"
      },
      {
      "code":"410115",
      "display":"หนองไฮ"
      },
      {
      "code":"410101",
      "display":"หมากแข้ง"
      },
      {
      "code":"410107",
      "display":"หมูม่น"
      },
      {
      "code":"410113",
      "display":"เชียงพิณ"
      },
      {
      "code":"410108",
      "display":"เชียงยืน"
      },
      {
      "code":"410119",
      "display":"โคกสะอาด"
      },
      {
      "code":"410106",
      "display":"โนนสูง"
      }
      ],
      "code":"4101",
      "display":"เมืองอุดรธานี"
      },
      {
      "districts":[
      {
      "code":"410504",
      "display":"ทมนางาม"
      },
      {
      "code":"410497",
      "display":"บุ่งแก้ว"
      },
      {
      "code":"410505",
      "display":"หนองกุงศรี"
      },
      {
      "code":"410506",
      "display":"โคกกลาง"
      },
      {
      "code":"410495",
      "display":"โนนสะอาด"
      },
      {
      "code":"410496",
      "display":"โพธิ์ศรีสำราญ"
      }
      ],
      "code":"4105",
      "display":"โนนสะอาด"
      },
      {
      "districts":[
      {
      "code":"410803",
      "display":"คำเลาะ"
      },
      {
      "code":"410694",
      "display":"หนองหลัก"
      },
      {
      "code":"410698",
      "display":"โพนสูง"
      },
      {
      "code":"410699",
      "display":"ไชยวาน"
      }
      ],
      "code":"4108",
      "display":"ไชยวาน"
      }
      ],
      "display":"อุดรธานี"
      },
      "อุตรดิตถ์":{
      "sort_order":"67",
      "code":"53",
      "amphoe":[
      {
      "districts":[
      {
      "code":"530205",
      "display":"ข่อยสูง"
      },
      {
      "code":"530204",
      "display":"น้ำอ่าง"
      },
      {
      "code":"530202",
      "display":"บ้านแก่ง"
      },
      {
      "code":"530201",
      "display":"วังแดง"
      },
      {
      "code":"530203",
      "display":"หาดสองแคว"
      }
      ],
      "code":"5302",
      "display":"ตรอน"
      },
      {
      "districts":[
      {
      "code":"530296",
      "display":"น้ำพี้"
      },
      {
      "code":"530297",
      "display":"บ่อทอง"
      },
      {
      "code":"530299",
      "display":"ป่าคาย"
      },
      {
      "code":"530298",
      "display":"ผักขวง"
      }
      ],
      "code":"5309",
      "display":"ทองแสนขัน"
      },
      {
      "districts":[
      {
      "code":"530304",
      "display":"จริม"
      },
      {
      "code":"530301",
      "display":"ท่าปลา"
      },
      {
      "code":"530306",
      "display":"ท่าแฝก"
      },
      {
      "code":"530307",
      "display":"นางพญา"
      },
      {
      "code":"530305",
      "display":"น้ำหมัน"
      },
      {
      "code":"530303",
      "display":"ผาเลือด"
      },
      {
      "code":"530308",
      "display":"ร่วมจิต"
      },
      {
      "code":"530302",
      "display":"หาดล้า"
      }
      ],
      "code":"5303",
      "display":"ท่าปลา"
      },
      {
      "districts":[
      {
      "code":"530404",
      "display":"น้ำไคร้"
      },
      {
      "code":"530405",
      "display":"น้ำไผ่"
      },
      {
      "code":"530402",
      "display":"บ้านฝาย"
      },
      {
      "code":"530406",
      "display":"ห้วยมุ่น"
      },
      {
      "code":"530403",
      "display":"เด่นเหล็ก"
      },
      {
      "code":"530114",
      "display":"แสนตอ"
      }
      ],
      "code":"5304",
      "display":"น้ำปาด"
      },
      {
      "districts":[
      {
      "code":"530603",
      "display":"นาขุม"
      },
      {
      "code":"530604",
      "display":"บ่อเบี้ย"
      },
      {
      "code":"530602",
      "display":"บ้านโคก"
      },
      {
      "code":"530601",
      "display":"ม่วงเจ็ดต้น"
      }
      ],
      "code":"5306",
      "display":"บ้านโคก"
      },
      {
      "districts":[
      {
      "code":"530705",
      "display":"คอรุม"
      },
      {
      "code":"530707",
      "display":"ท่ามะเฟือง"
      },
      {
      "code":"530704",
      "display":"ท่าสัก"
      },
      {
      "code":"530711",
      "display":"นายาง"
      },
      {
      "code":"530710",
      "display":"นาอิน"
      },
      {
      "code":"530702",
      "display":"บ้านดารา"
      },
      {
      "code":"530706",
      "display":"บ้านหม้อ"
      },
      {
      "code":"530708",
      "display":"บ้านโคน"
      },
      {
      "code":"530709",
      "display":"พญาแมน"
      },
      {
      "code":"530701",
      "display":"ในเมือง"
      },
      {
      "code":"530703",
      "display":"ไร่อ้อย"
      }
      ],
      "code":"5307",
      "display":"พิชัย"
      },
      {
      "districts":[
      {
      "code":"530503",
      "display":"บ้านเสี้ยว"
      },
      {
      "code":"530501",
      "display":"ฟากท่า"
      },
      {
      "code":"530502",
      "display":"สองคอน"
      },
      {
      "code":"530504",
      "display":"สองห้อง"
      }
      ],
      "code":"5305",
      "display":"ฟากท่า"
      },
      {
      "districts":[
      {
      "code":"530805",
      "display":"ชัยจุมพล"
      },
      {
      "code":"530808",
      "display":"ด่านแม่คำมัน"
      },
      {
      "code":"530807",
      "display":"ทุ่งยั้ง"
      },
      {
      "code":"530803",
      "display":"นานกกก"
      },
      {
      "code":"530804",
      "display":"ฝายหลวง"
      },
      {
      "code":"530801",
      "display":"ศรีพนมมาศ"
      },
      {
      "code":"530802",
      "display":"แม่พูล"
      },
      {
      "code":"530806",
      "display":"ไผ่ล้อม"
      }
      ],
      "code":"5308",
      "display":"ลับแล"
      },
      {
      "districts":[
      {
      "code":"530116",
      "display":"ขุนฝาง"
      },
      {
      "code":"530105",
      "display":"คุ้งตะเภา"
      },
      {
      "code":"530109",
      "display":"งิ้วงาม"
      },
      {
      "code":"530117",
      "display":"ถ้ำฉลอง"
      },
      {
      "code":"530101",
      "display":"ท่าอิฐ"
      },
      {
      "code":"530102",
      "display":"ท่าเสา"
      },
      {
      "code":"530108",
      "display":"น้ำริด"
      },
      {
      "code":"530111",
      "display":"บ้านด่าน"
      },
      {
      "code":"530110",
      "display":"บ้านด่านนาขาม"
      },
      {
      "code":"530103",
      "display":"บ้านเกาะ"
      },
      {
      "code":"530104",
      "display":"ป่าเซ่า"
      },
      {
      "code":"530112",
      "display":"ผาจุก"
      },
      {
      "code":"530106",
      "display":"วังกะพี้"
      },
      {
      "code":"530113",
      "display":"วังดิน"
      },
      {
      "code":"530107",
      "display":"หาดกรวด"
      },
      {
      "code":"530115",
      "display":"หาดงิ้ว"
      },
      {
      "code":"530114",
      "display":"แสนตอ"
      }
      ],
      "code":"5301",
      "display":"เมืองอุตรดิตถ์"
      }
      ],
      "display":"อุตรดิตถ์"
      },
      "อุทัยธานี":{
      "sort_order":"68",
      "code":"61",
      "amphoe":[
      {
      "districts":[
      {
      "code":"610210",
      "display":"ตลุกดู่"
      },
      {
      "code":"610201",
      "display":"ทัพทัน"
      },
      {
      "code":"610202",
      "display":"ทุ่งนาไทย"
      },
      {
      "code":"610208",
      "display":"หนองกระทุ่ม"
      },
      {
      "code":"610207",
      "display":"หนองกลางดง"
      },
      {
      "code":"610206",
      "display":"หนองยายดา"
      },
      {
      "code":"610209",
      "display":"หนองสระ"
      },
      {
      "code":"610204",
      "display":"หนองหญ้าปล้อง"
      },
      {
      "code":"610203",
      "display":"เขาขี้ฝอย"
      },
      {
      "code":"610205",
      "display":"โคกหม้อ"
      }
      ],
      "code":"6102",
      "display":"ทัพทัน"
      },
      {
      "districts":[
      {
      "code":"610604",
      "display":"คอกควาย"
      },
      {
      "code":"610602",
      "display":"ทัพหลวง"
      },
      {
      "code":"610611",
      "display":"บ้านบึง"
      },
      {
      "code":"610612",
      "display":"บ้านใหม่คลองเคียน"
      },
      {
      "code":"610601",
      "display":"บ้านไร่"
      },
      {
      "code":"610605",
      "display":"วังหิน"
      },
      {
      "code":"610609",
      "display":"หนองจอก"
      },
      {
      "code":"610613",
      "display":"หนองบ่มกล้วย"
      },
      {
      "code":"610610",
      "display":"หูช้าง"
      },
      {
      "code":"610603",
      "display":"ห้วยแห้ง"
      },
      {
      "code":"610614",
      "display":"เจ้าวัด"
      },
      {
      "code":"610606",
      "display":"เมืองการุ้ง"
      },
      {
      "code":"610607",
      "display":"แก่นมะกรูด"
      }
      ],
      "code":"6106",
      "display":"บ้านไร่"
      },
      {
      "districts":[
      {
      "code":"610706",
      "display":"ทุ่งนางาม"
      },
      {
      "code":"610705",
      "display":"น้ำรอบ"
      },
      {
      "code":"610698",
      "display":"ประดู่ยืน"
      },
      {
      "code":"610697",
      "display":"ป่าอ้อ"
      },
      {
      "code":"610704",
      "display":"ระบำ"
      },
      {
      "code":"610699",
      "display":"ลานสัก"
      }
      ],
      "code":"6107",
      "display":"ลานสัก"
      },
      {
      "districts":[
      {
      "code":"610305",
      "display":"บ่อยาง"
      },
      {
      "code":"610303",
      "display":"พลวงสองนาง"
      },
      {
      "code":"610301",
      "display":"สว่างอารมณ์"
      },
      {
      "code":"610302",
      "display":"หนองหลวง"
      },
      {
      "code":"610304",
      "display":"ไผ่เขียว"
      }
      ],
      "code":"6103",
      "display":"สว่างอารมณ์"
      },
      {
      "districts":[
      {
      "code":"610509",
      "display":"ดงขวาง"
      },
      {
      "code":"610503",
      "display":"ดอนกลอย"
      },
      {
      "code":"610505",
      "display":"ทุ่งพึ่ง"
      },
      {
      "code":"610506",
      "display":"ท่าโพ"
      },
      {
      "code":"610501",
      "display":"หนองขาหย่าง"
      },
      {
      "code":"610502",
      "display":"หนองไผ่"
      },
      {
      "code":"610507",
      "display":"หมกแถว"
      },
      {
      "code":"610508",
      "display":"หลุมเข้า"
      },
      {
      "code":"610504",
      "display":"ห้วยรอบ"
      }
      ],
      "code":"6105",
      "display":"หนองขาหย่าง"
      },
      {
      "districts":[
      {
      "code":"610408",
      "display":"ทุ่งพง"
      },
      {
      "code":"610407",
      "display":"ทุ่งโพ"
      },
      {
      "code":"610405",
      "display":"บ้านเก่า"
      },
      {
      "code":"610401",
      "display":"หนองฉาง"
      },
      {
      "code":"610403",
      "display":"หนองนางนวล"
      },
      {
      "code":"610402",
      "display":"หนองยาง"
      },
      {
      "code":"610404",
      "display":"หนองสรวง"
      },
      {
      "code":"610406",
      "display":"อุทัยเก่า"
      },
      {
      "code":"610410",
      "display":"เขากวางทอง"
      },
      {
      "code":"610409",
      "display":"เขาบางแกรก"
      }
      ],
      "code":"6104",
      "display":"หนองฉาง"
      },
      {
      "districts":[
      {
      "code":"610802",
      "display":"ทองหลาง"
      },
      {
      "code":"610696",
      "display":"สุขฤทัย"
      },
      {
      "code":"610695",
      "display":"ห้วยคต"
      }
      ],
      "code":"6108",
      "display":"ห้วยคต"
      },
      {
      "districts":[
      {
      "code":"610104",
      "display":"ดอนขวาง"
      },
      {
      "code":"610113",
      "display":"ทุ่งใหญ่"
      },
      {
      "code":"610107",
      "display":"ท่าซุง"
      },
      {
      "code":"610102",
      "display":"น้ำซึม"
      },
      {
      "code":"610103",
      "display":"สะแกกรัง"
      },
      {
      "code":"610112",
      "display":"หนองพังค่า"
      },
      {
      "code":"610110",
      "display":"หนองเต่า"
      },
      {
      "code":"610108",
      "display":"หนองแก"
      },
      {
      "code":"610111",
      "display":"หนองไผ่แบน"
      },
      {
      "code":"610105",
      "display":"หาดทนง"
      },
      {
      "code":"610101",
      "display":"อุทัยใหม่"
      },
      {
      "code":"610106",
      "display":"เกาะเทโพ"
      },
      {
      "code":"610114",
      "display":"เนินแจง"
      },
      {
      "code":"610109",
      "display":"โนนเหล็ก"
      }
      ],
      "code":"6101",
      "display":"เมืองอุทัยธานี"
      }
      ],
      "display":"อุทัยธานี"
      },
      "อุบลราชธานี":{
      "sort_order":"69",
      "code":"34",
      "amphoe":[
      {
      "districts":[
      {
      "code":"341204",
      "display":"กาบิน"
      },
      {
      "code":"341201",
      "display":"ข้าวปุ้น"
      },
      {
      "code":"341205",
      "display":"หนองทันน้ำ"
      },
      {
      "code":"341203",
      "display":"แก่งเค็ง"
      },
      {
      "code":"341202",
      "display":"โนนสวาง"
      }
      ],
      "code":"3412",
      "display":"กุดข้าวปุ้น"
      },
      {
      "districts":[
      {
      "code":"340110",
      "display":"คำไฮใหญ่"
      },
      {
      "code":"340106",
      "display":"ดอนมดแดง"
      },
      {
      "code":"340103",
      "display":"ท่าเมือง"
      },
      {
      "code":"340114",
      "display":"เหล่าแดง"
      }
      ],
      "code":"3424",
      "display":"ดอนมดแดง"
      },
      {
      "districts":[
      {
      "code":"341102",
      "display":"กระเดียน"
      },
      {
      "code":"341121",
      "display":"กุดยาลวน"
      },
      {
      "code":"341104",
      "display":"กุศกร"
      },
      {
      "code":"341105",
      "display":"ขามเปี้ย"
      },
      {
      "code":"341101",
      "display":"ขุหลุ"
      },
      {
      "code":"341106",
      "display":"คอนสาย"
      },
      {
      "code":"341123",
      "display":"คำเจริญ"
      },
      {
      "code":"341111",
      "display":"ตระการ"
      },
      {
      "code":"341112",
      "display":"ตากแดด"
      },
      {
      "code":"341118",
      "display":"ถ้ำแข้"
      },
      {
      "code":"341119",
      "display":"ท่าหลวง"
      },
      {
      "code":"341108",
      "display":"นาพิน"
      },
      {
      "code":"341109",
      "display":"นาสะไม"
      },
      {
      "code":"341122",
      "display":"บ้านแดง"
      },
      {
      "code":"341116",
      "display":"สะพือ"
      },
      {
      "code":"341117",
      "display":"หนองเต่า"
      },
      {
      "code":"341120",
      "display":"ห้วยฝ้ายพัฒนา"
      },
      {
      "code":"341103",
      "display":"เกษม"
      },
      {
      "code":"341115",
      "display":"เซเป็ด"
      },
      {
      "code":"341114",
      "display":"เป้า"
      },
      {
      "code":"341107",
      "display":"โคกจาน"
      },
      {
      "code":"341110",
      "display":"โนนกุง"
      },
      {
      "code":"341113",
      "display":"ไหล่ทุ่ง"
      }
      ],
      "code":"3411",
      "display":"ตระการพืชผล"
      },
      {
      "districts":[
      {
      "code":"342006",
      "display":"คำหว้า"
      },
      {
      "code":"342003",
      "display":"จิกเทิง"
      },
      {
      "code":"342001",
      "display":"ตาลสุม"
      },
      {
      "code":"342005",
      "display":"นาคาย"
      },
      {
      "code":"341513",
      "display":"สำโรง"
      },
      {
      "code":"342004",
      "display":"หนองกุง"
      }
      ],
      "code":"3420",
      "display":"ตาลสุม"
      },
      {
      "districts":[
      {
      "code":"340718",
      "display":"กุดเรือ"
      },
      {
      "code":"342606",
      "display":"นาห่อม"
      },
      {
      "code":"340714",
      "display":"นาเกษม"
      },
      {
      "code":"340707",
      "display":"หนองอ้ม"
      },
      {
      "code":"340722",
      "display":"โคกชำแระ"
      }
      ],
      "code":"3426",
      "display":"ทุ่งศรีอุดม"
      },
      {
      "districts":[
      {
      "code":"340801",
      "display":"นาจะหลวย"
      },
      {
      "code":"340804",
      "display":"บ้านตูม"
      },
      {
      "code":"340803",
      "display":"พรสวรรค์"
      },
      {
      "code":"340723",
      "display":"โนนสมบูรณ์"
      },
      {
      "code":"340806",
      "display":"โนนสวรรค์"
      },
      {
      "code":"340805",
      "display":"โสกแสง"
      }
      ],
      "code":"3408",
      "display":"นาจะหลวย"
      },
      {
      "districts":[
      {
      "code":"340502",
      "display":"กองโพน"
      },
      {
      "code":"340506",
      "display":"นาตาล"
      },
      {
      "code":"340509",
      "display":"พะลาน"
      },
      {
      "code":"340505",
      "display":"พังเคน"
      }
      ],
      "code":"3430",
      "display":"นาตาล"
      },
      {
      "districts":[
      {
      "code":"340719",
      "display":"นาดี"
      },
      {
      "code":"340703",
      "display":"นาเยีย"
      },
      {
      "code":"340705",
      "display":"นาเรือง"
      }
      ],
      "code":"3429",
      "display":"นาเยีย"
      },
      {
      "districts":[
      {
      "code":"340119",
      "display":"ขี้เหล็ก"
      },
      {
      "code":"340902",
      "display":"ตาเกา"
      },
      {
      "code":"340910",
      "display":"โคกสะอาด"
      },
      {
      "code":"340908",
      "display":"ไพบูลย์"
      }
      ],
      "code":"3433",
      "display":"น้ำขุ่น"
      },
      {
      "districts":[
      {
      "code":"340906",
      "display":"บุเปือย"
      },
      {
      "code":"340903",
      "display":"ยาง"
      },
      {
      "code":"340909",
      "display":"ยางใหญ่"
      },
      {
      "code":"340907",
      "display":"สีวิเชียร"
      },
      {
      "code":"340911",
      "display":"เก่าขาม"
      },
      {
      "code":"340901",
      "display":"โซง"
      },
      {
      "code":"340904",
      "display":"โดมประดิษฐ์"
      }
      ],
      "code":"3409",
      "display":"น้ำยืน"
      },
      {
      "districts":[
      {
      "code":"341003",
      "display":"คอแลน"
      },
      {
      "code":"341004",
      "display":"นาโพธิ์"
      },
      {
      "code":"340715",
      "display":"บัวงาม"
      },
      {
      "code":"341008",
      "display":"บ้านแมด"
      },
      {
      "code":"341005",
      "display":"หนองสะโน"
      },
      {
      "code":"341002",
      "display":"ห้วยข่า"
      },
      {
      "code":"341006",
      "display":"โนนค้อ"
      },
      {
      "code":"340720",
      "display":"โพนงาม"
      }
      ],
      "code":"3410",
      "display":"บุณฑริก"
      },
      {
      "districts":[
      {
      "code":"341902",
      "display":"กุดชมภู"
      },
      {
      "code":"341904",
      "display":"ดอนจิก"
      },
      {
      "code":"341905",
      "display":"ทรายมูล"
      },
      {
      "code":"341004",
      "display":"นาโพธิ์"
      },
      {
      "code":"341919",
      "display":"บ้านแขม"
      },
      {
      "code":"341901",
      "display":"พิบูล"
      },
      {
      "code":"341911",
      "display":"ระเว"
      },
      {
      "code":"341913",
      "display":"หนองบัวฮี"
      },
      {
      "code":"341914",
      "display":"อ่างศิลา"
      },
      {
      "code":"341907",
      "display":"โนนกลาง"
      },
      {
      "code":"341918",
      "display":"โนนกาหลง"
      },
      {
      "code":"341910",
      "display":"โพธิ์ศรี"
      },
      {
      "code":"341909",
      "display":"โพธิ์ไทร"
      },
      {
      "code":"341912",
      "display":"ไร่ใต้"
      }
      ],
      "code":"3419",
      "display":"พิบูลมังสาหาร"
      },
      {
      "districts":[
      {
      "code":"341403",
      "display":"ดุมใหญ่"
      },
      {
      "code":"341413",
      "display":"นาเลิง"
      },
      {
      "code":"341401",
      "display":"ม่วงสามสิบ"
      },
      {
      "code":"341407",
      "display":"ยางสักกระโพหลุ่ม"
      },
      {
      "code":"341411",
      "display":"ยางโยภาพ"
      },
      {
      "code":"341404",
      "display":"หนองช้างใหญ่"
      },
      {
      "code":"341410",
      "display":"หนองฮาง"
      },
      {
      "code":"341405",
      "display":"หนองเมือง"
      },
      {
      "code":"340418",
      "display":"หนองเหล่า"
      },
      {
      "code":"341408",
      "display":"หนองไข่นก"
      },
      {
      "code":"341406",
      "display":"เตย"
      },
      {
      "code":"341402",
      "display":"เหล่าบก"
      },
      {
      "code":"341414",
      "display":"โพนแพง"
      },
      {
      "code":"341412",
      "display":"ไผ่ใหญ่"
      }
      ],
      "code":"3414",
      "display":"ม่วงสามสิบ"
      },
      {
      "districts":[
      {
      "code":"341515",
      "display":"คำขวาง"
      },
      {
      "code":"341510",
      "display":"คำน้ำแซบ"
      },
      {
      "code":"341507",
      "display":"คูเมือง"
      },
      {
      "code":"341504",
      "display":"ท่าลาด"
      },
      {
      "code":"341502",
      "display":"ธาตุ"
      },
      {
      "code":"341511",
      "display":"บุ่งหวาย"
      },
      {
      "code":"341526",
      "display":"บุ่งไหม"
      },
      {
      "code":"341501",
      "display":"วารินชำราบ"
      },
      {
      "code":"341508",
      "display":"สระสมิง"
      },
      {
      "code":"341520",
      "display":"หนองกินเพล"
      },
      {
      "code":"341524",
      "display":"ห้วยขะยูง"
      },
      {
      "code":"341522",
      "display":"เมืองศรีไค"
      },
      {
      "code":"341518",
      "display":"แสนสุข"
      },
      {
      "code":"341521",
      "display":"โนนผึ้ง"
      },
      {
      "code":"341505",
      "display":"โนนโหนน"
      },
      {
      "code":"341516",
      "display":"โพธิ์ใหญ่"
      }
      ],
      "code":"3415",
      "display":"วารินชำราบ"
      },
      {
      "districts":[
      {
      "code":"340208",
      "display":"คำไหล"
      },
      {
      "code":"340211",
      "display":"ดอนใหญ่"
      },
      {
      "code":"340207",
      "display":"ตะบ่าย"
      },
      {
      "code":"340201",
      "display":"นาคำ"
      },
      {
      "code":"340210",
      "display":"นาเลิน"
      },
      {
      "code":"340205",
      "display":"ลาดควาย"
      },
      {
      "code":"340204",
      "display":"วาริน"
      },
      {
      "code":"340206",
      "display":"สงยาง"
      },
      {
      "code":"340209",
      "display":"หนามแท่ง"
      },
      {
      "code":"340203",
      "display":"เอือดใหญ่"
      },
      {
      "code":"340202",
      "display":"แก้งกอก"
      }
      ],
      "code":"3402",
      "display":"ศรีเมืองใหม่"
      },
      {
      "districts":[
      {
      "code":"341503",
      "display":"ท่าช้าง"
      },
      {
      "code":"341523",
      "display":"บุ่งมะแลง"
      },
      {
      "code":"341514",
      "display":"สว่าง"
      },
      {
      "code":"341525",
      "display":"แก่งโดม"
      }
      ],
      "code":"3432",
      "display":"สว่างวีระวงศ์"
      },
      {
      "districts":[
      {
      "code":"340503",
      "display":"ขามป้อม"
      },
      {
      "code":"341509",
      "display":"ค้อน้อย"
      },
      {
      "code":"342208",
      "display":"บอน"
      },
      {
      "code":"341513",
      "display":"สำโรง"
      },
      {
      "code":"341512",
      "display":"หนองไฮ"
      },
      {
      "code":"341517",
      "display":"โคกก่อง"
      },
      {
      "code":"341519",
      "display":"โคกสว่าง"
      },
      {
      "code":"341907",
      "display":"โนนกลาง"
      },
      {
      "code":"341506",
      "display":"โนนกาเล็น"
      }
      ],
      "code":"3422",
      "display":"สำโรง"
      },
      {
      "districts":[
      {
      "code":"341903",
      "display":"คันไร่"
      },
      {
      "code":"340306",
      "display":"คำเขื่อนแก้ว"
      },
      {
      "code":"341916",
      "display":"ช่องเม็ก"
      },
      {
      "code":"342504",
      "display":"นิคมสร้างตนเองลำโดมน้อย"
      },
      {
      "code":"341908",
      "display":"ฝางคำ"
      },
      {
      "code":"341917",
      "display":"โนนก่อ"
      }
      ],
      "code":"3425",
      "display":"สิรินธร"
      },
      {
      "districts":[
      {
      "code":"340503",
      "display":"ขามป้อม"
      },
      {
      "code":"340508",
      "display":"นาแวง"
      },
      {
      "code":"340511",
      "display":"หนองนกทา"
      },
      {
      "code":"340507",
      "display":"หนองผือ"
      },
      {
      "code":"340512",
      "display":"หนองสิม"
      },
      {
      "code":"340513",
      "display":"หัวนา"
      },
      {
      "code":"340501",
      "display":"เขมราฐ"
      },
      {
      "code":"340504",
      "display":"เจียด"
      },
      {
      "code":"340510",
      "display":"แก้งเหนือ"
      }
      ],
      "code":"3405",
      "display":"เขมราฐ"
      },
      {
      "districts":[
      {
      "code":"340413",
      "display":"กลางใหญ่"
      },
      {
      "code":"340404",
      "display":"ก่อเอ้"
      },
      {
      "code":"340403",
      "display":"ค้อทอง"
      },
      {
      "code":"340406",
      "display":"ชีทวน"
      },
      {
      "code":"340407",
      "display":"ท่าไห"
      },
      {
      "code":"340410",
      "display":"ธาตุน้อย"
      },
      {
      "code":"340408",
      "display":"นาคำใหญ่"
      },
      {
      "code":"340412",
      "display":"บ้านกอก"
      },
      {
      "code":"340411",
      "display":"บ้านไทย"
      },
      {
      "code":"340415",
      "display":"ยางขี้นก"
      },
      {
      "code":"340416",
      "display":"ศรีสุข"
      },
      {
      "code":"340402",
      "display":"สร้างถ่อ"
      },
      {
      "code":"340417",
      "display":"สหธาตุ"
      },
      {
      "code":"340418",
      "display":"หนองเหล่า"
      },
      {
      "code":"340405",
      "display":"หัวดอน"
      },
      {
      "code":"340401",
      "display":"เขื่องใน"
      },
      {
      "code":"340409",
      "display":"แดงหม้อ"
      },
      {
      "code":"340414",
      "display":"โนนรัง"
      }
      ],
      "code":"3404",
      "display":"เขื่องใน"
      },
      {
      "districts":[
      {
      "code":"340711",
      "display":"กลาง"
      },
      {
      "code":"340709",
      "display":"กุดประทาย"
      },
      {
      "code":"340716",
      "display":"คำครั่ง"
      },
      {
      "code":"340710",
      "display":"ตบหู"
      },
      {
      "code":"340706",
      "display":"ทุ่งเทิง"
      },
      {
      "code":"340713",
      "display":"ท่าโพธิ์ศรี"
      },
      {
      "code":"340717",
      "display":"นากระแซง"
      },
      {
      "code":"340702",
      "display":"นาส่วง"
      },
      {
      "code":"340704",
      "display":"นาเจริญ"
      },
      {
      "code":"340715",
      "display":"บัวงาม"
      },
      {
      "code":"340721",
      "display":"ป่าโมง"
      },
      {
      "code":"340708",
      "display":"สมสะอาด"
      },
      {
      "code":"340701",
      "display":"เมืองเดช"
      },
      {
      "code":"340712",
      "display":"แก้ง"
      },
      {
      "code":"340723",
      "display":"โนนสมบูรณ์"
      },
      {
      "code":"340720",
      "display":"โพนงาม"
      }
      ],
      "code":"3407",
      "display":"เดชอุดม"
      },
      {
      "districts":[
      {
      "code":"340113",
      "display":"กระโสบ"
      },
      {
      "code":"340116",
      "display":"กุดลาด"
      },
      {
      "code":"340108",
      "display":"ขามใหญ่"
      },
      {
      "code":"340119",
      "display":"ขี้เหล็ก"
      },
      {
      "code":"340107",
      "display":"ปทุม"
      },
      {
      "code":"340120",
      "display":"ปะอาว"
      },
      {
      "code":"340105",
      "display":"หนองขอน"
      },
      {
      "code":"340111",
      "display":"หนองบ่อ"
      },
      {
      "code":"340104",
      "display":"หัวเรือ"
      },
      {
      "code":"340109",
      "display":"แจระแม"
      },
      {
      "code":"340101",
      "display":"ในเมือง"
      },
      {
      "code":"340112",
      "display":"ไร่น้อย"
      }
      ],
      "code":"3401",
      "display":"เมืองอุบลราชธานี"
      },
      {
      "districts":[
      {
      "code":"340117",
      "display":"หนองบก"
      },
      {
      "code":"340115",
      "display":"เหล่าเสือโก้ก"
      },
      {
      "code":"340118",
      "display":"แพงใหญ่"
      },
      {
      "code":"340102",
      "display":"โพนเมือง"
      }
      ],
      "code":"3431",
      "display":"เหล่าเสือโก้ก"
      },
      {
      "districts":[
      {
      "code":"340303",
      "display":"นาโพธิ์กลาง"
      },
      {
      "code":"340304",
      "display":"หนองแสงใหญ่"
      },
      {
      "code":"340302",
      "display":"ห้วยยาง"
      },
      {
      "code":"340305",
      "display":"ห้วยไผ่"
      },
      {
      "code":"340301",
      "display":"โขงเจียม"
      }
      ],
      "code":"3403",
      "display":"โขงเจียม"
      },
      {
      "districts":[
      {
      "code":"342102",
      "display":"ม่วงใหญ่"
      },
      {
      "code":"342104",
      "display":"สองคอน"
      },
      {
      "code":"342105",
      "display":"สารภี"
      },
      {
      "code":"341513",
      "display":"สำโรง"
      },
      {
      "code":"342106",
      "display":"เหล่างาม"
      },
      {
      "code":"341909",
      "display":"โพธิ์ไทร"
      }
      ],
      "code":"3421",
      "display":"โพธิ์ไทร"
      }
      ],
      "display":"อุบลราชธานี"
      },
      "อ่างทอง":{
      "sort_order":"70",
      "code":"15",
      "amphoe":[
      {
      "districts":[
      {
      "code":"150306",
      "display":"นรสิงห์"
      },
      {
      "code":"150301",
      "display":"บางปลากด"
      },
      {
      "code":"150305",
      "display":"บางเสด็จ"
      },
      {
      "code":"150302",
      "display":"ป่าโมก"
      },
      {
      "code":"150303",
      "display":"สายทอง"
      },
      {
      "code":"150307",
      "display":"เอกราช"
      },
      {
      "code":"150308",
      "display":"โผงเผง"
      },
      {
      "code":"150304",
      "display":"โรงช้าง"
      }
      ],
      "code":"1503",
      "display":"ป่าโมก"
      },
      {
      "districts":[
      {
      "code":"150609",
      "display":"คลองขนาก"
      },
      {
      "code":"150615",
      "display":"ตลาดใหม่"
      },
      {
      "code":"150605",
      "display":"ท่าช้าง"
      },
      {
      "code":"150607",
      "display":"บางจัก"
      },
      {
      "code":"150612",
      "display":"ม่วงเตี้ย"
      },
      {
      "code":"150606",
      "display":"ยี่ล้น"
      },
      {
      "code":"150602",
      "display":"ศาลเจ้าโรงทอง"
      },
      {
      "code":"150604",
      "display":"สาวร้องไห้"
      },
      {
      "code":"150611",
      "display":"สี่ร้อย"
      },
      {
      "code":"150614",
      "display":"หลักแก้ว"
      },
      {
      "code":"150613",
      "display":"หัวตะพาน"
      },
      {
      "code":"150608",
      "display":"ห้วยคันแหลน"
      },
      {
      "code":"FALSE",
      "display":"ไผ่จำศีล"
      },
      {
      "code":"150603",
      "display":"ไผ่ดำพัฒนา"
      },
      {
      "code":"150610",
      "display":"ไผ่วง"
      }
      ],
      "code":"1506",
      "display":"วิเศษชัยชาญ"
      },
      {
      "districts":[
      {
      "code":"150705",
      "display":"มงคลธรรมนิมิต"
      },
      {
      "code":"150702",
      "display":"ราษฎรพัฒนา"
      },
      {
      "code":"150701",
      "display":"สามโก้"
      },
      {
      "code":"150703",
      "display":"อบทม"
      },
      {
      "code":"150704",
      "display":"โพธิ์ม่วงพันธ์"
      }
      ],
      "code":"1507",
      "display":"สามโก้"
      },
      {
      "districts":[
      {
      "code":"150113",
      "display":"คลองวัว"
      },
      {
      "code":"150110",
      "display":"จำปาหล่อ"
      },
      {
      "code":"150106",
      "display":"ตลาดกรวด"
      },
      {
      "code":"150101",
      "display":"ตลาดหลวง"
      },
      {
      "code":"150102",
      "display":"บางแก้ว"
      },
      {
      "code":"150112",
      "display":"บ้านรี"
      },
      {
      "code":"150108",
      "display":"บ้านอิฐ"
      },
      {
      "code":"150105",
      "display":"บ้านแห"
      },
      {
      "code":"150104",
      "display":"ป่างิ้ว"
      },
      {
      "code":"150107",
      "display":"มหาดไทย"
      },
      {
      "code":"150114",
      "display":"ย่านซื่อ"
      },
      {
      "code":"150103",
      "display":"ศาลาแดง"
      },
      {
      "code":"150109",
      "display":"หัวไผ่"
      },
      {
      "code":"150111",
      "display":"โพสะ"
      }
      ],
      "code":"1501",
      "display":"เมืองอ่างทอง"
      },
      {
      "districts":[
      {
      "code":"150507",
      "display":"จำลอง"
      },
      {
      "code":"150503",
      "display":"บ้านพราน"
      },
      {
      "code":"150504",
      "display":"วังน้ำเย็น"
      },
      {
      "code":"150502",
      "display":"ศรีพราน"
      },
      {
      "code":"150505",
      "display":"สีบัวทอง"
      },
      {
      "code":"150506",
      "display":"ห้วยไผ่"
      },
      {
      "code":"150501",
      "display":"แสวงหา"
      }
      ],
      "code":"1505",
      "display":"แสวงหา"
      },
      {
      "districts":[
      {
      "code":"150415",
      "display":"คำหยาด"
      },
      {
      "code":"150412",
      "display":"ทางพระ"
      },
      {
      "code":"150403",
      "display":"บางพลับ"
      },
      {
      "code":"150406",
      "display":"บางระกำ"
      },
      {
      "code":"150414",
      "display":"บางเจ้าฉ่า"
      },
      {
      "code":"150411",
      "display":"บ่อแร่"
      },
      {
      "code":"150410",
      "display":"ยางช้าย"
      },
      {
      "code":"150405",
      "display":"รำมะสัก"
      },
      {
      "code":"150413",
      "display":"สามง่าม"
      },
      {
      "code":"150404",
      "display":"หนองแม่ไก่"
      },
      {
      "code":"150408",
      "display":"องครักษ์"
      },
      {
      "code":"150402",
      "display":"อินทประมูล"
      },
      {
      "code":"150401",
      "display":"อ่างแก้ว"
      },
      {
      "code":"150409",
      "display":"โคกพุทรา"
      },
      {
      "code":"150407",
      "display":"โพธิ์รังนก"
      }
      ],
      "code":"1504",
      "display":"โพธิ์ทอง"
      },
      {
      "districts":[
      {
      "code":"150201",
      "display":"จรเข้ร้อง"
      },
      {
      "code":"150208",
      "display":"ชะไว"
      },
      {
      "code":"150203",
      "display":"ชัยฤทธิ์"
      },
      {
      "code":"150209",
      "display":"ตรีณรงค์"
      },
      {
      "code":"150205",
      "display":"ราชสถิตย์"
      },
      {
      "code":"150207",
      "display":"หลักฟ้า"
      },
      {
      "code":"150204",
      "display":"เทวราช"
      },
      {
      "code":"150202",
      "display":"ไชยภูมิ"
      },
      {
      "code":"150206",
      "display":"ไชโย"
      }
      ],
      "code":"1502",
      "display":"ไชโย"
      }
      ],
      "display":"อ่างทอง"
      },
      "เชียงราย":{
      "sort_order":"71",
      "code":"57",
      "amphoe":[
      {
      "districts":[
      {
      "code":"570496",
      "display":"ต้า"
      },
      {
      "code":"570499",
      "display":"ป่าตาล"
      },
      {
      "code":"570497",
      "display":"ยางฮอม"
      }
      ],
      "code":"5714",
      "display":"ขุนตาล"
      },
      {
      "districts":[
      {
      "code":"570707",
      "display":"ปงน้อย"
      },
      {
      "code":"570709",
      "display":"หนองป่าก่อ"
      },
      {
      "code":"570717",
      "display":"โชคชัย"
      }
      ],
      "code":"5718",
      "display":"ดอยหลวง"
      },
      {
      "districts":[
      {
      "code":"570597",
      "display":"ป่าแงะ"
      },
      {
      "code":"570599",
      "display":"ป่าแดด"
      },
      {
      "code":"570606",
      "display":"ศรีโพธิ์เงิน"
      },
      {
      "code":"570598",
      "display":"สันมะค่า"
      },
      {
      "code":"570605",
      "display":"โรงช้าง"
      }
      ],
      "code":"5706",
      "display":"ป่าแดด"
      },
      {
      "districts":[
      {
      "code":"571205",
      "display":"ตาดควัน"
      },
      {
      "code":"571204",
      "display":"เม็งราย"
      },
      {
      "code":"571202",
      "display":"แม่ต๋ำ"
      },
      {
      "code":"570498",
      "display":"แม่เปา"
      },
      {
      "code":"571203",
      "display":"ไม้ยา"
      }
      ],
      "code":"5712",
      "display":"พญาเม็งราย"
      },
      {
      "districts":[
      {
      "code":"570505",
      "display":"ดอยงาม"
      },
      {
      "code":"570510",
      "display":"ทรายขาว"
      },
      {
      "code":"570514",
      "display":"ทานตะวัน"
      },
      {
      "code":"570503",
      "display":"ธารทอง"
      },
      {
      "code":"570508",
      "display":"ป่าหุ่ง"
      },
      {
      "code":"570509",
      "display":"ม่วงคำ"
      },
      {
      "code":"570511",
      "display":"สันกลาง"
      },
      {
      "code":"570504",
      "display":"สันติสุข"
      },
      {
      "code":"570501",
      "display":"สันมะเค็ด"
      },
      {
      "code":"570506",
      "display":"หัวง้ม"
      },
      {
      "code":"570507",
      "display":"เจริญเมือง"
      },
      {
      "code":"570513",
      "display":"เมืองพาน"
      },
      {
      "code":"570515",
      "display":"เวียงห้าว"
      },
      {
      "code":"570502",
      "display":"แม่อ้อ"
      },
      {
      "code":"570512",
      "display":"แม่เย็น"
      }
      ],
      "code":"5705",
      "display":"พาน"
      },
      {
      "districts":[
      {
      "code":"570303",
      "display":"ครึ่ง"
      },
      {
      "code":"570304",
      "display":"บุญเรือง"
      },
      {
      "code":"570310",
      "display":"ริมโขง"
      },
      {
      "code":"570308",
      "display":"ศรีดอนชัย"
      },
      {
      "code":"570302",
      "display":"สถาน"
      },
      {
      "code":"570305",
      "display":"ห้วยซ้อ"
      },
      {
      "code":"570101",
      "display":"เวียง"
      }
      ],
      "code":"5703",
      "display":"เชียงของ"
      },
      {
      "districts":[
      {
      "code":"570803",
      "display":"บ้านแซว"
      },
      {
      "code":"570802",
      "display":"ป่าสัก"
      },
      {
      "code":"570804",
      "display":"ศรีดอนมูล"
      },
      {
      "code":"570101",
      "display":"เวียง"
      },
      {
      "code":"570805",
      "display":"แม่เงิน"
      },
      {
      "code":"570806",
      "display":"โยนก"
      }
      ],
      "code":"5708",
      "display":"เชียงแสน"
      },
      {
      "districts":[
      {
      "code":"570402",
      "display":"งิ้ว"
      },
      {
      "code":"570409",
      "display":"ตับเต่า"
      },
      {
      "code":"570403",
      "display":"ปล้อง"
      },
      {
      "code":"570412",
      "display":"ศรีดอนไชย"
      },
      {
      "code":"570411",
      "display":"สันทรายงาม"
      },
      {
      "code":"570410",
      "display":"หงาว"
      },
      {
      "code":"570413",
      "display":"หนองแรด"
      },
      {
      "code":"570405",
      "display":"เชียงเคี่ยน"
      },
      {
      "code":"570101",
      "display":"เวียง"
      },
      {
      "code":"570404",
      "display":"แม่ลอย"
      }
      ],
      "code":"5704",
      "display":"เทิง"
      },
      {
      "districts":[
      {
      "code":"570115",
      "display":"ดอยลาน"
      },
      {
      "code":"570120",
      "display":"ดอยฮาง"
      },
      {
      "code":"570118",
      "display":"ท่าสาย"
      },
      {
      "code":"570121",
      "display":"ท่าสุด"
      },
      {
      "code":"570104",
      "display":"นางแล"
      },
      {
      "code":"570103",
      "display":"บ้านดู่"
      },
      {
      "code":"570116",
      "display":"ป่าอ้อดอนชัย"
      },
      {
      "code":"570102",
      "display":"รอบเวียง"
      },
      {
      "code":"570114",
      "display":"ริมกก"
      },
      {
      "code":"570107",
      "display":"สันทราย"
      },
      {
      "code":"570112",
      "display":"ห้วยชมภู"
      },
      {
      "code":"570113",
      "display":"ห้วยสัก"
      },
      {
      "code":"570101",
      "display":"เวียง"
      },
      {
      "code":"570111",
      "display":"แม่กรณ์"
      },
      {
      "code":"570105",
      "display":"แม่ข้าวต้ม"
      },
      {
      "code":"570106",
      "display":"แม่ยาว"
      }
      ],
      "code":"5701",
      "display":"เมืองเชียงราย"
      },
      {
      "districts":[
      {
      "code":"570206",
      "display":"ดอนศิลา"
      },
      {
      "code":"570197",
      "display":"ผางาม"
      },
      {
      "code":"570208",
      "display":"เมืองชุม"
      },
      {
      "code":"570198",
      "display":"เวียงชัย"
      },
      {
      "code":"570196",
      "display":"เวียงเหนือ"
      }
      ],
      "code":"5702",
      "display":"เวียงชัย"
      },
      {
      "districts":[
      {
      "code":"571103",
      "display":"บ้านโป่ง"
      },
      {
      "code":"571104",
      "display":"ป่างิ้ว"
      },
      {
      "code":"571101",
      "display":"สันสลี"
      },
      {
      "code":"570101",
      "display":"เวียง"
      },
      {
      "code":"571105",
      "display":"เวียงกาหลง"
      },
      {
      "code":"571106",
      "display":"แม่เจดีย์"
      },
      {
      "code":"571107",
      "display":"แม่เจดีย์ใหม่"
      }
      ],
      "code":"5711",
      "display":"เวียงป่าเป้า"
      },
      {
      "districts":[
      {
      "code":"570207",
      "display":"ดงมหาวัน"
      },
      {
      "code":"570192",
      "display":"ทุ่งก่อ"
      },
      {
      "code":"570205",
      "display":"ป่าซาง"
      }
      ],
      "code":"5717",
      "display":"เวียงเชียงรุ้ง"
      },
      {
      "districts":[
      {
      "code":"571304",
      "display":"ท่าข้าม"
      },
      {
      "code":"570307",
      "display":"ปอ"
      },
      {
      "code":"570306",
      "display":"ม่วงยาย"
      },
      {
      "code":"570309",
      "display":"หล่ายงาว"
      }
      ],
      "code":"5713",
      "display":"เวียงแก่น"
      },
      {
      "districts":[
      {
      "code":"570713",
      "display":"จอมสวรรค์"
      },
      {
      "code":"570702",
      "display":"จันจว้า"
      },
      {
      "code":"570712",
      "display":"จันจว้าใต้"
      },
      {
      "code":"570706",
      "display":"ท่าข้าวเปลือก"
      },
      {
      "code":"570205",
      "display":"ป่าซาง"
      },
      {
      "code":"570708",
      "display":"ป่าตึง"
      },
      {
      "code":"570711",
      "display":"ศรีค้ำ"
      },
      {
      "code":"570107",
      "display":"สันทราย"
      },
      {
      "code":"570703",
      "display":"แม่คำ"
      },
      {
      "code":"570701",
      "display":"แม่จัน"
      },
      {
      "code":"570710",
      "display":"แม่ไร่"
      }
      ],
      "code":"5707",
      "display":"แม่จัน"
      },
      {
      "districts":[
      {
      "code":"571501",
      "display":"เทอดไทย"
      },
      {
      "code":"571504",
      "display":"แม่ฟ้าหลวง"
      },
      {
      "code":"570716",
      "display":"แม่สลองนอก"
      },
      {
      "code":"571502",
      "display":"แม่สลองใน"
      }
      ],
      "code":"5715",
      "display":"แม่ฟ้าหลวง"
      },
      {
      "districts":[
      {
      "code":"570117",
      "display":"จอมหมอกแก้ว"
      },
      {
      "code":"570109",
      "display":"ดงมะดะ"
      },
      {
      "code":"570108",
      "display":"บัวสลี"
      },
      {
      "code":"570110",
      "display":"ป่าก่อดำ"
      },
      {
      "code":"570119",
      "display":"โป่งแพร่"
      }
      ],
      "code":"5716",
      "display":"แม่ลาว"
      },
      {
      "districts":[
      {
      "code":"571005",
      "display":"ท่าก๊อ"
      },
      {
      "code":"570599",
      "display":"ป่าแดด"
      },
      {
      "code":"571006",
      "display":"วาวี"
      },
      {
      "code":"571004",
      "display":"ศรีถ้อย"
      },
      {
      "code":"571007",
      "display":"เจดีย์หลวง"
      },
      {
      "code":"571003",
      "display":"แม่พริก"
      },
      {
      "code":"571001",
      "display":"แม่สรวย"
      }
      ],
      "code":"5710",
      "display":"แม่สรวย"
      },
      {
      "districts":[
      {
      "code":"570908",
      "display":"บ้านด้าย"
      },
      {
      "code":"570905",
      "display":"ศรีเมืองชุม"
      },
      {
      "code":"570902",
      "display":"ห้วยไคร้"
      },
      {
      "code":"570903",
      "display":"เกาะช้าง"
      },
      {
      "code":"570906",
      "display":"เวียงพางคำ"
      },
      {
      "code":"570901",
      "display":"แม่สาย"
      },
      {
      "code":"570909",
      "display":"โป่งงาม"
      },
      {
      "code":"570904",
      "display":"โป่งผา"
      }
      ],
      "code":"5709",
      "display":"แม่สาย"
      }
      ],
      "display":"เชียงราย"
      },
      "เชียงใหม่":{
      "sort_order":"72",
      "code":"50",
      "amphoe":[
      {
      "districts":[
      {
      "code":"500306",
      "display":"บ้านจันทร์"
      },
      {
      "code":"500310",
      "display":"แจ่มหลวง"
      },
      {
      "code":"500309",
      "display":"แม่แดด"
      }
      ],
      "code":"FALSE",
      "display":"กัลยาณิวัฒนา"
      },
      {
      "districts":[
      {
      "code":"500204",
      "display":"ข่วงเปา"
      },
      {
      "code":"500207",
      "display":"ดอยแก้ว"
      },
      {
      "code":"500203",
      "display":"บ้านหลวง"
      },
      {
      "code":"500206",
      "display":"บ้านแปะ"
      },
      {
      "code":"500205",
      "display":"สบเตี๊ยะ"
      },
      {
      "code":"500209",
      "display":"แม่สอย"
      }
      ],
      "code":"5002",
      "display":"จอมทอง"
      },
      {
      "districts":[
      {
      "code":"500507",
      "display":"ตลาดขวัญ"
      },
      {
      "code":"500510",
      "display":"ตลาดใหญ่"
      },
      {
      "code":"500504",
      "display":"ป่าป้อง"
      },
      {
      "code":"500506",
      "display":"ป่าลาน"
      },
      {
      "code":"500513",
      "display":"ป่าเมี่ยง"
      },
      {
      "code":"500503",
      "display":"ลวงเหนือ"
      },
      {
      "code":"500505",
      "display":"สง่าบ้าน"
      },
      {
      "code":"500502",
      "display":"สันปูเลย"
      },
      {
      "code":"500508",
      "display":"สำราญราษฎร์"
      },
      {
      "code":"500501",
      "display":"เชิงดอย"
      },
      {
      "code":"500514",
      "display":"เทพเสด็จ"
      },
      {
      "code":"500509",
      "display":"แม่คือ"
      },
      {
      "code":"500511",
      "display":"แม่ฮ้อยเงิน"
      },
      {
      "code":"500512",
      "display":"แม่โป่ง"
      }
      ],
      "code":"5005",
      "display":"ดอยสะเก็ด"
      },
      {
      "districts":[
      {
      "code":"500208",
      "display":"ดอยหล่อ"
      },
      {
      "code":"500201",
      "display":"ยางคราม"
      },
      {
      "code":"500202",
      "display":"สองแคว"
      },
      {
      "code":"500210",
      "display":"สันติสุข"
      }
      ],
      "code":"5024",
      "display":"ดอยหล่อ"
      },
      {
      "districts":[
      {
      "code":"501701",
      "display":"ดอยเต่า"
      },
      {
      "code":"501702",
      "display":"ท่าเดื่อ"
      },
      {
      "code":"501705",
      "display":"บงตัน"
      },
      {
      "code":"501704",
      "display":"บ้านแอ่น"
      },
      {
      "code":"501703",
      "display":"มืดกา"
      },
      {
      "code":"501706",
      "display":"โปงทุ่ง"
      }
      ],
      "code":"5017",
      "display":"ดอยเต่า"
      },
      {
      "districts":[
      {
      "code":"500903",
      "display":"ม่อนปิ่น"
      },
      {
      "code":"500906",
      "display":"สันทราย"
      },
      {
      "code":"500901",
      "display":"เวียง"
      },
      {
      "code":"500911",
      "display":"แม่ข่า"
      },
      {
      "code":"500910",
      "display":"แม่คะ"
      },
      {
      "code":"500904",
      "display":"แม่งอน"
      },
      {
      "code":"500905",
      "display":"แม่สูน"
      },
      {
      "code":"500912",
      "display":"โป่งน้ำร้อน"
      }
      ],
      "code":"5009",
      "display":"ฝาง"
      },
      {
      "districts":[
      {
      "code":"501102",
      "display":"ทุ่งหลวง"
      },
      {
      "code":"501107",
      "display":"น้ำแพร่"
      },
      {
      "code":"501106",
      "display":"บ้านโป่ง"
      },
      {
      "code":"501103",
      "display":"ป่าตุ้ม"
      },
      {
      "code":"501104",
      "display":"ป่าไหน่"
      },
      {
      "code":"500906",
      "display":"สันทราย"
      },
      {
      "code":"501108",
      "display":"เขื่อนผาก"
      },
      {
      "code":"500901",
      "display":"เวียง"
      },
      {
      "code":"501110",
      "display":"แม่ปั๋ง"
      },
      {
      "code":"501109",
      "display":"แม่แวน"
      },
      {
      "code":"501111",
      "display":"โหล่งขอด"
      }
      ],
      "code":"5011",
      "display":"พร้าว"
      },
      {
      "districts":[
      {
      "code":"500804",
      "display":"บ่อแก้ว"
      },
      {
      "code":"500805",
      "display":"ยั้งเมิน"
      },
      {
      "code":"500802",
      "display":"สะเมิงเหนือ"
      },
      {
      "code":"500801",
      "display":"สะเมิงใต้"
      },
      {
      "code":"500803",
      "display":"แม่สาบ"
      }
      ],
      "code":"5008",
      "display":"สะเมิง"
      },
      {
      "districts":[
      {
      "code":"501312",
      "display":"ต้นเปา"
      },
      {
      "code":"501302",
      "display":"ทรายมูล"
      },
      {
      "code":"501304",
      "display":"บวกค้าง"
      },
      {
      "code":"501303",
      "display":"ร้องวัวแดง"
      },
      {
      "code":"501202",
      "display":"สันกลาง"
      },
      {
      "code":"501301",
      "display":"สันกำแพง"
      },
      {
      "code":"500706",
      "display":"ห้วยทราย"
      },
      {
      "code":"501306",
      "display":"ออนใต้"
      },
      {
      "code":"501305",
      "display":"แช่ช้าง"
      },
      {
      "code":"501310",
      "display":"แม่ปูคา"
      }
      ],
      "code":"5013",
      "display":"สันกำแพง"
      },
      {
      "districts":[
      {
      "code":"501412",
      "display":"ป่าไผ่"
      },
      {
      "code":"501402",
      "display":"สันทรายน้อย"
      },
      {
      "code":"501401",
      "display":"สันทรายหลวง"
      },
      {
      "code":"501404",
      "display":"สันนาเม็ง"
      },
      {
      "code":"501405",
      "display":"สันป่าเปา"
      },
      {
      "code":"501403",
      "display":"สันพระเนตร"
      },
      {
      "code":"501407",
      "display":"หนองจ๊อม"
      },
      {
      "code":"501408",
      "display":"หนองหาร"
      },
      {
      "code":"501406",
      "display":"หนองแหย่ง"
      },
      {
      "code":"501411",
      "display":"เมืองเล็น"
      },
      {
      "code":"501409",
      "display":"แม่แฝก"
      },
      {
      "code":"501410",
      "display":"แม่แฝกใหม่"
      }
      ],
      "code":"5014",
      "display":"สันทราย"
      },
      {
      "districts":[
      {
      "code":"501210",
      "display":"ทุ่งต้อม"
      },
      {
      "code":"501208",
      "display":"ทุ่งสะโตก"
      },
      {
      "code":"501203",
      "display":"ท่าวังพร้าว"
      },
      {
      "code":"501214",
      "display":"น้ำบ่อหลวง"
      },
      {
      "code":"501207",
      "display":"บ้านกลาง"
      },
      {
      "code":"501206",
      "display":"บ้านแม"
      },
      {
      "code":"501204",
      "display":"มะขามหลวง"
      },
      {
      "code":"501215",
      "display":"มะขุนหวาน"
      },
      {
      "code":"501201",
      "display":"ยุหว่า"
      },
      {
      "code":"501202",
      "display":"สันกลาง"
      },
      {
      "code":"501205",
      "display":"แม่ก๊า"
      }
      ],
      "code":"5012",
      "display":"สันป่าตอง"
      },
      {
      "districts":[
      {
      "code":"501905",
      "display":"ขัวมุง"
      },
      {
      "code":"501903",
      "display":"ชมภู"
      },
      {
      "code":"500710",
      "display":"ดอนแก้ว"
      },
      {
      "code":"501908",
      "display":"ท่ากว้าง"
      },
      {
      "code":"501910",
      "display":"ท่าวังตาล"
      },
      {
      "code":"501912",
      "display":"ป่าบง"
      },
      {
      "code":"501901",
      "display":"ยางเนิ้ง"
      },
      {
      "code":"500906",
      "display":"สันทราย"
      },
      {
      "code":"501902",
      "display":"สารภี"
      },
      {
      "code":"501907",
      "display":"หนองผึ้ง"
      },
      {
      "code":"501906",
      "display":"หนองแฝก"
      },
      {
      "code":"501904",
      "display":"ไชยสถาน"
      }
      ],
      "code":"5019",
      "display":"สารภี"
      },
      {
      "districts":[
      {
      "code":"501505",
      "display":"ขุนคง"
      },
      {
      "code":"501107",
      "display":"น้ำแพร่"
      },
      {
      "code":"501510",
      "display":"บ้านปง"
      },
      {
      "code":"501507",
      "display":"บ้านแหวน"
      },
      {
      "code":"501506",
      "display":"สบแม่ข่า"
      },
      {
      "code":"501508",
      "display":"สันผักหวาน"
      },
      {
      "code":"501509",
      "display":"หนองควาย"
      },
      {
      "code":"501504",
      "display":"หนองตอง"
      },
      {
      "code":"501502",
      "display":"หนองแก๋ว"
      },
      {
      "code":"501501",
      "display":"หางดง"
      },
      {
      "code":"501503",
      "display":"หารแก้ว"
      }
      ],
      "code":"5015",
      "display":"หางดง"
      },
      {
      "districts":[
      {
      "code":"501806",
      "display":"นาเกียน"
      },
      {
      "code":"501804",
      "display":"ม่อนจอง"
      },
      {
      "code":"501802",
      "display":"ยางเปียง"
      },
      {
      "code":"501805",
      "display":"สบโขง"
      },
      {
      "code":"501801",
      "display":"อมก๋อย"
      },
      {
      "code":"501803",
      "display":"แม่ตื่น"
      }
      ],
      "code":"5018",
      "display":"อมก๋อย"
      },
      {
      "districts":[
      {
      "code":"501606",
      "display":"นาคอเรือ"
      },
      {
      "code":"501605",
      "display":"บ่อสลี"
      },
      {
      "code":"501604",
      "display":"บ่อหลวง"
      },
      {
      "code":"501603",
      "display":"บ้านตาล"
      },
      {
      "code":"501501",
      "display":"หางดง"
      },
      {
      "code":"501602",
      "display":"ฮอด"
      }
      ],
      "code":"5016",
      "display":"ฮอด"
      },
      {
      "districts":[
      {
      "code":"500407",
      "display":"ทุ่งข้าวพวง"
      },
      {
      "code":"500406",
      "display":"ปิงโค้ง"
      },
      {
      "code":"500401",
      "display":"เชียงดาว"
      },
      {
      "code":"500405",
      "display":"เมืองคอง"
      },
      {
      "code":"500403",
      "display":"เมืองงาย"
      },
      {
      "code":"500402",
      "display":"เมืองนะ"
      },
      {
      "code":"500404",
      "display":"แม่นะ"
      }
      ],
      "code":"5004",
      "display":"เชียงดาว"
      },
      {
      "districts":[
      {
      "code":"500105",
      "display":"ช้างคลาน"
      },
      {
      "code":"500104",
      "display":"ช้างม่อย"
      },
      {
      "code":"500107",
      "display":"ช้างเผือก"
      },
      {
      "code":"500112",
      "display":"ท่าศาลา"
      },
      {
      "code":"500115",
      "display":"ป่าตัน"
      },
      {
      "code":"500110",
      "display":"ป่าแดด"
      },
      {
      "code":"500102",
      "display":"พระสิงห์"
      },
      {
      "code":"500114",
      "display":"ฟ้าฮ่าม"
      },
      {
      "code":"500106",
      "display":"วัดเกต"
      },
      {
      "code":"500101",
      "display":"ศรีภูมิ"
      },
      {
      "code":"500116",
      "display":"สันผีเสื้อ"
      },
      {
      "code":"500108",
      "display":"สุเทพ"
      },
      {
      "code":"500113",
      "display":"หนองป่าครั่ง"
      },
      {
      "code":"500111",
      "display":"หนองหอย"
      },
      {
      "code":"500103",
      "display":"หายยา"
      },
      {
      "code":"500109",
      "display":"แม่เหียะ"
      }
      ],
      "code":"5001",
      "display":"เมืองเชียงใหม่"
      },
      {
      "districts":[
      {
      "code":"502002",
      "display":"เปียงหลวง"
      },
      {
      "code":"502001",
      "display":"เมืองแหง"
      },
      {
      "code":"502003",
      "display":"แสนไห"
      }
      ],
      "code":"5020",
      "display":"เวียงแหง"
      },
      {
      "districts":[
      {
      "code":"500603",
      "display":"ขี้เหล็ก"
      },
      {
      "code":"500710",
      "display":"ดอนแก้ว"
      },
      {
      "code":"500702",
      "display":"ริมเหนือ"
      },
      {
      "code":"500701",
      "display":"ริมใต้"
      },
      {
      "code":"500705",
      "display":"สะลวง"
      },
      {
      "code":"500703",
      "display":"สันโป่ง"
      },
      {
      "code":"500706",
      "display":"ห้วยทราย"
      },
      {
      "code":"500711",
      "display":"เหมืองแก้ว"
      },
      {
      "code":"500709",
      "display":"แม่สา"
      },
      {
      "code":"500707",
      "display":"แม่แรม"
      },
      {
      "code":"500708",
      "display":"โป่งแยง"
      }
      ],
      "code":"5007",
      "display":"แม่ริม"
      },
      {
      "districts":[
      {
      "code":"502205",
      "display":"ดอนเปา"
      },
      {
      "code":"501209",
      "display":"ทุ่งปี้"
      },
      {
      "code":"501213",
      "display":"ทุ่งรวงทอง"
      },
      {
      "code":"501211",
      "display":"บ้านกาด"
      },
      {
      "code":"501212",
      "display":"แม่วิน"
      }
      ],
      "code":"5022",
      "display":"แม่วาง"
      },
      {
      "districts":[
      {
      "code":"501315",
      "display":"ทาเหนือ"
      },
      {
      "code":"501308",
      "display":"บ้านสหกรณ์"
      },
      {
      "code":"501309",
      "display":"ห้วยแก้ว"
      },
      {
      "code":"501316",
      "display":"ออนกลาง"
      },
      {
      "code":"501307",
      "display":"ออนเหนือ"
      },
      {
      "code":"501314",
      "display":"แม่ทา"
      }
      ],
      "code":"5023",
      "display":"แม่ออน"
      },
      {
      "districts":[
      {
      "code":"501005",
      "display":"ท่าตอน"
      },
      {
      "code":"500203",
      "display":"บ้านหลวง"
      },
      {
      "code":"501007",
      "display":"มะลิกา"
      },
      {
      "code":"501003",
      "display":"สันต้นหมื้อ"
      },
      {
      "code":"500995",
      "display":"แม่นาวาง"
      },
      {
      "code":"500996",
      "display":"แม่สาว"
      },
      {
      "code":"500997",
      "display":"แม่อาย"
      }
      ],
      "code":"5010",
      "display":"แม่อาย"
      },
      {
      "districts":[
      {
      "code":"500308",
      "display":"กองแขก"
      },
      {
      "code":"500301",
      "display":"ช่างเคิ่ง"
      },
      {
      "code":"500302",
      "display":"ท่าผา"
      },
      {
      "code":"500303",
      "display":"บ้านทับ"
      },
      {
      "code":"500307",
      "display":"ปางหินฝน"
      },
      {
      "code":"500305",
      "display":"แม่นาจร"
      },
      {
      "code":"500304",
      "display":"แม่ศึก"
      }
      ],
      "code":"5003",
      "display":"แม่แจ่ม"
      },
      {
      "districts":[
      {
      "code":"500612",
      "display":"กื้ดช้าง"
      },
      {
      "code":"500603",
      "display":"ขี้เหล็ก"
      },
      {
      "code":"500604",
      "display":"ช่อแล"
      },
      {
      "code":"500611",
      "display":"บ้านช้าง"
      },
      {
      "code":"500607",
      "display":"บ้านเป้า"
      },
      {
      "code":"500609",
      "display":"ป่าแป๋"
      },
      {
      "code":"500606",
      "display":"สบเปิง"
      },
      {
      "code":"500608",
      "display":"สันป่ายาง"
      },
      {
      "code":"500601",
      "display":"สันมหาพน"
      },
      {
      "code":"500613",
      "display":"อินทขิล"
      },
      {
      "code":"500610",
      "display":"เมืองก๋าย"
      },
      {
      "code":"500605",
      "display":"แม่หอพระ"
      },
      {
      "code":"500602",
      "display":"แม่แตง"
      }
      ],
      "code":"5006",
      "display":"แม่แตง"
      },
      {
      "districts":[
      {
      "code":"500902",
      "display":"ปงตำ"
      },
      {
      "code":"500907",
      "display":"ศรีดงเย็น"
      },
      {
      "code":"500909",
      "display":"หนองบัว"
      },
      {
      "code":"500908",
      "display":"แม่ทะลบ"
      }
      ],
      "code":"5021",
      "display":"ไชยปราการ"
      }
      ],
      "display":"เชียงใหม่"
      },
      "เพชรบุรี":{
      "sort_order":"73",
      "code":"76",
      "amphoe":[
      {
      "districts":[
      {
      "code":"760401",
      "display":"ชะอำ"
      },
      {
      "code":"760409",
      "display":"ดอนขุนห้วย"
      },
      {
      "code":"760403",
      "display":"นายาง"
      },
      {
      "code":"760402",
      "display":"บางเก่า"
      },
      {
      "code":"760408",
      "display":"สามพระยา"
      },
      {
      "code":"760405",
      "display":"หนองศาลา"
      },
      {
      "code":"760406",
      "display":"ห้วยทรายเหนือ"
      },
      {
      "code":"760404",
      "display":"เขาใหญ่"
      },
      {
      "code":"760407",
      "display":"ไร่ใหม่พัฒนา"
      }
      ],
      "code":"7604",
      "display":"ชะอำ"
      },
      {
      "districts":[
      {
      "code":"760511",
      "display":"กลัดหลวง"
      },
      {
      "code":"760502",
      "display":"ท่าคอย"
      },
      {
      "code":"760501",
      "display":"ท่ายาง"
      },
      {
      "code":"760514",
      "display":"ท่าแลง"
      },
      {
      "code":"760506",
      "display":"ท่าไม้รวก"
      },
      {
      "code":"760515",
      "display":"บ้านในดง"
      },
      {
      "code":"760512",
      "display":"ปึกเตียน"
      },
      {
      "code":"760505",
      "display":"มาบปลาเค้า"
      },
      {
      "code":"760503",
      "display":"ยางหย่อง"
      },
      {
      "code":"760507",
      "display":"วังไคร้"
      },
      {
      "code":"760504",
      "display":"หนองจอก"
      },
      {
      "code":"760513",
      "display":"เขากระปุก"
      }
      ],
      "code":"7605",
      "display":"ท่ายาง"
      },
      {
      "districts":[
      {
      "code":"760604",
      "display":"ตำหรุ"
      },
      {
      "code":"760617",
      "display":"ถ้ำรงค์"
      },
      {
      "code":"760616",
      "display":"ท่าช้าง"
      },
      {
      "code":"760607",
      "display":"ท่าเสน"
      },
      {
      "code":"760603",
      "display":"บ้านทาน"
      },
      {
      "code":"760601",
      "display":"บ้านลาด"
      },
      {
      "code":"760602",
      "display":"บ้านหาด"
      },
      {
      "code":"760610",
      "display":"ลาดโพธิ์"
      },
      {
      "code":"760605",
      "display":"สมอพลือ"
      },
      {
      "code":"760611",
      "display":"สะพานไกร"
      },
      {
      "code":"760608",
      "display":"หนองกระเจ็ด"
      },
      {
      "code":"760609",
      "display":"หนองกะปุ"
      },
      {
      "code":"760615",
      "display":"ห้วยข้อง"
      },
      {
      "code":"760618",
      "display":"ห้วยลึก"
      },
      {
      "code":"760613",
      "display":"โรงเข้"
      },
      {
      "code":"760606",
      "display":"ไร่มะขาม"
      },
      {
      "code":"760614",
      "display":"ไร่สะท้อน"
      },
      {
      "code":"760612",
      "display":"ไร่โคก"
      }
      ],
      "code":"7606",
      "display":"บ้านลาด"
      },
      {
      "districts":[
      {
      "code":"760709",
      "display":"ท่าแร้ง"
      },
      {
      "code":"760710",
      "display":"ท่าแร้งออก"
      },
      {
      "code":"760702",
      "display":"บางขุนไทร"
      },
      {
      "code":"760708",
      "display":"บางครก"
      },
      {
      "code":"760706",
      "display":"บางตะบูน"
      },
      {
      "code":"760707",
      "display":"บางตะบูนออก"
      },
      {
      "code":"760704",
      "display":"บางแก้ว"
      },
      {
      "code":"760701",
      "display":"บ้านแหลม"
      },
      {
      "code":"760703",
      "display":"ปากทะเล"
      },
      {
      "code":"760705",
      "display":"แหลมผักเบี้ย"
      }
      ],
      "code":"7607",
      "display":"บ้านแหลม"
      },
      {
      "districts":[
      {
      "code":"760304",
      "display":"ท่าตะคร้อ"
      },
      {
      "code":"760302",
      "display":"ยางน้ำกลัดเหนือ"
      },
      {
      "code":"760303",
      "display":"ยางน้ำกลัดใต้"
      },
      {
      "code":"760299",
      "display":"หนองหญ้าปล้อง"
      }
      ],
      "code":"7603",
      "display":"หนองหญ้าปล้อง"
      },
      {
      "districts":[
      {
      "code":"760204",
      "display":"ทับคาง"
      },
      {
      "code":"760203",
      "display":"บางเค็ม"
      },
      {
      "code":"760202",
      "display":"สระพัง"
      },
      {
      "code":"760207",
      "display":"หนองชุมพล"
      },
      {
      "code":"760210",
      "display":"หนองชุมพลเหนือ"
      },
      {
      "code":"760206",
      "display":"หนองปรง"
      },
      {
      "code":"760205",
      "display":"หนองปลาไหล"
      },
      {
      "code":"760209",
      "display":"ห้วยท่าช้าง"
      },
      {
      "code":"760208",
      "display":"ห้วยโรง"
      },
      {
      "code":"760201",
      "display":"เขาย้อย"
      }
      ],
      "code":"7602",
      "display":"เขาย้อย"
      },
      {
      "districts":[
      {
      "code":"760102",
      "display":"คลองกระแชง"
      },
      {
      "code":"760113",
      "display":"ช่องสะแก"
      },
      {
      "code":"760122",
      "display":"ดอนยาง"
      },
      {
      "code":"760119",
      "display":"ต้นมะพร้าว"
      },
      {
      "code":"760112",
      "display":"ต้นมะม่วง"
      },
      {
      "code":"760101",
      "display":"ท่าราบ"
      },
      {
      "code":"760105",
      "display":"ธงชัย"
      },
      {
      "code":"760104",
      "display":"นาพันสาม"
      },
      {
      "code":"760114",
      "display":"นาวุ้ง"
      },
      {
      "code":"760110",
      "display":"บางจาก"
      },
      {
      "code":"760103",
      "display":"บางจาน"
      },
      {
      "code":"760106",
      "display":"บ้านกุ่ม"
      },
      {
      "code":"760111",
      "display":"บ้านหม้อ"
      },
      {
      "code":"760120",
      "display":"วังตะโก"
      },
      {
      "code":"760115",
      "display":"สำมะโรง"
      },
      {
      "code":"760123",
      "display":"หนองขนาน"
      },
      {
      "code":"760124",
      "display":"หนองพลับ"
      },
      {
      "code":"760107",
      "display":"หนองโสน"
      },
      {
      "code":"760118",
      "display":"หัวสะพาน"
      },
      {
      "code":"760117",
      "display":"หาดเจ้าสำราญ"
      },
      {
      "code":"760109",
      "display":"เวียงคอย"
      },
      {
      "code":"760116",
      "display":"โพพระ"
      },
      {
      "code":"760121",
      "display":"โพไร่หวาน"
      },
      {
      "code":"760108",
      "display":"ไร่ส้ม"
      }
      ],
      "code":"7601",
      "display":"เมืองเพชรบุรี"
      },
      {
      "districts":[
      {
      "code":"760804",
      "display":"ป่าเด็ง"
      },
      {
      "code":"760805",
      "display":"พุสวรรค์"
      },
      {
      "code":"760508",
      "display":"วังจันทร์"
      },
      {
      "code":"760509",
      "display":"สองพี่น้อง"
      },
      {
      "code":"760806",
      "display":"ห้วยแม่เพรียง"
      },
      {
      "code":"760510",
      "display":"แก่งกระจาน"
      }
      ],
      "code":"7608",
      "display":"แก่งกระจาน"
      }
      ],
      "display":"เพชรบุรี"
      },
      "เพชรบูรณ์":{
      "sort_order":"74",
      "code":"67",
      "amphoe":[
      {
      "districts":[
      {
      "code":"670201",
      "display":"ชนแดน"
      },
      {
      "code":"670208",
      "display":"ซับพุทรา"
      },
      {
      "code":"670202",
      "display":"ดงขุย"
      },
      {
      "code":"670209",
      "display":"ตะกุดไร"
      },
      {
      "code":"670203",
      "display":"ท่าข้าม"
      },
      {
      "code":"670206",
      "display":"บ้านกล้วย"
      },
      {
      "code":"670204",
      "display":"พุทธบาท"
      },
      {
      "code":"670205",
      "display":"ลาดแค"
      },
      {
      "code":"670210",
      "display":"ศาลาลาย"
      }
      ],
      "code":"6702",
      "display":"ชนแดน"
      },
      {
      "districts":[
      {
      "code":"670499",
      "display":"น้ำหนาว"
      },
      {
      "code":"670903",
      "display":"วังกวาง"
      },
      {
      "code":"670902",
      "display":"หลักด่าน"
      },
      {
      "code":"670904",
      "display":"โคกมน"
      }
      ],
      "code":"6709",
      "display":"น้ำหนาว"
      },
      {
      "districts":[
      {
      "code":"670804",
      "display":"กันจุ"
      },
      {
      "code":"670801",
      "display":"ซับสมอทอด"
      },
      {
      "code":"670802",
      "display":"ซับไม้แดง"
      },
      {
      "code":"670809",
      "display":"บึงสามพัน"
      },
      {
      "code":"670806",
      "display":"พญาวัง"
      },
      {
      "code":"670805",
      "display":"วังพิกุล"
      },
      {
      "code":"670807",
      "display":"ศรีมงคล"
      },
      {
      "code":"670808",
      "display":"สระแก้ว"
      },
      {
      "code":"670803",
      "display":"หนองแจง"
      }
      ],
      "code":"6708",
      "display":"บึงสามพัน"
      },
      {
      "districts":[
      {
      "code":"671003",
      "display":"ซับเปิบ"
      },
      {
      "code":"670298",
      "display":"ท้ายดง"
      },
      {
      "code":"671005",
      "display":"วังศาล"
      },
      {
      "code":"671004",
      "display":"วังหิน"
      },
      {
      "code":"670299",
      "display":"วังโป่ง"
      }
      ],
      "code":"6710",
      "display":"วังโป่ง"
      },
      {
      "districts":[
      {
      "code":"670514",
      "display":"ซับน้อย"
      },
      {
      "code":"670510",
      "display":"ซับสมบูรณ์"
      },
      {
      "code":"670501",
      "display":"ท่าโรง"
      },
      {
      "code":"670114",
      "display":"น้ำร้อน"
      },
      {
      "code":"670511",
      "display":"บึงกระจับ"
      },
      {
      "code":"670506",
      "display":"บ่อรัง"
      },
      {
      "code":"670508",
      "display":"พุขาม"
      },
      {
      "code":"670507",
      "display":"พุเตย"
      },
      {
      "code":"670509",
      "display":"ภูน้ำหยด"
      },
      {
      "code":"670513",
      "display":"ยางสาว"
      },
      {
      "code":"670512",
      "display":"วังใหญ่"
      },
      {
      "code":"670502",
      "display":"สระประดู่"
      },
      {
      "code":"670503",
      "display":"สามแยก"
      },
      {
      "code":"670504",
      "display":"โคกปรง"
      }
      ],
      "code":"6705",
      "display":"วิเชียรบุรี"
      },
      {
      "districts":[
      {
      "code":"670597",
      "display":"คลองกระจัง"
      },
      {
      "code":"670595",
      "display":"นาสนุ่น"
      },
      {
      "code":"670607",
      "display":"ประดู่งาม"
      },
      {
      "code":"670599",
      "display":"ศรีเทพ"
      },
      {
      "code":"670598",
      "display":"สระกรวด"
      },
      {
      "code":"670606",
      "display":"หนองย่างทอย"
      },
      {
      "code":"670605",
      "display":"โคกสะอาด"
      }
      ],
      "code":"6706",
      "display":"ศรีเทพ"
      },
      {
      "districts":[
      {
      "code":"670701",
      "display":"กองทูล"
      },
      {
      "code":"670713",
      "display":"ท่าด้วง"
      },
      {
      "code":"670704",
      "display":"ท่าแดง"
      },
      {
      "code":"670702",
      "display":"นาเฉลียง"
      },
      {
      "code":"670709",
      "display":"บัววัฒนา"
      },
      {
      "code":"670706",
      "display":"บ่อไทย"
      },
      {
      "code":"670703",
      "display":"บ้านโภชน์"
      },
      {
      "code":"670712",
      "display":"ยางงาม"
      },
      {
      "code":"670708",
      "display":"วังท่าดี"
      },
      {
      "code":"670711",
      "display":"วังโบสถ์"
      },
      {
      "code":"670710",
      "display":"หนองไผ่"
      },
      {
      "code":"670707",
      "display":"ห้วยโป่ง"
      },
      {
      "code":"670705",
      "display":"เพชรละคร"
      }
      ],
      "code":"6707",
      "display":"หนองไผ่"
      },
      {
      "districts":[
      {
      "code":"670320",
      "display":"ช้างตะลูด"
      },
      {
      "code":"670303",
      "display":"ตาลเดี่ยว"
      },
      {
      "code":"670308",
      "display":"ท่าอิบุญ"
      },
      {
      "code":"670312",
      "display":"น้ำก้อ"
      },
      {
      "code":"670314",
      "display":"น้ำชุน"
      },
      {
      "code":"670306",
      "display":"น้ำเฮี้ย"
      },
      {
      "code":"670317",
      "display":"บุ่งคล้า"
      },
      {
      "code":"670318",
      "display":"บุ่งน้ำเต้า"
      },
      {
      "code":"670319",
      "display":"บ้านกลาง"
      },
      {
      "code":"670310",
      "display":"บ้านติ้ว"
      },
      {
      "code":"670323",
      "display":"บ้านหวาย"
      },
      {
      "code":"670309",
      "display":"บ้านโสก"
      },
      {
      "code":"670321",
      "display":"บ้านไร่"
      },
      {
      "code":"670313",
      "display":"ปากช่อง"
      },
      {
      "code":"670322",
      "display":"ปากดุก"
      },
      {
      "code":"670304",
      "display":"ฝายนาแซง"
      },
      {
      "code":"670316",
      "display":"ลานบ่า"
      },
      {
      "code":"670302",
      "display":"วัดป่า"
      },
      {
      "code":"670307",
      "display":"สักหลง"
      },
      {
      "code":"670305",
      "display":"หนองสว่าง"
      },
      {
      "code":"670315",
      "display":"หนองไขว่"
      },
      {
      "code":"670301",
      "display":"หล่มสัก"
      },
      {
      "code":"670311",
      "display":"ห้วยไร่"
      }
      ],
      "code":"6703",
      "display":"หล่มสัก"
      },
      {
      "districts":[
      {
      "code":"670409",
      "display":"ตาดกลอย"
      },
      {
      "code":"670402",
      "display":"นาซำ"
      },
      {
      "code":"670408",
      "display":"นาเกาะ"
      },
      {
      "code":"670406",
      "display":"นาแซง"
      },
      {
      "code":"670404",
      "display":"บ้านเนิน"
      },
      {
      "code":"670407",
      "display":"วังบาล"
      },
      {
      "code":"670405",
      "display":"ศิลา"
      },
      {
      "code":"670401",
      "display":"หล่มเก่า"
      },
      {
      "code":"670403",
      "display":"หินฮาว"
      }
      ],
      "code":"6704",
      "display":"หล่มเก่า"
      },
      {
      "districts":[
      {
      "code":"671101",
      "display":"ทุ่งสมอ"
      },
      {
      "code":"671104",
      "display":"ริมสีม่วง"
      },
      {
      "code":"671105",
      "display":"สะเดาะพง"
      },
      {
      "code":"671106",
      "display":"หนองแม่นา"
      },
      {
      "code":"671103",
      "display":"เขาค้อ"
      },
      {
      "code":"671107",
      "display":"เข็กน้อย"
      },
      {
      "code":"670399",
      "display":"แคมป์สน"
      }
      ],
      "code":"6711",
      "display":"เขาค้อ"
      },
      {
      "districts":[
      {
      "code":"670110",
      "display":"ชอนไพร"
      },
      {
      "code":"670108",
      "display":"ดงมูลเหล็ก"
      },
      {
      "code":"670102",
      "display":"ตะเบาะ"
      },
      {
      "code":"670107",
      "display":"ท่าพล"
      },
      {
      "code":"670106",
      "display":"นางั่ว"
      },
      {
      "code":"670111",
      "display":"นาป่า"
      },
      {
      "code":"670112",
      "display":"นายม"
      },
      {
      "code":"670114",
      "display":"น้ำร้อน"
      },
      {
      "code":"670109",
      "display":"บ้านโคก"
      },
      {
      "code":"670103",
      "display":"บ้านโตก"
      },
      {
      "code":"670105",
      "display":"ป่าเลา"
      },
      {
      "code":"670117",
      "display":"ระวิง"
      },
      {
      "code":"670113",
      "display":"วังชมภู"
      },
      {
      "code":"670104",
      "display":"สะเดียง"
      },
      {
      "code":"670115",
      "display":"ห้วยสะแก"
      },
      {
      "code":"670116",
      "display":"ห้วยใหญ่"
      },
      {
      "code":"670101",
      "display":"ในเมือง"
      }
      ],
      "code":"6701",
      "display":"เมืองเพชรบูรณ์"
      }
      ],
      "display":"เพชรบูรณ์"
      },
      "เลย":{
      "sort_order":"75",
      "code":"42",
      "amphoe":[
      {
      "districts":[
      {
      "code":"420507",
      "display":"กกสะทอน"
      },
      {
      "code":"420501",
      "display":"ด่านซ้าย"
      },
      {
      "code":"420503",
      "display":"นาดี"
      },
      {
      "code":"420510",
      "display":"นาหอ"
      },
      {
      "code":"420502",
      "display":"ปากหมัน"
      },
      {
      "code":"420509",
      "display":"วังยาว"
      },
      {
      "code":"420506",
      "display":"อิปุ่ม"
      },
      {
      "code":"420504",
      "display":"โคกงาม"
      },
      {
      "code":"420508",
      "display":"โป่ง"
      },
      {
      "code":"420505",
      "display":"โพนสูง"
      }
      ],
      "code":"4205",
      "display":"ด่านซ้าย"
      },
      {
      "districts":[
      {
      "code":"420801",
      "display":"ท่าลี่"
      },
      {
      "code":"420806",
      "display":"น้ำทูน"
      },
      {
      "code":"420804",
      "display":"น้ำแคม"
      },
      {
      "code":"420802",
      "display":"หนองผือ"
      },
      {
      "code":"420803",
      "display":"อาฮี"
      },
      {
      "code":"420805",
      "display":"โคกใหญ่"
      }
      ],
      "code":"4208",
      "display":"ท่าลี่"
      },
      {
      "districts":[
      {
      "code":"420204",
      "display":"ท่าสวรรค์"
      },
      {
      "code":"420203",
      "display":"ท่าสะอาด"
      },
      {
      "code":"420202",
      "display":"นาดอกคำ"
      },
      {
      "code":"420201",
      "display":"นาด้วง"
      }
      ],
      "code":"4202",
      "display":"นาด้วง"
      },
      {
      "districts":[
      {
      "code":"420603",
      "display":"นาพึง"
      },
      {
      "code":"420604",
      "display":"นามาลา"
      },
      {
      "code":"420599",
      "display":"นาแห้ว"
      },
      {
      "code":"420605",
      "display":"เหล่ากอหก"
      },
      {
      "code":"420594",
      "display":"แสงภา"
      }
      ],
      "code":"4206",
      "display":"นาแห้ว"
      },
      {
      "districts":[
      {
      "code":"420406",
      "display":"ชมเจริญ"
      },
      {
      "code":"420401",
      "display":"ปากชม"
      },
      {
      "code":"420403",
      "display":"หาดคัมภีร์"
      },
      {
      "code":"420404",
      "display":"ห้วยบ่อซืน"
      },
      {
      "code":"420405",
      "display":"ห้วยพิชัย"
      },
      {
      "code":"420402",
      "display":"เชียงกลม"
      }
      ],
      "code":"4204",
      "display":"ปากชม"
      },
      {
      "districts":[
      {
      "code":"421003",
      "display":"ท่าช้างคล้อง"
      },
      {
      "code":"421205",
      "display":"บ้านเพิ่ม"
      },
      {
      "code":"421004",
      "display":"ผาขาว"
      },
      {
      "code":"421009",
      "display":"โนนปอแดง"
      },
      {
      "code":"421006",
      "display":"โนนป่าซาง"
      }
      ],
      "code":"4212",
      "display":"ผาขาว"
      },
      {
      "districts":[
      {
      "code":"421005",
      "display":"ผานกเค้า"
      },
      {
      "code":"421007",
      "display":"ภูกระดึง"
      },
      {
      "code":"421001",
      "display":"ศรีฐาน"
      },
      {
      "code":"421010",
      "display":"ห้วยส้ม"
      }
      ],
      "code":"4210",
      "display":"ภูกระดึง"
      },
      {
      "districts":[
      {
      "code":"420999",
      "display":"ภูหอ"
      },
      {
      "code":"420998",
      "display":"หนองคัน"
      },
      {
      "code":"421104",
      "display":"ห้วยสีเสียด"
      },
      {
      "code":"421105",
      "display":"เลยวังไสย์"
      },
      {
      "code":"421106",
      "display":"แก่งศรีภูมิ"
      }
      ],
      "code":"4211",
      "display":"ภูหลวง"
      },
      {
      "districts":[
      {
      "code":"420597",
      "display":"ท่าศาลา"
      },
      {
      "code":"420595",
      "display":"ปลาบ่า"
      },
      {
      "code":"420593",
      "display":"ร่องจิก"
      },
      {
      "code":"420705",
      "display":"ลาดค่าง"
      },
      {
      "code":"420706",
      "display":"สานตม"
      },
      {
      "code":"420598",
      "display":"หนองบัว"
      }
      ],
      "code":"4207",
      "display":"ภูเรือ"
      },
      {
      "districts":[
      {
      "code":"420902",
      "display":"ทรายขาว"
      },
      {
      "code":"420905",
      "display":"ปากปวน"
      },
      {
      "code":"420906",
      "display":"ผาน้อย"
      },
      {
      "code":"420910",
      "display":"ผาบิ้ง"
      },
      {
      "code":"420901",
      "display":"วังสะพุง"
      },
      {
      "code":"420913",
      "display":"ศรีสงคราม"
      },
      {
      "code":"420904",
      "display":"หนองงิ้ว"
      },
      {
      "code":"420903",
      "display":"หนองหญ้าปล้อง"
      },
      {
      "code":"420911",
      "display":"เขาหลวง"
      },
      {
      "code":"420912",
      "display":"โคกขมิ้น"
      }
      ],
      "code":"4209",
      "display":"วังสะพุง"
      },
      {
      "districts":[
      {
      "code":"421011",
      "display":"ตาดข่า"
      },
      {
      "code":"421002",
      "display":"ปวนพุ"
      },
      {
      "code":"421008",
      "display":"หนองหิน"
      }
      ],
      "code":"4214",
      "display":"หนองหิน"
      },
      {
      "districts":[
      {
      "code":"420307",
      "display":"จอมศรี"
      },
      {
      "code":"420302",
      "display":"ธาตุ"
      },
      {
      "code":"420303",
      "display":"นาซ่าว"
      },
      {
      "code":"420306",
      "display":"บุฮม"
      },
      {
      "code":"420305",
      "display":"ปากตม"
      },
      {
      "code":"420308",
      "display":"หาดทรายขาว"
      },
      {
      "code":"420304",
      "display":"เขาแก้ว"
      },
      {
      "code":"420301",
      "display":"เชียงคาน"
      }
      ],
      "code":"4203",
      "display":"เชียงคาน"
      },
      {
      "districts":[
      {
      "code":"420104",
      "display":"กกดู่"
      },
      {
      "code":"420114",
      "display":"กกทอง"
      },
      {
      "code":"420101",
      "display":"กุดป่อง"
      },
      {
      "code":"420111",
      "display":"ชัยพฤกษ์"
      },
      {
      "code":"420109",
      "display":"นาดินดำ"
      },
      {
      "code":"420107",
      "display":"นาอาน"
      },
      {
      "code":"420103",
      "display":"นาอ้อ"
      },
      {
      "code":"420112",
      "display":"นาแขม"
      },
      {
      "code":"420108",
      "display":"นาโป่ง"
      },
      {
      "code":"420110",
      "display":"น้ำสวย"
      },
      {
      "code":"420105",
      "display":"น้ำหมาน"
      },
      {
      "code":"420113",
      "display":"ศรีสองรัก"
      },
      {
      "code":"420102",
      "display":"เมือง"
      },
      {
      "code":"420106",
      "display":"เสี้ยว"
      }
      ],
      "code":"4201",
      "display":"เมืองเลย"
      },
      {
      "districts":[
      {
      "code":"420914",
      "display":"ทรัพย์ไพวัลย์"
      },
      {
      "code":"420909",
      "display":"ผาสามยอด"
      },
      {
      "code":"420908",
      "display":"ผาอินทร์แปลง"
      },
      {
      "code":"420907",
      "display":"เอราวัณ"
      }
      ],
      "code":"4213",
      "display":"เอราวัณ"
      }
      ],
      "display":"เลย"
      },
      "แพร่":{
      "sort_order":"76",
      "code":"54",
      "amphoe":[
      {
      "districts":[
      {
      "code":"540207",
      "display":"ทุ่งศรี"
      },
      {
      "code":"540205",
      "display":"น้ำเลา"
      },
      {
      "code":"540206",
      "display":"บ้านเวียง"
      },
      {
      "code":"540201",
      "display":"ร้องกวาง"
      },
      {
      "code":"540204",
      "display":"ร้องเข็ม"
      },
      {
      "code":"540213",
      "display":"ห้วยโรง"
      },
      {
      "code":"540214",
      "display":"แม่ทราย"
      },
      {
      "code":"540208",
      "display":"แม่ยางตาล"
      },
      {
      "code":"540215",
      "display":"แม่ยางร้อง"
      },
      {
      "code":"540209",
      "display":"แม่ยางฮ่อ"
      },
      {
      "code":"540210",
      "display":"ไผ่โทน"
      }
      ],
      "code":"5402",
      "display":"ร้องกวาง"
      },
      {
      "districts":[
      {
      "code":"540303",
      "display":"ต้าผามอก"
      },
      {
      "code":"540307",
      "display":"ทุ่งแล้ง"
      },
      {
      "code":"540308",
      "display":"บ่อเหล็กลอง"
      },
      {
      "code":"540302",
      "display":"บ้านปิน"
      },
      {
      "code":"540305",
      "display":"ปากกาง"
      },
      {
      "code":"540306",
      "display":"หัวทุ่ง"
      },
      {
      "code":"540301",
      "display":"ห้วยอ้อ"
      },
      {
      "code":"540304",
      "display":"เวียงต้า"
      },
      {
      "code":"540309",
      "display":"แม่ปาน"
      }
      ],
      "code":"5403",
      "display":"ลอง"
      },
      {
      "districts":[
      {
      "code":"540704",
      "display":"นาพูน"
      },
      {
      "code":"540706",
      "display":"ป่าสัก"
      },
      {
      "code":"540701",
      "display":"วังชิ้น"
      },
      {
      "code":"540702",
      "display":"สรอย"
      },
      {
      "code":"540703",
      "display":"แม่ป้าก"
      },
      {
      "code":"540705",
      "display":"แม่พุง"
      },
      {
      "code":"540707",
      "display":"แม่เกิ๋ง"
      }
      ],
      "code":"5407",
      "display":"วังชิ้น"
      },
      {
      "districts":[
      {
      "code":"540608",
      "display":"ทุ่งน้าว"
      },
      {
      "code":"540602",
      "display":"บ้านกลาง"
      },
      {
      "code":"540601",
      "display":"บ้านหนุน"
      },
      {
      "code":"540606",
      "display":"สะเอียบ"
      },
      {
      "code":"540605",
      "display":"หัวเมือง"
      },
      {
      "code":"540603",
      "display":"ห้วยหม้าย"
      },
      {
      "code":"540604",
      "display":"เตาปูน"
      },
      {
      "code":"540607",
      "display":"แดนชุมพล"
      }
      ],
      "code":"5406",
      "display":"สอง"
      },
      {
      "districts":[
      {
      "code":"540404",
      "display":"ดอนมูล"
      },
      {
      "code":"540103",
      "display":"น้ำชำ"
      },
      {
      "code":"540406",
      "display":"บ้านกวาง"
      },
      {
      "code":"540408",
      "display":"บ้านกาศ"
      },
      {
      "code":"540407",
      "display":"บ้านปง"
      },
      {
      "code":"540405",
      "display":"บ้านเหล่า"
      },
      {
      "code":"540412",
      "display":"พระหลวง"
      },
      {
      "code":"540409",
      "display":"ร่องกาศ"
      },
      {
      "code":"540410",
      "display":"สบสาย"
      },
      {
      "code":"540401",
      "display":"สูงเม่น"
      },
      {
      "code":"540403",
      "display":"หัวฝาย"
      },
      {
      "code":"540411",
      "display":"เวียงทอง"
      }
      ],
      "code":"5404",
      "display":"สูงเม่น"
      },
      {
      "districts":[
      {
      "code":"540805",
      "display":"ตำหนักธรรม"
      },
      {
      "code":"540806",
      "display":"ทุ่งแค้ว"
      },
      {
      "code":"540211",
      "display":"น้ำรัด"
      },
      {
      "code":"540212",
      "display":"วังหลวง"
      },
      {
      "code":"540202",
      "display":"หนองม่วงไข่"
      },
      {
      "code":"540114",
      "display":"แม่คำมี"
      }
      ],
      "code":"5408",
      "display":"หนองม่วงไข่"
      },
      {
      "districts":[
      {
      "code":"540505",
      "display":"ปงป่าหวาย"
      },
      {
      "code":"540504",
      "display":"ห้วยไร่"
      },
      {
      "code":"540501",
      "display":"เด่นชัย"
      },
      {
      "code":"540502",
      "display":"แม่จั๊วะ"
      },
      {
      "code":"540503",
      "display":"ไทรย้อย"
      }
      ],
      "code":"5405",
      "display":"เด่นชัย"
      },
      {
      "districts":[
      {
      "code":"540120",
      "display":"กาญจนา"
      },
      {
      "code":"540118",
      "display":"ช่อแฮ"
      },
      {
      "code":"540115",
      "display":"ทุ่งกวาว"
      },
      {
      "code":"540105",
      "display":"ทุ่งโฮ้ง"
      },
      {
      "code":"540116",
      "display":"ท่าข้าม"
      },
      {
      "code":"540102",
      "display":"นาจักร"
      },
      {
      "code":"540103",
      "display":"น้ำชำ"
      },
      {
      "code":"540111",
      "display":"บ้านถิ่น"
      },
      {
      "code":"540104",
      "display":"ป่าแดง"
      },
      {
      "code":"540110",
      "display":"ป่าแมต"
      },
      {
      "code":"540119",
      "display":"ร่องฟอง"
      },
      {
      "code":"540107",
      "display":"วังธง"
      },
      {
      "code":"FALSE",
      "display":"วังหงษ์"
      },
      {
      "code":"540112",
      "display":"สวนเขื่อน"
      },
      {
      "code":"540109",
      "display":"ห้วยม้า"
      },
      {
      "code":"540106",
      "display":"เหมืองหม้อ"
      },
      {
      "code":"540114",
      "display":"แม่คำมี"
      },
      {
      "code":"540117",
      "display":"แม่ยม"
      },
      {
      "code":"540108",
      "display":"แม่หล่าย"
      },
      {
      "code":"540101",
      "display":"ในเวียง"
      }
      ],
      "code":"5401",
      "display":"เมืองแพร่"
      }
      ],
      "display":"แพร่"
      },
      "แม่ฮ่องสอน":{
      "sort_order":"77",
      "code":"58",
      "amphoe":[
      {
      "districts":[
      {
      "code":"580201",
      "display":"ขุนยวม"
      },
      {
      "code":"580203",
      "display":"เมืองปอน"
      },
      {
      "code":"580205",
      "display":"แม่กิ๊"
      },
      {
      "code":"580204",
      "display":"แม่ยวมน้อย"
      },
      {
      "code":"580206",
      "display":"แม่อูคอ"
      },
      {
      "code":"580202",
      "display":"แม่เงา"
      }
      ],
      "code":"5802",
      "display":"ขุนยวม"
      },
      {
      "districts":[
      {
      "code":"580703",
      "display":"ถ้ำลอด"
      },
      {
      "code":"580704",
      "display":"นาปู่ป้อม"
      },
      {
      "code":"580107",
      "display":"ปางมะผ้า"
      },
      {
      "code":"580108",
      "display":"สบป่อง"
      }
      ],
      "code":"5807",
      "display":"ปางมะผ้า"
      },
      {
      "districts":[
      {
      "code":"580305",
      "display":"ทุ่งยาว"
      },
      {
      "code":"580306",
      "display":"เมืองแปง"
      },
      {
      "code":"580302",
      "display":"เวียงเหนือ"
      },
      {
      "code":"580301",
      "display":"เวียงใต้"
      },
      {
      "code":"580303",
      "display":"แม่นาเติง"
      },
      {
      "code":"580304",
      "display":"แม่ฮี้"
      },
      {
      "code":"580307",
      "display":"โป่งสา"
      }
      ],
      "code":"5803",
      "display":"ปาย"
      },
      {
      "districts":[
      {
      "code":"580603",
      "display":"กองก๋อย"
      },
      {
      "code":"580605",
      "display":"ป่าโปง"
      },
      {
      "code":"580499",
      "display":"สบเมย"
      },
      {
      "code":"580498",
      "display":"แม่คะตวน"
      },
      {
      "code":"580604",
      "display":"แม่สวด"
      },
      {
      "code":"580606",
      "display":"แม่สามแลบ"
      }
      ],
      "code":"5806",
      "display":"สบเมย"
      },
      {
      "districts":[
      {
      "code":"580101",
      "display":"จองคำ"
      },
      {
      "code":"580104",
      "display":"ปางหมู"
      },
      {
      "code":"580103",
      "display":"ผาบ่อง"
      },
      {
      "code":"580105",
      "display":"หมอกจำแป่"
      },
      {
      "code":"580109",
      "display":"ห้วยปูลิง"
      },
      {
      "code":"580106",
      "display":"ห้วยผา"
      },
      {
      "code":"580102",
      "display":"ห้วยโป่ง"
      }
      ],
      "code":"5801",
      "display":"เมืองแม่ฮ่องสอน"
      },
      {
      "districts":[
      {
      "code":"580508",
      "display":"ขุนแม่ลาน้อย"
      },
      {
      "code":"580503",
      "display":"ท่าผาปุ้ม"
      },
      {
      "code":"580507",
      "display":"สันติคีรี"
      },
      {
      "code":"580505",
      "display":"ห้วยห้อม"
      },
      {
      "code":"580506",
      "display":"แม่นาจาง"
      },
      {
      "code":"580501",
      "display":"แม่ลาน้อย"
      },
      {
      "code":"580502",
      "display":"แม่ลาหลวง"
      },
      {
      "code":"580504",
      "display":"แม่โถ"
      }
      ],
      "code":"5805",
      "display":"แม่ลาน้อย"
      },
      {
      "districts":[
      {
      "code":"580401",
      "display":"บ้านกาศ"
      },
      {
      "code":"580408",
      "display":"ป่าแป๋"
      },
      {
      "code":"580406",
      "display":"เสาหิน"
      },
      {
      "code":"580403",
      "display":"แม่คง"
      },
      {
      "code":"580405",
      "display":"แม่ยวม"
      },
      {
      "code":"580402",
      "display":"แม่สะเรียง"
      },
      {
      "code":"580404",
      "display":"แม่เหาะ"
      }
      ],
      "code":"5804",
      "display":"แม่สะเรียง"
      }
      ],
      "display":"แม่ฮ่องสอน"
      }
      }

  ]



   }
  

}
