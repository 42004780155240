<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer glucernaContainer glucerna-cereal">
    <header class="traceabilityHeader bgPrimary-glucerna-cereal">
     <img class="homeLogo" routerLink="/glucernaplus-traceability" src="../../../assets/images/home_logo_white.png">
     <img  routerLink="/glucernaplus-traceability" src="../../../assets/images/glucerna/logo1.png">
      </header>

  <div class="heading-icon mt-5">
    <img class="w-100" src="../../../assets/images/glucerna/gulecerna-qc-image.png" >

  </div>
  <div class="grd-box mt-3 text-center">
   <div class="grd-inner-div"> <span>วันหมดอายุ : {{expiryDate}}</span></div>
  </div>
  <div class="contentWrapper">
    <div class="innerContent">
        <div class="text-type-22 text-left pt-5">
            สำหรับแอ๊บบอต การควบคุมคุณภาพในกระบวนการผลิตถือเป็น
            สิ่งสำคัญที่สุด เพื่อให้มั่นใจได้ว่าบริษัทฯ จะสามารถผลิตสินค้าที่มี
            คุณภาพและมีความปลอดภัยอยู่เสมอ
            
            
        
          </div>
    
          <div>
            <img class="w-100 mt-3" src="../../../assets/images/glucerna/quality.png">
          </div>
          <div class="text-type-22 mt-4 text-left">
            ตั้งแต่ขั้นตอนการรับวัตถุดิบสำหรับใช้ในการผลิตสินค้า จนกระทั่ง
            การผลิตสินค้าออกจากโรงงาน ในทุกขั้นตอนของการผลิตได้ผ่าน
            การตรวจสอบอย่างเข้มงวดโดยผู้เชี่ยวชาญด้านการประกันคุณ
            ภาพ ที่ผ่านการฝึกอบรม พร้อมด้วยการใช้อุปกรณ์การตรวจสอบ
            ที่ทันสมัย
          </div>
    </div>
    
    <div class="text-type-10 mt-4 text-uppercase">TH.2022.27291.GLU.1(v1.0)©2022Abbott </div>
  </div>
  
</section>
<app-app-glucerna-plus-cereal-nav></app-app-glucerna-plus-cereal-nav>
