import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SafePipeModule } from 'safe-pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopupModule } from '@ciri/ngx-popup';
import { WebcamModule } from 'ngx-webcam';

import { ApiService } from './services/api.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { HeaderComponent } from './shared/header/header.component';
import { HeaderNavComponent } from './shared/header-nav/header-nav.component';
import { NavigationService } from './navigation.service';
import { EditPasswordComponent } from './pages/edit-password/edit-password.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { MoreComponent } from './pages/more/more.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ConditionsComponent } from './pages/conditions/conditions.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HeaderFamilyClubComponent } from './shared/header-family-club/header-family-club.component';
import { HistoryComponent } from './pages/history/history.component';
import { HeaderWithAbbottComponent } from './shared/header-with-abbott/header-with-abbott.component';
import { TraceabilityQualityControlComponent } from './pages/traceability-quality-control/traceability-quality-control.component';
import { TraceabilityHeaderComponent } from './shared/traceability-header/traceability-header.component';
import { TraceabilityNavComponent } from './pages/traceability-nav/traceability-nav.component';
import { TraceabilityHomeComponent } from './pages/traceability-home/traceability-home.component';
import { TraceabilityTriplePlusComponent } from './pages/traceability-triple-plus/traceability-triple-plus.component';
import { TraceabilityManufacturingComponent } from './pages/traceability-manufacturing/traceability-manufacturing.component';
import { TraceabilityBrandComponent } from './pages/traceability-brand/traceability-brand.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CountdownModule } from 'ngx-countdown';
import { RewardsComponent } from './rewards/rewards.component';
import { TemporaryLandingComponent } from './pages/temporary-landing/temporary-landing.component';
import { HowToUseComponent } from './pages/how-to-use/how-to-use.component';
import { MobileRegisterComponent } from './pages/mobile-register/mobile-register.component';
import { GlucernaTracebilityComponent } from './pages/tracebility-glucerna/glucerna-tracebility/glucerna-tracebility.component';
import { GlucernaManufacturarComponent } from './pages/tracebility-glucerna/glucerna-manufacturar/glucerna-manufacturar.component';
import { GlucernaQualitycontrolComponent } from './pages/tracebility-glucerna/glucerna-qualitycontrol/glucerna-qualitycontrol.component';
import { GlucernaProductComponent } from './pages/tracebility-glucerna/glucerna-product/glucerna-product.component';
import { GlucernaMixinstructionComponent } from './pages/tracebility-glucerna/glucerna-mixinstruction/glucerna-mixinstruction.component';
import { GlucernaNavComponent } from './pages/tracebility-glucerna/glucerna-nav/glucerna-nav.component';
import { GlucernaHeaderComponent } from './pages/tracebility-glucerna/glucerna-header/glucerna-header.component';
import { EnsureTraceabilityComponent } from './pages/ensure/ensure-traceability/ensure-traceability.component';
import { EnsureHeaderComponent } from './pages/ensure/ensure-header/ensure-header.component';
import { EnsureNavComponent } from './pages/ensure/ensure-nav/ensure-nav.component';
import { EnsureManufacturingComponent } from './pages/ensure/ensure-manufacturing/ensure-manufacturing.component';
import { EnsureQualityControlComponent } from './pages/ensure/ensure-quality-control/ensure-quality-control.component';
import { EnsureProductComponent } from './pages/ensure/ensure-product/ensure-product.component';
import { EnsureMixinstructionComponent } from './pages/ensure/ensure-mixinstruction/ensure-mixinstruction.component';
import { EnsureFamilyClubComponent } from './shared/ensure-family-club/ensure-family-club.component';
import { QrScannerComponent } from './shared/qr-scanner/qr-scanner.component';
import { CameraComponent } from './shared/camera/camera.component';
import { ImageUploadSuccessComponent } from './pages/image-upload-success/image-upload-success.component';
import { EnsureCerealTraceabilityComponent } from './pages/ensure-cereal-traceability/ensure-cereal-traceability.component';
import { GlucernaPlusCerealTraceabilityComponent } from './pages/glucerna-plus-cereal-traceability/glucerna-plus-cereal-traceability.component';
import { EnsureGoldCerealTraceabilityComponent } from './pages/ensure-gold-cereal-traceability/ensure-gold-cereal-traceability.component';
import { PediasurePlusTastelessTraceabilityComponent } from './pages/pediasure-plus-tasteless-traceability/pediasure-plus-tasteless-traceability.component';
import bugsnagClient from './bugsnag';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AppGlucernaPlusCerealNavComponent } from './pages/new-traceability-navs/app-glucerna-plus-cereal-nav/app-glucerna-plus-cereal-nav.component';
import { PediasurePlusTastelessNavComponent } from './pages/new-traceability-navs/pediasure-plus-tasteless-nav/pediasure-plus-tasteless-nav.component';
import { EnsureCerealNavComponent } from './pages/new-traceability-navs/ensure-cereal-nav/ensure-cereal-nav.component';
import { EnsureGoldCerealNavComponent } from './pages/new-traceability-navs/ensure-gold-cereal-nav/ensure-gold-cereal-nav.component';
import { EnsurehManufacturingComponent } from './pages/ensureh-manufacturing/ensureh-manufacturing.component';
import { EnsurehQcComponent } from './pages/ensureh-qc/ensureh-qc.component';
import { EnsurehProductComponent } from './pages/ensureh-product/ensureh-product.component';
import { EnsurehMixinstructionComponent } from './pages/ensureh-mixinstruction/ensureh-mixinstruction.component';
import { EnsuregoldManufacturingComponent } from './pages/ensuregold-manufacturing/ensuregold-manufacturing.component';
import { EnsuregoldQcComponent } from './pages/ensuregold-qc/ensuregold-qc.component';
import { EnsuregoldProductComponent } from './pages/ensuregold-product/ensuregold-product.component';
import { EnsuregoldMixinstructionComponent } from './pages/ensuregold-mixinstruction/ensuregold-mixinstruction.component';
import { GlucernaplusManufacturingComponent } from './pages/glucernaplus-manufacturing/glucernaplus-manufacturing.component';
import { GlucernaplusQcComponent } from './pages/glucernaplus-qc/glucernaplus-qc.component';
import { GlucernaplusProductComponent } from './pages/glucernaplus-product/glucernaplus-product.component';
import { GlucernaplusMixinstructionComponent } from './pages/glucernaplus-mixinstruction/glucernaplus-mixinstruction.component';
import { PediasureplusManufacturingComponent } from './pages/pediasureplus-manufacturing/pediasureplus-manufacturing.component';
import { PediasureplusQcComponent } from './pages/pediasureplus-qc/pediasureplus-qc.component';
import { PediasureplusProductComponent } from './pages/pediasureplus-product/pediasureplus-product.component';
import { PediasureplusMixinstructionComponent } from './pages/pediasureplus-mixinstruction/pediasureplus-mixinstruction.component';
import { environment as env } from '../environments/environment';
import { EnsureAwTracebilityComponent } from './pages/ensure-aw/ensure-aw-tracebility/ensure-aw-tracebility.component';
import { EnsureAwManufacturingComponent } from './pages/ensure-aw/ensure-aw-manufacturing/ensure-aw-manufacturing.component';
import { EnsureAwQcComponent } from './pages/ensure-aw/ensure-aw-qc/ensure-aw-qc.component';
import { EnsureAwMixinstructionComponent } from './pages/ensure-aw/ensure-aw-mixinstruction/ensure-aw-mixinstruction.component';
import { EnsureAwProductComponent } from './pages/ensure-aw/ensure-aw-product/ensure-aw-product.component';
import { EnsureAwMenuComponent } from './pages/ensure-aw/ensure-aw-menu/ensure-aw-menu.component';
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AlphabetOnlyDirective } from './pages/register/alphabet-only.directive';
import { ScannerComponent } from './shared/scanner/scanner.component';
import { LineLandingComponent } from './line-landing/line-landing.component';

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function tokenGetter() {
  return sessionStorage.getItem('authToken');
}

Bugsnag.start({
  apiKey: 'e841f553edf3708cb2dfd1c8df95a805',
  appVersion: env.bugsnag_version,
  collectUserIp: false,
  releaseStage: 'development',
  enabledReleaseStages: ['development', 'production'],
});


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    FooterComponent,
    LandingComponent,
    ProfileComponent,
    EditProfileComponent,
    HeaderComponent,
    HeaderNavComponent,
    EditPasswordComponent,
    FaqComponent,
    ContactUsComponent,
    MoreComponent,
    TermsComponent,
    ConditionsComponent,
    AboutUsComponent,
    HeaderFamilyClubComponent,
    HistoryComponent,
    HeaderWithAbbottComponent,
    TraceabilityQualityControlComponent,
    TraceabilityHeaderComponent,
    TraceabilityNavComponent,
    TraceabilityHomeComponent,
    TraceabilityTriplePlusComponent,
    TraceabilityManufacturingComponent,
    TraceabilityBrandComponent,
    DashboardComponent,
    RewardsComponent,
    TemporaryLandingComponent,
    HowToUseComponent,
    MobileRegisterComponent,
    GlucernaTracebilityComponent,
    GlucernaManufacturarComponent,
    GlucernaQualitycontrolComponent,
    GlucernaProductComponent,
    GlucernaMixinstructionComponent,
    GlucernaNavComponent,
    GlucernaHeaderComponent,
    EnsureTraceabilityComponent,
    EnsureHeaderComponent,
    EnsureNavComponent,
    EnsureManufacturingComponent,
    EnsureQualityControlComponent,
    EnsureProductComponent,
    EnsureMixinstructionComponent,
    EnsureFamilyClubComponent,
    QrScannerComponent,
    CameraComponent,
    ImageUploadSuccessComponent,
    EnsureCerealTraceabilityComponent,
    GlucernaPlusCerealTraceabilityComponent,
    EnsureGoldCerealTraceabilityComponent,
    PediasurePlusTastelessTraceabilityComponent,
    AppGlucernaPlusCerealNavComponent,
    PediasurePlusTastelessNavComponent,
    EnsureCerealNavComponent,
    EnsureGoldCerealNavComponent,
    EnsurehManufacturingComponent,
    EnsurehQcComponent,
    EnsurehProductComponent,
    EnsurehMixinstructionComponent,
    EnsuregoldManufacturingComponent,
    EnsuregoldQcComponent,
    EnsuregoldProductComponent,
    EnsuregoldMixinstructionComponent,
    GlucernaplusManufacturingComponent,
    GlucernaplusQcComponent,
    GlucernaplusProductComponent,
    GlucernaplusMixinstructionComponent,
    PediasureplusManufacturingComponent,
    PediasureplusQcComponent,
    PediasureplusProductComponent,
    PediasureplusMixinstructionComponent,
    EnsureAwTracebilityComponent,
    EnsureAwManufacturingComponent,
    EnsureAwQcComponent,
    EnsureAwMixinstructionComponent,
    EnsureAwProductComponent,
    EnsureAwMenuComponent,
    ScannerComponent,
    AlphabetOnlyDirective,
    LineLandingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SafePipeModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgOtpInputModule,
    CountdownModule,
    WebcamModule,
    ToastrModule.forRoot({ timeOut: 2000 }),
    FormsModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ScrollToModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ImageCropperModule,
    PopupModule,
  ],
  providers: [
    ApiService,
    NavigationService,
    Document,
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
