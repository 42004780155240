import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    private apiService: ApiService, 
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
    ) { }

  headingOne: any
  descriptionOne: any;
  headingTwo: any
  descriptionTwo: any;
  faqCont:any;

  ngOnInit(): void {
    this.getFaq();
  }

  getFaq() {
    this.spinner.show();
    this.apiService.getFaq().subscribe((res: any) => {
      this.spinner.hide();
      console.log(res.data); 
      let sortedFaq = res.data
      sortedFaq.sort((a,b)=> (a.order > b.order ? 1 : -1))
      console.log(sortedFaq, '')
      this.faqCont = sortedFaq;
      // res.data.forEach(item => {
      //   console.log('order', item);
      //   if (item._id == "606d539937f3432a4e6e3278") {
      //     // console.log('heading', item.faqtitle);
      //     this.headingOne = item.faqtitle;
      //     this.descriptionOne = item.faqdescription
      //   }
      //   if (item._id == "606d53b4e4a8601bb216b4e0") {
      //     console.log('heading2', item.faqtitle);
      //     this.headingTwo = item.faqtitle;
      //     this.descriptionTwo = item.faqdescription
      //   }
        
      // })
    }, err => {
      this.bsService.notifyError(err);
    })
  }
}
