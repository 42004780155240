<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pb150 glucerna manufacturing">
    <app-glucerna-header></app-glucerna-header>
    <section class="qualityContainer">
        <div class="position-relative">
            <div class="productImgBlock quality productImgManufacturerBlock mt30">
            </div>
            <div class="productBlock">
                <p>{{ 'MANUFACTURING.PRODUCTION_TEXT' | translate }}</p>
            </div>
        </div>
        <div class="cardBlockOut px-2 pb-4 mt30">
            <div class="dateBlock dateBlockManufacture text-center py-2">
                <p class='mb-0'>{{ 'MANUFACTURING.DATE' | translate }} : {{ expiryDate }}</p>
            </div>

            <p class="cardDescription mb-0">
                {{ 'MANUFACTURING.CARD_TOP_DESC' | translate }}
            </p>
            <div>
                <figure>
                    <img class="img-fluid mt40 w-100" src="../../../assets/images/manufacturecardmiddle.png">
                </figure>
            </div>
            <p class="cardDescription mt40">
                {{ 'MANUFACTURING.CARD_BOTTOM_DESC' | translate }}
            </p>
        </div>
    </section>
</section>
<app-glucerna-nav></app-glucerna-nav>
