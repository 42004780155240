import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { pipe } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { environment as env } from '../../../environments/environment';
import { BugsnagService } from 'src/app/services/bugsnag.service';
declare var $: any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  pointsList: any;
  currentMonth: any;
  currentYear: any;
  modelMonth: any;
  modelYear: any;
  disableCurrentyear: boolean = false;
  addyear: any;
  buzzeebeeUrl: any;
  bonousPointList: any;
  totalLength: any;
  productList: any = [];
  prductCode: any;
  @ViewChild('popupModalUploadReceipt', { static: true })
  popupModalUploadReceipt: ModalDirective;
  filteredProductList = [];
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
    private bsService: BugsnagService
  ) {
    this.buzzeebeeUrl = env.BUZZEEBEE_URL;
    console.log(env.BUZZEEBEE_URL, 'env.BUZZEEBEE_URL');
    let d = new Date();
    this.currentMonth = d.getMonth();
    this.currentYear = d.getFullYear();
    console.log(
      this.currentMonth,
      this.currentYear,
      ' this.currentMonth && this.currentYear'
    );
    this.modelMonth = this.currentMonth;
    this.modelYear = d.getFullYear();
    this.addyear = d.getFullYear() + 1;
  }

  ngOnInit(): void {
    this.getAllPoints();
    this.getCustomerPoints();
  }
  onclickUploadReceipt(productCode) {
    this.popupModalUploadReceipt.show();
    this.prductCode = productCode;
    console.log(this.prductCode, 'this.prductCode ')
  }

  // getAllPoints() {
  //   let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
  //   let endOfMonth = moment()
  //     .clone()
  //     .endOf('month')
  //     .add(1, 'day')
  //     .format('YYYY-MM-DD');
  //   let mobile = sessionStorage.getItem('mobileNo');
  //   let token = sessionStorage.getItem('authToken');
  //   this.spinner.show();
  //   this.apiService
  //     .getPointsList(mobile, token, startOfMonth, endOfMonth)
  //     .pipe(first())
  //     .subscribe((res: any) => {
  //       this.spinner.hide();
  //       console.log(res, 'resp data');
  //       console.log(res?.customer?.transactions?.transaction, 'points list');
  //       this.pointsList = res?.customer?.transactions?.transaction;
  //     });
  // }
  yearChanged(data) {
    this.modelYear = data;
    if (this.currentYear > this.modelYear) {
      this.disableCurrentyear = true;
    } else {
      this.disableCurrentyear = false;
    }
    console.log(this.modelYear, ' this.modelYear');
  }

  monthDate: any;
  monthEndDate: any;
  monthChanged(data) {
    if (data == 0) {
      this.monthDate = `${this.modelYear}-01-01`;
      this.monthEndDate = `${this.modelYear}-02-01`;
    } else if (data == 1) {
      this.monthDate = `${this.modelYear}-02-01`;
      this.monthEndDate = `${this.modelYear}-03-01`;
    } else if (data == 2) {
      this.monthDate = `${this.modelYear}-03-01`;
      this.monthEndDate = `${this.modelYear}-04-01`;
    } else if (data == 3) {
      this.monthDate = `${this.modelYear}-04-01`;
      this.monthEndDate = `${this.modelYear}-05-01`;
    } else if (data == 4) {
      this.monthDate = `${this.modelYear}-05-01`;
      this.monthEndDate = `${this.modelYear}-06-01`;
    } else if (data == 5) {
      this.monthDate = `${this.modelYear}-06-01`;
      this.monthEndDate = `${this.modelYear}-07-01`;
    } else if (data == 6) {
      this.monthDate = `${this.modelYear}-07-01`;
      this.monthEndDate = `${this.modelYear}-08-01`;
    } else if (data == 7) {
      this.monthDate = `${this.modelYear}-08-01`;
      this.monthEndDate = `${this.modelYear}-09-01`;
    } else if (data == 8) {
      this.monthDate = `${this.modelYear}-09-01`;
      this.monthEndDate = `${this.modelYear}-10-01`;
    } else if (data == 9) {
      this.monthDate = `${this.modelYear}-10-01`;
      this.monthEndDate = `${this.modelYear}-11-01`;
    } else if (data == 10) {
      this.monthDate = `${this.modelYear}-11-01`;
      this.monthEndDate = `${this.modelYear}-12-01`;
    } else if (data == 11) {
      this.monthDate = `${this.modelYear}-12-01`;
      this.monthEndDate = `${this.modelYear}-12-31`;
    }

    this.monthDate = moment(this.monthDate).format("DD/MM/YYYY");
    this.monthEndDate = moment(this.monthEndDate).format("DD/MM/YYYY")
    console.log(this.monthDate, 'start month Date');
    console.log(this.monthEndDate, 'end month Date');

    this.getFilteredProducts()


    // testDate.isBetween(this.monthDate, this.monthEndDate, 'days', true)

    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.apiService
      .getPointsList(mobile, token, this.monthDate, this.monthEndDate)
      .pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        console.log(res, 'resp data');
        console.log(res?.customer?.transactions?.transaction, 'points list');
        this.pointsList = res?.customer?.transactions?.transaction;
      }, err => {
        this.bsService.notifyError(err);
      });
    this.spinner.show();
    this.apiService
      .getCapCustomerPromotionFilter(
        mobile,
        token,
        this.monthDate,
        this.monthEndDate
      )
      .subscribe((res: any) => {
        this.spinner.hide();
        console.log(
          res.customers.customer[0].promotion_points.customer.item,
          'Promotion points list Filter---'
        );
        this.bonousPointList =
          res.customers.customer[0].promotion_points.customer.item;
      }, err => {
        this.bsService.notifyError(err);
      });
  }

  getFilteredProducts() {
    let list = []
    var currentTime = new Date()
    if (this.monthDate == undefined && this.monthEndDate == undefined) {
      this.modelYear = currentTime.getFullYear().toString()
      var month = currentTime.getMonth().toString()
      this.monthChanged(month)
    }

    this.productList.forEach(product => {
      let date = product.submissionDate.split(" ")
      date = date[0]
      console.log(date)

      var dateFrom = this.monthDate;
      var dateTo = this.monthEndDate;
      var dateCheck = date;

      var d1 = dateFrom.split("/");
      var d2 = dateTo.split("/");
      var c = dateCheck.split("/");

      var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
      var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
      var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);


      if (check > from && check < to) {
        list.push(product)
      }
    });

    this.filteredProductList = list
  }

  getCustomerPoints() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.apiService
      .getCapCustomerPromotion(mobile, token)
      .subscribe((res: any) => {
        this.spinner.hide();
        console.log(
          res.customers.customer[0].promotion_points.customer.item,
          'Promotion points list---'
        );
        this.bonousPointList =
          res.customers.customer[0].promotion_points.customer.item;
      }, err => {
        this.bsService.notifyError(err);
      });
  }

  gotoHistory() {
    let abbott_token;
    let auth_key;
    let abbott_user_id = sessionStorage.getItem('abbottUserId');
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthToken = sessionStorage.getItem('authToken');
    let getAuthKey = sessionStorage.getItem('authKey');
    let formData = {
      mobile: getMobile,
      brand_id: env.BRAND,
      device_id: env.DEVICE_ID,
      auth_token: getAuthToken,
      auth_key: getAuthKey,
    };
    this.spinner.show();
    this.apiService.buzzbeetokengen(formData).subscribe((res: any) => {
      console.log(res, 'buzz bee token gen');
      abbott_token = res.result.abbott_token;
      auth_key = res.result.auth_key;
      (<HTMLInputElement>document.getElementById('abbott_token')).value =
        abbott_token;
      (<HTMLInputElement>document.getElementById('auth_key')).value = auth_key;
      (<HTMLInputElement>document.getElementById('abbott_user_id')).value =
        abbott_user_id;
      (<HTMLInputElement>document.getElementById('target_page')).value =
        'history';
      $('#formSubmit').submit();
      this.spinner.hide();
      console.log(
        'Sending request to ' +
        $('#formSubmit').attr('action') +
        ' with data: ' +
        $('#formSubmit').serialize()
      );
    }, err => {
      this.bsService.notifyError(err, formData);
    });
  }

  goBack() {
    this.router.navigate(['/profile']);
  }
  openCamera() {
    let data = {
      data: this.prductCode
    }
    this.router.navigate(['/camera', data]);
  }


  getAllPoints() {
    let mobile = sessionStorage.getItem('mobileNo')
    this.spinner.show();
    this.apiService.getProductsList(mobile).pipe(first())
      .subscribe(res => {
        this.spinner.hide();
        this.pointsList = res['result'];
        this.totalLength = this.pointsList.length;

        this.pointsList.forEach((product: any) => {
          let productItem = {
            name: product.product_name,
            image: product.product_image,
            status: product.receipt_status,
            productCode: product.id,
            submissionDate: moment(product.submission_date).format('DD/MM/YYYY HH:mm'),
            points: product.points,
            color: ''
          }
          //productItem.status = 'approved';
          if (productItem.status == "rejected") {
            productItem.color = '#CC3300'
          } else if (productItem.status == "pending") {
            productItem.points = 'Validasi'
            productItem.color = '#5A217A'
          }
          else if (productItem.status == "success") {
            productItem.color = '#60BB55'
          }
          else if (productItem.status == "approved") {
            productItem.color = '#60BB55'
          }
          else if (productItem.status == "awaiting") {
            productItem.color = '#EFBE35'
          }
          this.productList.push(productItem)
        }
        )
        this.productList = this.productList.reverse();
        this.filteredProductList = this.productList
        this.getFilteredProducts()
        console.log(this.productList, 'this.productList');
      }, err => {
        this.bsService.notifyError(err);
      }), err => {
        console.log(err);
      }

    // this.productList = this.productList.sort((a, b) => {
    //   return moment(a.submissionDate).diff(b.submissionDate)
    // })
    // let token = sessionStorage.getItem('authToken')
    // this.spinner.show();
    // this.apiService.getPointsList(mobile, token).pipe(first())
    //   .subscribe((res: any) => {
    //     this.spinner.hide();
    //     this.pointsList = res?.customer?.transactions?.transaction
    //     console.log(this.pointsList)
    //     let billingTime = moment(this.pointsList[0]?.billing_time).format('DD/MM/YYYY');
    //     let today = new Date();
    //     let todayDate = moment(today).add(2.5, 'hours').format('DD/MM/YYYY');
    //     let yesterday1 = moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('DD/MM/YYYY');
    //     let yesterday2 = moment(today).add(2.5, 'hours').subtract(1, 'day').format('DD/MM/YYYY');
    //     if (billingTime == todayDate) {
    //       this.findDay = 'TODAY'
    //     } else if (yesterday1 == yesterday2) {
    //       this.findDay = 'YESTERDAY'
    //     } else {
    //       this.findDay = 'LAST TRANSACTION DONE BY'
    //     }

    //     console.log(moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('hh:mm, DD-MM-YY'), 'pointsList[0]?.billing_time')
    //     this.lastTranasction = moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('hh:mm, DD-MM-YY');
    //     console.log(this.lastTranasction, ' this.lastTranasction')

    //   })



  }
}
