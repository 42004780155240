<div class="globalContainer _100vh">
    <app-header-family-club></app-header-family-club>
    <div class="container-fluid">
        <div class="videoContainer">
            <!-- <iframe id="Iframe" width="100%" height="400" src="https://www.youtube.com/embed/YUjd5UWbrgI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
            <iframe id="Iframe" width="100%" height="400" src="https://www.youtube.com/embed/tBNAHkmWLGs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
           
    </div>
 
</div>
<!-- <app-footer></app-footer> -->