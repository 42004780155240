import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ensure-family-club',
  templateUrl: './ensure-family-club.component.html',
  styleUrls: ['./ensure-family-club.component.scss'],
})
export class EnsureFamilyClubComponent implements OnInit {
  isNotChecked: any;
  @ViewChild('popupModalOne', { static: true }) popupModalOne: ModalDirective;

  constructor(private router: Router) {}

  ngOnInit(): void {
    // sessionStorage.removeItem('checkbox');
  }

  checkValue(value) {
    console.log(value);
    sessionStorage.setItem('checkbox', value);
  }

  onclickScanToCollect() {
    // this.popupModalOne.show();
    sessionStorage.setItem('inscan', 'inscan')
    let check = sessionStorage.getItem('checkbox');
    console.log(check);
    if (check !== 'isChecked') {
      this.popupModalOne.show();
    } else {
      this.router.navigate(['/qr-scanner']);
    }
  }
}
