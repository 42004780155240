import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
    // <div>
  //     <h2>{{ 'HOME.TITLE' | translate }}</h2>
  //     <label>
  //       {{ 'HOME.SELECT' | translate }}
  //       <select #langSelect (change)="translate.use(langSelect.value)">
  //         <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
  //       </select>
  //     </label>
  //   </div>
  // `,
})
export class AppComponent {
  title = 'Abbott-TH';
  constructor(public translate: TranslateService, private router: Router,) {
    translate.addLangs(['en_US', 'vi_TH']);
    translate.setDefaultLang('en_US');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en_US|vi_TH/) ? browserLang : 'en_US');
  }
}
