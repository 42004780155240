<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer ensure-cereal-gold">
  <!-- <app-ensure-header class="traceabilityHomeheader"></app-ensure-header> -->
  <header class="traceabilityHeader bgEnsureBlueGold">
    <img
      class="img-fluid logo"
      src="../../../../assets/images/ensure-aw/vector-smart-object.png"
    />
  </header>
  <div class="homeContainer">
    <h1 class="mt-2">ผลิตภัณฑ์เอนชัวร์</h1>
    <div class="imgBlock _gutter1">
      <figure class="_210px">
        <!-- <img
          class="gpasImg"
          src="../../../../assets/images/ensure-aw/prd1.png"
          alt="ensure"
        /> -->
        <img
          style="width: 100%"
          *ngIf="isIsprint == false"
          class="gpasImg"
          [src]="prodImage"
          alt="glucerna"
        />
        <img
          *ngIf="isIsprint == true"
          class=""
          [src]="productImage"
          alt="ensure"
        />
      </figure>
      <div class="prdDetails pt-30">
        <span>{{ productName }}
         </span
        ><br /><strong
          >{{ "TRACEABILITY_HOME.LOT_NUMBER" | translate }} :
          {{ lotNumber }}</strong
        >
      </div>
      <!-- <i class="rightArrow"
        ><img
          src="../../../../assets/images/glucerna/arrowleft.svg"
          alt="right arrow"
      /></i> -->
    </div>
    <div class="imgBlock _gutter2">
      <figure class="_255px">
        <img src="../../../../assets/images/ensure-aw/home1.png" alt="home" />
      </figure>
      <div class="prdDetails two">
        <p class="mt-3">
          <label
            >{{ "TRACEABILITY_HOME.COUNTRY_OF_ORIGIN" | translate }} :
          </label>
          <span> {{ manufactCountry }}</span>
        </p>
        <p>
          <label>{{ "TRACEABILITY_HOME.PRODUCTION_DATE" | translate }} </label>
          <span> {{ manufacturedDate }}</span>
        </p>
      </div>
      <!-- <i class="leftArrow"
        ><img
          src="../../../../assets/images/glucerna/arrowright.svg"
          alt="left arrow"
      /></i> -->
    </div>
    <div class="imgBlock _mt600px">
      <figure class="_158px">
        <img
          src="../../../../assets/images/ensure-aw/search1.png"
          alt="search"
        />
      </figure>
      <div class="prdDetails three mt-2">
        <span>{{ "TRACEABILITY_HOME.EXPIRATION_DATE" | translate }} </span
        ><br />
        <span>{{ expiryDate }}</span>
      </div>
    </div>
    <div class="triplePlusBlock">
      <h1 class="p-0">
        รู้จัก
        <div class="d-flex justify-content-center">
          <span> เอนชัวร์ โกลด์</span>
        </div>
      </h1>
      <a [routerLink]="['/ensureplantbased-branded']" class="tracePrimaryBtn"
        ><span> คลิกเลย! </span></a
      >
    </div>
    <small>TH.2022.34295.ENS.1 (v1.0) ©2023Abbott</small>
  </div>
</section>
<app-ensure-aw-menu></app-ensure-aw-menu>
