<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pb150">
    <app-traceability-header></app-traceability-header>
    <section class="qualityContainer">
        <div class="position-relative">
            <div class="productImgBlock productImgManufacturerBlock mt30">
            </div>
            <div class="productBlock">
                <p>{{ 'MANUFACTURING.PRODUCTION_TEXT' | translate }}</p>
            </div>
        </div>
        <div class="cardBlockOut px-2 pb-4 mt30">
            <div class="dateBlock dateBlockManufacture text-center py-2">
                <p class='mb-0'>{{ 'MANUFACTURING.DATE' | translate }} : {{ expiryDate }}</p>
            </div>

            <p class="cardDescription mb-0">
                {{ 'MANUFACTURING.CARD_TOP_DESC' | translate }}
            </p>
            <div>
                <figure>
                    <img class="img-fluid mt40 w-100" src="../../../assets/images/manufacturecardmiddle.png">
                </figure>
            </div>
            <p class="cardDescription mt40">
                {{ 'MANUFACTURING.CARD_BOTTOM_DESC' | translate }}
            </p>
        </div>
        <!--<div class="text-center">
            <h2 class="knowPediaSureTitle mt30"><span>
                    {{ 'MANUFACTURING.KNOW' | translate }}
                </span>
                {{ 'MANUFACTURING.PEDIA_SURE' | translate }}
            </h2>
        </div>
        <div class="mt30">
            <img class="img-fluid w-100" src="../../../assets/images/knowpediasure.png">
            <div class="text-center">
                <a class="text-center wrapper" routerLink="/brand">
                    <span> {{ 'MANUFACTURING.CLICK_BTN_TEXT' | translate }} </span>
                </a>
            </div>
        </div>-->
        <div class="copyright-details text-uppercase">
            <p>TH.2022.26826.PDS.1(v1.0)©2022Abbott</p>
          </div>
    </section>
</section>
<app-traceability-nav></app-traceability-nav>
