<div class="ensure-prod-container">
    <app-ensure-family-club></app-ensure-family-club>
  
    <div class="ensure-logo-section">
      <img
        class="img-fluid"
        src="../../../../assets/images/ensure-aw/vector-smart-object.png"
        alt="Ensure brand"
      />
      <a
        ><img
          class="img-fluid ensure-home-icon"
          src="../../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
          alt="Ensure Home"
          routerLink="/ensureplantbased-home"
      /></a>
    </div>
  
    <div class="product-section">
      <img
        src="../../../../assets/images/ensure-aw/pr1.png"
        alt="manufacture"
        class="img1"
      />
      <img
          src="../../../../assets/images/ensure-aw/pr2.png"
          alt="manufacture"
          class="img2"
      />
      <img
        src="../../../../assets/images/ensure-aw/pr3.png"
        alt="manufacture"
        class="img3"
      />
      <img
        src="../../../../assets/images/ensure-aw/pr4.png"
        alt="manufacture"
        class="img4"
      />
      <img
        src="../../../../assets/images/ensure-aw/pr5.png"
        alt="manufacture"
        class="img5"
      />
      <img
        src="../../../../assets/images/ensure-aw/pr6.png"
        alt="manufacture"
        class="img6"
      />
      <img
        src="../../../../assets/images/ensure-aw/pr7.png"
        alt="manufacture"
        class="img7"
      />
      <img
        src="../../../../assets/images/ensure-aw/pr8.png"
        alt="manufacture"
        class="img8"
      />
    </div>
    <div class="how-to-use">
      <p>อาหารทางการแพทย์ <br />
        ต้องใช้ภายใต้คำแนะนำของแพทย์</p>
      <p class="copyright-details">ใบอนุญาตโฆษณาเลขที่ ฆอ. 1762/2565</p>
      <small>TH.2022.34295.ENS.1 (v1.0) ©2023Abbott</small>
    </div>
   
  </div>

  <app-ensure-aw-menu></app-ensure-aw-menu>
  