import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgotMobileForm: FormGroup;
  forgotOtpForm: FormGroup;
  submitted = false;
  submittedMobileForm = false;
  submittedOtpForm = false;
  invalidOtp: boolean = false;
  otp: string;
  activeClassButton: boolean = false;
  categoryCode: any;
  scanCode: any;
  formValues: any;
  sessionId: any;
  invalidPassword: boolean = false;
  pointsEarned: any;
  phone_no: any;
  mobileNum: any;
  storedMobNum: any;
  mobileNumWithZero: any;

  iSprintData: any;
  image: any;
  customerName: any;
  suspiciousCount: any;
  latitude: any;
  longitude: any;
  confidence: any;
  prodImage: any;
  suspicious_count: any = 0;
  customer: any;
  customerData: any;
  sfCode: any;
  isprintPoint: any;
  gpasPoints: any;
  skipTrans: any;

  @ViewChild('forgotModalOne', { static: true }) forgotModalOne: ModalDirective;
  @ViewChild('forgotOtpModal', { static: true }) forgotOtpModal: ModalDirective;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  @ViewChild('successScanModal', { static: true })
  successScanModal: ModalDirective;
  @ViewChild('gpasSuccessMdl', { static: true })
  gpasSuccessMdl: ModalDirective;
  @ViewChild('failedScanModal', { static: true })
  failedScanModal: ModalDirective;
  @ViewChild('userNotRegsitered', { static: true })
  userNotRegistered: ModalDirective;
  @ViewChild('exceedLimitModal', { static: true })
  exceedLimitModal: ModalDirective;
  @ViewChild('popupModal', { static: true }) popupModal: ModalDirective;


  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: false,
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: true,
  };
  checkQRCode2: any;
  showResendOtp: boolean = false;
  timeLeft: any;
  beginTimer: boolean = false;
  gpasSku: any;
  userNotFound: boolean = false;
  inscan: any;
  catCode: any;
  tokenresp: any;
  otpvalidateResp: Object;
  respisprint: any;
  gpasresp: any;
  lineUserCode: any;
  lineID: any;
  lineAuthToken: any;
  lineURLCuid: any;
  lineUserMobileNumber: any;
  scanScode: any;
  loader: boolean = true;
  lineParamdata: any;
  lineAuthKey: any;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private bsService: BugsnagService,
    private route: ActivatedRoute
  ) {
   if (this.route.snapshot?.queryParams?.scan) {
      this.scanScode = this.route.snapshot.queryParams.scan;
      this.loader = true
      console.log(this.scanScode, 'this.scanScode 12');
      sessionStorage.setItem('scanCode', this.scanScode);
      if (
        this.scanScode === undefined ||
        this.scanScode === null ||
        this.scanScode === ''
      ) {
        this.loader = false;
        sessionStorage.removeItem('iSprintData');
      }
    } else {
      this.loader = false
      let scanCodeData = sessionStorage.getItem('iSprintData');
      if (scanCodeData != null) {
        let iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
        this.categoryCode = iSprintData?.product?.categoryCode;
        console.log(this.categoryCode, 'iSprintData==========');
        this.scanCode = sessionStorage.getItem('scanCode');
        console.log(this.scanCode, 'this.scanCode')
      }
    }
  }

  isScanCodeAvaialable(scancode:any){
   return scancode == undefined || scancode == "null" || scancode == '' ? false : true  
  }

  addQueryParamsToCurrentPage(scancode:any){
    const queryParams: Params = { scan: scancode };
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: queryParams, 
        queryParamsHandling: 'merge'
      });
  }

  ngOnInit(): void {
     if (this.route.snapshot.params?.['scan'] || this.route.snapshot?.params?.['token'] ) {
      this.scanCode = this.route.snapshot?.params?.['scan']
      if(this.scanCode){ 
        sessionStorage.setItem('scanCode',this.scanCode)
        sessionStorage.setItem('isQRScanned', 'true');}
      this.loader = true
      sessionStorage.setItem('isQRScanned', 'true');
      let token = this.route.snapshot?.params?.['token']
      this.getLineUserBasedOnToken(token)
    } else {
      this.iSprintData = sessionStorage.getItem('iSprintData');
      this.iSprintData = JSON.parse(this.iSprintData);
      console.log('isprint data', this.iSprintData);
      this.scanCode = sessionStorage.getItem('scanCode');
      console.log(this.scanCode, 'this.scanCode')
      this.gpasSku = this.iSprintData?.sku
      console.log(this.gpasSku, 'this.gpasSku ')
      this.checkQRCode2 = this.iSprintData?.uaid_info_type;
      console.log(this.checkQRCode2, 'this.checkQRCode2====')
      this.skipTrans = sessionStorage.getItem('skipTrans');
      console.log(this.skipTrans, ' this.skipTrans')
      this.inscan = sessionStorage.getItem('inscan');
      console.log(this.inscan, ' this.inscan')
      this.catCode = sessionStorage.getItem('catCode')
    }
    
    this.getPosition()

    this.loginForm = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^((\\+63-?)|0)?[0-9]{9,10}$'),
        ],
      ],
      password: ['', Validators.required],
    });

    this.forgotMobileForm = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^((\\+63-?)|0)?[0-9]{9,10}$'),
        ],
      ],
    });

    this.forgotOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
  }

  getLineUserBasedOnToken(token:any){
    sessionStorage.setItem('hasToExecuteLineAPI', 'true')
    this.apiService.getLineUserBasedOnToken(token).subscribe(res => {
      console.log(res)
      if(res['result']['tokenkey']) {
        console.log('linetokenkey',res['result']['tokenkey'])
        this.loader = true;
        this.lineUserMobileNumber = res['result']['mobile']
        sessionStorage.setItem('mobileNo', this.lineUserMobileNumber);
        this.lineAuthToken = res['result']['tokenkey']['token']
        this.lineAuthKey = res['result']['tokenkey']['key']
        sessionStorage.setItem("authToken", this.lineAuthToken)
        sessionStorage.setItem("authKey", this.lineAuthKey)
        sessionStorage.setItem('isLineUser', 'true')
        console.log('navigate to home')
        this.router.navigate(['/home',{popup:"true"}])
      } else if(res['result']['lineId']){
        console.log(res['result']['lineId'], 'lineid')
        this.loader = false
          sessionStorage.setItem('isLineUser', 'false')
          this.lineURLCuid = res['result']['lineId']
          sessionStorage.setItem('lineURLCuid', this.lineURLCuid)
      }
    }, err => {
      console.log(err)
      this.loader = false
      this.bsService.notifyError(err)
    })
  }

  getLineUserAccountExistingDetails(lineUserCode: any, scanCode: any) {
    sessionStorage.setItem('hasToExecuteLineAPI', 'true')
    this.apiService.getLineUserAccountExistingDetails(lineUserCode, scanCode).subscribe((res: any) => {
      this.spinner.show()
      if (res['status'] == 'success') {
        this.spinner.hide()
        // if token is returned ,   redirect to dashborad and call getcustomer
        if (res['result']['tokenkey']) {
          this.loader = true
          this.lineUserMobileNumber = res['result']['mobile']
          sessionStorage.setItem('mobileNo', this.lineUserMobileNumber);
          this.lineAuthToken = res['result']['tokenkey']['token']
          this.lineAuthKey = res['result']['tokenkey']['key']
          sessionStorage.setItem("authToken", this.lineAuthToken)
          sessionStorage.setItem("authKey", this.lineAuthKey)
          sessionStorage.setItem('isLineUser', 'true')
          this.router.navigate(['/home',{popup:"true"}])
        }
        // if lineId returned , user doesnt exist  let him login
        else if (res['result']['lineId']) {
         this.loader = false
          sessionStorage.setItem('isLineUser', 'false')
          this.lineURLCuid = res['result']['lineId']
          sessionStorage.setItem('lineURLCuid', this.lineURLCuid)
        }
      }
    }, err => {
      console.log(err)
      this.loader = false
      this.bsService.notifyError(err)
      this.spinner.hide()
    })
  }

  onOtpChange(otp) {
    this.invalidOtp = false;
    this.otp = otp;
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  onClickForgotPassword() {
    this.submittedMobileForm = false;
    this.forgotModalOne.show();
  }

  get loginFromVal() {
    return this.loginForm.controls;
  }

  get forgotFormOneVal() {
    return this.forgotMobileForm.controls;
  }

  get forgotOtpFormVal() {
    return this.forgotOtpForm.controls;
  }

  onLoginFormSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    } else {
      let mob1 = this.loginForm.value ? this.loginForm.value.mobileNumber : '';
      let password = this.loginForm.value ? this.loginForm.value.password : '';
      let mob = JSON.stringify(mob1).replace(/\b0+/g, '');
      sessionStorage.setItem('mobileNo', mob);
      this.phone_no = JSON.stringify(mob);
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: mob,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: 'test123',
        confirmPassword: 'test123',
      };
      let tokenresp;
      this.spinner.show();
      this.apiService
        .tokenGenerate(formData)
        .pipe(first()) //token generate api
        .subscribe((res) => {
          this.tokenresp = res;
          this.spinner.hide();
          if (res['status'].code == 200) {
            let sessionId = res['user'].sessionId;
            sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
            if (res['user'].userRegisteredForPassword == false) {
              this.userNotRegistered.show();
            } else if (
              res['user'].userRegisteredForPassword == true ||
              res['status'].code == 1515
            ) {
              // if user is present in CRM DB
              let formData = {
                identifierType: 'MOBILE',
                identifierValue: mob,
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                sessionId: sessionId,
                password: password,
              };
              this.apiService
                .passwordValidate(formData)
                .pipe(first())
                .subscribe((res) => {
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    if (res["user"].userRegisteredForPassword) {
                      this.apiService.getCapCustomer(mob, res['auth'].token).subscribe(customerResponse => {
                        if (customerResponse['status'].code == 200) {
                          let token = res['auth'].token;
                          sessionStorage.setItem('authToken', res['auth'].token);
                          sessionStorage.setItem('authTokenNew', res['auth'].token);
                          sessionStorage.setItem('authKey', res['auth'].key);
                          sessionStorage.setItem('userPassword', password);
                          // ------------------- On password validate success call add transcation api
                          console.log(this.scanCode, 'this.scanCode======')
                          let getMobile = sessionStorage.getItem('mobileNo');
                          let getAuthKey = sessionStorage.getItem('authToken');
                          if (this.checkQRCode2 == 1 || this.skipTrans == 'skiptrans') {
                            this.router.navigate(['/home',{popup:"true"}])
                            console.log('true')
                            return
                          }
                          if (this.checkQRCode2 == 2 || this.inscan == 'inscan') {
                            this.spinner.show();
                            let getMobile = sessionStorage.getItem('mobileNo');
                            let getAuthKey = sessionStorage.getItem('authToken');
                            this.apiService
                              .getCapCustomer(getMobile, getAuthKey)
                              .pipe(first())
                              .subscribe((res) => {
                                console.log(res, 'res 1 ======');
                                this.customerData = res['customers']['customer'][0];
                                this.customerName =
                                  this.customerData.firstname +
                                  ' ' +
                                  this.customerData.lastname;

                                this.customerData.custom_fields.field.forEach(
                                  (element) => {
                                    if (element.name === 'suspicious_count') {
                                      this.suspicious_count = parseInt(element.value);
                                    }
                                  }
                                );

                                console.log(this.suspicious_count, 'suspiciousCount');
                                this.spinner.hide();
                                if (
                                  res['customers']['customer'][0].item_status.code ==
                                  1012
                                ) {
                                  let data = { data: 'registersection' };
                                  this.router.navigate(['/register/11', data]);

                                }
                                this.onClickCancelModalone();
                              }, err => {
                                this.bsService.notifyError(err, formData);
                                this.spinner.hide();
                                console.log(err, 'err')
                              });
                          } else {
                            this.router.navigate(['/home',{popup:"true"}])
                          }
                        } else {
                          this.router.navigate(['/register/11']);
                        }
                      }, err => {
                        this.bsService.notifyError(err);
                        this.spinner.hide();
                      })
                    } else {
                      this.router.navigate(['/register/11']);
                    }
                  } else if (res['status'].code == 1505) {
                    //invalid session
                    this.router.navigate(['/sign-up']);
                    console.log(res['status'].message);
                  } else if (res['status'].code == 1528) {
                    //password does not match
                    console.log(res['status'].message);
                    this.invalidPassword = true;
                  } else {
                    console.log(res['status'].message);
                    this.router.navigate(['/home',{popup:"true"}])
                  }
                }, err => {
                  this.bsService.notifyError(err, formData);
                  this.spinner.hide();
                  console.log(err, 'err')
                });
            }

            //user not registered call salesforce Api
            else {
              this.spinner.show();
              this.apiService
                .getSalesForceData(mob)
                .pipe(first())
                .subscribe(
                  (res: any) => {
                    this.spinner.hide();
                    let salesforceData = res['result'];
                    // Put the salesforce data object into storage
                    sessionStorage.setItem(
                      'salesForceData',
                      JSON.stringify(salesforceData)
                    );
                    this.apiService.setSalesForceUser(true);
                    this.router.navigateByUrl('/register');
                  },
                  (err) => {
                    this.bsService.notifyError(err, formData);
                    this.spinner.hide();
                    this.apiService.setSalesForceUser(false);
                    console.log('user not registered !!!!');
                    this.userNotRegistered.show();
                    console.log(err, 'err--');
                  },

                );
            }
          }
        }, err => {
          this.bsService.notifyError(err, formData);
          this.spinner.hide();
          console.log(err, 'err')
        });
    }
  }

  passwordCheck(ev) {
    if (ev.target.value == '') {
      this.invalidPassword = false;
    }
  }

  closeForgotModalOne() {
    this.submittedMobileForm = false;
    this.forgotModalOne.hide();
    this.forgotMobileForm.reset();
  }

  closeForgotOtpModal() {
    this.submittedOtpForm = false;
    this.invalidOtp = false;
    this.activeClassButton = false;
    this.ngOtpInputRef.setValue('');
    this.forgotOtpModal.hide();
    this.beginTimer = false;
  }

  validateUserModal() {
    this.userNotRegistered.hide();
    sessionStorage.setItem('mobileNo', this.phone_no);
    this.router.navigateByUrl('/register/12');
  }

  closeUserModal() {
    this.userNotRegistered.hide();
  }

  onSubmitForgotFormOne() {
    this.submittedMobileForm = true;
    if (this.forgotMobileForm.invalid) {
      return;
    }
    if (this.forgotMobileForm.valid) {
      this.formValues = this.forgotMobileForm.value;
      this.mobileNum = JSON.stringify(this.formValues.mobileNumber);
      console.log(this.mobileNum, 'this.mobileNum2 ');
      this.mobileNum = this.mobileNum.replace(/\b0+/g, '');
      sessionStorage.setItem('mobileNo', this.mobileNum);
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: this.mobileNum,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        // "password":'test123',
        // "confirmPassword":'test123'
      };
      this.spinner.show();
      this.apiService
        .tokenGenerate(formData)
        .pipe(first()) //token generate api
        .subscribe((res) => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            if (res['user'].userRegisteredForPassword == false) {
              this.userNotFound = true;
              console.log('user not registered');
            } else {
              this.sessionId = res['user'].sessionId;
              sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
              this.forgotModalOne.hide();
              this.router.navigateByUrl('/forgot-password');
            }
          } else {
          }
        }, err => {
          this.bsService.notifyError(err, formData);
          this.spinner.hide();
          console.log(err, 'err')
        });
    }
  }

  onSubmitForgotOtpForm() {
    this.submittedOtpForm = true;
    console.log(this.activeClassButton, 'this.activeClassButton');
    if (this.otp == undefined || this.otp.length < 6) {
      console.log('in if--');
      return;
    } else {
      let formValues = this.forgotOtpForm.value;
      console.log('inside else---');
      console.log(
        this.forgotOtpForm.value.otp,
        'this.forgotOtpForm.value.otp--'
      );
      let otpSubmitData = {
        identifierType: 'MOBILE',
        identifierValue: this.mobileNum,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: this.sessionId,
        otp: this.otp,
      };
      this.spinner.show();
      this.apiService
        .otpValidate(otpSubmitData)
        .pipe(first())
        .subscribe(
          (res) => {
            this.otpvalidateResp = res

            this.spinner.hide();
            if (res['status'].code == 200) {
              sessionStorage.setItem('authToken', res['auth'].token);
              sessionStorage.setItem('authKey', res['auth'].key);
              this.router.navigateByUrl('/forgot-password');
              this.forgotOtpModal.hide();
              this.ngOtpInputRef.setValue('');
              this.forgotOtpForm.reset();
            } else {
              this.invalidOtp = true;
            }
          },
          (err) => {
            console.log(err);
            this.bsService.notifyError(err, otpSubmitData);
          }
        );
    }
  }

  onClickSuccessScanModal() {
    this.successScanModal.show();
  }
  closeSuccessScanModal() {
    this.successScanModal.hide();
    this.router.navigate(['/home',{popup:"true"}]);
  }
  closeGpasSuccessScanModal() {
    this.gpasSuccessMdl.hide();
    this.router.navigate(['/home',{popup:"true"}]);
  }
  onClickFailedScanModal() {
    this.failedScanModal.show();
  }
  closeFailedScanModal() {
    this.failedScanModal.hide();
  }
  closeExceedLimitModal() {
    this.exceedLimitModal.hide();
    this.router.navigate(['/home',{popup:"true"}]);
  }

  handleEvent(event) {
    console.log(event.left, 'event');
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

  mobileNoCheck(ev) {
    console.log(ev.target.value, 'ev.target.value');
    this.mobileNumWithZero = ev.target.value;
    if (ev.target.value == '') {
      this.userNotFound = false;
    }
  }

  enteredMobnum(event) {
    console.log(event.target.value, 'ev.target.value');
    this.storedMobNum = event.target.value;
    sessionStorage.setItem('storedMobNum', this.storedMobNum);
  }
  parseFunction(value) {
    return JSON.parse(value);
  }

  getPosition() {
    this.spinner.show()
    this.apiService.getPosition().then((pos) => {
      let myuuid = uuidv4();
      let loggerFormData = {
        unique_id: myuuid,
        message: JSON.stringify(pos),
      };
      this.spinner.hide()
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
      sessionStorage.setItem('latitude', this.latitude)
      sessionStorage.setItem('longitude', this.longitude)
      sessionStorage.setItem('confidence', this.confidence)
    }).catch(err => {
      this.spinner.hide()
      this.loader = false
    });
  }

  onClickCancelModalone() {
    this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.iSprintData.sku, 'isprint sku')
    let mobile = sessionStorage.getItem('mobileNo');
    let scanCode = sessionStorage.getItem('scanCode');
    let token = sessionStorage.getItem('authToken');
    console.log(this.iSprintData?.is_isprint);
    if (this.iSprintData?.is_isprint) {
      console.log('image: ', this.image);
      let parsedPrimaryValue = this.parseFunction(
        this.iSprintData?.product_name
      ).en_US;
      let prdname = this.iSprintData?.crm_name?.th_TH
      let formData = {
        scan_code: this.scanCode,
        mobile: mobile,
        cap_device_id: env.DEVICE_ID,
        customer_name: this.customerName,
        suspicious_count: this.suspicious_count,
        product_name: prdname,
        product_image: this.iSprintData?.product_image,
        confidence: this.confidence,
        product_type: this.iSprintData?.category_code,
       // "child_count": "1",
        //"weight": (this.weight == undefined || this.weight == null) ? '0' : this.weight,
        //"weight": this.weight,
        custom_fields: [
          {
            name: 'translat',
            value: this.latitude,
          },
          {
            name: 'translong',
            value: this.longitude,
          },
          {
            name: 'productname',
            value: prdname ? prdname : 'Pediasure',
          },
          {
            "name": 'categorycode',
            "value": prdname ? prdname : 'PediaSure',
          },
          {
            "name": 'sfproductcode',
            "value": this.iSprintData.sf_product_code ? this.iSprintData.sf_product_code : 'Null',
          },
        ],
      };
      this.spinner.show();
      console.log(formData, 'form data');
      
      this.apiService
        .postGpassEarnPoints(formData, token)
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.respisprint = res;
            sessionStorage.removeItem('iSprintData')
            this.spinner.hide();
            if (res['status'] == 'success') {
              this.spinner.hide();
              console.log(res, 'resp on isprint earns points....')
              this.isprintPoint = res?.result?.points
              this.successScanModal.show();
            } else {
              // add transcation fail show the pop-up
              this.onClickFailedScanModal();
            }
          },
          (err) => {
            sessionStorage.removeItem('iSprintData')
            this.bsService.notifyError(err, formData);
            this.spinner.hide()
            console.log(err);
            this.onClickFailedScanModal();

            if (err.status === 420) {
              this.exceedLimitModal.show();
            }
          }
        );
      
    } else {
      let prdname = this.iSprintData?.crm_name?.th_TH
      this.spinner.show();
      this.apiService
        .getGpasProductImage(this.iSprintData?.sku)
        .subscribe((res: any) => {
          this.spinner.hide();
          console.log(res.data, 'getGpasProductImage');
          console.log(this.latitude, this.longitude, this.confidence);
          this.prodImage = res.data[0]?.image;
          let formData = {
            scan_code: this.scanCode,
            mobile: mobile,
            cap_device_id: env.DEVICE_ID,
            customer_name: this.customerName,
            suspicious_count: this.suspicious_count,
            product_name: this.iSprintData?.thai_product_name,
            product_image: this.iSprintData?.product_image,
            confidence: this.confidence,
            product_type: this.iSprintData?.category_code,
            description: this.iSprintData?.product_description,
            custom_fields: [
              {
                name: 'translat',
                value: this.latitude,
              },
              {
                name: 'translong',
                value: this.longitude,
              },
              {
                name: 'productname',
                value: this.iSprintData?.thai_product_name,
              },
              {
                name: 'categorycode',
                value: this.iSprintData?.brand,
              },
              {
                name: 'sfproductcode',
                value: this.iSprintData?.product_code,
              },
            ],
          };
          console.log(formData, 'gpas form data');
          this.spinner.show();
          console.log(formData, 'form data');
          let country = this.iSprintData?.country?.toLowerCase().trim() || this.iSprintData?.country_of_origin?.toLowerCase().trim()
          let countries = this.iSprintData?.countries || []
              if (country == 'thailand') {
                    this.apiService
                    .postGpassEarnPoints(formData, token)
                    .pipe(first())
                    .subscribe(
                      (res: any) => {
                        this.gpasresp = res;
                        sessionStorage.removeItem('iSprintData')
                        this.spinner.hide();
                        if (res['status'] == 'success') {
                          this.spinner.hide();
                          this.apiService.getSkuPoints(this.iSprintData?.sku).subscribe((res: any) => {
                            console.log(res?.data[0]?.points, 'gpaspoints');
                            this.gpasPoints = res?.data[0]?.points;
                          })
                          console.log(res, 'resp on GPAS earns points....')
                          this.gpasSuccessMdl.show();
                        } else {
                          // add transcation fail show the pop-up
                          this.onClickFailedScanModal();
                        }
                      },
                      (err) => {
                        sessionStorage.removeItem('iSprintData')
                        this.bsService.notifyError(err, formData);
                        this.spinner.hide();
                        this.onClickFailedScanModal();
        
                        if (err.status === 420) {
                          this.exceedLimitModal.show();
                        }
                      });

                }else if(country == 'shared' && countries.includes('thailand')){
                  this.apiService
                  .postGpassEarnPoints(formData, token)
                  .pipe(first())
                  .subscribe(
                    (res: any) => {
                      this.gpasresp = res;
                      sessionStorage.removeItem('iSprintData')
                      this.spinner.hide();
                      if (res['status'] == 'success') {
                        this.spinner.hide();
                        this.apiService.getSkuPoints(this.iSprintData?.sku).subscribe((res: any) => {
                          console.log(res?.data[0]?.points, 'gpaspoints');
                          this.gpasPoints = res?.data[0]?.points;
                        })
                        console.log(res, 'resp on GPAS earns points....')
                        this.gpasSuccessMdl.show();
                      } else {
                        // add transcation fail show the pop-up
                        this.onClickFailedScanModal();
                      }
                    },
                    (err) => {
                      sessionStorage.removeItem('iSprintData')
                      this.bsService.notifyError(err, formData);
                      this.spinner.hide();
                      this.onClickFailedScanModal();
      
                      if (err.status === 420) {
                        this.exceedLimitModal.show();
                      }
                    });
                
                } else{
                  // show popup  invalid qr code
                  this.spinner.hide()
                  this.popupModal.show()
                }
            
        }, err => {
          sessionStorage.removeItem('iSprintData')
          this.bsService.notifyError(err);
        });
    }
  }

  onClickOK() {
    this.popupModal.hide()
  }
}

function tokenresp(tokenresp: any): import("@bugsnag/js").NotifiableError {
  throw new Error('Function not implemented.');
}

