<div class="ensure-container">
  <app-ensure-family-club></app-ensure-family-club>
  
    <div class="ensure-logo-section">
      <img
        class="img-fluid"
        src="../../../assets/images/ensure-gold/ensure-logo.webp"
        alt="Ensure brand"
      />
      <a
        ><img
          class="img-fluid ensure-home-icon"
          src="../../../assets/images/ensure-gold/ensure-gold-home-icon.webp"
          alt="Ensure Home" routerLink="/ensureh-traceability"
      /></a>
    </div>
  
    <div class="mixing-section">
        <h3>วิธีเตรียม <br /><span>เอนชัวร์</span></h3>
        <img class="img-sm" src="../../../assets/images/ensureh/ensureh-mix-195ml-new.png" />
        <h4>เทน้ำอุ่นหรือน้ำเย็น สะอาด <br />195 มล.</h4>
        <img class="img-mx-auto" src="../../../assets/images/ensureh/down-arrow.png" />
        <img class="img-sm" src="../../../assets/images/ensureh/ensureh-mix2-img-new.png" />
        <h4>ตักเอนชัวร์ 6 ช้อน <br /> ปาดผสม</h4>
        <img class="img-mx-auto" src="../../../assets/images/ensureh/down-arrow.png" />
        <img class="img-sm" src="../../../assets/images/ensureh/ensureh-mix3-img-new.png" />
        <h4>คนให้เข้ากัน <br /> จะได้ส่วนผสม 230 มล.</h4>
    </div>

    <div class="how-to-use">
        <a href="#">การเก็บรักษา</a>
        <ul>
           <li> เมื่อเปิดกระป๋องแล้ว ควรปิดฝาให้แน่นทุกครั้งหลังการ
            ใช้เก็บไว้ในที่เย็นและแห้ง (ไม่ควรเก็บไว้ในตู้เย็น) และควรใช้
            ให้หมดภายใน 3 สัปดาห์</li>
            <li>เมื่อผสมแล้วควรดื่มทันที หากดื่มไม่หมดให้ปิดฝาและ
                เก็บในตู้เย็น ใช้ให้หมดภายใน 24 ชั่วโมง</li>
        </ul>
        <p class="text-uppercase">TH.2022.26825.ENS.1(v1.0)©2022Abbott</p>
    </div>
    
 
  </div>
  <app-ensure-cereal-nav></app-ensure-cereal-nav>
  