<app-ensure-family-club></app-ensure-family-club>
<section
  class="traceAbilityContainer pediaSureContainer pediasure-plus-tasteless"
>
  <header class="traceabilityHeader">
    <img
      class="homeLogo"
      routerLink="/pediasureplus-traceability"
      src="../../../assets/images/home_logo_white.png"
    />
    <img class="img-fluid mt-2" src="../../../assets/images/pedisurelogo.png" />
  </header>

  <div class="heading-icon mt-5 text-center">
    <img
      class="d-block ped-plus-logo"
      src="../../../assets/images/pediasure/pediasure-plus-logo.png"
    />
    <img
      class="d-block w-100 ped-plus-logo-tag"
      src="../../../assets/images/pediasure/pediasure-plus-logo-tag.png"
    />
  </div>
  <img
    class="d-block w-100 mt-5"
    src="../../../assets/images/pediasure/pediasure-plus-product-image.png"
  />
  <img
    class="d-block w-100 ticker-img"
    src="../../../assets/images/pediasure/vector-smart-object-ticker.png"
  />
  <div class="contentWrapper">
    <div class="features-cards">
      <div class="d-flex align-items-center feature-card">
        <div class="img-content text-center">
          <img
            width="130px"
            height="130px"
            src="../../../assets/images/pediasure/icon-arginine.png"
          />
        </div>
        <p class="ml-4"><span>อาร์จีนีน*</span></p>
      </div>
      <div class="d-flex align-items-center mb-2 feature-card">
        <div class="img-content text-center">
          <img
            width="130px"
            height="130px"
            src="../../../assets/images/pediasure/icon-vitamin-k2.png"
          />
        </div>
        <p class="ml-4">มีส่วนประกอบของ <span> วิตามินเค 2**</span></p>
      </div>
      <div class="d-flex align-items-center mb-2 feature-card">
        <div class="img-content text-center">
          <img
            width="130px"
            height="130px"
            src="../../../assets/images/pediasure/icon-human-purple.png"
          />
        </div>
        <p class="ml-4">
          <span>สังกะสี</span> ช่วยในการเจริญเติบโต ของร่างกาย<br />
          <span>วิตามินเอ</span>
          มีส่วนช่วยในการเจริญเติบโต ของร่างกาย
        </p>
      </div>
      <div class="d-flex align-items-center mb-2 feature-card">
        <div class="img-content text-center">
          <img
            width="130px"
            height="130px"
            src="../../../assets/images/pediasure/icon-human-pink.png"
          />
        </div>
        <p class="ml-4">
          <span>วิตามินดี</span>มีส่วนช่วยในการทำงาน ตามปกติของกล้ามเนื้อ
        </p>
      </div>
      <div class="d-flex align-items-center mb-3 feature-card">
        <div class="img-content text-center">
          <img
            width="130px"
            height="130px"
            src="../../../assets/images/pediasure/icon-human-pink-light.png"
          />
        </div>
        <p class="ml-4">
          <span>วิตามินบี6 ซี ดี เหล็ก</span>มีส่วนช่วยในการทำหน้าที่
          ตามปกติของระบบภูมิคุ้มกัน
        </p>
      </div>
      <div class="d-flex align-items-center mb-3 feature-card">
        <div class="img-content text-center">
          <img
            width="130px"
            height="130px"
            src="../../../assets/images/pediasure/icon-human-orange.png"
          />
        </div>
        <p class="ml-4">
          <span>วิตามินบี12</span>มีส่วนช่วยในการทำงาน ตามปกติของระบบประสาท
          และสมอง
        </p>
      </div>
      <div class="d-flex align-items-center mb-4 feature-card">
        <div class="img-content text-center">
          <img
            class="icon-dha"
            src="../../../assets/images/pediasure/icon-dha.png"
          />
        </div>
        <p class="ml-4"><span>ดีเอชเอ</span></p>
      </div>
    </div>
  </div>
  <div class="bottom-section">
    <div class="pediasure-glass">
      <div
        class="contentWrapper d-flex align-items-end justify-content-between"
      >
        <div class="text-type-12 mt-3 part-one">
          <span
            >*มีอาร์จินีน 500 มก. ต่อหนึ่งหน่วยบริโภค<br />
            **มีส่วนประกอบของ วิตามิน เค 2 0.000008%</span
          >
          ใบอณุญาตโฆษณาเลขที่ ฆอ. 2038/2565<br />
          TH.2022.27391.PDS.1(v1.0)©2022Abbott
        </div>
        <div class="text-type-13 mt-3 part-two">
          ควรกินอาหารหลากหลาย ครบ 5 หมู่ ในสัดส่วนที่ เหมาะสมเป็นประจำ
        </div>
      </div>
    </div>
    <div class="yt-section d-flex align-items-center justify-content-center">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="promo-video-link"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/-AWB_2Jd2cA"
          title="พีเดียชัวร์+ ใหม่ รสจืดไม่เติมน้ำตาลทราย"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</section>
<app-pediasure-plus-tasteless-nav></app-pediasure-plus-tasteless-nav>
