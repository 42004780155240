import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted = false;
  mobile_no: any;
  sessionId: any;
  forgotOtpForm: FormGroup;
  @ViewChild('forgotOtpModal', { static: true }) forgotOtpModal: ModalDirective;

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: false,
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: true,
  };
  showResendOtp: boolean = false;
  timeLeft: any;
  beginTimer: boolean = false;
  submittedOtpForm = false;

  invalidOtp: boolean = false;
  otp: string;
  activeClassButton: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
  ) {
    this.mobile_no = sessionStorage.getItem('mobileNo');
  }

  ngOnInit(): void {
    console.log('inside forgot password--');
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );

    this.forgotOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
  }

  get restFormVal() {
    return this.resetPasswordForm.controls;
  }

  get forgotOtpFormVal() {
    return this.forgotOtpForm.controls;
  }

  onSubmitResetForm() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      let formValues = this.resetPasswordForm.value;
      this.spinner.show();
      this.sessionId = JSON.parse(sessionStorage.getItem('sessionID'));
      // //----------forgot password---------
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: this.mobile_no,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: this.sessionId,
        password: formValues.password,
        confirmPassword: formValues.confirmPassword,
      };
      this.apiService
        .forgotPassword(formData)
        .pipe(first()) // forgot password api
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res['status'].code == 200) {
              let otpGenerateData = {
                identifierType: 'MOBILE',
                identifierValue: this.mobile_no,
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                sessionId: this.sessionId,
              };
              
              this.apiService
                .otpGenerate(otpGenerateData)
                .pipe(first()) // otp generate api
                .subscribe(
                  (res) => {
                    
                    this.spinner.hide();
                    if (res['status'].code == 200) {
                      this.showForgotModal();
                    } else {
                      // otp genearte else
                      //this.toastr.error(res['status'].message)
                    }
                  },
                  (err) => {
                    // otp genearte error
                    this.spinner.hide();
                    console.log(err, '=== OTP gererate error list =======');
                    this.bsService.notifyError(err, otpGenerateData);
                  }
                );

              
             
            } else {
              // forgot passsword else
              console.log(res['status'].message);
            }
          },
          (err) => {
            //forgot passsword error
            this.spinner.hide();
            console.log(err);
            this.bsService.notifyError(err, formData);
          }
        );
    }

    // }) token genearte end
  }

  showForgotModal() {
    console.log('now show otp modal--');
    this.forgotOtpModal.show();
    this.beginTimer = true;
  }

  closeForgotOtpModal() {
    this.submittedOtpForm = false;
    this.invalidOtp = false;
    this.activeClassButton = false;
    this.ngOtpInputRef.setValue('');
    this.forgotOtpModal.hide();
    this.beginTimer = false;
  }

  onSubmitForgotOtpForm() {
    this.submittedOtpForm = true;
    console.log(this.activeClassButton, 'this.activeClassButton');
    if (this.otp == undefined || this.otp.length < 6) {
      console.log('in if--');
      return;
    } else {
      
              let otpSubmitData = {
                identifierType: 'MOBILE',
                identifierValue: this.mobile_no,
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                sessionId: this.sessionId,
                otp: this.otp,
              };
              
              this.spinner.show();
              this.apiService
                .otpValidate(otpSubmitData)
                .pipe(first())
                .subscribe(
                  (res) => {
                   
                    this.spinner.hide();
                    if (res['status'].code == 200) {
                      sessionStorage.setItem('authToken', res['auth'].token);
                      sessionStorage.setItem('authKey', res['auth'].key);
                      this.router.navigateByUrl('/login');
                      this.forgotOtpModal.hide();
                      this.ngOtpInputRef.setValue('');
                      this.forgotOtpForm.reset();
                    } else {
                      this.invalidOtp = true;
                    }
                  },
                  (err) => {
                    console.log(err);
                    this.bsService.notifyError(err, otpSubmitData);
                  }
                );
          
    }
  }

  handleEvent(event) {
    console.log(event.left, 'event');
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

  onOtpChange(otp) {
    this.invalidOtp = false;
    this.otp = otp;
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }

  resendOTP() {
    this.spinner.show();
    this.showResendOtp = false;
    let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    let otpGenerateData = {
      mobile: this.mobile_no,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      sessionId: sessionIdValue,
    };
    
    this.apiService
      .otpGenerate(otpGenerateData)
      .pipe(first())
      .subscribe(
        (res) => {
          
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.showForgotModal();
          } else if (res['status'].code == 401) {
            this.apiService.logout();
          } else {
            this.apiService.showToaster(res['status'].message);
          }
        },
        (err) => {
          this.spinner.hide();
          console.log(err, '=== OTP gererate error list =======');
          this.bsService.notifyError(err, otpGenerateData);
        }
      );
  }
}
