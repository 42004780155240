<div class="profileContainer">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-2">
                <img src="../../../assets/images/abbott.svg" alt="abbott" class="logo" />
            </div>
            <div class="col-10 text-center">
                <img class="headerLogo" src="../../assets/images/loginlogo.png"alt="logo" />
            </div>
        </div>
    </div>
    <!-- <div class="profileOut">
        <i class="profilePic" *ngIf="finalUserImage"><img [src]="finalUserImage" alt="profile picture" /></i>
        <i class="profilePic" *ngIf="!finalUserImage"><img src="../../../assets/images/user_icon.png" alt="profile picture" /></i>
       
            <form>
                <div class="imageUploadWrap">
                    <label class ="imageUploadLabel edit" for="upload">
                        change photo
                        <input id="upload" type="file" name="profile" (change)="fileChangeEvent($event)"/>
                    </label>
                    <ngx-popup [(ngModel)]="visible" [ngModelOptions]="{standalone: true}" >
                        <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [containWithinAspectRatio]="containWithinAspectRatio"
                        [aspectRatio]="3 / 3"
                        [resizeToWidth]="256"
                        [cropperMinWidth]="128"
                        [onlyScaleDown]="true"
                        [roundCropper]="false"
                        [canvasRotation]="canvasRotation"
                        [transform]="transform"
                        [alignImage]="'left'"
                        [style.display]="showCropper ? null : 'none'"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady($event)"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                    <button class="uploadImageBtn" (click)="uploadImage()">upload</button>
                    </ngx-popup>
                </div>
                </form>
       
        <h3>{{customerData?.name ? customerData?.name : customerData?.firstname + ' ' + customerData?.lastname}}</h3>
        <a class="edit" [routerLink]="['/edit-profile']"><img src="../../../assets/images/edit.svg" alt="edit" />{{ 'PROFILE.MODIFY' | translate }}</a>
  

    </div> -->

    <div class="profileOut">
       
        <i class="profilePic" *ngIf="finalUserImage">
            <form>
                <div class="imageUploadWrap">
                    <label class ="imageUploadLabel edit" for="upload">
                        <img [src]="finalUserImage" alt="profile picture" />
                        <input id="upload" type="file" name="profile" (change)="fileChangeEvent($event)"/>
                    </label>
                    <ngx-popup [(ngModel)]="visible" [ngModelOptions]="{standalone: true}" >
                        <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [containWithinAspectRatio]="containWithinAspectRatio"
                        [aspectRatio]="3 / 3"
                        [resizeToWidth]="256"
                        [cropperMinWidth]="128"
                        [onlyScaleDown]="true"
                        [roundCropper]="false"
                        [canvasRotation]="canvasRotation"
                        [transform]="transform"
                        [alignImage]="'left'"
                        [style.display]="showCropper ? null : 'none'"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady($event)"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                    <button class="uploadImageBtn" (click)="uploadImage()">upload</button>
                    </ngx-popup>
                </div>
                </form>
        </i>


        <i class="profilePic" *ngIf="!finalUserImage">
            <form>
                <div class="imageUploadWrap">
                    <label class ="imageUploadLabel edit" for="upload">
                        <img src="../../../assets/images/Group 1116.png" alt="profile picture" />
                        <input id="upload" type="file" name="profile" (change)="fileChangeEvent($event)"/>
                    </label>
                    <ngx-popup [(ngModel)]="visible" [ngModelOptions]="{standalone: true}" >
                        <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [containWithinAspectRatio]="containWithinAspectRatio"
                        [aspectRatio]="3 / 3"
                        [resizeToWidth]="256"
                        [cropperMinWidth]="128"
                        [onlyScaleDown]="true"
                        [roundCropper]="false"
                        [canvasRotation]="canvasRotation"
                        [transform]="transform"
                        [alignImage]="'left'"
                        [style.display]="showCropper ? null : 'none'"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady($event)"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                    <button class="uploadImageBtn" (click)="uploadImage()">upload</button>
                    </ngx-popup>
                </div>
                </form>
        </i>
       
        <h3 *ngIf="customerData">{{customerData?.name ? customerData?.name : customerData?.firstname + ' ' + customerData?.lastname}}</h3>
        <a class="edit" [routerLink]="['/edit-profile']"><img src="../../../assets/images/edit.svg" alt="edit" />{{ 'PROFILE.MODIFY' | translate }}</a>
    </div>

    <div class="redemptionOut">
        <p class="points">{{ 'PROFILE.YOU_HAVE' | translate }} {{customerData?.loyalty_points}} {{ 'PROFILE.SCORE' | translate }}</p>
        <button class="btn btn-block goldenBtn w289 h41" [routerLink]="['/history']">{{ 'PROFILE.REDEMPTION' | translate }}</button>
        <p class="expiryDate" *ngIf="year == '2021'">0 คะแนนของคุณ จะหมดอายุในวันที่ ธ.ค. 2021</p>
        <!-- <p class="expiryDate" *ngIf="year > '2021'">{{totalAvailablePoints}} คะแนนของคุณ จะหมดอายุในวันที่ {{expiryDate}}</p> -->
        <p class="expiryDate" *ngIf="year == '2022' && totalAvailablePoints > 0">{{totalAvailablePoints}} คะแนนของคุณ จะหมดอายุในวันที่  ธ.ค. 2023</p>
        <p class="expiryDate" *ngIf="year == '2023' && totalAvailablePoints > 0">{{totalAvailablePoints}} คะแนนของคุณ จะหมดอายุในวันที่  ธ.ค. 2024</p>
    </div>
    <hr>
    <a class="cancelMembership underlinedText" (click)="onClickCancelMembershipModal()">{{ 'PROFILE.CANCEL_MEMBERSHIP' | translate }}</a>
</div>
<app-footer></app-footer>



<div bsModal #cancelMembershipModal="bs-modal" class="modal fade customModalContainer successScanModal" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name">    
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" aria-label="Close" (click)="closeCancelMembershipModal()">
                    <img src="../../../assets/images/close.png" />
                </button>
                <h4>{{ 'CANCELMEMBERSHIP.TITLE' | translate }}</h4>
                <p>{{ 'CANCELMEMBERSHIP.CALL_CENTER' | translate }} 02-252-2448 {{ 'CANCELMEMBERSHIP.TO_UNSUBSCRIBE' | translate }}</p>
                <button class="btn btn-block goldenBtn" (click)="closeCancelMembershipModal()">{{ 'CANCELMEMBERSHIP.OK' | translate }}</button>
            </div>
        </div>
    </div>
</div>













