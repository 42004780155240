<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pb150 glucernaProduct">
    <app-glucerna-header></app-glucerna-header>
    <div class="top">
        <small>เครื่องหมายการค้า</small>
        <h1>กลูเซอนา เอสอาร์ <br />
            ทริปเปิ้ลแคร์</h1>
        <h2>อีกทางเลือกของอาหารทดแทน
            เพื่อช่วยควบคุมระดับน้ำตาล<br />
            สำหรับผู้ป่วยเบาหวาน</h2>
    </div>
    <div class="middle">
        <!-- <figure class="m-0">
            <img src="../../../../assets/images/glucerna/prodimg1.png" alt="img" />
        </figure> -->
    </div>
    <div class="bottom">
        <h3>ช่วยการย่อยคาร์โบไฮเดรตช้าลง
            และช่วยควบคุมให้ระดับน้ำตาลอยู่
            ระดับที่เหมาะสม</h3>
        <img src="../../../../assets/images/glucerna/prodomg2.png" alt="img" />
        <h3 >ซูโครมอลต์ + ไฟเบอร์ซอล 2
            มีกรดไขมันไม่อิ่มตัวชนิดมูฟา
            (โอเมก้า 9) & (โอเมก้า 3)
            </h3>
            <img src="../../../../assets/images/glucerna/prodimg3.png" alt="img" />
        <h3 class="mt-m35">มีใยอาหารและ FOS เพิ่มกากในระบบ
            ทางเดินอาหาร ช่วยกระตุ้นการขับถ่าย</h3>
            <img src="../../../../assets/images/glucerna/prodimg4.png" alt="img" />
            <h3 class="mt-m35">วิตามินและแร่ธาตุ 28 ชนิด</h3>
    </div>
    <img class="topFooter" src="../../../../assets/images/glucerna/topFooter.png" alt="footer" />
    <div class="footer">
        <article>
            <img src="../../../../assets/images/glucerna/prodimg5.png" alt="">
            <h2>อาหารทางการแพทย์ <br />
                ใช้ภายใต้คำแนะนำของแพทย์</h2>

                <p>*การคำนวณส่วนแบ่งการตลาดของ Abbott Laboratories Limited ส่วนหนึ่งอ้างอิงจาก
                    ข้อมูลดัชนีค้าปลีกรายเดือนที่รายงานโดย Nielsen สำหรับตลาดนมผงทดแทนสำหรับผู้ป่วย
                    เบาหวานตามคำนิยามของบริษัท Abbott Laboratories ในช่วงเวลาตั้งแต่กันยายน 2562 
                    สิ้นสุดเดือนตุลาคม 2563 ซึ่งเป็นข้อมูลของตลาดในประเทศไทย ซึ่งเป็นลิขสิทธิ์ของ 
                    Nielsen (Copyright © 2020, Nielsen)</p>
                    <span>ใบอนุญาตโฆษณาเลขที่ ฆอ.2684/2564</span>
        </article>
    </div>
    <iframe
    width="100%"
    height="400px"
    src="https://www.youtube.com/embed/wjEcH7Al_eQ"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  

    


</section>
<app-glucerna-nav></app-glucerna-nav>

