<app-ensure-family-club></app-ensure-family-club>
<section class="traceAbilityContainer pb150 glucerna manufacturing">
  <app-glucerna-header></app-glucerna-header>
  <section class="qualityContainer">
    <div class="position-relative">
      <div class="productImgBlock mt30"></div>
      <div class="productBlock">
        <p>มาตรฐานการผลิตสินค้า</p>
      </div>
    </div>
    <div class="cardBlockOut px-2 pb-4 mt30 mb-4">
      <div class="dateBlock text-center py-2">
        <p class="mb-0">
          {{ "QUALITY_CONTROL.COUNTRY_ORIGIN" | translate }} :
          {{ manufactCountry }}
        </p>
        <p class="mb-0">
          {{ "QUALITY_CONTROL.PRODUCTION_DATE" | translate }}
          {{ manufacturedDate }}
        </p>
      </div>

      <p class="text-justify cardImgTopDescriptionQuality mb-0">
        {{ "QUALITY_CONTROL.CARD_TOP_DESC_BEFORE" | translate }}
        <span class="">{{
          "QUALITY_CONTROL.CARD_TOP_MIDDLE_DESC" | translate
        }}</span>
        {{ "QUALITY_CONTROL.CARD_TOP_DESC_AFTER" | translate }}
      </p>
      <div>
        <figure>
          <img
            class="img-fluid mt40 w-100"
            src="../../../../assets/images/glucerna/manufact1.png"
          />
        </figure>
      </div>
      <p class="cardDescription mt40">
        โรงงานผลิตของแอ๊บบอต ในประเทศสิงคโปร์ ได้ใช้เทคโนโลยีการผลิต แบบระบบปิด
        ซึ่งเป็นระบบการควบคุมอัตโนมัติ และมีห้องปฏิบัติการ
        สำหรับทำการวิจัยผลิตภัณฑ์ของแอ๊บบอตที่ได้มาตรฐาน เพื่อให้ได้
        ผลิตภัณฑ์ที่มีคุณภาพและสอดคล้องตามเกณฑ์มาตรฐานสากล
      </p>
    </div>
  </section>
</section>
<app-glucerna-nav></app-glucerna-nav>
